import { ReactElement } from 'react'
import { Box, Chip, Divider, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterfaceProps } from './CampaignsDetails'
import {
  IconComponent,
  checkIsNotEmpty,
  dashBox
} from '../../commons/utils/utils'
import { Link, useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import * as MUIcon from '@mui/icons-material'
import './CampaignsDetailsStep.scss'

const CampaignDetailStep = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          <InputLabel
            size='small'
            variant='standard'
            className='small-label description-label'
          >
            {t`Description`}
          </InputLabel>
          {campaign.Description != null
            ? (
              <ReactMarkdown linkTarget='_blank' className='description-text'>
                {campaign.Description}
              </ReactMarkdown>
              )
            : (
                dashBox
              )}
          <Divider className='divider mt-01 mb-0' />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mb-2'
          >
            {t`CTA`}
          </InputLabel>
          {checkIsNotEmpty(campaign.ProviderSignupButtonLabel)
            ? (
              <Link to={campaign.SignupURL ?? location.pathname}>
                <Chip
                  className='mb-1 clickable'
                  label={campaign.ProviderSignupButtonLabel}
                />
              </Link>
              )
            : (
                dashBox
              )}
          <Divider className='divider mb-0' />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mb-2'
          >
            {t`Badges`}
          </InputLabel>
          {checkIsNotEmpty(campaign.badges)
            ? campaign.badges?.map((badge) => {
              return (
                <Chip
                  className='detail-badge'
                  key={badge.BadgeLabel}
                  avatar={IconComponent({ icon: badge.BadgeIcon as keyof typeof MUIcon })}
                  label={`${badge.BadgeLabel}`}
                />
              )
            })
            : dashBox}
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignDetailStep
