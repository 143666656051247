import { FC, useCallback } from 'react'
import {
  EarningsListColumns,
  PER_PAGE_USERS_TABLE,
  FIRST_PAGE_USERS_TABLE
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import {
  setClassNameForTable,
  convertStringToDateAndTime
} from '../../commons/utils/utils'
import { RoutePath } from '../../core/routes/routePaths'
import { Link } from 'react-router-dom'
import { EarningsInterface, EarningsListParams } from '../interfaces/users'

interface UsersEarningsListProps {
  onPageChange: (changedData: EarningsListParams) => void
  page?: number
  perPage?: number
  total: number
  earnings?: EarningsInterface[]
}

const COLUMNS = Object.values(EarningsListColumns)

const UsersEarningsList: FC<UsersEarningsListProps> = ({
  page,
  perPage,
  total,
  earnings,
  onPageChange
}) => {
  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {earnings?.length != null && earnings.length > 0 &&
          (
            <TableBody>
              {earnings?.map((row, index) => {
                const claimedDateString = convertStringToDateAndTime(
                  row.ClaimedAtUTC
                )
                const earnedAtUTC = convertStringToDateAndTime(row.EarnedAtUTC)
                return (
                  <TableRow
                    className='row-with-actions'
                    key={`${row.TransactionID}-${index}`}
                  >
                    <TableCell className='body-cell'>
                      <Link to={`${RoutePath.Campaigns}/${row.CampaignID ?? ''}`}>
                        <Box>
                          {row.Title != null ? row.Title : row.CampaignType}
                        </Box>
                        <Box className='mini-subtitle'>{row.CampaignID}</Box>
                      </Link>
                    </TableCell>
                    <TableCell className='body-cell'>
                      {earnedAtUTC?.date != null
                        ? `${earnedAtUTC?.date ?? ''} |
                  ${earnedAtUTC?.time ?? ''}`
                        : '-'}
                    </TableCell>
                    <TableCell className='body-cell'>
                      {claimedDateString?.date != null
                        ? `${claimedDateString?.date ?? ''} |
                  ${claimedDateString?.time ?? ''}`
                        : '-'}
                    </TableCell>
                    <TableCell className='body-cell'>{row.TransactionID}</TableCell>
                    <TableCell className='body-cell'>{row.VelosEarned}</TableCell>
                    <TableCell className='body-cell' />
                  </TableRow>
                )
              })}
            </TableBody>
          )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_USERS_TABLE}
        perPage={perPage ?? PER_PAGE_USERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={earnings?.length}
      />
    </TableContainer>
  )
}

export default UsersEarningsList
