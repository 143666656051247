import { Badge, Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import _ from 'lodash'
import '../../ui/phone-preview/PhonePreview.scss'
import { CampaignPreviewInterface } from '../interfaces/campaigns'
import { t } from 'i18next'
import { emptyPreview } from '../../ui/phone-preview/PhonePreview'

interface CampaignsInterfaceProps {
  campaign: CampaignPreviewInterface
  points: number
  isStreakCampaign: boolean
}

const campaignPreview = (
  { url, subHeader, title, subTitle }: CampaignPreviewInterface,
  points: number,
  isStreakCampaign: boolean
): ReactElement => (
  <Box className='campaign-preview'>
    <img alt='category-campaign-preview' src={url} />
    {(points !== 0 || isStreakCampaign) && (
      <Badge
        badgeContent={isStreakCampaign ? t`Streak` : `${points} pts`}
        overlap='rectangular'
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        className='mb-6'
      />
    )}
    <Typography className='header'>{subHeader}</Typography>
    <Typography className='subheader'>{title}</Typography>
    <Typography className='campaign-action'>{subTitle}</Typography>
  </Box>
)

const CategoriesCampaignPreview = ({
  campaign,
  points,
  isStreakCampaign
}: CampaignsInterfaceProps): ReactElement => {
  return (
    <Box className='flex-column'>
      {!_.isEmpty(campaign.category) && (
        <Typography className='category-title'>{campaign.category}</Typography>
      )}
      <Box className='flex-row'>
        {!_.isEmpty(campaign.url)
          ? campaignPreview(campaign, points, isStreakCampaign)
          : emptyPreview()}
        {emptyPreview()}
      </Box>
      <Box className='flex-row'>
        {emptyPreview('mt-01')}
        {emptyPreview('mt-01')}
      </Box>
    </Box>
  )
}

export default CategoriesCampaignPreview
