import { Divider, Box, Chip, TableCell } from '@mui/material'
import { FC } from 'react'
import SelfServeButton from '../../ui/button/Button'
import '../campaigns.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { useAppSelector } from '../../core/redux/hooks'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import CampaignPreviewBox from './CampaignPreviewBox'
import CampaignPreviewTitleBox from './CampaignPreviewTitleBox'
import {
  IconComponent,
  PreviewSegmentsList,
  convertStringToDateAndTime,
  formatDateAndTime
} from '../../commons/utils/utils'
import _ from 'lodash'
import * as MUIcon from '@mui/icons-material'
import { BadgeProp } from '../interfaces/campaigns'

const PREVIEW_LABEL = 'label'
const PREVIEW_CONTENTS = 'contents'
interface CampaignPreviewProps {
  webUiPosition: string
  handleBack: () => void
  onDiscard: () => void
  onSubmit: () => void
  handleVisitStep: (stepNumber: number) => void
  isLeavingDialogOpen: boolean
  isFromEdit?: boolean
}

const CampaignPreview: FC<CampaignPreviewProps> = ({
  webUiPosition,
  handleBack,
  onDiscard,
  onSubmit,
  handleVisitStep,
  isFromEdit = false
}) => {
  const {
    campaignForm: {
      name,
      title,
      subHeader1,
      subHeader2,
      AgencyID,
      category,
      isFeatured,
      description,
      hasCTA,
      ctaName,
      ctaLink,
      badge,
      provider,
      aggregateMethod,
      aggregateValue,
      filter,
      operator,
      filterValue,
      pointEarned,
      bucketRepetition,
      earningCount,
      earnUnit,
      startTimestampUTC,
      endTimestampUTC,
      hasExpiryDate,
      pointExpiryDate,
      availableSegments,
      excludeSegments,
      iconID,
      thumbnailID
    }
  } = useAppSelector((state) => state.campaigns)
  const { t } = useTranslation()
  const { segmentOptions } = useAppSelector((state) => state.segments)
  const { agencies } = useAppSelector((state) => state.agencies)
  const { date: startDate, time: startTime } =
    convertStringToDateAndTime(startTimestampUTC)
  const { date: endDate, time: endTime } =
    convertStringToDateAndTime(endTimestampUTC)
  const { date: expiryDate, time: expiryTime } =
    convertStringToDateAndTime(pointExpiryDate)
  const hasContents = (
    content: BadgeProp[] | string[],
    type: string
  ): string => {
    return !_.isEmpty(content)
      ? type === PREVIEW_LABEL
        ? 'shrink'
        : 'shrink_padding'
      : ''
  }
  const selectedAgency = agencies.find((agency) => agency.AgencyID === AgencyID)
  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Box className='details-box'>
        <CampaignPreviewTitleBox
          name={t`CardInfo`}
          toStep={0}
          handleVisitStep={handleVisitStep}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
            aria-label='simple table'
            className='preview-table'
          >
            <TableBody>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`CampaignNameInternal`}
                  value={name}
                />
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`Title`}
                  value={title}
                />
              </TableRow>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`SubHeader1`}
                  value={subHeader1}
                />
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`SubHeader2`}
                  value={subHeader2}
                />
              </TableRow>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`Category`}
                  value={category}
                />
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`Agency`}
                  value={selectedAgency !== null ? selectedAgency?.Name : null}
                />
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`CampaignIcon`}
                  value={iconID}
                  isImage
                  imageType='icon'
                />
              </TableRow>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`UiPosition`}
                  value={webUiPosition}
                />
                <CampaignPreviewBox
                  colSpanNumber={3}
                  label={t`FeaturedUiPosition`}
                  value={
                    isFeatured != null ? (isFeatured ? t`Yes` : t`No`) : null
                  }
                />
              </TableRow>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={6}
                  label={t`Thumbnail`}
                  value={thumbnailID}
                  isImage
                  imageType='thumbnail'
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className='details-box'>
        <CampaignPreviewTitleBox
          name={t`CardDetail`}
          toStep={1}
          handleVisitStep={handleVisitStep}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
            aria-label='simple table'
            className='preview-table'
          >
            <TableBody>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`Description`}
                  value={description}
                />
              </TableRow>
              {hasCTA && (
                <TableRow>
                  <CampaignPreviewBox
                    colSpanNumber={0}
                    label={t`CTALabel`}
                    value={ctaName}
                  />
                  <CampaignPreviewBox
                    colSpanNumber={0}
                    label={t`CTALink`}
                    value={ctaLink}
                  />
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2} className='body-cell preview-table-cell'>
                  <div className='preview-custom-wrapper'>
                    <p
                      className={`preview-custom-label ${hasContents(
                        badge,
                        PREVIEW_LABEL
                      )}`}
                    >
                      {t`Badges`}
                    </p>
                    <div
                      className={`preview-custom-contents ${hasContents(
                        badge,
                        PREVIEW_CONTENTS
                      )}`}
                    >
                      {badge?.map((singleBadge) => {
                        return (
                          <Chip
                            className='preview-badge'
                            key={singleBadge.label}
                            avatar={IconComponent({
                              icon: singleBadge.icon as keyof typeof MUIcon
                            })}
                            label={`${singleBadge.label}`}
                          />
                        )
                      })}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className='details-box'>
        <CampaignPreviewTitleBox
          name={t`CampaignOptions`}
          toStep={2}
          handleVisitStep={handleVisitStep}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
            aria-label='simple table'
            className='preview-table'
          >
            <TableBody>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`ProviderRequiredToConnect`}
                  value={provider}
                />
              </TableRow>
              {aggregateValue != null && aggregateMethod != null && (
                <TableRow>
                  <CampaignPreviewBox
                    colSpanNumber={2}
                    label={t`TransactionAggregate`}
                    value={aggregateValue + ' ' + aggregateMethod}
                  />
                </TableRow>
              )}
              {filter != null && operator != null && filterValue != null && (
                <TableRow>
                  <CampaignPreviewBox
                    colSpanNumber={2}
                    label={t`TransactionFilter`}
                    value={filter + ' ' + operator + ' ' + filterValue}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className='details-box'>
        <CampaignPreviewTitleBox
          name={t`RewardsDistribution`}
          toStep={3}
          handleVisitStep={handleVisitStep}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
            aria-label='simple table'
            className='preview-table'
          >
            <TableBody>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={0}
                  label={t`PointEarned`}
                  value={pointEarned}
                />
                <CampaignPreviewBox
                  colSpanNumber={0}
                  label={t`MaxTimeEarnedByUser`}
                  value={bucketRepetition}
                />
              </TableRow>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={2}
                  label={t`TimesEarnedByUserOnTimeFrame`}
                  value={
                    earningCount != null
                      ? t('PreviewEarnCountPerUnit', {
                        count: earningCount,
                        unit: earnUnit
                      })
                      : ''
                  }
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className='details-box'>
        <CampaignPreviewTitleBox
          name={t`Audience`}
          toStep={4}
          handleVisitStep={handleVisitStep}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
            aria-label='simple table'
            className='preview-table'
          >
            <TableBody>
              <TableRow>
                <CampaignPreviewBox
                  colSpanNumber={0}
                  label={t`StartDate`}
                  value={formatDateAndTime(startDate, startTime)}
                />
                <CampaignPreviewBox
                  colSpanNumber={0}
                  label={t`EndDate`}
                  value={formatDateAndTime(endDate, endTime)}
                />
              </TableRow>
              {hasExpiryDate && (
                <TableRow>
                  <CampaignPreviewBox
                    colSpanNumber={2}
                    label={t`ExpirePointsDate`}
                    value={formatDateAndTime(expiryDate, expiryTime)}
                  />
                </TableRow>
              )}
              {!isFromEdit && (
                <TableRow>
                  <TableCell
                    colSpan={0}
                    className='body-cell preview-table-cell'
                  >
                    <div className='preview-custom-wrapper'>
                      <p className='preview-custom-label shrink'>{t`IncludedSegments`}</p>
                      <div
                        className={`preview-custom-contents ${hasContents(
                          availableSegments,
                          PREVIEW_CONTENTS
                        )}`}
                      >
                        {PreviewSegmentsList(availableSegments, segmentOptions)}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    colSpan={0}
                    className='body-cell preview-table-cell'
                  >
                    <div className='preview-custom-wrapper'>
                      <p className='preview-custom-label shrink'>{t`ExcludedSegments`}</p>
                      <div
                        className={`preview-custom-contents ${hasContents(
                          excludeSegments,
                          PREVIEW_CONTENTS
                        )}`}
                      >
                        {PreviewSegmentsList(excludeSegments, segmentOptions)}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box className='mt-1 flex-row space-between'>
        <Box>
          <SelfServeButton
            id='campaign-create-preview'
            variant='contained'
            onClick={handleBack}
            color='secondary'
            className='mr-1'
          >
            {t`Previous`}
          </SelfServeButton>
          <SelfServeButton
            dataTestId='saveChangesCreateButton'
            id='offer-create-submit'
            type='submit'
            variant='contained'
            onClick={onSubmit}
          >
            {isFromEdit ? t`SaveChanges` : t`CreateCampaignSubmit`}
          </SelfServeButton>
        </Box>
        <SelfServeButton
          id='campaign-create-discard'
          onClick={onDiscard}
          variant='contained'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Box>
  )
}

export default CampaignPreview
