import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { campaignCategoriesService } from './services'
import { campaignCategoriesConstants } from './constants'
import { CampaignCategoryState } from '../../type'
import { CampaignListParams } from '../campaigns/interfaces/campaigns'
import {
  CampaignCategoryInterface,
  CampaignCategoryListParams,
  LiveCampaignsByCategoryIDParams
} from './interfaces/campaign-categories'
import { handleRejectWithReport } from '../core/error/handler'
import { handleSliceErrors } from '../commons/utils/utils'

export const getCampaignCategoryByID = createAsyncThunk(
  'categories/getCampaignCategoryByID',
  async (id: string, { rejectWithValue }) => {
    try {
      return await campaignCategoriesService.getCampaignCategoryByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getLiveCampaigns = createAsyncThunk(
  'categories/getLiveCampaigns',
  async (params: LiveCampaignsByCategoryIDParams, { rejectWithValue }) => {
    try {
      return await campaignCategoriesService.getLiveCampaignsByCategoryID(
        params.tenantID,
        params.id
      )
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getCampaignsByCategoryID = createAsyncThunk(
  'categories/getCampaignsByCategoryID',
  async (params: CampaignListParams, { rejectWithValue }) => {
    if (params.categories?.[0] != null) {
      try {
        return await campaignCategoriesService.getCampaignsByCategoryID(
          params,
          params.categories?.[0]
        )
      } catch (error: any) {
        return handleRejectWithReport(rejectWithValue, error)
      }
    } else {
      return {
        count: 0,
        campaigns: []
      }
    }
  }
)

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (params: CampaignCategoryListParams, { rejectWithValue }) => {
    try {
      return await campaignCategoriesService.getCampaignCategories(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const createCampaignCategory = createAsyncThunk(
  'categories/createCampaignCategory',
  async (params: CampaignCategoryInterface, { rejectWithValue }) => {
    try {
      return await campaignCategoriesService.createCampaignCategory(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editCampaignCategory = createAsyncThunk(
  'categories/editCampaignCategory',
  async (params: CampaignCategoryInterface, { rejectWithValue }) => {
    try {
      if (params.CampaignCategoryID != null) {
        return await campaignCategoriesService.editCampaignCategory(
          params.CampaignCategoryID,
          params
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

const categoriesInitialState = {
  campaignCategorySelected: {},
  liveCampaigns: [],
  campaigns: [],
  totalCampaignsList: 0,
  loading: true,
  categories: [],
  totals: 0,
  isFulfilled: false,
  errors: []
}

const campaignCategories = createSlice({
  name: campaignCategoriesConstants.Domain,
  initialState: categoriesInitialState as CampaignCategoryState,
  reducers: {
    setCampaignCategorySelected (state, action) {
      state.campaignCategorySelected = action.payload
    },
    resetCategoryForm (state) {
      state.isFulfilled = false
      state.errors = []
      state.campaignCategorySelected = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignCategoryByID.fulfilled, (state, action) => {
        state.campaignCategorySelected = action.payload
        state.loading = false
      })
      .addCase(getCampaignCategoryByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignCategoryByID.rejected, (state, action) => {
        state.campaignCategorySelected = {}
        state.loading = false
      })
      .addCase(getLiveCampaigns.fulfilled, (state, action) => {
        state.liveCampaigns = action.payload
        state.loading = false
      })
      .addCase(getLiveCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getLiveCampaigns.rejected, (state, action) => {
        state.liveCampaigns = []
        state.loading = false
      })
      .addCase(getCampaignsByCategoryID.fulfilled, (state, action) => {
        state.campaigns = action.payload?.campaigns ?? []
        state.totalCampaignsList = action.payload?.count ?? 0
        state.loading = false
      })
      .addCase(getCampaignsByCategoryID.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignsByCategoryID.rejected, (state, action) => {
        state.campaigns = []
        state.totalCampaignsList = 0
        state.loading = false
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload?.campaignCategories ?? []
        state.totals = action.payload.count
        state.loading = false
      })
      .addCase(getCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categories = []
        state.totals = 0
        state.loading = false
      })
      .addCase(createCampaignCategory.fulfilled, (state, action) => {
        state.loading = false
        state.isFulfilled = true
        state.campaignCategorySelected = action.payload
      })
      .addCase(createCampaignCategory.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(createCampaignCategory.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
      .addCase(editCampaignCategory.fulfilled, (state, action) => {
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editCampaignCategory.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(editCampaignCategory.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
  }
})

export const { setCampaignCategorySelected, resetCategoryForm } =
  campaignCategories.actions
export const campaignCategoriesReducer = campaignCategories.reducer
