import { ReactElement } from 'react'
import './live-campaigns.scss'
import LiveCampaignContent, {
  CampaignsInterfaceProps
} from './LiveCampaignContent'

const LiveCampaignCard = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  return campaign.CampaignID != null
    ? (
      <LiveCampaignContent campaign={campaign} />
      )
    : null
}

export default LiveCampaignCard
