import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { CampaignListParams } from './interfaces/campaigns'
import CampaignsList from './CampaignsList'
import CampaignsListHeader from './CampaignsListHeader'
import { getValueForForm } from '../commons/utils/utils'
import {
  cleanCampaignsForCSV,
  getCampaigns,
  getCampaignsForCSV
} from './slices'
import {
  FIRST_PAGE_CAMPAIGNS_TABLE,
  PER_PAGE_CAMPAIGNS_TABLE
} from './constants'
import { CSVLink } from 'react-csv'
import SelfServeButton from '../ui/button/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { RoleType } from '../core/routes/constants'
import { useRef } from 'react'
import { RoutePath } from '../core/routes/routePaths'
import { PAGE, constants } from '../commons/constants/constants'

const Campaigns = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const csvRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const {
    campaigns,
    totalCampaigns,
    campaignsForCSV,
    campaignsForCSVLoading,
    tenantID,
    agencyID,
    userType,
    isAgencyEnabled
  } = useAppSelector((state) => ({
    campaigns: state.campaigns.campaigns,
    totalCampaigns: state.campaigns.totalCampaigns,
    campaignsForCSV: state.campaigns.campaignsForCSV,
    campaignsForCSVLoading: state.campaigns.campaignsForCSVLoading,
    tenantID: state.auth.tenantID,
    agencyID: state.auth.agencyID,
    userType: state.auth.userType,
    isAgencyEnabled: state.auth.isAgencyEnabled
  }))

  const handleFormChange = ({
    agencyID,
    tenantID,
    search,
    categories,
    page,
    perPage,
    status
  }: CampaignListParams): void => {
    Object.entries({
      agencyID,
      tenantID,
      search,
      categories,
      page,
      perPage,
      status
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      setSearchParams((params) => {
        if (Array.isArray(checkedValue) && checkedValue.length === 0) {
          params.delete(k)
        } else if (checkedValue != null) {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
        }
        return params
      })
    })
  }

  const csvClick = (): void => {
    void dispatch(
      getCampaignsForCSV({
        categories: searchParams.get('categories')?.split(',') ?? [],
        tenantID,
        search: searchParams.get('search') ?? '',
        page: undefined,
        perPage: undefined,
        agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
        status: searchParams.get('status') ?? ''
      })
    )
  }

  React.useEffect(() => {
    if (!campaignsForCSVLoading && campaignsForCSV.length > 0) {
      csvRef?.current?.link.click()
    }
  }, [campaignsForCSVLoading, campaignsForCSV])

  React.useEffect(() => {
    void dispatch(
      getCampaigns({
        categories: searchParams.get('categories')?.split(',') ?? [],
        tenantID,
        search: searchParams.get('search') ?? '',
        page: parseInt(
          searchParams.get('page') ?? FIRST_PAGE_CAMPAIGNS_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ?? PER_PAGE_CAMPAIGNS_TABLE.toString()
        ),
        agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
        status: searchParams.get('status') ?? ''
      })
    )
  }, [agencyID, tenantID, dispatch, searchParams])

  React.useEffect(() => {
    return () => {
      void dispatch(cleanCampaignsForCSV())
    }
  }, [dispatch])

  return (
    <Box className='flex-column'>
      <Box className='flex-row space-between'>
        <h1 className='title'>{t`Campaigns`}</h1>
        <Box>
          <SelfServeButton
            id='generate-campaign-report'
            className='mr-1'
            variant='contained'
            color='secondary'
            onClick={csvClick}
          >
            <FileDownloadOutlinedIcon
              fontSize='small'
              className='button-icon'
            />{' '}
            {t`ExportList`}
          </SelfServeButton>
          <CSVLink
            data={campaignsForCSV.map((item) => {
              return {
                ID: item.CampaignID,
                Type: item.CampaignType,
                Name: item.Name,
                Agency: item.AgencyName,
                Category: item.CategoryName,
                CampaignUIPosition: item.WebUiPosition,
                Title: item.Title,
                SubHeader1: item.SubHeader,
                SubHeader2: item.SubTitle,
                Description: item.Description,
                CTALabel: item.ProviderSignupButtonLabel,
                CTALink: item.SignupURL,
                Points: item.RewardPerUser,
                EarningLimit: item.TotalReward,
                Status: item.status
              }
            })}
            ref={csvRef}
            filename={`campaigns-${Date.now()}.csv`}
          />
          <SelfServeButton
            dataTestId='addNewCampaginButton'
            color='primary'
            id='create-campaign'
            variant='contained'
          >
            <Link to={RoutePath.CreateCampaigns} className='neutral-link'>
              <AddOutlinedIcon className='button-icon' /> {t`NewCampaign`}
            </Link>
          </SelfServeButton>
        </Box>
      </Box>
      <CampaignsListHeader
        handleFormChange={handleFormChange}
        agency={agencyID ?? searchParams.get('agencyID') ?? ''}
        search={searchParams.get('search') ?? ''}
        status={searchParams.get('status') ?? ''}
        categories={searchParams.get('categories')?.split(',') ?? []}
      />
      <CampaignsList
        showAgency={userType !== RoleType.AGENCY && isAgencyEnabled}
        campaignsList={campaigns}
        total={totalCampaigns}
        handleFormChange={handleFormChange}
        page={parseInt(
          searchParams.get('page') ?? FIRST_PAGE_CAMPAIGNS_TABLE.toString()
        )}
        perPage={parseInt(
          searchParams.get('perPage') ?? PER_PAGE_CAMPAIGNS_TABLE.toString()
        )}
      />
    </Box>
  )
}

export default Campaigns
