import { Chip } from '@mui/material'
import { ReactElement } from 'react'
import { OfferCategoriesInterface } from './interfaces/offer-categories'
import { OffersStatuses } from '../offers/constants'
import { AppStatus } from '../commons/constants/constants'
import { RouteItem, RoutePath } from '../core/routes/routePaths'
import { RoleType } from '../core/routes/constants'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const CATEGORY_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const offerCategoryBarRoutePath = (
  id: string,
  offers?: number
): Record<string, RouteItem> => {
  return {
    details: {
      path: `${RoutePath.OfferCategories}/${id}`,
      setPath: true,
      title: i18n.t`Details`,
      roles: CATEGORY_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    offers: {
      path: `${RoutePath.OfferCategories}/${id}${RoutePath.Offers}`,
      title: i18n.t`AllOffers`,
      roles: CATEGORY_PAGE_ROLES,
      icon: VerifiedOutlinedIcon,
      showBadge: true,
      badgeValue: offers,
      children: []
    }
  }
}

export const chip = (
  value: string,
  categories: OfferCategoriesInterface[]
): ReactElement => (
  <Chip
    key={value}
    label={categories.find((x) => x.OfferCategoryID === parseInt(value))?.CategoryName}
  />
)

export const setStatusColor = (status: OffersStatuses): AppStatus => {
  switch (status) {
    case OffersStatuses.LIVE:
      return AppStatus.SUCCESS
    case OffersStatuses.HIDDEN:
      return AppStatus.WARNING
    case OffersStatuses.EXPIRED:
      return AppStatus.ERROR
  }
}

export const categoriesListActionItems = (categoryID: number): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.OfferCategories}/${categoryID}${RoutePath.Offers}`,
      title: i18n.t`AllOffers`,
      icon: <VerifiedOutlinedIcon />
    },
    {
      path: `${RoutePath.OfferCategories}/${categoryID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}
