import {
  Box,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  FormControl,
  TextField,
  FormHelperText
} from '@mui/material'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FC, ReactElement } from 'react'
import '../segments.scss'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import SelfServeButton from '../../ui/button/Button'
import {
  filterNonNumericValues,
  handleSubmit,
  replaceWhitespaceWithComma
} from '../../commons/utils/utils'
import { SegmentsFormStepsProps } from '../interfaces/segments'
import { useTranslation } from 'react-i18next'
import {
  EditRewardsMethod,
  INVITE_CODES,
  INVITE_CODES_FILE,
  formValidationConstants
} from '../constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import { setRadioButtonOption } from '../slices'
import _ from 'lodash'

const SegmentRewardsUploadListEdit: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  onSubmit,
  onDiscard,
  handleBack
}) => {
  const {
    segments: {
      isFormTouched,
      radioButtonOption,
      segmentsForm: {
        editMethod,
        generationMethod,
        inviteCodes,
        timesCanBeUsed,
        currentTimesCanBeUsed,
        codesFile,
        codesFileID
      }
    }
  } = useAppSelector((state) => state)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    trigger,
    control,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      inviteCodes,
      timesCanBeUsed,
      codesFile
    }
  })

  const renderSwitch = (): ReactElement | null => {
    switch (radioButtonOption) {
      case EditRewardsMethod.NEW_CODES_MANUAL.name:
        return (
          <FormControl>
            <FormFieldBox
              title={t`AddMoreCodesToSegment`}
              rules={{
                required: formValidationConstants.requiredField
              }}
              name={INVITE_CODES}
              render={({ field: { name, onChange } }) => (
                <TextField
                  placeholder={t`EnterCodesCommaSeparated`}
                  className='code-box mb-1'
                  name={name}
                  id='codes'
                  multiline
                  minRows={2}
                  onChange={(e) => {
                    onChange(e.target.value)
                    onFormChange(
                      replaceWhitespaceWithComma(e.target.value).split(','),
                      INVITE_CODES
                    )
                  }}
                  value={inviteCodes}
                />
              )}
              control={control}
              errorField={errors.inviteCodes}
            />
            <FormHelperText className='fw-400'>
              {t`DuplicateCodeCSVFileEdit`}
            </FormHelperText>
          </FormControl>
        )
      case EditRewardsMethod.NEW_CODES_UPLOAD.name:
      case EditRewardsMethod.REPLACE.name:
        return (
          <div>
            <FormFieldBox
              title={t`UploadTheFileOfCodesReplace`}
              rules={{
                validate: {
                  required: () => {
                    return !_.isEmpty(codesFile)
                  }
                }
              }}
              name={INVITE_CODES}
              render={({ field: { name, onChange } }) => (
                <ImageDropZone
                  isImage={false}
                  isThumbnail
                  name={name}
                  setFile={(data: File | undefined) => {
                    onFormChange(data, INVITE_CODES_FILE)
                    onChange(data)
                  }}
                  thumbnailID={codesFileID}
                  file={codesFile}
                />
              )}
              control={control}
              errorField={errors.inviteCodes}
            />
            <FormHelperText error>
              {errors?.inviteCodes != null &&
                _.isEmpty(codesFile) &&
                t`RequiredField`}
            </FormHelperText>
          </div>
        )
      case EditRewardsMethod.NUMBER_OF_TIMES.name:
        return (
          <FormControl fullWidth>
            <Typography variant='subtitle1'>
              {t('TimesCanBeUsedEdit', { currentTimesCanBeUsed })}
            </Typography>
            <FormFieldBox
              title=''
              rules={{
                required: formValidationConstants.requiredField,
                min: formValidationConstants.minNum,
                max: formValidationConstants.maxNum
              }}
              name='timesCanBeUsed'
              render={({ field: { name, onChange } }) => (
                <TextField
                  name={name}
                  id='timesCanBeUsed'
                  placeholder={t`EnterUsageLimit`}
                  onChange={(e) => {
                    onFormChange(filterNonNumericValues(e.target?.value), name)
                    onChange(filterNonNumericValues(e.target?.value))
                  }}
                  value={timesCanBeUsed}
                />
              )}
              control={control}
              errorField={errors.timesCanBeUsed}
            />
          </FormControl>
        )
      default:
        return null
    }
  }

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        onSubmit != null &&
          handleSubmit(event, trigger, onSubmit).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper'
    >
      <Typography variant='subtitle1' className='mt-1'>
        {t`CodeGenerationMethod`}
      </Typography>
      <Typography className='mb-1'>{generationMethod}</Typography>
      <Typography variant='subtitle1' className='mt-1'>
        {t`EditListOfCodes`}
      </Typography>
      <RadioGroup
        aria-labelledby='segment-csv-radios'
        name='editMethod'
        className='mb-1'
        value={editMethod}
        onChange={(e) => {
          onFormChange(e.target?.value, 'editMethod')
          onFormChange(
            e.target?.value === EditRewardsMethod.NEW_CODES_UPLOAD.name ||
              e.target?.value === EditRewardsMethod.REPLACE.name,
            'areCodesFromCSV'
          )
          dispatch(setRadioButtonOption(e.target?.value))
        }}
      >
        {Object.values(EditRewardsMethod).map((v) => {
          return (
            <FormControlLabel
              key={v.name}
              value={v.name}
              control={<Radio />}
              label={t(v.label)}
            />
          )
        })}
      </RadioGroup>
      {renderSwitch()}
      <Divider className='mt-2' />
      <Box className='mt-1 flex-row space-between'>
        <Box>
          <SelfServeButton
            id='segments-edit-back'
            variant='contained'
            onClick={handleBack}
            color='secondary'
            className='mr-1'
          >
            {t`Previous`}
          </SelfServeButton>
          <SelfServeButton
            id='segments-edit-submit'
            type='submit'
            variant='contained'
            disabled={!isDirty && !isFormTouched}
          >
            {t`UpdateSegment`}
          </SelfServeButton>
        </Box>
        <SelfServeButton
          id='segments-edit-discard'
          onClick={onDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Form>
  )
}

export default SegmentRewardsUploadListEdit
