import { ReactElement, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import Turndown from 'turndown'
import { useAppDispatch } from '../../core/redux/hooks'
import { Remarkable } from 'remarkable'
import _ from 'lodash'
import Editor from './TextFieldWithEditor'
import { TextFieldChangeSetterInterface } from '../interfaces/ui'

interface TextFieldWithEditorWrapperProps {
  onChange: (value: string) => void
  setIsChanged: (value: string | null) => TextFieldChangeSetterInterface
  resetIsChanged: () => TextFieldChangeSetterInterface
  valueMarkdown: string
  isChanged: boolean
  valueHTML: string
  placeholder: string
}

const TextFieldWithEditorWrapper = ({
  onChange,
  setIsChanged,
  resetIsChanged,
  valueMarkdown,
  isChanged,
  valueHTML,
  placeholder
}: TextFieldWithEditorWrapperProps): ReactElement => {
  const dispatch = useAppDispatch()

  const [value, setValue] = useState<string>('')
  const [isDone, setIsDone] = useState<boolean>(false)
  const [openFormat, setOpenFormat] = useState<boolean>(false)
  const toMarkdown = new Turndown()
  const toHTML = new Remarkable()
  const handleChange = (content: string): void => {
    setValue(content)
  }

  const handleSave = (): void => {
    const markdownInput = toMarkdown.turndown(value)
    if (markdownInput !== '') {
      onChange(markdownInput)
      dispatch(setIsChanged(value))
      setOpenFormat(false)
      setIsDone(true)
    }
  }

  const handleOpenFormat = (): void => {
    dispatch(resetIsChanged())
    setOpenFormat(true)
    setIsDone(false)
    setValue(toHTML.render(valueMarkdown))
  }

  const handleDiscard = (): void => {
    if (!_.isEmpty(valueMarkdown)) {
      setOpenFormat(false)
      setIsDone(true)
      dispatch(setIsChanged(null))
    } else {
      setValue('')
      setOpenFormat(false)
    }
  }

  return (
    <Editor
      openFormat={openFormat}
      isDone={isDone}
      isChanged={isChanged}
      value={value}
      valueHTML={valueHTML}
      placeholder={placeholder}
      setOpenFormat={setOpenFormat}
      handleChange={handleChange}
      handleSave={handleSave}
      handleDiscard={handleDiscard}
      handleOpenFormat={handleOpenFormat}
      valueMarkdown={valueMarkdown ?? ''}
    />
  )
}

export default TextFieldWithEditorWrapper
