import { FC, ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import { Box, Divider, InputLabel } from '@mui/material'
import './agencies.scss'
import SelfServeButton from '../ui/button/Button'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { getAgencyByID, resendAgencyEmail } from './slices'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../ui/sidebar/slices'
import { constants } from '../commons/constants/constants'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import { agenciesConstants } from './constants'
import { RoutePath } from '../core/routes/routePaths'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import SelfServeROTextField from '../ui/text-field/TextFieldRO'

const AgenciesDetails: FC<{}> = (): ReactElement => {
  const { Url, Name, DashboardAccess, AgencyID, Email, City, State, loading } =
    useAppSelector((state) => ({
      Url: state.agencies.agencySelected.Url,
      Name: state.agencies.agencySelected.Name,
      DashboardAccess: state.agencies.agencySelected.DashboardAccess,
      AgencyID: state.agencies.agencySelected.AgencyID,
      Email: state.agencies.agencySelected.Email,
      City: state.agencies.agencySelected.City,
      State: state.agencies.agencySelected.State,
      loading: state.agencies.loading
    }))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const [openResendInvitationDialog, setOpenResendInvitationDialog] =
    useState<boolean>(false)
  const [openResendEmailDialog, setOpenResendEmailDialog] =
    useState<boolean>(false)
  const [openResetPasswordDialog, setOpenResetPasswordDialog] =
    useState<boolean>(false)

  const hasDashboardAccess = DashboardAccess != null && DashboardAccess

  const handleClose = (): void => {
    setOpenResendInvitationDialog(false)
    setOpenResetPasswordDialog(false)
    setOpenResendEmailDialog(false)
  }

  const handleSendResetPasswordEmail = (): void => {
    if (AgencyID != null) {
      handleClose()
      void dispatch(
        resendAgencyEmail({
          id: AgencyID,
          emailType: agenciesConstants.EmailType.PASSWORD
        })
      )
      // TODO: function with user interaction: need to show user friendly error message as prompt in the future
    } else {
      reportError(t`NoAgencyIDError`, ErrorLevels.Error)
    }
  }

  const handleSendResendInvitationEmail = (): void => {
    if (AgencyID != null) {
      handleClose()
      void dispatch(
        resendAgencyEmail({
          id: AgencyID ?? '',
          emailType: hasDashboardAccess
            ? agenciesConstants.EmailType.INVITATION
            : agenciesConstants.EmailType.PASSWORD
        })
      )
      // TODO: function with user interaction: need to show user friendly error message as prompt in the future
    } else {
      reportError(t`NoAgencyIDError`, ErrorLevels.Error)
    }
  }

  const renderResendInvitationDialog = (
    <CustomDialog
      title={t`ResendInvitationTitle`}
      open={openResendInvitationDialog}
      cancelCallback={handleClose}
      okCallback={handleSendResendInvitationEmail}
      mainMessage={t`ResendInvitation`}
      okText={t`SendIt`}
      cancelText={t`Cancel`}
    />
  )

  const renderResendEmailDialog = (
    <CustomDialog
      title={t`ResendEmailTitle`}
      open={openResendEmailDialog}
      cancelCallback={handleClose}
      okCallback={handleSendResendInvitationEmail}
      mainMessage={t`ResendEmail`}
      okText={t`SendIt`}
      cancelText={t`Cancel`}
    />
  )

  const renderResetPasswordDialog = (
    <CustomDialog
      title={t`ResendPasswordTitle`}
      open={openResetPasswordDialog}
      cancelCallback={handleClose}
      okCallback={handleSendResetPasswordEmail}
      mainMessage={t`ResendPassword`}
      okText={t`SendIt`}
      cancelText={t`Cancel`}
    />
  )

  useEffect(() => {
    if (id != null) {
      void dispatch(getAgencyByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.AGENCY))
      dispatch(setBackButtonTitle(t`BackToAgencies`))
      dispatch(setBackButtonRoute(RoutePath.Agencies))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  return AgencyID != null && !loading
    ? (
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        <Box className='flex-row'>
          <Box>
            <h1 className='title'>{t`AgencyDetails`}</h1>
          </Box>
          <Box marginLeft='2em'>
            <SelfServeButton
              size='small'
              id='edit-agency-button'
              variant='contained'
              color='secondary'
              onClick={() => navigate(RoutePath.Edit)}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
        </Box>
        <Box className='box-details'>
          <SelfServeROTextField
            id='agency-name'
            label={t`AgencyName`}
            defaultValue={Name}
            showDivider
          />
          <SelfServeROTextField
            id='agency-location'
            label={t`StateCityLabel`}
            defaultValue={`${State ?? ''}, ${City ?? ''}`}
            showDivider
          />
          <SelfServeROTextField
            id='agency-email'
            label={t`Email`}
            defaultValue={Email}
            showDivider
          />
          <Box className='flex-row'>
            <SelfServeROTextField
              id='agency-dashboard-access'
              label={t`HasDashboardAccess`}
              defaultValue={hasDashboardAccess ? t`Yes` : t`No`}
              className={hasDashboardAccess ? 'success' : 'error'}
            />
            <Box className='flex-row space-between'>
              <SelfServeButton
                size='small'
                id='resend-invitation'
                variant='outlined'
                onClick={() =>
                  hasDashboardAccess
                    ? setOpenResendEmailDialog(true)
                    : setOpenResendInvitationDialog(true)}
              >
                <MarkEmailReadOutlinedIcon />
              &nbsp;{' '}
                {hasDashboardAccess
                  ? t`ResendInvitationButton`
                  : t`SendInvitationButton`}
              </SelfServeButton>
              {hasDashboardAccess
                ? (
                  <SelfServeButton
                    size='small'
                    id='reset-password'
                    variant='outlined'
                    onClick={() => setOpenResetPasswordDialog(true)}
                  >
                    <LockResetOutlinedIcon />
                &nbsp; {t`ResetPassword`}
                  </SelfServeButton>
                  )
                : null}
            </Box>
          </Box>
          <Divider />
          <InputLabel size='small' variant='standard' className='small-label'>
            {t`AgencyLogo`}
          </InputLabel>
          <img className='rounded-image-details' src={Url} alt='agency-logo' />
        </Box>
        {renderResendEmailDialog}
        {renderResendInvitationDialog}
        {renderResetPasswordDialog}
      </Box>
      )
    : (
      <></>
      )
}

export default AgenciesDetails
