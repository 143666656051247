import CircularProgress from '@mui/material/CircularProgress'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { uploadImage } from '../commons/components/uploader/utils'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import Box from '@mui/material/Box'
import {
  ReorderList,
  errorDisplay,
  getErrorMessage,
  handleReorderSave,
  validationErrorsDisplay,
  getItemKey,
  isValid
} from '../commons/utils/utils'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import { RoutePath } from '../core/routes/routePaths'
import CampaignCategoryForm from './CampaignCategoriesForm'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { REORDER_KEY_NAME, constants } from '../commons/constants/constants'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../ui/sidebar/slices'
import {
  editCampaignCategory,
  getCampaignCategoryByID,
  getCategories,
  resetCategoryForm
} from './slices'
import ReorderUiPositionWrapperEdit from '../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperEdit'

const CampaignCategoriesEdit: FC<{}> = () => {
  const { agencyID, tenantID, userID, isAgencyEnabled } = useAppSelector(
    (state) => state.auth
  )
  const {
    campaignCategorySelected: {
      CampaignCategoryID,
      IconImageID,
      CategoryName,
      AgencyID,
      Url,
      WebUiPosition
    },
    loading,
    isFulfilled,
    errors,
    categories
  } = useAppSelector((state) => state.campaignCategories)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const [formAgencyID, setFormAgencyID] = useState(AgencyID)
  const [icon, setIcon] = useState<File | undefined>()
  const [categoryName, setCategoryName] = useState(CategoryName)
  const [webUiPosition, setWebUiPosition] = useState(WebUiPosition)
  const [url, setUrl] = useState(Url)

  const [openConfirmEdit, setOpenConfirmEdit] = useState(false)
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsCategoryNameUpdated] = useState<boolean>(false)

  const handleClose = (): void => {
    setOpenConfirmEdit(false)
    setOpenConfirmLeaving(false)
  }

  const editCampaignCategorySubmission = async (): Promise<void> => {
    try {
      const upload = await uploadImage(userID, icon)
      if (CampaignCategoryID != null) {
        void dispatch(
          editCampaignCategory({
            CategoryName: categoryName,
            WebUiPosition: webUiPosition,
            IconImageID: upload.imageID !== 0 ? upload.imageID : IconImageID,
            TenantID: tenantID,
            AgencyID: agencyID ?? formAgencyID,
            CampaignCategoryID,
            UpdatedUIs: updatedUIs
          })
        )
      }
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (agencyID != null || formAgencyID !== '' || !isAgencyEnabled) {
      void dispatch(
        getCategories({
          tenantID,
          agencyID: agencyID ?? formAgencyID
        })
      )
    }
  }, [dispatch, agencyID, formAgencyID, tenantID, isAgencyEnabled])

  useEffect(() => {
    if (isFulfilled && CampaignCategoryID != null) {
      dispatch(resetCategoryForm())
      navigate(`${RoutePath.CampaignCategories}/${CampaignCategoryID}`)
    }
  }, [isFulfilled, navigate, dispatch, CampaignCategoryID])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])

  const onReorderSave = (list: ReorderList): void => {
    handleReorderSave(
      categoryName,
      list,
      setWebUiPosition,
      setOpenUIreorder,
      setUpdatedUIs,
      setListWithNewOrder,
      false,
      false,
      false,
      true,
      CampaignCategoryID
    )
  }

  const handleNameChange = (list: ReorderList): void => {
    const updatedList = list.map((category) => {
      const categoryIDKey = getItemKey(
        category,
        REORDER_KEY_NAME.CategoryIDKeyWord
      )
      if (categoryIDKey != null) {
        const campaignCategoryID =
          category[categoryIDKey as keyof typeof category]
        if (
          campaignCategoryID === CampaignCategoryID ||
          campaignCategoryID == null
        ) {
          category.CategoryName = categoryName
        }
      }
      return category
    })
    setListWithNewOrder(updatedList)
    setIsCategoryNameUpdated(false)
  }

  const renderConfirmEditDialog = (
    <CustomDialog
      title={t`EditCategory`}
      open={openConfirmEdit}
      cancelCallback={handleClose}
      okCallback={editCampaignCategorySubmission}
      mainMessage={t('CategoryEditDialogMessage', { categoryName })}
      okText={t`YesUpdateCategory`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmLeavingDialog = id != null && (
    <CustomDialog
      title={t`DiscardCategoryModification`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() => {
        navigate(`${RoutePath.CampaignCategories}/${id}`)
      }}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  useEffect(() => {
    dispatch(resetCategoryForm())
  }, [dispatch])

  useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignCategoryByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN_CATEGORY))
      dispatch(setBackButtonTitle(t`BackToCategories`))
      dispatch(setBackButtonRoute(RoutePath.CampaignCategories))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const setValueMap = {
    agencyID: setFormAgencyID,
    categoryName: setCategoryName,
    webUiPosition: setWebUiPosition,
    icon: setIcon,
    url: setUrl
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box>
      <CampaignCategoryForm
        isLeavingDialogOpen={openConfirmLeaving || openConfirmEdit}
        edit
        setValueMap={setValueMap}
        onSubmit={() => setOpenConfirmEdit(true)}
        properties={{
          agencyID: formAgencyID ?? '',
          categoryName,
          webUiPosition,
          icon,
          url
        }}
        onDiscard={() => setOpenConfirmLeaving(true)}
        isReorderDisabled={
          (formAgencyID !== '' || agencyID != null || !isAgencyEnabled) &&
          isValid(categoryName)
        }
        onUiReorderClick={() => setOpenUIreorder(true)}
        resetUIs={() => setResetUIs(true)}
        setIsCategoryNameUpdated={() => setIsCategoryNameUpdated(true)}
      />
      {isUIReorderOpen && (
        <ReorderUiPositionWrapperEdit
          isUIReorderOpen={isUIReorderOpen}
          handleClose={() => setOpenUIreorder(false)}
          name={categoryName}
          onReorderSave={onReorderSave}
          currentList={categories}
          listWithNewOrder={listWithNewOrder}
          currentID={CampaignCategoryID}
          handleNameChange={handleNameChange}
          isNameUpdated={isNameUpdated}
        />
      )}
      {errorDisplay(errorMessage)}
      {renderConfirmEditDialog}
      {renderConfirmLeavingDialog}
      {typeof errors === 'string'
        ? errorDisplay(errors)
        : validationErrorsDisplay(errors)}
    </Box>
  )
}

export default CampaignCategoriesEdit
