import {
  Box,
  Divider,
  TextField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControlLabel
} from '@mui/material'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FC, useEffect, useMemo } from 'react'
import '../segments.scss'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import SelfServeButton from '../../ui/button/Button'
import {
  filterNonNumericValues,
  getRoleTypeByString,
  handleNextStep,
  removeDecimalPoints,
  selectMenuItem
} from '../../commons/utils/utils'
import {
  SegmentsFormStepsProps,
  TargetGroupsInterface
} from '../interfaces/segments'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { getAllAgencies } from '../../agencies/slices'
import { userHasTenantPermission } from '../../core/routes/router'
import { useTranslation } from 'react-i18next'
import { EXPIRY_DATE, formValidationConstants } from '../constants'
import FormsTextFieldWithEditor from '../../ui/text-field/FormsTextFieldWithEditor'
import {
  setIsDescription,
  resetIsDescription,
  clearWelcomeDialog,
  clearWelcomePoints
} from '../slices'
import _ from 'lodash'
import SelfServeDatePicker from '../../ui/date-picker/SelfServeDatePicker'
import {
  FORM_DATE_FORMAT,
  FORM_DATE_FORMAT_PLACEHOLDER,
  constants
} from '../../commons/constants/constants'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import IconUpload from '../../ui/icon-upload/IconUpload'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

const SHOW_WELCOME_DIALOG = 'showWelcomeDialog'
const SHOW_WELCOME_POINTS = 'showWelcomePoints'

const SegmentRewardsInformationStep: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  handleNext,
  handleBack,
  onDiscard,
  handleDateChange,
  isEdit = false
}) => {
  const {
    isFormTouched,
    AgencyID,
    Name,
    description,
    expiryDate,
    welcomeDialogTitle,
    welcomeDialogMessage,
    welcomeDialogLabel,
    welcomeDialogImageUrl,
    welcomePointsTitle,
    welcomePoints,
    welcomePointsUrl,
    welcomePointsImageID,
    showWelcomeDialog,
    showWelcomePoints,
    descriptionHTML,
    isDescription,
    agencyIDLoggedIn,
    tenantID,
    userType,
    isAgencyEnabled,
    agencies
  } = useAppSelector((state) => ({
    isFormTouched: state.segments.isFormTouched,
    AgencyID: state.segments.segmentsForm.AgencyID,
    Name: state.segments.segmentsForm.Name,
    description: state.segments.segmentsForm.description,
    expiryDate: state.segments.segmentsForm.expiryDate,
    welcomeDialogTitle: state.segments.segmentsForm.welcomeDialogTitle,
    welcomeDialogMessage: state.segments.segmentsForm.welcomeDialogMessage,
    welcomeDialogLabel: state.segments.segmentsForm.welcomeDialogLabel,
    welcomeDialogImageUrl: state.segments.segmentsForm.welcomeDialogImageUrl,
    welcomePointsTitle: state.segments.segmentsForm.welcomePointsTitle,
    welcomePoints: state.segments.segmentsForm.welcomePoints,
    welcomePointsUrl: state.segments.segmentsForm.welcomePointsUrl,
    welcomePointsImageID: state.segments.segmentsForm.welcomePointsImageID,
    showWelcomeDialog: state.segments.segmentsForm.showWelcomeDialog,
    showWelcomePoints: state.segments.segmentsForm.showWelcomePoints,
    descriptionHTML: state.segments.descriptionHTML,
    isDescription: state.segments.isDescription,
    agencyIDLoggedIn: state.auth.agencyID,
    tenantID: state.auth.tenantID,
    userType: state.auth.userType,
    isAgencyEnabled: state.auth.isAgencyEnabled,
    agencies: state.agencies.agencies
  }))

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const role = getRoleTypeByString(userType)

  useEffect(() => {
    void dispatch(
      getAllAgencies({
        tenantID
      })
    )
  }, [tenantID, dispatch])

  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      AgencyID: agencyIDLoggedIn ?? AgencyID,
      Name,
      description,
      welcomeDialogTitle,
      welcomeDialogMessage,
      welcomeDialogLabel,
      welcomeDialogImageUrl,
      welcomePointsTitle,
      welcomePoints,
      welcomePointsImageID
    }
  })

  const setFile = (
    data: File | undefined,
    onChange: (data: File | undefined, name: string) => void,
    name: string
  ): void => {
    onChange(data, name)
    data != null
      ? onFormChange(
        URL.createObjectURL(data),
        'welcomePointsUrl' as keyof TargetGroupsInterface
      )
      : onFormChange(
        constants.EMPTY_STRING,
        'welcomePointsUrl' as keyof TargetGroupsInterface
      )
    onFormChange(data, name as keyof TargetGroupsInterface)
  }

  const agenciesSelectItems = useMemo(
    () =>
      agencies.map((item) => (
        <MenuItem key={item.AgencyID} value={item.AgencyID}>
          {item.Name}
        </MenuItem>
      )),
    [agencies]
  )

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        handleNext != null &&
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper mt-1'
    >
      {userHasTenantPermission(role) && isAgencyEnabled && (
        <FormFieldBox
          title={t`Agency`}
          rules={{
            required: formValidationConstants.requiredField,
            validate: {
              notEmpty: () => {
                return !_.isEmpty(AgencyID)
              }
            }
          }}
          name='AgencyID'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel shrink={false} id='agency-label'>
                {AgencyID === '' && t`SelectAgency`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value, name)
                }}
                value={AgencyID}
                menuItem={selectMenuItem(t`None`)}
                selectItems={agenciesSelectItems}
                name={name}
                inputProps='custom-form-select'
                isFormSelect
              />
            </>
          )}
          control={control}
          errorField={errors.AgencyID}
        />
      )}
      <FormFieldBox
        title={t`Name`}
        rules={{
          required: formValidationConstants.requiredField,
          minLength: formValidationConstants.minLengthError,
          maxLength: formValidationConstants.maxLengthError
        }}
        name='Name'
        render={({ field: { name, onChange } }) => (
          <TextField
            inputProps={{ 'data-testid': 'nameField' }}
            name='name'
            id='name'
            placeholder={t`SegmentsMeaningfulNameBreif`}
            onChange={(e) => {
              onFormChange(e.target?.value, name as keyof TargetGroupsInterface)
              onChange(e.target?.value)
            }}
            value={Name}
          />
        )}
        control={control}
        errorField={errors.Name}
      />
      <FormFieldBox
        title={t`Description`}
        rules={{
          required: formValidationConstants.requiredField,
          minLength: {
            value: 3,
            message: formValidationConstants.descriptionLengthError
          },
          maxLength: {
            value: 1000,
            message: formValidationConstants.descriptionLengthError
          }
        }}
        name='description'
        render={({ field: { name, onChange } }) => (
          <FormsTextFieldWithEditor
            onChange={(value: string) => {
              onFormChange(value, name as keyof TargetGroupsInterface)
              onChange(value)
            }}
            valueMarkdown={description}
            valueHTML={descriptionHTML}
            isChanged={isDescription}
            placeholder={t`SegmentsDescribeInFewWords`}
            setIsChanged={setIsDescription}
            resetIsChanged={resetIsDescription}
          />
        )}
        control={control}
        errorField={errors.description}
      />
      <FormHelperText>{t`ExpiryDate`}</FormHelperText>
      <SelfServeDatePicker
        onChange={(date) => {
          if (handleDateChange != null) {
            handleDateChange(date, EXPIRY_DATE as keyof TargetGroupsInterface)
          }
        }}
        placeholder={FORM_DATE_FORMAT_PLACEHOLDER}
        isFromEdit={isEdit}
        dateTime={expiryDate as string}
        dateFormat={FORM_DATE_FORMAT}
        disabled={false}
      />
      <Divider className='mt-2' />
      <FormHelperText className='mt-1'>{t`ShowWelcomeDialog`}</FormHelperText>
      <FormControlLabel
        className='mb-1 switch-label'
        name='welcomeDialog'
        control={
          <CustomSwitch
            size='medium'
            onClick={() => {
              if (showWelcomeDialog === true) {
                dispatch(clearWelcomeDialog())
              }
              showWelcomeDialog != null &&
                onFormChange(!showWelcomeDialog, SHOW_WELCOME_DIALOG)
            }}
            checked={showWelcomeDialog}
          />
        }
        label={t`ShowWelcomeDialogText`}
      />
      {showWelcomeDialog === true && (
        <>
          <FormFieldBox
            title={t`DialogImage`}
            rules={{}}
            name='welcomeDialogImageFile'
            render={({ field: { name, onChange } }) => (
              <ImageDropZone
                isImage
                url={welcomeDialogImageUrl}
                name={name}
                setFile={(data: File | undefined) => {
                  onChange(data, name)
                  onFormChange(data, name as keyof TargetGroupsInterface)
                }}
              />
            )}
            control={control}
            errorField={errors.welcomeDialogImageUrl}
          />
          <FormFieldBox
            title={t`DialogTitle`}
            rules={{
              required: formValidationConstants.requiredField,
              minLength: formValidationConstants.minLengthError,
              maxLength: formValidationConstants.maxLengthError
            }}
            name='welcomeDialogTitle'
            render={({ field: { name, onChange } }) => (
              <TextField
                name={name}
                id='welcomeDialogTitle'
                onChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value)
                }}
                value={welcomeDialogTitle}
              />
            )}
            control={control}
            errorField={errors.welcomeDialogTitle}
          />
          <FormFieldBox
            title={t`DialogContent`}
            rules={{
              required: formValidationConstants.requiredField,
              minLength: formValidationConstants.minLengthError,
              maxLength: formValidationConstants.maxLengthContentError
            }}
            name='welcomeDialogMessage'
            render={({ field: { name, onChange } }) => (
              <TextField
                name={name}
                id='welcomeDialogMessage'
                onChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value)
                }}
                value={welcomeDialogMessage}
              />
            )}
            control={control}
            errorField={errors.welcomeDialogMessage}
          />
          <FormFieldBox
            title={t`CTALabel`}
            rules={{
              required: formValidationConstants.requiredField,
              minLength: formValidationConstants.minLengthError,
              maxLength: formValidationConstants.maxLengthContentError
            }}
            name='welcomeDialogLabel'
            render={({ field: { name, onChange } }) => (
              <TextField
                name={name}
                id='welcomeDialogLabel'
                onChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value)
                }}
                value={welcomeDialogLabel}
              />
            )}
            control={control}
            errorField={errors.welcomeDialogLabel}
          />
        </>
      )}
      <Divider className='mt-1 light-divider' />
      <FormHelperText className='mt-2'>{t`GiveWelcomePoints`}</FormHelperText>
      <FormControlLabel
        className='mb-1 switch-label'
        name='welcomePoints'
        control={
          <CustomSwitch
            size='medium'
            onClick={() => {
              if (showWelcomePoints === true) {
                dispatch(clearWelcomePoints())
              }
              showWelcomePoints != null &&
                onFormChange(!showWelcomePoints, SHOW_WELCOME_POINTS)
            }}
            checked={showWelcomePoints}
          />
        }
        label={t`GiveWelcomePointsText`}
      />
      {showWelcomePoints === true && (
        <>
          <FormFieldBox
            title={t`EarningTitle`}
            rules={{
              required: formValidationConstants.requiredField,
              minLength: formValidationConstants.minLengthError,
              maxLength: formValidationConstants.maxLengthError
            }}
            name='welcomePointsTitle'
            render={({ field: { name, onChange } }) => (
              <TextField
                inputProps={{ 'data-testid': 'earningTitle' }}
                name={name}
                id='welcomePointsTitle'
                onChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value)
                }}
                value={welcomePointsTitle}
              />
            )}
            control={control}
            errorField={errors.welcomePointsTitle}
          />
          <FormFieldBox
            title={t`Points`}
            rules={{
              required: formValidationConstants.requiredField
            }}
            name='welcomePoints'
            render={({ field: { name, onChange } }) => (
              <TextField
                inputProps={{ 'data-testid': 'pointsField' }}
                name={name}
                id='welcomePoints'
                onChange={(e) => {
                  onFormChange(
                    filterNonNumericValues(e.target?.value),
                    name as keyof TargetGroupsInterface
                  )
                  onChange(filterNonNumericValues(e.target?.value))
                }}
                value={
                  isEdit
                    ? removeDecimalPoints(welcomePoints) ?? ''
                    : filterNonNumericValues(welcomePoints ?? '')
                }
              />
            )}
            control={control}
            errorField={errors.welcomePoints}
          />
          <FormFieldBox
            title={t`EarningIcon`}
            rules={{}}
            name='welcomePointsImageFile'
            render={({ field: { name, onChange } }) => (
              <IconUpload
                url={welcomePointsUrl}
                name={name}
                setFile={(data: File | undefined) =>
                  setFile(data, onChange, name)}
                onFormChange={(e) => onChange(e)}
              />
            )}
            control={control}
            errorField={errors.welcomePointsImageID}
          />
        </>
      )}
      <Divider className='mt-2' />
      <Box className='mt-1 space-between'>
        <Box>
          {!isEdit && (
            <SelfServeButton
              id='segments-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
          )}
          <SelfServeButton
            id='segments-create-next'
            dataTestId='nextButtonInfo'
            type='submit'
            variant='contained'
            disabled={!isDirty && !isFormTouched && !isEdit}
          >
            {t`Next`}
          </SelfServeButton>
        </Box>
        <SelfServeButton
          id='segments-create-discard'
          onClick={onDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Form>
  )
}

export default SegmentRewardsInformationStep
