import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { campaignsService } from './services'
import { CampaignsStatuses, campaignsConstants } from './constants'
import { CampaignState } from '../../type'
import {
  CampaignFormInterface,
  CampaignListParams,
  CampaignTargetGroup,
  CampaignsInterface,
  CampaignsPayloadInterface,
  ProviderListParams
} from './interfaces/campaigns'
import { handleRejectWithReport } from '../core/error/handler'
import { ErrorStatus, ImageKeyName } from './constants/CommonConstants'
import _ from 'lodash'
import { convertBadgesFormat, convertProvidersToArray } from './utils'
import {
  createImageID,
  handleSliceErrors,
  removeDecimalPoints,
  setFileInfo
} from '../commons/utils/utils'

export const getCampaignPreviewByID = createAsyncThunk(
  'campaigns/getCampaignPreviewByID',
  async (id: number, { rejectWithValue }) => {
    try {
      return await campaignsService.getCampaignPreviewByID(id.toString())
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getCampaignByID = createAsyncThunk(
  'campaigns/getCampaignByID',
  async (id: string, { rejectWithValue }) => {
    try {
      return await campaignsService.getCampaignByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getAllProviderList = createAsyncThunk(
  'campaigns/getAllProviderList',
  async ({ TenantID }: ProviderListParams, { rejectWithValue }) => {
    try {
      return await campaignsService.getAllProviderList(TenantID ?? '')
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)
export const getCampaigns = createAsyncThunk(
  'campaigns/getCampaigns',
  async (params: CampaignListParams, { rejectWithValue }) => {
    try {
      return await campaignsService.getCampaigns(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)
export const getFeaturedCampaigns = createAsyncThunk(
  'campaigns/getFeaturedCampaigns',
  async (params: CampaignListParams, { rejectWithValue }) => {
    try {
      return await campaignsService.getFeaturedCampaigns(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getCampaignsForCSV = createAsyncThunk(
  'campaigns/getCampaignsForCSV',
  async (params: CampaignListParams, { rejectWithValue }) => {
    try {
      return await campaignsService.getCampaigns(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const editCampaignStatus = createAsyncThunk(
  'campaigns/editCampaignStatus',
  async ({ CampaignID, status }: CampaignsInterface, { rejectWithValue }) => {
    try {
      if (CampaignID != null && status != null) {
        return await campaignsService.editCampaignStatus(
          CampaignID.toString(),
          status
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const removeCampaignFromTargetGroup = createAsyncThunk(
  'campaigns/removeCampaignFromTargetGroup',
  async (
    { CampaignID, TargetGroupID }: CampaignTargetGroup,
    { rejectWithValue }
  ) => {
    try {
      if (CampaignID != null && TargetGroupID != null) {
        return await campaignsService.removeCampaignFromTargetGroup(
          CampaignID,
          TargetGroupID
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const addCampaignToTargetGroup = createAsyncThunk(
  'campaigns/addCampaignToTargetGroup',
  async (
    { CampaignID, TargetGroupID }: CampaignTargetGroup,
    { rejectWithValue }
  ) => {
    try {
      if (CampaignID != null && TargetGroupID != null) {
        return await campaignsService.addCampaignToTargetGroup(
          CampaignID,
          TargetGroupID
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const excludeCampaignFromTargetGroup = createAsyncThunk(
  'campaigns/excludeCampaignFromTargetGroup',
  async (
    { CampaignID, TargetGroupID }: CampaignTargetGroup,
    { rejectWithValue }
  ) => {
    try {
      if (CampaignID != null && TargetGroupID != null) {
        return await campaignsService.excludeCampaignFromTargetGroup(
          CampaignID,
          TargetGroupID
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)
export const createNewCampaign = createAsyncThunk(
  'campaigns/createNewCampaign',
  async (campaignInfo: CampaignFormInterface, { rejectWithValue }) => {
    try {
      return await campaignsService.createNewCampaign(campaignInfo)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)
export const editCampaign = createAsyncThunk(
  'campaigns/editCampaign',
  async (campaignInfo: CampaignFormInterface, { rejectWithValue }) => {
    try {
      return await campaignsService.editCampaign(
        campaignInfo,
        campaignInfo.campaignID ?? ''
      )
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

const campaignInitialState = {
  liveCampaignSelected: {},
  campaignSelected: {},
  campaigns: [],
  featuredCampaigns: [],
  campaignsForCSV: [],
  totalCampaigns: 0,
  loading: false,
  campaignForm: {
    thumbnailUrl: '',
    thumbnailID: null,
    thumbnailFile: null,
    campaignIconUrl: '',
    iconID: null,
    campaignIconFile: null,
    name: '',
    title: '',
    subHeader1: '',
    subHeader2: '',
    AgencyID: '',
    category: null,
    categoryName: '',
    uiPosition: null,
    isFeatured: false,
    featuredUi: null,
    description: '',
    hasCTA: false,
    ctaName: '',
    ctaLink: '',
    badge: [],
    icon: '',
    label: '',
    startTimestampUTC: null,
    endTimestampUTC: null,
    hasExpiryDate: false,
    pointExpiryDate: null,
    availableSegments: [],
    excludeSegments: [],
    provider: [],
    aggregateMethod: '',
    aggregateValue: '',
    filter: '',
    operator: '',
    filterValue: '',
    pointEarned: null,
    bucketRepetition: null,
    earningCount: null,
    earnUnit: ''
  },
  allProviders: [],
  loadingSegments: false,
  campaignsForCSVLoading: false,
  pointExpiryDate: null,
  isFormTouched: false,
  isFormUpdated: false,
  errors: [],
  errorStatus: ErrorStatus.FAIL,
  isDescription: false,
  descriptionHTML: ''
}
const campaigns = createSlice({
  name: campaignsConstants.Domain,
  initialState: campaignInitialState as CampaignState,
  reducers: {
    modifyCampaignForm: (state, action) => {
      const { name, value } = action.payload as CampaignsPayloadInterface
      state.loading = true
      const currentCampaignForm = { ...action.payload.campaignForm }
      if (name === ImageKeyName.ThumbnailID) {
        currentCampaignForm[name] = createImageID(value, action.payload.value)
        currentCampaignForm.thumbnailFile = setFileInfo(action.payload.value)
      } else if (name === ImageKeyName.IconID) {
        currentCampaignForm[name] = createImageID(value, action.payload.value)
        currentCampaignForm.campaignIconFile = setFileInfo(action.payload.value)
      } else {
        currentCampaignForm[name] = action.payload.value
      }
      state.campaignForm = currentCampaignForm
      state.isFormTouched = true
      state.loading = false
    },
    setCampaignForm: (state) => {
      const {
        Title,
        Name,
        UiPosition,
        AgencyID,
        CategoryID,
        FeaturedUiPosition,
        WebUiPosition,
        Url,
        CategoryName,
        CampaignIcon,
        RewardPerUser,
        MaxBucketClaimsRepetition,
        SubHeader,
        SubTitle,
        Description,
        ProviderSignupButtonLabel,
        SignupURL,
        EndTimestampUTC,
        StartTimestampUTC,
        PointExpiryDate,
        providers,
        badges,
        campaignLimit,
        transactionalAggregates,
        transactionalFilter
      } = state.campaignSelected
      state.campaignForm = {
        thumbnailUrl: '',
        thumbnailID: Url,
        campaignIconUrl: '',
        iconID: CampaignIcon,
        campaignIconFile: null,
        name: Name,
        title: Title,
        subHeader1: SubHeader,
        subHeader2: SubTitle,
        AgencyID,
        category: CategoryID != null ? parseInt(CategoryID) : undefined,
        categoryName: CategoryName,
        uiPosition: WebUiPosition ?? UiPosition,
        isFeatured: FeaturedUiPosition != null,
        featuredUi: FeaturedUiPosition,
        description: Description,
        hasCTA: !_.isEmpty(ProviderSignupButtonLabel) || !_.isEmpty(SignupURL),
        ctaName: ProviderSignupButtonLabel,
        ctaLink: SignupURL,
        badge: convertBadgesFormat(badges),
        icon: '',
        label: '',
        startTimestampUTC: StartTimestampUTC,
        endTimestampUTC: EndTimestampUTC,
        hasExpiryDate: PointExpiryDate != null,
        pointExpiryDate: PointExpiryDate,
        availableSegments: [],
        excludeSegments: [],
        provider: convertProvidersToArray(providers),
        aggregateMethod: transactionalAggregates?.Category,
        aggregateValue: transactionalAggregates?.Value,
        filter:
          transactionalFilter != null && !_.isEmpty(transactionalFilter)
            ? transactionalFilter[0].Category
            : '',
        operator:
          transactionalFilter != null && !_.isEmpty(transactionalFilter)
            ? transactionalFilter[0].Operator
            : '',
        filterValue:
          transactionalFilter != null && !_.isEmpty(transactionalFilter)
            ? transactionalFilter[0].Value
            : '',
        pointEarned: removeDecimalPoints(RewardPerUser),
        bucketRepetition: MaxBucketClaimsRepetition,
        earningCount: removeDecimalPoints(campaignLimit?.Count),
        earnUnit: campaignLimit?.Unit ?? ''
      }
      state.isFormUpdated = true
      state.isDescription = true
      state.descriptionHTML = Description ?? ''
    },
    resetCampaignForm: (state) => {
      state.loading = true
      state.campaignForm = campaignInitialState.campaignForm
      state.isFormTouched = false
      state.isFormUpdated = false
      state.loading = false
      state.errors = []
      state.errorStatus = ErrorStatus.FAIL
      state.isDescription = false
      state.descriptionHTML = ''
    },
    cleanCampaignsForCSV: (state) => {
      state.campaignsForCSV = []
      state.campaignsForCSVLoading = false
    },
    setIsDescription: (state, action) => {
      state.isDescription = true
      if (action.payload != null) {
        state.descriptionHTML = action?.payload
      }
    },
    resetIsDescription: (state) => {
      state.isDescription = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignPreviewByID.fulfilled, (state, action) => {
        state.liveCampaignSelected = action.payload
        state.loading = false
      })
      .addCase(getCampaignPreviewByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignPreviewByID.rejected, (state, action) => {
        state.liveCampaignSelected = {}
        state.loading = false
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.campaigns
        state.totalCampaigns = action.payload.count
        state.loading = false
      })
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaigns.rejected, (state) => {
        state.campaigns = []
        state.loading = false
        state.totalCampaigns = 0
      })
      .addCase(getCampaignsForCSV.fulfilled, (state, action) => {
        state.campaignsForCSV = action.payload.campaigns
        state.campaignsForCSVLoading = false
      })
      .addCase(getCampaignsForCSV.pending, (state) => {
        state.campaignsForCSVLoading = true
      })
      .addCase(getCampaignsForCSV.rejected, (state) => {
        state.campaignsForCSV = []
        state.campaignsForCSVLoading = false
      })
      .addCase(getCampaignByID.fulfilled, (state, action) => {
        state.campaignSelected = action.payload
        state.loading = false
      })
      .addCase(getCampaignByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignByID.rejected, (state) => {
        state.campaignSelected = {}
        state.loading = false
      })
      .addCase(getAllProviderList.fulfilled, (state, action) => {
        state.allProviders = action.payload
        state.loading = false
      })
      .addCase(getAllProviderList.rejected, (state) => {
        state.allProviders = []
      })
      .addCase(editCampaignStatus.fulfilled, (state, action) => {
        state.campaignSelected.status =
          action.payload.Hidden === false
            ? CampaignsStatuses.LIVE_ACTIVE
            : CampaignsStatuses.HIDDEN_INACTIVE
      })
      .addCase(removeCampaignFromTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(removeCampaignFromTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(removeCampaignFromTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(addCampaignToTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(addCampaignToTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(addCampaignToTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(excludeCampaignFromTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(excludeCampaignFromTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(excludeCampaignFromTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(createNewCampaign.fulfilled, (state, action) => {
        state.campaignSelected.CampaignID = action.payload.CampaignID
        state.errors = []
        state.loading = false
        state.errorStatus = ErrorStatus.PASS
      })
      .addCase(createNewCampaign.pending, (state) => {
        state.errors = []
        state.loading = true
        state.errorStatus = ErrorStatus.FAIL
      })
      .addCase(createNewCampaign.rejected, (state, action) => {
        if (_.isString(action.payload)) {
          state.errors.push(action.payload)
        } else {
          state.errors = action.payload as string[]
        }
        state.loading = false
        state.errorStatus = ErrorStatus.FAIL
      })
      .addCase(editCampaign.fulfilled, (state, action) => {
        state.campaignSelected = action.payload
        state.errors = []
        state.loading = false
        state.errorStatus = ErrorStatus.PASS
      })
      .addCase(editCampaign.pending, (state) => {
        state.errors = []
        state.loading = true
        state.errorStatus = ErrorStatus.FAIL
      })
      .addCase(editCampaign.rejected, (state, action) => {
        if (_.isString(action.payload)) {
          state.errors.push(action.payload)
        } else {
          state.errors = action.payload as string[]
        }
        state.loading = false
        state.errorStatus = ErrorStatus.FAIL
      })
      .addCase(getFeaturedCampaigns.fulfilled, (state, action) => {
        state.featuredCampaigns = action.payload
        state.loading = false
      })
      .addCase(getFeaturedCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getFeaturedCampaigns.rejected, (state) => {
        state.featuredCampaigns = []
        state.loading = false
      })
  }
})

export const { cleanCampaignsForCSV } = campaigns.actions
export const campaignsReducer = campaigns.reducer
export const {
  modifyCampaignForm,
  resetCampaignForm,
  setCampaignForm,
  setIsDescription,
  resetIsDescription
} = campaigns.actions
