import {
  FormControl,
  Box,
  Divider,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material'
import { Form } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { FC, ReactElement, useState } from 'react'
import '../segments.scss'
import { useAppSelector } from '../../core/redux/hooks'
import SelfServeButton from '../../ui/button/Button'
import {
  filterDuplicateValues,
  filterNonNumericValues,
  filterSpecialCharacters,
  generateRandomCodes,
  handleSubmit,
  replaceWhitespaceWithComma
} from '../../commons/utils/utils'
import {
  SegmentsFormStepsProps,
  TargetGroupsInterface
} from '../interfaces/segments'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { useTranslation } from 'react-i18next'
import {
  GenerationMethodType,
  INVITE_CODES,
  INVITE_CODES_FILE,
  NUM_OF_CODES_MAX_VALUE,
  NUM_OF_CODES_MIN_VALUE,
  SELECT_LENGTH,
  formValidationConstants
} from '../constants'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import _ from 'lodash'

const LENGTH_OPTIONS = [4, 5, 6]

const SegmentCodesInformationStep: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  onSubmit,
  handleBack,
  onDiscard
}) => {
  const {
    isFormTouched,
    segmentsForm: {
      timesCanBeUsed,
      inviteCodes,
      areCodesFromCSV,
      generationMethod,
      codesFile,
      codesFileID
    }
  } = useAppSelector((state) => state.segments)
  const { t } = useTranslation()
  const [radioButtonOption, setRadioButtonOption] = useState<string>('')
  const [prefix, setPrefix] = useState<string>('')
  const [excludedChars, setExcludedChars] = useState<string[]>([])
  const [length, setLength] = useState<string>(t`SelectLength`)
  const [numberOfCodes, setNumberOfCodes] = useState<string>('')

  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      timesCanBeUsed,
      generationMethod,
      prefix,
      length,
      excludedChars,
      numberOfCodes,
      inviteCodes,
      codesFile
    }
  })

  const renderSwitch = (): ReactElement | null => {
    const value = !_.isEmpty(generationMethod)
      ? generationMethod
      : radioButtonOption
    switch (value) {
      case GenerationMethodType.LIST_OF_CODES:
        return (
          <FormControl>
            <FormFieldBox
              title={t`AddCodesToSegment`}
              rules={{
                required: formValidationConstants.requiredField
              }}
              name={INVITE_CODES}
              render={({ field: { name, onChange } }) => (
                <TextField
                  inputProps={{ 'data-testid': 'codeField' }}
                  placeholder={t`EnterCodesCommaSeparated`}
                  className='code-box mb-1'
                  name={name}
                  id='codes'
                  multiline
                  minRows={2}
                  onChange={(e) => {
                    onChange(e.target.value)
                    onFormChange(
                      replaceWhitespaceWithComma(e.target.value).split(','),
                      INVITE_CODES
                    )
                  }}
                  value={inviteCodes}
                />
              )}
              control={control}
              errorField={errors.inviteCodes}
            />
            <FormHelperText className='fw-400'>
              {t`DuplicateCodeCSVFile`}
            </FormHelperText>
          </FormControl>
        )
      case GenerationMethodType.CSV_CODES:
        return (
          <div>
            <FormFieldBox
              title={t`AddCodesToSegment`}
              rules={{
                validate: {
                  required: () => {
                    return !_.isEmpty(codesFile)
                  }
                }
              }}
              name={INVITE_CODES}
              render={({ field: { name, onChange } }) => (
                <ImageDropZone
                  isImage={false}
                  isThumbnail
                  name={name}
                  setFile={(data: File | undefined) => {
                    onFormChange(data, INVITE_CODES_FILE)
                    onChange(data)
                  }}
                  thumbnailID={codesFileID}
                  file={codesFile}
                />
              )}
              control={control}
              errorField={errors.inviteCodes}
            />
            <FormHelperText error>
              {errors?.inviteCodes != null &&
                _.isEmpty(codesFile) &&
                t`RequiredField`}
            </FormHelperText>
          </div>
        )
      case GenerationMethodType.AUTOMATIC_CODES:
        return (
          <Box className='automatic-codes'>
            <Typography variant='subtitle1' className='mt-1'>
              {t`Prefix`}
            </Typography>
            <TextField
              fullWidth
              name='prefix'
              placeholder={t`EnterCodePrefix`}
              id='prefix'
              onChange={(e) => {
                const value = filterSpecialCharacters(e.target?.value)
                setPrefix(value)
                onFormChange(value, 'prefix')
              }}
              value={prefix}
            />
            <Typography variant='subtitle1' className='mt-1'>
              {t`LengthOfTheCode`}
            </Typography>
            <FormControl fullWidth>
              <Controller
                name='length'
                control={control}
                rules={{
                  validate: {
                    required: () => {
                      return length !== SELECT_LENGTH
                    }
                  }
                }}
                render={({ field: { name, onChange } }) => (
                  <Select
                    className='mb-1'
                    size='small'
                    IconComponent={ExpandMoreOutlinedIcon}
                    id='length'
                    value={length}
                    onChange={(e) => {
                      onChange(e.target?.value)
                      setLength(e.target?.value)
                      onFormChange(e.target?.value, name)
                    }}
                  >
                    <MenuItem disabled value={t`SelectLength`}>
                      {t`SelectLength`}
                    </MenuItem>
                    {LENGTH_OPTIONS.map((v) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText error className='mt-minus1'>
                {errors?.length != null &&
                  length === SELECT_LENGTH &&
                  t`RequiredField`}
              </FormHelperText>
            </FormControl>
            <Typography variant='subtitle1' className='mt-1'>
              {t`CharactersToExclude`}
            </Typography>
            <TextField
              fullWidth
              placeholder={t`EnterCharacters`}
              id='excludedChars'
              name='excludedChars'
              onChange={(e) => {
                const value = filterDuplicateValues(
                  filterSpecialCharacters(e.target?.value)
                )
                setExcludedChars(replaceWhitespaceWithComma(value).split(','))
                onFormChange(
                  replaceWhitespaceWithComma(value),
                  'excludedCharacters'
                )
              }}
              value={excludedChars}
            />
            <FormFieldBox
              title={t`NumberOfCodesToGenerate`}
              rules={{
                required: formValidationConstants.requiredField,
                min: {
                  value: NUM_OF_CODES_MIN_VALUE,
                  message: t`NumOfCodesValueError`
                },
                max: {
                  value: NUM_OF_CODES_MAX_VALUE,
                  message: t`NumOfCodesValueError`
                }
              }}
              name='numberOfCodes'
              render={({ field: { name, onChange } }) => (
                <TextField
                  placeholder={t`NumberOfCodes`}
                  name={name}
                  id='numberOfCodes'
                  onChange={(e) => {
                    setNumberOfCodes(filterNonNumericValues(e.target?.value))
                    onChange(e.target?.value)
                  }}
                  value={numberOfCodes}
                />
              )}
              control={control}
              errorField={errors.numberOfCodes}
            />
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        radioButtonOption === GenerationMethodType.AUTOMATIC_CODES &&
          length !== SELECT_LENGTH &&
          onFormChange(
            generateRandomCodes(
              prefix,
              parseInt(length),
              parseInt(numberOfCodes),
              excludedChars
            ),
            INVITE_CODES
          )
        onSubmit != null &&
          handleSubmit(event, trigger, onSubmit).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper mt-1'
    >
      <Typography variant='subtitle1'>{t`TimesCanBeUsed`}</Typography>
      <FormFieldBox
        title=''
        rules={{
          required: formValidationConstants.requiredField,
          min: formValidationConstants.minNum,
          max: formValidationConstants.maxNum
        }}
        name='timesCanBeUsed'
        render={({ field: { name, onChange } }) => (
          <TextField
            inputProps={{ 'data-testid': 'usageLimitField' }}
            name={name}
            id='timesCanBeUsed'
            placeholder={t`EnterUsageLimit`}
            onChange={(e) => {
              onFormChange(
                filterNonNumericValues(e.target?.value),
                name as keyof TargetGroupsInterface
              )
              onChange(filterNonNumericValues(e.target?.value))
            }}
            value={timesCanBeUsed}
          />
        )}
        control={control}
        errorField={errors.timesCanBeUsed}
      />
      <Typography variant='subtitle1' className='mt-1'>
        {t`SegmentsGenerateCode`}
      </Typography>
      <FormControl fullWidth>
        <Controller
          rules={{
            validate: {
              required: () => {
                return !_.isEmpty(generationMethod)
              }
            }
          }}
          render={({ field: { name, onChange } }) => (
            <RadioGroup
              aria-labelledby='codes-csv-radios'
              name='areCodesFromCSV'
              className='mb-1'
              value={areCodesFromCSV}
              onChange={(e) => {
                onFormChange(
                  e.target?.value === GenerationMethodType.CSV_CODES,
                  'areCodesFromCSV'
                )
                onChange(e.target?.value)
                onFormChange(e.target?.value, name)
                setRadioButtonOption(e.target?.value)
              }}
            >
              <FormControlLabel
                value={GenerationMethodType.LIST_OF_CODES}
                control={<Radio />}
                label={t`PasteListOfCodes`}
                checked={
                  radioButtonOption === GenerationMethodType.LIST_OF_CODES ||
                  generationMethod === GenerationMethodType.LIST_OF_CODES
                }
              />
              <FormControlLabel
                value={GenerationMethodType.CSV_CODES}
                control={<Radio />}
                label={t`CodesIDCSVFile`}
                checked={
                  radioButtonOption === GenerationMethodType.CSV_CODES ||
                  generationMethod === GenerationMethodType.CSV_CODES
                }
              />
              <FormControlLabel
                value={GenerationMethodType.AUTOMATIC_CODES}
                control={<Radio />}
                label={t`CodesAutomaticGeneration`}
                checked={
                  radioButtonOption === GenerationMethodType.AUTOMATIC_CODES ||
                  generationMethod === GenerationMethodType.AUTOMATIC_CODES
                }
              />
            </RadioGroup>
          )}
          name='generationMethod'
          control={control}
        />
        <FormHelperText error>
          {errors?.generationMethod != null &&
            _.isEmpty(generationMethod) &&
            t`PleaseSelectAtLeastOneOption`}
        </FormHelperText>
      </FormControl>
      {renderSwitch()}
      <Divider className='mt-2' />
      <Box className='mt-1 space-between'>
        <Box>
          <SelfServeButton
            id='segments-create-previous'
            variant='contained'
            onClick={handleBack}
            color='secondary'
            className='mr-1'
          >
            {t`Previous`}
          </SelfServeButton>
          <SelfServeButton
            id='segments-create-next'
            type='submit'
            variant='contained'
            disabled={!isDirty && !isFormTouched}
          >
            {t`CreateSegment`}
          </SelfServeButton>
        </Box>
        <SelfServeButton
          id='segments-create-discard'
          onClick={onDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Form>
  )
}

export default SegmentCodesInformationStep
