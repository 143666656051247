import { ReactElement, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import './offers-sidebar-card.scss'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { OffersStatuses } from '../constants'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import { t } from 'i18next'
import { convertStringToDateAndTime } from '../../commons/utils/utils'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { editOfferStatus } from '../slices'

const OffersSidebarCard = (): ReactElement => {
  const {
    offerSelected: {
      WebTitle,
      Title,
      OfferID,
      StartTimestampUTC,
      EndTimestampUTC,
      OfferType,
      offerstatus,
      Url
    }
  } = useAppSelector((state) => state.offers)
  const { date: startDate, time: startTime } =
    convertStringToDateAndTime(StartTimestampUTC)
  const { date: endDate, time: endTime } =
    convertStringToDateAndTime(EndTimestampUTC)

  const [openEditStatusDialog, setOpenEditStatusDialog] = useState(false)
  const [isStatusLive, setIsStatusLive] = useState(
    offerstatus != null && offerstatus === OffersStatuses.LIVE
  )
  const dispatch = useAppDispatch()
  const handleClose = (): void => {
    setOpenEditStatusDialog(false)
  }

  useEffect(
    () =>
      setIsStatusLive(
        offerstatus != null && offerstatus === OffersStatuses.LIVE
      ),
    [offerstatus]
  )

  const handleChangeStatus = (): void => {
    setOpenEditStatusDialog(false)
    if (OfferID != null) {
      void dispatch(
        editOfferStatus({
          OfferID,
          offerstatus: isStatusLive
            ? OffersStatuses.HIDDEN
            : OffersStatuses.LIVE
        })
      )
    } else {
      reportError(t`NoOfferIDError`, ErrorLevels.Error)
    }
  }

  const mainMessage = (
    <>
      {t`OfferDialogMessageStart`}
      <b>"{WebTitle != null ? WebTitle : Title}"</b>
      {t`To`}
      <b>{isStatusLive ? t`Inactive` : t`Active`}</b>
      {isStatusLive ? t`InactiveOffer` : t`ActiveOffer`}
    </>
  )

  const renderWarningDialog = (
    <CustomDialog
      title={
        isStatusLive
          ? t`OfferDialogMessageTitleInactive`
          : t`OfferDialogMessageTitleActive`
      }
      open={openEditStatusDialog}
      cancelCallback={handleClose}
      okCallback={handleChangeStatus}
      mainMessage={mainMessage}
      okText={
        isStatusLive ? t`DialogMessageOkInactive` : t`DialogMessageOkActive`
      }
      cancelText={t`Cancel`}
    />
  )

  const cardSubtitles = (subTitle: string): ReactElement => (
    <Typography className='options' variant='subtitle2'>
      {subTitle}:
    </Typography>
  )

  const cardValues = (value: number | string | undefined): ReactElement => (
    <Typography className='sidebar-card-text' variant='subtitle2'>
      {value}
    </Typography>
  )

  return (
    <Box margin='2.5em 2.5em' className='offer-sidebar-card'>
      <img className='sidebar-preview-image' src={Url} alt='offer' />
      <Typography className='mb-02 mt-01' variant='h5'>
        {WebTitle != null ? WebTitle : Title}
      </Typography>
      <Box className='sidebar-card-detail'>
        <TagOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardValues(OfferID)}
      </Box>
      <Box className='sidebar-card-detail'>
        <EventAvailableOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardSubtitles(t`Start`)}
        {cardValues(`${startDate ?? ''} | ${startTime ?? ''}`)}
      </Box>
      <Box className='sidebar-card-detail'>
        <EventBusyOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardSubtitles(t`End`)}
        {cardValues(`${endDate ?? ''} | ${endTime ?? ''}`)}
      </Box>
      <Box className='sidebar-card-detail'>
        <InfoOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardSubtitles(t`OfferType`)}
        {cardValues(OfferType)}
      </Box>
      <Typography
        variant='subtitle1'
        className={
          isStatusLive
            ? 'offer-status-title success'
            : offerstatus === OffersStatuses.EXPIRED
              ? 'offer-status-title danger'
              : 'offer-status-title warning'
        }
      >
        <CustomSwitch
          color={offerstatus === OffersStatuses.EXPIRED ? 'error' : 'secondary'}
          disabled={offerstatus === OffersStatuses.EXPIRED}
          checked={isStatusLive}
          onClick={() => setOpenEditStatusDialog(true)}
        />
        &nbsp;
        {isStatusLive
          ? t`Active`
          : offerstatus === OffersStatuses.EXPIRED
            ? t`Expired`
            : t`Inactive`}
      </Typography>
      {renderWarningDialog}
    </Box>
  )
}

export default OffersSidebarCard
