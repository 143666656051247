import { MAX_CACHE_AGE } from './constants'
import { ImageUploadParamResponse, uploaderService } from './service'

interface UploadImageResponse {
  url: string
  imageID: number
}

const BYTE_CONVERSION_BASE = 1024

export const getSize = (size: number): string => {
  const sizes = [
    ' Bytes',
    ' KB',
    ' MB',
    ' GB',
    ' TB',
    ' PB',
    ' EB',
    ' ZB',
    ' YB'
  ]

  for (let i = 1; i < sizes.length; i++) {
    if (size < Math.pow(BYTE_CONVERSION_BASE, i)) {
      return `${
        Math.round((size / Math.pow(BYTE_CONVERSION_BASE, i - 1)) * 100) / 100
      } ${sizes[i - 1]}`
    }
  }
  return size.toString()
}

export const uploadImage = async (
  userID: string,
  file?: File
): Promise<UploadImageResponse> => {
  if (file != null) {
    const imageUploadParams: ImageUploadParamResponse =
      await uploaderService.imageUploadParams({
        userID,
        fileType: file.type,
        maxCacheAge: MAX_CACHE_AGE,
        prefix: 'admin'
      })
    const formData = new FormData()

    Object.entries(imageUploadParams.params).forEach(([k, v]) => {
      formData.append(k, v as string)
    })

    formData.append('file', file)

    const awsS3Response = await fetch(imageUploadParams.endpointUrl, {
      method: 'POST',
      body: formData
    })

    if (!awsS3Response.ok) {
      throw new Error(awsS3Response.statusText)
    }

    return {
      url: `${imageUploadParams.endpointUrl ?? ''}/${
        imageUploadParams.params.key ?? ''
      }`,
      imageID: imageUploadParams.imageId
    }
  } else {
    return {
      url: '',
      imageID: 0
    }
  }
}
