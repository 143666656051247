import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { RepeatableCouponsAddedInterface } from '../interfaces/offers'
import SelfServeButton from '../../ui/button/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import CouponCodesBox from './CouponCodesBox'
import {
  getDuplicatesCountFromList,
  getIntersectionCountFromLists,
  getValidFilteredCouponsWithLimits
} from '../utils'
import { addRepeatableCouponsByOfferID } from '../slices'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from '../../core/routes/routePaths'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import './OffersCoupons.scss'
import { constants } from '../../commons/constants/constants'
import {
  errorDisplay,
  filterNonNumericValues,
  removeWhitespace
} from '../../commons/utils/utils'

const RepeatableCouponsCreate = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [couponsAdded, setCouponsAdded] = useState<string[]>([''])
  const [couponsWithLimit, setCouponsWithLimit] = useState<
  RepeatableCouponsAddedInterface[]
  >([])
  const [couponInput, setCouponInput] = useState<string>('')
  const [limitInput, setLimitInput] = useState<string>('')
  const [openConfirmValidatedCodes, setOpenConfirmValidatedCodes] =
    useState<boolean>(false)
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [openConfirmAddCodes, setOpenConfirmAddCodes] = useState<boolean>(false)
  const [totalCoupons, setTotalCoupons] = useState<number>(0)
  const [duplicateCoupons, setDuplicateCoupons] = useState<number>(0)
  const [alreadyAddedCoupons, setAlreadyAddedCoupons] = useState<number>(0)
  const [totalValidCoupons, setTotalValidCoupons] = useState<number>(0)
  const [filteredCoupons, setFilteredCoupons] = useState<
  RepeatableCouponsAddedInterface[]
  >([])
  const [errorMessage, setErrorMessage] = useState<string>('')

  const {
    offerSelected: { Coupons, Title, OfferID },
    loading,
    couponsUpdated
  } = useAppSelector((state) => state.offers)

  const handleCouponChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => setCouponInput(e.target.value)

  const handleLimitChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const sanitizedLimit = filterNonNumericValues(e.target.value)
    setLimitInput(sanitizedLimit)
  }

  const couponTrimmed = removeWhitespace(couponInput)
  const limitTrimmed = removeWhitespace(limitInput)

  const handleAddCouponCode = (): void => {
    if (couponTrimmed !== '') {
      setCouponsWithLimit([
        ...couponsWithLimit,
        {
          code: couponTrimmed,
          limit: limitTrimmed
        }
      ])
      setCouponsAdded([...couponsAdded, couponTrimmed])
      setCouponInput('')
      setLimitInput('')
    }
  }

  const handleDeleteCouponCode = (index: number): void => {
    couponInput !== '' && handleAddCouponCode()
    setCouponsAdded((coupons) => {
      return coupons.filter((_, i) => i !== index + 1)
    })
    setCouponsWithLimit((coupons) => {
      return coupons.filter((_, i) => i !== index)
    })
  }

  const handleClose = (): void => {
    setOpenConfirmValidatedCodes(false)
    setOpenConfirmAddCodes(false)
    setOpenConfirmLeaving(false)
  }

  const handleValidateCouponCodes = (): void => {
    couponInput !== '' && handleAddCouponCode()
    setOpenConfirmValidatedCodes(true)
  }

  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const handleConfirmValidatedCodes = (): void => {
    if (totalValidCoupons === 0) {
      setErrorMessage(t`InvalidCodeError`)
    } else {
      setOpenConfirmAddCodes(true)
    }
    setOpenConfirmValidatedCodes(false)
  }

  const addCouponCodesSubmission = async (): Promise<void> => {
    if (OfferID != null) {
      void dispatch(
        addRepeatableCouponsByOfferID({
          codes: filteredCoupons,
          OfferID: OfferID?.toString()
        })
      )
    }
    handleClose()
  }

  useEffect(() => {
    if (couponsUpdated) {
      navigate(`${RoutePath.Offers}/${OfferID ?? ''}${RoutePath.Coupons}`)
    }
  }, [couponsUpdated, navigate, OfferID])

  const renderConfirmValidatedCodes = (
    <CustomDialog
      title={t`AddCoupons`}
      open={openConfirmValidatedCodes}
      cancelCallback={handleClose}
      okCallback={handleConfirmValidatedCodes}
      mainMessage={
        <CouponCodesBox
          totalCoupons={totalCoupons ?? 0}
          duplicateCoupons={duplicateCoupons}
          alreadyAddedCoupons={alreadyAddedCoupons}
          totalValidCoupons={totalValidCoupons}
        />
      }
      okText={t`AddCodes`}
      cancelText={t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const renderConfirmAddCodes = (
    <CustomDialog
      title={t`AddNewCodes`}
      open={openConfirmAddCodes}
      cancelCallback={handleClose}
      okCallback={addCouponCodesSubmission}
      mainMessage={t('AddCodesMainMessage', { totalValidCoupons, Title })}
      okText={t`AddCodesConfirm`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardCouponsCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() =>
        navigate(`${RoutePath.Offers}/${OfferID ?? ''}${RoutePath.Coupons}`)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const getTotalCouponsCount = useCallback(
    () => setTotalCoupons(() => couponsWithLimit.length),
    [couponsWithLimit]
  )

  const getAlreadyAddedCouponsCount = useCallback(() => {
    const alreadyAddedCouponsCount =
      Coupons != null ? getIntersectionCountFromLists(couponsAdded, Coupons) : 0
    setAlreadyAddedCoupons(alreadyAddedCouponsCount)
  }, [couponsAdded, Coupons])

  const getDuplicateCouponsCount = useCallback(() => {
    const duplicates = getDuplicatesCountFromList(couponsAdded)
    setDuplicateCoupons(duplicates)
  }, [couponsAdded])

  const getTotalValidCouponsCount = useCallback(() => {
    const filteredCouponCodes = getValidFilteredCouponsWithLimits(
      couponsWithLimit,
      Coupons
    )
    setTotalValidCoupons(filteredCouponCodes.length)
  }, [Coupons, couponsWithLimit])

  const setFilteredCouponCodes = useCallback(() => {
    const filteredCouponCodes = getValidFilteredCouponsWithLimits(
      couponsWithLimit,
      Coupons
    )
    setFilteredCoupons(filteredCouponCodes)
  }, [couponsWithLimit, Coupons])

  useEffect(() => {
    getTotalCouponsCount()
    getDuplicateCouponsCount()
    getAlreadyAddedCouponsCount()
    getTotalValidCouponsCount()
    setFilteredCouponCodes()
  }, [
    couponsWithLimit,
    couponsAdded,
    getAlreadyAddedCouponsCount,
    getDuplicateCouponsCount,
    getTotalCouponsCount,
    getTotalValidCouponsCount,
    setFilteredCouponCodes
  ])

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Box className='add-coupon-box'>
        <Box className='icon-row'>
          <VerifiedOutlinedIcon fontSize='small' color='action' />
          <Typography className='offer-id-title'>{t`OfferID`}</Typography>
          <Typography className='offer-id-value'>{OfferID}</Typography>
        </Box>
        <Box className='add-coupon-header'>
          <Typography>{t`CouponCode`}</Typography>
          <Typography>{t`RepeatableLimit`}</Typography>
        </Box>
        {couponsAdded.map((coupon, index) => {
          return (
            <Box className='coupon-input-box' key={`${coupon}-${index}`}>
              <TextField
                value={couponsWithLimit[index]?.code ?? couponInput}
                onChange={handleCouponChange}
                className='repeatable-input'
              />
              <TextField
                value={
                  couponsWithLimit[index]?.limit ??
                  filterNonNumericValues(limitInput)
                }
                onChange={handleLimitChange}
                className='repeatable-input'
              />
              <IconButton
                id='remove-code'
                aria-label='Remove Code'
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => handleDeleteCouponCode(index)}
                className={
                  couponsWithLimit[index] == null && couponTrimmed === ''
                    ? 'hidden-icon'
                    : 'delete-icon'
                }
              >
                <DeleteOutlineOutlinedIcon fontSize='medium' />
              </IconButton>
            </Box>
          )
        })}
      </Box>
      <SelfServeButton
        id='add-coupons'
        variant='contained'
        color='secondary'
        disabled={couponTrimmed === ''}
        onClick={handleAddCouponCode}
        className='mb-2'
      >
        {t`AddNewCode`}
      </SelfServeButton>
      <Divider className='coupons-divider' />
      <Box className='coupon-buttons'>
        <SelfServeButton
          id='validate-coupons'
          variant='contained'
          disabled={!(couponsWithLimit.length > 0 || couponInput !== '')}
          onClick={handleValidateCouponCodes}
        >
          {t`ValidateCouponCodes`}
        </SelfServeButton>
        <SelfServeButton
          id='coupons-create-discard'
          onClick={handleDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
      {errorDisplay(errorMessage)}
      {renderConfirmValidatedCodes}
      {renderConfirmAddCodes}
      {renderConfirmLeavingDialog}
    </Box>
  )
}

export default RepeatableCouponsCreate
