import { FC, ReactElement, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { RoutePath } from '../../core/routes/routePaths'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { useTranslation } from 'react-i18next'
import { getCampaignByID } from '../slices'
import SelfServeStepper from '../../ui/stepper/Stepper'
import CampaignInfoStep from './CampaignInfoStep'
import { Box } from '@mui/material'
import CampaignDetailStep from './CampaignDetailsStep'
import { CampaignsInterface } from '../interfaces/campaigns'
import SelfServeButton from '../../ui/button/Button'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import CampaignTypeStep from './CampaignTypeStep'
import CampaignRewardsStep from './CampaignRewardsStep'
import CampaignAudienceStep from './CampaignAudienceStep'
import CampaignPhonePreviewWrapper from '../campaign-phone-previews/CampaignPhonePreviewWrapper'

export interface CampaignsInterfaceProps {
  campaign: CampaignsInterface
}

const CampaignsDetails: FC<{}> = (): ReactElement | null => {
  const { campaignSelected, loading } = useAppSelector((state) => ({
    campaignSelected: state.campaigns.campaignSelected,
    loading: state.campaigns.loading
  }))

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const STEPS_FOR_CAMPAIGN_STEPPER = [
    t`CardInfo`,
    t`Details`,
    t`CampaignOptions`,
    t`RewardsDistribution`,
    t`Audience`
  ]
  const [activeStep, setActiveStep] = useState<number>(0)

  useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN))
      dispatch(setBackButtonTitle(t`BackToCampaigns`))
      dispatch(setBackButtonRoute(RoutePath.Campaigns))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return (
          <CampaignInfoStep
            data-testid='campaignInfoStep'
            campaign={campaignSelected}
          />
        )
      case 1:
        return (
          <CampaignDetailStep
            data-testid='campaignDetailStep'
            campaign={campaignSelected}
          />
        )
      case 2:
        return (
          <CampaignTypeStep
            data-testid='campaignTypeStep'
            campaign={campaignSelected}
          />
        )
      case 3:
        return (
          <CampaignRewardsStep
            data-testid='campaignRewardStep'
            campaign={campaignSelected}
          />
        )
      case 4:
        return (
          <CampaignAudienceStep
            data-testid='campaignAudeinceStep'
            campaign={campaignSelected}
          />
        )
      default:
        return null
    }
  }

  return !loading
    ? (
      <Box>
        <Box className='flex-row'>
          <Box>
            <h1 className='title'>{t`CampaignDetails`}</h1>
          </Box>
          <Box className='ml-2'>
            <SelfServeButton
              dataTestId='editButton'
              size='small'
              id='edit-campaign-button'
              variant='contained'
              color='secondary'
              onClick={() => navigate(RoutePath.Edit)}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
        </Box>
        <SelfServeStepper
          data-testid='stepper'
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          nonLinear
          steps={STEPS_FOR_CAMPAIGN_STEPPER}
        />
        <Box className='mt-2'>
          <Box className='space-between'>
            <Box width={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
            <CampaignPhonePreviewWrapper isFromCreateEdit={false} />
          </Box>
        </Box>
      </Box>
      )
    : null
}

export default CampaignsDetails
