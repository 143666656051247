import { ReactElement } from 'react'
import { Box, Divider, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterfaceProps } from './CampaignsDetails'
import {
  setDashIfEmpty,
  checkIsNotEmpty,
  dashBox
} from '../../commons/utils/utils'
import '../campaigns.scss'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
const CampaignInfoStep = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()
  const {
    auth: { userType, isAgencyEnabled }
  } = useAppSelector((state) => state)

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          <SelfServeROTextField
            id='name'
            label={t`CampaignName`}
            defaultValue={setDashIfEmpty(campaign.Name)}
            classNameDivider='mb-0'
            showDivider
          />
          <InputLabel size='small' variant='standard' className='small-label'>
            {t`Thumbnail`}
          </InputLabel>
          {checkIsNotEmpty(campaign.Url)
            ? (
              <img
                className='thumbnail mb-1'
                src={campaign.Url}
                alt='thumbnail'
              />
              )
            : (
                dashBox
              )}
          <SelfServeROTextField
            id='title'
            label={t`Title`}
            defaultValue={setDashIfEmpty(campaign.Title)}
            showDivider
          />
          <SelfServeROTextField
            id='subheader'
            label={t`SubHeader1`}
            defaultValue={setDashIfEmpty(campaign.SubHeader)}
            showDivider
          />
          <SelfServeROTextField
            id='subtitle'
            label={t`SubHeader2`}
            defaultValue={setDashIfEmpty(campaign.SubTitle)}
            classNameDivider='mb-0'
            showDivider
          />
          {checkIsNotEmpty(campaign.CampaignIcon) && (
            <>
              <InputLabel
                size='small'
                variant='standard'
                className='small-label'
              >
                {t`CampaignIcon`}
              </InputLabel>
              <img
                className='campaign-icon mb-1'
                src={campaign.CampaignIcon}
                alt='CampaignIcon'
              />
            </>
          )}
          <Divider className='divider' />
          {userType !== RoleType.AGENCY && isAgencyEnabled && (
            <SelfServeROTextField
              id='agencyName'
              label={t`Agency`}
              defaultValue={setDashIfEmpty(campaign.AgencyName)}
              showDivider
            />
          )}
          <SelfServeROTextField
            id='category'
            label={t`Category`}
            defaultValue={setDashIfEmpty(campaign.CategoryName)}
            showDivider
          />
          <SelfServeROTextField
            id='uiPosition'
            label={t`UIPosition`}
            defaultValue={setDashIfEmpty(campaign.WebUiPosition)}
            showDivider
          />
          <SelfServeROTextField
            id='isFeatured'
            label={t`IsFeatured`}
            defaultValue={campaign.FeaturedUiPosition != null ? t`Yes` : t`No`}
            className={
              campaign.FeaturedUiPosition != null ? 'success' : 'error'
            }
            showDivider
          />
          <SelfServeROTextField
            id='featuredUiPosition'
            label={t`FeaturedUIPosition`}
            defaultValue={setDashIfEmpty(
              campaign.FeaturedUiPosition?.toString()
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignInfoStep
