import { FC, useCallback, useState } from 'react'
import { CouponsListParams, CouponsInterface } from '../interfaces/offers'
import {
  CouponsListColumns,
  PER_PAGE_COUPONS_TABLE,
  FIRST_PAGE_COUPONS_TABLE,
  CouponStatuses,
  HEADER_STATUS_COLUMN,
  MAX_LENGTH_COUPON_CODE,
  MAX_LENGTH_USER_ID
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import {
  setClassNameForTable,
  convertStringToDateAndTime,
  truncateTextWithDots,
  getCapitalisation,
  emptyCell
} from '../../commons/utils/utils'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import { t } from 'i18next'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import { useAppSelector } from '../../core/redux/hooks'

interface CouponsListProps {
  onPageChange: (changedData: CouponsListParams) => void
  page?: number
  perPage?: number
  total: number
  couponsList?: CouponsInterface[]
  onDeleteCoupon: (couponID?: number) => void
  onRefundCoupon: (couponID?: number) => void
}

const handleCopyToClipboard = (UserID: string | undefined): void => {
  UserID != null && navigator.clipboard.writeText(UserID)
}

const COLUMNS = Object.values(CouponsListColumns)

const CouponsList: FC<CouponsListProps> = ({
  page,
  perPage,
  total,
  couponsList,
  onPageChange,
  onDeleteCoupon,
  onRefundCoupon
}) => {
  const [openConfirmDeleteCode, SetOpenConfirmDeleteCode] =
    useState<boolean>(false)
  const [couponID, setCouponID] = useState<number | null>(null)
  const [couponCode, setCouponCode] = useState<string | null>(null)
  const [openConfirmRefundCode, setOpenConfirmRefundCode] =
    useState<boolean>(false)
  const {
    offerSelected: { VeloAmount }
  } = useAppSelector((state) => state.offers)

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  const handleDeleteCoupon = (
    couponID?: number | null,
    couponCode?: string | null
  ): void => {
    SetOpenConfirmDeleteCode(true)
    couponCode != null && setCouponCode(couponCode)
    couponID != null && setCouponID(couponID)
  }

  const handleDiscard = (): void => {
    SetOpenConfirmDeleteCode(false)
    setOpenConfirmRefundCode(false)
    setCouponCode(null)
    setCouponID(null)
  }

  const handleConfirmDelete = (): void => {
    couponID != null && onDeleteCoupon(couponID)
    SetOpenConfirmDeleteCode(false)
  }

  const handleRefundCoupon = (couponID?: number | null): void => {
    setOpenConfirmRefundCode(true)
    couponID != null && setCouponID(couponID)
  }

  const handleConfirmRefund = (): void => {
    couponID != null && onRefundCoupon(couponID)
    setOpenConfirmRefundCode(false)
  }

  const mainMessageDeleteCode = (
    <div className='delete-coupon-dialog-msg mb-1'>
      {t`DeleteSelectedCouponsConfirm`} <br />
      {t`SelectedCoupon`}
      <Typography className='delete-coupon-highlight'>
        "
        {couponCode != null
          ? truncateTextWithDots(couponCode, MAX_LENGTH_COUPON_CODE)
          : ''}
        "
      </Typography>
      ?
    </div>
  )

  const mainMessageRefundCode = VeloAmount != null && (
    <>
      {t`RefundSelectedCouponConfirm`}
      <b>
        {+VeloAmount}
        {t`RefundPoints`}
      </b>
      {t`ConfirmRefundCouponMessage`}
    </>
  )

  const renderConfirmDeleteSelectedCode = (
    <CustomDialog
      title={t`DeleteCoupon`}
      open={openConfirmDeleteCode}
      cancelCallback={handleDiscard}
      okCallback={handleConfirmDelete}
      mainMessage={mainMessageDeleteCode}
      okText={t`CouponDialogMessageOk`}
      cancelText={t`CouponDialogMessageCancel`}
      isCustomized
      titleClassName='coupon-dialog-title title'
      actionClassName='coupon-dialog-actions'
      customColor='error'
    />
  )

  const renderConfirmRefundSelectedCode = (
    <CustomDialog
      title={t`ConfirmRefundCouponTitle`}
      open={openConfirmRefundCode}
      cancelCallback={handleDiscard}
      okCallback={handleConfirmRefund}
      mainMessage={mainMessageRefundCode}
      okText={t`ConfirmRefund`}
      cancelText={t`Cancel`}
    />
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(
                  column,
                  i,
                  COLUMNS,
                  column === HEADER_STATUS_COLUMN,
                  column === HEADER_STATUS_COLUMN
                    ? 'centered-cell header-cell'
                    : ''
                )}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {couponsList?.length != null && couponsList?.length > 0 && (
          <TableBody>
            {couponsList?.map((row) => {
              const createdTime = convertStringToDateAndTime(row.CreatedDate)
              const redeemTime = convertStringToDateAndTime(row.RedeemedDate)
              return (
                <TableRow className='row-with-actions' key={row.CouponID}>
                  <TableCell className='centered-cell body-cell'>
                    {row.CouponID}
                  </TableCell>
                  {row.CouponCode != null
                    ? (
                      <TableCell className='body-cell sample'>
                        {truncateTextWithDots(
                          row.CouponCode,
                          MAX_LENGTH_COUPON_CODE
                        )}
                        <Tooltip
                          title='Copy'
                          className='copy-icon'
                          onClick={() => handleCopyToClipboard(row.CouponCode)}
                        >
                          <ContentCopyRoundedIcon color='action' fontSize='small' />
                        </Tooltip>
                      </TableCell>
                      )
                    : (
                        emptyCell
                      )}
                  <TableCell className='body-cell'>
                    {`${createdTime.date ?? ''} |
                      ${createdTime.time ?? ''}`}
                  </TableCell>
                  <TableCell
                    className={`${
                    row.Status === CouponStatuses.REFUND
                      ? 'error'
                      : row.Status === CouponStatuses.REDEEMED
                      ? 'info'
                      : 'success'
                  } body-cell centered-cell`}
                  >
                    {getCapitalisation(row.Status != null ? row.Status : CouponStatuses.AVAILABLE)}
                  </TableCell>
                  <TableCell className='body-cell'>
                    {row.RedeemedDate != null
                      ? `${redeemTime.date ?? ''} |
                      ${redeemTime.time ?? ''}`
                      : ''}
                  </TableCell>
                  {row.UserID != null
                    ? (
                      <TableCell className='body-cell sample'>
                        {truncateTextWithDots(row.UserID, MAX_LENGTH_USER_ID)}
                        <Tooltip
                          title='Copy'
                          className='copy-icon'
                          onClick={() => handleCopyToClipboard(row.UserID)}
                        >
                          <ContentCopyRoundedIcon color='action' fontSize='small' />
                        </Tooltip>
                      </TableCell>
                      )
                    : (
                        emptyCell
                      )}
                  <TableCell className='body-cell'>
                    <Box
                      className='list-actions'
                      display='flex'
                      justifyContent='flex-end'
                    >
                      {row.Status === CouponStatuses.REDEEMED
                        ? (
                          <Tooltip title={t`Refund`}>
                            <IconButton
                              id='refund-coupon'
                              aria-label='Refund'
                              disableRipple
                              disableTouchRipple
                              onClick={() => handleRefundCoupon(row.CouponID)}
                            >
                              <UndoOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          )
                        : row.Status == null
                          ? (
                            <Tooltip title={t`DeleteCoupon`}>
                              <IconButton
                                id='remove-coupon'
                                aria-label='Remove Coupon'
                                disableRipple
                                disableTouchRipple
                                onClick={() =>
                                  handleDeleteCoupon(row.CouponID, row.CouponCode)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                            )
                          : null}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_COUPONS_TABLE}
        perPage={perPage ?? PER_PAGE_COUPONS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={couponsList?.length}
      />
      {renderConfirmDeleteSelectedCode}
      {renderConfirmRefundSelectedCode}
    </TableContainer>
  )
}

export default CouponsList
