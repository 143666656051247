import Grid from '@mui/material/Grid'
import { FC, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import SelfServeButton from '../ui/button/Button'
import AgenciesList from './AgenciesList'
import { useTranslation } from 'react-i18next'
import { AgenciesListSearch } from './interfaces/agencies'
import AgenciesListHeader from './AgenciesListHeader'
import { Link, useSearchParams } from 'react-router-dom'
import { FIRST_PAGE_AGENCIES_TABLE, PER_PAGE_AGENCIES_TABLE } from './constants'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { getAllAgencies, getFilteredAgencies } from './slices'
import { RoutePath } from '../core/routes/routePaths'
import { Box } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import './agencies.scss'
import { getValueForForm } from '../commons/utils/utils'
import { PAGE, constants } from '../commons/constants/constants'

const Agencies: FC<{}> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { agencies, tenantID } = useAppSelector((state) => ({
    agencies: state.agencies.agencies,
    tenantID: state.auth.tenantID
  }))

  const handleFormChange = ({
    city,
    state,
    perPage,
    page,
    search,
    tenantID
  }: AgenciesListSearch): void => {
    Object.entries({
      city,
      state,
      perPage,
      page,
      search,
      tenantID
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    void dispatch(
      getFilteredAgencies({
        search: searchParams.get('search') ?? '',
        page: parseInt(
          searchParams.get('page') ?? FIRST_PAGE_AGENCIES_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ?? PER_PAGE_AGENCIES_TABLE.toString()
        ),
        tenantID,
        city: searchParams.get('city') ?? '',
        state: searchParams.get('state') ?? ''
      })
    )
  }, [searchParams, tenantID, dispatch])

  useEffect(() => {
    void dispatch(
      getAllAgencies({
        search: searchParams.get('search') ?? '',
        page: 0,
        perPage: 0,
        tenantID,
        city: searchParams.get('city') ?? '',
        state: searchParams.get('state') ?? ''
      })
    )
  }, [searchParams, tenantID, dispatch])

  return (
    <Grid container spacing={1}>
      <Grid className='agencies-list-header' item xs={12}>
        <Box>
          <h1 className='title'>{t`Agencies`}</h1>
        </Box>
        <Box>
          <SelfServeButton
            id='generate-agency-report'
            style={{ marginRight: '1em' }}
            variant='contained'
            color='secondary'
          >
            <CSVLink
              data={agencies.map((item) => {
                return {
                  AgencyID: item.AgencyID,
                  Name: item.Name,
                  City: item.City,
                  State: item.State,
                  Email: item.Email,
                  DashboardAccess: item.DashboardAccess
                }
              })}
              filename={`agencies-${Date.now()}.csv`}
              className='neutral-link-secondary'
            >
              <FileDownloadOutlinedIcon
                fontSize='small'
                className='button-icon'
              />{' '}
              {t`ExportList`}
            </CSVLink>
          </SelfServeButton>
          <SelfServeButton
            color='primary'
            id='create-agency'
            variant='contained'
          >
            <Link to={RoutePath.CreateAgencies} className='neutral-link'>
              <AddOutlinedIcon className='button-icon' /> {t`NewAgency`}
            </Link>
          </SelfServeButton>
        </Box>
      </Grid>
      <Grid item xs={12} className='mb-01'>
        <AgenciesListHeader
          search={searchParams.get('search') ?? ''}
          city={searchParams.get('city') ?? ''}
          state={searchParams.get('state') ?? ''}
          handleFormChange={handleFormChange}
        />
      </Grid>
      <Grid item xs={12}>
        <AgenciesList
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_AGENCIES_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_AGENCIES_TABLE.toString()
          )}
          onPageChange={handleFormChange}
        />
      </Grid>
    </Grid>
  )
}

export default Agencies
