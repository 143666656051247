import {
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import {
  ReactElement,
  useState,
  FormEvent,
  ChangeEvent,
  useEffect,
  useCallback
} from 'react'
import SelfServeButton from '../../ui/button/Button'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { addCouponsByOfferID } from '../slices'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import CouponCodesBox from './CouponCodesBox'
import { RoutePath } from '../../core/routes/routePaths'
import {
  getDuplicatesCountFromList,
  getIntersectionCountFromLists,
  getValidFilteredCouponCodes
} from '../utils'
import { constants } from '../../commons/constants/constants'
import { errorDisplay, replaceWhitespaceWithComma } from '../../commons/utils/utils'

const NonRepeatableCouponCreate = (): ReactElement => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const [couponInput, setCouponInput] = useState<string>('')
  const [unfilteredCouponsList, setunfilteredCouponsList] = useState<string[]>(
    []
  )
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [openConfirmValidatedCodes, setOpenConfirmValidatedCodes] =
    useState<boolean>(false)
  const [openConfirmCodesAdded, setOpenConfirmCodesAdded] =
    useState<boolean>(false)
  const [totalCoupons, setTotalCoupons] = useState<number>()
  const [duplicateCoupons, setDuplicateCoupons] = useState<number>(0)
  const [alreadyAddedCoupons, setAlreadyAddedCoupons] = useState<number>(0)
  const [totalValidCoupons, setTotalValidCoupons] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [couponCodesToAdd, setCouponCodesToAdd] = useState<string[]>([])
  const {
    offerSelected: { Coupons },
    loading
  } = useAppSelector((state) => state.offers)

  const handleCouponsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setCouponInput(replaceWhitespaceWithComma(e.target.value))
  }

  const handleCouponsSubmit = (e: FormEvent): void => {
    e.preventDefault()
    setunfilteredCouponsList(
      couponInput
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item !== '')
    )
    setOpenConfirmValidatedCodes(true)
  }

  const getTotalCouponsCount = useCallback(
    () => setTotalCoupons(() => unfilteredCouponsList.length),
    [unfilteredCouponsList]
  )

  const getDuplicateCouponsCount = useCallback(() => {
    const duplicates = getDuplicatesCountFromList(unfilteredCouponsList)
    setDuplicateCoupons(duplicates)
  }, [unfilteredCouponsList])

  const getAlreadyAddedCouponsCount = useCallback(() => {
    const alreadyAddedCouponsCount =
      Coupons != null
        ? getIntersectionCountFromLists(unfilteredCouponsList, Coupons)
        : 0
    setAlreadyAddedCoupons(alreadyAddedCouponsCount)
  }, [unfilteredCouponsList, Coupons])

  const getTotalValidCouponsCount = useCallback(() => {
    const filteredCouponCodes = getValidFilteredCouponCodes(
      unfilteredCouponsList,
      Coupons
    )
    setTotalValidCoupons(filteredCouponCodes.length)
  }, [Coupons, unfilteredCouponsList])

  const setFilteredCouponCodes = useCallback(() => {
    const filteredCouponCodes = getValidFilteredCouponCodes(
      unfilteredCouponsList,
      Coupons
    )
    setCouponCodesToAdd(filteredCouponCodes)
  }, [unfilteredCouponsList, Coupons])

  const handleClose = (): void => {
    setOpenConfirmValidatedCodes(false)
    setOpenConfirmLeaving(false)
  }

  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const addCouponCodesSubmission = async (): Promise<void> => {
    if (totalValidCoupons === 0) {
      setErrorMessage(t`InvalidCodeError`)
    } else {
      void dispatch(
        addCouponsByOfferID({
          couponCodes: couponCodesToAdd,
          offerID: id ?? ''
        })
      )
      setOpenConfirmCodesAdded(true)
    }
    handleClose()
  }

  useEffect(() => {
    getTotalCouponsCount()
    getDuplicateCouponsCount()
    getAlreadyAddedCouponsCount()
    getTotalValidCouponsCount()
    setFilteredCouponCodes()
  }, [
    unfilteredCouponsList,
    getAlreadyAddedCouponsCount,
    getDuplicateCouponsCount,
    getTotalCouponsCount,
    getTotalValidCouponsCount,
    setFilteredCouponCodes
  ])

  const renderConfirmValidatedCodes = (
    <CustomDialog
      title={t`AddCoupons`}
      open={openConfirmValidatedCodes}
      cancelCallback={handleClose}
      okCallback={addCouponCodesSubmission}
      mainMessage={
        <CouponCodesBox
          totalCoupons={totalCoupons ?? 0}
          duplicateCoupons={duplicateCoupons}
          alreadyAddedCoupons={alreadyAddedCoupons}
          totalValidCoupons={totalValidCoupons}
        />
      }
      okText={t`AddCodes`}
      cancelText={t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const mainMessageCodesAdded = (
    <div className='coupon-dialog-msg'>
      <b>{totalValidCoupons}</b>
      {totalValidCoupons > 1
        ? t`CodesAddedSuccessfully`
        : t`CodeAddedSuccessfully`}
    </div>
  )

  const renderConfirmCodesAdded = (
    <CustomDialog
      title=''
      open={openConfirmCodesAdded}
      okCallback={() =>
        navigate(`${RoutePath.Offers}/${id ?? ''}${RoutePath.Coupons}`)}
      cancelCallback={() =>
        navigate(`${RoutePath.Offers}/${id ?? ''}${RoutePath.Coupons}`)}
      mainMessage={mainMessageCodesAdded}
      okText={t`OK`}
      isCustomized
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
      okButtonClassName='coupon-ok-btn'
    />
  )

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardCouponsCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() =>
        navigate(`${RoutePath.Offers}/${id ?? ''}${RoutePath.Coupons}`)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <Typography>
        {t`CouponCodeInstruction`}
        <span className='error'>*</span>
      </Typography>
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        <Form onSubmit={handleCouponsSubmit}>
          <TextField
            multiline
            minRows={2}
            className='code-box'
            onChange={handleCouponsChange}
            value={couponInput}
          />
          <Divider className='mb-1' />
          <Box className='coupon-buttons'>
            <SelfServeButton
              id='validate-coupons'
              type='submit'
              variant='contained'
              disabled={couponInput === ''}
            >
              {t`ValidateCouponCodes`}
            </SelfServeButton>
            <SelfServeButton
              id='coupons-create-discard'
              onClick={handleDiscard}
              variant='outlined'
            >
              {t`Discard`}
            </SelfServeButton>
          </Box>
        </Form>
      </Box>
      {errorDisplay(errorMessage)}
      {renderConfirmValidatedCodes}
      {renderConfirmCodesAdded}
      {renderConfirmLeavingDialog}
    </Box>
  )
}

export default NonRepeatableCouponCreate
