import { Box, FormControl, FormHelperText } from '@mui/material'
import { PropsWithChildren } from 'react'
import '../../offers/create-new-offer/offer-create.scss'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldValues,
  Merge,
  Path,
  UseFormStateReturn
} from 'react-hook-form'
import { ReactElement } from 'react-markdown/lib/react-markdown'

interface FormFieldBoxProps<T extends FieldValues>
  extends PropsWithChildren,
  PropsWithChildren {
  title: string
  rules: {}
  name: Path<T>
  render: ({
    field,
    fieldState,
    formState
  }: {
    field: ControllerRenderProps<T, Path<T>>
    fieldState: ControllerFieldState
    formState: UseFormStateReturn<FieldValues>
  }) => ReactElement
  control: Control<any>
  errorField: Merge<FieldError, Array<FieldError | undefined>> | undefined
  isAdjacentTextField?: boolean
  isAdjacentDatePicker?: boolean
  className?: string
}

const FormFieldBox = <T extends FieldValues>({
  title,
  rules,
  name,
  render,
  control,
  errorField,
  isAdjacentTextField = false,
  isAdjacentDatePicker = false,
  className = ''
}: FormFieldBoxProps<T>): ReactElement => {
  const controller = (
    <Controller rules={rules} control={control} name={name} render={render} />
  )
  const formHelperText = <FormHelperText>{title}</FormHelperText>

  const errorText = errorField != null && (
    <FormHelperText error>{errorField?.message?.toString()}</FormHelperText>
  )
  return isAdjacentTextField
    ? (
      <>
        <FormControl fullWidth>{controller}</FormControl>
        {errorText}
      </>
      )
    : isAdjacentDatePicker
      ? (
        <>
          {formHelperText}
          {controller}
          {errorText}
        </>
        )
      : (
        <Box className={`mb-1 ${className}`}>
          {formHelperText}
          <FormControl fullWidth>{controller}</FormControl>
          {errorText}
        </Box>
        )
}

export default FormFieldBox
