import { useTranslation } from 'react-i18next'
import { ReactElement, useState } from 'react'
import { CampaignPhonePreviewProps } from '../interfaces/campaigns'
import { Box, Tab, Tabs } from '@mui/material'
import PhonePreview from '../../ui/phone-preview/PhonePreview'
import CampaignCardPhonePreview from './CampaignCardPhonePreview'
import CampaignDetailsPhonePreview from './CampaignDetailsPhonePreview'

const CampaignPhonePreviewWrapper = ({
  isFromCreateEdit
}: CampaignPhonePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const [tab, setTab] = useState<number>(0)
  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setTab(newValue)
  }
  const TAB_0 = 0
  const TAB_1 = 1

  return (
    <Box>
      <Box className='tabs'>
        <Tabs
          data-testid='cardTabs'
          value={tab}
          onChange={handleChange}
          aria-label='preview tabs'
        >
          <Tab label={t`CampaignCard`} />
          <Tab label={t`CampaignDetail`} />
        </Tabs>
      </Box>
      {tab === TAB_0 && (
        <PhonePreview
          data-testid='cardPhonePreview'
          content={
            <CampaignCardPhonePreview isFromCreateEdit={isFromCreateEdit} />
          }
        />
      )}
      {tab === TAB_1 && (
        <PhonePreview
          data-testid='detailsPhonePreview'
          content={
            <CampaignDetailsPhonePreview isFromCreateEdit={isFromCreateEdit} />
          }
        />
      )}
    </Box>
  )
}

export default CampaignPhonePreviewWrapper
