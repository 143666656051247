import {
  FormControl,
  Box,
  Divider,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@mui/material'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FC, useEffect } from 'react'
import '../segments.scss'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import SelfServeButton from '../../ui/button/Button'
import {
  AgenciesSelectItems,
  getRoleTypeByString,
  handleSubmit,
  replaceWhitespaceWithComma,
  selectMenuItem
} from '../../commons/utils/utils'
import {
  SegmentsFormStepsProps,
  TargetGroupsInterface
} from '../interfaces/segments'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { getAllAgencies } from '../../agencies/slices'
import { userHasTenantPermission } from '../../core/routes/router'
import { useTranslation } from 'react-i18next'
import { USERS, USERS_FILE, formValidationConstants } from '../constants'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'

const SegmentInformationStep: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  onSubmit,
  handleBack,
  onDiscard
}) => {
  const {
    segments: {
      isFormTouched,
      segmentsForm: {
        userList,
        AgencyID,
        Name,
        isFromCSV,
        usersFile,
        usersFileID
      }
    },
    auth: { agencyID: agencyIDLoggedIn, tenantID, userType, isAgencyEnabled },
    agencies: { agencies }
  } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const role = getRoleTypeByString(userType)

  useEffect(() => {
    void dispatch(
      getAllAgencies({
        tenantID
      })
    )
  }, [tenantID, dispatch])

  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      userList,
      AgencyID: agencyIDLoggedIn ?? AgencyID,
      Name
    }
  })

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        onSubmit != null &&
          handleSubmit(event, trigger, onSubmit).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper'
    >
      <Box className='mb-1'>
        <FormControl fullWidth />
      </Box>
      {userHasTenantPermission(role) && isAgencyEnabled && (
        <FormFieldBox
          title={t`Agency`}
          rules={{
            required: formValidationConstants.requiredField,
            validate: {
              notEmpty: () => {
                return AgencyID !== ''
              }
            }
          }}
          name='AgencyID'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel shrink={false} id='agency-label'>
                {AgencyID === '' && t`SelectAgency`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof TargetGroupsInterface
                  )
                  onChange(e.target?.value, name)
                }}
                value={AgencyID}
                menuItem={selectMenuItem(t`None`)}
                selectItems={AgenciesSelectItems(agencies)}
                name={name}
                inputProps='custom-form-select'
                isFormSelect
              />
            </>
          )}
          control={control}
          errorField={errors.AgencyID}
        />
      )}
      <FormFieldBox
        title={t`Name`}
        rules={{
          required: formValidationConstants.requiredField,
          minLength: formValidationConstants.minLengthError,
          maxLength: formValidationConstants.maxLengthError
        }}
        name='Name'
        render={({ field: { name, onChange } }) => (
          <TextField
            inputProps={{ 'data-testid': 'nameField' }}
            name={name}
            id='name'
            placeholder={t`SegmentsMeaningfulName`}
            onChange={(e) => {
              onFormChange(e.target?.value, name as keyof TargetGroupsInterface)
              onChange(e.target?.value)
            }}
            value={Name}
          />
        )}
        control={control}
        errorField={errors.Name}
      />
      <Typography variant='subtitle1' className='mt-1'>
        {t`SegmentsGenerateCode`}
      </Typography>
      <RadioGroup
        aria-labelledby='segment-csv-radios'
        name='isFromCSV'
        className='mb-1'
        value={isFromCSV}
        onChange={(e) => {
          onFormChange(e.target?.value === 'true', 'isFromCSV')
        }}
      >
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={t`PasteListUserIDs`}
        />
        <FormControlLabel value control={<Radio />} label={t`UsersIDCSVFile`} />
      </RadioGroup>
      {isFromCSV === true && (
        <FormControl fullWidth>
          <FormHelperText>{t`UsersIDCSVFileOption`}</FormHelperText>
          <ImageDropZone
            isImage={false}
            isThumbnail
            name={USERS}
            setFile={(data: File | undefined) => {
              onFormChange(data, USERS_FILE)
            }}
            thumbnailID={usersFileID}
            file={usersFile}
          />
        </FormControl>
      )}
      {isFromCSV === false && (
        <FormControl>
          <FormHelperText>{t`AddUserToSegment`}</FormHelperText>
          <TextField
            inputProps={{ 'data-testid': 'usersListField' }}
            placeholder={t`EnterUsersCommaSeparated`}
            className='code-box mb-1'
            name={USERS}
            id='users'
            multiline
            minRows={2}
            onChange={(e) =>
              onFormChange(
                replaceWhitespaceWithComma(e.target.value).split(','),
                USERS
              )}
            value={userList}
          />
          <FormHelperText className='fw-400'>
            {t`OptionalUsersIDCSVFile`}
          </FormHelperText>
        </FormControl>
      )}
      <Divider className='mt-2' />
      <Box className='mt-1 space-between'>
        <Box>
          <SelfServeButton
            id='segments-create-previous'
            variant='contained'
            onClick={handleBack}
            color='secondary'
            className='mr-1'
          >
            {t`Previous`}
          </SelfServeButton>
          <SelfServeButton
            id='segments-create-next'
            dataTestId='createSegmentButton'
            type='submit'
            variant='contained'
            disabled={!isDirty && !isFormTouched}
          >
            {t`CreateSegment`}
          </SelfServeButton>
        </Box>
        <SelfServeButton
          id='segments-create-discard'
          onClick={onDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Form>
  )
}

export default SegmentInformationStep
