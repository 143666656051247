import { FC, useCallback } from 'react'
import {
  UsersListColumns,
  PER_PAGE_USERS_TABLE,
  FIRST_PAGE_USERS_TABLE,
  UserStatus
} from './constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { constants } from '../commons/constants/constants'
import SelfServePagination from '../ui/pagination/SelfServePagination'
import {
  setClassNameForTable,
  convertStringToDateAndTime,
  getCapitalisation
} from '../commons/utils/utils'
import { UsersListParams, UsersInterface } from './interfaces/users'
import StatusIndicator from '../ui/status-indicator/StatusIndicator'
import { RoutePath } from '../core/routes/routePaths'
import { Link } from 'react-router-dom'
import SelfServeActionItem from '../ui/list-action-item/SelfServeActionItem'
import { usersListActionItems } from './utils'

interface UsersListProps {
  onPageChange: (changedData: UsersListParams) => void
  page?: number
  perPage?: number
  total: number
  usersList?: UsersInterface[]
}

const COLUMNS = Object.values(UsersListColumns)

const UsersList: FC<UsersListProps> = ({
  page,
  perPage,
  total,
  usersList,
  onPageChange
}) => {
  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {usersList?.length != null && usersList?.length > 0 && (
          <TableBody>
            {usersList?.map((row) => {
              const joinDateString = convertStringToDateAndTime(row.JoinDate)
              return (
                <TableRow className='row-with-actions' key={row.UserID}>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Users}/${row.UserID ?? ''}`}>
                      {row.UserID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Users}/${row.UserID ?? ''}`}>
                      {row.VelociaID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    {row.JoinDate != null &&
                    `${joinDateString?.date ?? ''} |
                  ${joinDateString?.time ?? ''}`}
                  </TableCell>
                  <TableCell className='body-cell'>
                    <StatusIndicator
                      color={
                      row.Status === UserStatus.NEW ||
                      row.Status === UserStatus.ACTIVE
                        ? 'success'
                        : row.Status === UserStatus.INACTIVE
                          ? 'warning'
                          : 'error'
                    }
                      title={getCapitalisation(row.Status ?? '')}
                    />
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Box className='list-actions flex-center'>
                      {usersListActionItems(row.UserID ?? '').map((item) => {
                        return (
                          <SelfServeActionItem
                            path={item.path}
                            title={item.title}
                            icon={item.icon}
                            key={item.title}
                          />
                        )
                      })}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_USERS_TABLE}
        perPage={perPage ?? PER_PAGE_USERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={usersList?.length}
      />
    </TableContainer>
  )
}

export default UsersList
