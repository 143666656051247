import { ReactElement, FC, useState } from 'react'
import { UsersListParams } from '../../users/interfaces/users'
import { t } from 'i18next'
import { FormControl } from '@mui/material'
import { Form } from 'react-router-dom'
import {
  checkIsNotEmpty,
  handleDateRangeChange
} from '../../commons/utils/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'

dayjs.extend(utc)

interface SegmentUsersListHeaderProps {
  handleFormChange: (changedData: UsersListParams) => void
  search?: string
  addedDateStart: string
  addedDateEnd: string
}

const SegmentUsersListHeader: FC<SegmentUsersListHeaderProps> = ({
  handleFormChange,
  search,
  addedDateStart,
  addedDateEnd
}): ReactElement => {
  const [startDate, setStartDate] = useState<null | Date>(
    checkIsNotEmpty(addedDateStart) ? new Date(addedDateStart) : null
  )
  const [endDate, setEndDate] = useState<null | Date>(
    checkIsNotEmpty(addedDateEnd) ? new Date(addedDateEnd) : null
  )

  const handleDateChange = (selectedDateRange: Array<Date | null>): void => {
    const { startDateString, endDateString } = handleDateRangeChange(
      selectedDateRange,
      setStartDate,
      setEndDate
    )

    handleFormChange({
      addedDateStart: startDateString ?? '',
      addedDateEnd: endDateString ?? ''
    })
  }

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`AddedDate`}
              className='date-picker'
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
      </Form>
    </div>
  )
}

export default SegmentUsersListHeader
