import { ReactElement } from 'react'
import { Box, Avatar, Typography } from '@mui/material'
import { useAppSelector } from '../../core/redux/hooks'

const OfferCategoryDetailCard = (): ReactElement => {
  const { offerCategorySelected } = useAppSelector((state) => state.offerCategories)

  return (
    <Box className='flex-column' margin='3em 3em'>
      <Avatar
        className='sidebar-avatar'
        src={offerCategorySelected.IconImageUrl}
        alt='category-logo'
      />
      <Typography variant='h5'>{offerCategorySelected.CategoryName}</Typography>
      <Typography variant='subtitle2'>#{offerCategorySelected.OfferCategoryID}</Typography>
    </Box>
  )
}

export default OfferCategoryDetailCard
