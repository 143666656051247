import CircularProgress from '@mui/material/CircularProgress'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import Box from '@mui/material/Box'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import CampaignCategoryForm from './CampaignCategoriesForm'
import { RoutePath } from '../core/routes/routePaths'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import {
  ReorderList,
  errorDisplay,
  getErrorMessage,
  handleReorderSave,
  validationErrorsDisplay,
  getItemKey,
  isValid
} from '../commons/utils/utils'
import { uploadImage } from '../commons/components/uploader/utils'
import {
  createCampaignCategory,
  getCategories,
  resetCategoryForm
} from './slices'
import ReorderUiPositionWrapperCreate from '../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperCreate'
import { REORDER_KEY_NAME } from '../commons/constants/constants'

const CampaignCategoriesCreate: FC<{}> = () => {
  const { agencyID, tenantID, userID, isAgencyEnabled } = useAppSelector(
    (state) => state.auth
  )
  const {
    loading,
    errors,
    isFulfilled,
    categories,
    campaignCategorySelected: { CampaignCategoryID }
  } = useAppSelector((state) => state.campaignCategories)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [formAgencyID, setFormAgencyID] = useState('')
  const [icon, setIcon] = useState<File | undefined>()
  const [categoryName, setCategoryName] = useState('')
  const [webUiPosition, setWebUiPosition] = useState('')

  const [openConfirmCreate, setOpenConfirmCreate] = useState(false)
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsCategoryNameUpdated] = useState<boolean>(false)

  const handleClose = (): void => {
    setOpenConfirmCreate(false)
    setOpenConfirmLeaving(false)
  }

  const createCampaignCategorySubmission = async (): Promise<void> => {
    try {
      const upload = await uploadImage(userID, icon)
      void dispatch(
        createCampaignCategory({
          CategoryName: categoryName,
          WebUiPosition: webUiPosition,
          IconImageID: upload.imageID,
          TenantID: tenantID,
          AgencyID: agencyID ?? formAgencyID,
          UpdatedUIs: updatedUIs
        })
      )
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (agencyID != null || formAgencyID !== '' || !isAgencyEnabled) {
      void dispatch(
        getCategories({
          tenantID,
          agencyID: agencyID ?? formAgencyID
        })
      )
    }
  }, [dispatch, agencyID, formAgencyID, tenantID, isAgencyEnabled])

  useEffect(() => {
    if (isFulfilled && CampaignCategoryID != null) {
      dispatch(resetCategoryForm())
      navigate(`${RoutePath.CampaignCategories}/${CampaignCategoryID}`)
    }
  }, [isFulfilled, navigate, dispatch, CampaignCategoryID])

  useEffect(() => {
    if (isNameUpdated) {
      const updatedList = listWithNewOrder.map((category) => {
        const categoryIDKey = getItemKey(
          category,
          REORDER_KEY_NAME.CategoryIDKeyWord
        )
        if (categoryIDKey != null) {
          if (category[categoryIDKey as keyof typeof category] == null) {
            return { ...category, CategoryName: categoryName }
          }
        }
        return category
      })
      setListWithNewOrder(updatedList)
      setIsCategoryNameUpdated(false)
    }
  }, [isNameUpdated, categoryName, listWithNewOrder])

  const onReorderSave = (list: ReorderList): void => {
    handleReorderSave(
      categoryName,
      list,
      setWebUiPosition,
      setOpenUIreorder,
      setUpdatedUIs,
      setListWithNewOrder,
      false,
      false,
      false,
      false
    )
  }

  const renderConfirmCreateDialog = (
    <CustomDialog
      title={t`CreateCategory`}
      open={openConfirmCreate}
      cancelCallback={handleClose}
      okCallback={createCampaignCategorySubmission}
      mainMessage={t('CategoryCreateDialogMessage', { categoryName })}
      okText={t`Create`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardCategoryCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() => navigate(RoutePath.CampaignCategories)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])

  useEffect(() => {
    dispatch(resetCategoryForm())
  }, [dispatch])

  const setValueMap = {
    agencyID: setFormAgencyID,
    categoryName: setCategoryName,
    webUiPosition: setWebUiPosition,
    icon: setIcon,
    url: setIcon
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <CampaignCategoryForm
        isLeavingDialogOpen={openConfirmLeaving}
        setValueMap={setValueMap}
        edit={false}
        onSubmit={() => setOpenConfirmCreate(true)}
        properties={{
          agencyID: formAgencyID,
          categoryName,
          webUiPosition,
          icon
        }}
        onDiscard={() => {
          setOpenConfirmLeaving(true)
        }}
        onUiReorderClick={() => setOpenUIreorder(true)}
        isReorderDisabled={
          (formAgencyID !== '' || agencyID != null || !isAgencyEnabled) &&
          isValid(categoryName)
        }
        resetUIs={() => setResetUIs(true)}
        setIsCategoryNameUpdated={() => setIsCategoryNameUpdated(true)}
      />
      {errorDisplay(errorMessage)}
      {isUIReorderOpen && (
        <ReorderUiPositionWrapperCreate
          isUIReorderOpen={isUIReorderOpen}
          handleClose={() => setOpenUIreorder(false)}
          name={categoryName}
          onReorderSave={onReorderSave}
          currentList={categories}
          listWithNewOrder={listWithNewOrder}
        />
      )}
      {renderConfirmCreateDialog}
      {renderConfirmLeavingDialog}
      {typeof errors === 'string'
        ? errorDisplay(errors)
        : validationErrorsDisplay(errors)}
    </Box>
  )
}

export default CampaignCategoriesCreate
