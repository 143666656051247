import { FC } from 'react'
import * as MuiIcons from '@mui/icons-material'
import { Box } from '@mui/material'

type IconType = typeof import('@mui/icons-material/index')

interface DynamicIconProps {
  iconName: string
  className?: string
}

const DynamicIcon: FC<DynamicIconProps> = ({
  iconName,
  className
}: DynamicIconProps) => {
  const Icon = MuiIcons[iconName as keyof IconType]
  return Icon != null
    ? (
      <Icon className={className} />
      )
    : (
      <Box className={`mr-01 ${className as string}`}>{iconName}</Box>
      )
}

export default DynamicIcon
