import { ReactElement } from 'react'
import { OffersInterface } from '../interfaces/offers'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface OffersInterfaceProps {
  offer: OffersInterface
}

const LiveOffer = ({ offer }: OffersInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()
  const pointsToRedeem =
    offer?.VeloAmount != null && +offer.VeloAmount

  return offer.OfferID != null
    ? (
      <Box className='live-offers mt-1 flex-column'>
        <img className='live-offer' src={offer.Url} alt='live-offer' />
        <Box className='live-offer-info-container'>
          <Typography className='subheader-points'>
            {pointsToRedeem !== false
              ? pointsToRedeem === 0
                ? t`Free`
                : `${pointsToRedeem} points`
              : null}
          </Typography>
          <Typography className='subheader-title'>{offer.Title}</Typography>
        </Box>
      </Box>
      )
    : null
}

export default LiveOffer
