import { FC, useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../core/redux/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllAgencies } from '../agencies/slices'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import { uploadImage } from '../commons/components/uploader/utils'
import { RoutePath } from '../core/routes/routePaths'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { t } from 'i18next'
import { Box, CircularProgress } from '@mui/material'
import OfferCategoriesForm from './OfferCategoriesForm'
import {
  setEntity,
  setEditMode,
  setBackButtonRoute,
  setBackButtonTitle
} from '../ui/sidebar/slices'
import { REORDER_KEY_NAME, constants } from '../commons/constants/constants'
import {
  ReorderList,
  errorDisplay,
  getErrorMessage,
  handleReorderSave,
  validationErrorsDisplay,
  getItemKey,
  isValid
} from '../commons/utils/utils'
import {
  editOfferCategory,
  getOfferCategoryByID,
  resetCategoryForm,
  getOfferCategories
} from './slices'
import ReorderUiPositionWrapperEdit from '../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperEdit'
import { RoleType } from '../core/routes/constants'

const OfferCategoriesEdit: FC<{}> = () => {
  const {
    OfferCategoryID,
    CategoryName,
    WebUiPosition,
    IconImageUrl,
    IconImageID,
    AgencyID,
    loading,
    isFulfilled,
    errors,
    offerCategories
  } = useAppSelector((state) => ({
    OfferCategoryID:
      state.offerCategories.offerCategorySelected.OfferCategoryID,
    CategoryName: state.offerCategories.offerCategorySelected.CategoryName,
    WebUiPosition: state.offerCategories.offerCategorySelected.WebUiPosition,
    IconImageUrl: state.offerCategories.offerCategorySelected.IconImageUrl,
    IconImageID: state.offerCategories.offerCategorySelected.IconImageID,
    AgencyID: state.offerCategories.offerCategorySelected.AgencyID,
    loading: state.offerCategories.loading,
    isFulfilled: state.offerCategories.isFulfilled,
    errors: state.offerCategories.errors,
    offerCategories: state.offerCategories.offerCategories
  }))

  const { userID, agencyID, tenantID, userType, isAgencyEnabled } =
    useAppSelector((state) => state.auth)
  const navigate = useNavigate()

  const [formAgencyID, setFormAgencyID] = useState(AgencyID)
  const [categoryName, setCategoryName] = useState(CategoryName ?? '')
  const [icon, setIcon] = useState<File | undefined>()
  const [url, setUrl] = useState(IconImageUrl)
  const [webUiPosition, setWebUiPosition] = useState(WebUiPosition ?? '')

  const [errorMessage, setErrorMessage] = useState('')
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState(false)
  const [openConfirmEditCategory, setConfirmEditCategory] = useState(false)
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsCategoryNameUpdated] = useState<boolean>(false)

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getOfferCategoryByID({
          id,
          userType,
          agencyID,
          tenantID
        })
      )
      if (userType !== RoleType.AGENCY && isAgencyEnabled) {
        void dispatch(getAllAgencies({ tenantID }))
      }
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER_CATEGORY))
      dispatch(setEditMode(true))
      dispatch(setBackButtonTitle(t`OfferCategories`))
      dispatch(setBackButtonRoute(RoutePath.OfferCategories))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
      dispatch(setEditMode(false))
    }
  }, [tenantID, dispatch, id, agencyID, userType, isAgencyEnabled])

  const editOfferCategorySubmission = async (): Promise<void> => {
    try {
      const upload = await uploadImage(userID, icon)
      if (OfferCategoryID != null) {
        void dispatch(
          editOfferCategory({
            CategoryName: categoryName,
            WebUiPosition: webUiPosition,
            IconImageID: upload.imageID !== 0 ? upload.imageID : IconImageID,
            AgencyID: agencyID ?? formAgencyID,
            OfferCategoryID,
            UpdatedUIs: updatedUIs
          })
        )
      }
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (isFulfilled && OfferCategoryID != null) {
      dispatch(resetCategoryForm())
      navigate(`${RoutePath.OfferCategories}/${OfferCategoryID}`)
    }
  }, [isFulfilled, navigate, dispatch, OfferCategoryID])

  useEffect(() => {
    if (agencyID != null || formAgencyID !== '' || !isAgencyEnabled) {
      void dispatch(
        getOfferCategories({
          tenantID,
          userType,
          agencyID: agencyID ?? formAgencyID
        })
      )
    }
  }, [dispatch, agencyID, formAgencyID, tenantID, userType, isAgencyEnabled])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])
  const onReorderSave = (list: ReorderList): void => {
    handleReorderSave(
      categoryName,
      list,
      setWebUiPosition,
      setOpenUIreorder,
      setUpdatedUIs,
      setListWithNewOrder,
      false,
      false,
      false,
      true,
      OfferCategoryID
    )
  }
  const handleNameChange = (list: ReorderList): void => {
    const updatedList = list.map((category) => {
      const categoryIDKey = getItemKey(
        category,
        REORDER_KEY_NAME.CategoryIDKeyWord
      )
      if (categoryIDKey != null) {
        const offerCategoryID = category[categoryIDKey as keyof typeof category]
        if (offerCategoryID === OfferCategoryID || offerCategoryID == null) {
          category.CategoryName = categoryName
        }
      }
      return category
    })
    setListWithNewOrder(updatedList)
    setIsCategoryNameUpdated(false)
  }
  const handleClose = (): void => {
    setConfirmEditCategory(false)
    setOpenConfirmLeaving(false)
  }

  useEffect(() => {
    dispatch(resetCategoryForm())
  }, [dispatch])

  const renderConfirmEditDialog = (
    <CustomDialog
      title={t`UpdateOfferCategory`}
      open={openConfirmEditCategory}
      cancelCallback={handleClose}
      okCallback={editOfferCategorySubmission}
      mainMessage={t('OfferCategoryEditDialogMessage', { categoryName })}
      okText={t`UpdateCategory`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmLeavingDialog = OfferCategoryID != null && (
    <CustomDialog
      title={t`DiscardOfferCategoryModification`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() =>
        navigate(`${RoutePath.OfferCategories}/${OfferCategoryID}`)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const setValueMap = {
    agencyID: setFormAgencyID,
    categoryName: setCategoryName,
    icon: setIcon,
    webUiPosition: setWebUiPosition,
    url: setUrl
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <OfferCategoriesForm
        onSubmit={() => {
          setConfirmEditCategory(true)
        }}
        edit
        setValueMap={setValueMap}
        properties={{
          agencyID: formAgencyID ?? '',
          categoryName,
          icon,
          webUiPosition,
          url
        }}
        onDiscard={() => setOpenConfirmLeaving(true)}
        isLeavingDialogOpen={openConfirmLeaving}
        isReorderDisabled={
          (formAgencyID !== '' || agencyID != null || !isAgencyEnabled) &&
          isValid(categoryName)
        }
        onUiReorderClick={() => setOpenUIreorder(true)}
        resetUIs={() => setResetUIs(true)}
        setIsCategoryNameUpdated={() => setIsCategoryNameUpdated(true)}
      />
      {errorDisplay(errorMessage)}
      {isUIReorderOpen && (
        <ReorderUiPositionWrapperEdit
          isUIReorderOpen={isUIReorderOpen}
          handleClose={() => setOpenUIreorder(false)}
          name={categoryName}
          onReorderSave={onReorderSave}
          currentList={offerCategories}
          listWithNewOrder={listWithNewOrder}
          currentID={OfferCategoryID}
          handleNameChange={handleNameChange}
          isNameUpdated={isNameUpdated}
        />
      )}
      {renderConfirmEditDialog}
      {renderConfirmLeavingDialog}
      {typeof errors === 'string'
        ? errorDisplay(errors)
        : validationErrorsDisplay(errors)}
    </Box>
  )
}

export default OfferCategoriesEdit
