export const PER_PAGE_CAMPAIGNS_TABLE = 10
export const FIRST_PAGE_CAMPAIGNS_TABLE = 0
export enum AgenciesCampaignsListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'Ui Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum TenantsCampaignsListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'Ui Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export const campaignsConstants = {
  Domain: 'campaigns',
  Statuses: {
    HIDDEN_ACTIVE: 'Hidden - Active',
    LIVE_ACTIVE: 'Live - Active',
    HIDDEN_INACTIVE: 'Hidden - Inactive',
    LIVE_INACTIVE: 'Live - Inactive',
    EXPIRED: 'Expired'
  }
}

export const FilterCampaignStatus = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  HIDDEN: 'Hidden',
  INACTIVE: 'Inactive',
  LIVE: 'Live'
}

export enum CampaignsStatuses {
  HIDDEN_ACTIVE = 'Hidden - Active',
  LIVE_ACTIVE = 'Live - Active',
  HIDDEN_INACTIVE = 'Hidden - Inactive',
  LIVE_INACTIVE = 'Live - Inactive',
  EXPIRED = 'Expired'
}

export enum CampaignsTypes {
  TRANSACTIONAL = 'TRANSACTIONAL',
  CONNECT = 'CONNECT',
  VELOCIA_SIGNUP = 'VELOCIA_SIGNUP',
  ACTION = 'ACTION',
  PROMOTION = 'PROMOTION',
  EXTERNAL_SURVEY = 'EXTERNAL_SURVEY',
  LOCATION = 'LOCATION',
  REFERRAL = 'REFERRAL',
  STREAK = 'STREAK'
}

export const STEPS_FOR_CREATE_CAMPAIGN = [
  'Card Info',
  'Details',
  'Campaign Options',
  'Rewards Distribution',
  'Audience',
  'Preview'
]
export const STEP_NUMBER = {
  CardInfo: 0,
  Detail: 1,
  CampaignOptions: 2,
  RewardsDistribution: 3,
  Audience: 4,
  Preview: 5
}

export const DATE_FORMAT = 'MMM DD, YYYY | HH:mm'
