import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { authReducer } from '../../auth/slices'
import { agenciesReducer } from '../../agencies/slices'
import { campaignCategoriesReducer } from '../../campaign-categories/slices'
import sidebarReducer from '../../ui/sidebar/slices'
import {
  AgenciesState,
  AuthState,
  CampaignCategoryState,
  OfferCategoryState,
  CampaignState,
  SidebarState,
  OffersState,
  SegmentState,
  UsersState
} from '../../../type'
import { offerCategoriesReducer } from '../../offer-categories/slices'
import { offersReducer } from '../../offers/slices'
import { campaignsReducer } from '../../campaigns/slices'
import { segmentsReducer } from '../../segments/slices'
import { usersReducer } from '../../users/slices'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth'
  ]
}

interface ApplicationState {
  auth: AuthState
  agencies: AgenciesState
  campaignCategories: CampaignCategoryState
  campaigns: CampaignState
  sidebar: SidebarState
  offerCategories: OfferCategoryState
  offers: OffersState
  segments: SegmentState
  users: UsersState
}

export default persistReducer(
  persistConfig,
  combineReducers<ApplicationState>({
    auth: authReducer,
    agencies: agenciesReducer,
    campaignCategories: campaignCategoriesReducer,
    sidebar: sidebarReducer,
    offerCategories: offerCategoriesReducer,
    offers: offersReducer,
    campaigns: campaignsReducer,
    segments: segmentsReducer,
    users: usersReducer
  })
)
