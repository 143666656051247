import {
  BadgeProp,
  Badges,
  CampaignFormInterface,
  CampaignLimit,
  CampaignPreviewInterface,
  CampaignsInterface
} from './interfaces/campaigns'
import { useTranslation } from 'react-i18next'
import { RoleType } from '../core/routes/constants'
import { RouteItem, RoutePath } from '../core/routes/routePaths'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import { AppStatus, REORDER_KEY_NAME } from '../commons/constants/constants'
import { CampaignsStatuses } from './constants'
import _ from 'lodash'
import { CampaignCategoryInterface } from '../campaign-categories/interfaces/campaign-categories'
import { ReactElement } from 'react'
import { Box, Chip } from '@mui/material'
import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'
import { ReorderItem, getItemKey } from '../commons/utils/utils'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}
export interface SelectOption {
  text: string
  value: string
}
export interface ProviderOption {
  Name: string
}
const CAMPAIGN_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const setStatusColor = (status: CampaignsStatuses): AppStatus => {
  switch (status) {
    case CampaignsStatuses.LIVE_ACTIVE:
      return AppStatus.SUCCESS
    case CampaignsStatuses.LIVE_INACTIVE:
      return AppStatus.WARNING
    case CampaignsStatuses.HIDDEN_ACTIVE:
      return AppStatus.SUCCESS
    case CampaignsStatuses.HIDDEN_INACTIVE:
      return AppStatus.WARNING
    case CampaignsStatuses.EXPIRED:
      return AppStatus.ERROR
  }
}

export const campaignBarRoutePath = (id: string): Record<string, RouteItem> => {
  return {
    details: {
      path: `${RoutePath.Campaigns}/${id}`,
      title: i18n.t`Details`,
      setPath: true,
      roles: CAMPAIGN_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    segments: {
      path: `${RoutePath.Campaigns}/${id}${RoutePath.Segments}`,
      title: i18n.t`Segments`,
      roles: CAMPAIGN_PAGE_ROLES,
      icon: DonutSmallOutlinedIcon,
      children: []
    }
  }
}

export const GetLimitValue = (
  repeatableBucketClaims?: number,
  maxBucketClaimsRepetition?: number,
  campaignLimit?: CampaignLimit
): string => {
  const { t } = useTranslation()
  if (repeatableBucketClaims == null || maxBucketClaimsRepetition === 1) {
    return t`1TimeEarning`
  }

  if (maxBucketClaimsRepetition == null) {
    return t`NoLimits`
  }

  if (campaignLimit?.Count != null && campaignLimit?.Unit != null) {
    return `${maxBucketClaimsRepetition ?? ''} per ${
      campaignLimit.Count ?? ''
    } ${campaignLimit.Unit ?? ''}`
  }

  return `${maxBucketClaimsRepetition ?? ''}`
}

export const convertProvidersToArray = (
  existProviders: string | undefined
): string[] => {
  return existProviders != null ? existProviders.split(', ') : []
}

// TODO: unify interfaces
export const convertBadgesFormat = (
  existBadges: Badges[] | undefined
): BadgeProp[] => {
  return (
    existBadges?.map((singleBadge) => ({
      icon: singleBadge.BadgeIcon,
      label: singleBadge.BadgeLabel,
      ui: singleBadge.UiPosition.toString()
    })) ?? []
  )
}

export const setFileInfo = (fileValue: Blob): Blob | null => {
  return fileValue != null ? fileValue : null
}

export const getCampaignPreviewObject = (
  campaignForm: CampaignFormInterface,
  campaign: CampaignsInterface,
  categories: CampaignCategoryInterface[],
  isFromCreateEdit: boolean
): CampaignPreviewInterface => {
  return {
    name: isFromCreateEdit ? (campaignForm?.name as string) : campaign?.Name,
    ctaLink: isFromCreateEdit
      ? (campaignForm?.ctaLink as string)
      : campaign?.SignupURL,
    ctaName: isFromCreateEdit
      ? (campaignForm?.ctaName as string)
      : campaign?.ProviderSignupButtonLabel,
    description: isFromCreateEdit
      ? (campaignForm?.description as string)
      : campaign?.Description,
    rewardPerUser: isFromCreateEdit
      ? (campaignForm?.pointEarned as number)
      : campaign?.RewardPerUser,
    campaignType: isFromCreateEdit
      ? campaignForm?.campaignType
      : campaign?.CampaignType,
    url: isFromCreateEdit
      ? (campaignForm?.thumbnailID as string)
      : campaign?.Url,
    subHeader: isFromCreateEdit
      ? (campaignForm?.subHeader1 as string)
      : campaign?.SubHeader,
    title: isFromCreateEdit ? (campaignForm?.title as string) : campaign?.Title,
    subTitle: isFromCreateEdit
      ? (campaignForm?.subHeader2 as string)
      : campaign?.SubTitle,
    featuredUiPosition: isFromCreateEdit
      ? (campaignForm?.featuredUi as number)
      : campaign?.FeaturedUiPosition,
    isFeatured: isFromCreateEdit
      ? campaignForm?.isFeatured
      : !_.isNull(campaign?.FeaturedUiPosition),
    category: isFromCreateEdit
      ? categories.find((c) => c.CampaignCategoryID === campaignForm.category)
        ?.CategoryName
      : campaign?.CategoryName,
    badges: isFromCreateEdit
      ? campaignForm?.badge
      : convertBadgesFormat(campaign.badges)
  }
}

export const chip = (
  value: string,
  categories: CampaignCategoryInterface[]
): ReactElement => (
  <Chip
    key={value}
    label={categories.find((x) => x.CampaignCategoryID === parseInt(value))?.CategoryName}
  />
)

export const campaignsListActionItems = (campaignID: string): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.Campaigns}/${campaignID}${
        RoutePath.Segments
      }`,
      title: i18n.t`Segments`,
      icon: <DonutSmallOutlinedIcon />
    },
    {
      path: `${RoutePath.Campaigns}/${campaignID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}
export const getCampaignInfo = (item: ReorderItem): ReactElement => {
  const id = item[
    getItemKey(item, REORDER_KEY_NAME.CampaignIDKeyWord) as keyof ReorderItem
  ] as string
  const status = item[
    getItemKey(item, REORDER_KEY_NAME.CampaignStatus) as keyof ReorderItem
  ] as CampaignsStatuses
  return (
    <Box className='campaign-info-box'>
      {id != null && (
        <>
          <span className='campaign-id'>{i18n.t`ID`}: {id}</span>|
          <span
            className={`campaign-status-title ${setStatusColor(status)}`}
          >
            {status}
          </span>
        </>
      )}
    </Box>
  )
}
