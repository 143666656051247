import { axiosInstance } from '../core/api/init'
import {
  AgenciesInterface,
  AgenciesListSearch,
  AgenciesListSearchResponse
} from './interfaces/agencies'

export const agenciesService = {
  getAgencies: async (
    params: AgenciesListSearch
  ): Promise<AgenciesListSearchResponse> => {
    const res = await axiosInstance.get('/agencies', {
      params: {
        perPage: params.perPage,
        page: params.page,
        search: params.search,
        city: params.city,
        state: params.state,
        tenantID: params.tenantID
      }
    })
    return res.data
  },
  getAllAgencies: async (
    params: AgenciesListSearch
  ): Promise<AgenciesListSearchResponse> => {
    const res = await axiosInstance.get('/agencies', {
      params: {
        perPage: null,
        page: null,
        search: params.search,
        city: params.city,
        state: params.state,
        tenantID: params.tenantID
      }
    })
    return res.data
  },
  getAgencyByID: async (id: string): Promise<AgenciesInterface> => {
    const res = await axiosInstance.get('/agencies/' + id)
    return res.data
  },
  createAgency: async (
    agency: AgenciesInterface
  ): Promise<AgenciesInterface> => {
    const res = await axiosInstance.post('/agencies/', {
      agencyIconID: agency.AgencyIconID,
      state: agency.State,
      city: agency.City,
      email: agency.Email,
      name: agency.Name,
      dashboardAccess: agency.DashboardAccess,
      tenantID: agency.TenantID
    })
    return res.data
  },
  editAgency: async (
    id: string,
    agency: AgenciesInterface
  ): Promise<AgenciesInterface> => {
    const res = await axiosInstance.put('/agencies/' + id, {
      name: agency.Name,
      agencyIconID: agency.AgencyIconID,
      state: agency.State,
      city: agency.City,
      email: agency.Email,
      dashboardAccess: agency.DashboardAccess
    })
    return res.data
  },
  editAgencyStatus: async (
    id: string,
    status: string
  ): Promise<AgenciesInterface> => {
    const res = await axiosInstance.patch('/agencies/' + id, {
      AgencyStatus: status
    })
    return res.data
  },
  resendAgencyEmail: async (
    id: string,
    type: string
  ): Promise<AgenciesInterface> => {
    const res = await axiosInstance.post(`/agencies/${id ?? ''}/resend-email`, {
      emailType: type
    })
    return res.data
  }
}

export default agenciesService
