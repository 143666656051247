import { FC, useCallback } from 'react'
import { UsersListParams, TransactionsInterface } from '../interfaces/users'
import {
  PER_PAGE_WALLET_TABLE,
  FIRST_PAGE_WALLET_TABLE,
  WalletListColumns,
  transactionConditionType
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import {
  convertStringToDateAndTime,
  setClassNameForTable
} from '../../commons/utils/utils'

interface WalletListProps {
  onPageChange: (changedData: UsersListParams) => void
  page: number
  perPage: number
  total: number
  transactionsList: TransactionsInterface[]
}

interface TransactionDescription {
  type: string
  title?: string
  id?: string
}

const COLUMNS = Object.values(WalletListColumns)

export const getDescription = (
  transaction: TransactionsInterface
): TransactionDescription | null => {
  const description = Object.entries(transactionConditionType)
    .filter(([k, v]) => transaction[k as keyof typeof transaction] != null)
    .map((t) => {
      const value = t[1]
      return {
        type: value.type,
        title:
          value.titleFieldName != null
            ? transaction[value.titleFieldName as keyof typeof transaction]
            : null,
        id:
          value.idFieldName != null
            ? transaction[value.idFieldName as keyof typeof transaction]
            : null
      }
    })
  return description[0] as TransactionDescription
}

const WalletList: FC<WalletListProps> = ({
  page,
  perPage,
  total,
  transactionsList,
  onPageChange
}) => {
  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='transactions table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {transactionsList.length > 0 && (
          <TableBody>
            {transactionsList?.map((row) => {
              const description = getDescription(row)
              const date = convertStringToDateAndTime(row.TimestampUTC)
              return (
                <TableRow className='row-with-actions' key={row.TransactionID}>
                  <TableCell className='body-cell'>
                    {row.TransactionID}
                  </TableCell>
                  <TableCell className='body-cell'>
                    {description?.type}
                    <Box className='mini-subtitle'>
                      {[description?.title, description?.id]
                        .filter((v) => v)
                        .join(' - ')}
                    </Box>
                  </TableCell>
                  <TableCell className='body-cell'>
                    {[date.date, date.time].filter((v) => v).join(' | ')}
                  </TableCell>
                  <TableCell
                    className={`body-cell ${
                      (row.Value < 0 ? 'error' : '') as string
                    }`}
                  >
                    {+row.Value}
                  </TableCell>
                  <TableCell className='body-cell' />
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        perPage={perPage ?? PER_PAGE_WALLET_TABLE}
        page={page ?? FIRST_PAGE_WALLET_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={transactionsList.length}
      />
    </TableContainer>
  )
}

export default WalletList
