import { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { t } from 'i18next'
import { PER_PAGE_USERS_TABLE, FIRST_PAGE_USERS_TABLE } from '../constants'
import { getValueForForm } from '../../commons/utils/utils'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { PAGE, constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import UserVisibleOffersList from './UserVisibleOffersList'
import { getUserByID, getUserOffers } from '../slices'
import UserVisibleOffersListHeader from './UserVisibleOffersListHeader'
import { RoleType } from '../../core/routes/constants'
import { OffersListParams } from '../../offers/interfaces/offers'

const UserVisibleOffers = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const {
    users: { usersOffers, totalUsersOffers },
    auth: { agencyID: loggedAgency, userType, isAgencyEnabled }
  } = useAppSelector((state) => state)

  const handleFormChange = ({
    agencyID,
    search,
    categories,
    offerType,
    page,
    perPage
  }: OffersListParams): void => {
    Object.entries({
      agencyID,
      search,
      categories,
      offerType,
      page,
      perPage
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      setSearchParams((params) => {
        if (Array.isArray(checkedValue) && checkedValue.length === 0) {
          params.delete(k)
        } else if (checkedValue != null) {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
        }
        return params
      })
    })
  }

  useEffect(() => {
    if (id != null) {
      void dispatch(getUserByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.USER))
      dispatch(setBackButtonTitle(t`BackToUsers`))
      dispatch(setBackButtonRoute(RoutePath.Users))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getUserOffers({
          userID: id,
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          ),
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          ),
          search: searchParams.get('search') ?? '',
          agencyID: searchParams.get('agencyID') ?? '',
          offerType: searchParams.get('offerType') ?? '',
          categories: searchParams.get('categories')?.split(',') ?? []
        })
      )
    }
  }, [id, dispatch, searchParams])

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`UserVisibleOffers`}</h1>
        <UserVisibleOffersListHeader
          handleFormChange={handleFormChange}
          agencyID={loggedAgency ?? searchParams.get('agencyID') ?? ''}
          search={searchParams.get('search') ?? ''}
          offerType={searchParams.get('offerType') ?? ''}
          categories={searchParams.get('categories')?.split(',') ?? []}
        />
        <UserVisibleOffersList
          showAgency={userType !== RoleType.AGENCY && isAgencyEnabled}
          offers={usersOffers}
          total={totalUsersOffers}
          onPageChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default UserVisibleOffers
