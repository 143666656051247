import { ReactElement, useState } from 'react'
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import './dropzone.scss'
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { getSize } from '../../commons/components/uploader/utils'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useTranslation } from 'react-i18next'

interface DropzoneProps {
  setFile?: (file?: File) => void
  name?: string
  url?: string
  onFormChange?: (value: number) => void
  isThumbnail?: boolean
  thumbnailID?: string | null | undefined
  isImage?: boolean
  file?: File | null
  testId?: string
}

interface DropzoneStateProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  testId?: string
}

const ACCEPTED_IMG_TYPES = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png']
}

const ACCEPTED_TYPES = {
  'text/csv': ['.csv']
}

const MAX_SIZE = 1000000

const ImageDropZone = ({
  setFile,
  name,
  url,
  testId,
  onFormChange,
  isThumbnail = false,
  thumbnailID = null,
  isImage = false,
  file = null,
  ...rest
}: DropzoneProps & React.HTMLProps<HTMLInputElement>): ReactElement => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [path, setPath] = useState(url ?? undefined)
  const [sizeError, setSizeError] = useState(false)
  const { t } = useTranslation()
  const onDrop = (acceptedFiles: File[]): void => {
    const file = acceptedFiles[0]
    if (file.size > MAX_SIZE) {
      setSizeError(true)
      setSelectedFile(undefined)
    } else {
      setSelectedFile(file)
      setPath(URL.createObjectURL(file))
      setSizeError(false)
      setFile?.(file)
    }
  }

  const deleteFile = (): void => {
    setPath(undefined)
    setSelectedFile(undefined)
    setFile?.(undefined)
    onFormChange?.(0)
  }

  const dropZone = ({
    getRootProps,
    getInputProps,
    testId
  }: DropzoneStateProps): ReactElement => (
    <section>
      <div className='drop-zone' {...getRootProps()}>
        <input
          id={name}
          data-testid={testId}
          {...rest}
          {...getInputProps({ name })}
        />
        <Box textAlign='center'>
          <FileUploadOutlinedIcon className='highlighted-text' />
          <Typography>
            {isImage ? t`DragAndDropImage` : t`DragAndDropFile`}{' '}
            <span className='highlighted-text'>{t`Browse`}</span>
          </Typography>
          <Typography fontSize='.88em' marginBottom='1em'>
            {t`MaxSize1MB`}
          </Typography>
          <Typography fontSize='.75em'>
            <span>{t`AspectRatioSquare`}</span>
            <span className='ml-2'>{t`120x120Size`}</span>
          </Typography>
        </Box>
      </div>
    </section>
  )

  return (
    <Box>
      <Dropzone
        accept={isImage ? ACCEPTED_IMG_TYPES : ACCEPTED_TYPES}
        maxFiles={1}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }: DropzoneStateProps) =>
          selectedFile != null ||
          (path != null && path !== '') ||
          (thumbnailID != null && thumbnailID !== '')
            ? (
              <Grid container className='dropzone-card'>
                <Box display='flex' width='100%'>
                  <Box display='flex' justifyContent='flex-start'>
                    {isImage && (
                      <img
                        className='square-image mr-1'
                        src={
                        isThumbnail && thumbnailID != null ? thumbnailID : path
                      }
                        alt='dropzone-img'
                      />
                    )}
                  </Box>
                  {selectedFile != null && (
                    <Box className='mt-1'>
                      <Typography className='highlighted-text'>
                        {selectedFile?.name}
                      </Typography>
                      <Typography className='card-text'>
                        {getSize(selectedFile?.size)}
                      </Typography>
                    </Box>
                  )}
                  {selectedFile == null && file != null && (
                    <Box className='mt-1'>
                      <Typography className='highlighted-text'>
                        {file?.name}
                      </Typography>
                      <Typography className='card-text'>
                        {getSize(file?.size)}
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() => deleteFile()}
                    id='delete'
                    disableRipple
                    aria-label='delete'
                    color='error'
                    className='delete-icon'
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Box>
                <Grid item xs={12}>
                  {dropZone({ getRootProps, getInputProps })}
                </Grid>
              </Grid>
              )
            : (
              <Box>{dropZone({ getRootProps, getInputProps })}</Box>
              )}
      </Dropzone>
      {sizeError && <FormHelperText error>{t`MaxSize1MB`}</FormHelperText>}
    </Box>
  )
}

export default ImageDropZone
