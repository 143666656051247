export const PER_PAGE_OFFERS_TABLE = 10
export const FIRST_PAGE_OFFERS_TABLE = 0
export const OFFER_TYPE_UNIQUE_CODE = 'UNIQUE_CODE'
export const PER_PAGE_COUPONS_TABLE = 10
export const FIRST_PAGE_COUPONS_TABLE = 0
export const MAX_LENGTH_USER_ID = 15
export const MAX_LENGTH_COUPON_CODE = 16

export enum AgencyOffersListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'Ui Position',
  'STATUS' = 'Status',
  'AVAILABLE' = 'Available',
  'REDEEMED' = 'Redeemed',
  'MENU' = ''
}

export enum TenantOffersListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'Ui Position',
  'STATUS' = 'Status',
  'AVAILABLE' = 'Available',
  'REDEEMED' = 'Redeemed',
  'MENU' = ''
}

export const offersConstants = {
  Domain: 'offers',
  OFFER_STATUS: {
    LIVE: 'Live',
    HIDDEN: 'Hidden',
    EXPIRED: 'Expired'
  }
}

export enum OffersStatuses {
  LIVE = 'Live',
  HIDDEN = 'Hidden',
  EXPIRED = 'Expired'
}

export const FilterOfferStatus = {
  LIVE: 'Live',
  HIDDEN: 'Hidden',
  EXPIRED: 'Expired'
}

export const FilterOfferTypes = {
  DIRECT: 'Direct',
  DIRECT_EXPIRE: 'Direct Expire',
  DIRECT_EXPIRE_TICKET: 'Direct Expire Ticket',
  UNIQUE_CODE: 'Unique Code'
}

export enum CouponsListColumns {
  'ID' = 'ID',
  'COUPON_CODE' = 'Coupon Code',
  'CREATED_DATE' = 'Created Date',
  'STATUS' = 'Status',
  'REDEEMED_DATE' = 'Redeemed Date',
  'USER' = 'User',
  'MENU' = ''
}

export enum RepeatableSettingsListColumns {
  'COUPON_CODE' = 'Coupon Code',
  'TIMES_CAN_BE_REDEEMED' = 'Times can be Redeemed',
  'AVAILABLE_CODES' = 'Available Codes',
  'REDEEMED_CODES' = 'Redeemed Codes',
  'MENU' = ''
}

export const FilterCouponStatus = {
  AVAILABLE: 'Available',
  REDEEMED: 'Redeemed',
  REFUND: 'Refund'
}

export const CouponStatuses = {
  AVAILABLE: 'AVAILABLE',
  REDEEMED: 'REDEEMED',
  REFUND: 'REFUND'
}

export const HEADER_STATUS_COLUMN = 'Status'

export const formValidationConstants = {
  requiredField: { value: true, message: 'Required Field' },
  minLengthError: {
    value: 3,
    message: 'Length: Name must be between 3 and 50 characters.'
  },
  maxLengthError: {
    value: 50,
    message: 'Length: Name must be between 3 and 50 characters.'
  },
  numberPattern: { value: /^[0-9]+$/, message: 'Only numbers are allowed.' },
  minNum: { value: 0, message: 'Length: Must be between 0 and 10000 points.' },
  maxNum: {
    value: 10000,
    message: 'Length: Must be between 0 and 10000 points.'
  },
  descriptionLengthError:
    'Length: Description must be between 3 and 1000 characters.',
  instructionsLengthError:
    'Length: Instructions must be between 3 and 1000 characters.',
  startDateError: { value: true, message: 'Empty: Select a Start Date' },
  endDateError: { value: true, message: 'Empty: Select an End Date' },
  minValue: { value: 1, message: 'Required Field' }
}

export const CSVFileHeadersCoupons = [
  { label: 'Created Date', key: 'CreatedDate' },
  { label: 'Coupon Code', key: 'CouponCode' },
  { label: 'Status', key: 'Status' },
  { label: 'Redeemed Date', key: 'RedeemedDate' },
  { label: 'User', key: 'UserID' }
]

export const FREE = 'Free'

export const OFFER_FORM_CONSTANTS = {
  THUMBNAIL_ID: 'thumbnailID',
  ICON_ID: 'offerIconID',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  HAS_LIMIT: 'hasRedemptionLimit'
}

export const STEPS_FOR_OFFER_STEPPER = [
  'Offer Information',
  'Coupon Information',
  'Audience',
  'Preview'
]

export const COUPONE_TYPE = {
  UNIQUE: 'Unique',
  REPEATABLE: 'Repeatable'
}
