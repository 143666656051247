import { ReactElement, useEffect } from 'react'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { getOfferCategoryByID, getOffersByCategoryID } from '../slices'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { PAGE, constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import { OffersListParams } from '../../offers/interfaces/offers'
import { Box, CircularProgress } from '@mui/material'
import OffersList from '../../offers/OffersList'
import OffersListHeader from './CategoryOffersListHeader'
import { getValueForForm } from '../../commons/utils/utils'
import {
  PER_PAGE_OFFER_CATEGORIES_TABLE,
  FIRST_PAGE_OFFER_CATEGORIES_TABLE
} from '../constants'

const CategoryOffers = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { tenantID, agencyID, userType, offers, totalOffersList } =
    useAppSelector((state) => ({
      tenantID: state.auth.tenantID,
      agencyID: state.auth.agencyID,
      userType: state.auth.userType,
      offers: state.offerCategories.offers,
      totalOffersList: state.offerCategories.totalOffersList
    }))

  const { id } = useParams<{ id: string }>()

  const handleFormChange = ({
    agencyID,
    tenantID,
    search,
    page,
    perPage,
    status,
    offerType
  }: OffersListParams): void => {
    Object.entries({
      agencyID,
      tenantID,
      search,
      page,
      perPage,
      status,
      offerType
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getOfferCategoryByID({
          id,
          userType,
          agencyID,
          tenantID
        })
      )
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER_CATEGORY))
      dispatch(setBackButtonTitle(t`OfferCategories`))
      dispatch(setBackButtonRoute(RoutePath.OfferCategories))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, tenantID, agencyID, userType])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getOffersByCategoryID({
          id,
          tenantID,
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ??
              FIRST_PAGE_OFFER_CATEGORIES_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ??
              PER_PAGE_OFFER_CATEGORIES_TABLE.toString()
          ),
          agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
          status: searchParams.get('status') ?? '',
          offerType: searchParams.get('offerType') ?? '',
          userType
        })
      )
    }
  }, [id, agencyID, tenantID, dispatch, searchParams, userType])

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <Box>
          <h1 className='title'>{t`AllOffers`}</h1>
        </Box>
        <OffersListHeader
          search={searchParams.get('search') ?? undefined}
          agency={agencyID ?? searchParams.get('agencyID') ?? ''}
          status={searchParams.get('status') ?? ''}
          offerType={searchParams.get('offerType') ?? ''}
          handleFormChange={handleFormChange}
        />
        <OffersList
          offersList={offers}
          total={totalOffersList}
          handleFormChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ??
            FIRST_PAGE_OFFER_CATEGORIES_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ??
            PER_PAGE_OFFER_CATEGORIES_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default CategoryOffers
