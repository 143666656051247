import { FC, useEffect } from 'react'
import SelfServeButton from '../ui/button/Button'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import {
  FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE,
  PER_PAGE_CAMPAIGN_CATEGORIES_TABLE
} from './constants'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import CampaignCategoriesList from './CampaignCategoriesList'
import CampaignCategoriesListHeader from './CampaignCategoriesListHeader'
import { CampaignCategoryListParams } from './interfaces/campaign-categories'
import { userHasTenantPermission } from '../core/routes/router'
import { getRoleTypeByString, getValueForForm } from '../commons/utils/utils'
import { RoutePath } from '../core/routes/routePaths'
import { Box } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { getCategories } from './slices'
import { PAGE, USERTYPE, constants } from '../commons/constants/constants'

const CampaignCategories: FC<{}> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { tenantID, userType, agencyID, isAgencyEnabled, categories, totals } =
    useAppSelector((state) => ({
      tenantID: state.auth.tenantID,
      userType: state.auth.userType,
      agencyID: state.auth.agencyID,
      isAgencyEnabled: state.auth.isAgencyEnabled,
      categories: state.campaignCategories.categories,
      totals: state.campaignCategories.totals
    }))

  const isTenant = userHasTenantPermission(getRoleTypeByString(userType))

  const handleFormChange = ({
    perPage,
    page,
    tenantID,
    agencyID
  }: CampaignCategoryListParams): void => {
    Object.entries({
      perPage,
      page,
      tenantID,
      agencyID,
      userType
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE && k !== USERTYPE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    void dispatch(
      getCategories({
        tenantID,
        agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
        page: parseInt(
          searchParams.get('page') ??
            FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ??
            PER_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
        )
      })
    )
  }, [tenantID, agencyID, searchParams, dispatch])

  return (
    <Box className='category-list'>
      <Box className='category-list-headers'>
        <h1 className='title'>{t`CampaignCategories`}</h1>
        <SelfServeButton id='new-campaign-category-button' variant='contained'>
          <Link
            to={RoutePath.CreateCampaignCategories}
            className='neutral-link'
          >
            <AddOutlinedIcon className='button-icon' />
            {t`NewCampaignCategory`}
          </Link>
        </SelfServeButton>
      </Box>
      {isTenant && isAgencyEnabled && (
        <Box>
          <CampaignCategoriesListHeader
            agencyID={agencyID ?? searchParams.get('agencyID') ?? ''}
            handleFormChange={handleFormChange}
          />
        </Box>
      )}
      <Box>
        <CampaignCategoriesList
          isTenant={isTenant}
          page={parseInt(
            searchParams.get('page') ??
              FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ??
              PER_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          )}
          onPageChange={handleFormChange}
          campaignCategoriesList={categories}
          total={totals}
        />
      </Box>
    </Box>
  )
}

export default CampaignCategories
