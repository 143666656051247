import { ReactElement } from 'react'
import { Box, Divider, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  setDashIfEmpty,
  checkIsNotEmpty,
  dashBox,
  convertStringToDateAndTime
} from '../../commons/utils/utils'
import '../segments.scss'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'

const SegmentsInfoStep = (): ReactElement | null => {
  const { t } = useTranslation()
  const {
    auth: { userType, isAgencyEnabled },
    segments: {
      segmentSelected: {
        Name,
        AgencyName,
        description,
        inviteCodeSegmentName,
        welcomeDialogImageUrl,
        welcomeDialogMessage,
        welcomeDialogLabel,
        welcomeDialogTitle,
        welcomePoints,
        welcomePointsUrl,
        welcomePointsTitle,
        expiryDate
      }
    }
  } = useAppSelector((state) => state)
  const { date: expiredDate, time: expiredTime } = convertStringToDateAndTime(
    expiryDate as string
  )

  const hasWelcomeDialog = !_.isEmpty(welcomeDialogMessage)
  const hasWelcomePoints = !_.isEmpty(welcomePointsTitle)

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          {userType !== RoleType.AGENCY && isAgencyEnabled && (
            <SelfServeROTextField
              id='agencyName'
              label={t`Agency`}
              defaultValue={setDashIfEmpty(AgencyName)}
              showDivider
            />
          )}
          <SelfServeROTextField
            id='segmentName'
            label={t`SegmentName`}
            defaultValue={
              !_.isEmpty(inviteCodeSegmentName) ? inviteCodeSegmentName : Name
            }
            classNameDivider='mb-0'
            showDivider
          />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mt-0'
          >
            {t`Description`}
          </InputLabel>
          {description != null
            ? (
              <ReactMarkdown linkTarget='_blank' className='description-text'>
                {description}
              </ReactMarkdown>
              )
            : (
                dashBox
              )}
          <Divider className='divider mt-01' />
          <SelfServeROTextField
            id='expiredDate'
            label={t`ExpiredDate`}
            defaultValue={
              !_.isEmpty(expiryDate)
                ? `${expiredDate ?? ''} | ${expiredTime ?? ''}`
                : '-'
            }
            showDivider
          />
          <SelfServeROTextField
            id='hasWelcomeDialog'
            label={t`HasWelcomeDialog`}
            defaultValue={hasWelcomeDialog ? t`Yes` : t`No`}
            showDivider
          />
          {hasWelcomeDialog && (
            <>
              <InputLabel
                size='small'
                variant='standard'
                className='small-label'
              >
                {t`DialogImage`}
              </InputLabel>
              {checkIsNotEmpty(welcomeDialogImageUrl)
                ? (
                  <img
                    className='thumbnail mb-1'
                    src={welcomeDialogImageUrl}
                    alt='thumbnail'
                  />
                  )
                : (
                    dashBox
                  )}
              <Divider className='divider mt-01' />
              <SelfServeROTextField
                id='dialogTitle'
                label={t`DialogTitle`}
                defaultValue={setDashIfEmpty(welcomeDialogTitle)}
                showDivider
              />
              <SelfServeROTextField
                id='dialogContent'
                label={t`DialogContent`}
                defaultValue={setDashIfEmpty(welcomeDialogMessage)}
                multiline
                showDivider
              />
              <SelfServeROTextField
                id='dialogCTALabel'
                label={t`DialogCTALabel`}
                defaultValue={setDashIfEmpty(welcomeDialogLabel)}
                showDivider
              />
            </>
          )}
          <SelfServeROTextField
            id='hasWelcomePoints'
            label={t`HasWelcomePoints`}
            defaultValue={hasWelcomePoints ? t`Yes` : t`No`}
            showDivider
          />
          {hasWelcomePoints && (
            <>
              <SelfServeROTextField
                id='earningTitle'
                label={t`EarningTitle`}
                defaultValue={setDashIfEmpty(welcomePointsTitle)}
                classNameDivider='mb-0'
                showDivider
              />
              <InputLabel
                size='small'
                variant='standard'
                className='small-label'
              >
                {t`EarningIcon`}
              </InputLabel>
              {checkIsNotEmpty(welcomePointsUrl)
                ? (
                  <img
                    className='icon mb-1'
                    src={welcomePointsUrl}
                    alt='welcomePointsUrl'
                  />
                  )
                : (
                    dashBox
                  )}
              <Divider className='divider' />
              <SelfServeROTextField
                id='points'
                label={t`Points`}
                defaultValue={`${welcomePoints != null ? +welcomePoints : ''} ${
                  t`points` as string
                }`}
                showDivider
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SegmentsInfoStep
