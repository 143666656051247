import { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Typography } from '@mui/material'
import { t } from 'i18next'
import CouponsList from './CouponsList'
import {
  FIRST_PAGE_COUPONS_TABLE,
  PER_PAGE_COUPONS_TABLE,
  OFFER_TYPE_UNIQUE_CODE,
  FilterCouponStatus,
  CSVFileHeadersCoupons
} from '../constants'
import {
  deleteAllAvailableCouponsByOfferID,
  deleteCouponByOfferID,
  getCouponsByOfferID,
  getOfferDetailsByID,
  refundCouponByID,
  resetCouponUpdated,
  resetErrorMessage
} from '../slices'
import { CouponsListParams } from '../interfaces/offers'
import {
  errorDisplay,
  getValueForForm,
  setDashIfEmpty
} from '../../commons/utils/utils'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { PAGE, constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import CouponsListHeader from './CouponsListHeader'
import SelfServeButton from '../../ui/button/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import './OffersCoupons.scss'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { CSVLink } from 'react-csv'

const OffersCoupons = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const {
    coupons,
    totalCoupons,
    OfferType,
    loading,
    couponsUpdated,
    errorMessage
  } = useAppSelector((state) => ({
    coupons: state.offers.coupons,
    totalCoupons: state.offers.totalCoupons,
    OfferType: state.offers.offerSelected.OfferType,
    loading: state.offers.loading,
    couponsUpdated: state.offers.couponsUpdated,
    errorMessage: state.offers.errorMessage
  }))

  const [openConfirmDeleteAllCodes, SetOpenConfirmDeleteAllCodes] =
    useState<boolean>(false)

  const handleFormChange = ({
    search,
    page,
    perPage,
    status,
    redeemDateStart,
    redeemDateEnd
  }: CouponsListParams): void => {
    Object.entries({
      search,
      page,
      perPage,
      status,
      redeemDateStart,
      redeemDateEnd
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  const handleClose = (): void => {
    SetOpenConfirmDeleteAllCodes(false)
  }

  const deleteAllAvailableCoupons = (): void => {
    if (id != null) {
      void dispatch(deleteAllAvailableCouponsByOfferID(id))
    }
    handleClose()
  }

  const deleteSelectedAvailableCoupon = (couponID?: number): void => {
    if (id != null && couponID != null) {
      void dispatch(
        deleteCouponByOfferID({
          OfferID: id,
          CouponID: couponID
        })
      )
    }
  }

  const refundSelectedCoupon = (couponID?: number): void => {
    if (id != null && couponID != null) {
      void dispatch(
        refundCouponByID({
          OfferID: id,
          CouponID: couponID
        })
      )
    }
  }

  const mainMessageDeleteCode = (
    <div className='delete-coupon-dialog-msg mb-1'>
      {t`DeleteAllCouponsConfirm`}
      <Typography className='delete-coupon-highlight'>
        "{t`All`}
      </Typography>{' '}
      <br />
      <Typography className='delete-coupon-highlight'>
        {t`AvailableCoupons`}"
      </Typography>
      ?
    </div>
  )

  const renderConfirmDeleteAllCodes = (
    <CustomDialog
      title={t`DeleteCoupons`}
      open={openConfirmDeleteAllCodes}
      cancelCallback={handleClose}
      okCallback={deleteAllAvailableCoupons}
      mainMessage={mainMessageDeleteCode}
      okText={t`CouponDialogMessageOk`}
      cancelText={t`CouponDialogMessageCancel`}
      isCustomized
      titleClassName='coupon-dialog-title title'
      actionClassName='coupon-dialog-actions'
      customColor='error'
    />
  )

  useEffect(() => {
    if (id != null && couponsUpdated) {
      void dispatch(
        getCouponsByOfferID({
          id,
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_COUPONS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_COUPONS_TABLE.toString()
          ),
          status: searchParams.get('status') ?? '',
          redeemDateStart: searchParams.get('redeemDateStart') ?? '',
          redeemDateEnd: searchParams.get('redeemDateEnd') ?? ''
        })
      )
      dispatch(resetCouponUpdated())
    }
  }, [id, couponsUpdated, dispatch, searchParams])

  useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getCouponsByOfferID({
          id,
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_COUPONS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_COUPONS_TABLE.toString()
          ),
          status: searchParams.get('status') ?? '',
          redeemDateStart: searchParams.get('redeemDateStart') ?? '',
          redeemDateEnd: searchParams.get('redeemDateEnd') ?? ''
        })
      )
      dispatch(resetErrorMessage())
    }
  }, [id, dispatch, searchParams])

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <Box className='coupon-header'>
          <h1 className='title'>{t`OfferCoupons`}</h1>
          <Box className='button-box'>
            <SelfServeButton
              id='generate-coupon-codes'
              className='mr-1'
              variant='contained'
              color='secondary'
            >
              <CSVLink
                headers={CSVFileHeadersCoupons}
                data={coupons.map((coupon) => {
                  const {
                    CreatedDate,
                    CouponCode,
                    Status,
                    RedeemedDate,
                    UserID
                  } = coupon
                  return {
                    CreatedDate,
                    CouponCode,
                    Status: Status ?? FilterCouponStatus.AVAILABLE,
                    RedeemedDate: setDashIfEmpty(RedeemedDate),
                    UserID: setDashIfEmpty(UserID)
                  }
                })}
                filename={`coupons-${Date.now()}.csv`}
                className='neutral-link-secondary'
              >
                <FileDownloadOutlinedIcon
                  fontSize='small'
                  className='button-icon mr-0'
                />
                {t`GetCoupons`}
              </CSVLink>
            </SelfServeButton>
            {OfferType === OFFER_TYPE_UNIQUE_CODE && (
              <SelfServeButton
                id='delete-coupon-codes'
                className='mr-1'
                variant='contained'
                color='secondary'
                onClick={() => SetOpenConfirmDeleteAllCodes(true)}
              >
                <DeleteOutlineOutlinedIcon
                  fontSize='small'
                  className='button-icon mr-0'
                />
                {t`DeleteAvailCoupons`}
              </SelfServeButton>
            )}
            {OfferType === OFFER_TYPE_UNIQUE_CODE && (
              <SelfServeButton
                color='primary'
                id='create-coupon'
                variant='contained'
                onClick={() =>
                  navigate(
                  `${RoutePath.Offers}/${id ?? ''}${RoutePath.Coupons}/create`
                  )}
              >
                <AddOutlinedIcon className='button-icon' /> {t`NewCoupon`}
              </SelfServeButton>
            )}
          </Box>
        </Box>
        <CouponsListHeader
          search={searchParams.get('search') ?? ''}
          status={searchParams.get('status') ?? ''}
          redeemDateStart={searchParams.get('redeemDateStart') ?? ''}
          redeemDateEnd={searchParams.get('redeemDateEnd') ?? ''}
          handleFormChange={handleFormChange}
        />
        <CouponsList
          couponsList={coupons}
          total={totalCoupons}
          onPageChange={handleFormChange}
          onDeleteCoupon={deleteSelectedAvailableCoupon}
          onRefundCoupon={refundSelectedCoupon}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_COUPONS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_COUPONS_TABLE.toString()
          )}
        />
        {renderConfirmDeleteAllCodes}
        {errorDisplay(errorMessage)}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default OffersCoupons
