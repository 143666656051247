import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { offersService } from './services'
import { OffersState } from '../../type'
import {
  OFFER_FORM_CONSTANTS,
  OffersStatuses,
  offersConstants
} from './constants'
import {
  CouponsListParams,
  CouponsParamsInterface,
  CouponUpdateParamsInterface,
  OfferFormInterface,
  OffersErrorInterface,
  OffersInterface,
  OffersListParams,
  OfferTargetGroup,
  OffersPayloadInterface,
  RepeatableCouponsParamsInterface
} from './interfaces/offers'
import { handleRejectWithReport } from '../core/error/handler'
import {
  createImageID,
  handleSliceErrors,
  setFileInfo
} from '../commons/utils/utils'

export const getLiveOfferByID = createAsyncThunk(
  'offers/getLiveOfferByID',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await offersService.getLiveOfferByID(id)
      return response
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getOffers = createAsyncThunk(
  'categories/getOffers',
  async (params: OffersListParams, { rejectWithValue }) => {
    try {
      return await offersService.getOffers(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const createOffer = createAsyncThunk(
  'offers/createOffers',
  async (params: OfferFormInterface, { rejectWithValue }) => {
    try {
      return await offersService.createOffer(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editOffer = createAsyncThunk(
  'offers/editOffers',
  async (params: OfferFormInterface, { rejectWithValue }) => {
    try {
      return await offersService.editOffer(params, params.id ?? '')
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const addCouponsByOfferID = createAsyncThunk(
  'offers/addCouponsByOfferID',
  async (params: CouponsParamsInterface, { rejectWithValue }) => {
    try {
      const { couponCodes, offerID } = params
      return await offersService.addCouponsByOfferID(couponCodes, offerID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const addRepeatableCouponsByOfferID = createAsyncThunk(
  'offers/addRepeatableCouponsByOfferID',
  async (params: RepeatableCouponsParamsInterface, { rejectWithValue }) => {
    try {
      const { codes, OfferID } = params
      return await offersService.addRepeatableCouponsByOfferID(codes, OfferID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const editOfferStatus = createAsyncThunk(
  'offers/editOfferStatus',
  async ({ OfferID, offerstatus }: OffersInterface, { rejectWithValue }) => {
    try {
      if (OfferID != null && offerstatus != null) {
        return await offersService.editOfferStatus(
          OfferID.toString(),
          offerstatus
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getCouponsByOfferID = createAsyncThunk(
  'offers/getCouponsByOfferID',
  async (params: CouponsListParams, { rejectWithValue }) => {
    if (params.id != null) {
      try {
        const response = await offersService.getCouponsByOfferID(
          params,
          params?.id
        )
        return response
      } catch (error: any) {
        return handleRejectWithReport(rejectWithValue, error)
      }
    }
  }
)

export const deleteAllAvailableCouponsByOfferID = createAsyncThunk(
  'offers/deleteAllAvailableCouponsByOfferID',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await offersService.deleteAllAvailableCouponsByOfferID(
        id
      )
      return response
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const deleteCouponByOfferID = createAsyncThunk(
  'offers/deleteCouponByOfferID',
  async (params: CouponUpdateParamsInterface, { rejectWithValue }) => {
    try {
      const response = await offersService.deleteCouponByOfferID(params)
      return response
    } catch (error: any) {
      error.message = error.response.data
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const refundCouponByID = createAsyncThunk(
  'offers/refundCouponByID',
  async (params: CouponUpdateParamsInterface, { rejectWithValue }) => {
    try {
      const response = await offersService.refundCouponByID(params)
      return response
    } catch (error: any) {
      error.message = error.response.data
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getRepeatableCouponsByOfferID = createAsyncThunk(
  'offers/getRepeatableCouponsByOfferID',
  async (params: CouponsListParams, { rejectWithValue }) => {
    if (params.id != null) {
      try {
        const response = await offersService.getRepeatableCouponsByOfferID(
          params,
          params?.id
        )
        return response
      } catch (error: any) {
        return handleRejectWithReport(rejectWithValue, error)
      }
    }
  }
)

export const getOfferDetailsByID = createAsyncThunk(
  'offers/getOfferDetailsByID',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await offersService.getOfferDetailsByID(id)
      return response
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const removeOfferFromTargetGroup = createAsyncThunk(
  'offers/removeOfferFromTargetGroup',
  async ({ OfferID, TargetGroupID }: OfferTargetGroup, { rejectWithValue }) => {
    try {
      if (OfferID != null && TargetGroupID != null) {
        return await offersService.removeOfferFromTargetGroup(
          OfferID,
          TargetGroupID
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const addOfferToTargetGroup = createAsyncThunk(
  'agencies/addOfferToTargetGroup',
  async ({ OfferID, TargetGroupID }: OfferTargetGroup, { rejectWithValue }) => {
    try {
      if (OfferID != null && TargetGroupID != null) {
        return await offersService.addOfferToTargetGroup(OfferID, TargetGroupID)
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const excludeOfferFromTargetGroup = createAsyncThunk(
  'agencies/excludeOfferFromTargetGroup',
  async ({ OfferID, TargetGroupID }: OfferTargetGroup, { rejectWithValue }) => {
    try {
      if (OfferID != null && TargetGroupID != null) {
        return await offersService.excludeOfferFromTargetGroup(
          OfferID,
          TargetGroupID
        )
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

const offersInitialState = {
  offers: [],
  offerCreateForm: {
    offerName: '',
    title: '',
    thumbnailUrl: '',
    thumbnailID: null,
    listedPoints: null,
    realPoints: null,
    description: '',
    agencyID: '',
    categoryID: '',
    webUiPosition: null,
    offerIconUrl: '',
    offerIconID: null,
    instructions: '',
    redirectionLink: '',
    isARepeatableCoupon: false,
    hasRedemptionLimit: false,
    offerRedeemTimesCount: null,
    offerRedeemTimesDays: null,
    maxTimeRewardPerUser: null,
    startDate: null,
    endDate: null,
    segmentsIncluded: [],
    segmentsExcluded: [],
    thumbnailfile: null,
    offerIconFile: null,
    startDateTimeStamp: '',
    endDateTimeStamp: ''
  },
  isFormTouched: false,
  isFormUpdated: false,
  totalOffersList: 0,
  liveOfferSelected: {},
  offerSelected: {},
  loading: false,
  loadingSegments: false,
  coupons: [],
  totalCoupons: 0,
  couponsUpdated: false,
  repeatableCoupons: [],
  totalRepeatableCoupons: 0,
  errorMessage: '',
  isFulfilled: false,
  errors: [],
  isDescription: false,
  descriptionHTML: '',
  isInstruction: false,
  instructionsHTML: ''
}

const offers = createSlice({
  name: offersConstants.Domain,
  initialState: offersInitialState as OffersState,
  reducers: {
    modifyOfferForm: (state, action) => {
      const { name, value } = action.payload as OffersPayloadInterface
      state.loading = true
      const currentOfferForm = { ...action.payload.offerCreateForm }
      if (name === OFFER_FORM_CONSTANTS.THUMBNAIL_ID) {
        currentOfferForm[name] = createImageID(value, action.payload.value)
        currentOfferForm.thumbnailfile = setFileInfo(action.payload.value)
      } else if (name === OFFER_FORM_CONSTANTS.ICON_ID) {
        currentOfferForm[name] = createImageID(value, action.payload.value)
        currentOfferForm.offerIconFile = setFileInfo(action.payload.value)
      } else if (
        name === OFFER_FORM_CONSTANTS.START_DATE &&
        currentOfferForm.startDateTimeStamp !== ''
      ) {
        currentOfferForm[name] = action.payload.value
        currentOfferForm.startDateTimeStamp = ''
      } else if (
        name === OFFER_FORM_CONSTANTS.END_DATE &&
        currentOfferForm.endDateTimeStamp !== ''
      ) {
        currentOfferForm[name] = action.payload.value
        currentOfferForm.endDateTimeStamp = ''
      } else if (
        name === OFFER_FORM_CONSTANTS.HAS_LIMIT &&
        currentOfferForm.hasRedemptionLimit === true
      ) {
        currentOfferForm[name] = action.payload.value
        currentOfferForm.offerRedeemTimesCount = null
        currentOfferForm.offerRedeemTimesDays = null
        currentOfferForm.maxTimeRewardPerUser = null
      } else {
        currentOfferForm[name] = action.payload.value
      }
      state.offerCreateForm = currentOfferForm
      state.isFormTouched = true
      state.loading = false
    },
    setOfferForm: (state) => {
      const {
        Title,
        WebTitle,
        VeloAmount,
        RealValue,
        Url,
        IconImageUrl,
        Description,
        AgencyID,
        OfferCategoryID,
        WebUiPosition,
        CouponInstructions,
        HasRepeatableCoupons,
        MaxRedeemsPerUser,
        RedemptionsAllowedInTimeFrame,
        LimitTimeFrameInDays,
        CouponURLTemplate,
        StartTimestampUTC,
        EndTimestampUTC,
        SegmentsIncluded,
        SegmentsExcluded
      } = state.offerSelected

      state.offerCreateForm = {
        offerName: Title,
        title: WebTitle,
        thumbnailUrl: '',
        thumbnailID: Url,
        listedPoints: VeloAmount != null ? +VeloAmount : null,
        realPoints: RealValue != null ? +RealValue : null,
        description: Description,
        agencyID: AgencyID,
        categoryID: OfferCategoryID,
        webUiPosition: WebUiPosition,
        offerIconUrl: '',
        offerIconID: IconImageUrl,
        instructions: CouponInstructions,
        redirectionLink: CouponURLTemplate,
        isARepeatableCoupon: HasRepeatableCoupons,
        hasRedemptionLimit:
          (MaxRedeemsPerUser != null && MaxRedeemsPerUser > 0) ||
          (LimitTimeFrameInDays != null && LimitTimeFrameInDays > 0),
        offerRedeemTimesCount: RedemptionsAllowedInTimeFrame,
        offerRedeemTimesDays: LimitTimeFrameInDays,
        maxTimeRewardPerUser: MaxRedeemsPerUser,
        startDate: StartTimestampUTC ?? null,
        endDate: EndTimestampUTC ?? null,
        startDateTimeStamp: StartTimestampUTC,
        endDateTimeStamp: EndTimestampUTC,
        segmentsIncluded:
          SegmentsIncluded?.map((option) => option.Name ?? '') ?? [],
        segmentsExcluded:
          SegmentsExcluded?.map((option) => option.Name ?? '') ?? [],
        thumbnailfile: null,
        offerIconFile: null
      }
      state.isFormUpdated = true
      state.isDescription = true
      state.descriptionHTML = Description ?? ''
      state.isInstruction = true
      state.instructionsHTML = CouponInstructions ?? ''
    },
    resetOfferForm: (state) => {
      state.loading = true
      state.offerCreateForm = offersInitialState.offerCreateForm
      state.isFormTouched = false
      state.isFormUpdated = false
      state.errors = []
      state.isFulfilled = false
      state.loading = false
      state.isDescription = false
      state.descriptionHTML = ''
      state.isInstruction = false
      state.instructionsHTML = ''
    },
    resetErrorMessage: (state) => {
      state.errorMessage = ''
    },
    resetCouponUpdated: (state) => {
      state.couponsUpdated = false
    },
    setIsDescription: (state, action) => {
      state.isDescription = true
      if (action.payload != null) {
        state.descriptionHTML = action?.payload
      }
    },
    resetIsDescription: (state) => {
      state.isDescription = false
    },
    setIsInstruction: (state, action) => {
      state.isInstruction = true
      if (action.payload != null) {
        state.instructionsHTML = action.payload
      }
    },
    resetIsInstruction: (state) => {
      state.isInstruction = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLiveOfferByID.fulfilled, (state, action) => {
        state.liveOfferSelected = action.payload
        state.loading = false
      })
      .addCase(getLiveOfferByID.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getLiveOfferByID.rejected, (state, action) => {
        state.liveOfferSelected = {}
        state.loading = false
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.offers = action.payload.offers
        state.totalOffersList = action.payload.count
        state.loading = false
      })
      .addCase(getOffers.pending, (state) => {
        state.loading = true
      })
      .addCase(getOffers.rejected, (state, action) => {
        state.offers = []
        state.loading = false
        state.totalOffersList = 0
      })
      .addCase(getCouponsByOfferID.fulfilled, (state, action) => {
        state.coupons = action.payload.coupons
        state.totalCoupons = action.payload.count
      })
      .addCase(getCouponsByOfferID.rejected, (state) => {
        state.coupons = []
        state.totalCoupons = 0
      })
      .addCase(getOfferDetailsByID.fulfilled, (state, action) => {
        state.offerSelected = action.payload
        state.loading = false
      })
      .addCase(getOfferDetailsByID.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getOfferDetailsByID.rejected, (state, action) => {
        state.offerSelected = {}
        state.loading = false
      })
      .addCase(editOfferStatus.fulfilled, (state, action) => {
        state.offerSelected.offerstatus =
          action.payload.Hidden === false
            ? OffersStatuses.LIVE
            : OffersStatuses.HIDDEN
      })
      .addCase(createOffer.fulfilled, (state, action) => {
        state.offerSelected = action.payload
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(createOffer.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(createOffer.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
      .addCase(editOffer.fulfilled, (state, action) => {
        state.offerSelected = action.payload
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editOffer.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(editOffer.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
      .addCase(addCouponsByOfferID.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addCouponsByOfferID.pending, (state) => {
        state.loading = true
      })
      .addCase(addCouponsByOfferID.rejected, (state) => {
        state.loading = false
      })
      .addCase(addRepeatableCouponsByOfferID.fulfilled, (state) => {
        state.loading = false
        state.couponsUpdated = true
      })
      .addCase(addRepeatableCouponsByOfferID.pending, (state) => {
        state.loading = true
        state.couponsUpdated = false
      })
      .addCase(addRepeatableCouponsByOfferID.rejected, (state) => {
        state.loading = false
        state.couponsUpdated = false
      })
      .addCase(removeOfferFromTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(removeOfferFromTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(removeOfferFromTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(addOfferToTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(addOfferToTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(addOfferToTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(excludeOfferFromTargetGroup.fulfilled, (state) => {
        state.loadingSegments = false
      })
      .addCase(excludeOfferFromTargetGroup.pending, (state) => {
        state.loadingSegments = true
      })
      .addCase(excludeOfferFromTargetGroup.rejected, (state) => {
        state.loadingSegments = false
      })
      .addCase(getRepeatableCouponsByOfferID.fulfilled, (state, action) => {
        state.repeatableCoupons = action.payload.repeatableCoupons
        state.totalRepeatableCoupons = action.payload.count
        state.loading = false
      })
      .addCase(getRepeatableCouponsByOfferID.pending, (state) => {
        state.loading = true
      })
      .addCase(getRepeatableCouponsByOfferID.rejected, (state) => {
        state.repeatableCoupons = []
        state.loading = false
        state.totalRepeatableCoupons = 0
      })
      .addCase(deleteAllAvailableCouponsByOfferID.fulfilled, (state) => {
        state.loading = false
        state.couponsUpdated = true
      })
      .addCase(deleteAllAvailableCouponsByOfferID.pending, (state) => {
        state.loading = true
        state.couponsUpdated = false
      })
      .addCase(deleteAllAvailableCouponsByOfferID.rejected, (state) => {
        state.loading = false
        state.couponsUpdated = false
      })
      .addCase(deleteCouponByOfferID.fulfilled, (state, action) => {
        state.loading = false
        state.couponsUpdated = true
        state.errorMessage = ''
      })
      .addCase(deleteCouponByOfferID.pending, (state) => {
        state.loading = true
        state.couponsUpdated = false
      })
      .addCase(deleteCouponByOfferID.rejected, (state, action) => {
        const { error_description: error } =
          action.payload as OffersErrorInterface
        state.loading = false
        state.couponsUpdated = false
        state.errorMessage = error
      })
      .addCase(refundCouponByID.fulfilled, (state, action) => {
        state.loading = false
        state.couponsUpdated = true
        state.errorMessage = ''
      })
      .addCase(refundCouponByID.pending, (state) => {
        state.loading = true
        state.couponsUpdated = false
      })
      .addCase(refundCouponByID.rejected, (state, action) => {
        const { error_description: error } =
          action.payload as OffersErrorInterface
        state.loading = false
        state.couponsUpdated = false
        state.errorMessage = error
      })
  }
})

export const offersReducer = offers.reducer
export const {
  modifyOfferForm,
  resetOfferForm,
  setOfferForm,
  resetErrorMessage,
  resetCouponUpdated,
  setIsDescription,
  resetIsDescription,
  setIsInstruction,
  resetIsInstruction
} = offers.actions
