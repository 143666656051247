import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Form,
  useLocation,
  useBlocker,
  useNavigation
} from 'react-router-dom'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { t } from 'i18next'
import {
  TextField,
  FormControl,
  FormHelperText,
  Divider,
  Box,
  InputLabel
} from '@mui/material'
import {
  commonFormValidationConstants,
  constants
} from '../commons/constants/constants'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import { userHasTenantPermission } from '../core/routes/router'
import {
  AgenciesSelectItems,
  blockerConditionWithoutStepper,
  filterNonNumericValues,
  getRoleTypeByString,
  handleFormSubmit,
  onFormChange,
  onFormChangeWrapper,
  selectMenuItem
} from '../commons/utils/utils'
import { useAppSelector } from '../core/redux/hooks'
import SelfServeButton from '../ui/button/Button'
import ImageDropZone from '../ui/dropzone/Dropzone'
import FormFieldBox from '../ui/form-field-box/FormFieldBox'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'
import { CategoryFormProps } from '../commons/interfaces/commons-interfaces'

const OfferCategoriesForm: FC<CategoryFormProps> = ({
  properties: { agencyID, categoryName, icon, webUiPosition, url },
  setValueMap,
  onSubmit,
  onDiscard,
  edit,
  isLeavingDialogOpen,
  onUiReorderClick,
  isReorderDisabled,
  resetUIs,
  setIsCategoryNameUpdated
}) => {
  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      agencyID,
      categoryName,
      icon,
      webUiPosition,
      url
    }
  })
  const { userType, isAgencyEnabled } = useAppSelector((state) => state.auth)
  const { agencies } = useAppSelector((state) => state.agencies)
  const role = getRoleTypeByString(userType)
  const [openConfirmLeavingDialog, setOpenConfirmLeavingDialog] =
    useState(false)
  const location = useLocation()
  const navigation = useNavigation()

  const isDisabled = webUiPosition === ''
  const blocker = useBlocker(() => {
    return blockerConditionWithoutStepper(
      isDirty,
      location.pathname,
      isLeavingDialogOpen,
      setOpenConfirmLeavingDialog,
      navigation.location?.pathname
    )
  })

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={
        edit
          ? t`DiscardOfferCategoryModification`
          : t`DiscardOfferCategoryCreation`
      }
      open={openConfirmLeavingDialog}
      cancelCallback={() => {
        ;(blocker.reset as () => void)()
        setOpenConfirmLeavingDialog(false)
      }}
      okCallback={() => {
        ;(blocker.proceed as () => void)()
      }}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Box>
        <h1 className='title'>
          {edit ? t`EditOfferCategory` : t`CreateOfferCategory`}
        </h1>
      </Box>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleFormSubmit(event, trigger, onSubmit, errors).catch((error) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        {userHasTenantPermission(role) && isAgencyEnabled
          ? (
            <FormFieldBox
              title={t`Agency`}
              rules={{
                required: commonFormValidationConstants.requiredField,
                validate: {
                  notEmpty: () => {
                    return agencyID !== ''
                  }
                }
              }}
              name='agencyID'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel
                    shrink={false}
                    className='select-label label-position'
                    id='agency-label'
                  >
                    {agencyID === '' && t`SelectAgency`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    handleFormChange={(e) => {
                      onFormChangeWrapper(
                        e.target?.value as string,
                        name,
                        onChange,
                        setValueMap,
                        resetUIs
                      )
                    }}
                    value={agencyID}
                    menuItem={selectMenuItem(t`None`)}
                    selectItems={AgenciesSelectItems(agencies)}
                    name={name}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.agencyID}
            />
            )
          : null}
        <FormFieldBox
          title={t`CategoryName`}
          rules={{
            required: commonFormValidationConstants.requiredField,
            minLength: commonFormValidationConstants.minLengthError,
            maxLength: commonFormValidationConstants.maxLengthError
          }}
          name='categoryName'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'categoryName' }}
              name='categoryName'
              id='categoryName'
              onChange={(e) => {
                onFormChangeWrapper(
                  e.target?.value,
                  name,
                  onChange,
                  setValueMap
                )
                setIsCategoryNameUpdated?.()
              }}
              value={categoryName}
            />
          )}
          control={control}
          errorField={errors.categoryName}
        />
        <Box className='mb-1'>
          <FormControl fullWidth>
            <FormHelperText>{t`CategoryIcon`}</FormHelperText>
            <ImageDropZone
              testId='categoryIconDropZone'
              isImage
              url={url}
              name='icon'
              setFile={(data: File | undefined) => {
                onFormChange(
                  undefined,
                  constants.COMMON_FORM_VALUES.URL,
                  setValueMap
                )
                onFormChange(
                  data,
                  constants.COMMON_FORM_VALUES.ICON,
                  setValueMap
                )
              }}
            />
          </FormControl>
          {errors.icon != null && (
            <FormHelperText error>
              {errors.icon?.message?.toString()}
            </FormHelperText>
          )}
        </Box>
        <FormFieldBox
          title={t`UiPosition`}
          rules={{}}
          name='webUiPosition'
          render={({ field: { name, onChange } }) => (
            <Box className='category-ui'>
              <TextField
                name={name}
                id='webUiPosition'
                onChange={(e) => {
                  onFormChangeWrapper(
                    filterNonNumericValues(e.target?.value),
                    name,
                    onChange,
                    setValueMap
                  )
                }}
                value={webUiPosition}
                className='category-ui'
                inputProps={{
                  readOnly: true
                }}
              />
              <SelfServeButton
                id='category-ui-reorder'
                onClick={onUiReorderClick}
                variant='outlined'
                disabled={!isReorderDisabled}
                className='reorder-btn'
              >
                {t`Reorder`}
              </SelfServeButton>
            </Box>
          )}
          control={control}
          errorField={errors.webUiPosition}
        />
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <SelfServeButton
            id='offer-categories-create'
            dataTestId='saveCreateButton'
            type='submit'
            variant='contained'
            disabled={isDisabled}
          >
            {edit ? t`SaveChanges` : t`CreateButton`}
          </SelfServeButton>
          <SelfServeButton
            id='offer-categories-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
      {renderConfirmLeavingDialog}
    </Box>
  )
}

export default OfferCategoriesForm
