import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { Typography } from '@mui/material'
import { UserPrivacyStatus, UserStatus } from '../constants'
import { getCapitalisation } from '../../commons/utils/utils'
import { useTranslation } from 'react-i18next'
import { activateUser, suspendUser, optOutUser } from '../slices'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { RoleType } from '../../core/routes/constants'

const UserDetailCard = (): React.ReactElement => {
  const {
    users: {
      userSelected: { UsersBalance, Status, UserID, PrivacyStatus }
    },
    auth: { userType }
  } = useAppSelector((state) => state)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [openActivateDialog, setOpenActivateDialogDialog] =
    React.useState(false)
  const [openSuspendDialog, setOpenSuspendDialogDialog] = React.useState(false)
  const [openOptOutDialog, setOpenOptOutDialogDialog] = React.useState(false)

  const [isUserSuspended, setIsUserSuspended] = React.useState(
    Status === UserStatus.SUSPENDED
  )
  const [isUserOptedOut, setIsUserOptedOut] = React.useState(
    PrivacyStatus === UserPrivacyStatus.OPTED_OUT
  )

  const renderOptOutDialog = (
    <CustomDialog
      title={t`OptOutUser`}
      open={openOptOutDialog}
      okCallback={() => optOutUserCall()}
      cancelCallback={() => setOpenOptOutDialogDialog(false)}
      mainMessage={t`OptOutDialog`}
      okText={t`YesOptOut`}
      cancelText={t`Cancel`}
    />
  )

  const renderSuspendDialog = (
    <CustomDialog
      title={t`SuspendUser`}
      open={openSuspendDialog}
      okCallback={() => suspendUserCall()}
      cancelCallback={() => setOpenSuspendDialogDialog(false)}
      mainMessage={t`SuspendDialog`}
      okText={t`YesSuspend`}
      cancelText={t`Cancel`}
    />
  )

  const renderActivateDialog = (
    <CustomDialog
      title={t`ActivateUser`}
      open={openActivateDialog}
      okCallback={() => activateUserCall()}
      cancelCallback={() => setOpenActivateDialogDialog(false)}
      mainMessage={t`ActivateDialog`}
      okText={t`YesActivate`}
      cancelText={t`Cancel`}
    />
  )

  React.useEffect(() => {
    setIsUserSuspended(Status === UserStatus.SUSPENDED)
    setIsUserOptedOut(PrivacyStatus === UserPrivacyStatus.OPTED_OUT)
  }, [Status, PrivacyStatus, UserID, dispatch])

  const activateUserCall = (): void => {
    if (UserID != null) {
      void dispatch(activateUser(UserID))
      setOpenActivateDialogDialog(false)
    }
  }

  const suspendUserCall = (): void => {
    if (UserID != null) {
      void dispatch(suspendUser(UserID))
      setOpenSuspendDialogDialog(false)
    }
  }

  const optOutUserCall = (): void => {
    if (UserID != null) {
      void dispatch(optOutUser(UserID))
      setOpenOptOutDialogDialog(false)
    }
  }

  return (
    <Box className='detail-card-box'>
      <Box display='flex'>
        <Typography variant='h5'>{UsersBalance}</Typography>&nbsp;
        <Typography variant='subtitle2' className='sidebar-card-text'>
          {t`Points`}
        </Typography>
      </Box>
      <Typography
        variant='subtitle1'
        className={
          Status === UserStatus.NEW || Status === UserStatus.ACTIVE
            ? 'status-title success'
            : Status === UserStatus.INACTIVE
              ? 'status-title warning'
              : 'status-title error'
        }
      >
        {getCapitalisation(Status ?? '')}
      </Typography>
      {isUserSuspended && userType !== RoleType.AGENCY
        ? (
          <Typography
            onClick={() => setOpenActivateDialogDialog(true)}
            variant='subtitle2'
            className='sidebar-card-link'
          >
            {t`ActivateAgain`}
          </Typography>
          )
        : (
            userType !== RoleType.AGENCY && (
              <Typography
                onClick={() => setOpenSuspendDialogDialog(true)}
                variant='subtitle2'
                className='sidebar-card-link'
              >
                {t`SuspendUser`}
              </Typography>
            )
          )}
      {userType !== RoleType.AGENCY && (
        <Typography
          onClick={() => {
            !isUserOptedOut && setOpenOptOutDialogDialog(true)
          }}
          variant='subtitle2'
          className={`sidebar-card-link ${
            isUserOptedOut ? 'disabled-text' : ''
          }`}
        >
          {t`OptOutUser`}
        </Typography>
      )}
      {renderSuspendDialog}
      {renderActivateDialog}
      {renderOptOutDialog}
    </Box>
  )
}

export default UserDetailCard
