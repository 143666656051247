import { ReactElement, useEffect } from 'react'
import { signOut } from '../auth/slices'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import authConstants from '../auth/constants'
import { RoutePath } from '../core/routes/routePaths'
import { useNavigate } from 'react-router-dom'
import PersistentDrawerLeft from '../ui/sidebar/Sidebar'
import MainHeader from '../ui/header/MainHeader'
import './Main.scss'
import { AppBar, Box, ThemeProvider, createTheme } from '@mui/material'

const Main = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const THEME = createTheme({
    typography: {
      fontFamily: 'Jost'
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      }
    }
  })
  const { name, authStatus, logoUrl } = useAppSelector((state) => state.auth)

  const handleLogOut = (): void => {
    void dispatch(signOut())
  }

  useEffect(() => {
    if (authStatus === authConstants.AuthStatus.LOGGED_OUT) {
      navigate(RoutePath.Login)
    }
  }, [authStatus, navigate])

  return (
    <ThemeProvider theme={THEME}>
      <Box className='Main' sx={{ display: 'flex' }}>
        <AppBar
          elevation={0}
          position='fixed'
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            padding: '1em',
            backgroundColor: '#F5F5F5'
          }}
        >
          <MainHeader
            logoUrl={logoUrl}
            name={name}
            handleLogOut={handleLogOut}
          />
        </AppBar>
        <PersistentDrawerLeft open />
      </Box>
    </ThemeProvider>
  )
}

export default Main
