import { createBrowserRouter } from 'react-router-dom'
import { RoutePath } from './routePaths'
import Login from '../../login/Login'
import Main from '../../main/Main'
import { RoleType } from './constants'
import Agencies from '../../agencies/Agencies'
import AgenciesDetails from '../../agencies/AgenciesDetails'
import CampaignCategories from '../../campaign-categories/CampaignCategories'
import CampaignCategoriesDetails from '../../campaign-categories/CampaignCategoriesDetails'
import NotAllowed from '../../not-allowed/NotAllowed'
import ServerError from '../../server-error/ServerError'
import AgenciesCreate from '../../agencies/AgenciesCreate'
import AgenciesEdit from '../../agencies/AgenciesEdit'
import CampaignCreate from '../../campaigns/campaign-forms/CampaignCreate'
import CampaignEdit from '../../campaigns/campaign-forms/CampaignEdit'
import CampaignCategoriesCreate from '../../campaign-categories/CampaignCategoriesCreate'
import CampaignCategoriesEdit from '../../campaign-categories/CampaignCategoriesEdit'
import AgencyCampaigns from '../../agencies/campaigns/AgencyCampaigns'
import OfferCategories from '../../offer-categories/OfferCategories'
import AgencyOffers from '../../agencies/offers/AgencyOffers'
import OfferCategoriesDetails from '../../offer-categories/OfferCategoriesDetails'
import CategoryCampaigns from '../../campaign-categories/campaigns/CategoryCampaigns'
import OfferCategoriesCreate from '../../offer-categories/OfferCategoriesCreate'
import Campaigns from '../../campaigns/Campaigns'
import OfferCategoriesEdit from '../../offer-categories/OfferCategoriesEdit'
import CategoryOffers from '../../offer-categories/offers/CategoryOffers'
import CampaignsDetails from '../../campaigns/details/CampaignsDetails'
import Offers from '../../offers/Offers'
import OfferDetails from '../../offers/details/OfferDetails'
import OfferSegments from '../../offers/segments/OfferSegments'
import CampaignSegments from '../../campaigns/campaign-segments/CampaignSegments'
import OffersCoupons from '../../offers/coupons/OffersCoupons'
import OfferRepeatableSettings from '../../offers/repeatable-settings/OfferRepeatableSettings'
import OfferCreate from '../../offers/create-new-offer/OfferCreate'
import Users from '../../users/Users'
import UsersDetails from '../../users/UsersDetails'
import WalletTransactions from '../../users/wallet/WalletTransactions'
import UserEarnings from '../../users/user-earnings/UserEarnings'
import UserSegments from '../../users/user-segments/UserSegments'
import CouponCreate from '../../offers/coupons/CouponCreate'
import UserVisibleCampaigns from '../../users/user-visible-campaigns/UserVisibleCampaigns'
import OfferEdit from '../../offers/edit-offer/OfferEdit'
import UserVisibleOffers from '../../users/user-visible-offers/UserVisibleOffers'
import Segments from '../../segments/Segments'
import SegmentsCreate from '../../segments/create/SegmentsCreate'
import SegmentsDetails from '../../segments/details/SegmentsDetails'
import SegmentUsers from '../../segments/segment-users/SegmentUsers'
import SegmentCodes from '../../segments/segments-codes/SegmentCodes'
import SegmentsOffers from '../../segments/segment-offers/SegmentsOffers'
import SegmentsCampaigns from '../../segments/segment-campaigns/SegmentsCampaigns'
import SegmentsEdit from '../../segments/edit/SegmentsEdit'
import ForgotPassword from '../../login/forgot-password/ForgotPassword'
import ErrorsPage from '../../self-serve-errors/ErrorsPage'
import { t } from 'i18next'

const PAGE_NOT_FOUND_CODE = 404

const createElement = (
  element: React.ReactElement,
  role: RoleType
): React.ReactElement => {
  return userHasTenantPermission(role) ? element : <NotAllowed />
}

export const router = (role: RoleType): any => {
  return createBrowserRouter([
    {
      path: RoutePath.Login,
      element: <Login />
    },
    {
      path: RoutePath.ForgotPassword,
      element: <ForgotPassword />
    },
    {
      path: RoutePath.Main,
      element: <Main />,
      children: [
        {
          index: true,
          element: (
            <div>
              <h1>Dashboard</h1>
            </div>
          )
        },
        {
          path: RoutePath.Agencies,
          element: createElement(<Agencies />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Agency,
          element: createElement(<AgenciesDetails />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.AgencyCampaigns,
          element: createElement(<AgencyCampaigns />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.AgencyOffers,
          element: createElement(<AgencyOffers />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CreateAgencies,
          element: createElement(<AgenciesCreate />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.EditAgencies,
          element: createElement(<AgenciesEdit />, role),
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CampaignCategories,
          element: <CampaignCategories />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CategoryCampaigns,
          element: <CategoryCampaigns />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CampaignCategory,
          element: <CampaignCategoriesDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CreateCampaignCategories,
          element: <CampaignCategoriesCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.EditCampaignCategories,
          element: <CampaignCategoriesEdit />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OfferCategories,
          element: <OfferCategories />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OfferCategory,
          element: <OfferCategoriesDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CreateOfferCategories,
          element: <OfferCategoriesCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Campaigns,
          element: <Campaigns />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Campaign,
          element: <CampaignsDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CampaignSegments,
          element: <CampaignSegments />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CreateCampaigns,
          element: <CampaignCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.EditCampaigns,
          element: <CampaignEdit />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.EditOfferCategories,
          element: <OfferCategoriesEdit />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CategoryOffers,
          element: <CategoryOffers />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Offers,
          element: <Offers />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Offer,
          element: <OfferDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OfferSegments,
          element: <OfferSegments />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OffersCoupons,
          element: <OffersCoupons />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OffersCouponsCreate,
          element: <CouponCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.OfferRepeatableSettings,
          element: <OfferRepeatableSettings />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.CreateOffers,
          element: <OfferCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.EditOffers,
          element: <OfferEdit />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Users,
          element: <Users />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.User,
          element: <UsersDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.UserWallet,
          element: <WalletTransactions />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.UserEarnings,
          element: <UserEarnings />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.UserSegments,
          element: <UserSegments />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.UserCampaigns,
          element: <UserVisibleCampaigns />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.UserOffers,
          element: <UserVisibleOffers />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Segments,
          element: <Segments />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentCreate,
          element: <SegmentsCreate />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentEdit,
          element: <SegmentsEdit />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.Segment,
          element: <SegmentsDetails />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentUsers,
          element: <SegmentUsers />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentCodes,
          element: <SegmentCodes />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentOffers,
          element: <SegmentsOffers />,
          errorElement: <ServerError />
        },
        {
          path: RoutePath.SegmentCampaigns,
          element: <SegmentsCampaigns />,
          errorElement: <ServerError />
        }
      ]
    },
    {
      path: RoutePath.AccessForbidden,
      element: <NotAllowed />
    },
    {
      path: RoutePath.Default,
      element: (
        <ErrorsPage
          errorCode={PAGE_NOT_FOUND_CODE}
          errorTitle={t`PageNotFound`}
          errorContent={t`PageNotFoundContent`}
        />
      )
    }
  ])
}

export const userHasTenantPermission = (role: RoleType): boolean => {
  return role === RoleType.ADMIN || role === RoleType.TENANT
}
