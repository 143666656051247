import { axiosInstance } from '../core/api/init'
import {
  CampaignListParams,
  CampaignListResponse,
  CampaignsInterface,
  CampaignFormInterface,
  CampaignAllProviders,
  CreateCampaignResponse
} from './interfaces/campaigns'
export const campaignsService = {
  getCampaigns: async (
    params: CampaignListParams
  ): Promise<CampaignListResponse> => {
    const { perPage, page, tenantID, agencyID, categories, search, status } =
      params
    const res = await axiosInstance.get('/campaigns', {
      params: {
        perPage,
        page,
        tenantID,
        agencyID,
        categories:
          categories != null && categories?.length > 0
            ? JSON.stringify(categories)
            : null,
        search,
        status
      }
    })
    return res.data
  },
  getFeaturedCampaigns: async (
    params: CampaignListParams
  ): Promise<CampaignListResponse> => {
    const { tenantID, agencyID } = params
    const res = await axiosInstance.get('/campaigns/getFeaturedCampaigns', {
      params: {
        tenantID,
        agencyID
      }
    })
    return res.data
  },
  getCampaignPreviewByID: async (id: string): Promise<CampaignsInterface> => {
    const res = await axiosInstance.get(`/campaigns/${id}/live-preview`)
    return res.data
  },
  getCampaignByID: async (id: string): Promise<CampaignsInterface> => {
    const res = await axiosInstance.get(`/campaigns/${id}`)
    return res.data
  },
  editCampaignStatus: async (
    id: string,
    status: string
  ): Promise<CampaignsInterface> => {
    const res = await axiosInstance.patch(`/campaigns/${id}/status`, {
      status
    })
    return res.data
  },
  removeCampaignFromTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<CampaignsInterface> => {
    const res = await axiosInstance.delete(
      `/campaigns/${id}/target-group/${targetGroupID}`
    )
    return res.data
  },
  addCampaignToTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<CampaignsInterface> => {
    const res = await axiosInstance.post(
      `/campaigns/${id}/target-group/${targetGroupID}/include`
    )
    return res.data
  },
  excludeCampaignFromTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<CampaignsInterface> => {
    const res = await axiosInstance.post(
      `/campaigns/${id}/target-group/${targetGroupID}/exclude`
    )
    return res.data
  },
  getAllProviderList: async (
    tenantID?: string
  ): Promise<CampaignAllProviders> => {
    const res = await axiosInstance.get('/campaigns/getAllProviderList', {
      params: {
        tenantID
      }
    })
    return res.data
  },
  createNewCampaign: async (
    campaignInfo: CampaignFormInterface
  ): Promise<CreateCampaignResponse> => {
    const res = await axiosInstance.post('/campaigns/create', campaignInfo)
    return res.data
  },
  editCampaign: async (
    campaignInfo: CampaignFormInterface,
    id: string
  ): Promise<CampaignFormInterface> => {
    const res = await axiosInstance.put(
      `/campaigns/editCampaign/${id}`,
      campaignInfo
    )
    return res.data
  }
}

export default campaignsService
