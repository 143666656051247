import { ReactElement } from 'react'
import { useAppSelector } from '../../core/redux/hooks'
import LiveCampaignContent from './LiveCampaignContent'
import './live-campaigns.scss'
import { Box } from '@mui/material'

const LiveCampaignPreview = (): ReactElement | null => {
  const { liveCampaignSelected } = useAppSelector((state) => state.campaigns)

  return liveCampaignSelected.CampaignID != null
    ? (
      <Box>
        <LiveCampaignContent small campaign={liveCampaignSelected} />
      </Box>
      )
    : null
}

export default LiveCampaignPreview
