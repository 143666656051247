import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import { Typography } from '@mui/material'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { useTranslation } from 'react-i18next'
import { convertStringToDateAndTime } from '../../commons/utils/utils'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import { CampaignsStatuses, FilterCampaignStatus } from '../constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { editCampaignStatus } from '../slices'
import { ReactElement } from 'react'

const CampaignDetailCard = (): React.ReactElement => {
  const {
    CampaignID,
    Url,
    Title,
    Name,
    StartTimestampUTC,
    EndTimestampUTC,
    status
  } = useAppSelector((state) => ({
    CampaignID: state.campaigns.campaignSelected.CampaignID,
    Url: state.campaigns.campaignSelected.Url,
    Title: state.campaigns.campaignSelected.Title,
    Name: state.campaigns.campaignSelected.Name,
    StartTimestampUTC: state.campaigns.campaignSelected.StartTimestampUTC,
    EndTimestampUTC: state.campaigns.campaignSelected.EndTimestampUTC,
    status: state.campaigns.campaignSelected.status
  }))

  const [openEditStatusDialog, setOpenEditStatusDialog] = React.useState(false)
  const [isStatusActive, setIsStatusActive] = React.useState(
    status != null &&
      (status === CampaignsStatuses.LIVE_ACTIVE ||
        status === CampaignsStatuses.HIDDEN_ACTIVE)
  )
  const dispatch = useAppDispatch()
  const handleClose = (): void => {
    setOpenEditStatusDialog(false)
  }

  const { t } = useTranslation()

  const { date: startDate, time: startTime } =
    convertStringToDateAndTime(StartTimestampUTC)
  const { date: endDate, time: endTime } =
    convertStringToDateAndTime(EndTimestampUTC)

  React.useEffect(
    () =>
      setIsStatusActive(
        status != null &&
          (status === CampaignsStatuses.LIVE_ACTIVE ||
            status === CampaignsStatuses.HIDDEN_ACTIVE)
      ),
    [status]
  )

  const handleChangeStatus = (): void => {
    setOpenEditStatusDialog(false)
    if (CampaignID != null) {
      void dispatch(
        editCampaignStatus({
          CampaignID,
          status: isStatusActive
            ? FilterCampaignStatus.INACTIVE
            : FilterCampaignStatus.ACTIVE
        })
      )
    } else {
      reportError(t`NoCampaignIDError`, ErrorLevels.Error)
    }
  }
  const mainMessage = (
    <>
      {t`CampaignDialogMessageStart`}
      <b>"{Name}"</b>
      {isStatusActive ? t`InactiveCampaign` : t`ActiveCampaign`}
    </>
  )

  const renderWarningDialog = (
    <CustomDialog
      title={
        isStatusActive
          ? t`CampaignDialogMessageTitleInactive`
          : t`CampaignDialogMessageTitleActive`
      }
      open={openEditStatusDialog}
      cancelCallback={handleClose}
      okCallback={handleChangeStatus}
      mainMessage={mainMessage}
      okText={
        isStatusActive ? t`DialogMessageOkInactive` : t`DialogMessageOkActive`
      }
      cancelText={t`Cancel`}
    />
  )

  const cardSubtitles = (subTitle: string): ReactElement => (
    <Typography className='options' variant='subtitle2'>
      {subTitle}:
    </Typography>
  )

  const cardValues = (value: number | string | undefined): ReactElement => (
    <Typography className='sidebar-card-text' variant='subtitle2'>
      {value}
    </Typography>
  )

  return (
    <Box className='flex-column' margin='2.5em 2.5em'>
      <img className='sidebar-preview-image' src={Url} alt='campaign-preview' />
      <Typography className='mb-0' variant='h5'>
        {Title != null ? Title : Name}
      </Typography>
      <Box className='sidebar-card-detail'>
        <TagOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardValues(CampaignID)}
      </Box>
      <Box className='sidebar-card-detail'>
        <EventAvailableOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardSubtitles(t`Start`)}
        {cardValues(`${startDate ?? ''} | ${startTime ?? ''}`)}
      </Box>
      <Box className='sidebar-card-detail mb-1'>
        <EventBusyOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {cardSubtitles(t`End`)}
        {cardValues(`${endDate ?? ''} | ${endTime ?? ''}`)}
      </Box>
      <Typography
        variant='subtitle1'
        className={
          isStatusActive
            ? 'status-title success'
            : status === CampaignsStatuses.EXPIRED
              ? 'status-title error'
              : 'status-title warning'
        }
      >
        <CustomSwitch
          data-testid='statusButton'
          color={status === CampaignsStatuses.EXPIRED ? 'error' : 'secondary'}
          onClick={() => setOpenEditStatusDialog(true)}
          checked={isStatusActive}
          disabled={status === CampaignsStatuses.EXPIRED}
        />
        &nbsp;
        {status}
      </Typography>
      {renderWarningDialog}
    </Box>
  )
}

export default CampaignDetailCard
