import { Box } from '@mui/material'
import { ReactElement } from 'react'
import './PhonePreview.scss'

interface PhonePreviewProps {
  content: ReactElement
}

const PhonePreview = ({ content }: PhonePreviewProps): ReactElement => {
  return (
    <Box>
      <Box className='marvel-device iphone-x'>
        <Box className='sleep' />
        <Box className='volume' />
        <Box className='notch' />
        <Box className='screen'>
          <Box className='content'>{content}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export const emptyPreview = (className?: string): ReactElement => (
  <Box className={`empty-preview-box ${className as string}`}>
    <Box display='flex' flexDirection='column'>
      <Box className='empty-preview' />
      <Box className='empty-description' />
    </Box>
  </Box>
)

export const emptyPreviewWithCategory = (
  hideEmptyCategory?: boolean,
  className?: string
): ReactElement => (
  <Box className={`empty-preview-box ${className as string}`}>
    <Box className='flex-column'>
      <Box
        className={`${
          hideEmptyCategory === true ? 'hidden' : ''
        } empty-category`}
      >
        {emptyCategoryPlaceholder()}
      </Box>
      {emptyImagePlaceholder()}
      <Box className='empty-description-offers' />
    </Box>
  </Box>
)

export const emptyCategoryPlaceholder = (): ReactElement => (
  <Box className='empty-preview-box'>
    <Box className='empty-preview-point' />
    <Box className='empty-preview-title' />
  </Box>
)

export const emptyImagePlaceholder = (): ReactElement => (
  <Box className='empty-preview-box'>
    <Box className='empty-preview-offers' />
  </Box>
)

export default PhonePreview
