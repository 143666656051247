import { ReactElement, FC, useState, useEffect } from 'react'
import { t } from 'i18next'
import { FormControl, InputLabel } from '@mui/material'
import { Form } from 'react-router-dom'
import {
  AgenciesSelectItems,
  StaticSelectItems,
  allText,
  checkIsNotEmpty,
  handleDateRangeChange,
  selectMenuItem
} from '../../commons/utils/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import '../segments.scss'
import _ from 'lodash'
import { SegmentsListParams } from '../interfaces/segments'
import { SegmentsTypes } from '../constants'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import { getAllAgencies } from '../../agencies/slices'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

dayjs.extend(utc)

interface MainSegmentsListHeaderProps {
  handleFormChange: (changedData: SegmentsListParams) => void
  AgencyID?: string
  search?: string
  createdStart: string
  createdEnd: string
  type: string
}

const MainSegmentsListHeader: FC<MainSegmentsListHeaderProps> = ({
  handleFormChange,
  AgencyID,
  search,
  createdStart,
  createdEnd,
  type
}): ReactElement => {
  const {
    auth: { userType, tenantID, isAgencyEnabled },
    agencies: { agencies }
  } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()
  const [startDate, setStartDate] = useState<null | Date>(
    checkIsNotEmpty(createdStart) ? new Date(createdStart) : null
  )
  const [endDate, setEndDate] = useState<null | Date>(
    checkIsNotEmpty(createdEnd) ? new Date(createdEnd) : null
  )

  const handleDateChange = (selectedDateRange: Array<Date | null>): void => {
    const { startDateString, endDateString } = handleDateRangeChange(
      selectedDateRange,
      setStartDate,
      setEndDate
    )

    handleFormChange({
      createdStart: startDateString ?? '',
      createdEnd: endDateString ?? ''
    })
  }

  useEffect(() => {
    if (userType !== RoleType.AGENCY && isAgencyEnabled) {
      void dispatch(getAllAgencies({ tenantID }))
    }
  }, [tenantID, dispatch, userType, AgencyID, isAgencyEnabled])

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`CreatedDate`}
              className='date-picker'
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormControl className='form-control' size='small'>
            <InputLabel shrink={false} id='agencies-label'>
              {(AgencyID == null || AgencyID === '') &&
                allText(t`AgencyForSelect`)}
            </InputLabel>
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                handleFormChange({ AgencyID: e.target.value as string })}
              value={AgencyID}
              menuItem={selectMenuItem(t`AllAgencies`)}
              selectItems={AgenciesSelectItems(agencies)}
              inputProps='table-input maxw-8 minw-8'
            />
          </FormControl>
        )}
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='type-label'>
            {_.isEmpty(type) && allText(t`TypeForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ type: e.target.value as string })}
            value={type}
            inputProps='table-input maxw-8 minw-8'
            menuItem={selectMenuItem(t`AllTypes`)}
            selectItems={StaticSelectItems(SegmentsTypes)}
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default MainSegmentsListHeader
