import { MenuProps, Select, SelectChangeEvent } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import './SelfServeSelect.scss'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

interface SelfServeCustomSelectProps {
  value?: string | string[] | null
  handleFormChange: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void
  menuItem?: ReactElement
  selectItems: ReactElement[] | ReactNode
  inputProps?: string
  multiple?: boolean
  native?: boolean
  displayEmpty?: boolean
  renderValue?: (value: string) => ReactNode
  name?: string
  disabled?: boolean
  isFormSelect?: boolean
  menuProps?: Partial<MenuProps>
  placeholder?: string
  onClose?: () => void
  subheader?: ReactElement
  testId?: string
  id?: string
}

const SelfServeCustomSelect = ({
  value,
  handleFormChange,
  menuItem,
  selectItems,
  inputProps = '',
  multiple = false,
  native,
  displayEmpty,
  renderValue,
  name,
  disabled = false,
  isFormSelect = false,
  menuProps,
  placeholder,
  onClose,
  subheader,
  testId,
  id
}: SelfServeCustomSelectProps): ReactElement => {
  return (
    <Select
      id={id}
      inputProps={{ className: inputProps, 'data-testid': testId }}
      className={!isFormSelect ? 'mb-1' : ''}
      size='small'
      IconComponent={ExpandMoreOutlinedIcon}
      value={value as string}
      onChange={(event: SelectChangeEvent) => {
        handleFormChange(event)
      }}
      multiple={multiple}
      native={native}
      displayEmpty={displayEmpty}
      renderValue={renderValue}
      name={name}
      disabled={disabled}
      MenuProps={menuProps}
      placeholder={placeholder}
      onClose={onClose}
    >
      {subheader}
      {menuItem}
      {selectItems}
    </Select>
  )
}

export default SelfServeCustomSelect
