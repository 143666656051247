import { Box, CircularProgress, FormHelperText } from '@mui/material'
import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import { TargetGroupsInterface } from '../interfaces/segments'
import {
  codesPrecheck,
  editRewardsSegment,
  editSegment,
  getSegmentByIDForEdit,
  modifySegmentForm,
  resetSegmentForm,
  usersPrecheck
} from '../slices'
import {
  USERS_FILE,
  USERS_CSV,
  EditMethod,
  EditRewardsMethod,
  CODES_CSV,
  INVITE_CODES_FILE,
  EditAutomaticRewardsMethod,
  GenerationMethodType,
  USERS_FILE_ID,
  CODES_FILE_ID,
  CODES_FILE
} from '../constants'
import { RoutePath } from '../../core/routes/routePaths'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation,
  useParams
} from 'react-router-dom'
import _ from 'lodash'
import UserValidationBox from '../segments-forms/UserValidationBox'
import {
  getDuplicatesList,
  getValidFilteredCouponCodes
} from '../../offers/utils'
import { getFilteredCodes, getUserList, handleFormChangeForCSVFiles } from '../utils'
import SegmentManualEdit from '../segments-forms/SegmentManualEdit'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import SegmentRewardsEdit from '../segments-forms/SegmentRewardsEdit'
import { Trans } from 'react-i18next'
import { uploadImage } from '../../commons/components/uploader/utils'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import CouponCodesBox from '../../offers/coupons/CouponCodesBox'

const SegmentsEdit: FC<{}> = (): ReactElement | null => {
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [userCsv, setUserCsv] = useState<string[]>([])
  const [openConfirmValidatedUsers, setOpenConfirmValidatedUsers] =
    useState<boolean>(false)
  const [openConfirmUsersOrCodesAdded, setOpenConfirmUsersOrCodesAdded] =
    useState<boolean>(false)
  const [openConfirmUpdateOrRemoval, setOpenConfirmUpdateOrRemoval] =
    useState<boolean>(false)
  const [openConfirmValidatedCodes, setOpenConfirmValidatedCodes] =
    useState<boolean>(false)
  const [manualTotalUsers, setManualTotalUsers] = useState<number>(0)
  const [duplicatedUsersSum, setDuplicateUsersSum] = useState<number>(0)
  const [totalValidUsers, setTotalValidUsers] = useState<number>(0)
  const [couponCodesToAdd, setCouponCodesToAdd] = useState<string[]>([])
  const [totalCoupons, setTotalCoupons] = useState<number>(0)
  const [codes, setCodes] = useState<string[]>([])
  const [filteredCodes, setFileteredCodes] = useState<string[]>([])
  const [codesCsv, setCodesCsv] = useState<string[]>([])

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { id } = useParams<{ id: string }>()

  const {
    auth: { tenantID, agencyID: agencyIDLoggedIn, userID, userType },
    segments: {
      segmentsForm,
      segmentsForm: {
        Name,
        Manual,
        usersCSV,
        AgencyID,
        alreadyExistCodes,
        notExistUserList,
        duplicatedUsers,
        editMethod,
        totalUsers,
        totalCodes,
        previousName,
        timesCanBeUsed,
        currentTimesCanBeUsed,
        description,
        excludedCharacters,
        welcomeDialogImageFile,
        welcomePointsImageFile,
        expiryDate,
        welcomeDialogTitle,
        welcomeDialogMessage,
        welcomeDialogLabel,
        welcomePointsTitle,
        welcomePoints,
        welcomeDialogImageID,
        welcomePointsImageID,
        inviteCodes,
        areCodesFromCSV,
        codesCSV,
        notExistCodes,
        duplicatedCodes,
        existInCurrentSegment,
        TargetGroupID,
        TenantID,
        numberOfCodes
      },
      loading,
      errors,
      isFulfilled,
      isFormTouched
    }
  } = useAppSelector((state) => state)

  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const handleClose = useCallback((): void => {
    setOpenConfirmValidatedUsers(false)
    setOpenConfirmUsersOrCodesAdded(false)
    setOpenConfirmLeaving(false)
    setOpenConfirmUpdateOrRemoval(false)
    setOpenConfirmValidatedCodes(false)
  }, [])

  const handleCloseAndNavigate = useCallback((): void => {
    handleClose()
    navigate(`${RoutePath.Segments}/${id ?? ''}`)
  }, [handleClose, navigate, id])

  useEffect(() => {
    if (id != null) {
      void dispatch(getSegmentByIDForEdit(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
      dispatch(resetSegmentForm())
    }
  }, [dispatch, id])

  useEffect(() => {
    if (usersCSV != null) {
      setManualTotalUsers(getUserList(usersCSV).length)
      const duplicates = getDuplicatesList(usersCSV ?? [])
      setDuplicateUsersSum(
        _.add(duplicates.length, duplicatedUsers?.length ?? 0)
      )
      const validUsersCount =
        manualTotalUsers -
        _.add(duplicatedUsersSum, notExistUserList?.length ?? 0)
      setTotalValidUsers(validUsersCount)
    }
  }, [
    usersCSV,
    duplicatedUsersSum,
    notExistUserList,
    manualTotalUsers,
    duplicatedUsers
  ])

  useEffect(() => {
    if (openConfirmValidatedUsers && usersCSV != null) {
      void dispatch(
        usersPrecheck({
          userList: getValidFilteredCouponCodes(usersCSV, []),
          TargetGroupID: id
        })
      )
    }
  }, [openConfirmValidatedUsers, usersCSV, dispatch, id])

  useEffect(() => {
    if (openConfirmValidatedCodes) {
      void dispatch(
        codesPrecheck({
          Name,
          description,
          inviteCodes: filteredCodes,
          TargetGroupID,
          TenantID,
          replace: editMethod === EditRewardsMethod.REPLACE.name
        })
      )
    }
  }, [
    Name,
    TargetGroupID,
    TenantID,
    filteredCodes,
    description,
    editMethod,
    openConfirmValidatedCodes,
    dispatch
  ])

  useEffect(() => {
    if (
      isFulfilled &&
      (editMethod === EditMethod.NEW_USERS ||
        editMethod === EditMethod.REPLACE ||
        editMethod === EditRewardsMethod.NEW_CODES_UPLOAD.name ||
        editMethod === EditRewardsMethod.NEW_CODES_MANUAL.name)
    ) {
      setOpenConfirmUsersOrCodesAdded(true)
      setOpenConfirmValidatedUsers(false)
      setOpenConfirmValidatedCodes(false)
    } else if (isFulfilled) {
      handleCloseAndNavigate()
    }
  }, [isFulfilled, navigate, editMethod, handleCloseAndNavigate])

  useEffect(() => {
    userCsv.length > 0 &&
      dispatch(
        modifySegmentForm({
          name: USERS_CSV,
          value: userCsv,
          segmentsForm
        })
      )
  }, [userCsv, dispatch, segmentsForm])

  useEffect(() => {
    setCodes(
      areCodesFromCSV === true
        ? codesCSV ?? []
        : (inviteCodes as string[]) ?? []
    )
  }, [codesCSV, inviteCodes, Manual, areCodesFromCSV])

  useEffect(() => {
    if (codes != null) {
      setFileteredCodes(getFilteredCodes(codes))
    }
  }, [codes])

  useEffect(() => {
    setTotalCoupons(filteredCodes?.length)
    setCouponCodesToAdd(getValidFilteredCouponCodes(filteredCodes, []))
  }, [filteredCodes])

  useEffect(() => {
    codesCsv.length > 0 &&
      dispatch(
        modifySegmentForm({
          name: CODES_CSV,
          value: codesCsv,
          segmentsForm
        })
      )
  }, [codesCsv, dispatch, segmentsForm])

  useEffect(() => {
    handleClose()
  }, [errors, handleClose])

  const editSegmentSubmission = async (): Promise<void> => {
    if (Manual === true) {
      const validUserList =
        totalValidUsers === 0
          ? []
          : usersCSV?.filter(
            (val) =>
              val != null &&
                val.length > 0 &&
                !(notExistUserList ?? []).includes(val)
          )
      void dispatch(
        editSegment({
          TenantID: tenantID,
          AgencyID: agencyIDLoggedIn ?? AgencyID,
          Name,
          previousName,
          editMethod,
          Manual,
          TargetGroupID: id,
          userList: validUserList,
          userType
        })
      )
    } else {
      try {
        const welcomeImageUpload =
          welcomeDialogImageFile != null
            ? await uploadImage(userID, welcomeDialogImageFile)
            : null
        const pointsImageUpload =
          welcomePointsImageFile != null
            ? await uploadImage(userID, welcomePointsImageFile)
            : null
        void dispatch(
          editRewardsSegment({
            TenantID: tenantID,
            AgencyID: agencyIDLoggedIn ?? AgencyID,
            Name,
            previousName,
            editMethod,
            TargetGroupID: id,
            description,
            expiryDate,
            welcomeDialogTitle,
            welcomeDialogMessage,
            welcomeDialogLabel,
            welcomeDialogImageID:
              welcomeImageUpload?.imageID === 0
                ? welcomeDialogImageID
                : welcomeImageUpload?.imageID,
            welcomePointsImageID:
              pointsImageUpload?.imageID === 0
                ? welcomePointsImageID
                : pointsImageUpload?.imageID,
            welcomePointsTitle,
            welcomePoints,
            timesCanBeUsed,
            currentTimesCanBeUsed,
            inviteCodes: couponCodesToAdd,
            notExistCodes: _.isEqual(
              editMethod,
              EditAutomaticRewardsMethod.NEW_CODES_AUTOMATIC.name
            )
              ? couponCodesToAdd.length
              : notExistCodes,
            existInCurrentSegment,
            excludedCharacters,
            userType
          })
        )
      } catch (error: any) {
        reportError(error, ErrorLevels.Error)
      }
    }
  }

  const handleFormReset = (): void => {
    dispatch(resetSegmentForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(RoutePath.Segments)
  }

  const getDialogMessage = (): ReactElement => {
    switch (editMethod) {
      case EditMethod.REMOVAL:
        return (
          <Trans
            i18nKey={
              Manual === true ? 'UpdateWithRemoval' : 'UpdateWithCodeRemoval'
            }
            values={{ users: totalUsers, segmentName: Name, codes: totalCodes }}
          />
        )
      case EditRewardsMethod.NUMBER_OF_TIMES.name:
        return (
          <Trans
            i18nKey='UpdateNumberOfTimes'
            values={{ segmentName: Name, times: timesCanBeUsed }}
          />
        )
      case EditAutomaticRewardsMethod.NEW_CODES_AUTOMATIC.name:
        return (
          <Trans
            i18nKey='UpdateWithCodesGeneration'
            values={{ segmentName: Name, codes: numberOfCodes }}
          />
        )
      default:
        return (
          <Trans
            i18nKey='UpdateSegmentMessage'
            values={{ segmentName: Name }}
          />
        )
    }
  }

  const mainMessageUsersAdded = (
    <Trans
      i18nKey={
        Manual === true
          ? 'UpdateWithUsersSegmentMessage'
          : 'UpdateWithCodesSegmentMessage'
      }
      values={{
        users: totalValidUsers,
        Name,
        codes: _.isEqual(editMethod, GenerationMethodType.AUTOMATIC_CODES)
          ? couponCodesToAdd.length
          : notExistCodes
      }}
    />
  )

  const renderConfirmUsersOrCodesAdded = (
    <CustomDialog
      title=''
      open={openConfirmUsersOrCodesAdded}
      okCallback={handleCloseAndNavigate}
      cancelCallback={handleCloseAndNavigate}
      mainMessage={mainMessageUsersAdded}
      okText={t`OK`}
      okButtonClassName='coupon-ok-btn'
    />
  )

  const renderConfirmUpdateOrRemoval = (
    <CustomDialog
      title={t`UpdateSegment`}
      open={openConfirmUpdateOrRemoval}
      cancelCallback={handleClose}
      okCallback={editSegmentSubmission}
      mainMessage={<>{getDialogMessage()}</>}
      okText={t`YesUpdateIt`}
      cancelText={t`NotNow`}
    />
  )

  const renderConfirmValidatedUsers = (
    <CustomDialog
      title={t`AddUsers`}
      open={openConfirmValidatedUsers}
      cancelCallback={handleClose}
      okCallback={editSegmentSubmission}
      mainMessage={
        <UserValidationBox
          userEntered={manualTotalUsers}
          userNotFound={notExistUserList?.length}
          duplicated={duplicatedUsersSum}
          newUsersToAdd={totalValidUsers}
        />
      }
      okText={t`Confirm`}
      cancelText={t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardSegmentModification`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmValidatedCodes = (
    <CustomDialog
      title={t`AddCoupons`}
      open={openConfirmValidatedCodes}
      cancelCallback={handleClose}
      okCallback={editSegmentSubmission}
      mainMessage={
        <CouponCodesBox
          totalCoupons={totalCoupons}
          duplicateCoupons={_.add(
            duplicatedCodes?.length ?? 0,
            existInCurrentSegment?.length ?? 0
          )}
          isFromSegments
          showValidationText={
            _.isEqual(editMethod, EditRewardsMethod.REPLACE.name) &&
            _.isEqual(notExistCodes, 0)
          }
          validationText={t`NoValidCodes`}
          alreadyAddedCoupons={alreadyExistCodes}
          totalValidCoupons={notExistCodes}
        />
      }
      okText={t`AddCodes`}
      cancelText={t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const blockerCondition = (
    currentLocation: string,
    nextLocation?: string
  ): boolean => {
    if (
      isFormTouched &&
      nextLocation !== currentLocation &&
      !openConfirmLeaving &&
      !openConfirmUsersOrCodesAdded &&
      !openConfirmUpdateOrRemoval &&
      !openConfirmValidatedCodes
    ) {
      setOpenConfirmLeaving(true)
      return true
    }
    return false
  }

  const blocker = useBlocker(() => {
    return blockerCondition(location.pathname, navigation.location?.pathname)
  })

  const onFormChange = (
    value: TargetGroupsInterface[keyof TargetGroupsInterface],
    name: string
  ): void => {
    if (name === USERS_FILE) {
      handleFormChangeForCSVFiles(
        value,
        setUserCsv,
        dispatch,
        USERS_CSV,
        segmentsForm,
        USERS_FILE,
        USERS_FILE_ID
      )
    } else if (name === INVITE_CODES_FILE) {
      handleFormChangeForCSVFiles(
        value,
        setCodesCsv,
        dispatch,
        CODES_CSV,
        segmentsForm,
        CODES_FILE,
        CODES_FILE_ID
      )
    } else {
      dispatch(
        modifySegmentForm({
          name,
          value,
          segmentsForm
        })
      )
    }
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  const validationErrorsDisplay = (): ReactElement | null =>
    !_.isEmpty(errors)
      ? (
        <FormHelperText className='mt-1' error>
          {errors}
        </FormHelperText>
        )
      : null

  return (
    <Box>
      <Box className='segment-edit'>
        <h1 className='title'>{t`EditSegment`}</h1>
      </Box>
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        {Manual === true
          ? (
            <SegmentManualEdit
              onFormChange={onFormChange}
              onSubmit={() => {
                if (
                  editMethod === EditMethod.NEW_USERS ||
                editMethod === EditMethod.REPLACE
                ) {
                  setOpenConfirmValidatedUsers(true)
                } else {
                  setOpenConfirmUpdateOrRemoval(true)
                }
              }}
              onDiscard={handleDiscard}
            />
            )
          : (
            <SegmentRewardsEdit
              onFormChange={onFormChange}
              onDiscard={handleDiscard}
              onSubmit={() => {
                if (
                  editMethod === EditRewardsMethod.NEW_CODES_MANUAL.name ||
                editMethod === EditRewardsMethod.NEW_CODES_UPLOAD.name ||
                editMethod === EditRewardsMethod.REPLACE.name
                ) {
                  setOpenConfirmValidatedCodes(true)
                } else {
                  setOpenConfirmUpdateOrRemoval(true)
                }
              }}
            />
            )}
        {openConfirmValidatedUsers && renderConfirmValidatedUsers}
        {openConfirmUsersOrCodesAdded && renderConfirmUsersOrCodesAdded}
        {openConfirmLeaving && renderConfirmLeavingDialog}
        {openConfirmUpdateOrRemoval && renderConfirmUpdateOrRemoval}
        {openConfirmValidatedCodes && renderConfirmValidatedCodes}
        {validationErrorsDisplay()}
      </Box>
    </Box>
  )
}

export default SegmentsEdit
