import { ReactElement, FC, useState } from 'react'
import { t } from 'i18next'
import { FormControl } from '@mui/material'
import { Form } from 'react-router-dom'
import {
  checkIsNotEmpty,
  handleDateRangeChange
} from '../../commons/utils/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import { CodesListParams } from '../interfaces/segments'

dayjs.extend(utc)

interface SegmentCodesListHeaderProps {
  handleFormChange: (changedData: CodesListParams) => void
  search?: string
  createdDateStart: string
  createdDateEnd: string
}

const SegmentCodesListHeader: FC<SegmentCodesListHeaderProps> = ({
  handleFormChange,
  search,
  createdDateStart,
  createdDateEnd
}): ReactElement => {
  const [startDate, setStartDate] = useState<null | Date>(
    checkIsNotEmpty(createdDateStart) ? new Date(createdDateStart) : null
  )
  const [endDate, setEndDate] = useState<null | Date>(
    checkIsNotEmpty(createdDateEnd) ? new Date(createdDateEnd) : null
  )

  const handleDateChange = (selectedDateRange: Array<Date | null>): void => {
    const { startDateString, endDateString } = handleDateRangeChange(
      selectedDateRange,
      setStartDate,
      setEndDate
    )

    handleFormChange({
      createdDateStart: startDateString ?? '',
      createdDateEnd: endDateString ?? ''
    })
  }

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`CreatedDate`}
              className='date-picker'
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
      </Form>
    </div>
  )
}

export default SegmentCodesListHeader
