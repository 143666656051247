import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SelfServeButton from '../button/Button'
import './CustomDialog.scss'
import { ReactElement, ReactNode } from 'react'
import '../../offers/coupons/OffersCoupons.scss'
import _ from 'lodash'
import { ButtonColor, Nullable } from '../../../type'
export interface SimpleDialogProps {
  open: boolean
  title: string
  mainMessage?: ReactNode
  okText?: string
  cancelText?: string
  okCallback?: (() => Promise<void>) | (() => void)
  cancelCallback?: () => void
  isCustomized?: boolean
  isFullWidth?: boolean
  titleClassName?: string
  actionClassName?: string
  okButtonClassName?: string
  customStyle?: {}
  customColor?: ButtonColor
  name?: Nullable<string>
  isOkDisabled?: boolean
  customBtnColor?: ButtonColor
}

function CustomDialog (props: SimpleDialogProps): ReactElement {
  const {
    okCallback,
    cancelCallback,
    open,
    title,
    mainMessage,
    okText,
    cancelText,
    isOkDisabled = false,
    isCustomized = false,
    isFullWidth = false,
    titleClassName = '',
    actionClassName = '',
    okButtonClassName = '',
    customColor,
    customBtnColor,
    name = ''
  } = props
  return (
    <Dialog
      className='dialog'
      maxWidth='xs'
      onClose={cancelCallback}
      open={open}
      fullWidth={isFullWidth}
    >
      <DialogTitle className={(isCustomized || !_.isEmpty(titleClassName)) ? titleClassName : 'title'}>
        {title}
        <CloseOutlinedIcon
          onClick={cancelCallback}
          aria-label='close'
          className='close'
        />
      </DialogTitle>
      <DialogContent>
        {isCustomized
          ? (
              mainMessage
            )
          : (
            <Typography textAlign='center' gutterBottom className={customColor ?? ''}>
              {mainMessage}
              {!_.isEmpty(name) && <span className='dialog-name'> {name}?</span>}
            </Typography>
            )}
      </DialogContent>
      <DialogActions
        className={isCustomized ? actionClassName : 'dialog-actions'}
      >
        {okText != null && (
          <SelfServeButton
            id='ok-dialog'
            style={
              isCustomized ? { marginLeft: '1em' } : { marginRight: '1em' }
            }
            disabled={isOkDisabled}
            variant='contained'
            color={customBtnColor ?? customColor ?? 'primary'}
            onClick={okCallback}
            className={okButtonClassName}
          >
            {okText}
          </SelfServeButton>
        )}
        {cancelText != null && (
          <SelfServeButton
            id='cancel-dialog'
            variant='outlined'
            onClick={cancelCallback}
            style={isCustomized ? { marginRight: '1em' } : {}}
          >
            {cancelText}
          </SelfServeButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog
