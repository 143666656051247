import { TextField, SxProps, Theme, Divider } from '@mui/material'
import { ReactElement } from 'react'

interface SelfServeROTextFieldProps {
  multiline?: boolean
  id: string
  label?: string
  defaultValue?: string | number | readonly string[]
  sx?: SxProps<Theme>
  className?: string
  classNameDivider?: string
  showDivider?: boolean
}

const SelfServeROTextField = ({
  multiline,
  id,
  label,
  defaultValue,
  sx,
  className,
  showDivider = false,
  classNameDivider
}: SelfServeROTextFieldProps): ReactElement => {
  return (
    <>
      <TextField
        fullWidth
        multiline={multiline}
        id={id}
        label={label}
        defaultValue={defaultValue}
        sx={sx}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          className
        }}
        variant='standard'
      />
      {showDivider && <Divider className={classNameDivider ?? 'divider'} />}
    </>
  )
}

export default SelfServeROTextField
