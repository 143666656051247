import { Box } from '@mui/material'
import { ReactElement } from 'react'
import './StatusIndicator.scss'

interface StatusIndicatorProps {
  title?: string
  color?: 'success' | 'error' | 'warning'
}

const StatusIndicator = ({
  title,
  color
}: StatusIndicatorProps): ReactElement => {
  return (
    <Box minWidth='6em'>
      <Box className={`dot ${color ?? ''}`} />
      <span className={`status-title ${color ?? ''}`}>{title}</span>
    </Box>
  )
}

export default StatusIndicator
