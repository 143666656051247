import {
  CampaignListParams,
  CampaignListResponse
} from '../campaigns/interfaces/campaigns'
import { axiosInstance } from '../core/api/init'
import { OffersListParams } from '../offers/interfaces/offers'

import { TargetGroupsInterface } from '../segments/interfaces/segments'
import {
  UsersInterface,
  UsersListParams,
  UsersListResponse,
  WalletListResponse,
  EarningsListParams,
  EarningsListResponse
} from '../users/interfaces/users'

export const usersService = {
  getUsers: async (params: UsersListParams): Promise<UsersListResponse> => {
    const res = await axiosInstance.get('/users', { params })
    return res.data
  },
  getUserByID: async (id: string): Promise<UsersInterface> => {
    const res = await axiosInstance.get(`/users/${id}`)
    return res.data
  },
  activateUser: async (id: string): Promise<UsersInterface> => {
    const res = await axiosInstance.post(`/users/${id}/activate`)
    return res.data
  },
  suspendUser: async (id: string): Promise<UsersInterface> => {
    const res = await axiosInstance.post(`/users/${id}/suspend`)
    return res.data
  },
  optOutUser: async (id: string): Promise<UsersInterface> => {
    const res = await axiosInstance.post(`/users/${id}/opt-out`)
    return res.data
  },
  getUserWallet: async (
    params: UsersListParams
  ): Promise<WalletListResponse> => {
    const res = await axiosInstance.get(
      `/users/${params.userID as string}/wallet`,
      {
        params
      }
    )
    return res.data
  },
  getUserEarningsByID: async (
    id: string,
    params: EarningsListParams
  ): Promise<EarningsListResponse> => {
    const res = await axiosInstance.get(`/users/${id}/earnings`, {
      params
    })
    return res.data
  },
  getEarningsListOptions: async (id: string): Promise<EarningsListResponse> => {
    const res = await axiosInstance.get(`/users/${id}/earnings-options`)
    return res.data
  },
  getUserSegmentsByID: async (id: string): Promise<TargetGroupsInterface[]> => {
    const res = await axiosInstance.get(`/users/${id}/target-groups`)
    return res.data
  },
  removeUserFromTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `/users/${id}/target-group/${targetGroupID}`
    )
    return res.data
  },
  addUserToTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/users/${id}/target-group/${targetGroupID}`
    )
    return res.data
  },
  getUserCampaigns: async (
    params: CampaignListParams
  ): Promise<CampaignListResponse> => {
    const { perPage, page, agencyID, categories, search, userID } = params
    const res = await axiosInstance.get(
      `/users/${userID as string}/campaigns`,
      {
        params: {
          perPage,
          page,
          agencyID,
          categories:
            categories != null && categories?.length > 0
              ? JSON.stringify(categories)
              : null,
          search,
          userID
        }
      }
    )
    return res.data
  },
  getUserOffers: async (
    params: OffersListParams
  ): Promise<CampaignListResponse> => {
    const { perPage, page, agencyID, categories, search, offerType, userID } = params
    const res = await axiosInstance.get(
      `/users/${userID as string}/offers`,
      {
        params: {
          perPage,
          page,
          agencyID,
          categories:
            categories != null && categories?.length > 0
              ? JSON.stringify(categories)
              : null,
          search,
          offerType,
          userID
        }
      }
    )
    return res.data
  }
}

export default usersService
