import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { getRoleTypeByString } from './commons/utils/utils'
import { ErrorLevels } from './core/error/constants'
import { reportError } from './core/error/handler'
import { useAppSelector } from './core/redux/hooks'
import { router } from './core/routes/router'
import initTranslation from './core/utils/i18n'

initTranslation().catch((err) => {
  reportError(err, ErrorLevels.Fatal)
})

const App: React.FC = () => {
  const { userType } = useAppSelector((state) => state.auth)
  return (
    <React.StrictMode>
      <RouterProvider router={router(getRoleTypeByString(userType))} />
    </React.StrictMode>
  )
}

export default App
