import { Box, Chip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'
import './DetailsCampaignPhonePreview.scss'
import { useAppSelector } from '../../core/redux/hooks'
import { CampaignPhonePreviewProps } from '../interfaces/campaigns'
import SelfServeButton from '../../ui/button/Button'
import _ from 'lodash'
import { getCampaignPreviewObject } from '../utils'
import CampaignCardPhonePreview from './CampaignCardPhonePreview'
import DynamicIcon from '../../ui/dynamic-icon/DynamicIcon'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const emptyDetailsDrawer = (
  <Box>
    <Box className='header' />
    <Box className='space-evenly placeholder-box'>
      <Box className='placeholder' />
      <Box className='placeholder' />
    </Box>
    <Box className='space-evenly placeholder-box'>
      <Box className='placeholder' />
      <Box className='placeholder' />
    </Box>
  </Box>
)

const pointBadge = (points: number, label: string): ReactElement => {
  return (
    <>
      <Chip
        label={
          <Box display='flex'>
            <DynamicIcon
              className='badge-icon'
              iconName='AttachMoneyOutlined'
            />
            {`${points} ${label}`}
          </Box>
        }
        className='transparent-chip'
      />
    </>
  )
}

const CampaignDetailsPhonePreview = ({
  isFromCreateEdit
}: CampaignPhonePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const { campaignForm, campaignSelected, categories } = useAppSelector(
    (state) => ({
      campaignForm: state.campaigns.campaignForm,
      campaignSelected: state.campaigns.campaignSelected,
      categories: state.campaignCategories.categories
    })
  )

  const campaign = getCampaignPreviewObject(
    campaignForm,
    campaignSelected,
    categories,
    isFromCreateEdit
  )

  const points =
    campaign.rewardPerUser != null ? _.toInteger(campaign.rewardPerUser) : 0

  return (
    <Box className='black-screen'>
      <CampaignCardPhonePreview isFromCreateEdit={isFromCreateEdit} />
      <Box className='preview-drawer'>
        <Typography className='close' variant='subtitle1'>
          x
        </Typography>
        {!_.isEmpty(campaign.title)
          ? (
            <Box>
              <Typography className='name'>{campaign.title}</Typography>
              <Box className='row badges'>
                {pointBadge(points, t`Points` as string)}
                {campaign.badges?.map(({ label, icon }) => (
                  <Chip
                    key={icon}
                    label={
                      <Box>
                        <DynamicIcon className='badge-icon' iconName={icon} />{' '}
                        {label}
                      </Box>
                  }
                    className='transparent-chip'
                  />
                ))}
              </Box>
              <ReactMarkdown linkTarget='_blank' className='description'>
                {campaign.description ?? ''}
              </ReactMarkdown>
              {!_.isEmpty(campaign.ctaName) && (
                <a
                  target='_blank'
                  className='neutral-link'
                  href={campaign.ctaLink as string}
                  rel='noreferrer'
                >
                  <SelfServeButton
                    id='CTA'
                    variant='contained'
                    className='rewards-button'
                    size='small'
                  >
                    {campaign.ctaName}
                  </SelfServeButton>
                </a>
              )}
            </Box>
            )
          : (
              emptyDetailsDrawer
            )}
      </Box>
    </Box>
  )
}

export default CampaignDetailsPhonePreview
