import { FC, ReactElement, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Divider, Grid, InputLabel } from '@mui/material'
import './campaign-categories.scss'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { RoutePath } from '../core/routes/routePaths'
import SelfServeButton from '../ui/button/Button'
import { constants } from '../commons/constants/constants'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import LiveCampaignCard from '../campaigns/live-campaigns/LiveCampaignCard'
import { userHasTenantPermission } from '../core/routes/router'
import { getRoleTypeByString } from '../commons/utils/utils'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../ui/sidebar/slices'
import { getCampaignCategoryByID, getLiveCampaigns } from './slices'
import { useTranslation } from 'react-i18next'
import NoLiveCampaign from '../campaigns/live-campaigns/NoLiveCampaign'
import SelfServeROTextField from '../ui/text-field/TextFieldRO'

const CampaignCategoriesDetails: FC<{}> = (): ReactElement | null => {
  const { campaignCategorySelected, liveCampaigns, loading } = useAppSelector(
    (state) => state.campaignCategories
  )
  const { userType, tenantID, isAgencyEnabled } = useAppSelector((state) => state.auth)
  const role = getRoleTypeByString(userType)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignCategoryByID(id))
      void dispatch(
        getLiveCampaigns({
          id,
          tenantID
        })
      )
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN_CATEGORY))
      dispatch(setBackButtonTitle(t`BackToCategories`))
      dispatch(setBackButtonRoute(RoutePath.CampaignCategories))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t, tenantID])

  return campaignCategorySelected.CampaignCategoryID != null && !loading
    ? (
      <Box>
        <Box maxWidth={constants.MAX_WIDTH_CARD}>
          <Box className='flex-row'>
            <Box>
              <h1 className='title'>{t`CampaignCategoryDetails`}</h1>
            </Box>
            <Box marginLeft='2em'>
              <SelfServeButton
                size='small'
                id='edit-category-button'
                variant='contained'
                color='secondary'
                onClick={() => navigate(RoutePath.Edit)}
              >
                <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
              </SelfServeButton>
            </Box>
          </Box>
          <Box className='box-details'>
            {userHasTenantPermission(role) && isAgencyEnabled && (
              <SelfServeROTextField
                id='agency-name'
                label={t`AgencyName`}
                defaultValue={campaignCategorySelected.AgencyName}
                showDivider
              />
            )}
            <SelfServeROTextField
              id='category-name'
              label={t`CategoryName`}
              defaultValue={campaignCategorySelected.CategoryName}
              showDivider
              classNameDivider='mt-0'
            />
            <InputLabel size='small' variant='standard' className='small-label'>
              {t`CategoryIcon`}
            </InputLabel>
            <img
              src={campaignCategorySelected.Url}
              alt='category-icon'
              className='mt-2 square-image-list'
            />
            <Divider className='divider' />
            <SelfServeROTextField
              id='ui-position'
              label={t`UiPosition`}
              defaultValue={campaignCategorySelected.WebUiPosition}
              showDivider
              classNameDivider='mt-0'
            />
          </Box>
        </Box>
        <InputLabel size='small' variant='standard' className='small-label'>
          {t`LiveCampaigns`}
        </InputLabel>
        {liveCampaigns.length > 0
          ? (
            <Box>
              <Grid
                container
                className='live-campaign-grid'
                direction='row'
                spacing={2}
                wrap='nowrap'
              >
                {liveCampaigns?.map((campaign) => {
                  return (
                    <Grid item key={campaign.CampaignID}>
                      <LiveCampaignCard campaign={campaign} />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            )
          : (
            <NoLiveCampaign />
            )}
      </Box>
      )
    : null
}

export default CampaignCategoriesDetails
