import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CampaignsInterface, CampaignListParams } from './interfaces/campaigns'
import {
  TenantsCampaignsListColumns,
  AgenciesCampaignsListColumns,
  CampaignsStatuses,
  FIRST_PAGE_CAMPAIGNS_TABLE,
  PER_PAGE_CAMPAIGNS_TABLE
} from './constants'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import StatusIndicator from '../ui/status-indicator/StatusIndicator'
import SelfServePagination from '../ui/pagination/SelfServePagination'
import { constants } from '../commons/constants/constants'
import { checkIsNotEmpty, setClassNameForTable } from '../commons/utils/utils'
import { useAppSelector } from '../core/redux/hooks'
import { RoutePath } from '../core/routes/routePaths'
import { campaignsListActionItems, setStatusColor } from './utils'
import SelfServeActionItem from '../ui/list-action-item/SelfServeActionItem'

interface CampaignsListProps {
  handleFormChange: (changedData: CampaignListParams) => void
  page?: number
  perPage?: number
  total: number
  campaignsList: CampaignsInterface[]
  showAgency?: boolean
}

const CampaignsList: FC<CampaignsListProps> = ({
  page,
  perPage,
  total,
  campaignsList,
  handleFormChange,
  showAgency = false
}) => {
  const { selectedEntityType } = useAppSelector((state) => state.sidebar)
  const isCategoryEntity =
    selectedEntityType === constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN_CATEGORY
  const COLUMNS = Object.values(
    showAgency != null && showAgency
      ? TenantsCampaignsListColumns
      : AgenciesCampaignsListColumns
  ).filter(
    (column) =>
      !(isCategoryEntity && column === TenantsCampaignsListColumns.CATEGORY)
  )
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {campaignsList.length > 0 && (
          <TableBody>
            {campaignsList.map((row) => (
              <TableRow className='row-with-actions' key={row.CampaignID}>
                <TableCell className='body-cell centered-cell'>
                  <Link to={`${RoutePath.Campaigns}/${row.CampaignID ?? ''}`}>
                    {row.CampaignID}
                  </Link>
                </TableCell>
                <TableCell className='body-cell'>
                  <Link to={`${RoutePath.Campaigns}/${row.CampaignID ?? ''}`}>
                    {checkIsNotEmpty(row.Title) ? row.Title : row.Name}
                  </Link>
                </TableCell>
                {showAgency && (
                  <TableCell className='body-cell'>
                    <img
                      alt='agency-logo'
                      className='rounded-image-list'
                      src={row.AgencyIconUrl}
                    />{' '}
                    {row.AgencyName}
                  </TableCell>
                )}
                {!isCategoryEntity && (
                  <TableCell className='body-cell'>
                    {row.CategoryName}
                  </TableCell>
                )}
                <TableCell className='body-cell centered-cell'>
                  {row.WebUiPosition}
                </TableCell>
                <TableCell className='body-cell'>
                  {row.status != null && (
                    <StatusIndicator
                      color={setStatusColor(row.status as CampaignsStatuses)}
                      title={row.status}
                    />
                  )}
                </TableCell>
                <TableCell className='body-cell'>
                  <Box className='list-actions flex-center'>
                    {campaignsListActionItems(
                      row.CampaignID?.toString() ?? ''
                    ).map((item) => {
                      return (
                        <SelfServeActionItem
                          path={item.path}
                          title={item.title}
                          icon={item.icon}
                          key={item.title}
                        />
                      )
                    })}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_CAMPAIGNS_TABLE}
        perPage={perPage ?? PER_PAGE_CAMPAIGNS_TABLE}
        onPageChange={(page) => handleFormChange({ page })}
        onPerPageChange={(perPage) => handleFormChange({ perPage })}
        total={total}
        length={campaignsList.length}
      />
    </TableContainer>
  )
}

export default CampaignsList
