import Button, { ButtonProps } from '@mui/material/Button'
import { ReactElement, ReactNode } from 'react'
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from '../../../type'

interface SelfServeButtonProps extends ButtonProps {
  children?: ReactNode
  rounded?: boolean
  color?: ButtonColor
  style?: {}
  variant: ButtonVariant
  type?: ButtonType
  onClick?: () => any
  disabled?: boolean
  id: string
  size?: ButtonSize
  className?: string
  dataTestId?: string
}

const SelfServeButton = ({
  children,
  onClick,
  variant,
  style,
  type,
  disabled,
  id,
  color,
  size,
  className,
  dataTestId
}: SelfServeButtonProps): ReactElement => {
  return (
    <Button
      size={size ?? 'medium'}
      data-testid={dataTestId}
      color={color ?? 'primary'}
      id={id}
      disabled={disabled}
      type={type}
      style={style}
      variant={variant}
      onClick={onClick}
      disableElevation
      className={className}
    >
      {children}
    </Button>
  )
}

export default SelfServeButton
