import { ReactElement } from 'react'
import { Box, Divider, InputLabel } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import { OffersInterfaceProps } from './OfferDetails'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { setDashIfEmpty, dashBox } from '../../commons/utils/utils'
import ReactMarkdown from 'react-markdown'

const OfferInformation = ({
  offer
}: OffersInterfaceProps): ReactElement | null => {
  const { userType, isAgencyEnabled } = useAppSelector((state) => ({
    userType: state.auth.userType,
    isAgencyEnabled: state.auth.isAgencyEnabled
  }))

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD} marginTop='2em'>
      <Box className='offer-detail-step'>
        <SelfServeROTextField
          id='offer-name'
          label={t`OfferName`}
          defaultValue={setDashIfEmpty(offer.Title)}
          showDivider
        />
        <SelfServeROTextField
          id='offer-title'
          label={t`Title`}
          defaultValue={setDashIfEmpty(offer.WebTitle)}
          showDivider
          classNameDivider='divider mb-02'
        />
        <InputLabel
          size='small'
          variant='standard'
          className='small-label icon-label'
        >
          {t`Thumbnail`}
        </InputLabel>
        <img
          className={
            offer.IconImageUrl != null && offer.IconImageUrl !== ''
              ? 'offer-image'
              : 'offer-image mb-1'
          }
          src={offer.Url}
          alt='offer-header'
        />
        {offer.IconImageUrl != null && offer.IconImageUrl !== '' && (
          <>
            <InputLabel
              size='small'
              variant='standard'
              className='small-label icon-label'
            >
              {t`OfferIcon`}
            </InputLabel>
            <img
              src={offer.IconImageUrl}
              alt='offer-icon'
              className='mt-2 square-image-list'
            />
            <Divider className='divider' />
          </>
        )}
        <SelfServeROTextField
          id='offer-listed-points'
          label={t`ListedPoints`}
          defaultValue={offer.VeloAmount != null ? +offer.VeloAmount : t`Dash`}
          showDivider
        />
        <SelfServeROTextField
          id='offer-real-points'
          label={t`RealPoints`}
          defaultValue={offer.RealValue != null ? +offer.RealValue : t`Dash`}
          showDivider
        />
        <InputLabel
          size='small'
          variant='standard'
          className='small-label description-label'
        >
          {t`Description`}
        </InputLabel>
        {offer.Description != null
          ? (
            <ReactMarkdown linkTarget='_blank' className='description-text'>
              {offer.Description}
            </ReactMarkdown>
            )
          : (
              dashBox
            )}
        <Divider className='divider mt-01' />
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <SelfServeROTextField
            id='offer-agency'
            label={t`Agency`}
            defaultValue={setDashIfEmpty(offer.AgencyName)}
            showDivider
          />
        )}
        <SelfServeROTextField
          id='offer-category'
          label={t`Category`}
          defaultValue={setDashIfEmpty(offer.CategoryName)}
          showDivider
        />
        <SelfServeROTextField
          id='offer-ui-position'
          label={t`UIPosition`}
          defaultValue={setDashIfEmpty(offer.WebUiPosition)}
        />
      </Box>
    </Box>
  )
}

export default OfferInformation
