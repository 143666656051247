import {
  TextField,
  FormHelperText,
  Divider,
  Box,
  FormControlLabel,
  InputLabel,
  MenuItem,
  CircularProgress
} from '@mui/material'
import { useState, useEffect, useMemo, ReactElement } from 'react'
import SelfServeButton from '../../ui/button/Button'
import { Form } from 'react-router-dom'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import { useForm } from 'react-hook-form'
import '../campaigns.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import {
  CampaignFormInterface,
  CampaignInfoFormProps
} from '../interfaces/campaigns'
import * as formValidationRule from '../campaign-rules/CampaignFormValidationRules'
import * as validationConstants from '../constants/ValidationConstants'
import { CampaignCategoryInterface } from '../../campaign-categories/interfaces/campaign-categories'
import IconUpload from '../../ui/icon-upload/IconUpload'
import {
  AgenciesSelectItems,
  handleNextStep,
  onHandleChangeCampaignWrapper
} from '../../commons/utils/utils'
import { getCategories } from '../../campaign-categories/slices'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { getAllAgencies } from '../../agencies/slices'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'
import _ from 'lodash'
import { RoleType } from '../../core/routes/constants'

const campaignNameKey = 'name'
const CampaignCardInfo = ({
  webUiPosition,
  featuredUiPosition,
  onFormChange,
  handleChange,
  handleNext,
  onDiscard,
  isFromEdit = false,
  onUiReorderClick,
  isReorderDisabled,
  resetUIs,
  setIsCampaignNameUpdated,
  isFeaturedReorderDisabled,
  onFeaturedUiReorderClick
}: CampaignInfoFormProps): ReactElement | null => {
  const {
    campaignIconUrl,
    thumbnailUrl,
    campaignName,
    thumbnailID,
    title,
    subHeader1,
    subHeader2,
    AgencyID,
    category,
    uiPosition,
    isFeatured,
    featuredUi,
    iconID
  } = useAppSelector((state) => ({
    campaignIconUrl: state.campaigns.campaignForm.campaignIconUrl,
    thumbnailUrl: state.campaigns.campaignForm.thumbnailUrl,
    campaignName: state.campaigns.campaignForm.name,
    thumbnailID: state.campaigns.campaignForm.thumbnailID,
    title: state.campaigns.campaignForm.title,
    subHeader1: state.campaigns.campaignForm.subHeader1,
    subHeader2: state.campaigns.campaignForm.subHeader2,
    AgencyID: state.campaigns.campaignForm.AgencyID,
    category: state.campaigns.campaignForm.category,
    uiPosition: state.campaigns.campaignForm.uiPosition,
    isFeatured: state.campaigns.campaignForm.isFeatured,
    featuredUi: state.campaigns.campaignForm.featuredUi,
    iconID: state.campaigns.campaignForm.iconID
  }))

  const {
    tenantID,
    agencyID: loggedAgencyID,
    isAgencyEnabled,
    userType
  } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const { agencies } = useAppSelector((state) => state.agencies)
  const {
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      campaignIconUrl,
      thumbnailUrl,
      campaignName,
      thumbnailID,
      title,
      subHeader1,
      subHeader2,
      AgencyID,
      category,
      uiPosition,
      isFeatured,
      featuredUi,
      iconID,
      webUiPosition
    }
  })
  const { t } = useTranslation()
  const [categoriesList, setCategoriesList] = useState<
  CampaignCategoryInterface[]
  >([])
  const [agencyID, setAgencyID] = useState<string | undefined>('')
  const [currentCategory, setCurrentCategory] = useState<string>('')
  const [isTenantLogin, setIsTenantLogin] = useState<boolean>(true)
  const [isAgencySelected, setIsAgencySelected] = useState<boolean>(false)
  const isUiEmpty = webUiPosition === ''
  useEffect(() => {
    void dispatch(
      getAllAgencies({
        tenantID
      })
    )
  }, [tenantID, dispatch])
  const { loading } = useAppSelector((state) => state.agencies)
  useEffect(() => {
    if (!_.isEmpty(AgencyID)) {
      setAgencyID(AgencyID)
      setIsAgencySelected(true)
    }
  }, [AgencyID])
  useEffect(() => {
    let isMounted = true
    if (
      (agencyID != null && agencyID !== '' && loggedAgencyID == null) ||
      !isAgencyEnabled
    ) {
      setIsAgencySelected(true)
      void dispatch(getCategories({ tenantID }))
        .then((response) => {
          if (isMounted) {
            setCategoriesList(response.payload.campaignCategories)
          }
        })
        .catch((error) => {
          reportError(error, ErrorLevels.Error)
        })
    } else {
      setIsAgencySelected(false)
    }

    return () => {
      isMounted = false
    }
  }, [tenantID, agencyID, dispatch, loggedAgencyID, isAgencyEnabled])

  useEffect(() => {
    if (loggedAgencyID != null && loggedAgencyID.length > 0) {
      setAgencyID(loggedAgencyID)
      setIsTenantLogin(false)
    } else {
      setIsTenantLogin(true)
    }
  }, [loggedAgencyID])

  useEffect(() => {
    let isMounted = true
    if (category != null) {
      if (AgencyID != null || !isAgencyEnabled) {
        setIsAgencySelected(true)
        void dispatch(
          getCategories({
            tenantID,
            agencyID: AgencyID
          })
        )
          .then((response) => {
            if (isMounted) {
              setCategoriesList(response.payload.campaignCategories)
            }
            setCurrentCategory(category.toString())
          })
          .catch((error) => {
            reportError(error, ErrorLevels.Error)
          })
      }
    }
    return () => {
      isMounted = false
    }
  }, [category, AgencyID, tenantID, dispatch, isAgencyEnabled, loggedAgencyID])

  const categoriesListWithMemo = useMemo(
    () =>
      categoriesList.map((item) => (
        <MenuItem key={item.CampaignCategoryID} value={item.CampaignCategoryID}>
          {item.CategoryName}
        </MenuItem>
      )),
    [categoriesList]
  )
  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <FormFieldBox
          title={t`CampaignName`}
          rules={{
            required: formValidationRule.formValidationConstants.requiredField,
            ...formValidationRule.lengthRule(
              validationConstants.nameLength.min,
              validationConstants.nameLength.max
            )
          }}
          name='campaignName'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'campaignNameField' }}
              name='campaignName'
              id='campaignName'
              onChange={(e) =>
                handleChange(
                  e,
                  campaignNameKey as keyof CampaignFormInterface,
                  onChange
                )}
              value={campaignName}
            />
          )}
          control={control}
          errorField={errors.campaignName}
        />
        <FormFieldBox
          title={t`Thumbnail`}
          rules={{
            required: formValidationRule.formValidationConstants.requiredField,
            validate: (data: File) => data != null
          }}
          name='thumbnailID'
          render={({ field: { name, onChange } }) => (
            <ImageDropZone
              testId='campaignCardInfoThumbnail'
              isImage
              isThumbnail
              url={thumbnailUrl}
              name={name}
              setFile={(data: File | undefined) => {
                onChange(data, name)
                onFormChange(data, name as keyof CampaignFormInterface)
              }}
              thumbnailID={thumbnailID}
            />
          )}
          control={control}
          errorField={errors.thumbnailID}
        />
        <FormFieldBox
          title={t`Title`}
          rules={{
            required: formValidationRule.formValidationConstants.requiredField,
            ...formValidationRule.lengthRule(
              validationConstants.titleLength.min,
              validationConstants.titleLength.max
            )
          }}
          name='title'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'titleField' }}
              name='title'
              id='title'
              onChange={(e) => {
                onHandleChangeCampaignWrapper(
                  e,
                  name as keyof CampaignFormInterface,
                  handleChange,
                  onChange
                )
                setIsCampaignNameUpdated?.()
              }}
              value={title}
            />
          )}
          control={control}
          errorField={errors.title}
        />
        <FormFieldBox
          title={t`SubHeader1`}
          rules={{
            ...formValidationRule.lengthRule(
              validationConstants.subHeader1Length.min,
              validationConstants.subHeader1Length.max
            )
          }}
          name='subHeader1'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'subHeader1Field' }}
              name='subHeader1'
              id='subHeader1'
              onChange={(e) => {
                handleChange(e, name as keyof CampaignFormInterface, onChange)
              }}
              value={subHeader1 ?? ''}
            />
          )}
          control={control}
          errorField={errors.subHeader1}
        />
        <FormFieldBox
          title={t`SubHeader2`}
          rules={{
            ...formValidationRule.lengthRule(
              validationConstants.subHeader2Length.min,
              validationConstants.subHeader2Length.max
            )
          }}
          name='subHeader2'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'subHeader2Field' }}
              name='subHeader2'
              id='subHeader2'
              onChange={(e) => {
                handleChange(e, name as keyof CampaignFormInterface, onChange)
              }}
              value={subHeader2 ?? ''}
            />
          )}
          control={control}
          errorField={errors.subHeader2}
        />
        <FormFieldBox
          title={t`CampaignIcon`}
          rules={{}}
          name='iconID'
          render={({ field: { name, onChange } }) => (
            <IconUpload
              testId='campaignIcon'
              url={campaignIconUrl}
              name={name}
              setFile={(data: File | undefined) => {
                onChange(data, name)
                onFormChange(data, name as keyof CampaignFormInterface)
              }}
              iconID={iconID}
            />
          )}
          control={control}
          errorField={errors.iconID}
        />
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormFieldBox
            title={t`Agency`}
            rules={{
              validate: {
                required: (value: string) => {
                  if ((value == null || value?.length === 0) && isTenantLogin) {
                    return t`RequiredField`
                  }
                  return true
                }
              }
            }}
            name='AgencyID'
            render={({ field: { name, onChange } }) => (
              <>
                <InputLabel
                  shrink={false}
                  className='select-label'
                  id='state-label'
                >
                  {AgencyID?.length === 0 && t`SelectCampaignAgency`}
                </InputLabel>
                <SelfServeCustomSelect
                  handleFormChange={(e) => {
                    if (e.target?.value != null) {
                      setAgencyID(e.target?.value as string)
                    }
                    onHandleChangeCampaignWrapper(
                      e,
                      name as keyof CampaignFormInterface,
                      handleChange,
                      onChange,
                      resetUIs
                    )
                  }}
                  value={AgencyID}
                  selectItems={AgenciesSelectItems(agencies)}
                  name={name}
                  disabled={!isTenantLogin}
                  inputProps='custom-form-select'
                  isFormSelect
                />
              </>
            )}
            control={control}
            errorField={errors.AgencyID}
          />
        )}
        <FormFieldBox
          title={t`Category`}
          rules={{
            validate: {
              required: (value: string) => {
                if ((value == null || value?.length === 0) && isTenantLogin) {
                  return t`RequiredField`
                }
                return true
              }
            }
          }}
          name='category'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel
                shrink={false}
                className='select-label'
                id='state-label'
              >
                {category == null && t`SelectCampaignCategory`}
              </InputLabel>
              <SelfServeCustomSelect
                testId='categorySelect'
                handleFormChange={(e) => {
                  setCurrentCategory(e.target?.value as string)
                  onHandleChangeCampaignWrapper(
                    e,
                    name as keyof CampaignFormInterface,
                    handleChange,
                    onChange,
                    resetUIs
                  )
                }}
                value={currentCategory}
                selectItems={categoriesListWithMemo}
                name={name}
                disabled={!isAgencySelected}
                inputProps='custom-form-select'
                isFormSelect
              />
            </>
          )}
          control={control}
          errorField={errors.category}
        />
        <FormFieldBox
          title={t`UiPosition`}
          rules={{}}
          name='webUiPosition'
          render={({ field: { name, onChange } }) => (
            <Box className='campaign-ui'>
              <TextField
                name={name}
                id='webUiPosition'
                onChange={(e) => {
                  onHandleChangeCampaignWrapper(
                    e,
                    'WebUiPosition' as keyof CampaignFormInterface,
                    handleChange,
                    onChange
                  )
                }}
                value={webUiPosition ?? ''}
                className='campaign-ui'
                inputProps={{
                  readOnly: true
                }}
              />
              <SelfServeButton
                id='campaign-ui-reorder'
                onClick={onUiReorderClick}
                variant='outlined'
                disabled={!isReorderDisabled}
                className='reorder-btn'
              >
                {t`Reorder`}
              </SelfServeButton>
            </Box>
          )}
          control={control}
          errorField={errors.uiPosition}
        />
        <Box className='mb-1'>
          <FormHelperText>{t`IsFeatured`}</FormHelperText>
          <FormControlLabel
            className='mb-1 switch-label'
            name='isFeatured'
            control={
              <CustomSwitch
                data-testid='isFeaturedSwitch'
                size='medium'
                onClick={() =>
                  onFormChange(
                    isFeatured != null && !isFeatured,
                    constants.COMMON_FORM_VALUES
                      .ISFEATURED as keyof CampaignFormInterface
                  )}
                checked={isFeatured}
              />
            }
            label={t`IsFeaturedLabel`}
          />
        </Box>
        {isFeatured !== null && isFeatured && (
          <FormFieldBox
            title={t`FeaturedUiPosition`}
            rules={{}}
            name='featuredUi'
            render={({ field: { name, onChange } }) => (
              <Box className='campaign-ui'>
                <TextField
                  name={name}
                  id='featured-ui'
                  onChange={(e) => {
                    handleChange(
                      e,
                      name as keyof CampaignFormInterface,
                      onChange,
                      true
                    )
                  }}
                  className='campaign-ui'
                  inputProps={{
                    readOnly: true
                  }}
                  value={featuredUiPosition ?? ''}
                />
                <SelfServeButton
                  id='campaign-featured-ui-reorder'
                  onClick={onFeaturedUiReorderClick}
                  variant='outlined'
                  disabled={!isFeaturedReorderDisabled}
                  className='reorder-btn'
                >
                  {t`Reorder`}
                </SelfServeButton>
              </Box>
            )}
            control={control}
            errorField={errors.featuredUi}
          />
        )}
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='campaign-create-previous'
              variant='contained'
              color='secondary'
              disabled
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='nextButtonCampaignCardInfo'
              id='campaign-create-next'
              type='submit'
              variant='contained'
              disabled={isFromEdit ? false : isUiEmpty}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            dataTestId='discardButtonCardInfo'
            id='campaign-create-discard'
            onClick={onDiscard}
            variant='contained'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </Box>
  )
}

export default CampaignCardInfo
