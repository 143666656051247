import * as React from 'react'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteItem } from '../../core/routes/routePaths'
import { useAppSelector } from '../../core/redux/hooks'
import './sidebar.scss'
import { getRoleTypeByString } from '../../commons/utils/utils'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Badge, Collapse } from '@mui/material'
import { CREATE, EDIT } from './constants'
import { constants } from '../../commons/constants/constants'

interface SidebarItemProps {
  sidebarItem: RouteItem
  childStyle?: React.CSSProperties
}

const SidebarItem = ({
  sidebarItem,
  childStyle
}: SidebarItemProps): React.ReactElement => {
  const { userType } = useAppSelector((state) => state.auth)
  const { pathname } = useLocation()
  const Icon = sidebarItem.icon
  const children = sidebarItem.children ?? []
  const [open, setOpen] = React.useState(false)
  const handleClick = (): void => {
    setOpen(!open)
  }
  const navigate = useNavigate()
  const muiListItemSx = {
    ...childStyle,
    display: 'flex',
    paddingBottom: '.6em',
    paddingTop: '.6em',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
      borderRadius: '2em 0 0 2em'
    },
    '&:not(:hover)': {
      borderRadius: '2em 0 0 2em'
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      borderRadius: '2em 0 0 2em',
      '&:hover': {
        backgroundColor: 'white'
      }
    }
  }

  const pathCreate = `${sidebarItem.path}/${CREATE}`
  const pathEdit = `${sidebarItem.path}/${EDIT}`
  const validateSelect =
    (pathEdit === pathname || pathCreate === pathname) &&
    sidebarItem.setPath === true
  const isSelected =
    sidebarItem.children?.length === 0 &&
    (sidebarItem.path === pathname || validateSelect)

  return (
    <Box className='box-sidebar-item'>
      <ListItem key={sidebarItem.path} disablePadding>
        <ListItemButton
          data-testid={sidebarItem.title}
          disableRipple
          className={isSelected ? 'sidebar-item-selected' : 'sidebar-item'}
          sx={muiListItemSx}
          selected={isSelected}
          onClick={() =>
            children.length > 0
              ? handleClick()
              : navigate(sidebarItem.path ?? constants.EMPTY_STRING)}
        >
          {Icon != null ? <Icon className='sidebar-icon' /> : null}
          <span className='sidebar-title'>{sidebarItem.title}</span>
          <Box
            marginLeft='auto'
            onClick={handleClick}
            className='sidebar-item-collapse'
          >
            {sidebarItem.showBadge != null && sidebarItem.showBadge && (
              <Badge
                badgeContent={sidebarItem.badgeValue?.toString()}
                className='mr-1'
              />
            )}
            {children.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
          </Box>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {children.length > 0
          ? children.map((child) =>
            child.roles.includes(getRoleTypeByString(userType))
              ? (
                <SidebarItem
                  key={child.title}
                  childStyle={{ paddingLeft: '1.3em' }}
                  sidebarItem={child}
                />
                )
              : null
          )
          : null}
      </Collapse>
    </Box>
  )
}

export default SidebarItem
