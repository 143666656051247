import { ReactElement, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import ReorderUiPositionDialog from '../reorder-ui-position-dialog/ReorderUiPositionDialog'
import { ReorderUiPositionWrapperProps } from '../../commons/interfaces/commons-interfaces'
import { ReorderItem, ReorderList, getItemKey, modifyDragList } from '../../commons/utils/utils'
import { KEY_NAME_TYPE } from '../../commons/constants/constants'
import { getKeyName } from '../../offers/utils'

const ReorderUiPositionWrapperEdit = ({
  isUIReorderOpen,
  handleClose,
  name = '',
  onReorderSave,
  currentList,
  listWithNewOrder = [],
  currentID,
  handleNameChange,
  isNameUpdated = false,
  isOffer = false,
  isCampaign = false,
  isFeatured = false
}: ReorderUiPositionWrapperProps): ReactElement => {
  const [updatedList, setUpdatedList] = useState<ReorderList>(
    []
  )
  const modifyList = useCallback(() => {
    modifyDragList(currentList, setUpdatedList, name, isOffer, isCampaign, isFeatured)
  }, [currentList, name, isOffer, isCampaign, isFeatured])

  useEffect(() => {
    if (listWithNewOrder?.length > 0) {
      setUpdatedList(_.cloneDeep(listWithNewOrder))
    } else {
      if (
        currentList.some((item) => {
          const itemIDKey = getItemKey(
            item,
            getKeyName(isOffer, isCampaign, KEY_NAME_TYPE.ID)
          ) as keyof ReorderItem
          return itemIDKey != null
            ? item[itemIDKey] === currentID
            : false
        })
      ) {
        setUpdatedList([...currentList])
      } else {
        modifyList()
      }
    }
  }, [currentList, currentID, modifyList, listWithNewOrder, isOffer, isCampaign])

  useEffect(() => {
    if (isNameUpdated && updatedList.length > 0) {
      handleNameChange?.(_.cloneDeep(updatedList))
    }
  }, [handleNameChange, updatedList, isNameUpdated])
  return (
    <ReorderUiPositionDialog
      open={isUIReorderOpen}
      handleClose={handleClose}
      onReorderSave={onReorderSave}
      updatedList={updatedList}
      setUpdatedList={setUpdatedList}
      isOffer={isOffer}
      isCampaign={isCampaign}
      isFeatured={isFeatured}
    />
  )
}

export default ReorderUiPositionWrapperEdit
