import { Autocomplete, FormControl, TextField } from '@mui/material'
import { ReactElement } from 'react'
import './SelfServeSelect.scss'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { SelectOption } from '../../commons/interfaces/commons-interfaces'

const EMPTY_OPTION = {
  id: '',
  label: 'Select',
  groupID: ''
}

const CLEAR = 'clear'

interface SelfServeSelectProps {
  options: SelectOption[]
  value: SelectOption
  setValue: (newValue: SelectOption) => void
}
const SelfServeSelect = ({
  options,
  value,
  setValue
}: SelfServeSelectProps): ReactElement => {
  return (
    <FormControl
      className='form-control mb-1 mt-0 select-component'
      size='small'
    >
      <Autocomplete
        id='select'
        options={options}
        className='select-autocomplete'
        value={value}
        disableClearable
        popupIcon={<ExpandMoreOutlinedIcon />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue, reason) => {
          newValue?.id != null && reason !== CLEAR
            ? setValue(newValue)
            : setValue(EMPTY_OPTION)
        }}
        filterSelectedOptions
        renderOption={(props, option, state) => {
          return (
            <li {...props} key={`${option.id}${state.index}`}>
              {option.label}
            </li>
          )
        }}
        getOptionLabel={(option) => (option.label != null ? option.label : '')}
        filterOptions={(options) => options}
        renderInput={(params) => <TextField {...params} />}

      />
    </FormControl>
  )
}
export default SelfServeSelect
