import { ReactElement, FC, useState, useMemo } from 'react'
import { UsersListParams } from './interfaces/users'
import { t } from 'i18next'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import { Form } from 'react-router-dom'
import {
  allText,
  checkIsNotEmpty,
  handleDateRangeChange,
  selectMenuItem
} from '../commons/utils/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { USERS_CONSTANTS } from './constants'
import SelfServeSearchField from '../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'

dayjs.extend(utc)

interface UsersListHeaderProps {
  handleFormChange: (changedData: UsersListParams) => void
  search?: string
  status?: string
  joinDateStart: string
  joinDateEnd: string
}

const UsersListHeader: FC<UsersListHeaderProps> = ({
  handleFormChange,
  search,
  status,
  joinDateStart,
  joinDateEnd
}): ReactElement => {
  const [startDate, setStartDate] = useState<null | Date>(
    checkIsNotEmpty(joinDateStart) ? new Date(joinDateStart) : null
  )
  const [endDate, setEndDate] = useState<null | Date>(
    checkIsNotEmpty(joinDateEnd) ? new Date(joinDateEnd) : null
  )

  const handleDateChange = (selectedDateRange: Array<Date | null>): void => {
    const { startDateString, endDateString } = handleDateRangeChange(
      selectedDateRange,
      setStartDate,
      setEndDate
    )

    handleFormChange({
      joinDateStart: startDateString ?? '',
      joinDateEnd: endDateString ?? ''
    })
  }

  const userStatusSelectItems = useMemo(
    () =>
      Object.entries(USERS_CONSTANTS.USER_STATUS).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {v}
        </MenuItem>
      )),
    []
  )

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`JoinDate`}
              className='date-picker'
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='status-label'>
            {(status == null || status === '') && allText(t`StatusForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ status: e.target.value as string })}
            value={status}
            inputProps='table-input maxw-8 minw-8'
            menuItem={selectMenuItem(t`AllStatus`)}
            selectItems={userStatusSelectItems}
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default UsersListHeader
