export const titleLength = {
  min: 3,
  max: 50
}
export const nameLength = {
  min: 3,
  max: 50
}
export const subHeader1Length = {
  min: 3,
  max: 25
}
export const subHeader2Length = {
  min: 3,
  max: 25
}
export const descriptionLength = {
  min: 3,
  max: 1000
}
export const pointRange = {
  min: 0,
  max: 10000
}
export const CurrentTime = 'Current time'
export const EndDate = 'End Date'
export const StartDate = 'Start Date'
export const ExpiryDate = 'Expiry Date'
export const EndOfCampaign = 'End of the Campaign'
