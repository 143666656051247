import { CampaignsInterface } from '../campaigns/interfaces/campaigns'
import { SelectOption } from '../commons/interfaces/commons-interfaces'
import { axiosInstance } from '../core/api/init'
import { OffersInterface } from '../offers/interfaces/offers'
import { UsersInterface, UsersListParams } from '../users/interfaces/users'
import {
  CodesInterface,
  CodesListParams,
  SegmentsListParams,
  TargetGroupsInterface
} from './interfaces/segments'

export const segmentsService = {
  getAllSegments: async (
    tenantID?: string,
    agencyID?: string
  ): Promise<SelectOption[]> => {
    const res = await axiosInstance.get('/target-groups/options', {
      params: {
        tenantID,
        agencyID
      }
    })
    return res.data
  },
  getUsersSegments: async (tenantID?: string): Promise<SelectOption[]> => {
    const res = await axiosInstance.get('/target-groups/users', {
      params: { tenantID }
    })
    return res.data
  },
  getNonExistUserList: async (userList: string[]): Promise<string[]> => {
    const res = await axiosInstance.get('/target-groups/non-exist-users', {
      params: { userList }
    })
    return res.data
  },
  usersPrecheck: async (
    id: string,
    userList: string[]
  ): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.get(`/target-groups/${id}/users-precheck`, {
      params: { userList }
    })
    return res.data
  },
  getSegmentByID: async (id: string): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.get(`/target-groups/${id}`)
    return res.data
  },
  getSegments: async (
    params: SegmentsListParams
  ): Promise<TargetGroupsInterface[]> => {
    const res = await axiosInstance.get('/target-groups', { params })
    return res.data
  },
  createSegment: async (
    params: TargetGroupsInterface
  ): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.post('/target-groups', params)
    return res.data
  },
  editSegment: async (
    params: TargetGroupsInterface,
    id: string
  ): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.put(`/target-groups/${id}`, params)
    return res.data
  },
  editRewardsSegment: async (
    params: TargetGroupsInterface,
    id: string
  ): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.put(
      `/target-groups/rewards-code/${id}`,
      params
    )
    return res.data
  },
  createRewardsSegment: async (
    params: TargetGroupsInterface
  ): Promise<TargetGroupsInterface> => {
    const res = await axiosInstance.post('/target-groups/rewards-code', params)
    return res.data
  },
  getUsersForSegments: async (
    params: UsersListParams,
    id: string
  ): Promise<UsersInterface[]> => {
    const res = await axiosInstance.get(`/target-groups/${id}/users`, {
      params
    })
    return res.data
  },
  removeSegment: async (
    Name: string,
    TenantID: string
  ): Promise<UsersInterface[]> => {
    const res = await axiosInstance.delete(`/target-groups/${Name}`, {
      params: { TenantID }
    })
    return res.data
  },
  getCodesForSegments: async (
    id: string,
    params: CodesListParams
  ): Promise<CodesInterface[]> => {
    const res = await axiosInstance.get(`/target-groups/${id}/codes`, {
      params
    })
    return res.data
  },
  isCodeAvailable: async (code: string, id: string): Promise<boolean> => {
    const res = await axiosInstance.get(
      `/target-groups/${id}/codes/${code}/available`
    )
    return res.data
  },
  removeCodeFromSegment: async (code: string, id: string): Promise<boolean> => {
    const res = await axiosInstance.delete(`/target-groups/${id}/codes/${code}`)
    return res.data
  },
  codesPrecheck: async (
    params: TargetGroupsInterface
  ): Promise<boolean> => {
    const res = await axiosInstance.get('/target-groups/codes-precheck', {
      params: { ...params, skipNameValidation: true }
    })
    return res.data
  },
  getSegmentOffers: async (id: string): Promise<OffersInterface[]> => {
    const res = await axiosInstance.get(`/target-groups/${id}/offers`)
    return res.data
  },
  getSegmentCampaigns: async (id: string): Promise<CampaignsInterface[]> => {
    const res = await axiosInstance.get(`/target-groups/${id}/campaigns`)
    return res.data
  }
}

export default segmentsService
