import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { CircularProgress } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import {
  addCampaignToTargetGroup,
  excludeCampaignFromTargetGroup,
  getCampaignByID,
  removeCampaignFromTargetGroup
} from '../slices'
import SegmentsList from '../../segments/SegmentsList'
import EmptySegments from '../../segments/EmptySegments'
import { TargetGroupsInterface } from '../../segments/interfaces/segments'
import SegmentsListHeader from '../../segments/SegmentsListHeader'
import { getAllSegments } from '../../segments/slices'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useState } from 'react'
import { mapSegmentsToIDs } from '../../commons/utils/utils'

const CampaignSegments = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const {
    targetGroupsExclude,
    targetGroupsInclude,
    AgencyID,
    loadingSegments,
    tenantID
  } = useAppSelector((state) => ({
    targetGroupsExclude: state.campaigns.campaignSelected.targetGroupsExclude,
    targetGroupsInclude: state.campaigns.campaignSelected.targetGroupsInclude,
    AgencyID: state.campaigns.campaignSelected.AgencyID,
    loadingSegments: state.campaigns.loadingSegments,
    tenantID: state.auth.tenantID
  }))

  const segmentsIncludedIDs = mapSegmentsToIDs(targetGroupsInclude ?? [])
  const segmentsExcludedIDs = mapSegmentsToIDs(targetGroupsExclude ?? [])

  const dispatch = useAppDispatch()
  const [openRemoveIncludedDialog, setOpenRemoveIncludedDialog] =
    useState<boolean>(false)
  const [openRemoveExcludedDialog, setOpenRemoveExcludedDialog] =
    useState<boolean>(false)
  const [segmentID, setSegmentID] = useState<string>('')
  const [segmentName, setSegmentName] = useState<string>('')

  const handleClose = (): void => {
    setOpenRemoveIncludedDialog(false)
    setOpenRemoveExcludedDialog(false)
  }

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN))
      dispatch(setBackButtonTitle(t`BackToCampaigns`))
      dispatch(setBackButtonRoute(RoutePath.Campaigns))
      void dispatch(getAllSegments({ TenantID: tenantID, AgencyID }))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t, tenantID, AgencyID])

  const removeSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        removeCampaignFromTargetGroup({
          CampaignID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  const addIncludedSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        addCampaignToTargetGroup({
          CampaignID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  const addExcludedSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        excludeCampaignFromTargetGroup({
          CampaignID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  React.useEffect(() => {
    if (id != null && !loadingSegments) {
      void dispatch(getCampaignByID(id))
    }
  }, [loadingSegments, id, dispatch])

  const renderRemoveExcludedDialog = (): React.ReactElement => {
    return (
      <CustomDialog
        title={t`RemoveSegment`}
        open={openRemoveExcludedDialog}
        cancelCallback={handleClose}
        okCallback={() => removeSegment(segmentID)}
        mainMessage={t('RenderRemoveExcludedMessage', { segmentName })}
        okText={t`YesRemoveIt`}
        cancelText={t`Cancel`}
      />
    )
  }

  const renderRemoveIncludedDialog = (): React.ReactElement => {
    return (
      <CustomDialog
        title={t`RemoveSegment`}
        open={openRemoveIncludedDialog}
        cancelCallback={handleClose}
        okCallback={() => removeSegment(segmentID)}
        mainMessage={t('RenderRemoveIncludedMessage', { segmentName })}
        okText={t`YesRemoveIt`}
        cancelText={t`Cancel`}
      />
    )
  }

  const renderSegmentList = (
    excluded: boolean,
    targetGroup?: TargetGroupsInterface[]
  ): React.ReactElement => {
    return targetGroup != null && targetGroup.length > 0
      ? (
        <SegmentsList
          removeSegmentAction={(id, name) => {
            setSegmentID(id ?? '')
            setSegmentName(name ?? '')
            excluded
              ? setOpenRemoveExcludedDialog(true)
              : setOpenRemoveIncludedDialog(true)
          }}
          segmentsList={targetGroup}
        />
        )
      : (
        <EmptySegments excluded />
        )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`CampaignSegments`}</h1>
        <h3 className='title'>{t`IncludedSegmentsTitle`}</h3>
        <SegmentsListHeader
          onAddClick={addIncludedSegment}
          segmentListIDs={segmentsIncludedIDs}
        />
        {renderSegmentList(false, targetGroupsInclude)}
        <h3 className='title'>{t`ExcludedSegmentsTitle`}</h3>
        <SegmentsListHeader
          onAddClick={addExcludedSegment}
          excludeAll
          segmentListIDs={segmentsExcludedIDs}
        />
        {renderSegmentList(true, targetGroupsExclude)}
        {renderRemoveExcludedDialog()}
        {renderRemoveIncludedDialog()}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default CampaignSegments
