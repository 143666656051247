import {
  Box,
  Typography,
  TextField,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { ChangeEvent, FC, useState } from 'react'
import '../segments.scss'
import { useAppSelector } from '../../core/redux/hooks'
import {
  filterNonNumericValues,
  generateRandomCodes,
  handleSubmit,
  replaceWhitespaceWithComma
} from '../../commons/utils/utils'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import { SegmentsFormStepsProps } from '../interfaces/segments'
import { useTranslation } from 'react-i18next'
import {
  EditAutomaticRewardsMethod,
  INVITE_CODES,
  formValidationConstants
} from '../constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { Form } from 'react-router-dom'
import SelfServeButton from '../../ui/button/Button'
import _ from 'lodash'

const SegmentRewardsAutomaticEdit: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  onSubmit,
  onDiscard,
  handleBack
}) => {
  const {
    segments: {
      isFormTouched,
      segmentsForm: {
        generationMethod,
        editMethod,
        prefix,
        codeLength,
        excludedCharacters
      }
    }
  } = useAppSelector((state) => state)
  const { t } = useTranslation()
  const [excludedChars, setExcludedChars] = useState<string[]>(
    excludedCharacters?.split(',') ?? []
  )
  const [numberOfCodes, setNumberOfCodes] = useState<string>('')
  const [radioButtonOption, setRadioButtonOption] = useState<string>('')
  const onChangeWrapper = (
    onChange: (e: string) => void,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ): void => {
    onChange(e.target?.value)
    onFormChange(e.target?.value, name)
  }

  const {
    trigger,
    control,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      numberOfCodes
    }
  })

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        _.isEqual(
          radioButtonOption,
          EditAutomaticRewardsMethod.NEW_CODES_AUTOMATIC.name
        ) &&
          onFormChange(
            generateRandomCodes(
              prefix,
              codeLength ?? 0,
              _.parseInt(numberOfCodes),
              excludedChars
            ),
            INVITE_CODES
          )
        onSubmit != null &&
          handleSubmit(event, trigger, onSubmit).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper'
    >
      <Box className='automatic-codes'>
        <Typography variant='subtitle1' className='mt-1'>
          {t`CodeGenerationMethod`}
        </Typography>
        <Typography className='mb-1'>{generationMethod}</Typography>
        <Typography variant='subtitle1' className='mt-1'>
          {t`EditListOfCodes`}
        </Typography>
        <RadioGroup
          aria-labelledby='segment-csv-radios'
          name='editMethod'
          className='mb-1'
          value={editMethod}
          onChange={(e) => {
            onFormChange(e.target?.value, 'editMethod')
            setRadioButtonOption(e.target?.value)
          }}
        >
          {Object.values(EditAutomaticRewardsMethod).map((v) => {
            return (
              <FormControlLabel
                key={v.name}
                value={v.name}
                control={<Radio />}
                label={t(v.label)}
              />
            )
          })}
        </RadioGroup>
        {_.isEqual(
          radioButtonOption,
          EditAutomaticRewardsMethod.NEW_CODES_AUTOMATIC.name
        ) && (
          <Box>
            <Typography variant='subtitle1' className='mt-1'>
              {t`CharactersToExclude`}
            </Typography>
            <TextField
              fullWidth
              placeholder={t`EnterCharacters`}
              id='excludedChars'
              name='excludedChars'
              onChange={(e) => {
                setExcludedChars(
                  replaceWhitespaceWithComma(e.target?.value).split(',')
                )
                onFormChange(
                  replaceWhitespaceWithComma(e.target?.value),
                  'excludedChars'
                )
              }}
              className='mb-1'
              value={excludedChars}
            />
            <FormFieldBox
              title={t`NumberOfCodesToGenerate`}
              rules={{
                required: formValidationConstants.requiredField,
                min: formValidationConstants.minCodesAmount,
                max: formValidationConstants.maxCodesAmount
              }}
              name='numberOfCodes'
              render={({ field: { name, onChange } }) => (
                <TextField
                  placeholder={t`NewNumberOfCodes`}
                  name={name}
                  id='numberOfCodes'
                  onChange={(e) => {
                    setNumberOfCodes(filterNonNumericValues(e.target?.value))
                    onChangeWrapper(onChange, e, name)
                  }}
                  value={numberOfCodes}
                />
              )}
              control={control}
              errorField={errors.numberOfCodes}
            />
          </Box>
        )}
        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='segments-edit-back'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              id='segments-edit-submit'
              type='submit'
              variant='contained'
              disabled={!isDirty && !isFormTouched}
            >
              {t`UpdateSegment`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='segments-edit-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Box>
    </Form>
  )
}

export default SegmentRewardsAutomaticEdit
