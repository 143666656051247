import { ReactElement, useState } from 'react'
import logo from '../../../assets/images/velocia-logo.png'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { useAppDispatch } from '../../core/redux/hooks'
import '../Login.scss'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { formValidationConstants } from '../constants'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelfServeButton from '../../ui/button/Button'
import {
  Box,
  FormHelperText,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material'
import { regexEmail } from '../../commons/constants/constants'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { forgotPassword } from '../../auth/slices'
import { Link, useNavigate } from 'react-router-dom'
import { RoutePath } from '../../core/routes/routePaths'

const ForgotPassword = (): ReactElement => {
  const THEME = createTheme({
    typography: {
      fontFamily: 'Jost'
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      }
    }
  })
  const dispatch = useAppDispatch()
  const [showEmailSentPopUp, setShowEmailSentPopUp] = useState<boolean>(false)
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const {
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email
    }
  })

  const processForgotPassword = async (): Promise<void> => {
    const noErrors = await trigger()
    if (noErrors) {
      void dispatch(
        forgotPassword({
          email,
          password: ''
        })
      )
      setShowEmailSentPopUp(true)
    }
  }

  const renderConfirmEmailSentPopUp = (
    <CustomDialog
      title={t`EmailSent`}
      open={showEmailSentPopUp}
      customColor='info'
      okCallback={() => {
        setShowEmailSentPopUp(false)
        navigate(RoutePath.Login)
      }}
      cancelCallback={() => setShowEmailSentPopUp(false)}
      mainMessage={t`EmailSentMessage`}
      okText={t`OK`}
    />
  )

  return (
    <ThemeProvider theme={THEME}>
      <div className='Home'>
        <header className='Home-header'>
          <Stack
            component='form'
            sx={{
              width: '30ch'
            }}
            spacing={1}
            noValidate
            autoComplete='off'
            className='login'
          >
            <Box className='column logo-box'>
              <img src={logo} alt='logo' className='logo-image' />
              <Typography variant='h2' className='sign-in-title'>
                {t`ResetYourPassword`}
              </Typography>
            </Box>
            <Typography className='info mb-2'>
              {t`ItHaappensPasswordMessage`}
            </Typography>
            <FormFieldBox
              title={t`EnterYourEmail`}
              rules={{
                required: formValidationConstants.requiredEmail,
                validate: {
                  matchPattern: (v: string) => regexEmail.test(v)
                }
              }}
              name='email'
              render={({ field: { name, onChange } }) => (
                <TextField
                  name={name}
                  id='name'
                  onChange={(e) => {
                    onChange(e.target?.value)
                    setEmail(e.target?.value)
                  }}
                  value={email}
                />
              )}
              control={control}
              errorField={errors.email}
            />
            <FormHelperText error>
              {errors?.email != null && t`EmailFormat`}
            </FormHelperText>
            <SelfServeButton
              id='sign-in'
              variant='contained'
              disabled={email.length < 3}
              onClick={processForgotPassword}
              color='primary'
            >
              {t`VerifyEmail`}
            </SelfServeButton>
            <Link className='mt-2' to={RoutePath.Login}>
              {t`BackToSignIn`}
            </Link>
          </Stack>
        </header>
        {showEmailSentPopUp && renderConfirmEmailSentPopUp}
      </div>
    </ThemeProvider>
  )
}

export default ForgotPassword
