import { ReactElement, useState, useEffect } from 'react'
import logo from '../../assets/images/velocia-logo.png'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import CircularProgress from '@mui/material/CircularProgress'
import { clearAuth, signIn } from '../auth/slices'
import authConstants from '../auth/constants'
import { Form, Link, useNavigate } from 'react-router-dom'
import { RoutePath } from '../core/routes/routePaths'
import './Login.scss'
import FormFieldBox from '../ui/form-field-box/FormFieldBox'
import {
  NO_ATTEMPT_LEFT,
  ONE_ATTEMPT_LEFT,
  formValidationConstants
} from './constants'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelfServeButton from '../ui/button/Button'
import {
  Box,
  FilledInput,
  FormHelperText,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material'
import { handleSubmit } from '../commons/utils/utils'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import _ from 'lodash'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { constants, regexEmail } from '../commons/constants/constants'
import CustomDialog from '../ui/custom-dialog/CustomDialog'

const Login = (): ReactElement => {
  const THEME = createTheme({
    typography: {
      fontFamily: 'Jost'
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      }
    }
  })
  const dispatch = useAppDispatch()
  const { signInLoading, signInErrorMsg, authStatus } = useAppSelector(
    (state) => state.auth
  )
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showErrorPopUp, setShowErrorPopUp] = useState<boolean>(false)
  const [showOneAttemptLeftPopUp, setShowOneAttemptLeftPopUp] =
    useState<boolean>(false)
  const [showNoAttemptLeftPopUp, setShowNoAttemptLeftPopUp] =
    useState<boolean>(false)
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const {
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email,
      password
    }
  })

  const processSignIn = async (): Promise<void> => {
    const noErrors = await trigger()
    if (noErrors) {
      void dispatch(
        signIn({
          email,
          password
        })
      )
    }
  }

  useEffect(() => {
    if (authStatus === authConstants.AuthStatus.LOGGED_IN) {
      navigate(RoutePath.Main)
    }
  }, [authStatus, navigate])

  useEffect(() => {
    if (!_.isEmpty(signInErrorMsg)) {
      if (signInErrorMsg === NO_ATTEMPT_LEFT) {
        setShowNoAttemptLeftPopUp(true)
      } else if (signInErrorMsg === ONE_ATTEMPT_LEFT) {
        setShowOneAttemptLeftPopUp(true)
      } else {
        setShowErrorPopUp(true)
      }
    }

    return () => {
      dispatch(clearAuth())
    }
  }, [signInErrorMsg, dispatch])

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const renderConfirmErrorPopUp = (
    <CustomDialog
      title={t`AccountNotFound`}
      open={showErrorPopUp}
      customColor='info'
      okCallback={() => {
        setShowErrorPopUp(false)
        navigate(RoutePath.ForgotPassword)
      }}
      mainMessage={t`IncorrectEmailOrPassword`}
      okText={t`ForgotPassword`}
      cancelCallback={() => setShowErrorPopUp(false)}
    />
  )

  const renderNoAttemptLeftPopUp = (
    <CustomDialog
      title={t`AccountBlocked`}
      open={showNoAttemptLeftPopUp}
      customColor='info'
      cancelCallback={() => setShowNoAttemptLeftPopUp(false)}
      mainMessage={t`AccountBlockedMsg`}
      okText={t`ResetPassword`}
      titleClassName='error title'
      customBtnColor='primary'
      okButtonClassName='ml-2 mr-2 w-100'
      okCallback={() => {
        setShowNoAttemptLeftPopUp(false)
        navigate(RoutePath.ForgotPassword)
      }}
    />
  )

  const renderOneAttemptLeftPopUp = (
    <CustomDialog
      title={t`OneAttemptLeft`}
      open={showOneAttemptLeftPopUp}
      customColor='info'
      okCallback={() => setShowOneAttemptLeftPopUp(false)}
      mainMessage={t`OneAttemptLeftMsg`}
      okText={t`OK`}
      cancelCallback={() => setShowOneAttemptLeftPopUp(false)}
    />
  )

  if (signInLoading) {
    return (
      <div className='progress'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <ThemeProvider theme={THEME}>
      <div className='Home'>
        <header className='Home-header'>
          <Stack
            component='form'
            sx={{
              width: '30ch'
            }}
            spacing={1}
            noValidate
            autoComplete='off'
            className='login'
          >
            <Box className='column logo-box'>
              <img src={logo} alt='logo' className='logo-image' />
              <Typography variant='h2' className='sign-in-title'>
                {t`SignInTitle`}
              </Typography>
            </Box>
            <Form
              autoComplete='off'
              method='post'
              onSubmit={(event: React.FormEvent) => {
                handleSubmit(event, trigger, processSignIn).catch(
                  (error: any) => reportError(error, ErrorLevels.Error)
                )
              }}
            >
              <FormFieldBox
                title={t`Email`}
                rules={{
                  required: formValidationConstants.requiredEmail,
                  validate: {
                    matchPattern: (v: string) => regexEmail.test(v)
                  }
                }}
                name='email'
                render={({ field: { name, onChange } }) => (
                  <TextField
                    name={name}
                    id='name'
                    onChange={(e) => {
                      onChange(e.target?.value)
                      setEmail(e.target?.value)
                    }}
                    value={email}
                  />
                )}
                control={control}
                errorField={errors.email}
              />
              <FormHelperText error>
                {errors?.email != null && t`EmailFormat`}
              </FormHelperText>
              <FormFieldBox
                title={t`Password`}
                rules={{
                  required: formValidationConstants.requiredPassword
                }}
                name='password'
                render={({ field: { name, onChange } }) => (
                  <FilledInput
                    name={name}
                    id='password'
                    type={showPassword ? constants.TEXT : constants.PASSWORD}
                    endAdornment={
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    onChange={(e) => {
                      onChange(e.target?.value)
                      setPassword(e.target?.value)
                    }}
                    value={password}
                  />
                )}
                control={control}
                errorField={errors.password}
              />
            </Form>
            <SelfServeButton
              id='sign-in'
              variant='contained'
              disabled={[email, password].some((value) => _.isEmpty(value))}
              onClick={processSignIn}
              color='primary'
            >
              {t`SignIn`}
            </SelfServeButton>
            <Link
              className='mt-2'
              to={RoutePath.ForgotPassword}
            >{t`ForgotPassword`}
            </Link>
          </Stack>
        </header>
        {showErrorPopUp && renderConfirmErrorPopUp}
        {showNoAttemptLeftPopUp && renderNoAttemptLeftPopUp}
        {showOneAttemptLeftPopUp && renderOneAttemptLeftPopUp}
      </div>
    </ThemeProvider>
  )
}

export default Login
