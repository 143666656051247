import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { uploadImage } from '../commons/components/uploader/utils'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { RoutePath } from '../core/routes/routePaths'
import AgenciesForm from './AgenciesForm'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import { editAgency, getAgencyByID, resetAgencyForm } from './slices'
import {
  setEntity,
  setEditMode,
  setBackButtonRoute,
  setBackButtonTitle
} from '../ui/sidebar/slices'
import { constants } from '../commons/constants/constants'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import {
  errorDisplay,
  getErrorMessage,
  validationErrorsDisplay
} from '../commons/utils/utils'

const AgenciesEdit: FC<{}> = () => {
  const {
    Url,
    Name,
    DashboardAccess,
    AgencyID,
    Email,
    City,
    State,
    AgencyIconID,
    loading,
    isFulfilled,
    errors
  } = useAppSelector((state) => ({
    Url: state.agencies.agencySelected.Url,
    Name: state.agencies.agencySelected.Name,
    DashboardAccess: state.agencies.agencySelected.DashboardAccess,
    AgencyID: state.agencies.agencySelected.AgencyID,
    Email: state.agencies.agencySelected.Email,
    City: state.agencies.agencySelected.City,
    State: state.agencies.agencySelected.State,
    AgencyIconID: state.agencies.agencySelected.AgencyIconID,
    loading: state.agencies.loading,
    isFulfilled: state.agencies.isFulfilled,
    errors: state.agencies.errors
  }))
  const [errorMessage, setErrorMessage] = useState('')
  const [icon, setIcon] = useState<File | undefined>()
  const [url, setUrl] = useState(Url)
  const [agencyName, setAgencyName] = useState(Name)
  const [state, setState] = useState(State ?? '')
  const [city, setCity] = useState(City ?? '')
  const [dashboardAccess, setDashboardAccess] = useState(DashboardAccess)
  const navigate = useNavigate()
  const [email, setEmail] = useState(Email)
  const [openConfirmEditDialog, setConfirmEditDialog] = useState(false)
  const [openConfirmLeavingDialog, setConfirmLeavingDialog] = useState(false)
  const { userID } = useAppSelector((state) => state.auth)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const editAgencySubmission = async (): Promise<void> => {
    try {
      const upload = await uploadImage(userID, icon)
      if (AgencyID != null) {
        void dispatch(
          editAgency({
            Name: agencyName,
            Email: email,
            DashboardAccess: dashboardAccess,
            AgencyIconID: upload.imageID !== 0 ? upload.imageID : AgencyIconID,
            City: city,
            State: state,
            AgencyID
          })
        )
      }
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (isFulfilled) {
      dispatch(resetAgencyForm())
      navigate(`${RoutePath.Agencies}/${AgencyID ?? ''}`)
    }
  }, [isFulfilled, navigate, dispatch, AgencyID])

  const handleClose = (): void => {
    setConfirmEditDialog(false)
    setConfirmLeavingDialog(false)
  }

  useEffect(() => {
    dispatch(resetAgencyForm())
  }, [dispatch])

  const renderConfirmEditDialog = (
    <CustomDialog
      title={t`EditAgency`}
      open={openConfirmEditDialog}
      cancelCallback={handleClose}
      okCallback={editAgencySubmission}
      mainMessage={t('AgencyEditDialogMessage', { agencyName })}
      okText={t`UpdateAgency`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmLeavingDialog = AgencyID != null && (
    <CustomDialog
      title={t`DiscardAgencyModification`}
      open={openConfirmLeavingDialog}
      cancelCallback={handleClose}
      okCallback={() => navigate(`${RoutePath.Agencies}/${AgencyID}`)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  useEffect(() => {
    if (AgencyID != null) {
      void dispatch(getAgencyByID(AgencyID))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.AGENCY))
      dispatch(setEditMode(true))
      dispatch(setBackButtonTitle(t`BackToAgencies`))
      dispatch(setBackButtonRoute(RoutePath.Agencies))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
      dispatch(setEditMode(false))
    }
  }, [AgencyID, dispatch, t])

  const setValueMap = {
    state: setState,
    city: setCity,
    iconID: setIcon,
    dashboardAccess: setDashboardAccess,
    agencyName: setAgencyName,
    email: setEmail,
    url: setUrl
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <AgenciesForm
        isLeavingDialogOpen={openConfirmLeavingDialog}
        onSubmit={() => {
          setConfirmEditDialog(true)
        }}
        edit
        setValueMap={setValueMap}
        properties={{
          state,
          city,
          icon,
          dashboardAccess,
          agencyName,
          url,
          email
        }}
        onDiscard={() => setConfirmLeavingDialog(true)}
      />
      {errorDisplay(errorMessage)}
      {renderConfirmEditDialog}
      {renderConfirmLeavingDialog}
      {validationErrorsDisplay(errors)}
    </Box>
  )
}

export default AgenciesEdit
