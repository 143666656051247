import { createSlice } from '@reduxjs/toolkit'
import { SidebarState } from '../../../type'
import { sidebarConstants } from './constants'
import { constants } from '../../commons/constants/constants'

const sidebarInitialState = {
  selectedEntityType: constants.SIDEBAR_ENTITY_TYPES.GENERAL,
  editMode: false
}

const sidebar = createSlice({
  name: sidebarConstants.Domain,
  initialState: sidebarInitialState as SidebarState,
  reducers: {
    setEntity (state, action) {
      state.selectedEntityType = action.payload
    },
    setEditMode (state, action) {
      state.editMode = action.payload
    },
    setBackButtonRoute (state, action) {
      state.backButtonRoute = action.payload
    },
    setBackButtonTitle (state, action) {
      state.backButtonTitle = action.payload
    }
  }
})

export const {
  setEntity,
  setEditMode,
  setBackButtonRoute,
  setBackButtonTitle
} = sidebar.actions
export default sidebar.reducer
