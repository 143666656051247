import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { CircularProgress, Typography } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import { getSegmentByID, getSegmentCampaigns } from '../slices'
import SegmentCampaignsList from './SegmentCampaignsList'
import { CampaignsInterface } from '../../campaigns/interfaces/campaigns'

const SegmentsCampaigns = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { campaignsIncluded, campaignsExcluded } = useAppSelector(
    (state) => state.segments
  )

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getSegmentCampaigns(id))
      void dispatch(getSegmentByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const renderCampaignsList = (
    campaigns: CampaignsInterface[],
    excluded: boolean
  ): React.ReactElement => {
    return campaigns?.length > 0
      ? (
        <SegmentCampaignsList campaignList={campaigns} />
        )
      : (
        <Typography>
          {t('NoCampaignsInSegment', {
            excluded: excluded ? t`excluded` : t`included`
          })}
        </Typography>
        )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`Campaigns`}</h1>
        <h3 className='title'>
          {t('IncludedCampaigns', { count: campaignsIncluded?.length })}
        </h3>
        {renderCampaignsList(campaignsIncluded, false)}
        <h3 className='title mt-2'>
          {t('ExcludedCampaigns', { count: campaignsExcluded?.length })}
        </h3>
        {renderCampaignsList(campaignsExcluded, true)}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default SegmentsCampaigns
