import { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useSearchParams, useParams } from 'react-router-dom'
import { getValueForForm } from '../../commons/utils/utils'
import {
  getSegmentByID,
  getCodesForSegments,
  getCodesForSegmentsCSV,
  cleanCodesForSegmentsCSV,
  getCodeNotAvailable,
  removeCodeFromSegment
} from '../slices'
import {
  FIRST_PAGE_USERS_TABLE,
  PER_PAGE_USERS_TABLE
} from '../../users/constants'
import { Box, CircularProgress } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { PAGE, constants } from '../../commons/constants/constants'
import SegmentCodesListHeader from './SegmentCodesListHeader'
import SegmentCodesList from './SegmentCodesList'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { RoutePath } from '../../core/routes/routePaths'
import { CSVLink } from 'react-csv'
import SelfServeButton from '../../ui/button/Button'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { CodesListParams } from '../interfaces/segments'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'

const SegmentCodes: FC<{}> = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const {
    segmentsCodesForCSV,
    segmentsCodesForCSVLoading,
    isCodeAvailable,
    codeDeleted,
    codeDeletedLoading
  } = useAppSelector((state) => state.segments)
  const { t } = useTranslation()
  const csvRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const [openRemoveCodeDialog, setOpenRemoveCodeDialog] =
    useState<boolean>(false)
  const [openUnableToRemoveCodeDialog, setOpenUnableToRemoveCodeDialog] =
    useState<boolean>(false)
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    if (codeDeleted && !codeDeletedLoading) {
      void dispatch(
        getCodesForSegments({
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          ),
          createdDateStart: searchParams.get('createdDateStart') ?? '',
          createdDateEnd: searchParams.get('createdDateEnd') ?? '',
          targetGroupID: id
        })
      )
    }
    if (id != null) {
      void dispatch(getSegmentByID(id))
    }
    handleClose()
  }, [dispatch, searchParams, codeDeleted, codeDeletedLoading, id])

  useEffect(() => {
    return () => {
      dispatch(cleanCodesForSegmentsCSV())
    }
  }, [dispatch])

  useEffect(() => {
    if (!segmentsCodesForCSVLoading && segmentsCodesForCSV?.length > 0) {
      csvRef?.current?.link.click()
    }
  }, [segmentsCodesForCSV, segmentsCodesForCSVLoading])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getCodesForSegments({
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          ),
          createdDateStart: searchParams.get('createdDateStart') ?? '',
          createdDateEnd: searchParams.get('createdDateEnd') ?? '',
          targetGroupID: id
        })
      )
      void dispatch(getSegmentByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [dispatch, searchParams, id, t])

  const csvClick = (): void => {
    void dispatch(
      getCodesForSegmentsCSV({
        search: searchParams.get('search') ?? '',
        createdDateStart: searchParams.get('createdDateStart') ?? '',
        createdDateEnd: searchParams.get('createdDateEnd') ?? '',
        targetGroupID: id
      })
    )
  }

  const removeCodeAction = (code: string): void => {
    if (id != null) {
      void dispatch(getCodeNotAvailable({ code, TargetGroupID: id }))
    }
    setOpenRemoveCodeDialog(true)
    setCode(code)
  }

  const handleClose = (): void => {
    setOpenRemoveCodeDialog(false)
    setOpenUnableToRemoveCodeDialog(false)
  }

  const handleFormChange = ({
    search,
    page,
    perPage,
    createdDateStart,
    createdDateEnd
  }: CodesListParams): void => {
    Object.entries({
      search,
      page,
      perPage,
      createdDateStart,
      createdDateEnd
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  const renderRemoveCodeDialog = (
    <CustomDialog
      title={t`RemoveCode`}
      open={openRemoveCodeDialog}
      cancelCallback={handleClose}
      okCallback={() => {
        if (id != null && isCodeAvailable) {
          void dispatch(removeCodeFromSegment({ code, TargetGroupID: id }))
        } else {
          setOpenRemoveCodeDialog(false)
          setOpenUnableToRemoveCodeDialog(true)
        }
      }}
      mainMessage={
        <Trans i18nKey='RenderRemoveCodeMessage' values={{ code }} />
      }
      okText={t`YesRemoveIt`}
      cancelText={t`Cancel`}
    />
  )

  const renderUnableToRemoveCodeDialog = (
    <CustomDialog
      title={t`ErrorRemovingCode`}
      open={openUnableToRemoveCodeDialog}
      cancelCallback={handleClose}
      okCallback={handleClose}
      mainMessage={t`CodeNoLongerAvailable`}
      okText={t`Ok`}
    />
  )

  return id != null
    ? (
      <Box className='flex-column'>
        <Box className='flex-row space-between'>
          <h1 className='title'>{t`AvailableRewardCodes`}</h1>
          <Box>
            <SelfServeButton
              id='generate-codes-report'
              className='mr-1'
              variant='contained'
              color='secondary'
              onClick={csvClick}
            >
              <FileDownloadOutlinedIcon
                fontSize='small'
                className='button-icon'
              />{' '}
              {t`ExportList`}
            </SelfServeButton>
            <CSVLink
              data={(segmentsCodesForCSV ?? []).map((item) => {
                return {
                  CreatedData: item.CreatedAtUTC,
                  Code: item.PromotionCode,
                  Type: item.RedemptionLimit === 1 ? t`Unique` : t`Repeatable`,
                  AvailableCodes: item.RedemptionLimit - item.claims
                }
              })}
              ref={csvRef}
              filename={`codes-segments-${Date.now()}.csv`}
            />
          </Box>
        </Box>
        <SegmentCodesListHeader
          search={searchParams.get('search') ?? ''}
          createdDateStart={searchParams.get('createdDateStart') ?? ''}
          createdDateEnd={searchParams.get('createdDateEnd') ?? ''}
          handleFormChange={handleFormChange}
        />
        <SegmentCodesList
          onPageChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          )}
          removeCodeAction={removeCodeAction}
        />
        {openRemoveCodeDialog && renderRemoveCodeDialog}
        {openUnableToRemoveCodeDialog && renderUnableToRemoveCodeDialog}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default SegmentCodes
