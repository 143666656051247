import { useTranslation } from 'react-i18next'
import { ReactElement, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import PhonePreview from '../../ui/phone-preview/PhonePreview'
import { OfferPhonePreviewProps } from '../interfaces/offers'
import OfferCardPhonePreview from './OfferCardPhonePreview'
import OfferDetailsPhonePreview from './OfferDetailsPhonePreview'

const OfferPhonePreviewWrapper = ({
  isFromCreateEdit
}: OfferPhonePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const [tab, setTab] = useState<number>(0)
  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setTab(newValue)
  }
  const TAB_0 = 0
  const TAB_1 = 1

  return (
    <Box className='mt-1'>
      <Box className='tabs ml-0'>
        <Tabs value={tab} onChange={handleChange} aria-label='preview tabs'>
          <Tab label={t`OfferCard`} />
          <Tab label={t`OfferDetail`} />
        </Tabs>
      </Box>
      {tab === TAB_0 && (
        <PhonePreview
          content={
            <OfferCardPhonePreview isFromCreateEdit={isFromCreateEdit} />
          }
        />
      )}
      {tab === TAB_1 && (
        <PhonePreview
          content={
            <OfferDetailsPhonePreview isFromCreateEdit={isFromCreateEdit} />
          }
        />
      )}
    </Box>
  )
}

export default OfferPhonePreviewWrapper
