import { axiosInstance } from '../core/api/init'
import { OffersInterface, OffersListParams } from '../offers/interfaces/offers'
import {
  OfferCategoriesInterface,
  OfferCategoriesListParams,
  OfferCategoriesListResponse,
  OfferCategoriesParams,
  OffersByCategoryListResponse
} from './interfaces/offer-categories'

export const offerCategoriesService = {
  getOfferCategories: async (
    params: OfferCategoriesListParams
  ): Promise<OfferCategoriesListResponse> => {
    const res = await axiosInstance.get('/offers-categories', {
      params: {
        perPage: params.perPage,
        page: params.page,
        tenantID: params.tenantID,
        agencyID: params.agencyID,
        userType: params.userType
      }
    })
    return res.data
  },
  getOfferCategoryByID: async (
    params: OfferCategoriesParams
  ): Promise<OfferCategoriesInterface> => {
    const { id, tenantID, agencyID, userType } = params
    const res = await axiosInstance.get(`/offers-categories/${id}`, {
      params: {
        tenantID,
        agencyID,
        userType
      }
    })
    return res.data
  },
  getLiveOffersByCategoryID: async (
    params: OfferCategoriesParams
  ): Promise<OffersInterface[]> => {
    const { id, tenantID, agencyID, userType } = params
    const res = await axiosInstance.get(
      `/offers-categories/${id}/live-offers`,
      {
        params: {
          tenantID,
          agencyID,
          userType
        }
      }
    )
    return res.data
  },
  createOfferCategory: async (
    offerCategory: OfferCategoriesInterface
  ): Promise<OfferCategoriesInterface> => {
    const res = await axiosInstance.post('offers-categories', {
      categoryName: offerCategory.CategoryName,
      webUiPosition: offerCategory.WebUiPosition,
      iconImageID: offerCategory.IconImageID,
      tenantID: offerCategory.TenantID,
      agencyID: offerCategory.AgencyID,
      updatedUIs: offerCategory.UpdatedUIs
    })
    return res.data
  },
  editOfferCategory: async (
    id: number,
    offerCategory: OfferCategoriesInterface
  ): Promise<OfferCategoriesInterface> => {
    const res = await axiosInstance.put(`/offers-categories/${id}`, {
      categoryName: offerCategory.CategoryName,
      webUiPosition: offerCategory.WebUiPosition,
      iconImageID: offerCategory.IconImageID,
      agencyID: offerCategory.AgencyID,
      updatedUIs: offerCategory.UpdatedUIs
    })
    return res.data
  },
  getOffersByCategoryID: async (
    params: OffersListParams,
    id: string
  ): Promise<OffersByCategoryListResponse> => {
    const res = await axiosInstance.get(`/offers-categories/${id}/offers`, {
      params: {
        perPage: params.perPage,
        page: params.page,
        tenantID: params.tenantID,
        agencyID: params.agencyID,
        search: params.search,
        status: params.status,
        offerType: params.offerType,
        userType: params.userType
      }
    })
    return res.data
  }
}

export default offerCategoriesService
