
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as validationConstants from '../constants/ValidationConstants'
import { t } from 'i18next'
import { DateRule } from '../interfaces/campaigns'

dayjs.extend(utc)

export const lengthRule = (min: number, max: number): Object => {
  return {
    minLength: {
      value: min,
      message: t('LengthError', { min, max })
    },
    maxLength: {
      value: max,
      message: t('LengthError', { min, max })
    }
  }
}
export const formValidationConstants = {
  requiredField: { value: true, message: 'Required Field' },
  numberOnly: {
    value: /^[0-9]+$/,
    message: 'Only numbers are allowed'
  }
}
export const numberRangeRule = (
  minNumber: number,
  maxNumber: number
): Object => {
  return {
    min: {
      value: minNumber,
      message: t('numberRangeError', { minNumber, maxNumber })
    },
    max: {
      value: maxNumber,
      message: t('numberRangeError', { minNumber, maxNumber })
    }
  }
}

export const dateBeforeTargetTime = (
  startName: string,
  startTimestampUTC: Date | string | null | undefined,
  targetName: string,
  targetDate: Date | string | null | undefined,
  isFromEdit?: boolean
): DateRule | null => {
  const startDateInUTC =
    dayjs.utc(startTimestampUTC).format()
  const targetDateInUTC =
    targetName === validationConstants.CurrentTime
      ? dayjs.utc().format()
      : dayjs.utc(targetDate).format()
  return isFromEdit === false
    ? {
        validate: {
          required: () => {
            if (dayjs.utc(startDateInUTC).isBefore(dayjs.utc(targetDateInUTC))) {
              return t('IsDateBeforeError', { startName, targetName })
            }
          }
        }
      }
    : null
}
