import { ReactElement } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Box, TextField } from '@mui/material'
import './TextFieldWithEditor.scss'
import SelfServeButton from '../button/Button'
import { t } from 'i18next'
import { TEXTFIELD_EDITOR_TOOLBAR_OPTIONS } from '../../commons/constants/constants'

interface TextFieldWithEditorProps {
  openFormat: boolean
  isDone: boolean
  isChanged: boolean
  value: string
  valueHTML: string
  placeholder: string
  setOpenFormat: (value: boolean) => void
  handleChange: (value: string) => void
  handleSave: () => void
  handleDiscard: () => void
  handleOpenFormat: () => void
  valueMarkdown: string
}

const TextFieldWithEditor = ({
  openFormat,
  isDone,
  isChanged,
  value,
  valueHTML,
  placeholder,
  setOpenFormat,
  handleChange,
  handleSave,
  handleDiscard,
  handleOpenFormat,
  valueMarkdown
}: TextFieldWithEditorProps): ReactElement => {
  return (
    <Box className='field-with-editor'>
      {!openFormat && !isDone && !isChanged && (
        <TextField
          onClick={() => setOpenFormat(true)}
          multiline
          minRows={2}
          className='editor-textbox'
          placeholder={placeholder}
        />
      )}
      {openFormat && (
        <>
          <ReactQuill
            theme='snow'
            modules={{
              toolbar: {
                container: TEXTFIELD_EDITOR_TOOLBAR_OPTIONS
              }
            }}
            value={value !== '' ? value : valueHTML}
            onChange={handleChange}
          />
          <Box className='save-changes-btn-box'>
            <SelfServeButton
              variant='outlined'
              color='secondary'
              id='save-changes'
              onClick={handleSave}
              className='save-changes-btn'
            >
              {t`Save`}
            </SelfServeButton>
            <SelfServeButton
              variant='outlined'
              color='secondary'
              id='discard-changes'
              onClick={handleDiscard}
              className='discard-changes-btn'
            >
              {t`Cancel`}
            </SelfServeButton>
          </Box>
        </>
      )}
      {(isDone || isChanged) && (
        <>
          <ReactMarkdown className='editor-textbox text-markdown'>
            {valueMarkdown ?? ''}
          </ReactMarkdown>
          <Box className='save-changes-btn-box'>
            <SelfServeButton
              data-testid='editButtonTextField'
              id='edit-changes'
              color='secondary'
              variant='outlined'
              onClick={handleOpenFormat}
              className='edit-changes-btn'
            >
              {t`Edit`}
            </SelfServeButton>
          </Box>
        </>
      )}
    </Box>
  )
}

export default TextFieldWithEditor
