import { RouteItem, RoutePath } from '../core/routes/routePaths'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoney'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import { RoleType } from '../core/routes/constants'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'
import i18n from 'i18next'
import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const USER_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]
export const OFFER_TYPE_UNIQUE_CODE = 'UNIQUE_CODE'

export const userBarRoutePath = (id: string): Record<string, RouteItem> => {
  return {
    details: {
      path: `${RoutePath.Users}/${id}`,
      title: i18n.t`Details`,
      roles: USER_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    wallet: {
      path: `${RoutePath.Users}/${id}${RoutePath.Wallet}`,
      title: i18n.t`WalletTransactions`,
      roles: USER_PAGE_ROLES,
      icon: AccountBalanceWalletOutlinedIcon,
      children: []
    },
    campaignEarnings: {
      path: `${RoutePath.Users}/${id}${RoutePath.CampaignEarnings}`,
      title: i18n.t`CampaignEarnings`,
      roles: USER_PAGE_ROLES,
      icon: AttachMoneyOutlinedIcon,
      children: []
    },
    segments: {
      path: `${RoutePath.Users}/${id}${RoutePath.Segments}`,
      title: i18n.t`Segments`,
      roles: USER_PAGE_ROLES,
      icon: DonutSmallOutlinedIcon,
      children: []
    },
    visibleCampaigns: {
      path: `${RoutePath.Users}/${id}${RoutePath.VisibleCampaigns}`,
      title: i18n.t`VisibleCampaigns`,
      roles: USER_PAGE_ROLES,
      icon: CampaignOutlinedIcon,
      children: []
    },
    visibleOffers: {
      path: `${RoutePath.Users}/${id}${RoutePath.VisibleOffers}`,
      title: i18n.t`VisibleOffers`,
      roles: USER_PAGE_ROLES,
      icon: VerifiedOutlinedIcon,
      children: []
    }
  }
}

export const usersListActionItems = (userID: string): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.Users}/${userID}${
        RoutePath.VisibleCampaigns
      }`,
      title: i18n.t`VisibleCampaigns`,
      icon: <CampaignOutlinedIcon />
    },
    {
      path: `${RoutePath.Users}/${userID}${
        RoutePath.VisibleOffers
      }`,
      title: i18n.t`VisibleOffers`,
      icon: <VerifiedOutlinedIcon />
    },
    {
      path: `${RoutePath.Users}/${userID}${
        RoutePath.Segments
      }`,
      title: i18n.t`Segments`,
      icon: <DonutSmallOutlinedIcon />
    },
    {
      path: `${RoutePath.Users}/${userID}${
        RoutePath.CampaignEarnings
      }`,
      title: i18n.t`CampaignEarnings`,
      icon: <AttachMoneyOutlinedIcon />
    },
    {
      path: `${RoutePath.Users}/${userID}${
        RoutePath.Wallet
      }`,
      title: i18n.t`Wallet`,
      icon: <AccountBalanceWalletOutlinedIcon />
    },
    {
      path: `${RoutePath.Users}/${userID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}
