import { FormControl, Box, Divider } from '@mui/material'
import { Form } from 'react-router-dom'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import { FC } from 'react'
import '../create-new-offer/offer-create.scss'
import { useAppSelector } from '../../core/redux/hooks'
import { OfferFormInterface, OffersFormStepsProps } from '../interfaces/offers'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import SelfServeButton from '../../ui/button/Button'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { handleNextStep } from '../../commons/utils/utils'
import { formValidationConstants } from '../constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import SelfServeDatePicker from '../../ui/date-picker/SelfServeDatePicker'
import { FORM_DATE_FORMAT } from '../../commons/constants/constants'

dayjs.extend(utc)

const OfferEditAudienceStep: FC<OffersFormStepsProps> = ({
  handleDateChange,
  handleNext,
  handleBack,
  onDiscard,
  isEdit = false
}) => {
  const {
    offerCreateForm: { startDate, endDate, startDateTimeStamp },
    isFormTouched
  } = useAppSelector((state) => state.offers)

  const {
    control,
    trigger,
    watch,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      startDate,
      endDate
    }
  })

  const sdate = watch('startDate')
  const startTimeStampUnix = dayjs.utc(startDateTimeStamp).unix()
  const currentTimeStampUnix = dayjs.utc().unix()

  return (
    <>
      <Form
        autoComplete='off'
        method='post'
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
      >
        <Box className='mb-1'>
          <FormControl fullWidth>
            <Box className='flex-row space-between'>
              <Box>
                <FormFieldBox
                  title={t`StartDate`}
                  rules={{
                    required: formValidationConstants.startDateError
                  }}
                  name='startDate'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof OfferFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={startDate}
                      isFromEdit={isEdit}
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={startTimeStampUnix < currentTimeStampUnix}
                    />
                  )}
                  control={control}
                  errorField={errors.startDate}
                  isAdjacentDatePicker
                />
              </Box>
              <Box>
                <FormFieldBox
                  title={t`EndDate`}
                  rules={{
                    required: formValidationConstants.endDateError,
                    validate: (value: Date) =>
                      dayjs.utc(value).unix() >= dayjs.utc(sdate).unix() ||
                      t`EndDateValidationError`
                  }}
                  name='endDate'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof OfferFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={endDate}
                      isFromEdit={isEdit}
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={startDate == null || startDate === ''}
                    />
                  )}
                  control={control}
                  errorField={errors.endDate}
                  isAdjacentDatePicker
                />
              </Box>
            </Box>
          </FormControl>
        </Box>
        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='offer-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='offerAudienceNext'
              id='offer-create-next'
              type='submit'
              variant='contained'
              disabled={isEdit ? false : !isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='offer-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </>
  )
}

export default OfferEditAudienceStep
