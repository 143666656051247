import { RouteItem, RoutePath } from '../core/routes/routePaths'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import { RoleType } from '../core/routes/constants'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'
import initTranslation from '../core/utils/i18n'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const CATEGORY_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const categoryBarRoutePath = (
  id: string,
  campaigns?: number
): Record<string, RouteItem> => {
  return {
    details: {
      path: `${RoutePath.CampaignCategories}/${id}`,
      title: i18n.t`Details`,
      roles: CATEGORY_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    campaigns: {
      path: `${RoutePath.CampaignCategories}/${id}${RoutePath.Campaigns}`,
      title: i18n.t`AllCampaigns`,
      roles: CATEGORY_PAGE_ROLES,
      icon: CampaignOutlinedIcon,
      showBadge: true,
      badgeValue: campaigns,
      children: []
    }
  }
}

export const categoriesListActionItems = (categoryID: number): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.CampaignCategories}/${categoryID}${RoutePath.Campaigns}`,
      title: i18n.t`AllCampaigns`,
      icon: <CampaignOutlinedIcon />
    },
    {
      path: `${RoutePath.CampaignCategories}/${categoryID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}
