import Button from '@mui/material/Button'
import { ReactElement, ReactNode, useState } from 'react'
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from '../../../type'

interface PreviewButtonProps {
  children?: ReactNode
  rounded?: boolean
  color?: ButtonColor
  style?: {}
  variant: ButtonVariant
  type?: ButtonType
  disabled?: boolean
  id: string
  size?: ButtonSize
  className?: string
  loader?: () => void
  previewComponent: ReactElement
}

const PreviewButton = ({
  children,
  variant,
  style,
  type,
  disabled,
  id,
  color,
  size,
  className,
  loader,
  previewComponent
}: PreviewButtonProps): ReactElement => {
  const [showPreview, setShowPreview] = useState<Boolean>(false)

  const handleMouseOver = (): void => {
    if (loader != null) {
      loader()
      setShowPreview(true)
    }
  }
  const handleMouseOut = (): void => {
    setShowPreview(false)
  }

  return (
    <Button
      size={size ?? 'medium'}
      color={color ?? 'primary'}
      id={id}
      disabled={disabled}
      type={type}
      style={style}
      variant={variant}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      disableElevation
      className={className}
    >
      {children}
      {showPreview === true && previewComponent}
    </Button>
  )
}

export default PreviewButton
