import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { PAGE, constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { Box } from '@mui/material'
import {
  getEarningsListOptions,
  getUserByID,
  getUserEarningsByID
} from '../slices'
import { RoutePath } from '../../core/routes/routePaths'
import { EarningsListParams } from '../interfaces/users'
import { getValueForForm } from '../../commons/utils/utils'
import { FIRST_PAGE_USERS_TABLE, PER_PAGE_USERS_TABLE } from '../constants'
import UsersEarningsList from './UsersEarningsList'
import UsersEarningsListHeader from './UsersEarningsListHeader'

const UserEarnings = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const {
    users: { userEarningsList, totalEarningsList }
  } = useAppSelector((state) => state)
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const handleFormChange = ({
    page,
    perPage,
    earnedDateRange,
    claimDateRange,
    selectedCampaign
  }: EarningsListParams): void => {
    Object.entries({
      page,
      perPage,
      earnedDateRange,
      claimDateRange,
      selectedCampaign
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getUserByID(id))
      void dispatch(getEarningsListOptions(id))
      void dispatch(
        getUserEarningsByID({
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          ),
          earnedDateRange: searchParams.get('earnedDateRange') ?? '',
          claimDateRange: searchParams.get('claimDateRange') ?? '',
          selectedCampaign: searchParams.get('selectedCampaign') ?? '',
          userID: id
        })
      )
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.USER))
      dispatch(setBackButtonTitle(t`BackToUsers`))
      dispatch(setBackButtonRoute(RoutePath.Users))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t, searchParams])

  return (
    <Box className='flex-column'>
      <h1 className='title'>{t`UserCampaignEarnings`}</h1>
      <UsersEarningsListHeader
        selectedCampaign={searchParams.get('selectedCampaign') ?? ''}
        handleFormChange={handleFormChange}
      />
      <UsersEarningsList
        earnings={userEarningsList}
        total={totalEarningsList}
        onPageChange={handleFormChange}
        page={parseInt(
          searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
        )}
        perPage={parseInt(
          searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
        )}
      />
    </Box>
  )
}

export default UserEarnings
