import { Box, Divider, FormHelperText, Typography } from '@mui/material'
import { t } from 'i18next'
import { ReactElement } from 'react'
import './OffersCoupons.scss'

interface CouponCodesBoxProps {
  totalCoupons: number
  duplicateCoupons: number
  alreadyAddedCoupons: number
  totalValidCoupons: number
  isFromSegments?: boolean
  showValidationText?: boolean
  validationText?: string
}

const codeBoxRow = (title: string, value: number): ReactElement => (
  <Box className='code-box-row'>
    <Typography className='options' variant='subtitle2'>
      {title}
    </Typography>
    <Typography variant='subtitle2' fontWeight='700' fontSize='18px'>
      {value}
    </Typography>
  </Box>
)

const CouponCodesBox = ({
  totalCoupons,
  duplicateCoupons,
  alreadyAddedCoupons,
  totalValidCoupons,
  isFromSegments = false,
  showValidationText = false,
  validationText
}: CouponCodesBoxProps): ReactElement => (
  <Box className='code-box-container'>
    {codeBoxRow(t`CodesAdded`, totalCoupons)}
    {codeBoxRow(t`Duplicated`, duplicateCoupons)}
    {isFromSegments
      ? codeBoxRow(t`CreatedInAnotherSegment`, alreadyAddedCoupons)
      : codeBoxRow(t`AlreadyAdded`, alreadyAddedCoupons)}
    {codeBoxRow(t`ValidCodes`, totalValidCoupons)}
    {showValidationText && (
      <FormHelperText error className='centered-text'>
        {validationText}
      </FormHelperText>
    )}
    <Divider className='code-box-divider' />
  </Box>
)

CouponCodesBox.defaultProps = {
  totalCoupons: 0,
  duplicateCoupons: 0,
  alreadyAddedCoupons: 0,
  totalValidCoupons: 0
}

export default CouponCodesBox
