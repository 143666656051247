import { FC, useEffect } from 'react'
import SelfServeButton from '../ui/button/Button'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import {
  PER_PAGE_OFFER_CATEGORIES_TABLE,
  FIRST_PAGE_OFFER_CATEGORIES_TABLE
} from './constants'
import { userHasTenantPermission } from '../core/routes/router'
import { getRoleTypeByString, getValueForForm } from '../commons/utils/utils'
import { RoutePath } from '../core/routes/routePaths'
import { OfferCategoriesListParams } from './interfaces/offer-categories'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import OfferCategoriesListHeader from './OfferCategoriesListHeader'
import OfferCategoriesList from './OfferCategoriesList'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box } from '@mui/material'
import { getOfferCategories } from './slices'
import { PAGE, constants } from '../commons/constants/constants'

const OfferCategories: FC<{}> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    userType,
    tenantID,
    agencyID,
    isAgencyEnabled,
    offerCategories,
    totals
  } = useAppSelector((state) => ({
    userType: state.auth.userType,
    tenantID: state.auth.tenantID,
    agencyID: state.auth.agencyID,
    isAgencyEnabled: state.auth.isAgencyEnabled,
    offerCategories: state.offerCategories.offerCategories,
    totals: state.offerCategories.totals
  }))

  const isTenant = userHasTenantPermission(getRoleTypeByString(userType))

  const handleFormChange = ({
    perPage,
    page,
    tenantID,
    agencyID,
    userType
  }: OfferCategoriesListParams): void => {
    Object.entries({
      perPage,
      page,
      tenantID,
      agencyID,
      userType
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    void dispatch(
      getOfferCategories({
        tenantID,
        userType,
        agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
        page: parseInt(
          searchParams.get('page') ??
            FIRST_PAGE_OFFER_CATEGORIES_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ??
            PER_PAGE_OFFER_CATEGORIES_TABLE.toString()
        )
      })
    )
  }, [tenantID, agencyID, userType, searchParams, dispatch])

  return (
    <Box className='category-list'>
      <Box className='category-list-headers'>
        <h1 className='title'>{t`OfferCategories`}</h1>
        <SelfServeButton id='new-offer-category-button' variant='contained'>
          <Link to={RoutePath.CreateOfferCategories} className='neutral-link'>
            <AddOutlinedIcon className='button-icon' />
            {t`NewOfferCategory`}
          </Link>
        </SelfServeButton>
      </Box>
      {isTenant && isAgencyEnabled && (
        <Box>
          <OfferCategoriesListHeader
            agencyID={agencyID ?? searchParams.get('agencyID') ?? ''}
            handleFormChange={handleFormChange}
          />
        </Box>
      )}
      <Box>
        <OfferCategoriesList
          isTenant={isTenant}
          page={parseInt(
            searchParams.get('page') ??
              FIRST_PAGE_OFFER_CATEGORIES_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ??
              PER_PAGE_OFFER_CATEGORIES_TABLE.toString()
          )}
          onPageChange={handleFormChange}
          offerCategoriesList={offerCategories}
          total={totals}
        />
      </Box>
    </Box>
  )
}

export default OfferCategories
