import { Box, CircularProgress } from '@mui/material'
import { FC, ReactElement, useEffect, useState } from 'react'
import {
  REORDER_KEY_NAME,
  UI_TYPE,
  constants
} from '../../commons/constants/constants'
import { t } from 'i18next'
import OfferEditOfferInfoStep from '../create-new-offer/OfferCreateOfferInfoStep'
import OfferEditCouponInfoStep from '../create-new-offer/OfferCreateCouponInfoStep'
import OfferEditAudienceStep from './OfferEditAudienceStep'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation,
  useParams
} from 'react-router-dom'
import { RoutePath } from '../../core/routes/routePaths'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import {
  ReorderList,
  errorDisplay,
  getErrorMessage,
  getUpdatedList,
  handleReorderSave,
  isValid,
  modifyUIObjects,
  validationErrorsDisplay
} from '../../commons/utils/utils'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { OfferFormInterface } from '../interfaces/offers'
import {
  getOfferDetailsByID,
  modifyOfferForm,
  resetOfferForm,
  setOfferForm,
  editOffer,
  getOffers
} from '../slices'
import OfferEditPreviewStep from '../create-new-offer/OfferCreatePreviewStep'
import { uploadImage } from '../../commons/components/uploader/utils'
import { blockerCondition } from '../utils'
import {
  setEntity,
  setEditMode,
  setBackButtonRoute,
  setBackButtonTitle
} from '../../ui/sidebar/slices'
import { STEPS_FOR_OFFER_STEPPER } from '../constants'
import OfferPhonePreviewWrapper from '../offers-phone-preview/OfferPhonePreviewWrapper'
import dayjs, { Dayjs } from 'dayjs'
import ReorderUiPositionWrapperEdit from '../../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperEdit'

const OfferEdit: FC<{}> = (): ReactElement | null => {
  const {
    offerCreateForm,
    isFormUpdated,
    offerSelected,
    loading,
    isFulfilled,
    errors,
    offers,
    userID,
    tenantID,
    isAgencyEnabled,
    userType
  } = useAppSelector((state) => ({
    offerCreateForm: state.offers.offerCreateForm,
    isFormUpdated: state.offers.isFormUpdated,
    offerSelected: state.offers.offerSelected,
    loading: state.offers.loading,
    isFulfilled: state.offers.isFulfilled,
    errors: state.offers.errors,
    offers: state.offers.offers,
    userID: state.auth.userID,
    tenantID: state.auth.tenantID,
    isAgencyEnabled: state.auth.isAgencyEnabled,
    userType: state.auth.userType
  }))

  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [openConfirmEditOffer, setOpenConfirmEditOffer] =
    useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigation = useNavigation()
  const { id } = useParams<{ id: string }>()
  const [webUiPosition, setWebUiPosition] = useState(
    offerSelected.WebUiPosition?.toString() ?? ''
  )
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsOfferNameUpdated] = useState<boolean>(false)

  const {
    offerName,
    thumbnailfile,
    offerIconFile,
    agencyID,
    startDate,
    endDate,
    categoryID
  } = offerCreateForm

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleStepChange = (step: number): void => {
    setActiveStep(step)
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }
  const handleFormReset = (): void => {
    dispatch(resetOfferForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(`${RoutePath.Offers}/${id ?? ''}`)
  }
  const handleClose = (): void => {
    setOpenConfirmEditOffer(false)
    setOpenConfirmLeaving(false)
  }
  const onReorderSave = (list: ReorderList): void => {
    handleReorderSave(
      offerName,
      list,
      setWebUiPosition,
      setOpenUIreorder,
      setUpdatedUIs,
      setListWithNewOrder,
      true,
      false,
      false,
      true,
      offerSelected.OfferID
    )
  }
  const handleNameChange = (list: ReorderList): void => {
    setListWithNewOrder(
      getUpdatedList(
        true,
        list,
        REORDER_KEY_NAME.OfferIDKeyWord,
        offerName,
        offerSelected.OfferID
      )
    )
    setIsOfferNameUpdated(false)
  }

  useEffect(() => {
    if ((isValid(agencyID) || !isAgencyEnabled) && isValid(categoryID)) {
      void dispatch(
        getOffers({
          tenantID,
          agencyID,
          categories: categoryID != null ? [categoryID] : []
        })
      )
    }
  }, [dispatch, agencyID, tenantID, categoryID, isAgencyEnabled])

  useEffect(() => {
    if (categoryID === offerSelected.OfferCategoryID) {
      setListWithNewOrder(
        getUpdatedList(
          true,
          offers,
          REORDER_KEY_NAME.OfferIDKeyWord,
          offerName,
          offerSelected.OfferID
        )
      )
    }
  }, [categoryID, offerName, offerSelected, offers])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])

  useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      dispatch(setOfferForm())
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setEditMode(true))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
      dispatch(setEditMode(false))
    }
  }, [dispatch, id])

  const blocker = useBlocker(() => {
    return blockerCondition(
      location.pathname,
      true,
      true,
      openConfirmLeaving || openConfirmEditOffer,
      setOpenConfirmLeaving,
      navigation.location?.pathname
    )
  })

  const editOfferSubmission = async (): Promise<void> => {
    try {
      if (id != null) {
        const iconUpload =
          offerIconFile != null
            ? await uploadImage(userID, offerIconFile)
            : null
        const thumbnailUpload =
          thumbnailfile != null
            ? await uploadImage(userID, thumbnailfile)
            : null
        const startTimeStampUTC = startDate ?? undefined
        const endTimeStampUTC = endDate ?? undefined
        void dispatch(
          editOffer({
            ...offerCreateForm,
            thumbnailIDUpload:
              thumbnailUpload?.imageID === 0 ? null : thumbnailUpload?.imageID,
            offerIconUpload:
              iconUpload?.imageID === 0 ? null : iconUpload?.imageID,
            agencyID,
            startTimeStampUTC,
            endTimeStampUTC,
            id,
            UpdatedUIs: modifyUIObjects(updatedUIs, false, UI_TYPE.OFFER),
            userType,
            tenantID
          })
        )
      }
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    }
  }

  const renderConfirmEditOfferDialog = (
    <CustomDialog
      title={t`ConfirmOfferUpdate`}
      open={openConfirmEditOffer}
      cancelCallback={handleClose}
      okCallback={editOfferSubmission}
      mainMessage={t('OfferEditDialogMessage', { offerName })}
      okText={t`YesUpdateOffer`}
      cancelText={t`Cancel`}
    />
  )
  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardOfferModification`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const onFormChange = (
    value: OfferFormInterface[keyof OfferFormInterface] | Dayjs,
    name: string
  ): void => {
    dispatch(modifyOfferForm({ name, value, offerCreateForm }))
  }

  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof OfferFormInterface,
    onChange: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    onChange(date)
  }
  useEffect(() => {
    handleClose()
  }, [errors])

  useEffect(() => {
    if (isFulfilled) {
      dispatch(resetOfferForm())
      navigate(`${RoutePath.Offers}/${offerSelected.OfferID ?? ''}`)
    }
  }, [dispatch, navigate, isFulfilled, offerSelected])

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <OfferEditOfferInfoStep
              webUiPosition={webUiPosition}
              onFormChange={onFormChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isReorderDisabled={
                isValid(offerName) &&
                (isValid(agencyID) || !isAgencyEnabled) &&
                isValid(categoryID)
              }
              onUiReorderClick={() => setOpenUIreorder(true)}
              resetUIs={() => setResetUIs(true)}
              setIsOfferNameUpdated={() => setIsOfferNameUpdated(true)}
              isEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 1:
        return (
          <Box>
            <OfferEditCouponInfoStep
              onFormChange={onFormChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 2:
        return (
          <Box>
            <OfferEditAudienceStep
              onFormChange={onFormChange}
              handleDateChange={handleDateChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 3:
        return (
          <Box>
            <OfferEditPreviewStep
              webUiPosition={webUiPosition}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              onSubmit={() => setOpenConfirmEditOffer(true)}
              isLeavingDialogOpen={openConfirmLeaving}
              onStepChange={handleStepChange}
              isEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      default:
        return null
    }
  }

  return isFormUpdated
    ? (
      <Box>
        <Box className='offer-create'>
          <h1 className='title'>{t`EditOffer`}</h1>
          <SelfServeStepper
            nonLinear
            steps={STEPS_FOR_OFFER_STEPPER}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        </Box>
        <Box className='mt-2'>
          <Box className='space-between'>
            <Box width={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
            <OfferPhonePreviewWrapper isFromCreateEdit />
          </Box>
        </Box>
        {isUIReorderOpen && (
          <ReorderUiPositionWrapperEdit
            isUIReorderOpen={isUIReorderOpen}
            handleClose={() => setOpenUIreorder(false)}
            name={offerName}
            onReorderSave={onReorderSave}
            currentList={offers}
            listWithNewOrder={listWithNewOrder}
            currentID={offerSelected.OfferID}
            handleNameChange={handleNameChange}
            isNameUpdated={isNameUpdated}
            isOffer
          />
        )}
        {renderConfirmEditOfferDialog}
        {renderConfirmLeavingDialog}
        {validationErrorsDisplay(errors)}
      </Box>
      )
    : null
}

export default OfferEdit
