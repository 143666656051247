import { FC, ReactElement, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CampaignListParams } from '../../campaigns/interfaces/campaigns'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { getAllAgencies } from '../../agencies/slices'
import { RoleType } from '../../core/routes/constants'
import { FilterCampaignStatus } from '../../campaigns/constants'
import {
  AgenciesSelectItems,
  StaticSelectItems,
  allText,
  selectMenuItem
} from '../../commons/utils/utils'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

interface CategoryCampaignsListHeaderProps {
  handleFormChange: (changedData: CampaignListParams) => void
  search?: string
  agency?: string
  status?: string
}

const CategoryCampaignsListHeader: FC<CategoryCampaignsListHeaderProps> = ({
  handleFormChange,
  search,
  agency,
  status
}): ReactElement => {
  const { t } = useTranslation()
  const { userType, tenantID, isAgencyEnabled } = useAppSelector((state) => state.auth)
  const { agencies } = useAppSelector((state) => state.agencies)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userType !== RoleType.AGENCY && isAgencyEnabled) {
      void dispatch(getAllAgencies({ tenantID }))
    }
  }, [tenantID, dispatch, userType, isAgencyEnabled])

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormControl className='form-control' size='small'>
            <InputLabel shrink={false} id='agencies-label'>
              {(agency == null || agency === '') && allText(t`AgencyForSelect`)}
            </InputLabel>
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                handleFormChange({ agencyID: e.target.value as string })}
              value={agency}
              menuItem={selectMenuItem(t`AllAgencies`)}
              selectItems={AgenciesSelectItems(agencies)}
              inputProps='table-input maxw-8 minw-8'
            />
          </FormControl>
        )}
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='status-label'>
            {(status == null || status === '') && allText(t`StatusForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ status: e.target.value as string })}
            value={status}
            menuItem={selectMenuItem(t`AllStatus`)}
            selectItems={StaticSelectItems(FilterCampaignStatus)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default CategoryCampaignsListHeader
