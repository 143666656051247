import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'
import { RouteItem, RoutePath } from '../core/routes/routePaths'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { RoleType } from '../core/routes/constants'
import _ from 'lodash'
import {
  getDuplicatesCountFromList,
  getValidFilteredCouponCodes
} from '../offers/utils'
import { TargetGroupsInterface } from './interfaces/segments'
import {
  createImageID,
  processCSVInputFile,
  setFileInfo
} from '../commons/utils/utils'
import { Dispatch, SetStateAction } from 'react'
import { AnyAction, EmptyObject, ThunkDispatch } from '@reduxjs/toolkit'
import { modifySegmentForm } from './slices'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const CODES = 'Codes'
const SEGMENTS_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const segmentsListActionItems = (
  targetGroupID: string,
  manual?: boolean
): ListActionItems[] => {
  const items = [
    {
      path: `${RoutePath.Segments}/${targetGroupID}${RoutePath.Codes}`,
      title: i18n.t`Codes`,
      icon: <ConfirmationNumberOutlinedIcon />
    },
    {
      path: `${RoutePath.Segments}/${targetGroupID}${RoutePath.Campaigns}`,
      title: i18n.t`Campaigns`,
      icon: <CampaignOutlinedIcon />
    },
    {
      path: `${RoutePath.Segments}/${targetGroupID}${RoutePath.Offers}`,
      title: i18n.t`Offers`,
      icon: <VerifiedOutlinedIcon />
    },
    {
      path: `${RoutePath.Segments}/${targetGroupID}${RoutePath.Users}`,
      title: i18n.t`Users`,
      icon: <PeopleAltOutlinedIcon />
    },
    {
      path: `${RoutePath.Segments}/${targetGroupID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]

  return manual === true ? items.filter((item) => item.title !== CODES) : items
}

export const segmentBarRoutePath = (
  targetGroupID?: string,
  totalUsers?: number,
  totalCodes?: number,
  totalCampaignsForSegments?: number,
  totalOffersForSegments?: number,
  manual?: boolean
): Record<string, RouteItem> => {
  const sidebar = {
    details: {
      path: `${RoutePath.Segments}/${targetGroupID ?? ''}`,
      title: i18n.t`Details`,
      roles: SEGMENTS_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    users: {
      path: `${RoutePath.Segments}/${targetGroupID ?? ''}${RoutePath.Users}`,
      title: i18n.t`Users`,
      roles: SEGMENTS_PAGE_ROLES,
      icon: PeopleAltOutlinedIcon,
      showBadge: true,
      badgeValue: totalUsers,
      children: []
    },
    campaigns: {
      path: `${RoutePath.Segments}/${targetGroupID ?? ''}${
        RoutePath.Campaigns
      }`,
      title: i18n.t`Campaigns`,
      roles: SEGMENTS_PAGE_ROLES,
      icon: CampaignOutlinedIcon,
      showBadge: true,
      badgeValue: totalCampaignsForSegments,
      children: []
    },
    offers: {
      path: `${RoutePath.Segments}/${targetGroupID ?? ''}${RoutePath.Offers}`,
      title: i18n.t`Offers`,
      roles: SEGMENTS_PAGE_ROLES,
      icon: VerifiedOutlinedIcon,
      showBadge: true,
      badgeValue: totalOffersForSegments,
      children: []
    }
  }
  return manual === true
    ? sidebar
    : {
        ...sidebar,
        codes: {
          path: `${RoutePath.Segments}/${targetGroupID ?? ''}${
            RoutePath.Codes
          }`,
          title: i18n.t`Codes`,
          roles: SEGMENTS_PAGE_ROLES,
          icon: ConfirmationNumberOutlinedIcon,
          showBadge: true,
          badgeValue: totalCodes,
          children: []
        }
      }
}

export const getUserList = (userList: string[]): string[] => {
  return (userList ?? []).filter((user) => !_.isEmpty(user))
}

export const getTotalUsersCount = (
  isFromCSV: boolean,
  usersCSV: string[] = [],
  userList: string[] = []
): number => {
  return isFromCSV ? usersCSV?.length : getUserList(userList).length
}

export const getDuplicateCouponsCount = (codes: string[]): number => {
  return codes != null && codes.length > 0
    ? getDuplicatesCountFromList(codes)
    : 0
}

export const getTotalValidCouponsCount = (codes: string[]): number => {
  const filteredCouponCodes = getValidFilteredCouponCodes(codes, [])
  return filteredCouponCodes.length
}

export const getFilteredCouponCodes = (codes: string[]): string[] => {
  return getValidFilteredCouponCodes(codes, [])
}

export const handleFormChangeForCSVFiles = (
  value: TargetGroupsInterface[keyof TargetGroupsInterface],
  setCsv: Dispatch<SetStateAction<string[]>>,
  dispatch: ThunkDispatch<EmptyObject, undefined, AnyAction>,
  csvType: string,
  segmentsForm: TargetGroupsInterface,
  csvFileName: string,
  csvFileID: string
): void => {
  if (value != null) {
    processCSVInputFile(value as Blob, setCsv)
  } else {
    dispatch(
      modifySegmentForm({
        name: csvType,
        value: null,
        segmentsForm
      })
    )
  }
  dispatch(
    modifySegmentForm({
      name: csvFileID,
      value: value != null ? createImageID(value, value as Blob) : null,
      segmentsForm
    })
  )
  dispatch(
    modifySegmentForm({
      name: csvFileName,
      value: value != null ? setFileInfo(value as Blob) : null,
      segmentsForm
    })
  )
}

export const getFilteredCodes = (codes: string[]): string[] => {
  return Object.values(codes)
    .map((item) => item.trim())
    .filter((item) => item !== '')
}
