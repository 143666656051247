import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import { Avatar, Typography } from '@mui/material'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { AgenciesStatuses } from '../constants'
import { editAgencyStatus } from '../slices'
import { useTranslation } from 'react-i18next'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'

const AgencyDetailCard = (): React.ReactElement => {
  const { Url, Name, AgencyID, AgencyStatus } = useAppSelector(
    (state) => state.agencies.agencySelected
  )
  const { editMode } = useAppSelector((state) => state.sidebar)
  const [openEditStatusDialog, setOpenEditStatusDialog] = React.useState(false)
  const [openSwitchDialog, setOpenSwitchDialog] = React.useState(false)
  const [isStatusActive, setIsStatusActive] = React.useState(
    AgencyStatus != null && AgencyStatus === AgenciesStatuses.ACTIVE
  )
  const handleClose = (): void => {
    setOpenEditStatusDialog(false)
  }
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  React.useEffect(
    () =>
      setIsStatusActive(
        AgencyStatus != null && AgencyStatus === AgenciesStatuses.ACTIVE
      ),
    [AgencyStatus]
  )

  const handleChangeStatus = (): void => {
    setOpenEditStatusDialog(false)
    if (AgencyID != null) {
      void dispatch(
        editAgencyStatus({
          AgencyID: AgencyID ?? '',
          AgencyStatus: isStatusActive
            ? AgenciesStatuses.INACTIVE
            : AgenciesStatuses.ACTIVE
        })
      )
    } else {
      reportError('Missing agency ID', ErrorLevels.Error)
    }
  }
  const mainMessage = (
    <>
      {t`AgencyDialogMessageStart`}
      <b>"{Name}"</b>
      {isStatusActive ? t`InactiveAgency` : t`ActiveAgency`}
    </>
  )

  const onClick = (): void => {
    if (editMode) {
      setOpenSwitchDialog(true)
    } else {
      setOpenEditStatusDialog(true)
    }
  }

  const renderSwitchDialog = (
    <CustomDialog
      title={t`EditMode`}
      open={openSwitchDialog}
      okCallback={() => setOpenSwitchDialog(false)}
      cancelCallback={() => setOpenSwitchDialog(false)}
      mainMessage={t`SwitchOnEditMode`}
      okText={t`Ok`}
    />
  )

  const renderWarningDialog = (
    <CustomDialog
      title={
        isStatusActive
          ? t`AgencyDialogMessageTitleInactive`
          : t`AgencyDialogMessageTitleActive`
      }
      open={openEditStatusDialog}
      cancelCallback={handleClose}
      okCallback={handleChangeStatus}
      mainMessage={mainMessage}
      okText={
        isStatusActive ? t`DialogMessageOkInactive` : t`DialogMessageOkActive`
      }
      cancelText={t`AgencyDialogMessageCancel`}
    />
  )

  return (
    <Box className='flex-column' margin='3em 3em'>
      <Avatar className='sidebar-avatar' src={Url} alt='agency-logo' />
      <Typography variant='h5'>{Name}</Typography>
      <Typography variant='subtitle2'>
        {t('AgencySidebarID', { AgencyID })}
      </Typography>
      <Typography
        variant='subtitle1'
        className={
          isStatusActive ? 'status-title success' : 'status-title warning'
        }
      >
        <CustomSwitch
          color='secondary'
          onClick={onClick}
          checked={isStatusActive}
        />
        &nbsp;
        {isStatusActive ? t`Active` : t`Inactive`}
      </Typography>
      {renderWarningDialog}
      {renderSwitchDialog}
    </Box>
  )
}

export default AgencyDetailCard
