import { ReactElement, useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useAppDispatch } from '../../core/redux/hooks'
import TextFieldWithEditorWrapper from '../../ui/text-field/TextFieldWithEditorWrapper'
import _ from 'lodash'
import { Nullable, TextFieldChangeSetterInterface } from '../interfaces/ui'

interface FormsTextFieldWithEditorProps {
  onChange: (value: string) => void
  valueMarkdown: Nullable<string> | undefined
  valueHTML: string
  isChanged: boolean
  placeholder: string
  setIsChanged: (value: string | null) => TextFieldChangeSetterInterface
  resetIsChanged: () => TextFieldChangeSetterInterface
}

const FormsTextFieldWithEditor = ({
  onChange,
  valueMarkdown,
  valueHTML,
  isChanged,
  placeholder,
  setIsChanged,
  resetIsChanged
}: FormsTextFieldWithEditorProps): ReactElement => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!_.isEmpty(valueMarkdown)) {
      dispatch(setIsChanged(valueHTML))
    }
  }, [dispatch, valueMarkdown, valueHTML, setIsChanged])

  return (
    <TextFieldWithEditorWrapper
      valueHTML={valueHTML}
      placeholder={placeholder}
      valueMarkdown={valueMarkdown ?? ''}
      isChanged={isChanged}
      onChange={onChange}
      setIsChanged={setIsChanged}
      resetIsChanged={resetIsChanged}
    />
  )
}

export default FormsTextFieldWithEditor
