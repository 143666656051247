import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { CircularProgress } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import SegmentsList from '../../segments/SegmentsList'
import EmptySegmentsUsers from '../../segments/EmptySegmentsUser'
import { TargetGroupsInterface } from '../../segments/interfaces/segments'
import SegmentsListHeader from '../../segments/SegmentsListHeader'
import { getUsersSegments } from '../../segments/slices'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useState } from 'react'
import { mapSegmentsToIDs } from '../../commons/utils/utils'
import {
  addUserToTargetGroup,
  getUserByID,
  getUserSegmentsByID,
  removeUserFromTargetGroup
} from '../slices'

const UserSegments = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const {
    users: { userSegmentsList, loadSegments },
    auth: { tenantID }
  } = useAppSelector((state) => state)

  const segmentsIncludedIDs = mapSegmentsToIDs(userSegmentsList ?? [])

  const dispatch = useAppDispatch()
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [segmentID, setSegmentID] = useState<string>('')
  const [segmentName, setSegmentName] = useState<string>('')

  const handleClose = (): void => {
    setOpenRemoveDialog(false)
  }

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getUserByID(id))
      void dispatch(getUsersSegments({ TenantID: tenantID }))
      void dispatch(getUserSegmentsByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.USER))
      dispatch(setBackButtonTitle(t`BackToUsers`))
      dispatch(setBackButtonRoute(RoutePath.Users))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t, tenantID])

  const removeSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        removeUserFromTargetGroup({
          UserID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  const addSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        addUserToTargetGroup({
          UserID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  React.useEffect(() => {
    if (id != null && loadSegments) {
      void dispatch(getUserSegmentsByID(id))
    }
  }, [id, dispatch, loadSegments])

  const renderRemoveDialog = (): React.ReactElement => {
    return (
      <CustomDialog
        title={t`RemoveSegment`}
        open={openRemoveDialog}
        cancelCallback={handleClose}
        okCallback={() => removeSegment(segmentID)}
        mainMessage={t('RemoveSegmentMessage', { segmentName })}
        okText={t`YesRemoveIt`}
        cancelText={t`Cancel`}
      />
    )
  }

  const renderSegmentList = (
    targetGroup?: TargetGroupsInterface[]
  ): React.ReactElement => {
    return targetGroup != null && targetGroup.length > 0
      ? (
        <SegmentsList
          usersSegments
          removeSegmentAction={(id, name) => {
            setSegmentID(id ?? '')
            setSegmentName(name ?? '')
            setOpenRemoveDialog(true)
          }}
          segmentsList={targetGroup}
        />
        )
      : (
        <EmptySegmentsUsers />
        )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`UserSegments`}</h1>
        <h3 className='title'>{t`UserSegmentsTitle`}</h3>
        <SegmentsListHeader
          onAddClick={addSegment}
          segmentListIDs={segmentsIncludedIDs}
        />
        {renderSegmentList(userSegmentsList)}
        {renderRemoveDialog()}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default UserSegments
