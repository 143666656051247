import { Tooltip } from '@mui/material'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface SelfServeActionItemProps {
  path: string
  title: string
  icon: ReactElement
}

const SelfServeActionItem = ({
  path,
  title,
  icon
}: SelfServeActionItemProps): ReactElement => (
  <Link to={path}>
    <Tooltip title={title}>{icon}</Tooltip>
  </Link>
)

export default SelfServeActionItem
