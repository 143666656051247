import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../segments.scss'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { useAppSelector } from '../../core/redux/hooks'
import { setDashIfEmpty } from '../../commons/utils/utils'

const SegmentsCodesInfoStep = (): ReactElement | null => {
  const { t } = useTranslation()
  const {
    codeLength,
    excludedCharacters,
    prefix,
    timesCanBeUsed,
    generationMethod
  } = useAppSelector((state) => ({
    codeLength: state.segments.segmentSelected.codeLength,
    excludedCharacters: state.segments.segmentSelected.excludedCharacters,
    prefix: state.segments.segmentSelected.prefix,
    timesCanBeUsed: state.segments.segmentSelected.timesCanBeUsed,
    generationMethod: state.segments.segmentSelected.generationMethod
  }))

  return (
    <Box>
      <Box className='details-box'>
        <SelfServeROTextField
          id='generationMethod'
          label={t`CodeGenerationMethod`}
          defaultValue={generationMethod}
          showDivider
        />
        <SelfServeROTextField
          id='timesCanBeUsed'
          label={t`TimesEachCodeCanBeUsed`}
          defaultValue={`${setDashIfEmpty(timesCanBeUsed?.toString())} ${
            t`times` as string
          }`}
          showDivider
        />
        <SelfServeROTextField
          id='prefix'
          label={t`CodePrefix`}
          defaultValue={setDashIfEmpty(prefix)}
          showDivider
        />
        <SelfServeROTextField
          id='codeLength'
          label={t`LengthOfTheRandomCode`}
          defaultValue={codeLength ?? '-'}
          showDivider
        />
        <SelfServeROTextField
          id='excludedCharacters'
          label={t`ExcludedCharacters`}
          defaultValue={setDashIfEmpty(excludedCharacters)}
          showDivider
        />
      </Box>
    </Box>
  )
}

export default SegmentsCodesInfoStep
