import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { OffersInterface } from '../../offers/interfaces/offers'
import { OffersStatuses } from '../../offers/constants'
import { Link } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import StatusIndicator from '../../ui/status-indicator/StatusIndicator'
import { constants } from '../../commons/constants/constants'
import {
  checkIsNotEmpty,
  setClassNameForTable
} from '../../commons/utils/utils'
import { setStatusColor } from '../../offer-categories/utils'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'
import { offersListActionItems } from '../../offers/utils'
import { RoutePath } from '../../core/routes/routePaths'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import {
  OffersSegmentsListColumnsForTenants,
  OffersSegmentsListColumnsForAgencies
} from '../constants'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'

interface OffersListProps {
  offersList?: OffersInterface[]
}

const OffersSegmentList: FC<OffersListProps> = ({ offersList }) => {
  const {
    auth: { userType, isAgencyEnabled }
  } = useAppSelector((state) => state)

  const COLUMNS = Object.values(
    userType !== RoleType.AGENCY && isAgencyEnabled
      ? OffersSegmentsListColumnsForTenants
      : OffersSegmentsListColumnsForAgencies
  )
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {offersList?.map((row) => (
            <TableRow className='row-with-actions' key={row.OfferID}>
              <TableCell className='centered-cell body-cell'>
                {row.OfferID}
              </TableCell>
              <TableCell className='body-cell'>
                {checkIsNotEmpty(row.WebTitle) ? row.WebTitle : row.Title}
              </TableCell>
              {userType !== RoleType.AGENCY && isAgencyEnabled && (
                <TableCell className='body-cell'>
                  {row.AgencyIconUrl != null && (
                    <img
                      alt='agency-icon'
                      className='rounded-image-list'
                      src={row.AgencyIconUrl}
                    />
                  )}
                  <Link to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}>
                    {row.AgencyName}
                  </Link>
                </TableCell>
              )}
              <TableCell className='body-cell'>{row.CategoryName}</TableCell>
              <TableCell className='body-cell centered-cell'>
                {row.WebUiPosition}
              </TableCell>
              <TableCell className='body-cell'>
                {row.offerstatus != null && (
                  <StatusIndicator
                    color={setStatusColor(row.offerstatus as OffersStatuses)}
                    title={row.offerstatus}
                  />
                )}
              </TableCell>
              <TableCell className='body-cell'>
                <Box className='list-actions flex-center'>
                  <SelfServeActionItem
                    path='/'
                    title={t`Analytics`}
                    icon={<LeaderboardOutlinedIcon />}
                  />
                  {offersListActionItems(row.OfferID?.toString() ?? '').map(
                    (item) => {
                      return (
                        <SelfServeActionItem
                          path={item.path}
                          title={item.title}
                          icon={item.icon}
                          key={item.title}
                        />
                      )
                    }
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider />
    </TableContainer>
  )
}

export default OffersSegmentList
