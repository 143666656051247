import { Card, Typography, Box } from '@mui/material'
import { ReactElement } from 'react'
import { useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'

const LiveOfferPreview = (): ReactElement | null => {
  const { liveOfferSelected, loading } = useAppSelector((state) => state.offers)
  const { t } = useTranslation()
  const pointsToRedeem =
  liveOfferSelected?.VeloAmount != null && +liveOfferSelected.VeloAmount

  return !loading
    ? (
      <Card className='preview-card'>
        <Box className='preview-img'>
          <img className='offer-img' alt='offer' src={liveOfferSelected?.Url} />
        </Box>
        <Box className='preview-info-container'>
          <Typography className='preview-info-points'>
            {pointsToRedeem !== false
              ? pointsToRedeem === 0
                ? t`Free`
                : `${pointsToRedeem} points`
              : null}
          </Typography>
          <Typography className='preview-info-title'>
            {liveOfferSelected?.Title}
          </Typography>
        </Box>
      </Card>
      )
    : null
}
export default LiveOfferPreview
