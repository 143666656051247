import {
  FormControl,
  Box,
  FormHelperText,
  TextField,
  Typography,
  Divider
} from '@mui/material'
import { Form } from 'react-router-dom'
import { FC } from 'react'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import './offer-create.scss'
import { OfferFormInterface, OffersFormStepsProps } from '../interfaces/offers'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import { useAppSelector } from '../../core/redux/hooks'
import SelfServeButton from '../../ui/button/Button'
import {
  handleNextStep,
  filterNonNumericValues
} from '../../commons/utils/utils'
import { formValidationConstants } from '../constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import FormsTextFieldWithEditor from '../../ui/text-field/FormsTextFieldWithEditor'
import { resetIsInstruction, setIsInstruction } from '../slices'

const REPEATABLE_COUPON_CONSTANT = 'isARepeatableCoupon'
const REDEMPTION_LIMIT_CONSTANT = 'hasRedemptionLimit'

const OfferFormCouponInfoStep: FC<OffersFormStepsProps> = ({
  onFormChange,
  handleNext,
  handleBack,
  onDiscard,
  isEdit = false
}) => {
  const {
    offerCreateForm: {
      instructions,
      redirectionLink,
      isARepeatableCoupon,
      hasRedemptionLimit,
      offerRedeemTimesCount,
      offerRedeemTimesDays,
      maxTimeRewardPerUser
    },
    isFormTouched,
    instructionsHTML,
    isInstruction
  } = useAppSelector((state) => state.offers)

  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      instructions,
      redirectionLink,
      isARepeatableCoupon,
      hasRedemptionLimit,
      offerRedeemTimesCount,
      offerRedeemTimesDays,
      maxTimeRewardPerUser
    }
  })

  return (
    <>
      <Form
        autoComplete='off'
        method='post'
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
      >
        <FormFieldBox
          title={t`Instructions`}
          rules={{
            required: formValidationConstants.requiredField,
            validate: () => {
              return instructions != null && instructions !== ''
            },
            minLength: {
              value: 3,
              message: formValidationConstants.instructionsLengthError
            },
            maxLength: {
              value: 1000,
              message: formValidationConstants.instructionsLengthError
            }
          }}
          name='instructions'
          render={({ field: { name, onChange } }) => (
            <FormsTextFieldWithEditor
              onChange={(value: string) => {
                onFormChange(value, name as keyof OfferFormInterface)
                onChange(value)
              }}
              valueMarkdown={instructions}
              valueHTML={instructionsHTML}
              isChanged={isInstruction}
              placeholder={t`AddCouponInstructions`}
              setIsChanged={setIsInstruction}
              resetIsChanged={resetIsInstruction}
            />
          )}
          control={control}
          errorField={errors.instructions}
        />
        <FormFieldBox
          title={`${t`RedirectionLink` as string} (${t`URLFormat` as string})`}
          rules={{}}
          name='redirectionLink'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'redirectionField' }}
              name='redirectionLink'
              id='redirectionLink'
              onChange={(e) => {
                onFormChange(e.target?.value, name as keyof OfferFormInterface)
                onChange(e.target?.value)
              }}
              value={redirectionLink}
              type='url'
            />
          )}
          control={control}
          errorField={errors.redirectionLink}
        />
        <Box className='mb-1'>
          <FormControl fullWidth>
            <FormHelperText>{t`IsARepeatableCoupon`}</FormHelperText>
            <Typography>
              <CustomSwitch
                name='isARepeatableCoupon'
                size='medium'
                onClick={() => {
                  onFormChange(
                    isARepeatableCoupon != null && !isARepeatableCoupon,
                    REPEATABLE_COUPON_CONSTANT as keyof OfferFormInterface
                  )
                }}
                disabled={isEdit}
                checked={isARepeatableCoupon}
              />
              &nbsp;
              {t`RepeatableCouponText`}
            </Typography>
          </FormControl>
        </Box>
        <Box className='mb-1'>
          <FormControl fullWidth>
            <FormHelperText>{t`HasRedemptionLimit`}</FormHelperText>
            <CustomSwitch
              name='hasRedemptionLimit'
              size='medium'
              onClick={() =>
                onFormChange(
                  hasRedemptionLimit != null && !hasRedemptionLimit,
                  REDEMPTION_LIMIT_CONSTANT as keyof OfferFormInterface
                )}
              checked={hasRedemptionLimit}
            />
          </FormControl>
        </Box>
        {hasRedemptionLimit === true && (
          <>
            <Box className='mb-1'>
              <FormHelperText>{t`RedemptionCount`}</FormHelperText>
              <Box className='redemption-count'>
                <FormFieldBox
                  title=''
                  rules={{
                    required: formValidationConstants.requiredField,
                    min: formValidationConstants.minValue,
                    validate: () => {
                      return offerRedeemTimesCount != null
                    },
                    pattern: formValidationConstants.numberPattern
                  }}
                  name='offerRedeemTimesCount'
                  render={({ field: { name, onChange } }) => (
                    <TextField
                      name='offerRedeemTimesCount'
                      id='offerRedeemTimesCount'
                      onChange={(e) => {
                        onFormChange(
                          filterNonNumericValues(e.target?.value),
                          name as keyof OfferFormInterface
                        )
                        onChange(filterNonNumericValues(e.target?.value))
                      }}
                      value={offerRedeemTimesCount ?? ''}
                      placeholder='Count'
                    />
                  )}
                  control={control}
                  errorField={errors.offerRedeemTimesCount}
                  isAdjacentTextField
                />

                <FormFieldBox
                  title=''
                  rules={{
                    required: formValidationConstants.requiredField,
                    min: formValidationConstants.minValue,
                    validate: () => {
                      return offerRedeemTimesDays != null
                    },
                    pattern: formValidationConstants.numberPattern
                  }}
                  name='offerRedeemTimesDays'
                  render={({ field: { name, onChange } }) => (
                    <TextField
                      name='offerRedeemTimesDays'
                      id='offerRedeemTimesDays'
                      onChange={(e) => {
                        onFormChange(
                          filterNonNumericValues(e.target?.value),
                          name as keyof OfferFormInterface
                        )
                        onChange(filterNonNumericValues(e.target?.value))
                      }}
                      value={offerRedeemTimesDays ?? ''}
                      placeholder='Days'
                    />
                  )}
                  control={control}
                  errorField={errors.offerRedeemTimesDays}
                  isAdjacentTextField
                />
              </Box>
            </Box>

            <FormFieldBox
              title={t`MaxTimesEarnByUser`}
              rules={{
                required: formValidationConstants.requiredField,
                min: formValidationConstants.minValue,
                validate: () => {
                  return maxTimeRewardPerUser != null
                },
                pattern: formValidationConstants.numberPattern
              }}
              name='maxTimeRewardPerUser'
              render={({ field: { name, onChange } }) => (
                <TextField
                  name='maxTimeRewardPerUser'
                  id='maxTimeRewardPerUser'
                  onChange={(e) => {
                    onFormChange(
                      filterNonNumericValues(e.target?.value),
                      name as keyof OfferFormInterface
                    )
                    onChange(filterNonNumericValues(e.target?.value))
                  }}
                  value={maxTimeRewardPerUser ?? ''}
                />
              )}
              control={control}
              errorField={errors.maxTimeRewardPerUser}
            />
          </>
        )}
        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='offer-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='couponInfoNext'
              id='offer-create-next'
              type='submit'
              variant='contained'
              disabled={isEdit ? false : !isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='offer-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </>
  )
}

export default OfferFormCouponInfoStep
