import { RoleType } from '../core/routes/constants'
import { RouteItem, RoutePath } from '../core/routes/routePaths'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OFFER_TYPE_UNIQUE_CODE, OffersStatuses } from './constants'
import { SetStateAction } from 'react'
import {
  OfferFormInterface,
  OfferPreviewInterface,
  OffersInterface,
  RepeatableCouponsAddedInterface
} from './interfaces/offers'
import { OfferCategoriesInterface } from '../offer-categories/interfaces/offer-categories'
import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n, { t } from 'i18next'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'
import { ReorderItem, getItemKey } from '../commons/utils/utils'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { KEY_NAME_TYPE, REORDER_KEY_NAME } from '../commons/constants/constants'
import { Box } from '@mui/material'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const OFFER_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const offerBarRoutePath = (
  id: string,
  offerType?: string,
  hasRepeatableCoupons?: boolean
): Record<string, RouteItem> => {
  const offerBarItems = {
    details: {
      path: `${RoutePath.Offers}/${id}`,
      title: i18n.t`Details`,
      setPath: true,
      roles: OFFER_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    coupons: {
      path: `${RoutePath.Offers}/${id}${RoutePath.Coupons}`,
      title: i18n.t`Coupons`,
      setPath: true,
      roles: OFFER_PAGE_ROLES,
      icon: ConfirmationNumberOutlinedIcon,
      children: []
    },
    segments: {
      path: `${RoutePath.Offers}/${id}${RoutePath.Segments}`,
      title: i18n.t`Segments`,
      roles: OFFER_PAGE_ROLES,
      icon: DonutSmallOutlinedIcon,
      children: []
    }
  }

  if (offerType === OFFER_TYPE_UNIQUE_CODE && hasRepeatableCoupons === true) {
    const { details, coupons, segments } = offerBarItems
    return {
      details,
      coupons,
      repeatableSettings: {
        path: `${RoutePath.Offers}/${id}${RoutePath.RepeatableSettings}`,
        title: 'Repeatable Settings',
        roles: OFFER_PAGE_ROLES,
        icon: SettingsOutlinedIcon,
        children: []
      },
      segments
    }
  }
  return offerBarItems
}

export const blockerCondition = (
  currentLocation: string,
  isDirty: boolean,
  isFormTouched: boolean,
  isLeavingDialogOpen: boolean,
  setOpenConfirmLeavingDialog: (value: SetStateAction<boolean>) => void,
  nextLocation?: string
): boolean => {
  if (
    (isDirty || isFormTouched) &&
    nextLocation !== currentLocation &&
    !isLeavingDialogOpen
  ) {
    setOpenConfirmLeavingDialog(true)
    return true
  }
  return false
}

export const getDuplicatesCountFromList = (list: string[]): number => {
  const elementCounts: { [key: string]: number } = {}
  list.forEach((item) => {
    elementCounts[item] = (elementCounts[item] ?? 0) + 1
  })
  const duplicates = Object.values(elementCounts).reduce(
    (acc, val) => acc + (val > 1 ? 1 : 0),
    0
  )
  return duplicates
}

export const getIntersectionCountFromLists = (
  list1: string[],
  list2: string[]
): number => {
  const set2 = new Set(list2)
  const intersection = list1.filter(
    (element) => set2.has(element) && element !== ''
  )
  return intersection.length
}

export const getValidFilteredCouponCodes = (
  list1: string[] = [],
  list2?: string[]
): string[] => {
  const filteredCodesFromDuplicates = Array.from(new Set(list1))
  const list2Set = list2 != null ? new Set(list2) : list2
  const filteredCodesFromExisting =
    list2Set != null
      ? filteredCodesFromDuplicates.filter((code) => !list2Set.has(code))
      : filteredCodesFromDuplicates
  return filteredCodesFromExisting
}

export const getValidFilteredCouponsWithLimits = (
  list1: RepeatableCouponsAddedInterface[],
  list2?: string[]
): RepeatableCouponsAddedInterface[] => {
  const filteredCodesFromDuplicates = list1.filter((obj, index) => {
    return index === list1.findIndex((o) => obj.code === o.code)
  })
  const list2Set = list2 != null ? new Set(list2) : list2
  const filteredCodesFromExisting =
    list2Set != null
      ? filteredCodesFromDuplicates.filter(
        (coupon) => !list2Set.has(coupon.code)
      )
      : filteredCodesFromDuplicates
  return filteredCodesFromExisting
}

export const getOfferPreviewObject = (
  offerCreateForm: OfferFormInterface,
  offer: OffersInterface,
  categories: OfferCategoriesInterface[],
  isFromCreateEdit: boolean
): OfferPreviewInterface => {
  return {
    title: isFromCreateEdit ? offerCreateForm?.title : offer?.WebTitle,
    description: isFromCreateEdit
      ? offerCreateForm?.description
      : offer?.Description,
    url: isFromCreateEdit ? offerCreateForm?.thumbnailID : offer?.Url,
    veloAmount: isFromCreateEdit
      ? offerCreateForm?.listedPoints
      : parseInt(offer?.VeloAmount ?? '0'),
    category: isFromCreateEdit
      ? categories.find((c) => c.OfferCategoryID === offerCreateForm.categoryID)
        ?.CategoryName
      : offer?.CategoryName,
    redeemTimes: isFromCreateEdit
      ? offerCreateForm?.offerRedeemTimesCount
      : offer?.RedemptionsAllowedInTimeFrame,
    redeemDays: isFromCreateEdit
      ? offerCreateForm?.offerRedeemTimesDays
      : offer?.LimitTimeFrameInDays
  }
}

export const offersListActionItems = (offerID: string): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.Offers}/${offerID}${
        RoutePath.Segments
      }`,
      title: i18n.t`Segments`,
      icon: <DonutSmallOutlinedIcon />
    },
    {
      path: `${RoutePath.Offers}/${offerID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}

export const getDuplicatesList = (list: string[]): string[] => {
  const duplicatedList = list.filter(
    (currentValue, currentIndex) => list.indexOf(currentValue) !== currentIndex
  )
  return duplicatedList
}
export const getOfferInfo = (item: ReorderItem): ReactElement => {
  const id = item[
    getItemKey(item, REORDER_KEY_NAME.OfferIDKeyWord) as keyof ReorderItem
  ] as string
  const status = item[
    getItemKey(item, REORDER_KEY_NAME.OfferStatus) as keyof ReorderItem
  ] as string
  return (
    <Box className='offer-info-box'>
      {id != null && (
        <>
          <span className='offer-id'>{t`ID`}: {id}</span>|
          <span
            className={getStatusClassName(status)}
          >
            {status}
          </span>
        </>
      )}
    </Box>
  )
}
const getStatusClassName = (status: string): string => {
  switch (status) {
    case OffersStatuses.LIVE:
      return 'offer-status-title success'
    case OffersStatuses.EXPIRED:
      return 'offer-status-title danger'
    default:
      return 'offer-status-title warning'
  }
}
export const getKeyName = (
  isOffer: boolean,
  isCampaign: boolean,
  type: string
): string => {
  switch (true) {
    case isOffer:
      return type === KEY_NAME_TYPE.Title
        ? REORDER_KEY_NAME.OfferTitle
        : REORDER_KEY_NAME.OfferIDKeyWord
    case isCampaign:
      return type === KEY_NAME_TYPE.Title
        ? REORDER_KEY_NAME.CampaignTitle
        : REORDER_KEY_NAME.CampaignIDKeyWord
    default:
      return type === KEY_NAME_TYPE.Title
        ? REORDER_KEY_NAME.CategoryName
        : REORDER_KEY_NAME.CategoryIDKeyWord
  }
}
