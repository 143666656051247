import { FC, ReactElement, useEffect, useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OffersListParams } from './interfaces/offers'
import { Box, Checkbox, ListItemText } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { chip } from '../offer-categories/utils'
import { getOfferCategories } from '../offer-categories/slices'
import {
  allWithoutText,
  onCategorySelectChange,
  selectMenuItem
} from '../commons/utils/utils'
import SelfServeSearchField from '../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'
interface OffersListHeaderProps {
  handleFormChange: (changedData: OffersListParams) => void
  search?: string
  categories: string[]
}

const OffersListHeader: FC<OffersListHeaderProps> = ({
  handleFormChange,
  search,
  categories
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { AgencyID, tenantID, userType, offerCategories, loading } =
    useAppSelector((state) => ({
      AgencyID: state.agencies.agencySelected.AgencyID,
      tenantID: state.auth.tenantID,
      userType: state.auth.userType,
      offerCategories: state.offerCategories.offerCategories,
      loading: state.offerCategories.loading
    }))

  useEffect(() => {
    void dispatch(
      getOfferCategories({ tenantID, agencyID: AgencyID, userType })
    )
  }, [tenantID, AgencyID, userType, dispatch])

  const categoriesSelectItems = useMemo(
    () =>
      offerCategories.map((category) => (
        <MenuItem
          className='multi-select-item'
          disableRipple
          disableTouchRipple
          key={category.OfferCategoryID}
          value={category.OfferCategoryID?.toString()}
        >
          <Checkbox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={categories.includes(
              category.OfferCategoryID?.toString() ?? ''
            )}
          />
          <ListItemText primary={category.CategoryName} />
        </MenuItem>
      )),
    [offerCategories, categories]
  )

  return !loading
    ? (
      <div>
        <Form role='search'>
          <FormControl className='form-control'>
            <SelfServeSearchField value={search} onChange={handleFormChange} />
          </FormControl>
          <FormControl size='small' className='form-control'>
            {categories?.length === 0 && (
              <InputLabel shrink={false} id='categories-label'>
                {t`CategoriesSelect`}
                {categories?.length === 0 ? allWithoutText : t`Categories`}
              </InputLabel>
            )}
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                onCategorySelectChange(e, handleFormChange)}
              value={categories}
              menuItem={selectMenuItem(t`All`)}
              selectItems={categoriesSelectItems}
              inputProps='table-input minw-7'
              native={false}
              displayEmpty
              renderValue={() => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {categories.map((value) => chip(value, offerCategories))}
                </Box>
              )}
              multiple
            />
          </FormControl>
        </Form>
      </div>
      )
    : (
      <></>
      )
}

export default OffersListHeader
