export const PER_PAGE_OFFER_CATEGORIES_TABLE = 10
export const FIRST_PAGE_OFFER_CATEGORIES_TABLE = 0

export enum OfferCategoriesListColumnsForTenants {
  'OFFER_CATEGORY_ID' = 'ID',
  'NAME' = 'Name',
  'AGENCY' = 'Agency',
  'UI_POSITION' = 'UI Position',
  'ICON' = 'Icon',
  'LIVE_OFFERS' = 'Live Offers',
  'MENU' = ''
}

export enum OfferCategoriesListColumnsForAgencies {
  'OFFER_CATEGORY_ID' = 'ID',
  'NAME' = 'Name',
  'UI_POSITION' = 'UI Position',
  'ICON' = 'Icon',
  'LIVE_OFFERS' = 'Live Offers',
  'MENU' = ''
}

export const offerCategoriesConstants = {
  Domain: 'offerCategories'
}
