import { Box, CircularProgress, FormHelperText } from '@mui/material'
import { FC, ReactElement, useEffect, useState } from 'react'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { TargetGroupsInterface } from '../interfaces/segments'
import {
  codesPrecheck,
  createRewardsSegment,
  createSegment,
  getNonExistUserList,
  modifySegmentForm,
  resetSegmentForm
} from '../slices'
import {
  STEPS_FOR_SEGMENTS_STEPPER,
  STEPS_FOR_REWARDS_SEGMENTS_STEPPER,
  USERS_FILE,
  USERS_CSV,
  INVITE_CODES_FILE,
  CODES_CSV,
  GenerationMethodType,
  USERS_FILE_ID,
  CODES_FILE_ID,
  CODES_FILE
} from '../constants'
import SegmentInformationStep from '../segments-forms/SegmentInformationStep'
import SegmentTypeStep from '../segments-forms/SegmentTypeStep'
import SegmentRewardsInformationStep from '../segments-forms/SegmentRewardsInformationStep'
import { RoutePath } from '../../core/routes/routePaths'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation
} from 'react-router-dom'
import _ from 'lodash'
import SegmentCodesInformationStep from '../segments-forms/SegmentCodesInformationStep'
import { uploadImage } from '../../commons/components/uploader/utils'
import CouponCodesBox from '../../offers/coupons/CouponCodesBox'
import {
  getFilteredCodes,
  getTotalUsersCount,
  getUserList,
  handleFormChangeForCSVFiles
} from '../utils'
import dayjs from 'dayjs'
import UserValidationBox from '../segments-forms/UserValidationBox'
import {
  getDuplicatesList,
  getValidFilteredCouponCodes
} from '../../offers/utils'
import { Trans } from 'react-i18next'
import { COUPONE_TYPE } from '../../offers/constants'

const SegmentsCreate: FC<{}> = (): ReactElement | null => {
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [userCsv, setUserCsv] = useState<string[]>([])
  const [codesCsv, setCodesCsv] = useState<string[]>([])
  const [openConfirmValidatedCodes, setOpenConfirmValidatedCodes] =
    useState<boolean>(false)
  const [openConfirmCodesAdded, setOpenConfirmCodesAdded] =
    useState<boolean>(false)
  const [openConfirmValidatedUsers, setOpenConfirmValidatedUsers] =
    useState<boolean>(false)
  const [openConfirmUsersAdded, setOpenConfirmUsersAdded] =
    useState<boolean>(false)
  const [openConfirmCreation, setOpenConfirmCreation] = useState<boolean>(false)
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [manualTotalUsers, setManualTotalUsers] = useState<number>(0)
  const [duplicatedUsers, setDuplicateUsers] = useState<number>(0)
  const [totalValidUsers, setTotalValidUsers] = useState<number>(0)
  const [totalCoupons, setTotalCoupons] = useState<number>()
  const [couponCodesToAdd, setCouponCodesToAdd] = useState<string[]>([])
  const [filteredCodes, setFileteredCodes] = useState<string[]>([])
  const [codes, setCodes] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const {
    tenantID,
    agencyIDLoggedIn,
    userID,
    userType,
    segmentsForm,
    Name,
    Manual,
    isFromCSV,
    usersCSV,
    userList,
    AgencyID,
    codesCSV,
    areCodesFromCSV,
    inviteCodes,
    description,
    expiryDate,
    notExistUserList,
    welcomeDialogTitle,
    welcomeDialogMessage,
    welcomeDialogLabel,
    welcomePointsImageFile,
    welcomeDialogImageFile,
    welcomePointsTitle,
    welcomePoints,
    timesCanBeUsed,
    prefix,
    codeLength,
    excludedCharacters,
    generationMethod,
    duplicatedCodes,
    alreadyExistCodes,
    notExistCodes,
    loading,
    errors,
    isFulfilled,
    isFormTouched,
    segmentSelected
  } = useAppSelector((state) => ({
    tenantID: state.auth.tenantID,
    agencyIDLoggedIn: state.auth.agencyID,
    userID: state.auth.userID,
    userType: state.auth.userType,
    segmentsForm: state.segments.segmentsForm,
    Name: state.segments.segmentsForm.Name,
    Manual: state.segments.segmentsForm.Manual,
    isFromCSV: state.segments.segmentsForm.isFromCSV,
    usersCSV: state.segments.segmentsForm.usersCSV,
    userList: state.segments.segmentsForm.userList,
    AgencyID: state.segments.segmentsForm.AgencyID,
    codesCSV: state.segments.segmentsForm.codesCSV,
    areCodesFromCSV: state.segments.segmentsForm.areCodesFromCSV,
    inviteCodes: state.segments.segmentsForm.inviteCodes,
    description: state.segments.segmentsForm.description,
    expiryDate: state.segments.segmentsForm.expiryDate,
    notExistUserList: state.segments.segmentsForm.notExistUserList,
    welcomeDialogTitle: state.segments.segmentsForm.welcomeDialogTitle,
    welcomeDialogMessage: state.segments.segmentsForm.welcomeDialogMessage,
    welcomeDialogLabel: state.segments.segmentsForm.welcomeDialogLabel,
    welcomePointsImageFile: state.segments.segmentsForm.welcomePointsImageFile,
    welcomeDialogImageFile: state.segments.segmentsForm.welcomeDialogImageFile,
    welcomePointsTitle: state.segments.segmentsForm.welcomePointsTitle,
    welcomePoints: state.segments.segmentsForm.welcomePoints,
    timesCanBeUsed: state.segments.segmentsForm.timesCanBeUsed,
    prefix: state.segments.segmentsForm.prefix,
    codeLength: state.segments.segmentsForm.codeLength,
    excludedCharacters: state.segments.segmentsForm.excludedCharacters,
    generationMethod: state.segments.segmentsForm.generationMethod,
    duplicatedCodes: state.segments.segmentsForm.duplicatedCodes,
    alreadyExistCodes: state.segments.segmentsForm.alreadyExistCodes,
    notExistCodes: state.segments.segmentsForm.notExistCodes,
    loading: state.segments.loading,
    errors: state.segments.errors,
    isFulfilled: state.segments.isFulfilled,
    isFormTouched: state.segments.isFormTouched,
    segmentSelected: state.segments.segmentSelected
  }))

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const handleCloseAndNavigate = (): void => {
    handleClose()
    navigate(`${RoutePath.Segments}/${segmentSelected.TargetGroupID ?? ''}`)
  }

  const handleClose = (): void => {
    setOpenConfirmCreation(false)
    setOpenConfirmValidatedUsers(false)
    setOpenConfirmUsersAdded(false)
    setOpenConfirmCodesAdded(false)
    setOpenConfirmValidatedCodes(false)
    setOpenConfirmLeaving(false)
  }

  useEffect(() => {
    userCsv.length > 0 &&
      dispatch(
        modifySegmentForm({
          name: USERS_CSV,
          value: userCsv,
          segmentsForm
        })
      )
  }, [userCsv, dispatch, segmentsForm])

  useEffect(() => {
    codesCsv.length > 0 &&
      dispatch(
        modifySegmentForm({
          name: CODES_CSV,
          value: codesCsv,
          segmentsForm
        })
      )
  }, [codesCsv, dispatch, segmentsForm])

  useEffect(() => {
    handleClose()
  }, [errors])

  useEffect(() => {
    setCodes(
      areCodesFromCSV === true
        ? codesCSV ?? []
        : (inviteCodes as string[]) ?? []
    )
  }, [codesCSV, inviteCodes, Manual, areCodesFromCSV])

  useEffect(() => {
    return () => {
      dispatch(resetSegmentForm())
    }
  }, [dispatch])

  useEffect(() => {
    if (codes != null) {
      setFileteredCodes(getFilteredCodes(codes))
    }
  }, [codes])

  useEffect(() => {
    setTotalCoupons(filteredCodes?.length)
    setCouponCodesToAdd(getValidFilteredCouponCodes(filteredCodes, []))
  }, [filteredCodes])

  useEffect(() => {
    if (openConfirmValidatedCodes) {
      void dispatch(
        codesPrecheck({
          Name,
          description,
          inviteCodes: filteredCodes,
          TenantID: tenantID,
          replace: false
        })
      )
    }
  }, [
    Name,
    tenantID,
    filteredCodes,
    description,
    openConfirmValidatedCodes,
    dispatch
  ])

  useEffect(() => {
    const users = isFromCSV === true ? usersCSV : userList
    if (users != null) {
      setManualTotalUsers(getUserList(users).length)
      const duplicates = getDuplicatesList(users ?? [])
      setDuplicateUsers(duplicates.length)
      const validUsersCount =
        totalUsers - _.add(duplicatedUsers, notExistUserList?.length ?? 0)
      setTotalValidUsers(validUsersCount)
    }
  }, [
    userList,
    duplicatedUsers,
    notExistUserList,
    totalUsers,
    isFromCSV,
    usersCSV
  ])

  useEffect(() => {
    if (isFulfilled && Manual === true) {
      setOpenConfirmUsersAdded(true)
      setOpenConfirmValidatedUsers(false)
    } else if (isFulfilled && Manual === false) {
      setOpenConfirmCodesAdded(true)
      setOpenConfirmValidatedCodes(false)
      setOpenConfirmCreation(false)
    }
  }, [isFulfilled, navigate, Manual])

  useEffect(() => {
    const users = isFromCSV === true ? usersCSV : userList
    if (openConfirmValidatedUsers && users != null) {
      const filteredUserList = getValidFilteredCouponCodes(users, [])
      void dispatch(getNonExistUserList(filteredUserList))
    }
  }, [openConfirmValidatedUsers, userList, dispatch, isFromCSV, usersCSV])

  useEffect(() => {
    if (usersCSV != null || userList != null) {
      setTotalUsers(getTotalUsersCount(isFromCSV ?? false, usersCSV, userList))
    }
  }, [usersCSV, userList, isFromCSV])

  const createSegmentSubmission = async (): Promise<void> => {
    const welcomeImageUpload =
      welcomeDialogImageFile != null
        ? await uploadImage(userID, welcomeDialogImageFile)
        : null
    const pointsImageUpload =
      welcomePointsImageFile != null
        ? await uploadImage(userID, welcomePointsImageFile)
        : null
    if (Manual === true) {
      const filteredUserList = getValidFilteredCouponCodes(
        isFromCSV === true ? usersCSV : userList,
        []
      )
      let validUserList = getValidFilteredCouponCodes(
        filteredUserList,
        notExistUserList
      )
      validUserList = Object.values(validUserList).filter(
        (val) => val != null && val.length > 0
      )
      void dispatch(
        createSegment({
          TenantID: tenantID,
          AgencyID: agencyIDLoggedIn ?? AgencyID,
          Name,
          Manual,
          userList: validUserList,
          userType
        })
      )
    } else {
      void dispatch(
        createRewardsSegment({
          TenantID: tenantID,
          AgencyID: agencyIDLoggedIn ?? AgencyID,
          Name,
          Manual,
          inviteCodes: _.isEqual(notExistCodes, 0) ? [] : couponCodesToAdd,
          description,
          expiryDate,
          welcomeDialogTitle,
          welcomeDialogMessage,
          welcomeDialogLabel,
          welcomeDialogImageID:
            welcomeImageUpload?.imageID === 0
              ? null
              : welcomeImageUpload?.imageID,
          welcomePointsImageID:
            pointsImageUpload?.imageID === 0
              ? null
              : pointsImageUpload?.imageID,
          welcomePointsTitle,
          welcomePoints,
          timesCanBeUsed,
          prefix,
          codeLength:
            generationMethod === GenerationMethodType.AUTOMATIC_CODES
              ? codeLength
              : null,
          excludedCharacters,
          generationMethod,
          userType
        })
      )
    }
  }

  const mainMessageCodesAdded = (
    <div className='coupon-dialog-msg'>
      <b>
        {_.isEqual(generationMethod, GenerationMethodType.AUTOMATIC_CODES)
          ? couponCodesToAdd.length
          : notExistCodes}
      </b>
      {_.gt(notExistCodes, 1)
        ? t`CodesAddedSuccessfully`
        : t`CodeAddedSuccessfully`}
    </div>
  )

  const renderConfirmCreation = (
    <CustomDialog
      title={t`CreateNewSegment`}
      open={openConfirmCreation}
      cancelCallback={handleClose}
      okCallback={createSegmentSubmission}
      mainMessage={
        <Trans
          i18nKey='ConfirmSegmentCreationAutomaticCodes'
          values={{
            Name,
            codes: totalCoupons,
            type:
              timesCanBeUsed != null && parseInt(timesCanBeUsed) > 1
                ? COUPONE_TYPE.REPEATABLE
                : COUPONE_TYPE.UNIQUE
          }}
        />
      }
      okText={t`Create`}
      cancelText={t`Discard`}
    />
  )

  const renderConfirmCodesAdded = (
    <CustomDialog
      title=''
      open={openConfirmCodesAdded}
      okCallback={handleCloseAndNavigate}
      cancelCallback={handleCloseAndNavigate}
      mainMessage={mainMessageCodesAdded}
      okText={t`OK`}
      isCustomized
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
      okButtonClassName='coupon-ok-btn'
    />
  )

  const renderConfirmValidatedCodes = (
    <CustomDialog
      title={t`AddCoupons`}
      open={openConfirmValidatedCodes}
      cancelCallback={handleClose}
      okCallback={createSegmentSubmission}
      isOkDisabled={_.isEqual(notExistCodes, 0)}
      mainMessage={
        <CouponCodesBox
          totalCoupons={totalCoupons}
          duplicateCoupons={duplicatedCodes?.length ?? 0}
          isFromSegments
          alreadyAddedCoupons={alreadyExistCodes}
          totalValidCoupons={notExistCodes}
          showValidationText={_.isEqual(notExistCodes, 0)}
          validationText={t`NoValidCodesCreate`}
        />
      }
      okText={t`AddCodes`}
      cancelText={_.isEqual(notExistCodes, 0) ? t`GoBack` : t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const mainMessageUsersAdded = (
    <div className='user-dialog-msg'>
      <p>
        <span>{t('TheSegment')}</span>
        <b> {Name} </b>
        <span>{t('WasCreated')}</span>
      </p>
      <p>
        <span>{t('And')}</span>
        <b> {totalValidUsers} </b>
        <span>{t('UserWereAdded')}</span>
      </p>
    </div>
  )

  const renderConfirmUsersAdded = (
    <CustomDialog
      title=''
      open={openConfirmUsersAdded}
      okCallback={handleCloseAndNavigate}
      cancelCallback={handleCloseAndNavigate}
      mainMessage={mainMessageUsersAdded}
      okText={t`OK`}
      isCustomized
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
      okButtonClassName='coupon-ok-btn'
    />
  )
  const renderConfirmValidatedUsers = (
    <CustomDialog
      title={t`AddUsers`}
      open={openConfirmValidatedUsers}
      cancelCallback={handleClose}
      okCallback={createSegmentSubmission}
      mainMessage={
        <UserValidationBox
          userEntered={manualTotalUsers}
          userNotFound={notExistUserList?.length}
          duplicated={duplicatedUsers}
          newUsersToAdd={totalValidUsers}
        />
      }
      okText={t`Confirm`}
      cancelText={t`Discard`}
      isCustomized
      isFullWidth
      titleClassName='coupon-dialog-title'
      actionClassName='coupon-dialog-actions'
    />
  )

  const handleFormReset = (): void => {
    dispatch(resetSegmentForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(RoutePath.Segments)
  }

  const blockerCondition = (
    currentLocation: string,
    nextLocation?: string
  ): boolean => {
    if (
      isFormTouched &&
      nextLocation !== currentLocation &&
      !openConfirmLeaving &&
      !openConfirmUsersAdded &&
      !openConfirmCodesAdded &&
      !openConfirmValidatedUsers &&
      !openConfirmValidatedCodes &&
      !openConfirmCreation
    ) {
      setOpenConfirmLeaving(true)
      return true
    }
    return false
  }

  const blocker = useBlocker(() => {
    return blockerCondition(location.pathname, navigation.location?.pathname)
  })
  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardSegmentCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const onFormChange = (
    value: TargetGroupsInterface[keyof TargetGroupsInterface],
    name: string
  ): void => {
    if (name === USERS_FILE) {
      handleFormChangeForCSVFiles(
        value,
        setUserCsv,
        dispatch,
        USERS_CSV,
        segmentsForm,
        USERS_FILE,
        USERS_FILE_ID
      )
    } else if (name === INVITE_CODES_FILE) {
      handleFormChangeForCSVFiles(
        value,
        setCodesCsv,
        dispatch,
        CODES_CSV,
        segmentsForm,
        CODES_FILE,
        CODES_FILE_ID
      )
    } else {
      dispatch(
        modifySegmentForm({
          name,
          value,
          segmentsForm
        })
      )
    }
  }

  const renderStepper = (
    <SelfServeStepper
      nonLinear={false}
      steps={
        Manual === true
          ? STEPS_FOR_SEGMENTS_STEPPER
          : STEPS_FOR_REWARDS_SEGMENTS_STEPPER
      }
      setActiveStep={setActiveStep}
      activeStep={activeStep}
    />
  )

  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof TargetGroupsInterface,
    onChange?: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    if (onChange != null) {
      onChange(date)
    }
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  const validationErrorsDisplay = (): ReactElement | null =>
    !_.isEmpty(errors)
      ? (
        <FormHelperText className='mt-1' error>
          {errors}
        </FormHelperText>
        )
      : null

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return (
          <SegmentTypeStep
            onDiscard={handleDiscard}
            onFormChange={onFormChange}
            handleNext={handleNext}
          />
        )
      case 1:
        return Manual === true
          ? (
            <SegmentInformationStep
              onSubmit={() => setOpenConfirmValidatedUsers(true)}
              onFormChange={onFormChange}
              handleBack={handleBack}
              onDiscard={handleDiscard}
            />
            )
          : (
            <SegmentRewardsInformationStep
              handleNext={handleNext}
              handleBack={handleBack}
              handleDateChange={handleDateChange}
              onFormChange={onFormChange}
              onDiscard={handleDiscard}
            />
            )
      case 2:
        return (
          <SegmentCodesInformationStep
            onSubmit={() => {
              if (generationMethod === GenerationMethodType.AUTOMATIC_CODES) {
                setOpenConfirmCreation(true)
              } else {
                setOpenConfirmValidatedCodes(true)
              }
            }}
            onFormChange={onFormChange}
            handleBack={handleBack}
            onDiscard={handleDiscard}
          />
        )
      default:
        return null
    }
  }

  const getSegmentCreationTitle = (): string => {
    return activeStep === 0
      ? t`CreateNewSegment`
      : Manual === true
        ? t`CreateNewManualSegment`
        : t`CreateNewRewardsSegment`
  }

  return (
    <Box>
      <Box className='segment-create'>
        <h1 className='title'>{getSegmentCreationTitle()}</h1>
      </Box>
      {renderStepper}
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        {renderSwitch()}
        {openConfirmValidatedUsers && renderConfirmValidatedUsers}
        {openConfirmUsersAdded && renderConfirmUsersAdded}
        {openConfirmLeaving && renderConfirmLeavingDialog}
        {openConfirmValidatedCodes && renderConfirmValidatedCodes}
        {openConfirmCodesAdded && renderConfirmCodesAdded}
        {openConfirmCreation && renderConfirmCreation}
        {validationErrorsDisplay()}
      </Box>
    </Box>
  )
}

export default SegmentsCreate
