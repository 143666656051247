import i18n from 'i18next'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import initTranslation from '../core/utils/i18n'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export const formValidationConstants = {
  requiredEmail: { value: true, message: i18n.t('EmailRequired') },
  requiredPassword: { value: true, message: i18n.t('PasswordRequired') }
}

export const NO_ATTEMPT_LEFT = 'No attempt left.'
export const ONE_ATTEMPT_LEFT = 'One attempt left.'
