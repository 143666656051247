import { Stack, Chip } from '@mui/material'
import { FC } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import {
  CampaignFormInterface
} from '../../campaigns/interfaces/campaigns'
import { SelectOption } from '../../commons/interfaces/commons-interfaces'
import _ from 'lodash'

interface SelectionChipProps {
  selectedList: string[] | undefined
  selectionOptions?: SelectOption[]
  onFormChange: (value: string[], name: string) => void
  name: string
  isEditable?: boolean
  customClassName?: string
}

const FormSelectionChip: FC<SelectionChipProps> = ({
  selectedList,
  selectionOptions,
  onFormChange,
  name,
  isEditable = false,
  customClassName
}) => {
  return (
    <Stack className={customClassName ?? 'campaignChip'}>
      {selectedList?.map((value) => {
        const label = selectionOptions?.find((v) => v.id === value)
        return (
          <Chip
            key={value}
            label={!_.isEmpty(label) ? label.label : value}
            onDelete={() =>
              onFormChange(
                selectedList.filter((item) => item !== value),
                name as keyof CampaignFormInterface
              )}
            disabled={isEditable}
            deleteIcon={
              <ClearIcon onMouseDown={(event) => event.stopPropagation()} />
            }
          />
        )
      })}
    </Stack>
  )
}

export default FormSelectionChip
