import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { CircularProgress, Typography } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import { getSegmentByID, getSegmentOffers } from '../slices'
import { OffersInterface } from '../../offers/interfaces/offers'
import OffersSegmentList from './OffersSegmentList'

const SegmentsOffers = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { offersIncluded, offersExcluded } = useAppSelector(
    (state) => state.segments
  )

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getSegmentOffers(id))
      void dispatch(getSegmentByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const renderOffersList = (
    offers: OffersInterface[],
    excluded: boolean
  ): React.ReactElement => {
    return offers?.length > 0
      ? (
        <OffersSegmentList offersList={offers} />
        )
      : (
        <Typography>
          {t('NoOffersInSegment', {
            excluded: excluded ? t`excluded` : t`included`
          })}
        </Typography>
        )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`Offers`}</h1>
        <h3 className='title'>
          {t('IncludedOffers', { count: offersIncluded.length })}
        </h3>
        {renderOffersList(offersIncluded, false)}
        <h3 className='title mt-2'>
          {t('ExcludedOffers', { count: offersExcluded.length })}
        </h3>
        {renderOffersList(offersExcluded, true)}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default SegmentsOffers
