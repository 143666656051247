export const PER_PAGE_USERS_TABLE = 10
export const FIRST_PAGE_USERS_TABLE = 0
export const PER_PAGE_WALLET_TABLE = 10
export const FIRST_PAGE_WALLET_TABLE = 0

export enum UsersListColumns {
  'ID' = 'User ID',
  'VELOCIA_ID' = 'Velocia ID',
  'JOIN_DATE' = 'Joined Date',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum WalletListColumns {
  'ID' = 'Transaction ID',
  'DESCRIPTION' = 'Description',
  'DATE' = 'Date',
  'AMOUNT' = 'Amount',
  'MENU' = ''
}

export enum TenantUserCampaignsListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum AgencyUserCampaignsListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum TenantUserOffersListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'REDEEMED' = 'Redeemed',
  'MENU' = ''
}

export enum AgencyUserOffersListColumns {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'REDEEMED' = 'Redeemed',
  'MENU' = ''
}

export enum EarningsListColumns {
  'CAMPAIGN' = 'Campaign',
  'EARNED_DATE' = 'Earned Date',
  'CLAIM_DATE' = 'Claim Date',
  'TRANSACTION_ID' = 'Transaction ID',
  'POINTS' = 'Points',
  'MENU' = ''
}

export const USERS_CONSTANTS = {
  Domain: 'users',
  USER_STATUS: {
    NEW: 'New',
    ACTIVE: 'Active',
    SUSPENDED: 'Suspended',
    INACTIVE: 'Inactive'
  }
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW',
  SUSPENDED = 'SUSPENDED'
}

export enum UserPrivacyStatus {
  OPTED_OUT = 'OPTED_OUT',
  OPTED_IN = 'OPTED_IN'
}

export enum TransactionTypeOptions {
  EARN = 'Campaign Earnings',
  EXPIRY_DEDUCTION = 'Expiry Deduction',
  BALANCE_DEDUCTION = 'Inactivity Deduction',
  SPEND = 'Redemptions',
  REFUND = 'Refund',
  SUSPENSION_DEDUCTION = 'Suspension Deduction'
}

export const transactionConditionType = {
  FromConnectCampaign: {
    type: 'App Connect',
    titleFieldName: 'ConnectCampaignProvider',
    idFieldName: null
  },
  FromFriendReferralCampaign: {
    type: 'Friend Referral',
    titleFieldName: 'FriendEmail',
    idFieldName: null
  },
  FromSurveyCampaign: {
    type: 'Survey Campaign',
    titleFieldName: 'SurveyCampaignTitle',
    idFieldName: 'SurveyCampaignID'
  },
  FromActionCampaign: {
    type: 'Action Campaign',
    titleFieldName: 'ActionCampaignTitle',
    idFieldName: 'ActionCampaignID'
  },
  FromReferralCampaign: {
    type: 'Referral Campaign',
    titleFieldName: 'ReferralCampaignTitle',
    idFieldName: 'ReferralCampaignID'
  },
  FromLocationCampaign: {
    type: 'Location Campaign',
    titleFieldName: 'LocationCampaignTitle',
    idFieldName: 'LocationCampaignID'
  },
  FromVelociaSignup: {
    type: 'Velocia Signup',
    titleFieldName: null,
    idFieldName: null
  },
  FromOfferRedemption: {
    type: 'Offer Redeemed',
    titleFieldName: 'RedeemedOfferTitle',
    idFieldName: 'RedeemedOfferID'
  },
  FromTransactionalCampaign: {
    type: 'Transactional Campaign',
    titleFieldName: 'TransactionalCampaignTitle',
    idFieldName: 'TransactionalCampaignID'
  },
  FromGTReportCampaign: {
    type: 'GT Report Campaign',
    titleFieldName: 'GeoLocatedCampaignTitle',
    idFieldName: null
  },
  ScheduledCampaignEarningID: {
    type: 'Scheduled Campaign',
    titleFieldName: 'ScheduledCampaignTitle',
    idFieldName: null
  },
  FriendReferralSignUpTransaction: {
    type: 'Velocia Referral Signup',
    titleFieldName: 'FriendReferralSignUpCampaignTitle',
    idFieldName: null
  },
  PromotionCampaignTitle: {
    type: 'Promotion Campaign Claims',
    titleFieldName: null,
    idFieldName: null
  },
  FromSuspensionDeduction: {
    type: 'Suspension Deduction',
    titleFieldName: null,
    idFieldName: null
  },
  FromExpiryDeduction: {
    type: 'Expiry Deductions',
    titleFieldName: 'EarningCampaignID',
    idFieldName: 'ExpiryCampaignTitle'
  },
  FromInactivityDeduction: {
    type: 'Inactivity Deductions',
    titleFieldName: null,
    idFieldName: null
  },
  FromRefunds: {
    type: 'Refund',
    titleFieldName: 'RefundOfferTitle',
    idFieldName: 'RefundOfferID'
  },
  FromExternalSurveyCampaign: {
    type: 'Survey Campaign',
    titleFieldName: 'ExternalSurveyCampaignTitle',
    idFieldName: 'ExternalSurveyCampaignID'
  }
}
