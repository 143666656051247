import axios from 'axios'

let baseURL: string | undefined = process.env.REACT_APP_API_URL
if (baseURL === undefined) {
  baseURL = ''
}

export const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300
  }
})
