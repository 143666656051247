import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { AgenciesListSearch } from './interfaces/agencies'
import {
  AgenciesListColumns,
  AgenciesStatuses,
  FIRST_PAGE_AGENCIES_TABLE,
  PER_PAGE_AGENCIES_TABLE,
  agenciesConstants
} from './constants'
import { Link } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import './agencies.scss'
import { Box } from '@mui/material'
import StatusIndicator from '../ui/status-indicator/StatusIndicator'
import SelfServePagination from '../ui/pagination/SelfServePagination'
import { constants } from '../commons/constants/constants'
import { RoutePath } from '../core/routes/routePaths'
import { isAtIndexFromEnd } from '../commons/utils/utils'
import { useAppSelector } from '../core/redux/hooks'
import SelfServeActionItem from '../ui/list-action-item/SelfServeActionItem'
import { agenciesListActionItems } from './utils'

interface AgenciesListProps {
  onPageChange: (changedData: AgenciesListSearch) => void
  page?: number
  perPage?: number
}

const COLUMNS = Object.values(AgenciesListColumns)

const AgenciesList: FC<AgenciesListProps> = ({
  page,
  perPage,
  onPageChange
}) => {
  const { filteredAgencies, totals } = useAppSelector((state) => state.agencies)

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={
                  isAtIndexFromEnd(i, 1, COLUMNS)
                    ? 'last-header-cell'
                    : isAtIndexFromEnd(i, 2, COLUMNS)
                      ? 'second-last-header-cell'
                      : 'header-cell'
                }
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {filteredAgencies.length > 0 && (
          <TableBody>
            {filteredAgencies.map(
              (row) =>
                row.AgencyID != null && (
                  <TableRow className='row-with-actions' key={row.AgencyID}>
                    <TableCell className='body-cell'>
                      <Link to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}>
                        {row.AgencyID}
                      </Link>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <img
                        alt='agency-logo'
                        className='rounded-image-list'
                        src={row.Url}
                      />
                      <Link to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}>
                        {row.Name}
                      </Link>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Box>{row.City}</Box>
                      <Box className='mini-subtitle'>{row.State}</Box>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <StatusIndicator
                        color={
                          row.AgencyStatus === AgenciesStatuses.ACTIVE
                            ? 'success'
                            : 'warning'
                        }
                        title={
                          agenciesConstants.Statuses[
                            (row.AgencyStatus ??
                              AgenciesStatuses.INACTIVE) as keyof typeof agenciesConstants.Statuses
                          ]
                        }
                      />
                    </TableCell>
                    <TableCell
                      className='body-cell admin-access'
                      align='center'
                    >
                      {row.DashboardAccess != null && row.DashboardAccess
                        ? (
                          <CheckCircleIcon fontSize='small' color='success' />
                          )
                        : (
                          <DoNotDisturbOnIcon fontSize='small' color='disabled' />
                          )}
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Box className='list-actions flex-center'>
                        {agenciesListActionItems(row.AgencyID).map((item) => {
                          return (
                            <SelfServeActionItem
                              path={item.path}
                              title={item.title}
                              icon={item.icon}
                              key={item.title}
                            />
                          )
                        })}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_AGENCIES_TABLE}
        perPage={perPage ?? PER_PAGE_AGENCIES_TABLE}
        onPageChange={(page) => onPageChange({ page })}
        onPerPageChange={(perPage) => onPageChange({ perPage })}
        total={totals}
        length={filteredAgencies.length}
      />
    </TableContainer>
  )
}

export default AgenciesList
