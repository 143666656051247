import { FormControl, Box, InputLabel, Divider } from '@mui/material'
import { Form } from 'react-router-dom'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import { useEffect, FC } from 'react'
import './offer-create.scss'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { getAllSegments } from '../../segments/slices'
import { OfferFormInterface, OffersFormStepsProps } from '../interfaces/offers'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import SelfServeButton from '../../ui/button/Button'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { handleNextStep, SegmentList } from '../../commons/utils/utils'
import { formValidationConstants } from '../constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import SelectionChip from '../../ui/form-selection-chip/FormSelectionChip'
import SelfServeDatePicker from '../../ui/date-picker/SelfServeDatePicker'
import { FORM_DATE_FORMAT } from '../../commons/constants/constants'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

dayjs.extend(utc)

const OfferCreateAudienceStep: FC<OffersFormStepsProps> = ({
  onFormChange,
  handleDateChange,
  handleNext,
  handleBack,
  onDiscard
}) => {
  const {
    offerCreateForm: {
      segmentsIncluded,
      segmentsExcluded,
      startDate,
      endDate,
      agencyID
    },
    isFormTouched
  } = useAppSelector((state) => state.offers)
  const { segmentOptions } = useAppSelector((state) => state.segments)
  const dispatch = useAppDispatch()
  const { tenantID, agencyID: agencyIDLoggedIn } = useAppSelector(
    (state) => state.auth
  )

  const {
    control,
    trigger,
    watch,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      segmentsIncluded,
      segmentsExcluded,
      startDate,
      endDate
    }
  })

  const sdate = watch('startDate')

  useEffect(() => {
    if (agencyID != null) {
      void dispatch(
        getAllSegments({
          TenantID: tenantID,
          AgencyID: agencyIDLoggedIn ?? agencyID
        })
      )
    }
  }, [agencyID, dispatch, tenantID, agencyIDLoggedIn])

  return (
    <>
      <Form
        autoComplete='off'
        method='post'
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        className='form-wrapper'
      >
        <Box className='mb-1'>
          <FormControl fullWidth>
            <Box className='flex-row space-between'>
              <Box>
                <FormFieldBox
                  title={t`StartDate`}
                  rules={{
                    required: formValidationConstants.startDateError,
                    validate: (value: Date) =>
                      dayjs.utc(value).unix() >= dayjs.utc().unix() ||
                      t`StartDateValidationError`
                  }}
                  name='startDate'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof OfferFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={startDate}
                      isFromEdit={false}
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={false}
                    />
                  )}
                  control={control}
                  errorField={errors.startDate}
                  isAdjacentDatePicker
                />
              </Box>
              <Box>
                <FormFieldBox
                  title={t`EndDate`}
                  rules={{
                    required: formValidationConstants.endDateError,
                    validate: (value: Date) =>
                      dayjs.utc(value).unix() >= dayjs.utc(sdate).unix() ||
                      t`EndDateValidationError`
                  }}
                  name='endDate'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof OfferFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={endDate}
                      isFromEdit={false}
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={false}
                    />
                  )}
                  control={control}
                  errorField={errors.endDate}
                  isAdjacentDatePicker
                />
              </Box>
            </Box>
          </FormControl>
        </Box>
        <FormFieldBox
          title={t`SegmentsAvailableTo`}
          rules={{
            required: formValidationConstants.requiredField,
            validate: () => {
              return segmentsIncluded != null && segmentsIncluded?.length > 0
            }
          }}
          name='segmentsIncluded'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel shrink={false} id='segments-available-to-label'>
                {(segmentsIncluded == null || segmentsIncluded?.length < 1) &&
                  t`SearchForSegment`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof OfferFormInterface
                  )
                  onChange(e.target?.value, name)
                }}
                value={segmentsIncluded}
                selectItems={SegmentList(
                  segmentOptions,
                  segmentsIncluded,
                  segmentsExcluded,
                  false
                )}
                name={name}
                inputProps='custom-form-select'
                isFormSelect
                multiple
                renderValue={() => (
                  <SelectionChip
                    selectedList={segmentsIncluded}
                    selectionOptions={segmentOptions}
                    onFormChange={onFormChange}
                    name={name}
                    isEditable={false}
                    customClassName='offer-segment-chip'
                  />
                )}
              />
            </>
          )}
          control={control}
          errorField={errors.segmentsIncluded}
        />
        <FormFieldBox
          title={t`SegmentsExcludedFrom`}
          rules={{}}
          name='segmentsExcluded'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel shrink={false} id='segments-excluded-from-label'>
                {(segmentsExcluded == null || segmentsExcluded?.length < 1) &&
                  t`SearchForSegment`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  onFormChange(
                    e.target?.value,
                    name as keyof OfferFormInterface
                  )
                  onChange(e.target?.value, name)
                }}
                value={segmentsExcluded}
                selectItems={SegmentList(
                  segmentOptions,
                  segmentsExcluded,
                  segmentsIncluded,
                  true
                )}
                name={name}
                inputProps='custom-form-select'
                isFormSelect
                multiple
                renderValue={() => (
                  <SelectionChip
                    selectedList={segmentsExcluded}
                    selectionOptions={segmentOptions}
                    onFormChange={onFormChange}
                    name={name}
                    isEditable={false}
                    customClassName='offer-segment-chip'
                  />
                )}
              />
            </>
          )}
          control={control}
          errorField={errors.segmentsExcluded}
        />

        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='offer-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='offerAudeinceStepNext'
              id='offer-create-next'
              type='submit'
              variant='contained'
              disabled={!isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='offer-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </>
  )
}

export default OfferCreateAudienceStep
