import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { CircularProgress } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import {
  getOfferDetailsByID,
  addOfferToTargetGroup,
  removeOfferFromTargetGroup,
  excludeOfferFromTargetGroup
} from '../slices'
import SegmentsList from '../../segments/SegmentsList'
import EmptySegments from '../../segments/EmptySegments'
import { getAllSegments } from '../../segments/slices'
import SegmentsListHeader from '../../segments/SegmentsListHeader'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { TargetGroupsInterface } from '../../segments/interfaces/segments'
import { mapSegmentsToIDs } from '../../commons/utils/utils'

const OfferSegments = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  const {
    SegmentsIncluded,
    SegmentsExcluded,
    AgencyID,
    loadingSegments,
    TenantID
  } = useAppSelector((state) => ({
    SegmentsIncluded: state.offers.offerSelected.SegmentsIncluded,
    SegmentsExcluded: state.offers.offerSelected.SegmentsExcluded,
    AgencyID: state.offers.offerSelected.AgencyID,
    loadingSegments: state.offers.loadingSegments,
    TenantID: state.auth.tenantID
  }))

  const segmentsIncludedIDs = mapSegmentsToIDs(SegmentsIncluded ?? [])
  const segmentsExcludedIDs = mapSegmentsToIDs(SegmentsExcluded ?? [])

  const [openRemoveIncludedDialog, setOpenRemoveIncludedDialog] =
    React.useState<boolean>(false)
  const [openRemoveExcludedDialog, setOpenRemoveExcludedDialog] =
    React.useState<boolean>(false)
  const [segmentID, setSegmentID] = React.useState<string>('')
  const [segmentName, setSegmentName] = React.useState<string>('')

  const handleClose = (): void => {
    setOpenRemoveIncludedDialog(false)
    setOpenRemoveExcludedDialog(false)
  }

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      void dispatch(getAllSegments({ TenantID, AgencyID }))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t, TenantID, AgencyID])

  const removeSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        removeOfferFromTargetGroup({
          OfferID: id,
          TargetGroupID: targetGroupID.toString()
        })
      )
      handleClose()
    }
  }

  const addIncludedSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        addOfferToTargetGroup({
          OfferID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }
  const addExcludedSegment = (targetGroupID: string): void => {
    if (id != null) {
      void dispatch(
        excludeOfferFromTargetGroup({
          OfferID: id,
          TargetGroupID: targetGroupID
        })
      )
      handleClose()
    }
  }

  React.useEffect(() => {
    if (id != null && !loadingSegments) {
      void dispatch(getOfferDetailsByID(id))
    }
  }, [loadingSegments, id, dispatch])

  const renderRemoveExcludedDialog = (): React.ReactElement => {
    return (
      <CustomDialog
        title={t`RemoveSegment`}
        open={openRemoveExcludedDialog}
        cancelCallback={handleClose}
        okCallback={() => removeSegment(segmentID)}
        mainMessage={t('OfferRemoveExcludedMessage', { segmentName })}
        okText={t`YesRemoveIt`}
        cancelText={t`Cancel`}
      />
    )
  }
  const renderRemoveIncludedDialog = (): React.ReactElement => {
    return (
      <CustomDialog
        title={t`RemoveSegment`}
        open={openRemoveIncludedDialog}
        cancelCallback={handleClose}
        okCallback={() => removeSegment(segmentID)}
        mainMessage={t('OffersRemoveIncludedMessage', { segmentName })}
        okText={t`YesRemoveIt`}
        cancelText={t`Cancel`}
      />
    )
  }

  const renderSegmentList = (
    excluded: boolean,
    targetGroup?: TargetGroupsInterface[]
  ): React.ReactElement => {
    return targetGroup != null && targetGroup.length > 0
      ? (
        <SegmentsList
          removeSegmentAction={(id, name) => {
            setSegmentID(id ?? '')
            setSegmentName(name ?? '')
            excluded
              ? setOpenRemoveExcludedDialog(true)
              : setOpenRemoveIncludedDialog(true)
          }}
          segmentsList={targetGroup}
          showRedemption={!excluded}
        />
        )
      : (
        <EmptySegments excluded isOfferSegment />
        )
  }

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`OfferSegments`}</h1>
        <h3 className='title'>{t`IncludedOfferSegmentsTitle`}</h3>
        <SegmentsListHeader
          onAddClick={addIncludedSegment}
          segmentListIDs={segmentsIncludedIDs}
        />
        {renderSegmentList(false, SegmentsIncluded)}
        <h3 className='title mt-1'>{t`ExcludedOfferSegmentsTitle`}</h3>
        <SegmentsListHeader
          onAddClick={addExcludedSegment}
          segmentListIDs={segmentsExcludedIDs}
          excludeAll
        />
        {renderSegmentList(true, SegmentsExcluded)}
        {renderRemoveExcludedDialog()}
        {renderRemoveIncludedDialog()}
      </Box>
      )
    : (
      <CircularProgress />
      )
}
export default OfferSegments
