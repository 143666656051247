import { FC, useCallback } from 'react'
import {
  PER_PAGE_USERS_TABLE,
  FIRST_PAGE_USERS_TABLE
} from '../../users/constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import {
  setClassNameForTable,
  convertStringToDateAndTime
} from '../../commons/utils/utils'
import { UsersListParams } from '../../users/interfaces/users'
import { useAppSelector } from '../../core/redux/hooks'
import { CodesForSegmentsListColumns } from '../constants'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import _ from 'lodash'

interface SegmentCodesListProps {
  onPageChange: (changedData: UsersListParams) => void
  page?: number
  perPage?: number
  removeCodeAction: (code: string) => void
}

const SegmentCodesList: FC<SegmentCodesListProps> = ({
  page,
  perPage,
  onPageChange,
  removeCodeAction
}) => {
  const { codes, totalCodes } = useAppSelector((state) => state.segments)
  const { t } = useTranslation()

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )
  const COLUMNS = Object.values(CodesForSegmentsListColumns)

  return (
    <TableContainer className='mt-1'>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {codes?.length > 0 && (
          <TableBody>
            {codes?.map((row) => {
              const createdDate = convertStringToDateAndTime(row.CreatedAtUTC)
              const availableAmount = _.subtract(row.RedemptionLimit, row.claims)
              return (
                <TableRow className='row-with-actions' key={row.PromotionCode}>
                  <TableCell className='body-cell'>
                    {row.CreatedAtUTC != null &&
                      `${createdDate?.date ?? ''} |
                  ${createdDate?.time ?? ''}`}
                  </TableCell>
                  <TableCell className='body-cell'>
                    {row.PromotionCode}
                  </TableCell>
                  <TableCell className='body-cell'>
                    {row.RedemptionLimit === 1 ? t`Unique` : t`Repeatable`}
                  </TableCell>
                  <TableCell className='body-cell'>
                    {availableAmount < 0 ? 0 : availableAmount}
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Box className='list-actions flex-center'>
                      <Tooltip title={t`RemoveCode`}>
                        <IconButton
                          id='remove-code'
                          aria-label='Remove Code'
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() =>
                            removeCodeAction(row.PromotionCode)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_USERS_TABLE}
        perPage={perPage ?? PER_PAGE_USERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={totalCodes}
        length={codes?.length}
      />
    </TableContainer>
  )
}

export default SegmentCodesList
