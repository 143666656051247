import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import SelfServeButton from '../../ui/button/Button'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../core/routes/routePaths'
import { Link } from 'react-router-dom'

const NoLiveCampaign = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Box className='mt-2 ml-0 no-live-offer-box'>
      <Typography noWrap className='subtitle3'>{t`NoLiveCampaign`}</Typography>
      <SelfServeButton id='go-to-campaigns-button' variant='outlined'>
        <Link to={RoutePath.CampaignCategories} className='neutral-link-secondary'>
          {t`GoToCategories`}
        </Link>
      </SelfServeButton>
    </Box>
  )
}

export default NoLiveCampaign
