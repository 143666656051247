import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'
import '../../campaigns/campaign-phone-previews/DetailsCampaignPhonePreview.scss'
import { useAppSelector } from '../../core/redux/hooks'
import _ from 'lodash'
import { getOfferPreviewObject } from '../utils'
import { OfferPhonePreviewProps } from '../interfaces/offers'
import OfferCardPhonePreview from './OfferCardPhonePreview'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import './OffersPhonePreview.scss'
import { checkIsNumPositive } from '../../commons/utils/utils'
import { FREE } from '../constants'
import SelfServeButton from '../../ui/button/Button'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const emptyDetailsDrawer = (
  <Box className='ml-1'>
    <Box className='empty-image' />
    <Box className='empty-title' />
    <Box className='empty-limit' />
    <Box className='empty-details' />
    <Box className='empty-details' />
    <Box className='empty-details-short' />
  </Box>
)

const OfferDetailsPhonePreview = ({
  isFromCreateEdit
}: OfferPhonePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const { offerCreateForm, offerSelected, offerCategories } = useAppSelector(
    (state) => ({
      offerCreateForm: state.offers.offerCreateForm,
      offerSelected: state.offers.offerSelected,
      offerCategories: state.offerCategories.offerCategories
    })
  )

  const offer = getOfferPreviewObject(
    offerCreateForm,
    offerSelected,
    offerCategories,
    isFromCreateEdit
  )

  const { redeemTimes, redeemDays, veloAmount, title, url, description } = offer
  const points = checkIsNumPositive(veloAmount) ? veloAmount : FREE

  return (
    <Box className='black-screen'>
      <OfferCardPhonePreview isFromCreateEdit={isFromCreateEdit} />
      <Box className='preview-drawer'>
        <Typography className='close' variant='subtitle1'>
          x
        </Typography>
        {!_.isEmpty(title)
          ? (
            <Box>
              {!_.isEmpty(url) && (
                <img src={url ?? ''} alt='offer' className='drawer-img' />
              )}
              <Typography className='name'>{title}</Typography>
              {checkIsNumPositive(redeemTimes) && checkIsNumPositive(redeemDays) && (
                <Box className='timer-box'>
                  <TimerOutlinedIcon fontSize='small' className='timer-icon' />
                  <Typography className='redeem-times'>
                    {t('PreviewRedeemLimitText', { redeemTimes, redeemDays })}
                  </Typography>
                </Box>
              )}
              <ReactMarkdown className='offer-description'>
                {description ?? ''}
              </ReactMarkdown>
              <SelfServeButton
                id='preview-button'
                variant='contained'
                className='rewards-button'
                size='small'
              >
                {t('RedeemButtonText', { points })}
                {points !== FREE && t`OfferPoints`}
              </SelfServeButton>
            </Box>
            )
          : (
              emptyDetailsDrawer
            )}
      </Box>
    </Box>
  )
}

export default OfferDetailsPhonePreview
