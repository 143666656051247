import {
  TextField,
  FormControl,
  FormHelperText,
  Divider,
  Box,
  InputLabel,
  MenuItem
} from '@mui/material'
import SelfServeButton from '../../ui/button/Button'
import { Form } from 'react-router-dom'
import { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import '../campaigns.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import { useAppSelector } from '../../core/redux/hooks'
import {
  CampaignFormInterface,
  CampaignFormProps
} from '../interfaces/campaigns'
import * as formValidationRule from '../campaign-rules/CampaignFormValidationRules'
import * as validationConstants from '../constants/ValidationConstants'
import { handleNextStep } from '../../commons/utils/utils'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import { SelectOption, getEarnUnit } from '../constants/CommonConstants'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

const CampaignRewards = ({
  handleChange,
  handleNext,
  handleBack,
  onDiscard,
  isFromEdit = false,
  isEditable = true
}: CampaignFormProps): ReactElement | null => {
  const {
    campaignForm: { pointEarned, bucketRepetition, earningCount, earnUnit },
    isFormTouched
  } = useAppSelector((state) => state.campaigns)
  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      pointEarned,
      bucketRepetition,
      earningCount,
      earnUnit
    }
  })
  const { t } = useTranslation()

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <FormFieldBox
          title={t`PointToReward`}
          rules={
            isEditable && {
              required:
                formValidationRule.formValidationConstants.requiredField,
              pattern: formValidationRule.formValidationConstants.numberOnly,
              ...formValidationRule.numberRangeRule(
                validationConstants.pointRange.min,
                validationConstants.pointRange.max
              )
            }
          }
          name='pointEarned'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'pointsToReward' }}
              name={name}
              id='pointEarned'
              onChange={(e) => {
                handleChange(
                  e,
                  name as keyof CampaignFormInterface,
                  onChange,
                  true
                )
              }}
              value={pointEarned ?? ''}
              disabled={!isEditable}
            />
          )}
          control={control}
          errorField={errors.pointEarned}
        />
        <FormFieldBox
          title={t`MaxTimeEarn`}
          rules={
            isEditable && {
              required:
                formValidationRule.formValidationConstants.requiredField,
              pattern: formValidationRule.formValidationConstants.numberOnly
            }
          }
          name='bucketRepetition'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'maxTimesField' }}
              name={name}
              id='bucketRepetition'
              onChange={(e) => {
                handleChange(
                  e,
                  name as keyof CampaignFormInterface,
                  onChange,
                  true
                )
              }}
              value={bucketRepetition ?? ''}
              disabled={!isEditable}
            />
          )}
          control={control}
          errorField={errors.bucketRepetition}
        />
        <FormHelperText>{t`TimesEarnedByUserOnTimeFrame`}</FormHelperText>
        <Box className='mb-1' display='flex' justifyContent='space-between'>
          <FormControl fullWidth>
            <FormFieldBox
              title=''
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField,
                  pattern: formValidationRule.formValidationConstants.numberOnly
                }
              }
              name='earningCount'
              render={({ field: { name, onChange } }) => (
                <TextField
                  inputProps={{ 'data-testid': 'earningCountField' }}
                  name={name}
                  id='earningCount'
                  onChange={(e) => {
                    handleChange(
                      e,
                      name as keyof CampaignFormInterface,
                      onChange,
                      true
                    )
                  }}
                  value={earningCount ?? ''}
                  disabled={!isEditable}
                />
              )}
              control={control}
              errorField={errors.earningCount}
            />
          </FormControl>
          <Divider orientation='vertical' variant='inset' flexItem />
          <FormControl fullWidth>
            <FormFieldBox
              title=''
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField
                }
              }
              name='earnUnit'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel shrink={false} id='earnUnit-label'>
                    {earnUnit === '' && t`SelectMode`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    data-testid='earnUnitField'
                    handleFormChange={(e) => {
                      handleChange(
                        e,
                        name as keyof CampaignFormInterface,
                        onChange
                      )
                    }}
                    value={earnUnit ?? ''}
                    selectItems={getEarnUnit.map((item: SelectOption) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                    name={name}
                    disabled={!isEditable}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.earnUnit}
            />
          </FormControl>
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='campaign-create-preview'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='nextButtonCampaignRewardsDistribution'
              id='campaign-create-next'
              type='submit'
              variant='contained'
              disabled={isFromEdit ? false : !isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='campaign-create-discard'
            onClick={onDiscard}
            variant='contained'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </Box>
  )
}

export default CampaignRewards
