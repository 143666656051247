import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'

const EmptySegmentsUsers = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <Box display='flex' className='mt-1 mb-1'>
      <InboxOutlinedIcon />
      <Typography>
      &nbsp;{t`ThisUserNotIncludedSegment`}
      </Typography>
    </Box>
  )
}

export default EmptySegmentsUsers
