import { FC, useCallback, useEffect, useRef } from 'react'
import './segments.scss'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { SegmentsListParams } from './interfaces/segments'
import { getValueForForm } from '../commons/utils/utils'
import { Box, CircularProgress } from '@mui/material'
import SelfServeButton from '../ui/button/Button'
import MainSegmentsListHeader from './segments-main/MainSegmentsListHeader'
import MainSegmentsList from './segments-main/MainSegmentsList'
import {
  FIRST_PAGE_SEGMENTS_TABLE,
  PER_PAGE_SEGMENTS_TABLE,
  SegmentsTypes
} from './constants'
import { cleanSegmentsForCSV, getSegments, getSegmentsForCSV } from './slices'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { PAGE, constants } from '../commons/constants/constants'
import { RoutePath } from '../core/routes/routePaths'

const Segments: FC = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    segments,
    segmentsForCSV,
    segmentsForCSVLoading,
    agencyID,
    tenantID
  } = useAppSelector((state) => ({
    segments: state.segments.segments,
    segmentsForCSV: state.segments.segmentsForCSV,
    segmentsForCSVLoading: state.segments.segmentsForCSVLoading,
    agencyID: state.auth.agencyID,
    tenantID: state.auth.tenantID
  }))

  const { t } = useTranslation()
  const csvRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const handleFormChange = ({
    AgencyID,
    search,
    createdStart,
    createdEnd,
    type,
    perPage,
    page
  }: SegmentsListParams): void => {
    Object.entries({
      AgencyID,
      search,
      createdStart,
      createdEnd,
      type,
      perPage,
      page
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  const createPayloadFromSearchParams = useCallback(
    (isForCSV: boolean): SegmentsListParams => {
      const commonFields = {
        AgencyID: agencyID ?? searchParams.get('AgencyID') ?? '',
        TenantID: tenantID ?? '',
        search: searchParams.get('search') ?? '',
        createdStart: searchParams.get('createdStart') ?? '',
        createdEnd: searchParams.get('createdEnd') ?? '',
        type: searchParams.get('type') ?? ''
      }

      return {
        ...commonFields,
        page: !isForCSV
          ? parseInt(
            searchParams.get('page') ?? FIRST_PAGE_SEGMENTS_TABLE.toString()
          )
          : undefined,
        perPage: !isForCSV
          ? parseInt(
            searchParams.get('perPage') ?? PER_PAGE_SEGMENTS_TABLE.toString()
          )
          : undefined
      }
    },
    [agencyID, tenantID, searchParams]
  )

  useEffect(() => {
    if (!segmentsForCSVLoading && segmentsForCSV?.length > 0) {
      csvRef?.current?.link.click()
    }
  }, [segmentsForCSVLoading, segmentsForCSV])

  const csvClick = (): void => {
    void dispatch(getSegmentsForCSV(createPayloadFromSearchParams(true)))
  }

  useEffect(() => {
    void dispatch(getSegments(createPayloadFromSearchParams(false)))
  }, [dispatch, createPayloadFromSearchParams])

  useEffect(() => {
    return () => {
      dispatch(cleanSegmentsForCSV())
    }
  }, [dispatch])

  return segments != null
    ? (
      <Box className='column'>
        <Box className='space-between row'>
          <h1 className='title'>{t`Segments`}</h1>
          <Box>
            <SelfServeButton
              id='generate-segments-report'
              className='mr-1'
              variant='contained'
              color='secondary'
              onClick={csvClick}
            >
              <FileDownloadOutlinedIcon
                fontSize='small'
                className='button-icon'
              />{' '}
              {t`ExportList`}
            </SelfServeButton>
            <CSVLink
              data={segmentsForCSV.map((item) => {
                return {
                  SegmentID: item.TargetGroupID,
                  Name: item.Name,
                  Agency: item.AgencyName,
                  CreatedDate: item.CreatedAtUTC,
                  Type:
                  item.Manual === true
                    ? SegmentsTypes.MANUAL
                    : SegmentsTypes.REWARDS_CODE,
                  UsersInSegments: item.TargetGroupUserCount
                }
              })}
              ref={csvRef}
              filename={`segments-${Date.now()}.csv`}
            />
            <SelfServeButton
              color='primary'
              id='create-agency'
              variant='contained'
            >
              <Link to={RoutePath.SegmentCreate} className='neutral-link'>
                <AddOutlinedIcon className='button-icon' /> {t`NewSegmentButton`}
              </Link>
            </SelfServeButton>
          </Box>
        </Box>
        <MainSegmentsListHeader
          search={searchParams.get('search') ?? ''}
          type={searchParams.get('type') ?? ''}
          createdStart={searchParams.get('createdStart') ?? ''}
          createdEnd={searchParams.get('createdEnd') ?? ''}
          AgencyID={searchParams.get('AgencyID') ?? ''}
          handleFormChange={handleFormChange}
        />
        <MainSegmentsList
          onPageChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_SEGMENTS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_SEGMENTS_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}
export default Segments
