import { TextField } from '@mui/material'
import { FC } from 'react'
import TableCell from '@mui/material/TableCell'

interface CampaignPreviewBoxProps {
  colSpanNumber: number
  label: string
  value: string | number | string[] | Date | boolean | null | undefined
  isImage?: boolean
  imageType?: string
}

const CampaignPreviewBox: FC<CampaignPreviewBoxProps> = ({
  label,
  value,
  isImage = false,
  imageType,
  colSpanNumber
}) => {
  return (
    <TableCell colSpan={colSpanNumber} className='body-cell preview-table-cell'>
      {isImage && value != null && typeof value === 'string' && imageType != null
        ? (
          <div className='preview-img-wrapper'>
            <div
              className={`preview-${imageType}-title`}
            >
              {label}
            </div>
            <img
              className={`preview-${imageType}`}
              src={value}
              alt='dropzone-img'
            />
          </div>
          )
        : (
          <TextField
            label={label}
            variant='filled'
            value={value ?? ''}
            disabled
            multiline
            className='preview-contents-text'
            InputLabelProps={{
              shrink: true
            }}
          />
          )}
    </TableCell>
  )
}

export default CampaignPreviewBox
