import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
interface EmptySegmentProps {
  excluded: boolean
  isOfferSegment?: boolean
}
const EmptySegments = ({ excluded, isOfferSegment }: EmptySegmentProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Box display='flex' className='mt-1 mb-1'>
      <InboxOutlinedIcon />
      <Typography>
      &nbsp; {t`NoSegmentText`}{isOfferSegment === true
        ? t`offer`
        : t`campaign`} is {excluded ? t`excluded` : t`included`}.
      </Typography>
    </Box>
  )
}

export default EmptySegments
