import i18n from 'i18next'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import initTranslation from '../core/utils/i18n'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export const PER_PAGE_SEGMENTS_TABLE = 10
export const FIRST_PAGE_SEGMENTS_TABLE = 0

export enum IncludeSegmentsListColumns {
  'ID' = 'ID',
  'NAME' = 'Name',
  'ADDED_DATE' = 'Added Date',
  'USERS' = 'Users in Group',
  'REDEMPTIONS' = 'Redemptions',
  'MENU' = ''
}

export enum UsersForSegmentsListColumns {
  'ID' = 'User ID',
  'ADDED_DATE' = 'Added Date',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum CodesForSegmentsListColumns {
  'CREATED DATE' = 'Created Date',
  'CODE' = 'Code',
  'TYPE' = 'Type',
  'AVAILABLE CODES' = 'Available Codes',
  'MENU' = ''
}

export enum UsersForRewardsSegmentsListColumns {
  'ID' = 'User ID',
  'ADDED_DATE' = 'Added Date',
  'STATUS' = 'Status',
  'CODE_USED' = 'Code Used',
  'MENU' = ''
}

export enum TargetGroupsListColumns {
  'ID' = 'ID',
  'NAME' = 'Name',
  'ADDED_DATE' = 'Added Date',
  'USERS' = 'Users in Group',
  'MENU' = ''
}

export enum SegmentsMainListColumnsForTenants {
  'ID' = 'ID',
  'NAME' = 'Name',
  'AGENCY' = 'Agency',
  'CREATED_DATE' = 'Created Date',
  'TYPE' = 'Type',
  'USERS' = 'Users',
  'MENU' = ''
}

export enum SegmentsMainListColumnsForAgencies {
  'ID' = 'ID',
  'NAME' = 'Name',
  'CREATED_DATE' = 'Created Date',
  'TYPE' = 'Type',
  'USERS' = 'Users',
  'MENU' = ''
}

export enum OffersSegmentsListColumnsForTenants {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum OffersSegmentsListColumnsForAgencies {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum CampaignsSegmentsListColumnsForTenants {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'AGENCY' = 'Agency',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export enum CampaignsSegmentsListColumnsForAgencies {
  'ID' = 'ID',
  'TITLE' = 'Title',
  'CATEGORY' = 'Category',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export const segmentsConstants = {
  Domain: 'segments'
}

export enum SegmentsTypes {
  MANUAL = 'Manual',
  REWARDS_CODE = 'Rewards Code'
}

export const ADD_TO_ALL_OPTION_SEGMENTS = {
  id: 'Add All Users',
  label: 'All Segments',
  groupID: 'All'
}

export const EMPTY_OPTION_SEGMENTS = {
  id: '',
  label: 'Select Segment',
  groupID: ''
}

export const STEPS_FOR_SEGMENTS_STEPPER = [
  'Segment Type',
  'Segment Information'
]

export const STEPS_FOR_REWARDS_SEGMENTS_STEPPER = [
  'Segment Type',
  'Segment Information',
  'Codes Information'
]

export const STEPS_FOR_EDIT_REWARDS_SEGMENTS_STEPPER = [
  'Segment Information',
  'Codes Information'
]

export const formValidationConstants = {
  requiredField: { value: true, message: i18n.t('Required Field') },
  minLengthError: {
    value: 3,
    message: i18n.t('LengthError', { min: 3, max: 50 })
  },
  maxLengthError: {
    value: 50,
    message: i18n.t('LengthError', { min: 3, max: 50 })
  },
  descriptionLengthError: i18n.t('DescriptionLengthError', {
    min: 3,
    max: 1000
  }),
  maxLengthContentError: {
    value: 500,
    message: i18n.t('LengthError', { min: 3, max: 500 })
  },
  minNum: {
    value: 1,
    message: i18n.t('NumberMinMaxError', { min: 1, max: 10000 })
  },
  maxNum: {
    value: 10000,
    message: i18n.t('NumberMinMaxError', { min: 1, max: 10000 })
  },
  minCodesAmount: {
    value: 1,
    message: i18n.t('NumberMinMaxError', { min: 1, max: 50000 })
  },
  maxCodesAmount: {
    value: 50000,
    message: i18n.t('NumberMinMaxError', { min: 1, max: 50000 })
  }
}

export const USERS_FILE = 'usersFile'
export const USERS_FILE_ID = 'usersFileID'
export const CODES_FILE = 'codesFile'
export const CODES_FILE_ID = 'codesFileID'
export const USERS = 'userList'
export const USERS_CSV = 'usersCSV'
export const INVITE_CODES_FILE = 'inviteCodesFile'
export const INVITE_CODES = 'inviteCodes'
export const CODES_CSV = 'codesCSV'
export const MANUAL = 'Manual'
export const EXPIRY_DATE = 'expiryDate'

export enum GenerationMethodType {
  LIST_OF_CODES = 'Entered list of Codes',
  CSV_CODES = 'Upload list of Codes',
  AUTOMATIC_CODES = 'Automatically generated list of codes'
}

export enum EditMethod {
  NEW_USERS = 'NEW_USERS',
  REMOVAL = 'REMOVAL',
  REPLACE = 'REPLACE',
  NO_CHANGE = 'NO_CHANGE'
}

export const EditRewardsMethod = {
  NEW_CODES_MANUAL: {
    name: 'NEW_CODES_MANUAL',
    label: 'ManuallyAddMoreRewardsCode'
  },
  NEW_CODES_UPLOAD: {
    name: 'NEW_CODES_UPLOAD',
    label: 'UploadMoreRewardsCode'
  },
  REPLACE: {
    name: 'REPLACE',
    label: 'ReplaceListOfAvailableCodes'
  },
  REMOVAL: {
    name: 'REMOVAL',
    label: 'DeleteAllAvailableCodes'
  },
  NUMBER_OF_TIMES: {
    name: 'NUMBER_OF_TIMES',
    label: 'IncreaseTheNumberOfTimes'
  },
  NO_CHANGE: {
    name: 'NO_CHANGE',
    label: 'NoChange'
  }
}

export const EditAutomaticRewardsMethod = {
  NEW_CODES_AUTOMATIC: {
    name: 'NEW_CODES_AUTOMATIC',
    label: 'GenerateMoreRewardsCode'
  },
  REMOVAL: {
    name: 'REMOVAL',
    label: 'DeleteAllAvailableCodes'
  },
  NUMBER_OF_TIMES: {
    name: 'NUMBER_OF_TIMES',
    label: 'IncreaseTheNumberOfTimes'
  },
  NO_CHANGE: {
    name: 'NO_CHANGE',
    label: 'NoChange'
  }
}

export const SELECT_LENGTH = 'Select Length'
export const NUM_OF_CODES_MIN_VALUE = 1
export const NUM_OF_CODES_MAX_VALUE = 50000
