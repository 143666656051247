import * as Sentry from '@sentry/react'
import { ErrorLevels } from './constants'
const EXTERNAL_ERROR_MSG = 'An external error occurred: '

export function reportError (error: any, level: string): void {
  let context = {}
  import('../../core/redux/store')
    .then((module) => {
      const store = module.default
      if (store != null) {
        const state = store.getState()
        if (state != null) {
          const auth = state.auth
          context = {
            tags: {
              email: auth.email,
              level
            },
            contexts: {
              formContext: {
                UserIDInfo: {
                  AgencyID: auth.agencyID,
                  TenantID: auth.tenantID
                }
              }
            }
          }
        }
      }
      if (error instanceof Error) {
        Sentry.captureException(error, context)
      } else if (typeof error === 'string') {
        Sentry.captureMessage(error, context)
      } else {
        Sentry.captureMessage(
          `${EXTERNAL_ERROR_MSG}${
            error.message != null ? String(error.message) : ''
          }`,
          context
        )
      }
    })
    .catch((err) => {
      Sentry.captureException(err, {
        tags: {
          level: ErrorLevels.Fatal
        }
      })
    })
}

export function handleRejectWithReport (
  rejectWithValue: Function,
  error: any
): any {
  reportError(error, ErrorLevels.Error)
  return rejectWithValue(error.message)
}
