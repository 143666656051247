import { agencyBarRoutePath } from '../../agencies/utils'
import { categoryBarRoutePath } from '../../campaign-categories/utils'
import { campaignBarRoutePath } from '../../campaigns/utils'
import { getRoleTypeByString } from '../../commons/utils/utils'
import { RouteItem } from '../../core/routes/routePaths'
import { offerCategoryBarRoutePath } from '../../offer-categories/utils'
import SidebarItem from './SidebarItem'
import { offerBarRoutePath } from '../../offers/utils'
import { userBarRoutePath } from '../../users/utils'
import { segmentBarRoutePath } from '../../segments/utils'
import { AGENCIES } from '../../agencies/constants'
import authConstants from '../../auth/constants'

export const renderSidebarItems = (
  routes: Record<string, RouteItem>,
  userType: string,
  isAgencyEnabled?: boolean,
  isGeneralSidebar?: boolean
): React.ReactNode => {
  return Object.keys(routes).map((key) => {
    const value = routes[key]
    const role = getRoleTypeByString(userType)
    if (
      isGeneralSidebar === true &&
      value.title === AGENCIES &&
      userType === authConstants.UserType.TENANT &&
      isAgencyEnabled === false
    ) {
      return null
    } else {
      return value.roles.includes(role)
        ? (
          <SidebarItem key={value.title} sidebarItem={value} />
          )
        : null
    }
  })
}

export const checkSelectedEntity = (
  entityType: string,
  selectedEntityType: string,
  entityID?: string | number
): boolean => {
  return entityID != null && entityType === selectedEntityType
}

export const getAgencyRoutes = (
  campaignsCount?: number,
  offersCount?: number,
  agencyID?: string
): Record<string, RouteItem> => {
  return agencyID != null
    ? agencyBarRoutePath(agencyID, campaignsCount, offersCount)
    : {}
}

export const getCategoryRoutes = (
  campaignCategoryID?: string,
  totalCampaigns?: number
): Record<string, RouteItem> => {
  return campaignCategoryID != null
    ? categoryBarRoutePath(campaignCategoryID, totalCampaigns)
    : {}
}

export const getOfferCategoryRoutes = (
  offerCategoryID?: string,
  totalOffers?: number
): Record<string, RouteItem> => {
  return offerCategoryID != null
    ? offerCategoryBarRoutePath(offerCategoryID, totalOffers)
    : {}
}

export const getCampaignRoutes = (
  campaignID?: string
): Record<string, RouteItem> => {
  return campaignID != null ? campaignBarRoutePath(campaignID) : {}
}

export const getOfferRoutes = (
  offerID?: string,
  offerType?: string,
  hasRepeatableCoupons?: boolean
): Record<string, RouteItem> => {
  return offerID != null
    ? offerBarRoutePath(offerID, offerType, hasRepeatableCoupons)
    : {}
}

export const getUserRoutes = (userID?: string): Record<string, RouteItem> => {
  return userID != null ? userBarRoutePath(userID) : {}
}

export const getSegmentRoutes = (
  targetGroupID?: string,
  totalUsers?: number,
  totalCodes?: number,
  totalCampaignsForSegments?: number,
  totalOffersForSegments?: number,
  manual?: boolean
): Record<string, RouteItem> => {
  return targetGroupID != null
    ? segmentBarRoutePath(
      targetGroupID,
      totalUsers,
      totalCodes,
      totalCampaignsForSegments,
      totalOffersForSegments,
      manual
    )
    : {}
}
