import { ReactElement, useEffect } from 'react'
import ErrorsPage from '../self-serve-errors/ErrorsPage'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from '../core/routes/routePaths'
import { useAppSelector } from '../core/redux/hooks'
import authConstants from '../auth/constants'

const ACCESS_FORBIDDEN_CODE = 403

const NotAllowed = (): ReactElement => {
  const navigate = useNavigate()
  const { authStatus } = useAppSelector((state) => state.auth)

  useEffect(() => {
    const path =
      authStatus === authConstants.AuthStatus.LOGGED_OUT
        ? RoutePath.Login
        : RoutePath.AccessForbidden
    navigate(path)
  }, [navigate, authStatus])

  return (
    <ErrorsPage
      errorCode={ACCESS_FORBIDDEN_CODE}
      errorTitle={t`AccessForbidden`}
      errorContent={t`AccessForbiddenContent`}
    />
  )
}

export default NotAllowed
