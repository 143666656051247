import { memo } from 'react'
import { TextField, Box, ListItem } from '@mui/material'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  OnDragEndResponder
} from 'react-beautiful-dnd'
import StrictModeDroppable from './DroppableForStrictMode'
import { DeleteOutline } from '@mui/icons-material'
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp'
import { BadgeProp } from '../../campaigns/interfaces/campaigns'
import { IconComponent } from '../../commons/utils/utils'
import * as MUIcon from '@mui/icons-material'

interface DraggableListProps {
  items: BadgeProp[]
  onDragEnd: OnDragEndResponder
  handleRemoveBadge: (index: number) => void
}
const DraggableList = memo(
  ({ items, onDragEnd, handleRemoveBadge }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId='droppable-list'>
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='badgeWrapper'
            >
              {items.map((singleBadge: BadgeProp, index: number) => (
                <Draggable
                  draggableId={`dragID-${index}`}
                  index={index}
                  key={`dragKey-${index}`}
                >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={snapshot.isDragging ? 'draggingListItem' : ''}
                      key={`ListKey${index}`}
                    >
                      <Box className='badgeFieldAdded' key={`BoxIndex-${index}`}>
                        <div key={index}>
                          <DragIndicatorSharpIcon className='singleBadge-dragIcon' />
                          <TextField
                            id='icon'
                            disabled
                            key={`IconField-${index}`}
                            InputProps={{
                              startAdornment: (
                                IconComponent({ icon: singleBadge.icon as keyof typeof MUIcon })
                              )
                            }}
                          />
                          <TextField
                            id='label'
                            value={singleBadge.label}
                            placeholder='Label'
                            disabled
                            key={`LabelField-${index}`}
                          />
                        </div>
                      </Box>
                      <Box>
                        <button
                          type='button'
                          className='removeNewBadge'
                          onClick={() => handleRemoveBadge(index)}
                          key={`removeButton-${index}`}
                        >
                          <DeleteOutline className='removeBadgeIcon' />
                        </button>
                      </Box>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    )
  }
)

export default DraggableList
