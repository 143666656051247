import { FC, ReactElement, useEffect, useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CampaignListParams } from '../../campaigns/interfaces/campaigns'
import { Box, Checkbox, ListItemText } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import {
  allWithoutText,
  onCategorySelectChange,
  selectMenuItem
} from '../../commons/utils/utils'
import { chip } from '../../campaigns/utils'
import { getCategories } from '../../campaign-categories/slices'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

interface CampaignsListHeaderProps {
  handleFormChange: (changedData: CampaignListParams) => void
  search?: string
  categories: string[]
  enableCategoryDropdown: boolean
}

const AgencyCampaignsListHeader: FC<CampaignsListHeaderProps> = ({
  handleFormChange,
  search,
  categories,
  enableCategoryDropdown
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { loading, campaignCategories, AgencyID, tenantID } = useAppSelector(
    (state) => ({
      loading: state.campaignCategories.loading,
      campaignCategories: state.campaignCategories.categories,
      AgencyID: state.agencies.agencySelected.AgencyID,
      tenantID: state.auth.tenantID
    })
  )

  useEffect(() => {
    void dispatch(getCategories({ tenantID, agencyID: AgencyID }))
  }, [tenantID, AgencyID, dispatch])

  const categoriesSelectItems = useMemo(
    () =>
      campaignCategories.map((category) => (
        <MenuItem
          className='multi-select-item'
          disableRipple
          disableTouchRipple
          key={category.CampaignCategoryID}
          value={category.CampaignCategoryID?.toString()}
        >
          <Checkbox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={categories.includes(
              category.CampaignCategoryID?.toString() ?? ''
            )}
          />
          <ListItemText primary={category.CategoryName} />
        </MenuItem>
      )),
    [campaignCategories, categories]
  )

  return !loading
    ? (
      <div>
        <Form role='search'>
          <FormControl className='form-control'>
            <SelfServeSearchField value={search} onChange={handleFormChange} />
          </FormControl>
          {enableCategoryDropdown && (
            <FormControl size='small' className='form-control'>
              {categories?.length === 0 && (
                <InputLabel shrink={false} id='categories-label'>
                  {t`CategoriesSelect`}
                  {categories?.length === 0 ? allWithoutText : t`Categories`}
                </InputLabel>
              )}
              <SelfServeCustomSelect
                handleFormChange={(e) =>
                  onCategorySelectChange(e, handleFormChange)}
                value={categories}
                menuItem={selectMenuItem(t`All`)}
                selectItems={categoriesSelectItems}
                inputProps='table-input minw-7'
                multiple
                native={false}
                displayEmpty
                renderValue={() => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {categories.map((value) => chip(value, campaignCategories))}
                  </Box>
                )}
              />
            </FormControl>
          )}
        </Form>
      </div>
      )
    : (
      <></>
      )
}

export default AgencyCampaignsListHeader
