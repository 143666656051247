import styled from '@emotion/styled'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { ReactElement } from 'react'

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))((props) => {
  const bgColor = props.color === 'secondary'
    ? '#F0AB26'
    : props.color === 'error'
      ? '#FF0000'
      : '#EBEBEB'

  return {
    marginLeft: '.5em',
    width: props.size === 'medium' ? 51 : 38,
    height: props.size === 'medium' ? 31 : 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: props.size === 'medium' ? 'translateX(22px)' : 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: bgColor,
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#fff',
        opacity: 0.5
      }
    },
    '& .MuiSwitch-thumb': {
      marginTop: '.08em',
      boxSizing: 'border-box',
      width: props.size === 'medium' ? 24 : 18,
      height: props.size === 'medium' ? 24 : 18
    },
    '& .MuiSwitch-track': {
      borderRadius: (props.size === 'medium' ? 33 : 26) / 2,
      backgroundColor: bgColor,
      opacity: 1
    }
  }
})

const CustomSwitch = ({
  sx,
  defaultChecked,
  checked,
  onClick,
  color,
  size,
  disabled
}: SwitchProps): ReactElement => {
  return (
    <StyledSwitch
      disabled={disabled}
      size={size}
      color={color}
      onClick={onClick}
      sx={sx}
      defaultChecked={defaultChecked}
      checked={checked}
    />
  )
}

export default CustomSwitch
