import { ReactElement, FC, useEffect, useMemo } from 'react'
import { OffersListParams } from '../../offers/interfaces/offers'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import { getAllAgencies } from '../../agencies/slices'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material'
import { Form } from 'react-router-dom'
import { FilterOfferStatus, FilterOfferTypes } from '../../offers/constants'
import { getOfferCategories } from '../../offer-categories/slices'
import {
  AgenciesSelectItems,
  StaticSelectItems,
  allText,
  onCategorySelectChange,
  selectMenuItem
} from '../../commons/utils/utils'
import { chip } from '../../offer-categories/utils'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'
interface OffersListMainHeaderProps {
  handleFormChange: (changedData: OffersListParams) => void
  search?: string
  agency?: string
  status?: string
  offerType?: string
  categories?: string[]
}

const OffersListMainHeader: FC<OffersListMainHeaderProps> = ({
  handleFormChange,
  search,
  agency,
  status,
  offerType,
  categories
}): ReactElement => {
  const { userType, tenantID, isAgencyEnabled, agencies, offerCategories } =
    useAppSelector((state) => ({
      userType: state.auth.userType,
      tenantID: state.auth.tenantID,
      isAgencyEnabled: state.auth.isAgencyEnabled,
      agencies: state.agencies.agencies,
      offerCategories: state.offerCategories.offerCategories
    }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userType !== RoleType.AGENCY && isAgencyEnabled) {
      void dispatch(getAllAgencies({ tenantID }))
    }
    void dispatch(getOfferCategories({ tenantID, agencyID: agency, userType }))
  }, [tenantID, dispatch, userType, agency, isAgencyEnabled])

  const categoriesSelectItems = useMemo(
    () =>
      offerCategories.map((category) => (
        <MenuItem
          className='multi-select-item'
          disableRipple
          disableTouchRipple
          key={category.OfferCategoryID}
          value={category.OfferCategoryID?.toString()}
        >
          <Checkbox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={categories?.includes(
              category.OfferCategoryID?.toString() ?? ''
            )}
          />
          <ListItemText primary={category.CategoryName} />
        </MenuItem>
      )),
    [offerCategories, categories]
  )

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='offer-type-label'>
            {(offerType == null || offerType === '') &&
              allText(t`OfferTypeSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ offerType: e.target.value as string })}
            value={offerType}
            menuItem={selectMenuItem(t`All`)}
            selectItems={StaticSelectItems(FilterOfferTypes)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormControl className='form-control' size='small'>
            <InputLabel shrink={false} id='agencies-label'>
              {(agency == null || agency === '') && allText(t`AgencyForSelect`)}
            </InputLabel>
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                handleFormChange({ agencyID: e.target.value as string })}
              value={agency}
              menuItem={selectMenuItem(t`AllAgencies`)}
              selectItems={AgenciesSelectItems(agencies)}
              inputProps='table-input maxw-8 minw-8'
            />
          </FormControl>
        )}
        <FormControl size='small' className='form-control'>
          {categories?.length === 0 && (
            <InputLabel shrink={false} id='offer-categories-label'>
              {(categories == null || categories?.length === 0) &&
                allText(t`CategoryForSelect`)}
            </InputLabel>
          )}
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              onCategorySelectChange(e, handleFormChange)}
            value={categories}
            menuItem={selectMenuItem(t`AllCategories`)}
            selectItems={categoriesSelectItems}
            inputProps='table-input minw-7'
            native={false}
            displayEmpty
            renderValue={() => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {categories?.map((value) => chip(value, offerCategories))}
              </Box>
            )}
            multiple
          />
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='status-label'>
            {(status == null || status === '') && allText(t`StatusForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ status: e.target.value as string })}
            value={status}
            inputProps='table-input maxw-8 minw-8'
            menuItem={selectMenuItem(t`AllStatus`)}
            selectItems={StaticSelectItems(FilterOfferStatus)}
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default OffersListMainHeader
