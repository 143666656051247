import { ReactElement, FC, useEffect, useMemo } from 'react'
import { Form } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { getAllAgencies } from '../../agencies/slices'
import { RoleType } from '../../core/routes/constants'
import { getOfferCategories } from '../../offer-categories/slices'
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem
} from '@mui/material'
import {
  AgenciesSelectItems,
  StaticSelectItems,
  allText,
  onCategorySelectChange,
  selectMenuItem
} from '../../commons/utils/utils'
import { OffersListParams } from '../../offers/interfaces/offers'
import { FilterOfferTypes } from '../../offers/constants'
import { chip } from '../../offer-categories/utils'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

interface WalletTransactionsListHeaderProps {
  handleFormChange: (changedData: OffersListParams) => void
  search?: string
  agencyID?: string
  offerType?: string
  categories: string[]
}

const WalletTransactionsListHeader: FC<WalletTransactionsListHeaderProps> = ({
  handleFormChange,
  search,
  offerType,
  agencyID,
  categories
}): ReactElement => {
  const { t } = useTranslation()

  const {
    agencies: { agencies },
    auth: { userType, tenantID, isAgencyEnabled },
    offerCategories: { offerCategories }
  } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userType !== RoleType.AGENCY && isAgencyEnabled) {
      void dispatch(getAllAgencies({ tenantID }))
    }
    void dispatch(getOfferCategories({ tenantID, agencyID, userType }))
  }, [tenantID, dispatch, userType, agencyID, isAgencyEnabled])

  const categoriesSelectItems = useMemo(
    () =>
      offerCategories.map((category) => (
        <MenuItem
          className='multi-select-item'
          disableRipple
          disableTouchRipple
          key={category.OfferCategoryID}
          value={category.OfferCategoryID?.toString()}
        >
          <Checkbox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={categories.includes(
              category.OfferCategoryID?.toString() ?? ''
            )}
          />
          <ListItemText primary={category.CategoryName} />
        </MenuItem>
      )),
    [offerCategories, categories]
  )

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='offer-type-label'>
            {(offerType == null || offerType === '') &&
              allText(t`TypeForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ offerType: e.target.value as string })}
            value={offerType}
            menuItem={selectMenuItem(t`All`)}
            selectItems={StaticSelectItems(FilterOfferTypes)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormControl className='form-control' size='small'>
            <InputLabel shrink={false} id='agencies-label'>
              {(agencyID == null || agencyID === '') &&
                allText(t`AgencyForSelect`)}
            </InputLabel>
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                handleFormChange({ agencyID: e.target.value as string })}
              value={agencyID}
              menuItem={selectMenuItem(t`AllAgencies`)}
              selectItems={AgenciesSelectItems(agencies)}
              inputProps='table-input maxw-8 minw-8'
            />
          </FormControl>
        )}
        <FormControl size='small' className='form-control'>
          {categories?.length === 0 && (
            <InputLabel shrink={false} id='categories-label'>
              {(categories == null || categories?.length === 0) &&
                allText(t`CategoriesSelect`)}
            </InputLabel>
          )}
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              onCategorySelectChange(e, handleFormChange)}
            value={categories}
            menuItem={selectMenuItem(t`AllCategories`)}
            selectItems={categoriesSelectItems}
            inputProps='table-input minw-7'
            native={false}
            displayEmpty
            renderValue={() => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {categories.map((value) => chip(value, offerCategories))}
              </Box>
            )}
            multiple
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default WalletTransactionsListHeader
