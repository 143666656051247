import {
  Box,
  Divider,
  MenuItem,
  Pagination,
  PaginationItem,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material'
import { ReactElement, useMemo, useState } from 'react'
import './SelfServePagination.scss'
import {
  PER_PAGE_AGENCIES_TABLE,
  FIRST_PAGE_AGENCIES_TABLE
} from '../../agencies/constants'
import { PAGINATION_SELECT_OPTIONS } from '../constants'
import { t } from 'i18next'
import SelfServeCustomSelect from '../select/SelfServeCustomSelect'

interface SelfServePaginationProps {
  total: number
  perPage: number
  page: number
  onPageChange: (page: number) => void
  onPerPageChange: (perPage: number) => void
  length?: number
}

const SelfServePagination = ({
  total,
  perPage,
  onPageChange,
  onPerPageChange,
  page,
  length = 0
}: SelfServePaginationProps): ReactElement => {
  const [value, setValue] = useState<number>(perPage)

  const onSelect = (
    e: SelectChangeEvent | SelectChangeEvent<string[]>
  ): void => {
    const perPageSelect = parseInt(e.target.value as string)
    setValue(perPageSelect)
    onPerPageChange(perPageSelect)
  }

  const paginationSelect = useMemo(
    () =>
      PAGINATION_SELECT_OPTIONS.map((num, i) => (
        <MenuItem key={`${num}-${i}`} value={num}>
          {num}
        </MenuItem>
      )),
    []
  )

  const lowerLimit = page * perPage + 1
  let upperLimit
  length < perPage
    ? (upperLimit = lowerLimit + length - 1)
    : (upperLimit = lowerLimit + perPage - 1)

  return length > 0
    ? (
      <Box>
        <Divider />
        <Box className='pagination-box'>
          <Typography className='pagination-text'>
            {t('ShowingPaginationRecordsText', { lowerLimit, upperLimit, total })}
          </Typography>
          <Box className='pagination-right'>
            <Box className='per-page-select-box'>
              <SelfServeCustomSelect
                handleFormChange={(e) => onSelect(e)}
                value={value.toString()}
                inputProps='per-page-select'
                selectItems={paginationSelect}
              />
              <Typography className='per-page-select-text'>
                {t`PaginationEntriesPerPage`}
              </Typography>
            </Box>
            <Stack spacing={2} className='pagination-stack'>
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      last: () => <span>{t`Last`}</span>,
                      first: () => <span>{t`First`}</span>
                    }}
                    {...item}
                  />
                )}
                showFirstButton
                showLastButton
                count={
                perPage === 0
                  ? 1
                  : Math.ceil(total / (perPage ?? PER_PAGE_AGENCIES_TABLE))
              }
                shape='rounded'
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  page: number
                ): void => {
                  onPageChange(page - 1)
                }}
                page={(page ?? FIRST_PAGE_AGENCIES_TABLE) + 1}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      )
    : (
      <Typography className='mt-1'>{t`NoRecordsAvailable`}</Typography>
      )
}

export default SelfServePagination
