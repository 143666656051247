export const PER_PAGE_CAMPAIGN_CATEGORIES_TABLE = 10
export const FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE = 0

export enum CampaignCategoriesListColumnsForTenants {
  'CAMPAIGN_CATEGORY_ID' = 'ID',
  'NAME' = 'Name',
  'AGENCY' = 'Agency',
  'UI_POSITION' = 'UI Position',
  'ICON' = 'Icon',
  'LIVE_CAMPAIGNS' = 'Live Campaigns',
  'MENU' = ''
}

export enum CampaignCategoriesListColumnsForAgencies {
  'CAMPAIGN_CATEGORY_ID' = 'ID',
  'NAME' = 'Name',
  'UI_POSITION' = 'UI Position',
  'ICON' = 'Icon',
  'LIVE_CAMPAIGNS' = 'Live Campaigns',
  'MENU' = ''
}

export enum CampaignsByCampaignCategoryListColumns {
  'CAMPAIGN_ID' = 'ID',
  'NAME' = 'Name',
  'TITLE' = 'Title',
  'UI_POSITION' = 'UI Position',
  'STATUS' = 'Status',
  'MENU' = ''
}

export const campaignCategoriesConstants = {
  Domain: 'campaignCategories'
}
