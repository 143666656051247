import CircularProgress from '@mui/material/CircularProgress'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { uploadImage } from '../commons/components/uploader/utils'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import Box from '@mui/material/Box'
import './agencies.scss'
import { RoutePath } from '../core/routes/routePaths'
import AgenciesForm from './AgenciesForm'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import { createAgency, resetAgencyForm } from './slices'
import { errorDisplay, getErrorMessage, validationErrorsDisplay } from '../commons/utils/utils'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'

const AgenciesCreate: FC<{}> = () => {
  const { userID, tenantID } = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [icon, setIcon] = useState<File | undefined>()
  const [dashboardAccess, setDashboardAccess] = useState<boolean>(false)
  const [agencyName, setAgencyName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading, isFulfilled, errors } = useAppSelector(
    (state) => state.agencies
  )

  const [openConfirmCreateAccessGranted, setOpenConfirmCreateAccessGranted] =
    useState<boolean>(false)
  const [openConfirmCreateAccessDenied, setOpenConfirmCreateAccessDenied] =
    useState<boolean>(false)
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)

  const handleClose = (): void => {
    setOpenConfirmCreateAccessGranted(false)
    setOpenConfirmCreateAccessDenied(false)
    setOpenConfirmLeaving(false)
  }

  const createAgencySubmission = async (): Promise<void> => {
    try {
      const upload = await uploadImage(userID, icon)
      void dispatch(
        createAgency({
          Name: agencyName,
          Email: email,
          DashboardAccess: dashboardAccess,
          AgencyIconID: upload.imageID,
          City: city,
          State: state,
          TenantID: tenantID
        })
      )
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (isFulfilled) {
      dispatch(resetAgencyForm())
      navigate(RoutePath.Agencies)
    }
  }, [isFulfilled, navigate, dispatch])

  const renderConfirmCreateAccessGrantedDialog = (
    <CustomDialog
      title={t`CreateAgency`}
      open={openConfirmCreateAccessGranted}
      cancelCallback={handleClose}
      okCallback={createAgencySubmission}
      mainMessage={t('AgencyCreateDashboardDialogMessage', { agencyName })}
      okText={t`CreateWithAccess`}
      cancelText={t`Cancel`}
    />
  )

  const renderConfirmCreateAccessDeniedDialog = (
    <CustomDialog
      title={t`CreateAgency`}
      open={openConfirmCreateAccessDenied}
      cancelCallback={handleClose}
      okCallback={createAgencySubmission}
      mainMessage={t('AgencyCreateDialogMessage', { agencyName })}
      okText={t`Create`}
      cancelText={t`Cancel`}
    />
  )

  useEffect(() => {
    dispatch(resetAgencyForm())
  }, [dispatch])

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardAgencyCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={() => navigate(RoutePath.Agencies)}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const setValueMap = {
    state: setState,
    city: setCity,
    iconID: setIcon,
    dashboardAccess: setDashboardAccess,
    agencyName: setAgencyName,
    email: setEmail,
    url: setIcon
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <AgenciesForm
        isLeavingDialogOpen={openConfirmLeaving}
        edit={false}
        onSubmit={() =>
          dashboardAccess
            ? setOpenConfirmCreateAccessGranted(true)
            : setOpenConfirmCreateAccessDenied(true)}
        setValueMap={setValueMap}
        properties={{
          state,
          city,
          icon,
          dashboardAccess,
          agencyName,
          email
        }}
        onDiscard={() => setOpenConfirmLeaving(true)}
      />
      {errorDisplay(errorMessage)}
      {dashboardAccess
        ? renderConfirmCreateAccessGrantedDialog
        : renderConfirmCreateAccessDeniedDialog}
      {renderConfirmLeavingDialog}
      {validationErrorsDisplay(errors)}
    </Box>
  )
}

export default AgenciesCreate
