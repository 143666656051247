import { LOCATIONS } from '../commons/constants/locations'
import { RouteItem, RoutePath } from '../core/routes/routePaths'
import { RoleType } from '../core/routes/constants'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'
import { ListActionItems } from '../commons/interfaces/commons-interfaces'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

const AGENCY_PAGE_ROLES = [RoleType.ADMIN, RoleType.TENANT]

export const getCities = (state: string): string[] => {
  const locations = LOCATIONS
  return state !== '' ? locations[state as keyof typeof locations] : []
}

export const agencyBarRoutePath = (
  id: string,
  campaigns?: number,
  offers?: number
): Record<string, RouteItem> => {
  return {
    details: {
      path: `${RoutePath.Agencies}/${id}`,
      title: i18n.t`Details`,
      roles: AGENCY_PAGE_ROLES,
      icon: ModeEditOutlinedIcon,
      children: []
    },
    campaigns: {
      path: `${RoutePath.Agencies}/${id}${RoutePath.Campaigns}`,
      title: i18n.t`Campaigns`,
      roles: AGENCY_PAGE_ROLES,
      icon: CampaignOutlinedIcon,
      showBadge: true,
      badgeValue: campaigns,
      children: []
    },
    offers: {
      path: `${RoutePath.Agencies}/${id}${RoutePath.Offers}`,
      title: i18n.t`Offers`,
      roles: AGENCY_PAGE_ROLES,
      showBadge: true,
      badgeValue: offers,
      icon: VerifiedOutlinedIcon,
      children: []
    }
  }
}

export const agenciesListActionItems = (AgencyID: string): ListActionItems[] => {
  return [
    {
      path: `${RoutePath.Agencies}/${AgencyID}${RoutePath.Campaigns}`,
      title: i18n.t`Campaigns`,
      icon: <CampaignOutlinedIcon />
    },
    {
      path: `${RoutePath.Agencies}/${AgencyID}${RoutePath.Offers}`,
      title: i18n.t`Offers`,
      icon: <VerifiedOutlinedIcon />
    },
    {
      path: `${RoutePath.Agencies}/${AgencyID}`,
      title: i18n.t`Details`,
      icon: <ModeEditOutlinedIcon />
    }
  ]
}
