import { ReactElement } from 'react'
import { Box, Divider, InputLabel } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import './offer-details.scss'
import { t } from 'i18next'
import {
  convertStringToDateAndTime,
  formatDateAndTime,
  renderSegments
} from '../../commons/utils/utils'
import { OffersInterfaceProps } from './OfferDetails'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'

const AudienceInformation = ({
  offer
}: OffersInterfaceProps): ReactElement | null => {
  const { date: startDate, time: startTime } = convertStringToDateAndTime(
    offer.StartTimestampUTC
  )
  const { date: endDate, time: endTime } = convertStringToDateAndTime(
    offer.EndTimestampUTC
  )
  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD} marginTop='2em'>
      <Box className='offer-detail-step'>
        <Box className='details-date-box'>
          <Box className='offer-date'>
            <SelfServeROTextField
              id='offer-start-date'
              label={t`StartDate`}
              defaultValue={formatDateAndTime(startDate, startTime)}
              showDivider
            />
          </Box>
          <Box className='offer-date'>
            <SelfServeROTextField
              id='offer-end-date'
              label={t`EndDate`}
              defaultValue={formatDateAndTime(endDate, endTime)}
              showDivider
            />
          </Box>
        </Box>
        <InputLabel
          size='small'
          variant='standard'
          className='small-label mb-2 mt-minus'
        >
          {t`IncludedSegments`}
        </InputLabel>
        {renderSegments(offer.SegmentsIncluded)}
        <Divider className='divider mb-0' />
        <InputLabel
          size='small'
          variant='standard'
          className='small-label mb-2'
        >
          {t`ExcludedSegments`}
        </InputLabel>
        {renderSegments(offer.SegmentsExcluded)}
      </Box>
    </Box>
  )
}

export default AudienceInformation
