import { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { t } from 'i18next'
import { FIRST_PAGE_COUPONS_TABLE, PER_PAGE_COUPONS_TABLE } from '../constants'
import { CouponsListParams } from '../interfaces/offers'
import { getValueForForm, checkIsNotEmpty } from '../../commons/utils/utils'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { PAGE, constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import RepeatableSettingsList from './RepeatableSettingsList'
import { getRepeatableCouponsByOfferID, getOfferDetailsByID } from '../slices'

const OffersRepeatableSettings = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const { repeatableCoupons, totalRepeatableCoupons } = useAppSelector((state) => state.offers)

  const handleFormChange = ({
    page,
    perPage
  }: CouponsListParams): void => {
    Object.entries({
      page,
      perPage
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkIsNotEmpty(checkedValue) &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getRepeatableCouponsByOfferID({
          id,
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_COUPONS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_COUPONS_TABLE.toString()
          )
        })
      )
    }
  }, [id, dispatch, searchParams])

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`OfferRepeatableSettings`}</h1>
        <RepeatableSettingsList
          repeatableCouponsList={repeatableCoupons}
          total={totalRepeatableCoupons}
          onPageChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_COUPONS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_COUPONS_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default OffersRepeatableSettings
