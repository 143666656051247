import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { Outlet, useNavigate } from 'react-router-dom'
import { DRAWER_WIDTH } from '../constants'
import { SideBarRoutePath } from '../../core/routes/routePaths'
import { useAppSelector } from '../../core/redux/hooks'
import './sidebar.scss'
import { ListItem, ListItemButton, Toolbar } from '@mui/material'
import AgencyDetailCard from '../../agencies/agency-detail-card/AgencyDetailCard'
import WestOutlinedIcon from '@mui/icons-material/WestOutlined'
import { constants } from '../../commons/constants/constants'
import {
  checkSelectedEntity,
  getAgencyRoutes,
  getCampaignRoutes,
  getCategoryRoutes,
  getOfferCategoryRoutes,
  renderSidebarItems,
  getOfferRoutes,
  getUserRoutes,
  getSegmentRoutes
} from './utils'
import CampaignCategoryDetailCard from '../../campaign-categories/campaign-category-detail-card/CampaignCategoryDetailCard'
import OfferCategoryDetailCard from '../../offer-categories/offer-category-detail-card/OfferCategoryDetailCard'
import CampaignDetailCard from '../../campaigns/campaign-detail-card/CampaignDetailCard'
import OffersSidebarCard from '../../offers/offers-sidebar-card/OffersSidebarCard'
import UserDetailCard from '../../users/user-detail-card/UserDetailCard'
import SegmentsSidebarCard from '../../segments/segment-sidebar-card/SegmentsSidebarCard'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}))

interface DrawerProps {
  open: boolean
}

const PersistentDrawerLeft = ({ open }: DrawerProps): React.ReactElement => {
  const {
    auth: { userType, isAgencyEnabled },
    agencies: {
      agencySelected: { AgencyID, OffersCount, CampaignsCount },
      loading
    },
    campaignCategories: {
      campaignCategorySelected: { CampaignCategoryID, TotalCampaigns }
    },
    offerCategories: {
      offerCategorySelected: { OfferCategoryID, TotalOffers }
    },
    campaigns: {
      campaignSelected: { CampaignID }
    },
    users: {
      userSelected: { UserID }
    },
    offers: {
      offerSelected: { OfferID, OfferType, HasRepeatableCoupons }
    },
    segments: {
      segmentSelected: {
        TargetGroupID,
        totalUsers,
        totalCodes,
        Manual,
        totalCampaigns: totalCampaignsForSegments,
        totalOffers: totalOffersForSegments
      }
    }
  } = useAppSelector((state) => state)

  const navigate = useNavigate()

  const { selectedEntityType, backButtonRoute, backButtonTitle } =
    useAppSelector((state) => state.sidebar)

  const renderSegmentDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.SEGMENT,
    selectedEntityType,
    TargetGroupID
  ) && (
    <Box>
      <SegmentsSidebarCard />
      {renderSidebarItems(
        getSegmentRoutes(
          TargetGroupID?.toString(),
          totalUsers,
          totalCodes,
          totalCampaignsForSegments,
          totalOffersForSegments,
          Manual
        ),
        userType
      )}
    </Box>
  )

  const renderUserDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.USER,
    selectedEntityType,
    UserID
  ) && (
    <Box>
      <UserDetailCard />
      {renderSidebarItems(getUserRoutes(UserID?.toString()), userType)}
    </Box>
  )

  const renderCampaignDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN,
    selectedEntityType,
    CampaignID
  ) && (
    <Box>
      <CampaignDetailCard />
      {renderSidebarItems(getCampaignRoutes(CampaignID?.toString()), userType)}
    </Box>
  )

  const renderAgencyDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.AGENCY,
    selectedEntityType,
    AgencyID
  ) &&
    !loading && (
      <Box>
        <AgencyDetailCard />
        {renderSidebarItems(
          getAgencyRoutes(CampaignsCount, OffersCount, AgencyID),
          userType
        )}
      </Box>
  )

  const renderCategoryDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN_CATEGORY,
    selectedEntityType,
    CampaignCategoryID
  ) && (
    <Box>
      <CampaignCategoryDetailCard />
      {renderSidebarItems(
        getCategoryRoutes(CampaignCategoryID?.toString(), TotalCampaigns),
        userType
      )}
    </Box>
  )

  const renderOfferCategoryDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.OFFER_CATEGORY,
    selectedEntityType,
    OfferCategoryID
  ) && (
    <Box>
      <OfferCategoryDetailCard />
      {renderSidebarItems(
        getOfferCategoryRoutes(OfferCategoryID?.toString(), TotalOffers),
        userType
      )}
    </Box>
  )

  const renderOfferDetails = checkSelectedEntity(
    constants.SIDEBAR_ENTITY_TYPES.OFFER,
    selectedEntityType,
    OfferID
  ) && (
    <Box>
      <OffersSidebarCard />
      {renderSidebarItems(
        getOfferRoutes(OfferID?.toString(), OfferType, HasRepeatableCoupons),
        userType
      )}
    </Box>
  )

  const renderGeneralMenu =
    checkSelectedEntity(
      constants.SIDEBAR_ENTITY_TYPES.GENERAL,
      selectedEntityType,
      constants.EMPTY_STRING
    ) && renderSidebarItems(SideBarRoutePath, userType, isAgencyEnabled, true)

  return (
    <Box display='flex' width='100%'>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            border: 'none'
          }
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <Toolbar />
        <List className='sidebar'>
          {selectedEntityType !== constants.SIDEBAR_ENTITY_TYPES.GENERAL && (
            <Box>
              <ListItem disablePadding>
                <ListItemButton
                  className='back-button-sidebar'
                  onClick={() => navigate(backButtonRoute)}
                >
                  <WestOutlinedIcon fontSize='small' />
                  &nbsp;<span className='sidebar-title'>{backButtonTitle}</span>
                </ListItemButton>
              </ListItem>
              {renderAgencyDetails}
              {renderCategoryDetails}
              {renderOfferCategoryDetails}
              {renderCampaignDetails}
              {renderOfferDetails}
              {renderUserDetails}
              {renderSegmentDetails}
            </Box>
          )}
          {renderGeneralMenu}
        </List>
      </Drawer>
      <Main open={open}>
        <Toolbar />
        <Box className='main-content'>
          <Outlet />
        </Box>
      </Main>
    </Box>
  )
}

export default PersistentDrawerLeft
