import { ReactElement } from 'react'
import { Box, Divider, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterfaceProps } from './CampaignsDetails'
import {
  convertStringToDateAndTime,
  formatDateAndTime,
  renderSegments
} from '../../commons/utils/utils'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'

const CampaignAudienceStep = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()
  const { date: startDate, time: startTime } = convertStringToDateAndTime(
    campaign.StartTimestampUTC
  )
  const { date: endDate, time: endTime } = convertStringToDateAndTime(
    campaign.EndTimestampUTC
  )
  const { date: expiryDate, time: expiryTime } = convertStringToDateAndTime(
    campaign.PointExpiryDate
  )

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          <Box className='details-date-box mb-1'>
            <Box className='shared-row-input'>
              <SelfServeROTextField
                id='startTimestampUTC'
                label={t`StartDate`}
                defaultValue={formatDateAndTime(startDate, startTime)}
                classNameDivider='mb-0'
                showDivider
              />
            </Box>
            <Box className='shared-row-input'>
              <SelfServeROTextField
                id='endTimestampUTC'
                label={t`EndDate`}
                defaultValue={formatDateAndTime(endDate, endTime)}
                classNameDivider='mb-0'
                showDivider
              />
            </Box>
          </Box>
          <SelfServeROTextField
            id='expiryPointDate'
            label={t`ExpiryPointDate`}
            defaultValue={formatDateAndTime(expiryDate, expiryTime)}
            classNameDivider='mb-0'
            showDivider
          />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mb-2'
          >
            {t`IncludedSegments`}
          </InputLabel>
          {renderSegments(campaign.targetGroupsInclude)}
          <Divider className='divider mb-0' />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mb-2'
          >
            {t`ExcludedSegments`}
          </InputLabel>
          {renderSegments(campaign.targetGroupsExclude)}
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignAudienceStep
