import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { CampaignListParams } from '../../campaigns/interfaces/campaigns'
import { PAGE, constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import CampaignsList from '../../campaigns/CampaignsList'
import CampaignsListHeader from './CategoriesCampaignsListHeader'
import {
  getValueForForm
} from '../../commons/utils/utils'
import { getCampaignCategoryByID, getCampaignsByCategoryID } from '../slices'
import { CircularProgress } from '@mui/material'
import { RoutePath } from '../../core/routes/routePaths'
import {
  FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE,
  PER_PAGE_CAMPAIGN_CATEGORIES_TABLE
} from '../constants'

const CategoryCampaigns = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { campaigns, totalCampaignsList } = useAppSelector(
    (state) => state.campaignCategories
  )
  const { tenantID, agencyID } = useAppSelector((state) => state.auth)
  const { id } = useParams<{ id: string }>()

  const handleFormChange = ({
    agencyID,
    tenantID,
    search,
    categories,
    page,
    perPage,
    status
  }: CampaignListParams): void => {
    Object.entries({
      agencyID,
      tenantID,
      search,
      categories,
      page,
      perPage,
      status
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  React.useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignCategoryByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN_CATEGORY))
      dispatch(setBackButtonTitle(t`BackToCategories`))
      dispatch(setBackButtonRoute(RoutePath.CampaignCategories))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  React.useEffect(() => {
    if (id != null) {
      void dispatch(
        getCampaignsByCategoryID({
          categories: [id],
          tenantID,
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ??
              FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ??
              PER_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          ),
          agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
          status: searchParams.get('status') ?? ''
        })
      )
    }
  }, [id, agencyID, tenantID, dispatch, searchParams])

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <Box>
          <h1 className='title'>{t`Campaigns`}</h1>
        </Box>
        <CampaignsListHeader
          search={searchParams.get('search') ?? undefined}
          agency={agencyID ?? searchParams.get('agencyID') ?? ''}
          status={searchParams.get('status') ?? ''}
          handleFormChange={handleFormChange}
        />
        <CampaignsList
          campaignsList={campaigns}
          total={totalCampaignsList}
          handleFormChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ??
            FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ??
            PER_PAGE_CAMPAIGN_CATEGORIES_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default CategoryCampaigns
