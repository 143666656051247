import { Box, TextField, InputLabel, MenuItem, Divider } from '@mui/material'
import { Form } from 'react-router-dom'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import ImageDropZone from '../../ui/dropzone/Dropzone'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import {
  AgenciesSelectItems,
  filterNonNumericValues,
  getRoleTypeByString,
  handleNextStep,
  onFormChangeOfferWrapper,
  selectMenuItem
} from '../../commons/utils/utils'
import { userHasTenantPermission } from '../../core/routes/router'
import { FC, useEffect, useMemo } from 'react'
import { getOfferCategories } from '../../offer-categories/slices'
import IconUpload from '../../ui/icon-upload/IconUpload'
import { OfferFormInterface, OfferInfoFormProps } from '../interfaces/offers'
import { getAllAgencies } from '../../agencies/slices'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import SelfServeButton from '../../ui/button/Button'
import './offer-create.scss'
import { formValidationConstants } from '../constants'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import FormsTextFieldWithEditor from '../../ui/text-field/FormsTextFieldWithEditor'
import { resetIsDescription, setIsDescription } from '../slices'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

const OfferFormOfferInfoStep: FC<OfferInfoFormProps> = ({
  webUiPosition,
  onFormChange,
  handleNext,
  handleBack,
  onDiscard,
  isEdit = false,
  onUiReorderClick,
  isReorderDisabled,
  resetUIs,
  setIsOfferNameUpdated
}) => {
  const {
    offerCreateForm: {
      offerName,
      title,
      thumbnailUrl,
      thumbnailID,
      listedPoints,
      realPoints,
      description,
      agencyID,
      categoryID,
      offerIconUrl,
      offerIconID
    },
    isDescription,
    descriptionHTML
  } = useAppSelector((state) => state.offers)
  const dispatch = useAppDispatch()
  const {
    agencies,
    offerCategories,
    userType,
    tenantID,
    agencyIDLoggedIn,
    isAgencyEnabled
  } = useAppSelector((state) => ({
    agencies: state.agencies.agencies,
    offerCategories: state.offerCategories.offerCategories,
    userType: state.auth.userType,
    tenantID: state.auth.tenantID,
    agencyIDLoggedIn: state.auth.agencyID,
    isAgencyEnabled: state.auth.isAgencyEnabled
  }))

  const role = getRoleTypeByString(userType)
  const isUiEmpty = webUiPosition === ''

  const {
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      offerName,
      title,
      thumbnailUrl,
      thumbnailID,
      listedPoints,
      realPoints,
      description,
      agencyID,
      categoryID,
      webUiPosition,
      offerIconUrl,
      offerIconID
    }
  })
  useEffect(() => {
    void dispatch(
      getAllAgencies({
        tenantID
      })
    )
  }, [tenantID, dispatch])
  useEffect(() => {
    void dispatch(
      getOfferCategories({
        tenantID,
        agencyID: agencyIDLoggedIn ?? agencyID,
        userType
      })
    )
  }, [agencyID, dispatch, tenantID, userType, agencyIDLoggedIn])

  const offerCategoriesSelectItems = useMemo(
    () =>
      offerCategories.map((item) => (
        <MenuItem key={item.OfferCategoryID} value={item.OfferCategoryID}>
          {item.CategoryName}
        </MenuItem>
      )),
    [offerCategories]
  )

  return (
    <>
      <Form
        autoComplete='off'
        method='post'
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
      >
        <FormFieldBox
          title={t`OfferName`}
          rules={{
            required: formValidationConstants.requiredField,
            minLength: formValidationConstants.minLengthError,
            maxLength: formValidationConstants.maxLengthError
          }}
          name='offerName'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'offerNameField' }}
              name='offerName'
              id='offerName'
              onChange={(e) => {
                onFormChangeOfferWrapper(
                  e.target?.value,
                  name as keyof OfferFormInterface,
                  onFormChange,
                  onChange
                )
                setIsOfferNameUpdated?.()
              }}
              value={offerName}
            />
          )}
          control={control}
          errorField={errors.offerName}
        />
        <FormFieldBox
          title={t`Title`}
          rules={{
            required: formValidationConstants.requiredField,
            minLength: formValidationConstants.minLengthError,
            maxLength: formValidationConstants.maxLengthError
          }}
          name='title'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'offerTitleField' }}
              name='title'
              id='offerTitle'
              onChange={(e) => {
                onFormChange(e.target?.value, name as keyof OfferFormInterface)
                onChange(e.target?.value)
              }}
              value={title}
            />
          )}
          control={control}
          errorField={errors.title}
        />

        <FormFieldBox
          title={t`Thumbnail`}
          rules={{
            required: { value: true, message: t`RequiredField` },
            validate: (data: File) => data != null
          }}
          name='thumbnailID'
          render={({ field: { name, onChange } }) => (
            <ImageDropZone
              testId='offerCreateThumbnail'
              isImage
              isThumbnail
              url={thumbnailUrl}
              name={name}
              setFile={(data: File | undefined) => {
                onChange(data, name)
                onFormChange(data, name as keyof OfferFormInterface)
              }}
              thumbnailID={thumbnailID}
            />
          )}
          control={control}
          errorField={errors.thumbnailID}
        />

        <FormFieldBox
          title={t`OfferIconOptional`}
          rules={{}}
          name='offerIconID'
          render={({ field: { name, onChange } }) => (
            <IconUpload
              url={offerIconUrl}
              name={name}
              setFile={(data: File | undefined) => {
                onChange(data, name)
                onFormChange(data, name as keyof OfferFormInterface)
              }}
              onFormChange={(e) => onChange(e)}
              iconID={offerIconID}
            />
          )}
          control={control}
          errorField={errors.offerIconID}
        />

        <FormFieldBox
          title={t`ListedPoints`}
          rules={{
            required: formValidationConstants.requiredField,
            pattern: formValidationConstants.numberPattern,
            min: formValidationConstants.minNum,
            max: formValidationConstants.maxNum
          }}
          name='listedPoints'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'offerListedPointField' }}
              name='listedPoints'
              id='listedPoints'
              onChange={(e) => {
                onFormChange(
                  filterNonNumericValues(e.target?.value),
                  name as keyof OfferFormInterface
                )
                onChange(filterNonNumericValues(e.target?.value))
              }}
              value={listedPoints ?? ''}
            />
          )}
          control={control}
          errorField={errors.listedPoints}
        />

        <FormFieldBox
          title={t`RealPoints`}
          rules={{
            pattern: formValidationConstants.numberPattern,
            min: formValidationConstants.minNum,
            max: formValidationConstants.maxNum
          }}
          name='realPoints'
          render={({ field: { name, onChange } }) => (
            <TextField
              inputProps={{ 'data-testid': 'offerRealPointField' }}
              name='realPoints'
              id='realPoints'
              onChange={(e) => {
                onFormChange(
                  filterNonNumericValues(e.target?.value),
                  name as keyof OfferFormInterface
                )
                onChange(filterNonNumericValues(e.target?.value))
              }}
              value={realPoints ?? ''}
            />
          )}
          control={control}
          errorField={errors.realPoints}
        />

        <FormFieldBox
          title={t`Description`}
          rules={{
            required: formValidationConstants.requiredField,
            minLength: {
              value: 3,
              message: formValidationConstants.descriptionLengthError
            },
            maxLength: {
              value: 1000,
              message: formValidationConstants.descriptionLengthError
            }
          }}
          name='description'
          render={({ field: { name, onChange } }) => (
            <FormsTextFieldWithEditor
              onChange={(value: string) => {
                onFormChange(value, name as keyof OfferFormInterface)
                onChange(value)
              }}
              valueMarkdown={description}
              valueHTML={descriptionHTML}
              isChanged={isDescription}
              placeholder={t`AddDescription`}
              setIsChanged={setIsDescription}
              resetIsChanged={resetIsDescription}
            />
          )}
          control={control}
          errorField={errors.description}
        />

        {userHasTenantPermission(role) && isAgencyEnabled
          ? (
            <FormFieldBox
              title={t`Agency`}
              rules={{
                required: formValidationConstants.requiredField,
                validate: {
                  notEmpty: () => {
                    return agencyID !== ''
                  }
                }
              }}
              name='agencyID'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel shrink={false} id='agency-label'>
                    {agencyID === '' && t`SelectAgency`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    handleFormChange={(e) => {
                      onFormChangeOfferWrapper(
                        e.target?.value as string,
                        name as keyof OfferFormInterface,
                        onFormChange,
                        onChange,
                        resetUIs
                      )
                    }}
                    value={agencyID}
                    menuItem={selectMenuItem(t`None`)}
                    selectItems={AgenciesSelectItems(agencies)}
                    name={name}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.agencyID}
            />
            )
          : null}

        <FormFieldBox
          title={t`Category`}
          rules={{
            required: formValidationConstants.requiredField,
            validate: {
              notEmpty: () => {
                return categoryID !== ''
              }
            }
          }}
          name='categoryID'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel shrink={false} id='category-label'>
                {categoryID === '' && t`SelectCategory`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  onFormChangeOfferWrapper(
                    e.target?.value as string,
                    name as keyof OfferFormInterface,
                    onFormChange,
                    onChange,
                    resetUIs
                  )
                }}
                value={categoryID}
                menuItem={selectMenuItem(t`None`)}
                selectItems={offerCategoriesSelectItems}
                name={name}
                inputProps='custom-form-select'
                isFormSelect
                disabled={
                  isAgencyEnabled && (agencyID === '' || agencyID == null)
                }
              />
            </>
          )}
          control={control}
          errorField={errors.categoryID}
        />
        <FormFieldBox
          title={t`UiPosition`}
          rules={{}}
          name='webUiPosition'
          render={({ field: { name, onChange } }) => (
            <Box className='offer-ui'>
              <TextField
                name={name}
                id='webUiPosition'
                onChange={(e) => {
                  onFormChangeOfferWrapper(
                    filterNonNumericValues(e.target?.value),
                    name as keyof OfferFormInterface,
                    onFormChange,
                    onChange
                  )
                }}
                value={webUiPosition ?? ''}
                className='offer-ui'
                inputProps={{
                  readOnly: true
                }}
              />
              <SelfServeButton
                id='offer-ui-reorder'
                onClick={onUiReorderClick}
                variant='outlined'
                disabled={!isReorderDisabled}
                className='reorder-btn'
              >
                {t`Reorder`}
              </SelfServeButton>
            </Box>
          )}
          control={control}
          errorField={errors.webUiPosition}
        />
        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='offer-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              disabled
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='offerStepNextButton'
              id='offer-create-next'
              type='submit'
              variant='contained'
              disabled={isEdit ? false : isUiEmpty}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            dataTestId='discardButton'
            id='offer-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </>
  )
}

export default OfferFormOfferInfoStep
