import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  OfferCategoriesListParams,
  OfferCategoriesInterface
} from './interfaces/offer-categories'
import {
  OfferCategoriesListColumnsForTenants,
  OfferCategoriesListColumnsForAgencies,
  PER_PAGE_OFFER_CATEGORIES_TABLE,
  FIRST_PAGE_OFFER_CATEGORIES_TABLE
} from './constants'
import { Box } from '@mui/material'
import SelfServePagination from '../ui/pagination/SelfServePagination'
import { constants } from '../commons/constants/constants'
import { Link } from 'react-router-dom'
import { setClassNameForTable } from '../commons/utils/utils'
import './offer-categories.scss'
import PreviewButton from '../ui/button/PreviewButton'
import { RoutePath } from '../core/routes/routePaths'
import { getLiveOfferByID } from '../offers/slices'
import LiveOfferPreview from './preview/LiveOfferPreview'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import SelfServeActionItem from '../ui/list-action-item/SelfServeActionItem'
import { categoriesListActionItems } from './utils'

interface OfferCategoriesListProps {
  onPageChange: (changedData: OfferCategoriesListParams) => void
  page?: number
  perPage?: number
  total: number
  offerCategoriesList: OfferCategoriesInterface[]
  isTenant?: boolean
}

const OfferCategoriesList: FC<OfferCategoriesListProps> = ({
  page,
  perPage,
  total,
  offerCategoriesList,
  isTenant,
  onPageChange
}) => {
  const dispatch = useAppDispatch()
  const { isAgencyEnabled } = useAppSelector((state) => state.auth)

  const COLUMNS = Object.values(
    isTenant === true && isAgencyEnabled
      ? OfferCategoriesListColumnsForTenants
      : OfferCategoriesListColumnsForAgencies
  )

  const loader = (categoryID?: number): void => {
    if (categoryID != null) {
      void dispatch(getLiveOfferByID(categoryID))
    }
  }

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {offerCategoriesList.length > 0 && (
          <TableBody>
            {offerCategoriesList.map(
              (row) =>
                row.OfferCategoryID != null && (
                  <TableRow
                    className='row-with-actions'
                    key={row.OfferCategoryID}
                  >
                    <TableCell className='body-cell centered-cell'>
                      <Link
                        to={`${RoutePath.OfferCategories}/${row.OfferCategoryID}`}
                      >
                        {row.OfferCategoryID}
                      </Link>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Link
                        to={`${RoutePath.OfferCategories}/${row.OfferCategoryID}`}
                      >
                        {row.CategoryName}
                      </Link>
                    </TableCell>
                    {isTenant === true && isAgencyEnabled && (
                      <TableCell className='body-cell'>
                        {row.AgencyIconUrl != null && (
                          <img
                            alt='agency icon'
                            className='rounded-image-list'
                            src={row.AgencyIconUrl}
                          />
                        )}
                        <Link
                          to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}
                        >
                          {row.AgencyName}
                        </Link>
                      </TableCell>
                    )}
                    <TableCell className='body-cell centered-cell'>
                      {row.WebUiPosition}
                    </TableCell>
                    <TableCell className='body-cell centered-cell'>
                      {row.IconImageUrl != null && (
                        <img
                          alt='offer category icon'
                          className='square-image-list'
                          src={row.IconImageUrl}
                        />
                      )}
                    </TableCell>
                    <TableCell className='body-cell'>
                      <div className='live-offer-container'>
                        {row.ActiveOffers?.map((offer) => {
                          return (
                            row.OfferCategoryID != null && (
                              <PreviewButton
                                variant='contained'
                                id={`live-offer-${row.OfferCategoryID}`}
                                key={row.OfferCategoryID}
                                color='secondary'
                                loader={() => loader(offer)}
                                previewComponent={<LiveOfferPreview />}
                                className='live-preview preview-parent minw-3'
                              >
                                {offer}
                              </PreviewButton>
                            )
                          )
                        })}
                      </div>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Box className='list-actions flex-center'>
                        {categoriesListActionItems(row.OfferCategoryID).map(
                          (item) => {
                            return (
                              <SelfServeActionItem
                                path={item.path}
                                title={item.title}
                                icon={item.icon}
                                key={item.title}
                              />
                            )
                          }
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_OFFER_CATEGORIES_TABLE}
        perPage={perPage ?? PER_PAGE_OFFER_CATEGORIES_TABLE}
        onPageChange={(page) => onPageChange({ page })}
        onPerPageChange={(perPage) => onPageChange({ perPage })}
        total={total}
        length={offerCategoriesList.length}
      />
    </TableContainer>
  )
}

export default OfferCategoriesList
