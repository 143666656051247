import { ReactElement, FC, useState, useMemo } from 'react'
import { EarningsListParams } from '../interfaces/users'
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material'
import { Form } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  allText,
  constructDate,
  handleDateRangeChange,
  selectMenuItem
} from '../../commons/utils/utils'
import { useAppSelector } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

dayjs.extend(utc)

interface UserEarningsListHeaderProps {
  handleFormChange: (changedData: EarningsListParams) => void
  selectedCampaign?: string
}

const UserEarningsListHeader: FC<UserEarningsListHeaderProps> = ({
  handleFormChange,
  selectedCampaign = ''
}): ReactElement => {
  const { userEarningsListOptions } = useAppSelector((state) => state.users)
  const [earnedRangeStart, setEarnedRangeStart] = useState<null | Date>(null)
  const [earnedRangeEnd, setEarnedRangeEnd] = useState<null | Date>(null)
  const [claimedRangeStart, setClaimedRangeStart] = useState<null | Date>(null)
  const [claimedRangeEnd, setClaimedRangeEnd] = useState<null | Date>(null)
  const { t } = useTranslation()

  const selectItems = useMemo(
    () =>
      userEarningsListOptions.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.label}
        </MenuItem>
      )),
    [userEarningsListOptions]
  )

  return (
    <div className='mb-1'>
      <Form role='search'>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`EarnedDate`}
              className='date-picker'
              selectsRange
              startDate={earnedRangeStart}
              endDate={earnedRangeEnd}
              onChange={(selectedDateRange) => {
                const { endDateString, startDateString } =
                  handleDateRangeChange(
                    selectedDateRange,
                    setEarnedRangeStart,
                    setEarnedRangeEnd
                  )
                handleFormChange({
                  earnedDateRange: constructDate(startDateString, endDateString)
                })
              }}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`ClaimedDate`}
              className='date-picker'
              selectsRange
              startDate={claimedRangeStart}
              endDate={claimedRangeEnd}
              onChange={(selectedDateRange) => {
                const { endDateString, startDateString } =
                  handleDateRangeChange(
                    selectedDateRange,
                    setClaimedRangeStart,
                    setClaimedRangeEnd
                  )
                handleFormChange({
                  claimDateRange: constructDate(startDateString, endDateString)
                })
              }}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='agencies-label'>
            {(selectedCampaign == null || selectedCampaign === '') &&
              allText(t`CampaignForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ selectedCampaign: e.target.value as string })}
            value={selectedCampaign}
            inputProps='table-input maxw-8 minw-8'
            menuItem={selectMenuItem(t`AllCampaigns`)}
            selectItems={selectItems}
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default UserEarningsListHeader
