export const LOCATIONS = {
  AK: [
    'Adak',
    'Adak Island/Adak Apt',
    'Afognak',
    'Akhiok',
    'Akiachak',
    'Akiak',
    'Akutan',
    'Alakanuk',
    'Alcan',
    'Aleknagik',
    'Aleneva',
    'Alitak',
    'Allakaket',
    'Alyeska',
    'Ambler',
    'Amchitka',
    'Amook',
    'Anaktuvuk',
    'Anchor Point',
    'Anchorage',
    'Angoon',
    'Aniak',
    'Anita Bay',
    'Annette Island',
    'Anvik',
    'Arctic Village',
    'Atka',
    'Atmautluak',
    'Atqasuk',
    'Attu Island',
    'Baker Island',
    'Baranof',
    'Barrow',
    'Barter Island',
    'Bartletts',
    'Bear Creek',
    'Beaver',
    'Beaver Inlet',
    'Bell Island',
    'Beluga',
    'Bethel',
    'Bettles',
    'Big Creek',
    'Big Delta',
    'Big Lake',
    'Big Mountain',
    'Birch Creek',
    'Birchwood',
    'Blue Fox Bay',
    'Bornite',
    'Boswell Bay',
    'Boundary',
    'Brooks Lake',
    'Brooks Lodge',
    'Buckland',
    'Cabin Creek',
    'Candle',
    'Cape Lisburne',
    'Cape Newenham',
    'Cape Pole',
    'Cape Romanzof',
    'Cape Sabine',
    'Cape Spencer',
    'Castle Island',
    'Central',
    'Chalkyitsik',
    'Chandalar',
    'Chatham',
    'Chefornak',
    'Chena Hot Springs',
    'Chernofski',
    'Chevak',
    'Chicken',
    'Chignik',
    'Chisana',
    'Chistochina',
    'Chitina',
    'Chomley',
    'Chuathbaluk',
    'Chugiak',
    'Circle',
    'Circle Hot Springs',
    'Clam Gulch',
    'Clarks Point',
    'Clear',
    'Coffee Point',
    'Coffman Cove',
    'Cold Bay',
    'Coldfoot',
    'College',
    'Colorado Creek',
    'Cooper Landing',
    'Cooper Lodge',
    'Copper Centre',
    'Cordova',
    'Corner Bay',
    'Council',
    'Craig',
    'Crooked Creek',
    'Cube Cove',
    'Dahl Creek',
    'Danger Bay',
    'Deep Bay',
    'Deering',
    'Delta Junction',
    'Denali',
    'Dillingham',
    'Diomede Island',
    'Dolomi',
    'Donnelly',
    'Dora Bay',
    'Drift River',
    'Dutch Harbor',
    'Eagle',
    'Eagle River',
    'East Fork',
    'Edna Bay',
    'Eek',
    'Egegik',
    'Eight Fathom Bight',
    'Eklutna',
    'Ekuk',
    'Ekwok',
    'Eldred Rock',
    'Elfin Cove',
    'Elim',
    'Ellamar',
    'Elmendorf Air Force Base',
    'Emmonak',
    'English Bay',
    'Excursion Inlet',
    'Fairbanks',
    'Falls Bay',
    'False Island',
    'False Pass',
    'Farewell',
    'Fink Creek',
    'Fire Cove',
    'Five Finger',
    'Five Mile',
    'Flat',
    'Flaxman Island',
    'Fort Richardson',
    'Fort Yukon',
    'Fortuna Ledge',
    'Fox',
    'Fresh Water Bay',
    'Funter Bay',
    'Gakona',
    'Galbraith Lake',
    'Galena',
    'Gambell',
    'Ganes Creek',
    'Gertrude Cove',
    'Girdwood',
    'Glacier Creek',
    'Glennallen',
    'Golden Horn',
    'Golovin',
    'Goodnews Bay',
    'Graehl',
    'Granite Mountain',
    'Grayling',
    'Gulkana',
    'Gustavus',
    'Haines',
    'Hanus Bay',
    'Hawk Inlet',
    'Haycock',
    'Healy',
    'Healy Lake',
    'Herendeen',
    'Hidden Falls',
    'Hobart Bay',
    'Hogatza',
    'Holikachuk',
    'Hollis',
    'Holy Cross',
    'Homer',
    'Homeshore',
    'Hoonah',
    'Hooper Bay',
    'Houston',
    'Hughes',
    'Huslia',
    'Hydaburg',
    'Hyder',
    'Icy Bay',
    'Igiugig',
    'Iliamna',
    'Isabel Pass',
    'Ivanof Bay',
    'Ivishak',
    'Jakolof Bay',
    'Juneau',
    'Kaguyak',
    'Kagvik Creek',
    'Kake',
    'Kakhonak',
    'Kalakaket',
    'Kalskag',
    'Kaltag',
    'Karluk',
    'Karluk Lake',
    'Kasaan',
    'Kasigluk',
    'Kasilof',
    'Kavik',
    'Kelly Bar',
    'Kelp Bay',
    'Kenai',
    'Ketchikan',
    'Kiana',
    'King Cove',
    'King Salmon',
    'Kipnuk',
    'Kiska Harbour',
    'Kiska Island',
    'Kitoi Bay',
    'Kivalina',
    'Kizhuyak',
    'Klag Bay',
    'Klawock',
    'Kobuk',
    'Kodiak',
    'Kongiganak',
    'Kotlik',
    'Kotzebue',
    'Koyuk',
    'Koyukuk',
    'Kugururok River',
    'Kulik Lake',
    'Kuluk Bay',
    'Kuparuk',
    'Kwethluk',
    'Kwigillingok',
    'Labouchere Bay',
    'Lake Minchumina',
    'Larsen Bay',
    'Lemeta',
    'Lenard Harbour',
    'Levelock',
    'Lime Village',
    'Lincoln Rock',
    'Little Naukati',
    'Little Port Walter',
    'Livengood',
    'Lonely',
    'Long Island',
    'Loring',
    'Lost Harbor',
    'Lost River',
    'Manley Hot Springs',
    'Manokotak',
    'Marguerite Bay',
    'Marshall',
    'Matanuska',
    'May Creek',
    'McCarthy',
    'McGrath',
    'McKinley Park',
    'Medfra',
    'Mekoryuk',
    'Metlakatla',
    'Meyers Chuck',
    'Middleton Island',
    'Minchumina',
    'Minto',
    'Moose Pass',
    'Moser Bay',
    'Moses Point',
    'Mount McKinley',
    'Mountain Village',
    'Naknek',
    'Nakolik River',
    'Napakiak',
    'Napaskiak',
    'Naukiti',
    'Neets Bay',
    'Nelson Lagoon',
    'Nenana',
    'New Chenega',
    'New Koliganek',
    'New Stuyahok',
    'Newtok',
    'Niblack',
    'Nichen Cove',
    'Nightmute',
    'Nikishka',
    'Nikiski',
    'Nikolai',
    'Nikolski',
    'Ninilchik',
    'Noatak',
    'Nome',
    'Nondalton',
    'Noorvik',
    'North Pole',
    'Northeast Cape',
    'Northway',
    'Nuiqsut',
    'Nulato',
    'Nunapitchuk',
    'Nyac',
    'Oceanic',
    'Old Harbor',
    'Olga Bay',
    'Onion Bay',
    'Orca',
    'Ouzinkie',
    'Pack Creek',
    'Paf Warren',
    'Paimiut',
    'Painter Creek',
    'Palmer',
    'Parks',
    'Pauloff Harbor',
    'Paxson',
    'Pedro Bay',
    'Pelican',
    'Perry Island',
    'Perryville',
    'Petersburg',
    "Peterson's Point",
    'Pilot Point',
    'Pilot Station',
    'Platinum',
    'Pleasant Harbour',
    'Pleasant Island',
    'Point Baker',
    'Point Barrow',
    'Point Hope',
    'Point Lay',
    'Point Retreat',
    'Polk Inlet',
    'Pope Vanoy',
    'Porcupine Creek',
    'Port Alexander',
    'Port Alice',
    'Port Alsworth',
    'Port Armstrong',
    'Port Bailey',
    'Port Clarence',
    'Port Frederick',
    'Port Graham',
    'Port Heiden',
    'Port Johnson',
    'Port Lions',
    'Port MacKenzie',
    'Port Moller',
    'Port Oceanic',
    'Port Protection',
    'Port San Juan',
    'Port Wakefield',
    'Port Walter',
    'Port Williams',
    'Portage Creek',
    'Prospect Creek',
    'Prudhoe Bay',
    'Queen',
    'Quinhagak',
    'Rampart',
    'Raspberry Strait',
    'Red Devil',
    'Red Dog',
    'Rowan Bay',
    'Ruby',
    'Russian Mission',
    'Saginaw Bay',
    'Sagwon',
    'Saint George Island',
    'Saint Marys',
    'Saint Michael',
    'Saint Paul Island',
    'San Juan',
    'Sand Point',
    'Sandy River',
    'Sarichef',
    'Savoonga',
    'Scammon Bay',
    'Seal Bay',
    'Selawik',
    'Seldovia',
    'Seward',
    'Shageluk',
    'Shaktoolik',
    'Sheep Mountain',
    'Sheldon Point',
    'Shemya',
    'Shishmaref',
    'Shoal Cove',
    'Shungnak',
    'Sitka',
    'Sitkinak Island',
    'Skagway',
    'Skwentna',
    'Sledge Island',
    'Sleetmute',
    'Smith Cove',
    'Soldotna',
    'Solomon',
    'South Naknek',
    'Sparrevohn',
    'Spenard',
    'Steamboat Bay',
    'Stebbins',
    'Sterling',
    'Stevens Village',
    'Stony River',
    'Summit',
    'Susitna',
    'Sutton',
    'Tahneta Pass Lodge',
    'Takotna',
    'Taku Lodge',
    'Talkeetna',
    'Tanacross',
    'Tanaga Bay',
    'Tanalian Point',
    'Tanana',
    'Tatalina',
    'Tatitlek',
    'Taylor',
    'Telida',
    'Teller',
    'Teller Mission',
    'Tenakee Springs',
    'Tetlin',
    'Thorne Bay',
    'Tikchik',
    'Tin City',
    'Togiak',
    'Togiak Fish',
    'Togiak Village',
    'Tok',
    'Tokeen',
    'Toksook Bay',
    'Tree Point',
    'Tuluksak',
    'Tuntutuliak',
    'Tununak',
    'Tuxekan Island',
    'Twin Hills',
    'Tyee',
    'Tyonek',
    'Uganik',
    'Ugashik',
    'Umiat',
    'Umnak Island',
    'Unalakleet',
    'Unalaska',
    'Usibelli',
    'Utopia Creek',
    'Uyak',
    'Valdez',
    'Venetie',
    'Wainwright',
    'Wales',
    'Ward Cove',
    'Wasilla',
    'Waterfall',
    'West Kavik',
    'West Kuparuk',
    'West Point',
    'Whale Pass',
    'White Mountain',
    'Whittier',
    'Willow',
    'Wrangell',
    'Yakutat',
    'Yoke Bay',
    'Zachar Bay'
  ],
  AL: [
    'Abbeville',
    'Adamsville',
    'Addison',
    'Adger',
    'Alabama City',
    'Alabama Pt.',
    'Alabaster',
    'Alberta',
    'Albertville',
    'Alexander City',
    'Aliceville',
    'Alpine',
    'Andalusia',
    'Anderson',
    'Anniston',
    'Arab',
    'Ardmore',
    'Ariton',
    'Arley',
    'Arlington',
    'Ashford',
    'Ashland',
    'Ashville',
    'Athens',
    'Atmore',
    'Attalla',
    'Auburn',
    'Autaugaville',
    'Axis',
    'Baileyton',
    'Barton',
    'Bay Minette',
    'Bayou la Batre',
    'Bear Creek',
    'Beatrice',
    'Belle Mina',
    'Bellwood',
    'Benton',
    'Berry',
    'Bessemer',
    'Billingsley',
    'Birmingham',
    'Birmingport',
    'Blountsville',
    'Boaz',
    'Boligee',
    'Bon Air',
    'Bon Secour',
    'Branchville',
    'Brantley',
    'Bremen',
    'Brent',
    'Brewton',
    'Bridgeport',
    'Brookley',
    'Brookwood',
    'Brownsboro',
    'Brundidge',
    'Bucks',
    'Buhl',
    'Burkville',
    'Butler',
    'Calera',
    'Calvert',
    'Camden',
    'Camp Hill',
    'Carrollton',
    'Castleberry',
    'Cedar Bluff',
    'Center Point',
    'Centre',
    'Centreville',
    'Chapman',
    'Chatom',
    'Chelsea',
    'Cherokee',
    'Chickasaw',
    'Childersburg',
    'Chunchula',
    'Citronelle',
    'Clanton',
    'Clay',
    'Clayton',
    'Cleveland',
    'Clinton',
    'Coden',
    'Collinsville',
    'Columbia',
    'Columbiana',
    'Cooks Springs',
    'Coosa Pines',
    'Cordova',
    'Cottondale',
    'Cottonton',
    'Cottonwood',
    'Courtland',
    'Cragford',
    'Creola',
    'Cropwell',
    'Crossville',
    'Cullman',
    'Cusseta',
    'Dadeville',
    'Daleville',
    'Danville',
    'Daphne',
    'Daviston',
    'Deatsville',
    'Decatur',
    'Delta',
    'Demopolis',
    'Detroit',
    'Dolomite',
    'Dora',
    'Dothan',
    'Double Springs',
    'Dutton',
    'Eastaboga',
    'Eclectic',
    'Edwardsville',
    'Eight Mile',
    'Elba',
    'Elberta',
    'Elkmont',
    'Elmore',
    'Emelle',
    'Empire',
    'Ensley',
    'Enterprise',
    'Eufaula',
    'Eutaw',
    'Eva',
    'Evergreen',
    'Fackler',
    'Fairfax',
    'Fairfield',
    'Fairhope',
    'Falkville',
    'Fayette',
    'Fitzpatrick',
    'Flomaton',
    'Florala',
    'Florence',
    'Florence-Muscle Shoals Apt',
    'Foley',
    'Forkland',
    'Fort Deposit',
    'Fort Mitchell',
    'Fort Payne',
    'Fort Rucker',
    'Frisco City',
    'Fruithurst',
    'Fultondale',
    'Fyffe',
    'Gadsden',
    'Gainesville',
    'Gantt',
    'Garden City',
    'Gardendale',
    'Gaylesville',
    'Geneva',
    'Georgia',
    'Georgiana',
    'Geraldine',
    'Glencoe',
    'Goodwater',
    'Gordo',
    'Gordon',
    'Goshen',
    'Graham',
    'Grand Bay',
    'Grant',
    'Greensboro',
    'Greenville',
    'Grove Hill',
    'Guin',
    'Gulf Shores',
    'Guntersville',
    'Gurley',
    'Hackleburg',
    'Haleyville',
    'Hamilton',
    'Hampton',
    'Hanceville',
    'Hardaway',
    'Harpersville',
    'Hartford',
    'Hartselle',
    'Hayden',
    'Hayneville',
    'Hazel Green',
    'Headland',
    'Heflin',
    'Helena',
    'Henagar',
    'Highland Home',
    'Hillsboro, Lawrence',
    'Hillsboro, Madison',
    'Hodges',
    'Hokes Bluff',
    'Holly Pond',
    'Hollywood',
    'Homewood',
    'Hoover',
    'Hope Hull',
    'Houston',
    'Hueytown',
    'Huntsville',
    'Hurtsboro',
    'Huxford',
    'Ider',
    'Indian Springs',
    'Irondale',
    'Irvington',
    'Jack',
    'Jackson',
    'Jacksonville',
    'Jasper',
    'Jemison',
    'Killen',
    'Kinston',
    'Knoxville',
    'Laceys Spring',
    'Lafayette',
    'Lanett',
    'Le Moyne',
    'Leeds',
    'Leesburg',
    'Leighton',
    'Leroy',
    'Letohatchee',
    'Lexington',
    'Lillian',
    'Lincoln',
    'Linden',
    'Lineville',
    'Lipscomb',
    'Listerhill',
    'Livingston',
    'Loachapoka',
    'Logan',
    'Louisville',
    'Lowndesboro',
    'Loxley',
    'Luverne',
    'Lynn',
    'Madison',
    'Mahrt',
    'Malvern',
    'Maplesville',
    'Marion',
    'Maxwell',
    'Maylene',
    'Mc Shan',
    'McCalla',
    'McIntosh',
    'McKenzie',
    'McWilliams',
    'Mentone',
    'Meridianville',
    'Midfield',
    'Midland City',
    'Millbrook',
    'Millport',
    'Millry',
    'Mitchell',
    'Mobile',
    'Monroeville',
    'Montevallo',
    'Montgomery',
    'Montrose',
    'Moody',
    'Morgan City',
    'Morris',
    'Moulton',
    'Moundville',
    'Mount Hope',
    'Mount Meigs',
    'Mount Vernon',
    'Mountain Brook',
    'Mountain View',
    'Mulga',
    'Munford',
    'Muscle Shoals',
    'Muscle Shoals-Florence Apt',
    'Naheola',
    'Nauvoo',
    'Needham',
    'New Brockton',
    'New Market',
    'Newton',
    'North Arab',
    'North Birmingham',
    'Northport',
    'Oakman',
    'Odenville',
    'Ohatchee',
    'Oneonta',
    'Opelika',
    'Opp',
    'Orange Beach',
    'Owens Cross Roads',
    'Oxford',
    'Ozark',
    'Paint Rock',
    'Parrish',
    'Pelham',
    'Pell City',
    'Pennington',
    'Perdue Hill',
    'Peterman',
    'Phenix City',
    'Phil Campbell',
    'Pickensville',
    'Piedmont',
    'Pike Road',
    'Pinckard',
    'Pine Apple',
    'Pine Hill',
    'Pineville',
    'Pinson',
    'Pinto Island',
    'Pisgah',
    'Pittsview',
    'Pleasant Grove',
    'Point Clear',
    'Port Lions',
    'Prattville',
    'Priceville',
    'Prichard',
    'Quinton',
    'Ragland',
    'Rainbow',
    'Rainbow City',
    'Rainsville',
    'Ramer',
    'Ranburne',
    'Red Bay',
    'Reform',
    'River Falls',
    'Riverside',
    'Roanoke',
    'Robertsdale',
    'Rockford',
    'Rogersville',
    'Russellville',
    'Saginaw',
    'Saint Paul',
    'Salem',
    'Samantha',
    'Samson',
    'Saraland',
    'Satsuma',
    'Scottsboro',
    'Seale',
    'Section',
    'Selma',
    'Semmes',
    'Sheffield',
    'Shorter',
    'Shorterville',
    'Snead',
    'Somerville',
    'Southside',
    'Spanish Fort',
    'Springville',
    'Spruce Pine',
    'Stapleton',
    'Steele',
    'Sterrett',
    'Stevenson',
    'Stewart',
    'Stockton',
    'Sulligent',
    'Sumiton',
    'Summerdale',
    'Summit',
    'Sunshine',
    'Sycamore',
    'Sylacauga',
    'Sylvania',
    'Talladega',
    'Tallassee, Elmore',
    'Tanner',
    'Tarrant',
    'Theodore',
    'Thomasville',
    'Thorsby',
    'Toney',
    'Town Creek',
    'Trafford',
    'Trinity',
    'Troy',
    'Trussville',
    'Tuscaloosa',
    'Tuscumbia',
    'Tuskegee',
    'Tyler',
    'Union Grove',
    'Union Springs',
    'Uriah',
    'Valley',
    'Vance',
    'Vernon',
    'Vestavia Hills',
    'Vincent',
    'Vinemont',
    'Wadley',
    'Warrior',
    'Waterloo',
    'Weaver',
    'Wedowee',
    'Wellington',
    'West Blocton',
    'Westover',
    'Wetumpka',
    'Whistler',
    'Wilsonville',
    'Winfield',
    'Woodford',
    'Woodland',
    'Woodstock',
    'York'
  ],
  AR: [
    'Alexander',
    'Alma',
    'Altheimer',
    'Altus',
    'Amity',
    'Arkadelphia',
    'Arkansas City',
    'Armorel',
    'Ash Flat',
    'Ashdown',
    'Atkins',
    'Augusta',
    'Austin',
    'Bald Knob',
    'Barling',
    'Barton',
    'Batesville',
    'Bauxite',
    'Bearden',
    'Beebe',
    'Beech Glove',
    'Beirne',
    'Bella Vista',
    'Benton',
    'Bentonville',
    'Berryville',
    'Bethel Heights',
    'Bigelow',
    'Bigfork',
    'Bismarck',
    'Black Rock',
    'Blackwell',
    'Blytheville',
    'Bono',
    'Booneville',
    'Bradford',
    'Bradley',
    'Brinkley',
    'Brockwell',
    'Brookland',
    'Bruno',
    'Bryant',
    'Buckeye',
    'Byant',
    'Cabot',
    'Camden',
    'Caraway',
    'Carlisle',
    'Cash',
    'Cave City',
    'Cave Spring',
    'Cecil',
    'Centerton',
    'Centerville',
    'Charleston',
    'Cherokee',
    'Cherokee Village',
    'Cherry Valley',
    'Clarendon',
    'Clarksville',
    'Clinton',
    'Colt',
    'Conway',
    'Corning',
    'Cotton Plant',
    'Cove',
    'Crawfordsville',
    'Crossett',
    'Cypress Bend',
    'Damascus',
    'Danville',
    'Dardanelle',
    'De Queen',
    'De Witt',
    'Decatur',
    'Deer',
    'Dell',
    'Dermott',
    'Des Arc',
    'Desha',
    'Dierks',
    'Douglas',
    'Draper',
    'Dumas',
    'Earle',
    'East Camden',
    'Economy',
    'El Dorado',
    'Elizabeth',
    'Elm Springs',
    'Emmet',
    'England',
    'Eudora',
    'Eureka Springs',
    'Evadale',
    'Fair Oaks',
    'Fairfield Bay',
    'Farmington',
    'Fayetteville',
    'Flippin',
    'Fordyce',
    'Foreman',
    'Forest Park',
    'Forrest City',
    'Fort Chaffee',
    'Fort Smith',
    'Fredonia',
    'Fulton',
    'Garfield',
    'Gassville',
    'Gentry',
    'Gillett',
    'Gillham',
    'Glencoe',
    'Glenwood',
    'Grady',
    'Gravette',
    'Green Forest',
    'Greenbrier',
    'Greenwood, Sebastian',
    'Guion',
    'Gurdon',
    'Guy',
    'Hackett',
    'Hamburg',
    'Hampton',
    'Hardy',
    'Harrisburg',
    'Harrison',
    'Hartford',
    'Hartman',
    'Haskell',
    'Hatfield',
    'Havana',
    'Heber Springs',
    'Helena',
    'Hermitage',
    'Highland',
    'Hindsville',
    'Holiday Island',
    'Hope',
    'Hot Springs',
    'Hot Springs National Park',
    'Hoxie',
    'Hughes',
    'Humnoke',
    'Hutchinson',
    'Ida',
    'Imboden',
    'Jacksonville',
    'Jasper',
    'Johnson',
    'Joiner',
    'Jones Mill',
    'Jonesboro',
    'Jordan',
    'Judsonia',
    'Kansas',
    'Kensett',
    'Knobel',
    'Knoxville',
    'Lake Village',
    'Lavaca',
    'Leachville',
    'Lead Hill',
    'Leola',
    'Lewisville',
    'Lexa',
    'Lincoln',
    'Little Rock',
    'London',
    'Lonoke',
    'Lonsdale',
    'Lowell',
    'Lynn',
    'Mabelvale',
    'Magazine',
    'Magness',
    'Magnolia',
    'Malvern',
    'Mammoth Spring',
    'Manila',
    'Mansfield',
    'Marianna',
    'Marion',
    'Marked Tree',
    'Marmaduke',
    'Marshall',
    'Marvell',
    'Maumelle',
    'Mayflower',
    'McCrory',
    'McGehee',
    'Melbourne',
    'Mellwood',
    'Mena',
    'Mesa',
    'Midway',
    'Mineral Springs',
    'Monette',
    'Monticello',
    'Moro',
    'Morrilton',
    'Mount Holly',
    'Mount Ida',
    'Mountain Home',
    'Mountain View',
    'Mulberry',
    'Murfreesboro',
    'Nashville',
    'Newark',
    'Newport',
    'Norfork',
    'Norman',
    'Norphlet',
    'North Little Rock',
    'Ola',
    'Old Hickory',
    'Omaha',
    'Osceola',
    'Otwell',
    'Ozark',
    'Ozone',
    'Palestine',
    'Paragould',
    'Paris',
    'Paron',
    'Patterson',
    'Pea Ridge',
    'Pearcy',
    'Pennington',
    'Perkins',
    'Phoenix Township',
    'Piggott',
    'Pine Bluff',
    'Pineville',
    'Plumerville',
    'Pocahontas',
    'Portland',
    'Prairie Grove',
    'Prattsville',
    'Prescott',
    'Quitman',
    'Ravenden Springs',
    'Rector',
    'Red Hill',
    'Redfield',
    'Roe',
    'Rogers',
    'Rose Bud',
    'Rosedale',
    'Royal',
    'Russellville',
    'Saint Charles',
    'Saint Paul',
    'Salem',
    'Scranton',
    'Searcy',
    'Sheridan',
    'Sherrill',
    'Sherwood',
    'Siloam Springs',
    'Smackover',
    'Smithville',
    'Springdale',
    'Stamps',
    'Star City',
    'Strong',
    'Stuttgart',
    'Sulphur Springs',
    'Summers',
    'Swifton',
    'Texarkana',
    'Thatcher',
    'Tillar',
    'Tontitown',
    'Trumann',
    'Tucker',
    'Tuckerman',
    'Ulm',
    'Umpire',
    'Van Buren',
    'Vilonia',
    'Viola',
    'Waldenburg',
    'Waldron',
    'Walnut Ridge',
    'Ward',
    'Warm Springs',
    'Warren',
    'Weiner',
    'West Fork',
    'West Helena',
    'West Memphis',
    'West Ridge',
    'Western Grove',
    'Wheatley',
    'White Hall',
    'Widener',
    'Willisville',
    'Wilson',
    'Winthrop',
    'Wynne',
    'Yellville'
  ],
  AZ: [
    'Ajo',
    'Amado',
    'Anthem',
    'Apache Junction',
    'Arlington',
    'Ash Fork',
    'Avondale',
    'Bagdad',
    'Bellemont',
    'Benson',
    'Bisbee',
    'Black Canyon City',
    'Bouse',
    'Bowie',
    'Buckeye',
    'Bullhead City',
    'Camp Verde',
    'Carefree',
    'Casa Grande',
    'Cashion',
    'Catalina',
    'Cave Creek',
    'Central',
    'Chambers',
    'Chandler',
    'Chinle',
    'Chino Valley',
    'Clarkdale',
    'Claypool',
    'Clifton',
    'Cochise',
    'Concho',
    'Congress',
    'Coolidge',
    'Cornville',
    'Cottonwood',
    'Dateland',
    'Davis Monthan AFB',
    'Desert Hills',
    'Dewey',
    'Douglas',
    'Drake',
    'Eagar',
    'Ehrenberg',
    'El Mirage',
    'Elfrida',
    'Eloy',
    'Flagstaff',
    'Florence',
    'Fort Huachuca-Sierra Vista Apt',
    'Fort Mohave',
    'Fountain Hills',
    'Fredonia',
    'Ganado',
    'Gila Bend',
    'Gilbert',
    'Glendale',
    'Globe',
    'Gold Canyon',
    'Golden Valley',
    'Goodyear',
    'Grand Canyon',
    'Grand Canyon West Apt',
    'Green Valley',
    'Greer',
    'Griffith',
    'Havasupai',
    'Hayden',
    'Higley',
    'Holbrook',
    'Humboldt',
    'Jerome',
    'Joseph City',
    'Kayenta',
    'Kearny',
    'Kingman',
    'Lake Havasu City',
    'Lakeside',
    'Laveen',
    'Litchfield Park',
    'Lukeville',
    'Magma',
    'Many Farms',
    'Marana',
    'Marble Canyon',
    'Maricopa',
    'Mayer',
    'Mesa',
    'Miami',
    'Mohave Valley',
    'Morenci',
    'Naco',
    'New River',
    'Nogales',
    'Oatman',
    'Oro Valley',
    'Overgaard',
    'Page',
    'Paradise Valley',
    'Parker',
    'Parks',
    'Paulden',
    'Payson',
    'Peach Springs',
    'Pearce',
    'Peoria',
    'Phoenix',
    'Picacho',
    'Pima',
    'Pine',
    'Pinedale',
    'Polacca',
    'Prescott',
    'Prescott Valley',
    'Quartzsite',
    'Queen Creek',
    'Red Rock',
    'Red Valley',
    'Rillito',
    'Rio Rico',
    'Roll',
    'Safford',
    'Sahuarita',
    'Saint Johns',
    'Salome',
    'San Carlos, Graham',
    'San Carlos, Maricopa',
    'San Luis',
    'San Manuel',
    'San Tan Valley',
    'Sanders',
    'Sasabe',
    'Scottsdale',
    'Sedona',
    'Show Low',
    'Sierra Vista',
    'Sierra Vista-Fort Huachuca Apt',
    'Skull Valley',
    'Snowflake',
    'Somerton',
    'Sonoita',
    'South Tucson',
    'Springerville',
    'Stanfield',
    'Sun City',
    'Superior',
    'Surprise',
    'Taylor',
    'Tempe',
    'Thatcher',
    'Tolleson',
    'Tombstone',
    'Tonopah',
    'Tuba City',
    'Tubac',
    'Tucson',
    'Tucson International Apt',
    'Tusayan',
    'Vail',
    'Valle',
    'Vanar',
    'Waddell',
    'Wellton',
    'West Sedona',
    'White River',
    'Whiteriver',
    'Wickenburg',
    'Willcox',
    'Williams',
    'Winslow',
    'Wittmann',
    'Yucca',
    'Yuma',
    'Littlefield'
  ],
  CA: [
    'Acampo',
    'Acton',
    'Adelanto',
    'Agoura',
    'Agoura Hills',
    'Agua Dulce',
    'Aguanga',
    'Alameda',
    'Alamo',
    'Albany',
    'Alhambra',
    'Aliso Viejo',
    'Allensworth',
    'Alpaugh',
    'Alpine Meadows',
    'Alpine, Los Angeles',
    'Alpine, San Diego',
    'Alta',
    'Alta Loma',
    'Altadena',
    'Alturas',
    'Alum Rock',
    'Alvarado',
    'Alviso',
    'Amboy',
    'American Canyon',
    'Anaheim',
    'Anderson',
    'Andrade',
    'Angeles Oaks',
    'Angels Camp',
    'Angwin',
    'Antelope',
    'Antioch',
    'Anza',
    'Apple Valley',
    'Aptos',
    'Arbuckle',
    'Arcadia',
    'Arcata',
    'Arleta',
    'Arnold',
    'Aromas',
    'Arroyo Grande',
    'Artesia',
    'Artois',
    'Arvin',
    'Ashland',
    'Asti',
    'Atascadero',
    'Atherton',
    'Atlanta',
    'Atwater',
    'Atwood',
    'Auburn',
    'Auckland',
    'August F. Haw',
    'Aukum',
    'Avalon',
    'Avenal',
    'Avery',
    'Azusa',
    'Baker',
    'Bakersfield',
    'Balboa',
    'Baldwin Park',
    'Ballard',
    'Ballico',
    'Banning',
    'Barstow',
    'Bartlett Springs',
    'Bassett',
    'Bay Point',
    'Beale Air Force Base',
    'Bear Valley',
    'Beaumont',
    'Belair',
    'Bell',
    'Bell Gardens',
    'Bella Vista',
    'Bellflower',
    'Belmont',
    'Belvedere',
    'Benicia',
    'Berkeley',
    'Berry Creek',
    'Bethel Island',
    'Beverly Hills',
    'Bieber',
    'Big Bear',
    'Big Bear Lake',
    'Big Sur',
    'Biggs',
    'Biola',
    'Birds Landing',
    'Bishop',
    'Blairsden-Graeagle',
    'Bloomington',
    'Blue Canyon',
    'Blue Lake',
    'Blythe',
    'Bodega Bay',
    'Bolinas',
    'Bonita',
    'Bonsall',
    'Boonville',
    'Boron',
    'Borrego Springs',
    'Boulder Creek',
    'Boulevard',
    'Box Springs',
    'Boyle Heights',
    'Bradbury',
    'Bradley',
    'Brawley',
    'Brea',
    'Brentwood',
    'Bridgeport',
    'Brisbane',
    'Brookdale',
    'Brooks',
    'Buellton',
    'Buena Park',
    'Burbank',
    'Burlingame',
    'Burney',
    'Butler',
    'Butte Meadows',
    'Buttonwillow',
    'Byron',
    'Cabazon',
    'Cadiz',
    'Calabasas',
    'Calexico',
    'California City',
    'Calimesa',
    'Calipatria',
    'Calistoga',
    'Calpella',
    'Calwa',
    'Camarillo',
    'Cameron Park',
    'Camino',
    'Camp Pendleton',
    'Campbell',
    'Campo',
    'Camptonville',
    'Canby',
    'Canoga Park',
    'Cantil',
    'Cantua Creek',
    'Canyon',
    'Canyon Country',
    'Canyon Lake',
    'Capay',
    'Capistrano Beach',
    'Capitan',
    'Capitola',
    'Cardiff by the Sea',
    'Caribou',
    'Carlsbad',
    'Carmel',
    'Carmel Valley',
    'Carmel-Monterey Apt',
    'Carmichael',
    'Carnelian Bay',
    'Carpinteria',
    'Carson',
    'Caruthers',
    'Castaic',
    'Castro Valley',
    'Castroville',
    'Catalina Island',
    'Cathedral City',
    'Catheys Valley',
    'Cayucos',
    'Cazadero',
    'Cedar Glen',
    'Cedar Stock',
    'Cedarpines Park',
    'Cedarville',
    'Centerville',
    'Century City',
    'Ceres',
    'Cerritos',
    'Challenge',
    'Charter Oak',
    'Chatsworth',
    'Cherry Valley',
    'Chester',
    'Chestnut',
    'Chico',
    'China Lake',
    'Chinese Camp',
    'Chino',
    'Chino Hills',
    'Chowchilla',
    'Chualar',
    'Chula Vista',
    'Citrus Heights',
    'City of Commerce',
    'City of Industry',
    'Claremont',
    'Clarksburg',
    'Clayton',
    'Clearlake',
    'Clearlake Oaks',
    'Clements',
    'Cloverdale',
    'Clovis',
    'Coachella',
    'Coalinga',
    'Coarsegold',
    'Cobb',
    'Coleville',
    'Colfax',
    'College City',
    'Collegeville',
    'Colma',
    'Coloma',
    'Colton',
    'Columbia',
    'Colusa',
    'Commerce/Los Angeles',
    'Compton',
    'Concord',
    'Cool',
    'Copperopolis',
    'Corcoran',
    'Cordelia',
    'Corning',
    'Corona',
    'Corona del Mar',
    'Coronado',
    'Corralitos',
    'Corte Madera',
    'Costa Mesa',
    'Cotati',
    'Coto de Caza',
    'Cottonwood',
    'Coulterville',
    'Courtland',
    'Covina',
    'Crescent City',
    'Crestline',
    'Creston',
    'Crockett',
    'Cromberg',
    'Crows Landing',
    'Cudahy',
    'Culver City',
    'Cupertino',
    'Cutler',
    'Cypress',
    'Daggett',
    'Daly City',
    'Dana Point',
    'Danville',
    'Davenport',
    'Davis',
    'Death Valley',
    'Deer Park',
    'Del Mar',
    'Del Mar, San Diego',
    'Del Rey',
    'Del Rey Oaks',
    'Delano',
    'Denair',
    'Descanso',
    'Desert Center',
    'Desert Hot Springs',
    'Di Giorgio',
    'Diablo',
    'Diamond Bar',
    'Diamond Springs',
    'Dinuba',
    'Discovery Bay',
    'Dixon',
    'Dobbins',
    'Dominguez',
    'Dominguez Hills',
    'Dos Palos',
    'Downey',
    'Downieville',
    'Doyle',
    'Drytown',
    'Duarte',
    'Dublin',
    'Ducor',
    'Dulzura',
    'Duncans Mills',
    'Dunnigan',
    'Durham',
    'Eagle Rock',
    'Earlimart',
    'East Irvine',
    'East Los Angeles',
    'East Palo Alto',
    'East Rancho Dominguez',
    'East Stockton',
    'Eastvale',
    'Edison',
    'Edwards',
    'El Cajon',
    'El Centro',
    'El Cerrito',
    'El Dorado',
    'El Dorado Hills',
    'El Modena/Orange',
    'El Monte',
    'El Nido',
    'El Portal',
    'El Segundo',
    'El Sereno',
    'El Sobrante',
    'El Toro',
    'Eldridge',
    'Elfin Forest',
    'Elk',
    'Elk Creek',
    'Elk Grove',
    'Ellwood',
    'Elverta',
    'Elwood',
    'Embarcadero/San Francisco',
    'Emeryville',
    'Emigrant Gap',
    'Encinitas',
    'Encino',
    'Escalon',
    'Escondido',
    'Esparto',
    'Esperanza',
    'Essex',
    'Estero Bay',
    'Etiwanda',
    'Etna',
    'Eureka',
    'Exeter',
    'Fair Oaks',
    'Fair Play',
    'Fairfax',
    'Fairfield',
    'Fairmont',
    'Fall River Mills',
    'Fallbrook',
    'Fallon',
    'Farmersville',
    'Farmington',
    'Fawnskin',
    'Fellows',
    'Felton',
    'Ferndale',
    'Fiddletown',
    'Fields Landing',
    'Fillmore',
    'Firebaugh',
    'Fish Camp',
    'Five Points',
    'Florence',
    'Folsom',
    'Fontana',
    'Foothill Farms',
    'Foothill Ranch',
    'Forest Knolls',
    'Forest Ranch',
    'Foresthill',
    'Forestville',
    'Fort Bragg',
    'Fort Hunter Liggett',
    'Fort Irwin',
    'Fort Jones, Siskiyou',
    'Fortuna',
    'Foster City',
    'Fountain Valley',
    'Fowler',
    'Frazier Park',
    'Freedom',
    'Fremont',
    'French Camp',
    'Fresno',
    'Friant',
    'Fullerton',
    'Fulton',
    'Galt',
    'Garberville',
    'Garden Grove',
    'Garden Valley',
    'Gardena',
    'Gasquet',
    'Georgetown',
    'Geyserville',
    'Gilroy',
    'Glen Ellen',
    'Glendale',
    'Glendora',
    'Glenn',
    'Gold River',
    'Gold Run',
    'Goleta',
    'Gonzales',
    'Gorman',
    'Goshen',
    'Granada Hills',
    'Grand Terrace',
    'Granite Bay',
    'Grapevine',
    'Grass Valley',
    'Graton',
    'Green Brae',
    'Greenbrae',
    'Greenfield',
    'Greenville',
    'Greenwood, El Dorado',
    'Grenada',
    'Gridley',
    'Griffith Park',
    'Grimes',
    'Grover Beach',
    'Guadalupe',
    'Guerneville',
    'Gustine',
    'Hacienda Heights',
    'Half Moon Bay',
    'Hamilton City',
    'Hanford',
    'Happy Camp',
    'Harbor City',
    'Hawaiian Gardens',
    'Hawthorne',
    'Hayfork',
    'Hayward',
    'Healdsburg',
    'Heber',
    'Helendale',
    'Helm',
    'Hemet',
    'Hercules',
    'Herlong',
    'Hermosa Beach',
    'Hesperia',
    'Hickman',
    'Hidden Hills',
    'Highgrove',
    'Highland',
    'Highland Park',
    'Highway Highlands',
    'Hillsborough',
    'Hilmar',
    'Hinkley',
    'Hollister',
    'Hollywood',
    'Holt',
    'Holtville',
    'Homeland',
    'Homewood',
    'Hood',
    'Hoopa',
    'Hopland',
    'Hornbrook',
    'Hughson',
    'Huntington Beach',
    'Huntington Park',
    'Huron',
    'Hyde Park',
    'Hydesville',
    'Idyllwild',
    'Igo',
    'Imperial',
    'Imperial Beach',
    'Indian Wells',
    'Indianola',
    'Indio',
    'Industry',
    'Inglewood',
    'Inverness',
    'Inyokern',
    'Ione',
    'Irvine',
    'Irvington',
    'Irwindale',
    'Isleton',
    'Ivanhoe',
    'Jackson',
    'Jamestown',
    'Jamul',
    'Jayhawk',
    'Jolon',
    'Joshua Tree',
    'Julian',
    'Junction City',
    'Jurupa Valley',
    'Kaiser',
    'Kelseyville',
    'Kensington',
    'Kentfield',
    'Kenwood',
    'Kerman',
    'Kernville',
    'Kettleman City',
    'Keyes',
    'King City',
    'Kings Beach',
    'Kingsburg',
    'Kirkwood',
    'Kneeland',
    'Knights Landing',
    'La Cañada-Flintridge',
    'La Crescenta',
    'La Grange',
    'La Habra Heights',
    'La Habra, Orange',
    'La Honda',
    'La Jolla',
    'La Mesa',
    'La Mirada',
    'La Palma',
    'La Porte',
    'La Puente',
    'La Quinta',
    'La Selva Beach',
    'La Verne',
    'Ladera Ranch',
    'Lafayette',
    'Laguna Beach',
    'Laguna Hills',
    'Laguna Niguel',
    'Laguna Woods',
    'Lagunitas',
    'Lake Almanor',
    'Lake Arrowhead',
    'Lake Elsinore',
    'Lake Forest',
    'Lake Isabella',
    'Lake View Terrace',
    'Lakehead',
    'Lakeport',
    'Lakeshore',
    'Lakeside',
    'Lakeview',
    'Lakewood',
    'Lamont',
    'Lancaster',
    'Larkspur',
    'Las Flores',
    'Lathrop',
    'Laton',
    'Latrobe',
    'Lawndale',
    'Laytonville',
    'Le Grand',
    'Lebec',
    'Lemon Grove',
    'Lemoore',
    'Lennox',
    'Leucadia',
    'Lewiston',
    'Lincoln',
    'Linden',
    'Lindsay',
    'Little Lake',
    'Little River',
    'Littlerock',
    'Live Oak',
    'Livermore',
    'Livingston',
    'Llano',
    'Lockeford',
    'Lockwood',
    'Lodi',
    'Loleta',
    'Loma Linda',
    'Lomita',
    'Lompoc',
    'Lone Pine',
    'Long Barn',
    'Long Beach',
    'Longview',
    'Loomis',
    'Los Alamitos',
    'Los Alamos',
    'Los Altos',
    'Los Altos Hills',
    'Los Angeles',
    'Los Banos',
    'Los Gatos',
    'Los Molinos',
    'Los Nietos',
    'Los Olivos',
    'Los Osos',
    'Lost Hills',
    'Lotus',
    'Lower Lake',
    'Loyalton',
    'Lucerne Valley',
    'Lynwood',
    'Lyoth',
    'Macdoel',
    'Mad River',
    'Madera',
    'Magalia',
    'Magnolia Park',
    'Malibu',
    'Mammoth',
    'Mammoth Lakes',
    'Manchester',
    'Manhattan Beach',
    'Manteca',
    'Mar Vista',
    'March Air Reserve Base',
    'Mare Island',
    'Maricopa',
    'Marin City',
    'Marina',
    'Marina del Rey',
    'Mariposa',
    'Markleeville',
    'Marshall',
    'Martinez',
    'Marysville',
    'Mather',
    'Maxwell',
    'Maywood',
    'McClellan',
    'McClellan Air Force Base',
    'McClellan Place',
    'Mccloud',
    'McFarland',
    'McKinleyville',
    'Mckittrick',
    'Mecca',
    'Mendocino',
    'Mendota',
    'Menifee',
    'Menlo Park',
    'Mentone',
    'Merced',
    'Meridian',
    'Mettler',
    'Middletown',
    'Midpines',
    'Midway',
    'Midway City',
    'Mill Valley',
    'Millbrae',
    'Milpitas',
    'Mineral',
    'Mira Loma',
    'Mira Mesa',
    'Miranda',
    'Mission Hills',
    'Mission Valley,San Diego',
    'Mission Viejo',
    'Modesto',
    'Moffett Field',
    'Mojave',
    'Monrovia',
    'Montague',
    'Montara',
    'Montclair',
    'Monte Rio',
    'Montebello',
    'Montecito',
    'Monterey',
    'Monterey Park',
    'Monterey-Carmel Apt',
    'Montrose',
    'Moorpark',
    'Moraga',
    'Moreno Valley',
    'Morgan Hill',
    'Morro Bay',
    'Moss Beach',
    'Moss Landing',
    'Mount Hermon',
    'Mount Shasta',
    'Mount Wilson',
    'Mountain Center',
    'Mountain House',
    'Mountain Pass',
    'Mountain Ranch',
    'Mountain View',
    'Murphys',
    'Murrieta',
    'Myers Flat',
    'Napa',
    'Nashville',
    'National City',
    'Needles',
    'Nelson',
    'Nevada City',
    'New Cuyama',
    'Newark',
    'Newberry Springs',
    'Newbury Park',
    'Newcastle',
    'Newhall',
    'Newman',
    'Newport',
    'Newport Beach',
    'Newport Coast',
    'Nicasio',
    'Nice',
    'Niland',
    'Niles',
    'Nipomo',
    'Nipton',
    'Norco',
    'Norden',
    'North Highlands',
    'North Hills',
    'North Hollywood',
    'North Long Beach',
    'North Palm Springs',
    'North Shore',
    'Northridge',
    'Northstar',
    'Norwalk',
    'Novato',
    'Nuevo',
    'Oak Hills',
    'Oak Park',
    'Oak View',
    'Oakdale',
    'Oakhurst',
    'Oakland',
    'Oakley',
    'Oakville',
    'Oasis',
    'Occidental',
    'Ocean Beach',
    'Oceano',
    'Oceanside',
    'Oildale',
    'Ojai',
    'Olancha',
    'Oleum',
    'Olive',
    'Olivehurst',
    'Olympic Valley',
    'Ontario',
    'Orange',
    'Orange Cove',
    'Orangevale',
    'Orcutt',
    'Ordbend',
    'Oregon House',
    'Orick',
    'Orinda',
    'Orland',
    'Orleans',
    'Oro Grande',
    'Orosi',
    'Oroville',
    'Otay Mesa',
    'Oxnard',
    'Oxnard-Ventura Apt',
    'Pacheco',
    'Pacific Grove',
    'Pacific Palisades',
    'Pacifica',
    'Pacoima',
    'Paicines',
    'Pajaro',
    'Pala',
    'Palm Desert',
    'Palm Springs',
    'Palmdale',
    'Palo Alto',
    'Palo Cedro',
    'Palomar Mountain',
    'Palos Verdes Estates',
    'Panama',
    'Panorama City',
    'Paradise',
    'Paramount',
    'Parkfield',
    'Parlier',
    'Pasadena',
    'Paso Robles',
    'Patterson',
    'Patton',
    'Pauma Valley',
    'Pebble Beach',
    'Pedley',
    'Penn Valley',
    'Penngrove',
    'Perris',
    'Pescadero',
    'Petaluma',
    'Petrolia',
    'Phelan',
    'Philo',
    'Pico Rivera',
    'Piedmont',
    'Piercy',
    'Pine Cove',
    'Pine Grove',
    'Pine Valley',
    'Pinecrest',
    'Pinedale',
    'Pinole',
    'Pinyon Pines',
    'Pioneer, Amador',
    'Piru',
    'Pismo Beach',
    'Pittsburg',
    'Pixley',
    'Placentia',
    'Placerville',
    'Plaster City',
    'Playa del Rey',
    'Playa Vista',
    'Pleasant Grove',
    'Pleasant Hill',
    'Pleasanton',
    'Plymouth',
    'Point Arena',
    'Point Conception',
    'Point Mugu Nawc',
    'Point Reyes Station',
    'Pollock Pines',
    'Pomona',
    'Pope Valley',
    'Port Chicago',
    'Port Costa',
    'Port Hueneme',
    'Port San Luis',
    'Porter Ranch',
    'Porterville',
    'Portola',
    'Portola Valley',
    'Potrero',
    'Potter Valley',
    'Poway',
    'Proberta',
    'Prunedale',
    'Quail Valley',
    'Quartz Hill',
    'Quincy',
    'Ramona',
    'Rancho',
    'Rancho Belago',
    'Rancho Bernardo',
    'Rancho Cordova',
    'Rancho Cucamonga',
    'Rancho Dominguez',
    'Rancho Mirage',
    'Rancho Murieta',
    'Rancho Palos Verdes',
    'Rancho Park',
    'Rancho Santa Fe',
    'Rancho Santa Margarita',
    'Raymond',
    'Red Bluff',
    'Redding',
    'Redlands',
    'Redondo Beach',
    'Redway',
    'Redwood City',
    'Redwood Valley',
    'Reedley',
    'Rescue',
    'Reseda',
    'Rialto',
    'Richgrove',
    'Richmond',
    'Richvale',
    'Ridgecrest',
    'Rio Dell',
    'Rio Linda',
    'Rio Oso',
    'Rio Vista',
    'Ripley',
    'Ripon',
    'Riverbank',
    'Riverdale',
    'Riverside',
    'Robbins',
    'Rocklin',
    'Rodeo',
    'Rohnert Park',
    'Rolling Hills',
    'Rolling Hills Estates',
    'Romoland',
    'Rosamond',
    'Rosemead',
    'Roseville',
    'Ross',
    'Rossmoor',
    'Rowland Heights',
    'Rubidoux',
    'Running Springs',
    'Rutherford',
    'Ryde',
    'Sacramento',
    'Sage',
    'Saint Helena',
    'Saint Louis',
    'Saint Louis/Sierra County',
    'Salida',
    'Salinas',
    'Salton City',
    'Samoa',
    'San Andreas',
    'San Anselmo',
    'San Antonio Heights',
    'San Ardo',
    'San Bernardino',
    'San Bruno',
    'San Carlos',
    'San Clemente',
    'San Diego',
    'San Dimas',
    'San Fernando',
    'San Francisco',
    'San Gabriel',
    'San Geronimo',
    'San Gregorio',
    'San Jacinto',
    'San Joaquin',
    'San Joaquin River',
    'San Jose',
    'San Juan Bautista',
    'San Juan Capistrano',
    'San Leandro',
    'San Lorenzo',
    'San Lucas',
    'San Luis Obispo',
    'San Marcos',
    'San Marino',
    'San Martin',
    'San Mateo',
    'San Miguel',
    'San Pablo',
    'San Pedro',
    'San Quentin',
    'San Rafael',
    'San Ramon',
    'San Ysidro',
    'Sand City',
    'Sanford',
    'Sanger',
    'Santa Ana',
    'Santa Barbara',
    'Santa Clara',
    'Santa Clarita',
    'Santa Cruz',
    'Santa Fe Springs',
    'Santa Margarita',
    'Santa Maria',
    'Santa Monica',
    'Santa Paula',
    'Santa Rosa',
    'Santa Rosa Island',
    'Santa Ynez',
    'Santa Ysabel',
    'Santee',
    'Saratoga',
    'Saticoy',
    'Saugus',
    'Sausalito',
    'Scotia',
    'Scotts Valley',
    'Seal Beach',
    'Searles',
    'Seaside',
    'Sebastopol',
    'Seeley',
    'Selby',
    'Selma',
    'Sepulveda',
    'Shafter',
    'Shafter, Kern',
    'Shandon',
    'Shasta',
    'Shasta Lake',
    'Shaver Lake',
    'Sherman Oaks',
    'Shingle Springs',
    'Shingletown',
    'Shoshone',
    'Sierra Madre',
    'Sierra Vista',
    'Signal Hill',
    'Silverado',
    'Simi Valley',
    'Smith River',
    'Soda Springs',
    'Solana Beach',
    'Soledad',
    'Solvang',
    'Somerset',
    'Somis',
    'Sonoma',
    'Sonora',
    'Soquel',
    'Sorrento',
    'South El Monte',
    'South Gate',
    'South Lake Tahoe',
    'South Pasadena',
    'South San Francisco',
    'Spring Valley, San Diego',
    'Springville',
    'Squaw Valley',
    'Standard',
    'Stanford',
    'Stanton',
    'Stevenson Ranch',
    'Stevinson',
    'Stinson Beach',
    'Stockton',
    'Stonehurst',
    'Storrie',
    'Stratford',
    'Strathmore',
    'Strawberry',
    'Studio City',
    'Suisun City',
    'Sultana',
    'Summerland',
    'Sun City',
    'Sun Valley',
    'Sunland',
    'Sunnyvale',
    'Sunol',
    'Sunset',
    'Sunset Beach',
    'Sunset Whitney Ranch',
    'Susanville',
    'Sutter',
    'Sutter Creek',
    'Sylmar',
    'Taft',
    'Tahoe City',
    'Tahoma',
    'Tarzana',
    'Tecate',
    'Tecopa',
    'Tehachapi',
    'Temecula',
    'Temple City',
    'Templeton',
    'Terminal Island',
    'Terra Bella',
    'Thenard',
    'Thermal',
    'Thornton',
    'Thousand Oaks',
    'Thousand Palms',
    'Three Rivers',
    'Tiburon',
    'Tipton',
    'Toluca Lake',
    'Topanga',
    'Torrance',
    'Trabuco Canyon',
    'Tracy',
    'Traver',
    'Travis Air Force Base',
    'Treasure Island/Los Angeles',
    'Trenton',
    'Tres Pinos',
    'Trinidad',
    'Trona',
    'Truckee',
    'Tujunga',
    'Tulare',
    'Tulelake',
    'Tuolumne',
    'Tupman',
    'Turlock',
    'Tustin',
    'Twentynine Palms',
    'Ukiah',
    'Union City',
    'Universal City',
    'Upland',
    'Vacaville',
    'Valencia',
    'Vallecito',
    'Vallejo',
    'Valley Center',
    'Valley Springs',
    'Valley Village',
    'Van Nuys',
    'Vandenberg Air Force Base',
    'Venice',
    'Ventura',
    'Ventura-Oxnard Apt',
    'Verdugo City',
    'Vermont',
    'Vernalis',
    'Vernon',
    'Verona',
    'Victor',
    'Victorville',
    'Villa Park',
    'Vina',
    'Vineburg',
    'Visalia',
    'Vista',
    'Volcano',
    'Volta',
    'Walker',
    'Wallace',
    'Walnut',
    'Walnut Creek',
    'Walnut Grove',
    'Walsh Station',
    'Warm Springs',
    'Warner Springs',
    'Wasco',
    'Washington',
    'Waterford',
    'Watson',
    'Watsonville',
    'Watts',
    'Weaverville',
    'Weed',
    'Weldon',
    'Weott',
    'West Covina',
    'West Hills',
    'West Hollywood',
    'West Los Angeles',
    'West Sacramento',
    'Westchester',
    'Westlake Village',
    'Westley',
    'Westminster',
    'Westport',
    'Westwood',
    'Westwood Village',
    'Whitewater',
    'Whittier',
    'Wildomar',
    'Williams',
    'Willits',
    'Willow Creek',
    'Willowbrook',
    'Willows',
    'Wilmington',
    'Wilton',
    'Winchester',
    'Windsor',
    'Winnetka',
    'Winterhaven',
    'Winters',
    'Winton',
    'Woodacre',
    'Woodbridge',
    'Woodlake',
    'Woodland',
    'Woodland Hills',
    'Woodside',
    'Wrightwood',
    'Yermo',
    'Yolo',
    'Yorba Linda',
    'Yorkville',
    'Yosemite Ntl Park',
    'Yountville',
    'Yreka',
    'Yuba City',
    'Yucaipa',
    'Yucca Valley',
    'Zamora',
    'SF'
  ],
  CO: [
    'Adams City',
    'Akron',
    'Alamosa',
    'Alma',
    'Antonito',
    'Arapahoe',
    'Arvada',
    'Aspen',
    'Ault',
    'Aurora',
    'Austin',
    'Avon',
    'Avondale',
    'Bailey',
    'Basalt',
    'Bayfield',
    'Beaver Creek',
    'Bellvue',
    'Bennett',
    'Berthoud',
    'Black Hawk',
    'Blanca',
    'Boone',
    'Boulder',
    'Breckenridge',
    'Bridgeport',
    'Brighton',
    'Bristol',
    'Brookfield',
    'Broomfield',
    'Brush',
    'Buena Vista',
    'Burlington',
    'Calhan',
    'Canon City',
    'Capulin',
    'Carbondale',
    'Cascade',
    'Castle Pines',
    'Castle Rock',
    'Cedaredge',
    'Centennial',
    'Center',
    'Central City',
    'Cherry Hills Village',
    'Cheyenne Wells',
    'Cimarron',
    'Clark',
    'Clifton, Mesa',
    'Climax',
    'Colorado City',
    'Colorado Springs',
    'Commerce City',
    'Conifer',
    'Cope',
    'Copper Mountain',
    'Cortez',
    'Craig',
    'Crawford',
    'Crested Butte',
    'Crestone',
    'Cripple Creek',
    'Dacono',
    'De Beque',
    'Del Norte',
    'Delta',
    'Denver',
    'Dillon',
    'Divide',
    'Dolores',
    'Dove Creek',
    'Dupont',
    'Durango',
    'Eagle',
    'Eaton',
    'Edgewater',
    'Edwards',
    'Egnar',
    'Elbert',
    'Elizabeth',
    'Englewood',
    'Erie',
    'Estes Park',
    'Evans',
    'Evergreen',
    'Fair Play',
    'Fairplay',
    'Federal Heights',
    'Firestone',
    'Flagler',
    'Florence',
    'Fort Carson',
    'Fort Collins',
    'Fort Garland',
    'Fort Lupton',
    'Fort Morgan',
    'Fountain',
    'Franktown',
    'Fraser',
    'Frederick',
    'Frisco',
    'Fruita',
    'Gardner',
    'Gateway',
    'Genoa',
    'Gilcrest',
    'Gill',
    'Glade Park',
    'Glendale',
    'Glenwood Springs',
    'Golden',
    'Granby',
    'Grand Junction',
    'Grand Lake',
    'Granite',
    'Greeley',
    'Greenwood Village',
    'Grover',
    'Gunnison',
    'Gypsum',
    'Hartsel',
    'Haxtun',
    'Hayden',
    'Henderson',
    'Hesperus',
    'Highlands Ranch',
    'Hillside',
    'Holly',
    'Holyoke',
    'Hooper',
    'Hotchkiss',
    'Hudson',
    'Hugo',
    'Idaho Springs',
    'Idalia',
    'Ignacio',
    'Irondale',
    'Jamestown',
    'Johnstown',
    'Keenesburg',
    'Keystone',
    'Kiowa',
    'Kremmling',
    'La Junta',
    'La Salle',
    'Lafayette',
    'Lake George',
    'Lakewood',
    'Lamar',
    'Laporte',
    'Larkspur',
    'Leadville',
    'Limon',
    'Littleton',
    'Loan Tree',
    'Lone Tree',
    'Longmont',
    'Louisville',
    'Loveland',
    'Lucerne',
    'Lyons',
    'Maher',
    'Manassa',
    'Mancos',
    'Manitou Springs',
    'Manzanola',
    'Marble',
    'McClave',
    'McCoy',
    'Mead',
    'Meeker',
    'Merino',
    'Mesa',
    'Milliken',
    'Minturn',
    'Moffat',
    'Monte Vista',
    'Montrose',
    'Monument',
    'Morrison',
    'Nathrop',
    'Nederland',
    'New Castle',
    'Niwot',
    'Northglenn',
    'Nucla',
    'Nunn',
    'Oak Creek',
    'Ohio City',
    'Olathe',
    'Otis',
    'Ouray',
    'Pagosa Springs',
    'Palisade',
    'Palmer Lake',
    'Paonia',
    'Parachute',
    'Parker',
    'Parshall',
    'Peetz',
    'Penrose',
    'Peyton',
    'Pine',
    'Platteville',
    'Poncha Springs',
    'Pueblo',
    'Rangely',
    'Red Feather Lakes',
    'Redstone',
    'Redvale',
    'Rico',
    'Ridgeway',
    'Ridgway',
    'Rifle',
    'Rio Blanco',
    'Rocky Ford',
    'Roggen',
    'Salida',
    'San Acacio',
    'San Luis',
    'Sanford',
    'Security',
    'Sedalia',
    'Severance',
    'Sheridan',
    'Silt',
    'Silver Plume',
    'Silverthorne',
    'Silverton',
    'Slater',
    'Snowmass',
    'Snowmass Village',
    'Somerset',
    'Steamboat Springs',
    'Sterling',
    'Superior',
    'Tabernash',
    'Telluride',
    'Thornton',
    'Thorp',
    'Timnath',
    'Trinidad',
    'Vail',
    'Vernon',
    'Victor',
    'Villa Grove',
    'Walden',
    'Walsh',
    'Ward',
    'Watkins',
    'Weldona',
    'Wellington',
    'Westcliffe',
    'Westminster',
    'Wheat Ridge',
    'Whitewater',
    'Wiggins',
    'Windsor',
    'Winter Park',
    'Woodland Park',
    'Wray',
    'Yuma'
  ],
  CT: [
    'Andover',
    'Ansonia',
    'Ashford',
    'Avon',
    'Ballouville',
    'Baltic',
    'Bantam',
    'Barkhamsted',
    'Bayview',
    'Beacon Falls',
    'Berlin',
    'Bethany',
    'Bethel',
    'Bethlehem',
    'Bloomfield',
    'Bolton',
    'Botsford',
    'Bozrah',
    'Branford',
    'Bridgeport',
    'Bridgewater',
    'Bristol',
    'Broad Brook',
    'Brookfield',
    'Brooklyn',
    'Burlington',
    'Byram',
    'Canaan',
    'Canton',
    'Centerbrook',
    'Chaplin',
    'Cheshire',
    'Chester',
    'Clinton',
    'Colchester',
    'Colebrook',
    'Collinsville',
    'Columbia',
    'Cornwall',
    'Cos Cob',
    'Coventry',
    'Cromwell',
    'Danbury',
    'Danielson',
    'Darien',
    'Dayville',
    'Deep River',
    'Derby',
    'Durham',
    'East Berlin',
    'East Bridgeport',
    'East Canaan',
    'East Granby',
    'East Haddam',
    'East Hampton',
    'East Hartford',
    'East Haven',
    'East Lyme',
    'East Norwalk',
    'East Windsor',
    'East Woodstock',
    'Eastford',
    'Easton',
    'Ellington',
    'Elmwood',
    'Enfield',
    'Essex',
    'Fabyan',
    'Fairfield',
    'Falls Village',
    'Farmington',
    'Fitchville',
    'Forestville',
    'Gales Ferry',
    'Gaylordsville',
    'Georgetown, Fairfield',
    'Gilman',
    'Glastonbury',
    'Glenbrook',
    'Goshen',
    'Granby',
    'Greenwich',
    'Griswold',
    'Groton',
    'Groton-New London Apt',
    'Guilford',
    'Haddam',
    'Hamden',
    'Hartford',
    'Hebron',
    'Higganum',
    'Huntington',
    'Ivoryton',
    'Jewett City',
    'Kensington',
    'Kent',
    'Killingly',
    'Killingworth',
    'Lakeville',
    'Lebanon',
    'Ledyard Center',
    'Lisbon',
    'Litchfield',
    'Lyme',
    'Madison',
    'Manchester',
    'Mansfield Center',
    'Marlborough',
    'Mashantucket',
    'Meriden',
    'Middlebury',
    'Middlefield',
    'Middletown',
    'Milford',
    'Milldale',
    'Monroe',
    'Montville',
    'Moodus',
    'Moosup',
    'Morris',
    'Mystic',
    'Naugatuck',
    'New Britain',
    'New Canaan',
    'New Fairfield',
    'New Hartford',
    'New Haven',
    'New London',
    'New London-Groton Apt',
    'New Milford',
    'New Preston',
    'Newington',
    'Newtown',
    'Niantic',
    'Noank',
    'Norfolk',
    'North Branford',
    'North Canaan',
    'North Canton',
    'North Franklin',
    'North Granby',
    'North Grosvenor Dale',
    'North Haven',
    'North Stonington',
    'North Windham',
    'Northford',
    'Norwalk',
    'Norwich',
    'Oakdale',
    'Oakville',
    'Old Greenwich',
    'Old Lyme',
    'Old Saybrook',
    'Orange',
    'Oxford',
    'Pawcatuck',
    'Plainfield',
    'Plainville',
    'Plantsville',
    'Plymouth',
    'Pomfret',
    'Pomfret Center',
    'Poquonock',
    'Portland',
    'Preston',
    'Prospect',
    'Putnam',
    'Putnam Heights',
    'Redding',
    'Redding Ridge',
    'Ridgefield',
    'Riverside',
    'Rockfall',
    'Rockville',
    'Rocky Hill',
    'Rogers',
    'Roxbury',
    'Salem',
    'Salisbury',
    'Sandy Hook',
    'Saybrook',
    'Seymour',
    'Sharon',
    'Shelton',
    'Sherman',
    'Simsbury',
    'Somers',
    'Somersville',
    'South Glastonbury',
    'South Kent',
    'South Norwalk',
    'South Windham',
    'South Windsor',
    'Southbury',
    'Southington',
    'Southport',
    'Stafford',
    'Stafford Springs',
    'Stamford',
    'Sterling',
    'Stonington',
    'Storrs',
    'Stratford',
    'Suffield',
    'Taftville',
    'Tariffville',
    'Terryville',
    'Thomaston',
    'Thompson',
    'Tolland',
    'Torrington',
    'Trumbull',
    'Uncasville',
    'Unionville',
    'Vernon',
    'Versailles',
    'Voluntown',
    'Wallingford',
    'Warehouse Point',
    'Warren',
    'Washington',
    'Waterbury',
    'Waterford',
    'Watertown',
    'Wauregan',
    'West Hartford',
    'West Haven',
    'West Mystic',
    'West Redding',
    'Westbrook',
    'Weston',
    'Westport',
    'Wethersfield',
    'Willimantic',
    'Willington',
    'Wilton',
    'Winchester Center',
    'Windham',
    'Windsor',
    'Windsor Locks',
    'Windsor Locks-Hartford Apt',
    'Winsted',
    'Wolcott',
    'Woodbridge',
    'Woodbury',
    'Woodstock',
    'Woodstock Valley',
    'Yalesville',
    'Yantic'
  ],
  DC: ['Anacostia', 'National Apt/Washington', 'Washington', 'Woodridge'],
  DE: [
    'Atlas Point',
    'Bear',
    'Bellefonte',
    'Bethany Beach',
    'Big Stone',
    'Bridgeville',
    'Camden',
    'Cape Henlopen',
    'Cheswold',
    'Christiana',
    'Claymont',
    'Clayton',
    'Dagsboro',
    'Delaware City',
    'Delmar',
    'Dover',
    'Dover-Cheswold',
    'Edgemoor',
    'Elsmere',
    'Felton',
    'Fenwick Island',
    'Frankford',
    'Frederica',
    'Georgetown',
    'Glasgow',
    'Greenville',
    'Greenwood',
    'Harbeson',
    'Harrington',
    'Hartly',
    'Hockessin',
    'Kirkwood',
    'Lancashire',
    'Laurel',
    'Lewes',
    'Marshallton',
    'Middletown',
    'Milford',
    'Millsboro',
    'Milton',
    'New Castle',
    'Newark',
    'Newport',
    'Ocean View',
    'Odessa',
    'Port Penn',
    'Rehoboth Beach',
    'Rogers Haven',
    'Seaford',
    'Selbyville',
    'Slaughter Beach',
    'Smyrna',
    'Stanton',
    'Talleyville',
    'Townsend',
    'Wilmington',
    'Winterthur',
    'Woodside',
    'Wyoming',
    'Yorklyn'
  ],
  FL: [
    'Alachua',
    'Altamonte Springs',
    'Altha',
    'Altoona',
    'Alva',
    'Alys Beach',
    'Amelia City',
    'Amelia Island',
    'Anna Maria',
    'Anthony',
    'Apalachicola',
    'Apollo Beach',
    'Apopka',
    'Arcadia',
    'Astatula',
    'Atlantic Beach',
    'Auburndale',
    'Aventura',
    'Avon Park',
    'Azalea Park',
    'Baker',
    'Bal Harbour',
    'Baldwin',
    'Bartow',
    'Bay Harbor Islands',
    'Bay Lake',
    'Bay Pines',
    'Bayonet Point',
    'Bean City',
    'Bell',
    'Belle Glade',
    'Belleview',
    'Belmont',
    'Beverly Hills',
    'Big Pine Key',
    'Biscayne Park',
    'Blount Island',
    'Blountstown',
    'Boca Grande',
    'Boca Raton',
    'Bokeelia',
    'Bonifay',
    'Bonita Springs',
    'Bostwick',
    'Bowling Green',
    'Boynton Beach',
    'Bradenton',
    'Bradenton Beach',
    'Bradenton-Sarasota Apt',
    'Bradley',
    'Brandon',
    'Branford',
    'Bristol',
    'Brooksville',
    'Brownsville',
    'Bunnell',
    'Bushnell',
    'Callahan',
    'Callaway',
    'Campbellton',
    'Canal Point',
    'Candler',
    'Cantonment',
    'Cape Canaveral',
    'Cape Coral',
    'Captiva',
    'Carol City',
    'Carrabelle',
    'Casselberry',
    'Cedar Key',
    'Celebration',
    'Center Hill',
    'Champions Gate',
    'Chattahoochee',
    'Chiefland',
    'Chipley',
    'Citra',
    'Citrus Springs',
    'Clair Mel City',
    'Clearwater',
    'Clearwater Beach',
    'Clermont',
    'Clewiston',
    'Cocoa',
    'Cocoa Beach',
    'Coconut Creek',
    'Coconut Grove',
    'Cooper City',
    'Coral Gables',
    'Coral Springs',
    'Coral Way Village/Miami',
    'Cortez',
    'Cottondale',
    'Crawfordville',
    'Crescent City',
    'Crestview',
    'Cross City',
    'Crystal Beach',
    'Crystal River',
    'Cudjoe Key',
    'Cutler Bay',
    'Cutler Ridge',
    'Cypress',
    'Dade City',
    'Dania Beach',
    'Davenport',
    'Davie',
    'Daytona Beach',
    'De Funiak Springs',
    'De Land',
    'De Leon Springs',
    'DeBary',
    'Deerfield Beach',
    'Deerpark',
    'Deland',
    'Delray Beach',
    'Deltona',
    'Denaud',
    'Destin',
    'Doral',
    'Dover',
    'Dundee',
    'Dunedin',
    'Dunnellon',
    'Durant',
    'Eagle Lake',
    'Eastpoint',
    'Eaton Park',
    'Ebro',
    'Edgar',
    'Edgewater',
    'Eglin Air Force Base',
    'Elkton',
    'Ellenton',
    'Eloise',
    'Englewood',
    'Estero',
    'Eustis',
    'Everglades City',
    'Felda',
    'Fellsmere',
    'Fernandina Beach',
    'Fisher Island, Miami',
    'Flagler Beach',
    'Fleming Island',
    'Floral City',
    'Florida City',
    'Foley',
    'Fort Drum',
    'Fort Jefferson',
    'Fort Lauderdale',
    'Fort McCoy',
    'Fort Meade',
    'Fort Myers',
    'Fort Myers Beach',
    'Fort Pierce',
    'Fort Walton Beach',
    'Fort White',
    'Freeport',
    'Frostproof',
    'Fruitland Park',
    'Gainesville',
    'Geneva',
    'Georgetown',
    'Gibsonton',
    'Golden Beach',
    'Gonzalez',
    'Goulds',
    'Graceville',
    'Grand Island',
    'Green Cove Springs',
    'Greenacres',
    'Greensboro',
    'Greenville',
    'Greenwood',
    'Groveland',
    'Gulf Breeze',
    'Gulfport',
    'Haines City',
    'Hallandale',
    'Hampton',
    'Hastings',
    'Havana',
    'Hawthorne',
    'Heathrow',
    'Hernando',
    'Hialeah',
    'Hialeah Gardens',
    'High Springs',
    'Highland City',
    'Hilliard',
    'Hillsboro Beach',
    'Hobe Sound',
    'Holiday',
    'Hollister',
    'Holly Hill',
    'Hollywood',
    'Holt',
    'Homestead',
    'Homosassa',
    'Hosford',
    'Houston',
    'Howey In The Hills',
    'Hudson',
    'Hunters Creek',
    'Hurlburt Field',
    'Hypoluxo',
    'Immokalee',
    'Indialantic',
    'Indian Harbour Beach',
    'Indian Lake Estates',
    'Indian River City',
    'Indian Rocks Beach',
    'Indianola',
    'Indiantown',
    'Inglis',
    'Intercession City',
    'Inverness',
    'Islamorada',
    'Jacksonville',
    'Jacksonville Beach',
    'Jasper',
    'Jay',
    'Jennings',
    'Jensen Beach',
    'John F Kennedy Space Center/Cape Canaveral',
    'Juno Beach',
    'Jupiter',
    'Kathleen',
    'Kenansville',
    'Kendall',
    'Key Biscayne',
    'Key Largo',
    'Key West',
    'Kings Bay',
    'Kissimmee',
    'La Belle',
    'La Crosse',
    'Lady Lake',
    'Lake Alfred',
    'Lake Buena Vista',
    'Lake City',
    'Lake Garfield',
    'Lake Hamilton',
    'Lake Harbor',
    'Lake Mary',
    'Lake Monroe',
    'Lake Panasoffkee',
    'Lake Park',
    'Lake Placid',
    'Lake Suzy',
    'Lake Wales',
    'Lake Worth',
    'Lakeland',
    'Lakeview',
    'Lakewood',
    'Lakewood Ranch',
    "Land O'Lakes",
    'Lantana',
    'Largo',
    'Lauderdale By The Sea',
    'Lauderdale Lakes',
    'Lauderhill',
    'Lecanto',
    'Lee',
    'Leesburg',
    'Lehigh Acres',
    'Lemon City',
    'Lighthouse Point',
    'Lithia',
    'Little Torch Key',
    'Live Oak',
    'Longboat Key',
    'Longwood',
    'Lorida',
    'Loxahatchee',
    'Lutz',
    'Lynn Haven',
    'Macclenny',
    'MacDill AFB',
    'Madeira Beach',
    'Madison',
    'Maitland',
    'Malabar',
    'Manalapan',
    'Manatee',
    'Mandarin',
    'Marathon',
    'Marco Island',
    'Margate',
    'Marianna',
    'Marietta',
    'Mary Esther',
    'Masaryktown',
    'Mascotte',
    'Mayo',
    'Mayport',
    'McAlpin',
    'Medley',
    'Melbourne',
    'Melbourne Beach',
    'Memphis',
    'Merritt Island',
    'Mexico Beach',
    'Miami',
    'Miami Beach',
    'Miami Downtown Heliport Airport',
    'Miami Gardens',
    'Miami Lakes',
    'Miami River',
    'Miami Shores',
    'Miami Springs',
    'Micanopy',
    'Middleburg',
    'Midway',
    'Miles City',
    'Milton',
    'Mims',
    'Minneola',
    'Miramar',
    'Miramar Beach',
    'Monticello',
    'Moore Haven',
    'Morriston',
    'Mount Dora',
    'Mount Pleasant',
    'Mulberry',
    'Myakka City',
    'Myrtle Grove',
    'Naples',
    'Naranja',
    'Nas Pensacola',
    'National Gardens',
    'Navarre',
    'Neptune Beach',
    'New Port Richey',
    'New Smyrna Beach',
    'Newberry',
    'Niceville',
    'Nokomis',
    'North Bay Village',
    'North Fort Myers',
    'North Lauderdale',
    'North Miami',
    'North Miami Beach',
    'North Palm Beach',
    'North Port',
    'Oak Hill',
    'Oakland',
    'Oakland Park, Broward',
    "O'brien",
    'Ocala',
    'Ocean Reef',
    'Oceanway',
    'Ochopee',
    'Ocoee',
    'Odessa',
    'Okahumpka',
    'Okeechobee',
    'Okeelanta',
    'Oklawaha',
    'Oldsmar',
    'Olustee',
    'Ona',
    'Oneco',
    'Opa Locka',
    'Orange',
    'Orange City',
    'Orange Park',
    'Orlando',
    'Ormond Beach',
    'Osteen',
    'Oviedo',
    'Oxford',
    'Pace',
    'Pahokee',
    'Paisley',
    'Palatka',
    'Palm Bay',
    'Palm Beach',
    'Palm Beach Gardens',
    'Palm City',
    'Palm Coast',
    'Palm Harbor',
    'Palm Springs',
    'Palmdale',
    'Palmetto',
    'Palmetto Bay',
    'Panama City',
    'Panama City Beach',
    'Parish',
    'Parkland',
    'Parrish',
    'Paxton',
    'Pembroke',
    'Pembroke Park',
    'Pembroke Pines',
    'Pensacola',
    'Pensacola Beach',
    'Perrine',
    'Perry',
    'Pierson',
    'Pineland',
    'Pinellas Park',
    'Placida',
    'Plant City',
    'Plantation',
    'Plymouth',
    'Poinciana',
    'Polk City',
    'Pompano Beach',
    'Ponce de Leon',
    'Ponte Vedra',
    'Ponte Vedra Beach',
    'Port Canaveral',
    'Port Charlotte',
    'Port Everglades',
    'Port Laudania',
    'Port Manatee',
    'Port of Palm Beach',
    'Port Orange',
    'Port Richey',
    'Port Saint Joe',
    'Port Saint Lucie',
    'Port Tampa',
    'Princeton',
    'Punta Gorda',
    'Quincy',
    'Raiford',
    'Reddick',
    'Reunion',
    'Rivera Beach',
    'Riverview',
    'Riviera Beach',
    'Rockledge',
    'Rosemary Beach',
    'Rotunda',
    'Royal Palm Beach',
    'Rubonia',
    'Ruskin',
    'Safety Harbor',
    'Saint Augustine',
    'Saint Cloud',
    'Saint James City',
    'Saint Johns',
    'Saint Joseph',
    'Saint Lucie',
    'Saint Marks',
    'Saint Pete Beach',
    'Saint Petersburg',
    'San Antonio',
    'Sand Lake',
    'Sanderson',
    'Sanford',
    'Sanibel',
    'Santa Rosa Beach',
    'Sarasota',
    'Sarasota-Bradenton Apt',
    'Satellite Beach',
    'Sebastian',
    'Sebring',
    'Seffner',
    'Seminole',
    'Shalimar',
    'Sharpes',
    'Silver Springs',
    'Singer Island',
    'Sneads',
    'Sorrento',
    'South Bay',
    'South Daytona',
    'South Gate',
    'South Miami',
    'South Pierce',
    'Southwest Ranches',
    'Spring Hill',
    'Starke',
    'Stuart',
    'Summerfield',
    'Summerland Key',
    'Sumterville',
    'Sun City',
    'Sunny Isles',
    'Sunrise',
    'Surfside',
    'Suwannee',
    'Sweetwater',
    'Taft, Orange',
    'Tallahassee',
    'Talleyrand',
    'Tamarac',
    'Tampa',
    'Tarpon Spings',
    'Tarpon Springs',
    'Tavares',
    'Tavernier',
    'Telogia',
    'Temple Terrace',
    'Tequesta',
    'The Villages',
    'Thonotosassa',
    'Tierra Verde',
    'Titusville',
    'Treasure Island',
    'Trenton',
    'Tyndall AFB',
    'Umatilla',
    'University Park',
    'Valparaiso',
    'Valrico',
    'Venice',
    'Venus',
    'Vero Beach',
    'Viera',
    'Wabasso',
    'Waldo',
    'Walnut Hill',
    'Wauchula',
    'Webster',
    'Wellborn',
    'Wellington',
    'Wesley Chapel',
    'West Melbourne',
    'West Miami',
    'West Palm Beach',
    'West Park',
    'Weston',
    'Wewahitchka',
    'White Springs',
    'Whitehouse',
    'Wildwood',
    'Williston',
    'Wilton Manors',
    'Wimauma',
    'Windermere',
    'Winter Garden',
    'Winter Haven',
    'Winter Park',
    'Winter Springs',
    'Worthington Springs',
    'Yulee',
    'Zellwood',
    'Zephyrhills',
    'Zolfo Springs',
    'Opa-locka',
    'Three Lakes',
    'West Little River',
    'Ives Estates',
    'Glenvar Heights',
    'Fontainebleau',
    'The Crossings',
    'Country Club',
    'Palmetto Estates',
    'Olympia Heights',
    'Golden Glades',
    'Coral Terrace',
    'Westchester',
    'Pinewood',
    'The Hammocks',
    'Gladeview',
    'Sunny Isles Beach',
    'Richmond West',
    'Kendale Lakes',
    'Country Walk',
    'Belle Isle',
    'Westwood Lakes',
    'West Perrine',
    'Ojus',
    'Kendall West',
    'Westview',
    'Tamiami',
    'South Miami Heights',
    'Roosevelt Gardens',
    'Sunset',
    'Hallandale Beach',
    'Williamsburg',
    'El Portal',
    'Pinecrest',
    'Virginia Gardens',
    'Duck Key',
    'St. Augustine',
    'Port St. Lucie',
    'Oakland Park',
    '',
    'Holmes Beach',
    'Babson Park',
    'DeLand',
    'St. Petersburg',
    'Palm Beach Shores',
    "Town 'N' Country",
    'Stock Island',
    'Pine Hills',
    'Layton',
    'Cypress Gardens',
    'Mt Dora',
    'LaBelle',
    'Quail Heights',
    'Westchase',
    'Defuniak Springs',
    'Richmond Heights',
    'Daytona Beach Shores'
  ],
  GA: [
    'Aaron',
    'Ackerman',
    'Acworth',
    'Adairsville',
    'Adel',
    'Ailey',
    'Albany',
    'Alma',
    'Alpharetta',
    'Alto',
    'Alvaton',
    'Ambrose',
    'Americus',
    'Anderson City',
    'Andersonville',
    'Appling',
    'Arabi',
    'Aragon',
    'Arlington',
    'Armuchee',
    'Ashburn',
    'Athens',
    'Atlanta',
    'Attapulgus',
    'Auburn',
    'Augusta',
    'Austell',
    'Avalon',
    'Axson',
    'Ayersville',
    'Baconton',
    'Bainbridge',
    'Baldwin',
    'Ball Ground',
    'Barnesville',
    'Bartow',
    'Barwick',
    'Baxley',
    'Bay Meadows',
    'Benton',
    'Berkeley Lake',
    'Bethlehem',
    'Birmingham Road Estates',
    'Bishop',
    'Black Creek',
    'Blackshear',
    'Blackshear, Pierce',
    'Blaine',
    'Blairesville',
    'Blairsville',
    'Blakely',
    'Bloomingdale',
    'Blue Ridge',
    'Bluffton',
    'Bogart',
    'Bonaire',
    'Boneville',
    'Boston',
    'Bowdon',
    'Bowersville',
    'Bowman',
    'Box Springs',
    'Boykin',
    'Braselton',
    'Bremen',
    'Bronwood',
    'Brookfield',
    'Brookhaven',
    'Brooklet',
    'Brooks',
    'Brunswick',
    'Buckhead',
    'Buena Vista',
    'Buford',
    'Butler',
    'Byromville',
    'Byron',
    'Cairo',
    'Calhoun',
    'Callaway Gardens',
    'Camak',
    'Camilla',
    'Canon',
    'Canton',
    'Carlton',
    'Carnesville',
    'Carrollton',
    'Cartersville',
    'Cataula',
    'Cave Spring',
    'Cedar Springs',
    'Cedartown',
    'Centerville, Talbot',
    'Centreville',
    'Chamblee',
    'Chatsworth',
    'Chattahoochee',
    'Chattahoochee Hills',
    'Cherry Log',
    'Chester',
    'Chickamauga',
    'Clarkesville',
    'Clarkston',
    'Claxton',
    'Clayton',
    'Clermont',
    'Cleveland',
    'Climax',
    'Clinchfield',
    'Clyattville',
    'Clyo',
    'Cochran',
    'Cohutta',
    'Colbert',
    'College Park',
    'Collins',
    'Colquitt',
    'Columbus',
    'Comer',
    'Commerce',
    'Concord',
    'Conley',
    'Conyers',
    'Coolidge',
    'Coosa',
    'Cordele',
    'Cornelia',
    'Covington',
    'Crawfordville',
    'Crescent',
    'Crossroads',
    'Cumming',
    'Cuthbert',
    'Dacula',
    'Dahlonega',
    'Dallas',
    'Dalton',
    'Damascus',
    'Danielsville',
    'Danville',
    'Darien',
    'Dawson',
    'Dawsonville',
    'Dearing',
    'Decatur',
    'Demorest',
    'Denton',
    'Dewy Rose',
    'Dillon',
    'Dixie',
    'Doerun',
    'Donalsonville',
    'Doraville',
    'Douglas',
    'Douglasville',
    'Dover',
    'Dry Branch',
    'Dublin',
    'Duluth',
    'Dunwoody',
    'East Dublin',
    'East Point',
    'Eastanollee',
    'Eastman',
    'Eatonton',
    'Eden',
    'Edison',
    'Elberton',
    'Elko',
    'Ellabell',
    'Ellaville',
    'Ellenwood',
    'Ellijay',
    'Emerson',
    'Enigma',
    'Eton',
    'Euharlee',
    'Evans',
    'Fairburn',
    'Fairfield',
    'Fairmount',
    'Fargo',
    'Fayetteville',
    'Fitzgerald',
    'Fleming',
    'Flemington',
    'Flintstone',
    'Flowery Branch',
    'Folkston',
    'Forest Park',
    'Forsyth',
    'Fort Benning',
    'Fort Gaines',
    'Fort Gillem',
    'Fort Gordon',
    'Fort McPherson',
    'Fort Oglethorpe',
    'Fort Stewart',
    'Fort Valley',
    'Franklin',
    'Franklin Springs',
    'Funston',
    'Gainesville',
    'Garden City',
    'Gay',
    'Georgetown',
    'Gibson',
    'Gillsville',
    'Girard',
    'Glennville',
    'Glenwood',
    'Glynco',
    'Gordon',
    'Graham',
    'Grantville',
    'Gray',
    'Grayson',
    'Greensboro',
    'Greenville',
    'Greenwood, Henry',
    'Griffin',
    'Grovetown',
    'Haddock',
    'Hahira',
    'Hamilton',
    'Hampton',
    'Hapeville',
    'Harlem',
    'Harrison',
    'Hartwell',
    'Hawkinsville',
    'Hazlehurst',
    'Helen',
    'Hephzibah',
    'Hinesville',
    'Hiram',
    'Hoboken',
    'Hogansville',
    'Holy Springs',
    'Homer',
    'Homerville',
    'Hoschton',
    'Huber',
    'Hull',
    'Iron City',
    'Irwinton',
    'Jackson',
    'Jacksonville, Telfair',
    'Jasper',
    'Jefferson',
    'Jefferson, Jackson',
    'Jeffersonville',
    'Jekyll Island',
    'Jenkinsburg',
    'Jesup',
    'Johns Creek',
    'Jonesboro',
    'Juliette',
    'Junction City',
    'Kathleen',
    'Kennesaw',
    'Kensington',
    'Kings Bay',
    'Kingsland',
    'Kingston',
    'Kite',
    'Knoxville',
    'Lafayette',
    'Lagrange',
    'LaGrange',
    'Lake City',
    'Lake Park',
    'Lakeland',
    'Lakeview',
    'Lakewood',
    'Lanett',
    'Lanier',
    'Lavonia',
    'Lawrenceville',
    'Leesburg',
    'Leslie',
    'Lexington',
    'Lilburn',
    'Lilly',
    'Lincolnton',
    'Lindale',
    'Lithia Springs',
    'Lithonia',
    'Locust Grove',
    'Loganville',
    'Lookout Mountain',
    'Louisville',
    'Lovejoy',
    'Lula',
    'Lumber City',
    'Lumpkin',
    'Luthersville',
    'Lyerly',
    'Lyons',
    'Mableton',
    'Machen',
    'Macon',
    'Madison',
    'Manassas',
    'Manchester',
    'Manor',
    'Mansfield',
    'Marble Hill',
    'Marietta',
    'Marshallville',
    'Martinez',
    'Maysville',
    'McDonough',
    'McIntosh',
    'McIntyre',
    'McRae',
    'Meansville',
    'Meigs',
    'Menlo',
    'Mershon',
    'Metter',
    'Midland',
    'Midville',
    'Midway',
    'Midway, Liberty',
    'Milan',
    'Milledgeville',
    'Millen',
    'Milner',
    'Milton',
    'Mineral Bluff',
    'Molena',
    'Monroe',
    'Montezuma',
    'Monticello',
    'Morgan',
    'Morganton',
    'Morrow',
    'Moultrie',
    'Mount Airy',
    'Mount Berry',
    'Mountain City',
    'Mountain View',
    'Mountville',
    'Murrayville',
    'Nahunta',
    'Nashville',
    'Nelson',
    'Newborn',
    'Newnan',
    'Newton',
    'Nicholls',
    'Norcross',
    'Norman Park',
    'Oakwood',
    'Ochlocknee',
    'Ocilla',
    'Oglethorpe',
    'Old Rock Hill',
    'Omega',
    'Oxford',
    'Pabst',
    'Palmetto',
    'Patterson',
    'Pavo',
    'Peachtree City',
    'Peachtree Corners',
    'Pearson',
    'Pelham',
    'Pembroke',
    'Pendergrass',
    'Perry',
    'Pine Mountain',
    'Pine Mountain Valley',
    'Pinehurst',
    'Pitts',
    'Plains',
    'Plainville',
    'Pooler',
    'Port Wentworth',
    'Portal',
    'Porterdale',
    'Poulan',
    'Powder Springs',
    'Preston',
    'Quitman',
    'Rabun Gap',
    'Ranger',
    'Ray City',
    'Rayle',
    'Raymond',
    'Rebecca',
    'Redan',
    'Register',
    'Reidsville',
    'Rentz',
    'Resaca',
    'Rex',
    'Reynolds',
    'Rhine',
    'Riceboro',
    'Richland',
    'Richmond Hill',
    'Rincon',
    'Ringgold',
    'Rising Fawn',
    'Riverdale',
    'Roberta',
    'Robins Air Force Base',
    'Rochelle',
    'Rock Springs',
    'Rockledge',
    'Rockmart',
    'Rocky Face',
    'Rome',
    'Roopville',
    'Rossville',
    'Roswell',
    'Royston',
    'Rutledge',
    'Rydal',
    'Saint Marys',
    'Saint Simons Island',
    'Sandersville',
    'Sandy Springs',
    'Sasser',
    'Sautee Nacoochee',
    'Savannah',
    'Scottdale',
    'Sea Island',
    'Senoia',
    'Shady Dale',
    'Shannon',
    'Shellman',
    'Shenandoah',
    'Silver Creek',
    'Smarr',
    'Smithville',
    'Smyrna',
    'Snellville',
    'Social Circle',
    'Soperton',
    'Sparks',
    'Sparta',
    'Stapleton',
    'Statesboro',
    'Statham',
    'Stillmore',
    'Stockbridge',
    'Stockton',
    'Stone Mountain',
    'Sugar Hill',
    'Sugar Valley',
    'Summerville',
    'Sumner',
    'Sunny Side',
    'Surrency',
    'Suwanee',
    'Swainsboro',
    'Sycamore',
    'Sylvania',
    'Sylvester',
    'Tallapoosa',
    'Talmo',
    'Tate',
    'Taylorsville',
    'Tazewell',
    'Temple',
    'Tennille',
    'The Rock',
    'Thomaston',
    'Thomasville',
    'Thomson',
    'Tifton',
    'Tiger',
    'Tignall',
    'Toccoa',
    'Toomsboro',
    'Townsend',
    'Trenton',
    'Trion',
    'Tucker',
    'Tunnel Hill',
    'Twin City',
    'Ty Ty',
    'Tybee Island',
    'Tyrone',
    'Unadilla',
    'Union City',
    'Union Point',
    'Uvalda',
    'Valdosta',
    'Valley',
    'Varnell',
    'Vidalia',
    'Vienna',
    'Villa Rica',
    'Waco',
    'Wadley',
    'Walthourville',
    'Warner Robins',
    'Warrenton',
    'Warthen',
    'Warwick',
    'Washington',
    'Watkinsville',
    'Waverly',
    'Waverly Hall',
    'Waycross',
    'Waynesboro',
    'West Point',
    'Weston',
    'Westover',
    'Whigham',
    'White',
    'Whitestone',
    'Willacoochee',
    'Williamson',
    'Winder',
    'Winokur',
    'Winston',
    'Winterville',
    'Woodbine',
    'Woodbury',
    'Woodstock',
    'Wray',
    'Wrens',
    'Wrightsville',
    'Zebulon',
    'North Decatur'
  ],
  HI: [
    'Aiea (Oahu)',
    'Anahola',
    'Barbers Point (Oahu)',
    'Captain Cook',
    'Eleele',
    'Ewa Beach (Oahu)',
    'Haiku',
    'Haleiwa',
    'Hana',
    'Hanapepe',
    'Hauula',
    'Hickam AFB',
    'Hilo',
    'Honolulu',
    'Hoolehua',
    'Kahuku',
    'Kahului',
    'Kailua',
    'Kailua (Maui)',
    'Kailua (Oahu)',
    'Kailua Kona',
    'Kalaheo',
    'Kalaupapa',
    'Kamuela',
    'Kaneohe',
    'Kapaa',
    'Kapalua',
    'Kapolei',
    'Kauai Island Apt',
    'Kaunakakai (Molokai)',
    'Kaupulehu',
    'Kawaihae',
    'Keaau',
    'Kealakekua',
    'Kekaha',
    'Kihei',
    'Kilauea',
    'Kohala',
    'Koloa',
    'Kula',
    'Kunia Camp',
    'Lahaina',
    'Lanai City',
    'Lihue (Kauai)',
    'Makawao',
    'Mililani Town',
    'Mountain View',
    'Nawiliwili',
    'Pearl City (Oahu)',
    'Pearl Harbor',
    'Pohakuloa',
    'Port Allen',
    'Princeville',
    "Pu'unene",
    'Ulupalakua',
    'Upolu Point',
    'Wahiawa',
    'Waialua',
    'Waianae',
    'Waikoloa',
    'Wailea',
    'Wailuku (Maui)',
    'Waimanalo',
    'Waimea',
    'Waipahu (Oahu)'
  ],
  IA: [
    'Ackley',
    'Adair',
    'Adel',
    'Afton',
    'Akron',
    'Albia',
    'Albion',
    'Alburnett',
    'Alden',
    'Alexander',
    'Algona',
    'Allerton',
    'Alta',
    'Altoona',
    'Alvord',
    'Amador',
    'Amana',
    'Ames',
    'Anamosa',
    'Anita',
    'Ankeny',
    'Arion',
    'Arlington',
    'Armstrong',
    'Arnolds Park',
    'Arthur',
    'Asbury',
    'Atlantic',
    'Auburn',
    'Avoca',
    'Bagley',
    'Bancroft',
    'Baxter',
    'Bayard',
    'Beacon',
    'Beaman',
    'Bedford',
    'Belle Plaine',
    'Bellevue',
    'Belmond',
    'Bettendorf',
    'Bevington',
    'Blairsburg',
    'Blairstown',
    'Blanchard',
    'Bloomfield',
    'Blue Grass',
    'Bode',
    'Bonaparte',
    'Bondurant',
    'Boone',
    'Bouton',
    'Boyden',
    'Breda',
    'Bristow',
    'Britt',
    'Bronson',
    'Brooklyn',
    'Buffalo',
    'Burlington',
    'Camanche',
    'Cambridge',
    'Carlisle',
    'Carroll',
    'Carter Lake',
    'Cascade',
    'Cedar Falls',
    'Cedar Rapids',
    'Centerville',
    'Central City',
    'Chariton',
    'Charles City',
    'Chatsworth',
    'Chelsea',
    'Cherokee',
    'Clare',
    'Clarence',
    'Clarinda',
    'Clarion',
    'Clear Lake',
    'Clearfield',
    'Clemons',
    'Clermont',
    'Clinton',
    'Clive',
    'Coggon',
    'Colesburg',
    'Colo',
    'Columbus City',
    'Columbus Junction',
    'Conrad',
    'Conroy',
    'Coon Rapids',
    'Coralville',
    'Corning',
    'Correctionville',
    'Corwith',
    'Corydon, Wayne',
    'Coulter',
    'Council Bluffs',
    'Crescent',
    'Cresco',
    'Creston',
    'Cumming',
    'Dakota City',
    'Dallas',
    'Dallas Center',
    'Danville',
    'Davenport',
    'De Soto',
    'De Witt',
    'Decatur City',
    'Decorah',
    'Defiance',
    'Delhi',
    'Deloit',
    'Denison',
    'Des Moines',
    'Dewitt',
    'Dike',
    'Donnellson',
    'Doon',
    'Dubuque',
    'Duncombe',
    'Dunlap',
    'Durant',
    'Dyersville',
    'Dysart',
    'Eagle Grove',
    'Earlham',
    'Earling',
    'Earlville',
    'Eddyville',
    'Edgewood',
    'Elberon',
    'Eldon',
    'Eldora',
    'Eldridge',
    'Elk Horn',
    'Elkader',
    'Elkhart',
    'Ellsworth',
    'Elma',
    'Ely',
    'Emerson',
    'Emmetsburg',
    'Essex',
    'Estherville',
    'Evansdale',
    'Everly',
    'Fairbank',
    'Fairfax',
    'Fairfield',
    'Farley',
    'Farragut',
    'Fayette',
    'Fenton',
    'Forest City',
    'Fort Atkinson',
    'Fort Dodge',
    'Fort Madison',
    'Franklin',
    'Fredericksburg',
    'Fremont',
    'Fulton',
    'Galva',
    'Garnavillo',
    'Garner',
    'Garrison',
    'George',
    'Gilmore City',
    'Glenwood',
    'Glidden',
    'Goldfield',
    'Goodell',
    'Graettinger',
    'Grand Junction',
    'Grand Mound',
    'Granger',
    'Granville',
    'Greene',
    'Greenfield',
    'Grimes',
    'Grinnell',
    'Griswold',
    'Grundy Center',
    'Guttenberg',
    'Halbur',
    'Hamburg',
    'Hampton',
    'Hanlontown',
    'Harcourt',
    'Harlan',
    'Harpers Ferry',
    'Harris',
    'Hartley',
    'Harvey',
    'Hawarden',
    'Hiawatha',
    'Hills',
    'Hinton',
    'Holstein',
    'Holy Cross',
    'Hopkinton',
    'Hospers',
    'Houghton',
    'Hudson',
    'Hull',
    'Humboldt',
    'Huxley',
    'Ida Grove',
    'Independence',
    'Indianola',
    'Inwood',
    'Ionia',
    'Iowa City',
    'Iowa Falls',
    'Ireton',
    'Janesville',
    'Jefferson',
    'Jesup',
    'Jewell',
    'Johnston',
    'Kalona',
    'Kanawha',
    'Kellogg',
    'Kensett',
    'Keokuk',
    'Keosauqua',
    'Keota',
    'Keswick',
    'Kilbourn',
    'Kimballton',
    'Kiron',
    'Knoxville',
    'La Motte',
    'La Porte City',
    'Lake City',
    'Lake Mills',
    'Lake Park',
    'Lakeview',
    'Lakota',
    'Lansing',
    'Larchwood',
    'Latimer',
    'Laurens',
    'Lawler',
    'Lawton',
    'Le Claire',
    'Le Mars',
    'Leland',
    'Lemars',
    'Lenox',
    'Lester',
    'Liberty',
    'Libertyville',
    'Lime Springs',
    'Lineville',
    'Lisbon',
    'Lohrville',
    'Lorimor',
    'Lowden',
    'Luana',
    'Lynnville',
    'Lytton',
    'Madrid',
    'Malcom',
    'Manchester',
    'Manly',
    'Manning',
    'Manson',
    'Maquoketa',
    'Marathon',
    'Marengo',
    'Marion',
    'Marne',
    'Marshalltown',
    'Mason City',
    'Masonville',
    'Maxwell',
    'McGregor',
    'Mediapolis',
    'Melbourne',
    'Menlo',
    'Merrill',
    'Middletown',
    'Milford',
    'Milton',
    'Mingo',
    'Missouri Valley',
    'Mitchellville',
    'Monmouth',
    'Monona',
    'Monroe',
    'Montezuma',
    'Monticello',
    'Montrose',
    'Moravia',
    'Moscow',
    'Mount Ayr',
    'Mount Pleasant',
    'Mount Vernon',
    'Moville',
    'Murray',
    'Muscatine',
    'Nevada',
    'New Albin',
    'New Hampton',
    'New Liberty',
    'New London',
    'New Market',
    'New Providence',
    'New Vienna',
    'New Virginia',
    'Newell',
    'Newton',
    'Nora Springs',
    'North Liberty',
    'Northwood',
    'Norwalk',
    'Norway',
    'Oakland',
    'Odebolt',
    'Oelwein',
    'Ogden',
    'Okoboji',
    'Onawa',
    'Orange City',
    'Orchard',
    'Osage',
    'Osceola',
    'Oskaloosa',
    'Ottumwa',
    'Oxford',
    'Pacific Junction',
    'Palmer',
    'Palo',
    'Panama',
    'Panora',
    'Parnell',
    'Paton',
    'Paullina',
    'Pella',
    'Peoria',
    'Peosta',
    'Perry',
    'Plainfield',
    'Plano',
    'Pleasant Hill',
    'Pleasant Prairie',
    'Pleasantville',
    'Plover',
    'Pocahontas',
    'Pomeroy',
    'Portsmouth',
    'Postville',
    'Prairie City',
    'Preston',
    'Primghar',
    'Princeton',
    'Promise City',
    'Radcliffe',
    'Ralston',
    'Reasnor',
    'Red Oak',
    'Redding',
    'Redfield',
    'Reinbeck',
    'Rembrandt',
    'Remsen',
    'Renwick',
    'Riceville',
    'Richland',
    'Ringsted',
    'Riverdale',
    'Riverside',
    'Rock Rapids',
    'Rock Valley',
    'Rockford',
    'Rockwell City',
    'Rowley',
    'Sac City',
    'Saint Ansgar',
    'Saint Charles',
    'Saint Lucas',
    'Saint Marys',
    'Saint Olaf',
    'Sanborn',
    'Schaller',
    'Schleswig',
    'Scranton',
    'Seneca',
    'Sergeant Bluff',
    'Seymour',
    'Sheffield',
    'Shelby',
    'Sheldon',
    'Shell Rock',
    'Shenandoah',
    'Sibley',
    'Sidney',
    'Sigourney',
    'Sioux Center',
    'Sioux City',
    'Sioux Rapids',
    'Slater',
    'Solon',
    'Spencer',
    'Spillville',
    'Spirit Lake',
    'Spragueville',
    'Springville',
    'Stacyville',
    'Stanton',
    'Stockton',
    'Storm Lake',
    'Story City',
    'Strawberry Point',
    'Stuart',
    'Sully',
    'Summerset',
    'Sumner',
    'Swisher',
    'Tabor',
    'Taintor',
    'Tama',
    'Templeton',
    'Thompson',
    'Tiffin',
    'Tipton',
    'Toleda',
    'Toledo',
    'Troy Mills',
    'Underwood',
    'Union',
    'Urbana',
    'Urbandale',
    'Ute',
    'Vail',
    'Van Horne',
    'Van Meter',
    'Van Wert',
    'Ventura',
    'Victor',
    'Villisca',
    'Vincent',
    'Vinton',
    'Voorhies',
    'Walcott',
    'Walford',
    'Wall Lake',
    'Walnut',
    'Wapello',
    'Washington',
    'Waterloo',
    'Waukee',
    'Waukon',
    'Waverly',
    'Webster City',
    'Weldon',
    'Wellman',
    'Wellsburg',
    'West Bend',
    'West Branch',
    'West Burlington',
    'West Des Moines',
    'West Liberty',
    'West Point',
    'West Union',
    'Westside',
    'Wever',
    'Wheatland',
    'Whittemore',
    'Williamsburg',
    'Wilton',
    'Windsor Heights',
    'Winterset',
    'Winthrop',
    'Woodward',
    'Yarmouth',
    'Zearing',
    'Blencoe'
  ],
  ID: [
    'Aberdeen',
    'Ahsahka',
    'Albion',
    'American Falls',
    'Ammon',
    'Arimo',
    'Ashton',
    'Athol',
    'Bancroft',
    'Blackfoot',
    'Blanchard',
    'Boise',
    'Bonners Ferry',
    'Bovill',
    'Buhl',
    'Burley',
    'Burley-Rupert Apt',
    'Caldwell',
    'Camas',
    'Cambridge',
    'Careywood',
    'Cascade',
    'Cataldo',
    'Challis',
    'Chubbuck',
    'Clark Fork',
    'Clayton',
    'Clearwater',
    'Clover',
    'Cocolalla',
    "Coeur d'Alene",
    'Collins',
    'Cottonwood',
    'Council',
    'Craigmont',
    'Dalton Gardens',
    'Declo',
    'Dirggs',
    'Donnelly',
    'Driggs',
    'Dubois',
    'Eagle',
    'Eagle, Ada',
    'Eastport',
    'Eden',
    'Emmett',
    'Fernwood',
    'Filer',
    'Firth',
    'Fort Hall',
    'Franklin',
    'Fruitland',
    'Garden City',
    'Garden Valley',
    'Genesee',
    'Glenns Ferry',
    'Gooding',
    'Grace',
    'Grand View',
    'Grangeville',
    'Greenleaf',
    'Gwinnett',
    'Hailey',
    'Hailey-Sun Valley Apt',
    'Hamer',
    'Hansen',
    'Harrison',
    'Hauser',
    'Hayden',
    'Hayden Lake',
    'Hazelton',
    'Heyburn',
    'Homedale',
    'Hope',
    'Idaho City',
    'Idaho Falls',
    'Inkom',
    'Jerome',
    'Kamiah',
    'Kellogg',
    'Kendrick',
    'Ketchum',
    'Kimberly',
    'Kootenai',
    'Kuna',
    'Laclede',
    'Leadore',
    'Letha',
    'Lewiston',
    'Lewisville',
    'Macks Inn',
    'Malad City',
    'Marsing',
    'Mayfield',
    'McCall',
    'Melba',
    'Meridian',
    'Middleton',
    'Milner',
    'Minidoka',
    'Montpelier',
    'Morocco',
    'Moscow',
    'Mountain Home',
    'Moyie Springs',
    'Murtaugh',
    'Nampa',
    'New Meadows',
    'New Plymouth',
    'Newdale',
    'Nezperce',
    'Notus',
    'Orofino',
    'Osburn',
    'Ovid',
    'Parma',
    'Paul',
    'Payette',
    'Pierce',
    'Pingree',
    'Pocatello',
    'Ponderay',
    'Porthill',
    'Post Falls',
    'Potlatch',
    'Preston',
    'Priest River',
    'Princeton',
    'Rathdrum',
    'Rexburg',
    'Richfield',
    'Rigby',
    'Riggins',
    'Ririe',
    'Rupert',
    'Rupert-Burley Apt',
    'Sagle',
    'Saint Anthony',
    'Salmon',
    'Sandpoint',
    'Santa',
    'Shelley',
    'Shoshone',
    'Shoshone County',
    'Smelterville',
    'Soda Springs',
    'Spirit Lake',
    'St. Maries',
    'Stanley',
    'Star',
    'Sugar City',
    'Sun Valley',
    'Sun Valley-Hailey Apt',
    'Sunnyslope',
    'Swan Valley',
    'Terreton',
    'Troy',
    'Twin Falls',
    'Victor',
    'Viola',
    'Wallace',
    'Weiser',
    'Wendell',
    'Weston',
    'Wilder'
  ],
  IL: [
    'Abbott Park',
    'Abingdon',
    'Addison',
    'Albany',
    'Albers',
    'Albion',
    'Aledo',
    'Alexander',
    'Alexis',
    'Algonquin',
    'Alorton',
    'Alpha',
    'Alsey',
    'Alsip',
    'Altamont',
    'Alton',
    'Amboy',
    'Andalusia',
    'Anna',
    'Antioch',
    'Arcola',
    'Arenzville',
    'Argenta',
    'Argo',
    'Arlington',
    'Arlington Heights',
    'Aroma Park',
    'Arthur',
    'Ashkum',
    'Ashland',
    'Ashley',
    'Ashmore',
    'Ashton',
    'Assumption',
    'Astoria',
    'Athens',
    'Atkinson',
    'Atlanta',
    'Attica',
    'Atwood',
    'Auburn',
    'Augusta',
    'Aurora',
    'Ava',
    'Avery Hill',
    'Avon',
    'Baldwin',
    'Bannockburn',
    'Barrington',
    'Barry',
    'Bartlett',
    'Bartonville',
    'Basco',
    'Batavia',
    'Beardstown',
    'Beaverville',
    'Beckemeyer',
    'Bedford Park',
    'Beecher',
    'Belleville',
    'Bellevue',
    'Bellflower',
    'Bellwood',
    'Beloit',
    'Belvidere',
    'Bement',
    'Bensenville',
    'Benson',
    'Benton',
    'Berkeley',
    'Berwyn',
    'Bethalto',
    'Bethany',
    'Big Rock',
    'Binghampton',
    'Bismarck',
    'Blandinsville',
    'Bloomingdale',
    'Bloomington',
    'Bloomington-Normal Apt',
    'Blue Island',
    'Blue Mound',
    'Bluffs',
    'Bolingbrook',
    'Bourbonnais',
    'Braceville',
    'Bradford',
    'Bradley',
    'Braidwood',
    'Breese',
    'Bridgeport',
    'Bridgeview',
    'Brighton',
    'Brimfield',
    'Bristol',
    'Broadview',
    'Brookfield',
    'Brookport',
    'Brownstown',
    'Buda',
    'Buffalo',
    'Buffalo Grove',
    'Bull Valley',
    'Bunker Hill',
    'Burbank',
    'Bureau',
    'Burlington',
    'Burnham',
    'Burnt Prairie',
    'Burr Ridge',
    'Bushnell',
    'Butler',
    'Byron',
    'Byron Hills Estates',
    'Cache',
    'Cahokia',
    'Cairo',
    'Calhoun',
    'Calumet City',
    'Calumet Park',
    'Cambria',
    'Cambridge',
    'Camp Point',
    'Campbell Hill',
    'Canton',
    'Capron',
    'Carbon Cliff',
    'Carbondale',
    'Carlinville',
    'Carlock',
    'Carlyle',
    'Carmi',
    'Carol Stream',
    'Carpentersville',
    'Carrier Mills',
    'Carrollton',
    'Carterville',
    'Carthage',
    'Cary',
    'Casey',
    'Caseyville',
    'Catlin',
    'Cave-in-Rock',
    'Centerville',
    'Central City',
    'Centralia',
    'Centreville',
    'Cerro Gordo',
    'Chadwick',
    'Champaign',
    'Chandlerville',
    'Channahon',
    'Charleston',
    'Chatham',
    'Chatsworth',
    'Chebanse',
    'Chenoa',
    'Cherry Valley',
    'Chester',
    'Chicago',
    'Chicago Heights',
    'Chicago Ridge',
    'Chillicothe',
    'Chrisman',
    'Christopher',
    'Cicero',
    'Cisne',
    'Cissna Park',
    'Clare',
    'Claremont',
    'Clarendon Hill',
    'Clarendon Hills',
    'Clark',
    'Clay City',
    'Clayton',
    'Clearing',
    'Cleveland',
    'Clinton',
    'Coal City',
    'Coal Valley',
    'Cobden',
    'Coffeen',
    'Colchester',
    'Coles',
    'Colfax',
    'Collinsville',
    'Colona',
    'Columbia',
    'Columbus',
    'Compton',
    'Cooksville',
    'Cordova',
    'Cortland',
    'Cottage Grove',
    'Cottage Hills',
    'Coulterville',
    'Country Club Hills',
    'Country Side',
    'Countryside',
    'Creal Springs',
    'Crest Hill',
    'Crestwood',
    'Crete',
    'Creve Coeur',
    'Cropsey',
    'Crossville',
    'Crystal Lake',
    'Crystal Lawns',
    'Cuba',
    'Cumberland',
    'Cutler',
    'Dahlgren',
    'Dakota',
    'Dallas City',
    'Dalton City',
    'Danvers',
    'Danville',
    'Darien',
    'Davis Junction',
    'Dayton',
    'De Kalb',
    'De Soto',
    'Decatur',
    'Deer Grove',
    'Deer Park',
    'Deerfield',
    'DeKalb',
    'Delavan',
    'Depue',
    'Des Plaines',
    'Detroit',
    'Dewitt',
    'Diamond Lake',
    'Dieterich',
    'Divernon',
    'Dix',
    'Dixmoor',
    'Dixon',
    'Dolton',
    'Dongola',
    'Donovan',
    'Dorchester',
    'Douglas',
    'Dover',
    'Downers Grove',
    'Downs',
    'Du Quoin',
    'Dubois',
    'Dundee',
    'Dunlap',
    'Dupo',
    'Duquoin',
    'Durand',
    'Dwight',
    'Earlville',
    'East Alton',
    'East Carondelet',
    'East Clinton',
    'East Dubuque',
    'East Dundee',
    'East Hazel Crest',
    'East Moline',
    'East Peoria',
    'East Saint Louis',
    'Edwards',
    'Edwardsville',
    'Effingham',
    'El Paso',
    'Elburn',
    'Elco',
    'Eldorado',
    'Elgin',
    'Elizabeth',
    'Elizabethtown',
    'Elk Grove Village',
    'Elkhart',
    'Elkville',
    'Ellis Grove',
    'Ellsworth',
    'Elmhurst',
    'Elmwood',
    'Elmwood Park',
    'Elsah',
    'Elwood',
    'Emden',
    'Energy',
    'Enfield',
    'Equality',
    'Erie',
    'Eureka',
    'Evanston',
    'Evansville',
    'Evergreen Park',
    'Fairbury',
    'Fairfield',
    'Fairmont',
    'Fairmont City',
    'Fairmont, Will',
    'Fairmount',
    'Fairview',
    'Fairview Heights',
    'Fargo',
    'Farina',
    'Farmer City',
    'Farmersville',
    'Farmingdale',
    'Farmington',
    'Fayette',
    'Fayetteville',
    'Findlay',
    'Fisher',
    'Fithian',
    'Flanagan',
    'Flat Rock',
    'Flora',
    'Flossmoor',
    'Ford Heights',
    'Forest City',
    'Forest Park',
    'Forest View',
    'Forrest',
    'Forreston',
    'Forsyth',
    'Fort Sheridan',
    'Fowler',
    'Fox Lake',
    'Fox River Grove',
    'Frankfort',
    'Franklin Park',
    'Freeburg',
    'Freeport',
    'Fulton',
    'Galena',
    'Galesburg',
    'Galva',
    'Gardner',
    'Geneseo',
    'Geneva',
    'Genoa',
    'Georgetown, Fayette',
    'Germantown',
    'Gibson City',
    'Gilberts',
    'Gillespie',
    'Gilman',
    'Gilson',
    'Girard',
    'Glen Ellyn',
    'Glen View',
    'Glencoe',
    'Glendale Heights',
    'Glendale Hghts',
    'Glenview',
    'Glenwood',
    'Godfrey',
    'Golden',
    'Golf',
    'Goodfield',
    'Grand Chain',
    'Grand Ridge',
    'Grandville',
    'Granite City',
    'Grant Park',
    'Granville',
    'Grayslake',
    'Grayville',
    'Great Lakes',
    'Green Oaks',
    'Greenfield',
    'Greenup',
    'Greenview',
    'Greenville',
    'Greenwood',
    'Gridley',
    'Griggsville',
    'Gurnee',
    'Hamel',
    'Hamilton',
    'Hammond',
    'Hampshire',
    'Hanover',
    'Hanover Park',
    'Hardin',
    'Harmon',
    'Harrisburg',
    'Harristown',
    'Hartford',
    'Harvard',
    'Harvey',
    'Harwood Heights',
    'Havana',
    'Hazel Crest',
    'Hebron',
    'Hennepin',
    'Henning',
    'Henry',
    'Herod',
    'Herrin',
    'Hettick',
    'Heyworth',
    'Hickory Hills, Cook',
    'Hidalgo',
    'Highland',
    'Highland Park',
    'Highwood',
    'Hillsdale',
    'Hillside',
    'Hinckley',
    'Hinsdale',
    'Hodgkins',
    'Hoffman Estates',
    'Holcomb',
    'Holland, Effingham',
    'Homer Glen',
    'Homewood',
    'Hoopeston',
    'Hudson',
    'Huntley',
    'Illinois City',
    'Illiopolis',
    'Indianola',
    'Ingleside',
    'Inverness',
    'Island Lake',
    'Itasca',
    'Ivesdale',
    'Jacksonville',
    'Janesville',
    'Jerseyville',
    'Johnsburg',
    'Johnsonville',
    'Joliet',
    'Joppa',
    'Joslin',
    'Justice',
    'Kaneville',
    'Kankakee',
    'Keithsburg',
    'Kenilworth',
    'Kenney',
    'Kewanee',
    'Keyesport',
    'Kildeer',
    'Kinmundy',
    'Kinsman',
    'Kirkland',
    'Knoxville',
    'La Fayette',
    'La Fox',
    'La Grange',
    'La Grange Highlands',
    'La Grange Park',
    'La Harpe',
    'La Salle',
    'Lacon',
    'Ladd',
    'Lafayette',
    'Lake Barrington',
    'Lake Bluff',
    'Lake Forest',
    'Lake In The Hills',
    'Lake Villa',
    'Lake Zurich',
    'Lakemoor',
    'Lakewood',
    'Lanark',
    'Lansing',
    'Lawrenceville',
    'Le Roy',
    'Lebanon',
    'Lee',
    'Leland',
    'Lemmon',
    'Lemont',
    'Lena',
    'Lenox',
    'Lewistown',
    'Lexington',
    'Liberty',
    'Libertyville',
    'Lincoln',
    'Lincoln Park, Chicago',
    'Lincolnshire',
    'Lincolnwood',
    'Lindenhurst',
    'Lindenwood',
    'Lisle',
    'Litchfield',
    'Lockport',
    'Loda',
    'Logan, Edgar',
    'Lombard',
    'London Mills',
    'Long Grove',
    'Longview',
    'Lostant',
    'Louisville',
    'Loves Park',
    'Lovington',
    'Lynwood',
    'Lyons',
    'Macedonia',
    'Machesney Park',
    'Mackinaw',
    'Macomb',
    'Macon',
    'Madison',
    'Mahomet',
    'Makanda',
    'Malta',
    'Manhattan',
    'Manito',
    'Manteno',
    'Maple Grove',
    'Maple Park',
    'Mapleton',
    'Marblehead',
    'Marengo',
    'Marietta',
    'Marion',
    'Marissa',
    'Mark',
    'Markham',
    'Maroa',
    'Marseilles',
    'Marshall',
    'Martinsville',
    'Maryville',
    'Mascoutah',
    'Mason City',
    'Matteson',
    'Mattoon',
    'Maywood/Chicago',
    'Mazon',
    'McCook',
    'McGaw Park',
    'McHenry',
    'McLeansboro',
    'Mechanicsburg',
    'Medinah',
    'Melrose Park',
    'Melvin',
    'Mendota',
    'Meredosia',
    'Metamora',
    'Metropolis',
    'Mettawa',
    'Michael',
    'Middleton',
    'Midlothian',
    'Midway',
    'Milan',
    'Milford',
    'Milledgeville',
    'Millsdale',
    'Millstadt',
    'Minonk',
    'Minooka',
    'Mokena',
    'Moline',
    'Momence',
    'Monee',
    'Monmouth',
    'Montgomery',
    'Monticello',
    'Moro',
    'Morris',
    'Morrison',
    'Morrisonville',
    'Morton',
    'Morton Grove',
    'Mossville',
    'Mound City',
    'Mounds',
    'Mount Carmel',
    'Mount Carroll',
    'Mount Erie',
    'Mount Morris',
    'Mount Prospect',
    'Mount Pulaski',
    'Mount Sterling',
    'Mount Vernon',
    'Mount Zion',
    'Moweaqua',
    'Muncie',
    'Mundelein',
    'Murdock',
    'Murphysboro',
    'Naperville',
    'Naples',
    'Nashville',
    'National City',
    'Nauvoo',
    'Neoga',
    'Neponset',
    'New Baden',
    'New Bedford',
    'New Berlin',
    'New Haven',
    'New Lenox',
    'New Salem',
    'Newark',
    'Newton',
    'Niles',
    'Noble',
    'Normal',
    'Norridge',
    'Norris',
    'North Aurora',
    'North Barrington',
    'North Chicago',
    'North Pekin',
    'North Riverside',
    'North Venice',
    'Northbrook',
    'Northfield',
    'Northlake',
    'Oak Brook',
    'Oak Forest',
    'Oak Grove',
    'Oak Lawn',
    'Oak Park',
    'Oakbrook Terrace',
    'Oakdale',
    'Oakland',
    'Oakley',
    'Oblong',
    "O'Fallon",
    'Ogden',
    'Oglesby',
    "O'Hare Apt/Chicago",
    'Ohio',
    'Okawville',
    'Olive Branch',
    'Olmsted',
    'Olney',
    'Olympia Fields',
    'Omaha',
    'Onarga',
    'Orangeville',
    'Oregon',
    'Orion',
    'Orland Park',
    'Osco',
    'Oswego',
    'Ottawa',
    'Palatine',
    'Palestine',
    'Palos Heights',
    'Palos Hills',
    'Palos Park',
    'Pana',
    'Paris',
    'Park City',
    'Park Forest',
    'Park Ridge',
    'Patoka',
    'Pawnee',
    'Paxton',
    'Pearl City',
    'Pecatonica',
    'Pekin',
    'Peoria',
    'Peoria Heights',
    'Peotone',
    'Peru',
    'Petersburg, Menard',
    'Phoenix',
    'Pinckneyville',
    'Pingree Grove',
    'Piper City',
    'Pittsburg',
    'Pittsfield',
    'Plaines',
    'Plainfield',
    'Plano',
    'Pleasant Hill',
    'Pleasant Plains',
    'Plymouth',
    'Polo',
    'Pomona',
    'Pontiac',
    'Pontoon Beach',
    'Poplar Grove',
    'Port Byron',
    'Posen',
    'Prairie View',
    'Princeton',
    'Princeville',
    'Prophetstown',
    'Prospect',
    'Prospect Heights',
    'Quincy',
    'Radom',
    'Raleigh',
    'Rantoul',
    'Red Bud',
    'Reynolds',
    'Richmond',
    'Richton Park',
    'Ridgway',
    'Ridott',
    'Ringwood',
    'River Forest',
    'River Grove',
    'River Grove/Chicago',
    'Riverdale',
    'Riverside',
    'Riverton',
    'Riverwoods',
    'Roanoke',
    'Roberts',
    'Robinson',
    'Rochelle',
    'Rochester',
    'Rock City',
    'Rock Falls',
    'Rock Island',
    'Rockdale',
    'Rockford',
    'Rockport',
    'Rockton',
    'Rockville Township',
    'Rockwood',
    'Rolling Meadows',
    'Romeoville',
    'Roodhouse',
    'Roscoe',
    'Roselle',
    'Rosemont',
    'Rosiclaire',
    'Rosiclare',
    'Rossville',
    'Round Lake',
    'Round Lake Beach',
    'Round Lake Park',
    'Roxana',
    'Rushville',
    'Russell',
    'Russellville',
    'Sadorus',
    'Saint Anne',
    'Saint Charles',
    'Saint Elmo',
    'Saint Joseph',
    'Salem',
    'Sandoval',
    'Sandwich',
    'Sauget',
    'Sauk Village',
    'Savanna',
    'Savoy',
    'Scales Mound',
    'Schaumburg',
    'Schiller Park',
    'Scott Air Force Base',
    'Secor',
    'Seneca',
    'Serena',
    'Sesser',
    'Seward',
    'Shannon',
    'Sheffield',
    'Shelbyville',
    'Sheridan',
    'Sherrard',
    'Shiloh',
    'Shorewood',
    'Shumway',
    'Sidell',
    'Sigel',
    'Silvis',
    'Skokie',
    'Smithfield',
    'Somonauk',
    'South Barrington',
    'South Beloit',
    'South Chicago Heights',
    'South Elgin',
    'South Holland',
    'South Roxana',
    'Sparta',
    'Spring Grove',
    'Spring Valley',
    'Springfield',
    'Staunton',
    'Steeleville',
    'Steger',
    'Sterling',
    'Sterling-Rockfalls Apt',
    'Stickney',
    'Stockton',
    'Stone Park',
    'Stonington',
    'Stookey',
    'Streamwood',
    'Streator',
    'Sugar Grove',
    'Sullivan',
    'Summit',
    'Swansea',
    'Sycamore',
    'Tamms',
    'Tampico',
    'Taylor Ridge',
    'Taylorville',
    'Tennessee',
    'Terre Haute',
    'Teutopolis',
    'Thomasboro',
    'Thomson',
    'Thornton',
    'Tilton',
    'Tinley Park',
    'Tiskilwa',
    'Toledo',
    'Tolono',
    'Toluca',
    'Tonica',
    'Topeka',
    'Toulon',
    'Tower Lakes',
    'Tremont',
    'Trenton',
    'Trilla',
    'Troy',
    'Troy Grove',
    'Tuscola',
    'Union',
    'University Park',
    'Urbana',
    'Utica',
    'Valmeyer',
    'Vandalia',
    'Varna',
    'Venice',
    'Vergennes',
    'Vermilion',
    'Vermont',
    'Vernon',
    'Vernon Hills',
    'Victoria',
    'Vienna',
    'Villa Grove',
    'Villa Park',
    'Virden',
    'Virgil',
    'Virginia',
    'Volo',
    'Wadsworth',
    'Walnut Hill',
    'Warren',
    'Warrensburg',
    'Warrenville',
    'Warsaw',
    'Wasco',
    'Washburn',
    'Washington',
    'Washington Park',
    'Wataga',
    'Waterloo',
    'Waterman',
    'Watseka',
    'Wauconda',
    'Waukegan',
    'Wayne',
    'Wedron',
    'Weldon',
    'Wellington',
    'West Chicago',
    'West Dundee',
    'West Frankfort',
    'West Peoria',
    'West Salem',
    'West Springfield',
    'Westchester',
    'Western Springs',
    'Westmont',
    'Westville',
    'Wheaton',
    'Wheeling',
    'White Hall',
    'Whittington',
    'Wicker Park',
    'Willow Brook',
    'Willow Springs',
    'Willowbrook, DuPage',
    'Wilmette',
    'Wilmington',
    'Wilsonville',
    'Winchester',
    'Windsor',
    'Winfield',
    'Winnebago',
    'Winnetka',
    'Winslow',
    'Winthrop Harbor',
    'Wolf Lake',
    'Wonder Lake',
    'Wood Dale',
    'Wood River',
    'Woodhull',
    'Woodlawn',
    'Woodridge',
    'Woodstock',
    'Worden',
    'Worth',
    'Wyanet',
    'Wyoming',
    'Yorkville',
    'Zion',
    'East St Louis'
  ],
  IN: [
    'Acton',
    'Advance',
    'Akron',
    'Albany',
    'Albion',
    'Alexandria',
    'Ambia',
    'Anderson',
    'Andrews',
    'Angola',
    'Arcadia',
    'Arcola',
    'Argos',
    'Arlington',
    'Ashley',
    'Atlanta',
    'Attica',
    'Auburn',
    'Aurora',
    'Austin',
    'Avilla',
    'Avon',
    'Bargersville',
    'Bartlettsville',
    'Batesville',
    'Battle Ground',
    'Bedford',
    'Berne',
    'Bicknell',
    'Bloomfield',
    'Bloomingdale',
    'Bloomington',
    'Bluffton',
    'Boonville',
    'Borden',
    'Boswell',
    'Bourbon',
    'Bowling Green',
    'Brazil',
    'Bremen',
    'Bridgeton',
    'Brimfield',
    'Bristol',
    'Brook',
    'Brookston',
    'Brooksville',
    'Brookville',
    'Brownsburg',
    'Brownstown',
    'Brownsville',
    'Bruceville',
    'Buckskin',
    'Buffalo',
    'Bunker Hill',
    'Burlington',
    'Burney',
    'Burns Harbor',
    'Butler',
    'Butlerville',
    'Cambridge City',
    'Camby',
    'Camden',
    'Campbellsburg',
    'Cannelburg',
    'Cannelton',
    'Carbon',
    'Carlisle',
    'Carmel',
    'Carthage',
    'Cayuga',
    'Cedar Lake',
    'Center Point',
    'Centerville',
    'Chalmers',
    'Chandler',
    'Charlestown',
    'Chesterton',
    'Chrisney',
    'Churubusco',
    'Cicero',
    'Clarks Hill',
    'Clarksville',
    'Claypool',
    'Clayton',
    'Clermont',
    'Clinton',
    'Cloverdale',
    'Coatesville',
    'Collegeville',
    'Columbia City',
    'Columbus',
    'Connersville',
    'Cortland',
    'Corunna',
    'Cory',
    'Corydon',
    'Covington',
    'Crane',
    'Crawfordsville',
    'Cromwell',
    'Cross Plains',
    'Crothersville',
    'Crown Point',
    'Culver',
    'Cumberland',
    'Cutler',
    'Cynthiana',
    'Dale',
    'Daleville',
    'Danville',
    'Darlington',
    'Decatur',
    'Decker',
    'Delphi',
    'DeMotte',
    'Denham',
    'Depauw',
    'Dillsboro',
    'Dubois',
    'Dunkirk',
    'Dunlap',
    'Dyer',
    'Earl Park',
    'East Chicago',
    'Eaton',
    'Edinburgh',
    'Edwardsport',
    'Elberfeld',
    'Elizabeth',
    'Elizabethtown',
    'Elkhart',
    'Ellettsville',
    'Elwood',
    'English',
    'Evansville',
    'Evensville',
    'Fair Oaks',
    'Fairland',
    'Fairmount',
    'Farmersburg',
    'Ferdinand',
    'Fishers',
    'Flat Rock',
    'Flora',
    'Florence',
    'Floyds Knobs',
    'Fontanet',
    'Fort Branch',
    'Fort Wayne',
    'Fortville',
    'Fountain City',
    'Fowler',
    'Francesville',
    'Francisco',
    'Frankfort',
    'Franklin',
    'Frankton',
    'Freedom',
    'Fremont',
    'French Lick',
    'Fulda',
    'Garrett',
    'Gary',
    'Gas City',
    'Geneva',
    'Georgetown',
    'Glendale',
    'Goodland',
    'Goshen',
    'Goshen, Scott',
    'Gosport',
    'Grabill',
    'Grandview',
    'Granger',
    'Graysville',
    'Green Town',
    'Greencastle',
    'Greendale',
    'Greenfield',
    'Greens Fork',
    'Greensburg',
    'Greenville',
    'Greenwood, Johnson',
    'Griffin',
    'Griffith',
    'Guilford',
    'Hagerstown',
    'Hamilton',
    'Hamlet',
    'Hammond',
    'Hanover',
    'Harlan',
    'Hartford City',
    'Hartsdale',
    'Hebron',
    'Helmsburg',
    'Heltonville',
    'Henderson',
    'Henryville',
    'Highland',
    'Hillsboro',
    'Hillsdale',
    'Hoagland',
    'Hobart',
    'Hobbs',
    'Holland',
    'Holton',
    'Hoosierlift',
    'Hope',
    'Howe',
    'Hudson',
    'Huntertown',
    'Huntingburg',
    'Huntington',
    'Indianapolis',
    'Ingalls',
    'Inglefield',
    'Inwood',
    'Ireland',
    'Jamestown /Boone',
    'Jasonville',
    'Jasper',
    'Jefferson',
    'Jeffersonville',
    'Jerome',
    'Jonesville',
    'Kendallville',
    'Kentland',
    'Kewanna',
    'Keystone',
    'Kingman',
    'Kingsbury',
    'Kirklin',
    'Knightstown',
    'Knox',
    'Kokomo',
    'Kouts',
    'Kyana',
    'La Paz',
    'La Porte',
    'Lafayette',
    'Lagrange',
    'Lagro',
    'Lake Station',
    'Lake Village',
    'Lakeville',
    'Lamar',
    'Lanesville',
    'Laotto',
    'Lapel',
    'Laporte',
    'Larwill',
    'Lawrenceburg',
    'Leavenworth',
    'Lebanon',
    'Leesburg',
    'Leo-Cedarville',
    'Liberty',
    'Ligonier',
    'Linton',
    'Lizton',
    'Logansport',
    'Loogootee',
    'Lowell, Lake',
    'Lynn',
    'Madison',
    'Marengo',
    'Marion',
    'Markle',
    'Markleville',
    'Martinsville',
    'Matthews',
    'Mauckport',
    'Maxwell',
    'Mecca',
    'Memphis',
    'Mentone',
    'Merom',
    'Merrillville',
    'Michigan City',
    'Michigantown',
    'Middlebury',
    'Middletown',
    'Middletown, Allen',
    'Middletown, Shelby',
    'Milan',
    'Milford',
    'Millersburg',
    'Millville',
    'Milroy',
    'Mishawaka',
    'Mitchell',
    'Modoc',
    'Monon',
    'Monroe',
    'Monroe, Tippecanoe',
    'Monroeville',
    'Monrovia',
    'Montezuma',
    'Montgomery',
    'Monticello',
    'Montmorenci',
    'Montpelier',
    'Mooresville',
    'Morristown',
    'Mount Saint Francis',
    'Mount Summit',
    'Mount Vernon',
    'Muncie',
    'Munster',
    'Nabb',
    'Nappanee',
    'Nashville',
    'New Albany',
    'New Augusta',
    'New Carlisle',
    'New Castle',
    'New Harmony',
    'New Haven',
    'New Middletown',
    'New Palestine',
    'New Paris',
    'New Pekin',
    'New Richmond',
    'New Salisbury',
    'New Washington',
    'Newberry',
    'Newburgh',
    'Newport',
    'Newtonville',
    'Newtown',
    'Noblesville',
    'Norman',
    'North Judson',
    'North Liberty',
    'North Manchester',
    'North Vernon',
    'North Webster',
    'Oakford',
    'Oakland City',
    'Oaklandon',
    'Oaktown',
    'Odon',
    'Orestes',
    'Orland',
    'Orleans',
    'Osgood',
    'Ossian',
    'Otterbein',
    'Owensville',
    'Oxford',
    'Palmyra',
    'Paoli',
    'Paragon',
    'Patoka',
    'Pekin',
    'Pendleton',
    'Peru',
    'Petersburg',
    'Petroleum',
    'Pierceton',
    'Pimento',
    'Pittsboro',
    'Plainfield',
    'Plainville',
    'Pleasant Lake',
    'Plymouth',
    'Portage',
    'Porter',
    'Portland',
    'Poseyville',
    'Preble',
    'Princeton',
    'Putnamville',
    'Ramsey',
    'Reelsville',
    'Remington',
    'Rensselaer',
    'Reynolds',
    'Richmond',
    'Ridgeville',
    'Roann',
    'Roanoke',
    'Rochester',
    'Rockport',
    'Rockville',
    'Rolling Prairie',
    'Romney',
    'Rosedale',
    'Roselawn',
    'Rossville',
    'Royal Center',
    'Rushville',
    'Russellville',
    'Russiaville',
    'Saint Anthony',
    'Saint Croix',
    'Saint Joe',
    'Saint John',
    'Saint Marys',
    'Saint Meinrad',
    'Saint Paul',
    'Salem',
    'Sandford',
    'Santa Claus',
    'Schererville',
    'Scottsburg',
    'Sellersburg',
    'Selma',
    'Seymour',
    'Shadeland, Tippecanoe',
    'Sharpsville',
    'Shelby',
    'Shelbyville',
    'Sheridan',
    'Shipshewana',
    'Shoals',
    'Siberia',
    'Silver Lake',
    'South Bend',
    'South Milford',
    'South Whitley',
    'Southport',
    'Speed',
    'Speedway',
    'Spencer',
    'Spencerville',
    'Spiceland',
    'Starlight',
    'Straughn',
    'Sullivan',
    'Summitville',
    'Sunman',
    'Switz City',
    'Syracuse',
    'Taylorsville',
    'Tell City',
    'Terre Haute',
    'Tippecanoe',
    'Tipton',
    'Topeka',
    'Trafalgar',
    'Troy',
    'Twelve Mile',
    'Union City',
    'Upland',
    'Urbana',
    'Valparaiso',
    'Van Buren',
    'Veedersburg',
    'Vernon',
    'Versailles',
    'Vevay',
    'Vincennes',
    'Wabash',
    'Wadesville',
    'Wakarusa',
    'Walkerton',
    'Walton',
    'Wanatah',
    'Warren',
    'Warrick',
    'Warsaw',
    'Washington',
    'Waterloo',
    'Waterloo, Johnson',
    'Waveland',
    'Wawaka',
    'Wayne',
    'West College Corner',
    'West Harrison',
    'West Lafayette',
    'West Lebanon',
    'West Sayville',
    'West Terre Haute',
    'Westfield',
    'Westport',
    'Westville',
    'Wheatfield',
    'Whiteland',
    'Whitestown',
    'Whiting',
    'Winamac',
    'Winchester',
    'Windfall',
    'Wolcott',
    'Wolcottville',
    'Woodburn',
    'Woodstock',
    'Worthington',
    'Wyatt',
    'Yoder',
    'Yorktown',
    'Zionsville'
  ],
  KS: [
    'Abbyville',
    'Abilene',
    'Alma',
    'Alta Vista',
    'Altamont',
    'Andale',
    'Andover',
    'Anthony',
    'Arkansas City',
    'Asherville',
    'Ashland',
    'Assaria',
    'Atchison',
    'Attica',
    'Atwood',
    'Auburn',
    'Augusta',
    'Baldwin City',
    'Baxter Springs',
    'Bazine',
    'Belleville',
    'Belmont',
    'Beloit',
    'Belvue',
    'Benton',
    'Bern',
    'Berryton',
    'Bison',
    'Blue Mound',
    'Blue Rapids',
    'Bonner Springs',
    'Brazilton',
    'Buhler',
    'Burdett',
    'Burlington',
    'Burrton',
    'Bushton',
    'Caldwell',
    'Caney',
    'Carbondale',
    'Centralia',
    'Chanute',
    'Chapman',
    'Chase',
    'Cheney',
    'Cherryvale',
    'Circleville',
    'Claflin',
    'Clay Center',
    'Clearwater',
    'Coffeyville',
    'Colby',
    'Coldwater',
    'Colony',
    'Columbus',
    'Colwich',
    'Concordia',
    'Conway',
    'Council Grove',
    'Courtland',
    'Crestline',
    'Cunningham',
    'Danville',
    'De Soto',
    'Derby',
    'Dexter',
    'Dodge City',
    'Dorrance',
    'Douglass',
    'Downs',
    'Durham',
    'Edgerton',
    'Edson',
    'Edwardsville',
    'El Dorado',
    'Elkhart',
    'Ellis',
    'Ellsworth',
    'Elwood',
    'Emporia',
    'Enterprise',
    'Eudora',
    'Eureka',
    'Fairview',
    'Florence',
    'Fort Leavenworth',
    'Fort Riley',
    'Fort Scott',
    'Franklin',
    'Frontenac',
    'Galena',
    'Galesburg',
    'Garden City',
    'Gardner',
    'Garnett',
    'Gas',
    'Gem',
    'Girard',
    'Glen Elder',
    'Goddard',
    'Goodland',
    'Great Bend',
    'Greenleaf',
    'Greensburg',
    'Gridley',
    'Gypsum',
    'Hallowell',
    'Halstead',
    'Harper',
    'Haven',
    'Hays',
    'Haysville',
    'Healy',
    'Herkimer',
    'Hesston',
    'Hiawatha',
    'Hickok',
    'Highland',
    'Hill City',
    'Hillsboro',
    'Hillsdale',
    'Hoisington',
    'Holcomb',
    'Holton',
    'Hope',
    'Horton',
    'Hoxie',
    'Hoyt',
    'Hugoton',
    'Humboldt',
    'Hutchinson',
    'Independence',
    'Iola',
    'Jewell',
    'Johnson',
    'Junction City',
    'Kalvesta',
    'Kansas City',
    'Kincaid',
    'Kingman',
    'Kirwin',
    'Kismet',
    'La Crosse',
    'La Harpe',
    'Lacygne',
    'Lake City',
    'Lansing',
    'Larned',
    'Latham',
    'Lawrence',
    'Le Roy',
    'Leavenworth',
    'Leawood',
    'Lebanon',
    'Lenexa',
    'Lenora',
    'Leonardville',
    'Leoti',
    'Liberal',
    'Liberty',
    'Lincoln',
    'Lindsborg',
    'Linwood',
    'Long Island',
    'Lorraine',
    'Louisburg',
    'Louisville',
    'Lucas',
    'Lyndon',
    'Lyons',
    'Maize',
    'Manhattan',
    'Maple Hill',
    'Marion',
    'Marysville',
    'Mayetta',
    'Mclouth',
    'McPherson',
    'Meade',
    'Medicine Lodge',
    'Medora',
    'Meriden',
    'Merriam',
    'Milan',
    'Mingo',
    'Minneapolis',
    'Minneola',
    'Mission',
    'Monett',
    'Montezuma',
    'Monument',
    'Moscow',
    'Moundridge',
    'Mount Hope',
    'Mulvane',
    'Muncie',
    'Nashville',
    'Natoma',
    'Neodesha',
    'Ness City',
    'New Century',
    'New Strawn',
    'Newton',
    'North Newton',
    'Norton',
    'Nortonville',
    'Norwich',
    'Oakley',
    'Olathe',
    'Olsburg',
    'Onaga',
    'Osage City',
    'Osborne',
    'Oskaloosa',
    'Otis',
    'Ottawa',
    'Overbrook',
    'Overland Park',
    'Paradise',
    'Park',
    'Park City',
    'Parsons',
    'Pauline',
    'Paxico',
    'Peabody',
    'Phillipsburg',
    'Piedmont',
    'Pittsburg',
    'Plains',
    'Plainville',
    'Pleasanton',
    'Prairie Village',
    'Pratt',
    'Protection',
    'Quinter',
    'Rantoul',
    'Rexford',
    'Richmond',
    'Riley',
    'Riverton',
    'Roeland Park',
    'Rolla',
    'Rose Hill',
    'Rosedale',
    'Rossville',
    'Rush Center',
    'Russell',
    'Russell Springs',
    'Sabetha',
    'Saint Francis',
    'Saint George',
    'Saint John',
    'Saint Marys',
    'Salina',
    'Satanta',
    'Scammon',
    'Scott City',
    'Sedan',
    'Seneca',
    'Shawnee',
    'Shawnee Mission',
    'Smith Center',
    'Solomon',
    'South Hutchinson',
    'Spearville',
    'Spring Hill',
    'Stafford',
    'Sterling',
    'Stilwell',
    'Stockton',
    'Sublette',
    'Sun City',
    'Tampa',
    'Tecumseh',
    'Thayer',
    'Tipton',
    'Tonganoxie',
    'Topeka',
    'Toronto',
    'Towanda',
    'Troy',
    'Ulysses',
    'Valley Center',
    'Valley Falls',
    'Vassar',
    'Wakeeney',
    'Walnut',
    'Walton',
    'Wamego',
    'Washington',
    'Waterville',
    'Wathena',
    'Waverly',
    'Wellington',
    'Wellsville',
    'Weskan',
    'Westmoreland',
    'Westwood',
    'White Cloud',
    'Wichita',
    'Windom',
    'Winfield',
    'Wright'
  ],
  KY: [
    'Aberdeen',
    'Adairville',
    'Albany',
    'Alexandria',
    'Allen',
    'Annville',
    'Ashland',
    'Auburn',
    'Augusta',
    'Auxier',
    'Avon',
    'Barbourville',
    'Bardstown',
    'Barlow',
    'Battletown',
    'Beattyville',
    'Beaver Dam',
    'Bedford',
    'Beech Grove',
    'Belfry',
    'Bellevue',
    'Belton',
    'Benton',
    'Berea',
    'Berlin',
    'Berry',
    'Bethanna',
    'Bethlehem',
    'Blaze',
    'Bloomfield',
    'Boston',
    'Bowling Green',
    'Brandenburg',
    'Breeding',
    'Brodhead',
    'Bromley',
    'Bronston',
    'Brooklyn',
    'Brooks',
    'Brooksville',
    'Buckner',
    'Buechel',
    'Burkesville',
    'Burlington',
    'Burnside',
    'Butler',
    'Cadiz',
    'Calhoun',
    'Calvert City',
    'Campbellsburg',
    'Campbellsville',
    'Campton',
    'Caneyville',
    'Canmer',
    'Carrollton',
    'Carter',
    'Catlettsburg',
    'Cave City',
    'Cecilia',
    'Centertown',
    'Central City',
    'Chavies',
    'Cincinnati',
    'Clay',
    'Clay City',
    'Clermont',
    'Clifford',
    'Cloverport',
    'Cold Spring',
    'Columbia',
    'Columbus',
    'Conley',
    'Conway',
    'Corbin',
    'Covington',
    'Coxs Creek',
    'Crestview Hills',
    'Crestwood',
    'Crittenden',
    'Crockett',
    'Crofton',
    'Cromwell',
    'Cumberland',
    'Cynthiana',
    'Danville',
    'Dawson Springs',
    'Dayton',
    'Debord',
    'Derby',
    'Dixon',
    'Doe Run',
    'Dover',
    'Drakesboro',
    'Dry Ridge',
    'Dunnville',
    'Earlington',
    'East Bernstadt',
    'East Point',
    'Eastwood',
    'Eddyville',
    'Edgewood',
    'Edmonton',
    'Elizabethtown',
    'Elk Horn',
    'Elkton',
    'Eminence',
    'Erlanger',
    'Eubank',
    'Fairdale',
    'Falls of Rough',
    'Falmouth',
    'Farmington',
    'Firebrick',
    'Flemingsburg',
    'Florence',
    'Fordsville',
    'Fort Campbell',
    'Fort Knox',
    'Fort Mitchell',
    'Fort Thomas',
    'Fort Wright',
    'Fountain Run',
    'Frankfort',
    'Franklin',
    'Fredonia',
    'Fulton',
    'Georgetown',
    'Germantown',
    'Ghent',
    'Gilbertsville',
    'Glasgow',
    'Glencoe',
    'Gordonville',
    'Goshen',
    'Grahn',
    'Grand Rivers',
    'Gravel Switch',
    'Gray',
    'Grayson',
    'Greensburg',
    'Greenup',
    'Greenville',
    'Grethel',
    'Guthrie',
    'Hagerhill',
    'Hanson',
    'Hardinsburg',
    'Harlan',
    'Harold',
    'Harrodsburg',
    'Hartford',
    'Hawesville',
    'Hazard',
    'Hazel',
    'Hazel Green',
    'Hebron',
    'Henderson',
    'Hestand',
    'Hickman',
    'Hickory',
    'Hillview',
    'Hindman',
    'Hitchins',
    'Hodgenville',
    'Holmes Mill',
    'Hopkinsville',
    'Horse Cave',
    'Hustonville',
    'Hyden',
    'Independence',
    'Irvine',
    'Ivel',
    'Jackson',
    'Jamestown',
    'Jeffersontown',
    'Jeffersonville',
    'Jinks',
    'Kansas',
    'Keene',
    'Kettle',
    'Kevil',
    'Kimper',
    'La Center',
    'La Fayette',
    'La Grange',
    'LaFayette',
    'Lancaster',
    'Latonia',
    'Lawrenceburg',
    'Lawrenceville',
    'Leatherwood',
    'Lebanon',
    'Lebanon Junction',
    'Leitchfield',
    'Lewisburg',
    'Lewisport',
    'Lexington',
    'Liberty',
    'Livermore',
    'Logansport',
    'London',
    'Loretto',
    'Louisa',
    'Louisville',
    'Lowmansville',
    'Ludlow',
    'Lynch',
    'Lyndon',
    'Maceo',
    'Madisonville',
    'Magnolia',
    'Manchester',
    'Marion',
    'Marrowbone',
    'Martin',
    'Mayfield',
    'Maysville',
    'Means',
    'Middlesboro',
    'Midway',
    'Millersburg',
    'Millwood',
    'Milton',
    'Minnie',
    'Miracle',
    'Mize',
    'Monticello',
    'Moorefield',
    'Morehead',
    'Morganfield',
    'Morgantown',
    'Mortons Gap',
    'Mount Eden',
    'Mount Sterling',
    'Mount Vernon',
    'Mount Washington',
    'Mouthcard',
    'Munfordville',
    'Murray',
    'Nebo',
    'New Castle',
    'New Hope',
    'Newport',
    'Nicholasville',
    'Nortonville',
    'Oakland',
    'Oakville',
    'Olive Hill',
    'Oven Fork',
    'Owensboro',
    'Owenton',
    'Owingsville',
    'Paducah',
    'Paintsville',
    'Paris',
    'Park City',
    'Parkers Lake',
    'Petersburg',
    'Pewee Valley',
    'Phelps',
    'Philpot',
    'Pikeville',
    'Pine Knot',
    'Pineville',
    'Pleasure Ridge Park',
    'Pleasureville',
    'Prestonsburg',
    'Princeton',
    'Prospect',
    'Providence, Webster',
    'Raceland',
    'Radcliff',
    'Ravenna',
    'Richardson',
    'Richmond',
    'Richwood',
    'Robards',
    'Rochester',
    'Rockfield',
    'Roxana',
    'Rumsey',
    'Rush',
    'Russell',
    'Russell Springs',
    'Russellville',
    'Sacramento',
    'Saint Helens',
    'Salem',
    'Salvisa',
    'Salyersville',
    'Science Hill',
    'Scottsville',
    'Sebree',
    'Sharpsburg',
    'Shelbyville',
    'Shepherdsville',
    'Shively',
    'Sidney',
    'Silver Grove',
    'Simpsonville',
    'Smith',
    'Smithfield',
    'Smiths Grove',
    'Somerset',
    'Sonora',
    'South Shore',
    'South Union',
    'Southgate',
    'Springfield',
    'Stamping Ground',
    'Stanford',
    'Stanton',
    'Stanville',
    'Stoney Fork',
    'Sturgis',
    'Summer Shade',
    'Symsonia',
    'Taylor Mill',
    'Taylorsville',
    'Tompkinsville',
    'Trenton',
    'Turners Station',
    'Union',
    'Uniontown',
    'Vanceburg',
    'Verona',
    'Versailles',
    'Vicco',
    'Vine Grove',
    'Virgie',
    'Wallingford',
    'Walton',
    'Warsaw',
    'West Liberty',
    'West Louisville',
    'White Plains',
    'Whitesburg',
    'Whitley City',
    'Whitner',
    'Wickliffe',
    'Wilder',
    'Williamsburg',
    'Williamstown',
    'Willisburg',
    'Wilmore',
    'Winchester',
    'Wolf Creek',
    'Woodburn',
    'Woodman',
    'Woorburn',
    'Worthington',
    'Wurtland',
    'York'
  ],
  LA: [
    'Abbeville',
    'Abita Springs',
    'Addis',
    'Akers',
    'Albany',
    'Alexandria',
    'Algiers',
    'Alliance',
    'Ama',
    'Amelia',
    'Amite',
    'Angola',
    'Arabi',
    'Arcadia',
    'Arcola',
    'Arnaudville',
    'Avery Island',
    'Avondale',
    'Baker',
    'Baldwin',
    'Ball',
    'Baskin',
    'Bastrop',
    'Batchelor',
    'Baton Rouge',
    'Bayou Vista',
    'Beekman',
    'Belle Chase',
    'Belle Chasse',
    'Benton',
    'Bernice',
    'Berwick',
    'Bluff Creek',
    'Bogalusa',
    'Bossier City',
    'Bourg',
    'Boutte',
    'Boyce',
    'Braithwaite',
    'Breaux Bridge',
    'Brian',
    'Bridge City',
    'Broussard, Lafayette',
    'Brusly',
    'Bunkie',
    'Buras',
    'Burnside',
    'Bush',
    'Cade',
    'Calcasieu',
    'Cameron',
    'Campti',
    'Carencro',
    'Carlyss',
    'Carterville',
    'Carville',
    'Centerville',
    'Chalmette',
    'Chauvin',
    'Chopin',
    'Choudrant',
    'Church Point',
    'Clinton',
    'Colfax',
    'Columbia',
    'Convent',
    'Converse',
    'Cotton Valley',
    'Coushatta',
    'Covington',
    'Crowley',
    'Cullen',
    'Cut Off',
    'Darrow',
    'Davant',
    'De Ridder',
    'Deer Park',
    'Delhi',
    'Denham Springs',
    'DeQuincy',
    'Deridder',
    'Destrehan',
    'Dixie',
    'Dodson',
    'Donaldsonville',
    'Downsville',
    'Effie',
    'Elton',
    'Empire',
    'Erath',
    'Eunice',
    'Farmerville',
    'Ferriday',
    'Florien',
    'Folsom',
    'Forbing',
    'Forest Hill',
    'Fort Polk',
    'Franklin',
    'Franklinton',
    'Galliano',
    'Garyville',
    'Geismar',
    'Gibson',
    'Golden Meadow',
    'Goldonna',
    'Gonzales',
    'Good Hope',
    'Grambling',
    'Gramercy',
    'Grand Chenier',
    'Grand Coteau',
    'Grand Isle',
    'Gray',
    'Greenwell Springs',
    'Greenwood',
    'Gretna',
    'Gueydan',
    'Hackberry',
    'Hahnville',
    'Hammond',
    'Harahan',
    'Harrisonburg',
    'Harvey',
    'Haughton',
    'Haynesville',
    'Hessmer',
    'Hodge',
    'Holden',
    'Holly Ridge',
    'Homer',
    'Houma',
    'Independence',
    'Iowa',
    'Jackson',
    'Jeanerette',
    'Jefferson',
    'Jefferson Island',
    'Jena',
    'Jennings',
    'Jonesville',
    'Kaplan',
    'Keithville',
    'Kendall',
    'Kenner',
    'Kentwood',
    'Killona',
    'Kinder',
    'Krotz Springs',
    'La Place',
    'Lacassine',
    'Lacombe',
    'Lafayette',
    'Lafitte',
    'Lake Arthur',
    'Lake Charles',
    'Lake Providence',
    'Lakeland',
    'Larose',
    'Le Compte',
    'Le Moyen',
    'Leblanc',
    'Lecompte',
    'Leesville',
    'Lena',
    'Livingston',
    'Lockport',
    'Logansport',
    'Longville',
    'Loop Terminal',
    'Loreauville',
    'Louisiana Offshore Oil Port (Loop)',
    'Luling',
    'Lutcher',
    'Madisonville',
    'Mamou',
    'Mandeville',
    'Mansfield',
    'Mansura',
    'Many',
    'Maringouin',
    'Marion',
    'Marksville',
    'Marrero',
    'Maryland',
    'Mathews',
    'Mer Rouge',
    'Meraux',
    'Mermentau',
    'Metairie',
    'Minden',
    'Monroe',
    'Montgomery',
    'Montz',
    'Morgan City',
    'Morgan City-Patterson Apt',
    'Morris',
    'Mount Airy',
    'Myrtle Grove',
    'Napoleonville',
    'Natchitoches',
    'New Iberia',
    'New Orleans',
    'New Roads',
    'Newellton',
    'Norco',
    'Oak Grove',
    'Oakdale',
    'Oakland',
    'Oil City',
    'Opelousas',
    'Ostrica',
    'Paincourtville',
    'Palmetto, Bossier',
    'Palmetto, Saint Landry',
    'Patterson',
    'Patterson-Morgan City Apt',
    'Pearl River',
    'Pelican',
    'Pierre Part',
    'Pilottown',
    'Pineville',
    'Plaquemine',
    'Plaucheville',
    'Pleasant Hill',
    'Pointe a la Hache',
    'Pollock',
    'Ponchatoula',
    'Port Allen',
    'Port Barre',
    'Port Eads',
    'Port Fourchon',
    'Port Hudson',
    'Port Of South Louisiana',
    'Port Sulphur',
    'Prairieville',
    'Princeton',
    'Raceland',
    'Rayne',
    'Rayville',
    'Reddell',
    'Reserve',
    'River Ridge',
    'Riverside',
    'Robeline',
    'Romeville',
    'Roseland',
    'Rosepine',
    'Ruston',
    'Saint Amant',
    'Saint Bernard Parish',
    'Saint Charles',
    'Saint Francisville',
    'Saint Gabriel',
    'Saint James',
    'Saint James Parish',
    'Saint Joe',
    'Saint Joseph',
    'Saint Louis',
    'Saint Martinville',
    'Saint Rose',
    'Saint Rose Plantation',
    'Sarepta',
    'Schriever',
    'Scott',
    'Shreveport',
    'Sibley',
    'Sicily Island',
    'Simsboro',
    'Singer',
    'Slaughter',
    'Slidell',
    'Somerset',
    'Sorrento',
    'South Bend',
    'Springhill',
    'Sterlington',
    'Stonewall',
    'Sulphur',
    'Sunset',
    'Sunshine',
    'Supreme',
    'Taft',
    'Talla Bena',
    'Tallulah',
    'Taylor',
    'Terrytown',
    'Thibodaux',
    'Tioga',
    'Trenton',
    'Urania',
    'Vacherie',
    'Venice',
    'Vidalia',
    'Ville Platte',
    'Vinton',
    'Violet',
    'Vivian',
    'Waggaman',
    'Walker, Jackson',
    'Walker, Livingston',
    'Washington',
    'Waterproof',
    'Weeks',
    'Welcome',
    'Welsh',
    'West Monroe',
    'Westlake',
    'Westwego',
    'White Castle',
    'Winnfield',
    'Winnsboro',
    'Woodworth',
    'Youngsville',
    'Zachary',
    'Zwolle',
    'Timberlane'
  ],
  MA: [
    'Abington',
    'Accord',
    'Acton',
    'Acushnet',
    'Adams',
    'Agawam',
    'Alford',
    'Allston',
    'Amesbury',
    'Amherst',
    'Andover',
    'Annisquam Harbour',
    'Arlington',
    'Ashburnham',
    'Ashby',
    'Ashfield',
    'Ashland',
    'Assonet',
    'Athol',
    'Attleboro',
    'Attleboro Falls',
    'Auburn',
    'Auburndale',
    'Avon',
    'Ayer',
    'Baldwinville',
    'Barnstable',
    'Barre',
    'Beachwood',
    'Becket',
    'Bedford',
    'Belchertown',
    'Bellingham',
    'Belmont',
    'Berkley',
    'Berlin',
    'Bernardston',
    'Beverly',
    'Billerica',
    'Blackstone',
    'Blandford',
    'Bolton',
    'Bondsville',
    'Boston',
    'Bourne',
    'Boxboro',
    'Boxborough',
    'Boxford',
    'Boylston',
    'Bradford',
    'Braintree',
    'Brayton Point',
    'Brewster',
    'Bridgewater',
    'Brighton',
    'Brimfield',
    'Brockton',
    'Brookfield',
    'Brookline',
    'Buckland',
    'Burlington',
    'Buzzards Bay',
    'Byfield',
    'Cambridge',
    'Canton',
    'Carlisle',
    'Carver',
    'Cataumet',
    'Centerville',
    'Charlemont',
    'Charlestown',
    'Charlton',
    'Chatham',
    'Chelmsford',
    'Chelsea',
    'Cheshire',
    'Chester Center',
    'Chesterfield',
    'Chestnut Hill',
    'Chicopee',
    'Chilmark',
    'Clinton',
    'Cohasset',
    'Colrain',
    'Concord',
    'Conway',
    'Cummington',
    'Dalton',
    'Danvers',
    'Dartmouth',
    'Dedham',
    'Deerfield',
    'Dennis',
    'Devens',
    'Devenscrest',
    'Dighton',
    'Dorchester',
    'Dover',
    'Dracut',
    'Dudley',
    'Dunstable',
    'Duxbury',
    'East Boston',
    'East Bridgewater',
    'East Brookfield',
    'East Cambridge',
    'East Dennis',
    'East Douglas',
    'East Falmouth',
    'East Freetown',
    'East Harwich',
    'East Longmeadow',
    'East Mansfield',
    'East Otis',
    'East Sandwich',
    'East Taunton',
    'East Templeton',
    'East Walpole',
    'East Weymouth',
    'Eastham',
    'Easthampton',
    'Easton',
    'Edgartown',
    'Eliot',
    'Erving',
    'Essex',
    'Everett',
    'Fairhaven',
    'Fall River',
    'Fall River-New Bedford Apt',
    'Falmouth',
    'Fayville',
    'Feeding Hills',
    'Fiskdale',
    'Fitchburg',
    'Florence',
    'Florida',
    'Fort Devens',
    'Foxboro',
    'Foxborough',
    'Framingham',
    'Franklin',
    'Gardner',
    'Georgetown',
    'Gilbertville',
    'Glendale',
    'Gloucester',
    'Goshen',
    'Grafton',
    'Granby',
    'Granville',
    'Great Barrington',
    'Greenfield',
    'Greenville',
    'Griswoldville',
    'Groton',
    'Groveland',
    'Hadley',
    'Halifax',
    'Hampden',
    'Hanover',
    'Hanson',
    'Hardwick',
    'Harvard',
    'Harwich',
    'Hatfield',
    'Haverhill',
    'Haydenville',
    'Heath',
    'Hingham',
    'Hinsdale',
    'Holbrook',
    'Holden',
    'Holland',
    'Holliston',
    'Holyoke',
    'Hopedale',
    'Hopkinton',
    'Housatonic',
    'Hubbardston',
    'Hudson',
    'Hull',
    'Huntington',
    'Hyannis',
    'Hyde Park',
    'Indian Orchard',
    'Ipswich',
    'Jamaica Plain',
    'Kingston',
    'Lakeville',
    'Lancaster',
    'Lanesborough',
    'Lawrence',
    'Lee',
    'Leeds',
    'Leicester',
    'Lenox',
    'Lenox Dale',
    'Leominster',
    'Leverett',
    'Lexington',
    'Lincoln',
    'Linwood',
    'Littleton',
    'Longmeadow',
    'Lowell',
    'Ludlow',
    'Lunenburg',
    'Lynn',
    'Lynnfield',
    'Magnolia',
    'Malden',
    'Manchaug',
    'Manchester',
    'Mansfield',
    'Marblehead',
    'Marion',
    'Marlboro (Marlborough)',
    'Marlborough, Middlesex',
    'Marshfield',
    "Martha's Vineyard",
    'Mashpee',
    'Mattapoisett',
    'Maynard',
    'Medfield',
    'Medford',
    'Medway',
    'Melrose',
    'Mendon',
    'Menemsha',
    'Merrimac',
    'Methuen',
    'Middleboro',
    'Middlefield',
    'Middleton',
    'Mildford',
    'Milford',
    'Millbury',
    'Millis',
    'Millville',
    'Milton',
    'Monroe Bridge',
    'Monson',
    'Montague',
    'Monterey',
    'Nahant',
    'Nantucket',
    'Natick',
    'Needham',
    'Needham Heights',
    'New Bedford',
    'New Bedford-Fall River Apt',
    'New Braintree',
    'New Marlborough',
    'New Salem',
    'Newbury',
    'Newburyport',
    'Newton',
    'Newton Highlands',
    'Newton Upper Falls',
    'Newtonville',
    'Norfolk',
    'North Abington',
    'North Adams',
    'North Andover',
    'North Attleboro',
    'North Billerica',
    'North Brookfield',
    'North Chelmsford',
    'North Dartmouth',
    'North Dighton',
    'North Eastham',
    'North Falmouth',
    'North Grafton',
    'North Oxford',
    'North Pembroke',
    'North Reading',
    'North Scituate',
    'North Truro',
    'North Weymouth',
    'Northampton',
    'Northborough',
    'Northbridge',
    'Northfield',
    'Norton',
    'Norwell',
    'Norwood',
    'Nutting Lake',
    'Oak Bluffs',
    'Oakham',
    'Onset',
    'Orange',
    'Orleans',
    'Osterville',
    'Otis',
    'Otter River',
    'Oxford',
    'Palmer',
    'Paxton',
    'Peabody',
    'Pembroke',
    'Pepperell',
    'Petersham',
    'Phillipston',
    'Pinehurst',
    'Pittsfield',
    'Plainfield',
    'Plainville',
    'Plymouth',
    'Plympton',
    'Pocasset',
    'Princeton',
    'Provincetown',
    'Quincy',
    'Randolph',
    'Raynham',
    'Reading',
    'Readville',
    'Rehoboth',
    'Revere',
    'Richmond',
    'Rochdale',
    'Rochester',
    'Rockland',
    'Rockport',
    'Roslindale',
    'Rowe',
    'Rowley',
    'Roxbury',
    'Royalston',
    'Russell',
    'Rutland',
    'Sagamore',
    'Sagamore Beach',
    'Salem',
    'Salisbury',
    'Sandisfield',
    'Sandwich',
    'Saugus',
    'Savoy',
    'Scituate',
    'Seekonk',
    'Sharon',
    'Sheffield',
    'Shelburne Falls',
    'Sheldonville',
    'Sherborn',
    'Shirley',
    'Shrewsbury',
    'Shutesbury',
    'Silver Beach',
    'Somerset',
    'Somerville',
    'South Attleboro',
    'South Barre',
    'South Boston',
    'South Dartmouth',
    'South Deerfield',
    'South Dennis',
    'South Easton',
    'South Grafton',
    'South Hadley',
    'South Hamilton',
    'South Lee',
    'South Natick',
    'South Walpole',
    'South Wellfleet',
    'South Weymouth',
    'South Yarmouth',
    'Southampton',
    'Southborough',
    'Southbridge',
    'Southfield',
    'Southwick',
    'Spencer',
    'Springfield',
    'Sterling',
    'Stockbridge',
    'Stoneham',
    'Stoughton',
    'Stow',
    'Sturbridge',
    'Sudbury',
    'Sunderland',
    'Sutton',
    'Swampscott',
    'Swansea',
    'Taunton',
    'Tauton',
    'Templeton',
    'Tewksbury',
    'Three Rivers',
    'Tisbury',
    'Topsfield',
    'Townsend',
    'Truro',
    'Turners Falls',
    'Tyngsboro',
    'Upton',
    'Uxbridge',
    'Vineyard Haven',
    'Waban',
    'Wakefield',
    'Wales',
    'Walpole',
    'Waltham',
    'Ward Hill',
    'Ware',
    'Wareham',
    'Warren',
    'Warwick',
    'Watertown',
    'Waterville',
    'Waverley',
    'Wayland',
    'Webster',
    'Wellesley',
    'Wellfleet',
    'Wendell',
    'Wenham',
    'West Boylston',
    'West Bridgewater',
    'West Brookfield',
    'West Chesterfield',
    'West Chop',
    'West Concord',
    'West Gloucester',
    'West Groton',
    'West Hanover',
    'West Harwich',
    'West Hatfield',
    'West Newbury',
    'West Newton',
    'West Roxbury',
    'West Springfield',
    'West Stockbridge',
    'West Tisbury',
    'West Wareham',
    'West Warren',
    'West Yarmouth',
    'Westborough',
    'Westchester',
    'Westfield',
    'Westford',
    'Westminster',
    'Weston',
    'Westport',
    'Westport Point',
    'Westwood',
    'Weymouth',
    'Whately',
    'Whitinsville',
    'Whitman',
    'Wilbraham',
    'Wilkinsonville',
    'Williamsburg',
    'Williamstown',
    'Wilmington',
    'Winchendon',
    'Winchester',
    'Windsor',
    'Winthrop',
    'Woburn',
    'Woods Hole',
    'Worcestar',
    'Worcester',
    'Woronoco',
    'Worthington Center',
    'Wrentham',
    'Yarmouth',
    'Yarmouth Port'
  ],
  MD: [
    'Aberdeen',
    'Abingdon',
    'Accident',
    'Accokeek',
    'Adamstown',
    'Adelphi',
    'Andrews AFB',
    'Annapolis',
    'Annapolis Junction',
    'Arbutus',
    'Arlington/Baltimore',
    'Arnold',
    'Arundel Village',
    'Ashton',
    'Avenel/Washington, D.C.',
    'Baltimore',
    'Baltimore-Washington Int Apt',
    'Barclay',
    'Beallsville',
    'Bel Air',
    'Belcamp',
    'Beltsville',
    'Bengies',
    'Berlin',
    'Bethesda',
    'Bishopville',
    'Bladensburg',
    'Boonsboro',
    'Bowie',
    'Boyds',
    'Braddock Heights',
    'Brandywine',
    'Brentwood',
    'Bridgetown',
    'Brookeville',
    'Brooklandville',
    'Brooklyn',
    'Brooklyn Park',
    'Brunswick',
    'Burtonsville',
    'Butler',
    'Cabin John',
    'California',
    'Calverton',
    'Cambridge',
    'Camp Springs',
    'Capitol Heights',
    'Cascade',
    'Catonsville',
    'Cavetown',
    'Cecilton',
    'Centreville',
    'Charlotte Hall',
    'Cheltenham',
    'Chesapeake City',
    'Chester',
    'Chestertown',
    'Cheverly',
    'Chevy Chase',
    'Churchville',
    'Claiborne',
    'Clarksbourg',
    'Clarksburg',
    'Clarksville',
    'Clear Spring',
    'Clinton',
    'Cockeysville',
    'College Park',
    'Colora',
    'Columbia',
    'Conowingo',
    'Cooksville',
    'Cordova',
    'Cottage City',
    'Cove Point',
    'Crisfield',
    'Crofton',
    'Cumberland',
    'Curtis Bay, Baltimore',
    'Damascus',
    'Darlington',
    'Davidsonville',
    'Deer Park',
    'Delmar',
    'Denton',
    'Derwood',
    'Detour',
    'District Heights',
    'Dorsey',
    'Drayden',
    'Dundalk',
    'Dunkirk',
    'Earleville',
    'Easton',
    'Edgemere',
    'Edgewater',
    'Edgewood',
    'Edmonston',
    'Eldersburg',
    'Elkridge',
    'Elkton',
    'Ellicott City',
    'Essex',
    'Fallston',
    'Faulkner',
    'Federalsburg',
    'Finksburg',
    'Flintstone',
    'Forest Hill',
    'Forestville',
    'Fork',
    'Fort George G Meade',
    'Fort Meade',
    'Fort Washington',
    'Frederick',
    'Frederick Village',
    'Friendship',
    'Friendsville',
    'Frostburg',
    'Fruitland',
    'Fulton',
    'Gaithersburg',
    'Galena',
    'Galesville',
    'Gambrills',
    'Garrison',
    'Germantown',
    'Glen Allen',
    'Glen Arm',
    'Glen Burnie',
    'Glenarden',
    'Glendale',
    'Glenndale',
    'Glenwood',
    'Glyndon',
    'Grantsville',
    'Grasonville',
    'Great Mills',
    'Greenbelt',
    'Greenmount',
    'Gwynn Oak',
    'Hagerstown',
    'Halethorpe',
    'Hampstead',
    'Hancock',
    'Hanover',
    'Harmans',
    'Harwood',
    'Havre de Grace',
    'Hebron',
    'Henryton',
    'Highland',
    'Highlandtown',
    'Hollywood',
    'Hunt Valley',
    'Huntingtown',
    'Hurlock',
    'Hyattsville',
    'Ijamsville',
    'Indian Head',
    'Jarrettsville',
    'Jessup',
    'Joppa',
    'Kennedyville',
    'Kensington',
    'Kettering',
    'Keymar',
    'Keysville',
    'Kingsville',
    'La Plata',
    'La Vale',
    'Landover',
    'Langley Park',
    'Lanham',
    'Lansdowne',
    'Lansdowne-Baltimore',
    'Largo',
    'Laurel',
    'Leeds',
    'Leonardtown',
    'Lexington Park',
    'Libertytown',
    'Linkwood',
    'Linthicum',
    'Linthicum Heights',
    'Long Green',
    'Lothian',
    'Luke',
    'Lusby',
    'Lutherville-Timonium',
    'Manchester',
    'Marlow Heights',
    'Martin Mariet/Baltimore',
    'Maryland City',
    'Massey',
    'McHenry',
    'Middle River',
    'Middletown',
    'Middletown, Frederick',
    'Milford Mill',
    'Millersville',
    'Millington',
    'Mitchellville',
    'Monkton',
    'Monrovia',
    'Montgomery Village',
    'Mount Airy',
    'Mount Hebron',
    'Mount Savage',
    'Mountain Lake Park',
    'Myersville',
    'Nanticoke',
    'New Carrollton',
    'New Market',
    'New Windsor',
    'Newburg',
    'Newmarket',
    'North Beach',
    'North Bethesda',
    'North East',
    'North Potomac',
    'Nottingham',
    'Oakland',
    'Oakland Mills',
    'Ocean City',
    'Odenton',
    'Oldtown',
    'Olney',
    'Orchard Beach',
    'Owings',
    'Owings Mills',
    'Oxford',
    'Oxon Hill',
    'Parkton',
    'Parkville',
    'Parsonsburg',
    'Pasadena',
    'Patuxent River',
    'Perry Hall',
    'Perryman',
    'Perryville',
    'Phoenix',
    'Pikesville',
    'Piney Point',
    'Pinto',
    'Pittsville',
    'Pocomoke City',
    'Pomfret',
    'Port Deposit',
    'Portland',
    'Potomac',
    'Preston',
    'Prince Frederick',
    'Princess Anne',
    'Quantico',
    'Queen Anne',
    'Queenstown',
    'Randallstown',
    'Reisterstown',
    'Relay',
    'Rhodesdale',
    'Ridgely',
    'Rising Sun',
    'Riva',
    'Riverdale',
    'Rockville',
    'Rosedale',
    'Sabillasville',
    'Saint Charles',
    'Saint Inigoes',
    'Saint Marys City',
    'Saint Michaels',
    'Salisbury',
    'Sandy Spring',
    'Savage',
    'Severn',
    'Severna Park',
    'Showell',
    'Silver Spring',
    'Smithsburg',
    'Snow Hill',
    'Solomons',
    'South Baltimore',
    'Sparks',
    'Sparks Glencoe',
    'Sparrows Point',
    'Springfield',
    'Stevensville',
    'Street',
    'Sudlersville',
    'Sugarland',
    'Suitland',
    'Sykesville',
    'Takoma Park',
    'Taneytown',
    'Temple Hills',
    'Texas',
    'Thurmont',
    'Timonium',
    'Towson',
    'Tracys Landing',
    'Trappe',
    'Tuxedo',
    'Union Bridge',
    'Upper Malboro',
    'Upper Marlboro',
    'Upperco',
    'Vienna',
    'Wagners Point',
    'Waldorf',
    'Walkersville',
    'Warwick',
    'Washington-Baltimore Int Apt',
    'Waverly',
    'West Friendship',
    'West Ocean City',
    'Westernport',
    'Westminster',
    'Westover',
    'Westport',
    'Whaleysville',
    'Wheaton',
    'White Marsh',
    'White Marsh Station',
    'White Oak',
    'White Plains',
    'Whiteford',
    'Williamsport',
    'Windsor Mill',
    'Wittman',
    'Woodbine',
    'Woodlawn',
    'Woodsboro',
    'Woodstock',
    'Worton',
    'Wye Mills',
    'Wyemills'
  ],
  ME: [
    'Abbot',
    'Acton',
    'Addison',
    'Albion',
    'Alfred',
    'Alna',
    'Amherst',
    'Anson',
    'Arundel',
    'Ashland',
    'Athens',
    'Auburn',
    'Auburn-Lewiston Apt',
    'Augusta',
    'Aurora',
    'Avon Lake',
    'Bailey Island',
    'Baileyville',
    'Bangor',
    'Bar Harbor',
    'Bass Harbor',
    'Bath',
    'Beals',
    'Belfast',
    'Belgrade',
    'Benton',
    'Berwick',
    'Bethel',
    'Biddeford',
    'Bingham',
    'Blaine',
    'Blue Hill',
    'Boothbay',
    'Boothbay Harbor',
    'Bowdoin',
    'Bowdoinham',
    'Bradford',
    'Bradley',
    'Bremen',
    'Brewer',
    'Bridgewater',
    'Bridgton',
    'Bristol',
    'Brooklin, Hancock',
    'Brooks',
    'Brooksville',
    'Brownfield',
    'Brownville',
    'Brunswick',
    'Buckfield',
    'Bucksport',
    'Buffalo',
    'Burlington',
    'Burnham',
    'Buxton',
    'Calais',
    'Cambridge',
    'Camden',
    'Canaan',
    'Canton',
    'Cape Elizabeth',
    'Cape Neddick',
    'Caratunk',
    'Caribou',
    'Carmel',
    'Carrabasset Valley',
    'Casco',
    'Castine',
    'Charleston',
    'Chebeague Island',
    'Chelsea',
    'Cherryfield',
    'Chester',
    'China',
    'Clinton',
    'Columbia Falls',
    'Corinna',
    'Corinth',
    'Cornish',
    'Cranberry Isles',
    'Cumberland Center',
    'Cushing',
    'Damariscotta',
    'Danforth',
    'Deer Isle',
    'Denmark',
    'Dennysville',
    'Derby',
    'Detroit',
    'Dexter',
    'Dixfield',
    'Dixmont',
    'Dover Foxcroft',
    'Dresden',
    'Durham',
    'Eagle Lake',
    'East Boothbay',
    'East Livermore',
    'East Machias',
    'East Millinocket',
    'East Wilton',
    'Easton',
    'Eastport',
    'Eddington',
    'Edgecomb',
    'Eliot',
    'Ellsworth',
    'Etna',
    'Eustis',
    'Exeter',
    'Fairfield',
    'Falmouth',
    'Farmingdale',
    'Farmington',
    'Farmington Falls',
    'Fort Fairfield',
    'Fort Kent',
    'Frankfort',
    'Franklin',
    'Freedom',
    'Freeport',
    'Frenchboro',
    'Frenchville',
    'Friendship',
    'Fryeburg',
    'Gardiner',
    'Garland',
    'Georgetown',
    'Gilford',
    'Gorham',
    'Gouldsboro',
    'Grand Isle',
    'Gray',
    'Greenbush',
    'Greene',
    'Greenville',
    'Greenwood',
    'Guilford',
    'Hallowell',
    'Hampden',
    'Hancock',
    'Hanover',
    'Harmony',
    'Harpswell Center',
    'Harrington',
    'Harrison',
    'Hartland',
    'Hebron',
    'Hermon',
    'Hinckley',
    'Hiram',
    'Hodgdon',
    'Holden',
    'Hollis',
    'Hope',
    'Houlton',
    'Howland',
    'Hudson',
    'Island Falls',
    'Islesboro',
    'Jackman',
    'Jay',
    'Jefferson',
    'Jonesboro',
    'Jonesport',
    'Kenduskeag',
    'Kennebunk',
    'Kennebunkport',
    'Kingfield',
    'Kittery',
    'Knox',
    'Lagrange',
    'Lebanon',
    'Lee',
    'Leeds',
    'Levant',
    'Lewiston',
    'Lewiston-Auburn Apt',
    'Liberty',
    'Limerick',
    'Limestone',
    'Limington',
    'Lincoln',
    'Lincoln Center',
    'Lincolnville',
    'Lisbon',
    'Lisbon Falls',
    'Litchfield',
    'Little Deer Isle',
    'Livermore',
    'Livermore Falls',
    'Long Island',
    'Lovell',
    'Lubec',
    'Machias',
    'Machiasport',
    'Madawaska',
    'Madison',
    'Manchester',
    'Manset',
    'Mapleton',
    'Mars Hill',
    'Marshfield',
    'Masardis',
    'Mattawamkeag',
    'Mechanic Falls',
    'Meddybemps',
    'Medway',
    'Mercer',
    'Mexico',
    'Milbridge',
    'Milford',
    'Millinocket',
    'Milo',
    'Minot',
    'Monmouth',
    'Monroe',
    'Monson',
    'Monticello',
    'Morrill',
    'Mount Desert',
    'Mount Vernon',
    'Naples',
    'New Gloucester',
    'New Portland',
    'New Sharon',
    'New Sweden',
    'New Vineyard',
    'Newcastle',
    'Newfield',
    'Newport',
    'Newry',
    'Nobleboro',
    'Norridgewock',
    'North Anson',
    'North Berwick',
    'North Haven',
    'North Monmouth',
    'North Vassalboro',
    'North Windham',
    'North Yarmouth',
    'Norway',
    'Oakfield',
    'Oakland',
    'Ogunquit',
    'Old Orchard Beach',
    'Old Town',
    'Orient',
    'Orland',
    'Orono',
    'Orrington',
    'Owls Head',
    'Oxford',
    'Palermo',
    'Palmyra',
    'Paris',
    'Parsonsfield',
    'Passadumkeag',
    'Patten',
    'Pejepscot',
    'Pembroke',
    'Penobscot',
    'Perham',
    'Perry',
    'Peru',
    'Phillips',
    'Phippsburg',
    'Pittsfield',
    'Plymouth',
    'Poland',
    'Poland Spring',
    'Portage Lake',
    'Porter',
    'Portland',
    'Pownal',
    'Presque Isle',
    'Princeton',
    'Prospect Harbor',
    'Randolph',
    'Rangeley',
    'Raymond',
    'Readfield',
    'Richmond',
    'Robbinston',
    'Rockland',
    'Rockport',
    'Rockwood',
    'Round Pond',
    'Roxbury',
    'Rumford',
    'Sabattus',
    'Saco',
    'Saint Agatha',
    'Saint Albans',
    'Saint Francis',
    'Saint George',
    'Sandy Point',
    'Sanford',
    'Sangerville',
    'Scarborough',
    'Seal Cove',
    'Searsmont',
    'Searsport',
    'Sebago',
    'Sebago Lake',
    'Shapleigh',
    'Shawmut',
    'Showhegan',
    'Skowhegan',
    'Small Point',
    'Smithfield',
    'Solon',
    'Sorrento',
    'South Berwick',
    'South Bristol',
    'South Paris',
    'South Portland',
    'South Thomaston',
    'South Windham',
    'Southport',
    'Southwest Harbor',
    'Springfield',
    'Springvale',
    'Spruce Head',
    'Standish',
    'Stetson',
    'Steuben',
    'Stockholm',
    'Stockton Springs',
    'Stoneham',
    'Stonington',
    'Strong',
    'Sullivan',
    'Sumner',
    'Surry',
    'Swans Island',
    'Temple',
    'Tenants Harbour',
    'Thomaston',
    'Topsfield',
    'Topsham',
    'Trenton',
    'Trevett',
    'Troy',
    'Turner',
    'Union',
    'Unity',
    'Van Buren',
    'Vanceboro',
    'Vassalboro',
    'Vienna',
    'Vinalhaven',
    'Waite',
    'Waldo',
    'Waldoboro',
    'Wallagrass',
    'Walpole',
    'Warren',
    'Washburn',
    'Washington',
    'Washington County',
    'Waterboro',
    'Waterford',
    'Waterville',
    'Wayne',
    'Weld',
    'Wells',
    'West Baldwin',
    'West Bath',
    'West Kennebunk',
    'West Minot',
    'West Paris',
    'Westbrook',
    'Westfield',
    'Whitefield',
    'Whiting',
    'Wilton',
    'Windham',
    'Windham Hill',
    'Windsor',
    'Winn',
    'Winslow',
    'Winter Harbor',
    'Winterport',
    'Winthrop',
    'Wiscasset',
    'Woodland',
    'Woolwich',
    'Yarmouth',
    'York',
    'York Beach',
    'York Harbor'
  ],
  MI: [
    'Acme',
    'Ada',
    'Addison',
    'Adrian',
    'Afton',
    'Akron',
    'Alanson',
    'Alba',
    'Albion',
    'Alger',
    'Algonac',
    'Alicia',
    'Allegan',
    'Allen',
    'Allen Park',
    'Allendale',
    'Alma',
    'Almont',
    'Alpena',
    'Alto',
    'Amasa',
    'Anchorville',
    'Ann Arbor',
    'Armada',
    'Ashland',
    'Ashley',
    'Athens',
    'Atlanta',
    'Au Gres',
    'Auburn',
    'Auburn Heights',
    'Auburn Hills',
    'Augusta',
    'Bad Axe',
    'Bailey',
    'Baldwin',
    'Bancroft',
    'Bangor',
    'Bannister',
    'Baraga',
    'Baroda',
    'Bath',
    'Battle Creek',
    'Bay City',
    'Bay City-Saginaw-Midland Apt',
    'Bay Port',
    'Beaver Island',
    'Beaverton',
    'Bedford',
    'Belding',
    'Bellaire',
    'Belleville',
    'Belmont',
    'Benton Harbor',
    'Benzonia',
    'Berkley',
    'Berrien Center',
    'Berrien Springs',
    'Bessemer',
    'Beverly Hills',
    'Big Rapids',
    'Bingham Farms',
    'Birch Run',
    'Birmingham',
    'Blanchard',
    'Blissfield',
    'Bloomfield',
    'Bloomfield Hills',
    'Boyne City',
    'Boyne Falls',
    'Brampton',
    'Breckenridge',
    'Brevort',
    'Bridgeport',
    'Bridgewater',
    'Bridgman',
    'Brighton',
    'Brimley',
    'Britton',
    'Bronson',
    'Brooklyn',
    'Brown City',
    'Brownstown',
    'Brownstown Township',
    'Bruce Crossing',
    'Buchanan',
    'Burlington',
    'Burr Oak',
    'Burton',
    'Byron',
    'Byron Center',
    'Cadillac',
    'Cadmus',
    'Calcite',
    'Caledonia',
    'Calumet',
    'Camden',
    'Canadian Lakes',
    'Canton',
    'Capac',
    'Carleton',
    'Caro',
    'Carrollton',
    'Carsonville',
    'Casco',
    'Caseville',
    'Casnovia',
    'Caspian',
    'Cass City',
    'Cassopolis',
    'Cedar',
    'Cedar Lake',
    'Cedar River',
    'Cedar Springs',
    'Cement City',
    'Center Line',
    'Central Lake',
    'Centreville',
    'Champion',
    'Channing',
    'Charlevoix',
    'Charlotte',
    'Chassell',
    'Cheboygan',
    'Chelsea',
    'Cherry Bend',
    'Chesaning',
    'Chesterfield',
    'China Township',
    'Christmas',
    'Clare',
    'Clarklake',
    'Clarkston',
    'Clarksville',
    'Clawson',
    'Clayton',
    'Clifford',
    'Clinton',
    'Clinton, Township of',
    'Clio',
    'Coldwater',
    'Coleman',
    'Coloma',
    'Colon',
    'Columbiaville',
    'Columbus',
    'Commerce Township',
    'Comstock',
    'Comstock Park',
    'Concord',
    'Conklin',
    'Constantine',
    'Conway',
    'Coopersville',
    'Copemish',
    'Corunna',
    'Cottrellville',
    'Croswell',
    'Crystal',
    'Crystal Falls',
    'Dansville',
    'Davisburg',
    'Davison',
    'De Tour Village',
    'De Witt',
    'Dearborn',
    'Dearborn Heights',
    'Decatur',
    'Deerfield',
    'Delton',
    'Detroit',
    'Dexter',
    'Dimondale',
    'Dollar Bay',
    'Dorr',
    'Douglas, Allegan',
    'Dowagiac',
    'Drayton Plains',
    'Drummond Island',
    'Dryden',
    'Dundee',
    'Durand',
    'Dutton',
    'East Grand Rapids',
    'East Jordan',
    'East Lansing',
    'East Leroy',
    'East Tawas',
    'Eastpointe',
    'Eaton Rapids',
    'Eau Claire',
    'Ecorse',
    'Edmore',
    'Edwardsburg',
    'Elk Rapids',
    'Elkton',
    'Ellsworth',
    'Elmira',
    'Elsie',
    'Elwell',
    'Empire',
    'Erie',
    'Escanaba',
    'Essexville',
    'Eureka',
    'Evart',
    'Fair Haven',
    'Fairgrove',
    'Fairview',
    'Farmington',
    'Farmington Hills',
    'Farwell',
    'Fennville',
    'Fenton',
    'Ferndale',
    'Ferrysburg',
    'Fife Lake',
    'Filer City',
    'Flat Rock',
    'Flatrock',
    'Flint',
    'Flushing',
    'Fort Gratiot',
    'Fowlerville',
    'Frankenmuth',
    'Frankfort',
    'Franklin',
    'Fraser',
    'Freeland',
    'Freeport',
    'Fremont',
    'Fruitport',
    'Fulton',
    'Galesburg',
    'Galien',
    'Garden',
    'Garden City',
    'Gaylord',
    'Gibraltar',
    'Gilford Township',
    'Gladstone',
    'Gladwin',
    'Gobles',
    'Goodrich',
    'Grand Blanc',
    'Grand Haven',
    'Grand Junction',
    'Grand Ledge',
    'Grand Rapids',
    'Grandville',
    'Grant',
    'Grass Lake',
    'Grawn',
    'Grayling',
    'Greenville',
    'Greenwood',
    'Gregory',
    'Grosse Ile',
    'Grosse Point Woods',
    'Grosse Pointe',
    'Grosse Ponte Farms',
    'Gulliver',
    'Gwinn',
    'Hagar Shores',
    'Hamburg',
    'Hamilton',
    'Hamtramck',
    'Hancock',
    'Hanover',
    'Harbor Beach',
    'Harbor Springs',
    'Harper Woods',
    'Harrison',
    'Harrison Township',
    'Hart',
    'Hartford',
    'Hartland',
    'Haslett',
    'Hastings',
    'Hazel Park',
    'Hemlock',
    'Hickory Corners',
    'Higgins Lake',
    'Highland',
    'Highland Park',
    'Highland, Osceola',
    'Hillman',
    'Hillsdale',
    'Holland',
    'Holly',
    'Holt',
    'Homer',
    'Honor',
    'Hope',
    'Hopkins',
    'Horton',
    'Houghton',
    'Houghton Lake',
    'Howard City',
    'Howell',
    'Hoxeyville',
    'Hubbardston',
    'Hubbell',
    'Hudson',
    'Hudsonville',
    'Hughesville',
    'Huntington Woods',
    'Imlay City',
    'Independence Township',
    'Indian River',
    'Ingalls',
    'Inkster',
    'Interlochen',
    'Ionia',
    'Ira',
    'Iron Mountain',
    'Iron River',
    'Ironwood',
    'Irving',
    'Ishpeming',
    'Ithaca',
    'Jackson',
    'Jamestown',
    'Jasper',
    'Jeddo',
    'Jenison',
    'Jones',
    'Jonesville',
    'Kalamazoo',
    'Kalkaska',
    'Kawkawlin',
    'Keego Harbor',
    'Kent City',
    'Kenton',
    'Kentwood',
    'Kimball',
    'Kincheloe',
    'Kingsford',
    'Kingsley',
    'Kinross',
    'La Salle',
    'Lake City',
    'Lake Linden',
    'Lake Odessa',
    'Lake Orion',
    'Lake Orion Heights',
    'Lakeview',
    'Lakeville',
    'Lambertville',
    "L'Anse",
    'Lansing',
    'Lapeer',
    'Lawrence',
    'Lawton',
    'Le Roy',
    'Leonard',
    'Leslie',
    'Levering',
    'Lewiston',
    'Lexington',
    'Liberty',
    'Lincoln Park',
    'Linwood',
    'Litchfield',
    'Livingston',
    'Livonia',
    'Lowell',
    'Ludington',
    'Lupton',
    'Lyons',
    'Macatawa',
    'Mackinac Island',
    'Mackinaw City',
    'Macomb',
    'Madison Heights',
    'Mancelona',
    'Manchester',
    'Manistee',
    'Manistique',
    'Manton',
    'Maple City',
    'Marcellus',
    'Marion',
    'Marlette',
    'Marne',
    'Marquette',
    'Marshall',
    'Martin',
    'Marysville',
    'Mason',
    'Mattawan',
    'Mayville',
    'McBain',
    'Mecosta',
    'Melvindale',
    'Memphis',
    'Mendon',
    'Menominee',
    'Merrill',
    'Mesick',
    'Metamora',
    'Michigamme',
    'Michigan Center',
    'Middleton',
    'Middleville',
    'Midland',
    'Midland-Bay City-Saginaw Apt',
    'Milan',
    'Milford',
    'Millington',
    'Minden City',
    'Mio',
    'Monroe',
    'Montague',
    'Morenci',
    'Morrice',
    'Mottville',
    'Mount Clemens',
    'Mount Morris',
    'Mount Pleasant',
    'Mulliken',
    'Munger',
    'Munising',
    'Muskegon',
    'Muskegon Heights',
    'Nashville',
    'National City',
    'New Baltimore',
    'New Boston',
    'New Buffalo',
    'New Era',
    'New Hudson',
    'New Troy',
    'Newaygo',
    'Newport',
    'Niles',
    'North Branch',
    'North Muskegon',
    'Northport',
    'Northville',
    'Norton Shores',
    'Norway',
    'Novi',
    'Nunica',
    'Oak Park, Oakland',
    'Oakley',
    'Oakwood, Oakland',
    'Okemos',
    'Olivet',
    'Omena',
    'Onaway',
    'Onekama',
    'Onsted',
    'Ontonagon',
    'Orchard Lake',
    'Orion',
    'Ortonville',
    'Oscoda',
    'Osseo',
    'Ossineke',
    'Osthemo',
    'Otisville',
    'Otsego',
    'Ottawa Lake',
    'Ovid',
    'Owendale',
    'Owosso',
    'Oxford',
    'Palmyra',
    'Parchment',
    'Parma',
    'Paw Paw',
    'Peck',
    'Pelkie',
    'Pellston',
    'Pentwater',
    'Perrinton',
    'Perry',
    'Petersburg',
    'Petoskey',
    'Pewamo',
    'Pigeon',
    'Pinconning',
    'Plainwell',
    'Plymouth',
    'Pontiac',
    'Port Huron',
    'Portage',
    'Portland',
    'Posen',
    'Potterville',
    'Presque Isle',
    'Prudenville',
    'Quincy',
    'Quinnesec',
    'Ralph',
    'Rapid City',
    'Ravenna',
    'Ray',
    'Reading',
    'Redford',
    'Reed City',
    'Reese',
    'Remus',
    'Richland',
    'Richmond',
    'Richville',
    'Riga',
    'Riley',
    'River Rouge',
    'Riverdale',
    'Riverside',
    'Riverview',
    'Rives Junction',
    'Rochester',
    'Rochester Hills/Detroit',
    'Rockford',
    'Rockwood',
    'Rogers City',
    'Romeo',
    'Romulus',
    'Roscommon',
    'Rose City',
    'Rosebush',
    'Roseville',
    'Rothbury',
    'Royal Oak',
    'Rudyard',
    'Ruth',
    'Saginaw',
    'Saginaw-Midland-Bay City Apt',
    'Sagola',
    'Saint Charles',
    'Saint Clair',
    'Saint Clair Shores',
    'Saint James',
    'Saint Johns',
    'Saint Joseph',
    'Saint Louis',
    'Saline',
    'Sandusky',
    'Sanford',
    'Saranac',
    'Sault Sainte Marie',
    'Sawyer',
    'Schoolcraft',
    'Scotts',
    'Scottville',
    'Sears',
    'Sebewaing',
    'Shelby',
    'Shelby Township',
    'Shelbyville',
    'Shepherd',
    'Sheridan',
    'Sherwood',
    'Sidney',
    'Smiths Creek',
    'Snover',
    'Sodus',
    'South Boardman',
    'South Haven',
    'South Lyon',
    'South Range',
    'South Rockwood',
    'Southfield',
    'Southgate',
    'Sparta',
    'Spring Arbor',
    'Spring Lake',
    'Springfield, Calhoun',
    'Springport, Jackson',
    'Standish',
    'Stanton',
    'Stanwood',
    'Stephenson',
    'Sterling',
    'Sterling Heights',
    'Stevensville',
    'Stockbridge',
    'Sturgis',
    'Sunfield',
    'Superior Township',
    'Suttons Bay',
    'Swartz Creek',
    'Sylvan Lake',
    'Tawas City',
    'Taylor',
    'Tecumseh',
    'Tekonsha',
    'Temperance',
    'Thompsonville',
    'Three Oaks',
    'Three Rivers',
    'Traverse City',
    'Trenton',
    'Troy',
    'Twin Lake',
    'Ubly',
    'Union',
    'Union City',
    'Unionville',
    'Utica',
    'Van Buren Township',
    'Vanderbilt',
    'Vassar',
    'Vermilion',
    'Vermontville',
    'Vestaburg',
    'Vicksburg',
    'Wakefield',
    'Waldron',
    'Wales',
    'Walker',
    'Walkerville',
    'Wallace',
    'Walled Lake',
    'Warren',
    'Washington',
    'Waterford',
    'Watervliet',
    'Wayland',
    'Wayne',
    'Wayne County Apt/Detroit',
    'Webberville',
    'Weidman',
    'Wells',
    'West Bloomfield',
    'West Branch',
    'West Olive',
    'Westland',
    'Weston',
    'Wheeler',
    'White Cloud',
    'White Lake',
    'White Pigeon',
    'White Pine',
    'Whitehall',
    'Whitmore Lake',
    'Whittemore',
    'Williamsburg',
    'Williamston',
    'Willis',
    'Willow Run',
    'Willow Run Apt/Detroit',
    'Wilson',
    'Winn',
    'Wixom',
    'Woodhaven',
    'Wyandotte',
    'Wyoming',
    'Yale',
    'Ypsilanti',
    'Zeeland',
    'Redford Charter Twp'
  ],
  MN: [
    'Ada',
    'Adrian',
    'Afton',
    'Aitkin',
    'Albany',
    'Albert Lea',
    'Albertville',
    'Alborn',
    'Alden',
    'Alexandria',
    'Altura',
    'Alvarado',
    'Andover',
    'Annandale',
    'Anoka',
    'Apple Valley',
    'Appleton',
    'Arden Hills',
    'Arlington',
    'Ashby',
    'Atwater',
    'Aurora',
    'Austin',
    'Avon',
    'Babbitt',
    'Bagley',
    'Barnesville',
    'Barnum',
    'Baudette',
    'Baxter',
    'Bayport',
    'Becker',
    'Belgrade',
    'Belle Plaine',
    'Bemidji',
    'Benson',
    'Bertha',
    'Big Lake',
    'Big Mountain',
    'Bigfork',
    'Bird Island',
    'Biwabik',
    'Blackduck',
    'Blaine',
    'Blomkest',
    'Blooming Prairie',
    'Bloomington',
    'Blue Earth',
    'Bongards',
    'Brainerd',
    'Brandon',
    'Breckenridge',
    'Brewster',
    'Bricelyn',
    'Brighton',
    'Brook Park',
    'Brooklyn Center',
    'Brooklyn Park',
    'Brooks',
    'Brooten',
    'Browns Valley',
    'Brownton',
    'Brushvale',
    'Buffalo',
    'Buffalo Lake',
    'Buhl',
    'Burnsville',
    'Byron',
    'Caledonia',
    'Callaway',
    'Calumet',
    'Cambridge',
    'Canby',
    'Cannon Falls',
    'Carlos',
    'Carlton',
    'Castle Rock',
    'Centerville',
    'Champlin',
    'Chandler',
    'Chanhassen',
    'Chaska',
    'Chatfield',
    'Chisago City',
    'Chisholm',
    'Chisholm-Hibbing Apt',
    'Circle Pines',
    'Clara City',
    'Claremont',
    'Clear Lake',
    'Clearwater',
    'Clinton',
    'Cloquet',
    'Cohasset',
    'Cokato',
    'Cold Spring',
    'Coleraine',
    'Collegeville',
    'Columbia Heights',
    'Columbus',
    'Cook',
    'Coon Rapids',
    'Corcoran',
    'Cottage Grove',
    'Cottonwood',
    'Courtland',
    'Crane Lake',
    'Crookston',
    'Crosby',
    'Crosslake',
    'Crystal',
    'Dakota',
    'Dalbo',
    'Dalton',
    'Danvers',
    'Dassel',
    'Dawson',
    'Dayton',
    'Deer Creek',
    'Deer River',
    'Deerwood',
    'Delano',
    'Detroit Lakes',
    'Dilworth',
    'Dodge Center',
    'Donaldson',
    'Dover',
    'Duluth',
    'Dundas',
    'Eagan',
    'East Grand Forks',
    'Easton',
    'Eden Prairie',
    'Eden Valley',
    'Edgerton',
    'Edina/Minneapolis',
    'Eitzen',
    'Elgin',
    'Elk River',
    'Elko New Market',
    'Ellendale',
    'Ely',
    'Elysian',
    'Emily',
    'Esko',
    'Eveleth',
    'Excelsior',
    'Eyota',
    'Fairfax',
    'Fairmont',
    'Faribault',
    'Farmington',
    'Fergus Falls',
    'Fifty Lakes',
    'Finland',
    'Floodwood',
    'Foley',
    'Forest Lake',
    'Fort Ripley',
    'Fort Snelling',
    'Fosston',
    'Fountain',
    'Foxhome',
    'Frazee',
    'Freeborn',
    'Freeport',
    'Fridley',
    'Frontenac',
    'Garden City',
    'Garrison',
    'Gary',
    'Gaylord',
    'Gibbon',
    'Gilbert',
    'Glencoe',
    'Glenville',
    'Glenwood',
    'Golden Valley',
    'Good Thunder',
    'Goodhue',
    'Graceville',
    'Grand Marais',
    'Grand Meadow',
    'Grand Portage',
    'Grand Rapids',
    'Granite Falls',
    'Green Isle',
    'Greenbush',
    'Greenfield',
    'Grove City',
    'Hackensack',
    'Hallock',
    'Ham Lake',
    'Hamburg',
    'Hamel',
    'Hampton',
    'Hancock',
    'Hanska',
    'Hardwick',
    'Harmony',
    'Harris',
    'Hartland',
    'Hastings',
    'Hawley',
    'Hayfield',
    'Henderson',
    'Henning',
    'Hermantown',
    'Hewitt',
    'Hibbing',
    'Hibbing-Chisholm Apt',
    'Hill City',
    'Hills',
    'Hines',
    'Hokah',
    'Hope',
    'Hopkins',
    'Houston',
    'Howard Lake',
    'Hoyt Lakes',
    'Hugo',
    'Hutchinson',
    'International Falls',
    'Inver Grove Heights',
    'Iron Junction',
    'Ironton',
    'Isabella',
    'Isanti',
    'Isle',
    'Ivanhoe',
    'Jackson',
    'Janesville',
    'Jordan, Scott',
    'Karlstad',
    'Kasota',
    'Kasson',
    'Keewatin',
    'Kellogg',
    'Kennedy',
    'Kenyon',
    'Kiester',
    'Kimball',
    'Lafayette',
    'Lake Benton',
    'Lake City',
    'Lake Crystal',
    'Lake Elmo',
    'Lake Park',
    'Lake Wilson',
    'Lakefield',
    'Lakeville',
    'Lancaster',
    'Le Center',
    'Le Roy',
    'Le Suer',
    'Le Sueur',
    'Lester Prairie',
    'Lindstrom',
    'Lino Lakes',
    'Litchfield',
    'Little Canada',
    'Little Falls',
    'Long Lake',
    'Long Prairie',
    'Lonsdale',
    'Loretto',
    'Lucan',
    'Lutsen',
    'Luverne',
    'Madelia',
    'Madison',
    'Magnolia',
    'Mahnomen',
    'Mahtomedi',
    'Mankato',
    'Mantorville',
    'Maple Grove',
    'Maple Island',
    'Maple Lake',
    'Maple Plain',
    'Mapleton',
    'Maplewood',
    'Marshall',
    'Mayer',
    'Maynard',
    'McGregor',
    'Medford',
    'Medina',
    'Melrose',
    'Menahga',
    'Mendota Heights',
    'Mentor',
    'Meriden',
    'Merrifield',
    'Milaca',
    'Miltona',
    'Minneapolis',
    'Minneapolis/St Paul Apt',
    'Minneota',
    'Minnesota City',
    'Minnetonka',
    'Montevideo',
    'Montgomery',
    'Monticello',
    'Montrose',
    'Moorhead',
    'Mora',
    'Morris',
    'Morristown',
    'Morton',
    'Motley',
    'Mound',
    'Mounds View',
    'Mountain Iron',
    'Mountain Lake',
    'Murdock',
    'Nashwauk',
    'Nerstrand',
    'Nevis',
    'New Auburn',
    'New Brighton',
    'New Germany',
    'New Hope',
    'New Prague',
    'New Ulm',
    'New York Mills',
    'Newport',
    'Nicollet',
    'Norcross',
    'North Branch',
    'North Mankato',
    'North Oaks',
    'Northfield',
    'Norwood',
    'Noyes',
    'Oak Park Heights',
    'Oakdale',
    'Odessa',
    'Ogilvie',
    'Olivia',
    'Onamia',
    'Orono',
    'Oronoco',
    'Oslo',
    'Osseo',
    'Otsego',
    'Ottawa',
    'Owatonna',
    'Palisade',
    'Park Rapids',
    'Paynesville',
    'Pease',
    'Pelican Rapids',
    'Pennock',
    'Pequot Lakes',
    'Perham',
    'Pierz',
    'Pillager',
    'Pine City',
    'Pine Island',
    'Pine River',
    'Pinecreek',
    'Pipestone',
    'Plainview',
    'Plato',
    'Plymouth',
    'Ponsford',
    'Porter',
    'Preston',
    'Princeton',
    'Prinsburg',
    'Prior Lake',
    'Proctor',
    'Rainier',
    'Ramsey',
    'Randolph',
    'Ranier',
    'Raymond',
    'Red Lake Falls',
    'Red Wing',
    'Redby',
    'Redwood Falls',
    'Renville',
    'Rice',
    'Richfield',
    'Richmond',
    'Riverton',
    'Robbinsdale',
    'Rochester',
    'Rockford',
    'Rockville',
    'Rogers',
    'Roseau',
    'Rosemount',
    'Roseville',
    'Round Lake',
    'Royalton',
    'Rush City',
    'Rushford',
    'Russell',
    'Sabin',
    'Sacred Heart',
    'Saginaw',
    'Saint Anthony',
    'Saint Augusta',
    'Saint Bonifacius',
    'Saint Charles',
    'Saint Cloud',
    'Saint Hilaire',
    'Saint James',
    'Saint Joseph',
    'Saint Louis Park',
    'Saint Martin',
    'Saint Michael',
    'Saint Paul',
    'Saint Paul Park',
    'Saint Peter',
    'Saint Vincent',
    'Sandstone',
    'Saratoga',
    'Sartell',
    'Sauk Centre',
    'Sauk Rapids',
    'Savage',
    'Sawyer',
    'Scanlon',
    'Shafer',
    'Shakopee',
    'Shoreview',
    'Shorewood',
    'Silver Bay',
    'Slayton',
    'Sleepy Eye',
    'Solway',
    'South Haven',
    'South Saint Paul',
    'Spicer',
    'Spring Grove',
    'Spring Lake Park',
    'Spring Park',
    'Spring Valley',
    'Springfield',
    'Stacy',
    'Stanton',
    'Staples',
    'Starbuck',
    'Stephen',
    'Stewart, McLeod',
    'Stewartville',
    'Stillwater',
    'Storden',
    'Swanville',
    'Taconite Harbor',
    'Taopi',
    'Thief River Falls',
    'Tower',
    'Tracy',
    'Truman',
    'Two Harbors',
    'Tyler',
    'Vadnais Heights',
    'Vermillion',
    'Veseli',
    'Vesta',
    'Villard',
    'Virginia',
    'Wabasha',
    'Wabasso',
    'Waconia',
    'Wadena',
    'Waite Park',
    'Walker',
    'Wanamingo',
    'Warren',
    'Warroad',
    'Waseca',
    'Watkins',
    'Watson',
    'Waverly',
    'Wayzata',
    'Webster',
    'Welch',
    'Wells',
    'West Concord',
    'West Saint Paul',
    'Whalan',
    'Wheaton',
    'White Bear Lake',
    'Williams',
    'Willmar',
    'Windom',
    'Winger',
    'Winnebago, Faribault',
    'Winona',
    'Winsted',
    'Winthrop',
    'Winton',
    'Woodbury',
    'Worthington',
    'Wrenshall',
    'Wykoff',
    'Wyoming',
    'Young America',
    'Zimmerman',
    'Zumbrota',
    'St Paul'
  ],
  MO: [
    'Advance',
    'Affton',
    'Albany',
    'Alexandria',
    'Altenburg',
    'Alton',
    'Amsterdam',
    'Anderson',
    'Annapolis',
    'Anniston',
    'Antonia',
    'Arbela',
    'Arbyrd',
    'Arcadia',
    'Archie',
    'Arnold',
    'Asbury',
    'Ash Grove',
    'Ashland',
    'Augusta',
    'Aurora',
    'Auxvasse',
    'Ava',
    'Ballwin',
    'Barnhart',
    'Bates City',
    'Bel Ridge',
    'Belle',
    'Bellefontaine Neighbors',
    'Belton',
    'Berger',
    'Berkeley',
    'Bernie',
    'Bethany',
    'Bethel',
    'Billings',
    'Black',
    'Blairstown',
    'Bland',
    'Bloomfield',
    'Bloomsdale',
    'Blue Springs',
    'Blythedale',
    'Bolivar',
    'Bonne Terre',
    'Boonville',
    'Boss',
    'Bourbon',
    'Bowling Green',
    'Brandsville',
    'Branson',
    'Branson West',
    'Braymer',
    'Breckenridge Hills',
    'Brentwood',
    'Bridgeton',
    'Brighton',
    'Bronaugh',
    'Brookfield',
    'Brookline',
    'Brookline Station',
    'Broseley',
    'Brownwood',
    'Brunswick',
    'Buckner',
    'Buffalo',
    'Bunker',
    'Butler',
    'Cabool',
    'Cadet',
    'Cairo',
    'California',
    'Callao',
    'Camdenton',
    'Cameron',
    'Campbell',
    'Canton',
    'Cape Girardeau',
    'Carrollton',
    'Carthage',
    'Caruthersville',
    'Cassville',
    'Catawissa',
    'Caterville',
    'Cedar Hill',
    'Centerville',
    'Centralia',
    'Chaffee',
    'Charleston',
    'Cherryville',
    'Chesterfield',
    'Chicopee',
    'Chillicothe',
    'Church Hill',
    'Clark',
    'Clarksville',
    'Claycomo',
    'Clayton',
    'Cleveland',
    'Clinton',
    'Cole Camp',
    'Columbia',
    'Columbus',
    'Concordia',
    'Cosby',
    'Cottleville',
    'Craig',
    'Crane',
    'Crestwood',
    'Creve Coeur',
    'Crystal City',
    'Cuba',
    'Dallas',
    'De Soto',
    'Deerfield',
    'Defiance',
    'Denver',
    'Des Peres',
    'Dexter',
    'Diamond',
    'Dixon',
    'Doniphan',
    'Dresden',
    'Drexel',
    'Dudley',
    'Duenweg',
    'Dutzow',
    'Eagle Rock',
    'Eagleville',
    'Earth City',
    'East Prairie',
    'Edgerton',
    'Edina',
    'El Dorado Springs',
    'Eldon',
    'Ellington',
    'Ellisville',
    'Ellsinore',
    'Elsberry',
    'Eminence',
    'Eolia',
    'Eugene',
    'Eureka, Perry',
    'Eureka, Saint Louis',
    'Everton',
    'Excelsior Springs',
    'Exeter',
    'Fairfax',
    'Fairview',
    'Falcon',
    'Farber',
    'Farmington',
    'Fayette',
    'Fenton',
    'Ferguson',
    'Festus',
    'Flat River',
    'Florence',
    'Florissant',
    'Fordland',
    'Forest City',
    'Foristell',
    'Forsyth',
    'Fort Leonard Wood',
    'Fortuna',
    'Four Seasons',
    'Frankford',
    'Franklin',
    'Fredericktown',
    'Freeburg',
    'Frohna',
    'Frontenac',
    'Fulton',
    'Gainesville',
    'Gallatin',
    'Garden City',
    'Garrison',
    'Gerald',
    'Gibbons',
    'Gideon',
    'Gladstone',
    'Glasgow',
    'Glen Allen',
    'Glenwood',
    'Golden',
    'Golden City',
    'Goodman',
    'Gorin',
    'Gower',
    'Grain Valley',
    'Grandview',
    'Grant City',
    'Gravois Mills',
    'Gray Summit',
    'Green Castle',
    'Green Park',
    'Green Ridge',
    'Greenfield',
    'Greenville',
    'Greenwood',
    'Grover',
    'Half Way',
    'Hamilton',
    'Hannibal',
    'Harris',
    'Harrisburg',
    'Harrisonville',
    'Harviell',
    'Harwood',
    'Hayti',
    'Hayward',
    'Hazelwood',
    'Henrietta',
    'Herculaneum',
    'Hermann',
    'Higbee',
    'Higginsville',
    'High Hill',
    'High Ridge',
    'Highlandville',
    'Hillsboro',
    'Holland',
    'Hollister',
    'Holt',
    'House Springs',
    'Houston',
    'Hughesville',
    'Humphreys',
    'Huntsville',
    'Huron',
    'Imperial',
    'Independence',
    'Irondale',
    'Ironton',
    'Ivory',
    'Jackson',
    'Jamesport',
    'Jasper',
    'Jefferson City',
    'Jenkins',
    'Jennings',
    'Jonesburg',
    'Joplin',
    'Kahoka',
    'Kaiser/Lake Ozark',
    'Kansas City',
    'Kansas City International Apt',
    'Kearney',
    'Kennett',
    'Keytesville',
    'Kimberling City',
    'Kingdom City',
    'Kingsville',
    'Kinloch',
    'Kirbyville',
    'Kirksville',
    'Kirkwood',
    'Knob Lick',
    'Knob Noster',
    'La Grange',
    'La Monte',
    'Labadie',
    'Laddonia',
    'Ladue',
    'Lake Saint Louis',
    'Lamar',
    'Lampe',
    'Latham',
    'Lathrop',
    'Laurie',
    'Leasburg',
    'Lebanon',
    'Leeds',
    'Lees Summit',
    "Lee's Summit",
    'Lemay',
    'Lexington',
    'Liberty',
    'Licking',
    'Lincoln',
    'Linn',
    'Linn Creek',
    'Lockwood',
    'Lohman',
    'Lone Jack',
    'Loose Creek',
    'Louisburg',
    'Louisiana',
    'Macks Creek',
    'Macon',
    'Madison',
    'Malden',
    'Manchester',
    'Mansfield',
    'Maplewood',
    'Marble Hill',
    'Marceline',
    'Marionville',
    'Marshall',
    'Marshfield',
    'Marston',
    'Marthasville',
    'Maryland Heights',
    'Maryville',
    'Matson',
    'Matthews',
    'McBride',
    'Memphis',
    'Mendon',
    'Mercer',
    'Meta',
    'Mexico',
    'Middletown',
    'Milan',
    'Mill Spring',
    'Miller',
    'Miner',
    'Mineral Point',
    'Missouri City',
    'Moberly',
    'Mokane',
    'Molt',
    'Monett',
    'Monroe City',
    'Montgomery City',
    'Montreal',
    'Mooresville',
    'Morehouse',
    'Morley',
    'Moscow Mills',
    'Mound City',
    'Moundville',
    'Mount Vernon',
    'Mountain Grove',
    'Mountain View',
    'Neelys',
    'Neosho',
    'Nevada',
    'New Bloomfield',
    'New Boston',
    'New Florence',
    'New Franklin',
    'New Haven',
    'New London',
    'New Madrid',
    'New Melle',
    'Newark',
    'Newtown',
    'Niangua',
    'Nixa',
    'Noel',
    'Normandy',
    'North Kansas City',
    'Northmoor',
    'Novelty',
    'Oak Grove',
    'Odessa',
    "O'Fallon",
    'Olathia',
    'Old Monroe',
    'Olivette',
    'Oran',
    'Orrick',
    'Osage Beach',
    'Oscar',
    'Osceola',
    'Otterville',
    'Overland',
    'Owensville',
    'Oxly',
    'Ozark',
    'Pacific',
    'Pagedale',
    'Palmyra',
    'Park Hills',
    'Parkville',
    'Parma',
    'Patton',
    'Pattonsburg',
    'Peace Valley',
    'Peculiar',
    'Perryville',
    'Pevely',
    'Phillipsburg',
    'Pickering',
    'Piedmont',
    'Pierce City',
    'Pineville',
    'Pittsville',
    'Plato',
    'Platte',
    'Platte City',
    'Plattsburg',
    'Pleasant Hill',
    'Pleasant Hope',
    'Pleasant Valley',
    'Pomona',
    'Poplar Bluff',
    'Portageville',
    'Potosi',
    'Princeton',
    'Purdy',
    'Puxico',
    'Randolph',
    'Ravenwood',
    'Raymondville',
    'Raymore',
    'Raytown',
    'Reeds',
    'Reeds Spring',
    'Republic',
    'Rich Hill',
    'Richland',
    'Richmond',
    'Richwoods',
    'Ridgedale',
    'Riverside',
    'Roach',
    'Roby',
    'Rock Port',
    'Rockport',
    'Rogersville',
    'Rolla',
    'Rosebud',
    'Rutledge',
    'Saint Albans',
    'Saint Ann',
    'Saint Charles',
    'Saint Clair, Franklin',
    'Saint James',
    'Saint John',
    'Saint Joseph',
    'Saint Louis',
    'Saint Paul',
    'Saint Peters',
    'Saint Thomas',
    'Sainte Genevieve',
    'Salem',
    'Salisbury',
    'Sarcoxie',
    'Savannah',
    'Saverton',
    'Scott City',
    'Sedalia',
    'Senath',
    'Seneca',
    'Seymour',
    'Shelbyville',
    'Sheldon',
    'Shell Knob',
    'Sibley',
    'Sikeston',
    'Silva',
    'Skidmore',
    'Slater',
    'Smithton',
    'South River',
    'South West City',
    'Spickard',
    'Spirit of Saint Louis Apt/Saint Louis',
    'Springfield',
    'Stanberry',
    'Stanton',
    'Steele',
    'Steelville',
    'Stockton',
    'Stotts City',
    'Stoutland',
    'Strafford',
    'Success',
    'Sugar Creek',
    'Sullivan',
    'Sunrise Beach',
    'Sunset Hills',
    'Sweet Springs',
    'Tarkio',
    'Taylor',
    'Tecumseh',
    'Thayer',
    'Thompson',
    'Thompsonfield',
    'Tipton',
    'Town and Country',
    'Trenton',
    'Troy',
    'Truesdale',
    'Union',
    'Union City',
    'Union, Franklin',
    'Unionville',
    'University',
    'University City',
    'Utica',
    'Valley Park',
    'Van Buren',
    'Vandalia',
    'Verona',
    'Versailles',
    'Viburnum',
    'Vichy',
    'Vienna',
    'Villa Ridge',
    'Vinita Park',
    'Walnut Grove',
    'Walnut Shade',
    'Warrensburg',
    'Warrenton',
    'Warsaw',
    'Washington',
    'Waverly',
    'Wayland',
    'Waynesville',
    'Weatherby',
    'Webb City',
    'Webster Groves',
    'Weldon Spring',
    'Wellsville',
    'Wentzville',
    'West Alton',
    'West Plains',
    'Weston',
    'Whitewater',
    'Wildwood',
    'Willard',
    'Willow Springs',
    'Windsor',
    'Woodson Terrace/St Louis',
    'Wright City',
    'KCMO'
  ],
  MS: [
    'Abbeville',
    'Aberdeen',
    'Ackerman',
    'Algoma',
    'Amory',
    'Anguilla',
    'Artesia',
    'Ashland',
    'Baldwyn',
    'Ballground',
    'Bassfield',
    'Batesville',
    'Bay Saint Louis',
    'Bay Springs',
    'Belden',
    'Bellefontaine',
    'Belmont',
    'Belzoni',
    'Benton',
    'Biloxi',
    'Blue Mountain',
    'Blue Springs',
    'Bogue Chitto',
    'Bolton',
    'Booneville',
    'Boyle',
    'Brandon',
    'Braxton',
    'Brookhaven',
    'Brooklyn',
    'Brooksville',
    'Bruce',
    'Bude',
    'Burnsville',
    'Byhalia',
    'Byram',
    'Caledonia',
    'Calhoun City',
    'Cambridge',
    'Camp Shelby',
    'Canton',
    'Carriere',
    'Carson',
    'Carthage',
    'Centerville',
    'Centreville',
    'Charleston',
    'Choctaw',
    'Clarksdale',
    'Cleveland',
    'Clinton',
    'Coffeeville',
    'Coldwater',
    'Collins',
    'Columbia',
    'Columbus',
    'Como',
    'Corinth',
    'Crenshaw',
    'Crystal Springs',
    'De Kalb',
    'Delisle',
    'Derma',
    'Diamondhead',
    'Diberville',
    'Drew',
    'Dublin',
    'Dumas',
    'Durant',
    'Ecru',
    'Edwards',
    'Ellisville',
    'Enterprise',
    'Etta',
    'Eupora',
    'Falkner',
    'Fayette',
    'Fernwood',
    'Flora',
    'Florence',
    'Flowood',
    'Forest',
    'Foxworth',
    'Fulton',
    'Furrs',
    'Gallman',
    'Gautier',
    'Gloster',
    'Gluckstadt',
    'Golden',
    'Greenfield',
    'Greenville',
    'Greenwood',
    'Grenada',
    'Gulfport',
    'Guntown',
    'Hamilton',
    'Hattiesburg',
    'Hazlehurst',
    'Hernando',
    'Hickory',
    'Hickory Flat, Benton',
    'Hickory Flat, Chicasaw',
    'Hollandale',
    'Holly Ridge',
    'Holly Springs, Marshall',
    'Horn Lake',
    'Houlka',
    'Houston',
    'Indianola',
    'Inverness',
    'Isola',
    'Itta Bena',
    'Iuka',
    'Jackson',
    'Jefferson',
    'Jonestown',
    'Kiln',
    'Kosciusko',
    'Lackey',
    'Lake Cormorant',
    'Lamont',
    'Lauderdale',
    'Laurel',
    'Leland',
    'Lena',
    'Lexington',
    'Liberty',
    'Long Beach',
    'Louin',
    'Louisville',
    'Lucedale',
    'Ludlow',
    'Lumberton',
    'Luther',
    'Lyon',
    'Maben',
    'Macon',
    'Madison',
    'Magee',
    'Magnolia',
    'Mantachie',
    'Mantee',
    'Marks',
    'Mathiston',
    'Mayersville',
    'Mayhew',
    'McComb',
    'Memphis',
    'Mendenhall',
    'Meridian',
    'Mineral Wells',
    'Mitchell',
    'Money',
    'Monticello',
    'Morton',
    'Moselle',
    'Moss Point',
    'Mount Olive',
    'Myrtle',
    'Natchez',
    'Neosho',
    'Nesbit',
    'Nettleton',
    'New Albany',
    'New Augusta',
    'Newton',
    'Nicholson',
    'Noxapater',
    'Oakland',
    'Ocean Springs',
    'Okolona',
    'Olive Branch',
    'Oxford',
    'Pachuta',
    'Pascagoula',
    'Pass Christian',
    'Patel',
    'Pearl',
    'Pearlington',
    'Pelahatchie',
    'Perkinston',
    'Petal',
    'Philadelphia',
    'Picayune',
    'Plantersville',
    'Pocahontas',
    'Pontotoc',
    'Pope',
    'Poplarville',
    'Port Bienville',
    'Port Gibson',
    'Prairie',
    'Puckett',
    'Purvis',
    'Quitman',
    'Randolph',
    'Raymond',
    'Redwood',
    'Richland',
    'Richton',
    'Ridgeland',
    'Rienzi',
    'Ripley',
    'Robinsonville',
    'Rolling Fork',
    'Rosedale',
    'Ruleville',
    'Sallis',
    'Saltillo',
    'Sandy Hook',
    'Sardis',
    'Saucier',
    'Scooba',
    'Scott',
    'Sebastopol',
    'Seminary',
    'Senatobia',
    'Shannon',
    'Shaw',
    'Sherman',
    'Ship Island',
    'Shubuta',
    'Shuqualak',
    'Sidon',
    'Silver Creek',
    'Sledge',
    'Smithville',
    'South Haven',
    'Southaven',
    'Southhaven',
    'Star',
    'Starkville',
    'Stennis Space Center',
    'Stoneville',
    'Stonewall',
    'Sturgis',
    'Summit',
    'Sumner',
    'Sumrall, Lamar',
    'Sunflower',
    'Taylor',
    'Taylorsville',
    'Tchula',
    'Terry',
    'Tillatoba',
    'Tishomingo',
    'Tremont',
    'Trinity',
    'Troy',
    'Tunica',
    'Tupelo',
    'Tuscan',
    'Tutwiler',
    'Tylertown',
    'Union',
    'Union, Neshaboa',
    'Utica',
    'Vaiden',
    'Valley Park',
    'Vancleave',
    'Vardaman',
    'Vernon',
    'Verona',
    'Vicksburg',
    'Walls',
    'Walnut Grove',
    'Water Valley',
    'Waveland',
    'Waynesboro',
    'Wesson',
    'West Point',
    'Wiggins',
    'Winona',
    'Woodland',
    'Woodville',
    'Wren',
    'Yazoo City',
    "D'Iberville"
  ],
  MT: [
    'Alder',
    'Anaconda',
    'Arlee',
    'Augusta',
    'Baker',
    'Belgrade',
    'Big Sandy',
    'Big Sky',
    'Big Timber',
    'Bigfork',
    'Billings',
    'Birney',
    'Bonner',
    'Boyd',
    'Bozeman',
    'Brady',
    'Bridger',
    'Broadus',
    'Butte',
    'Cameron',
    'Carter',
    'Cascade',
    'Choteau',
    'Clancy',
    'Colstrip',
    'Columbia Falls',
    'Columbus',
    'Conrad',
    'Corvallis',
    'Crow Agency',
    'Culbertson',
    'Cut Bank',
    'Darby',
    'Dayton',
    'Deer Lodge',
    'Del Bonita',
    'Dell',
    'Denton',
    'Dillon',
    'Drummond',
    'Edgar',
    'Ennis',
    'Ethridge',
    'Eureka',
    'Evergreen',
    'Fairfield',
    'Florence',
    'Forsyth',
    'Fort Benton',
    'Gallatin Gateway',
    'Geyser',
    'Gildford',
    'Glasgow',
    'Glen',
    'Glendive',
    'Great Falls',
    'Greenough',
    'Hamilton',
    'Hardin',
    'Harlowton',
    'Havre',
    'Helena',
    'Helmville',
    'Heron',
    'Hilger',
    'Hingham',
    'Huntley',
    'Inverness',
    'Jackson',
    'Jordan',
    'Kalispell',
    'Kremlin',
    'Laurel',
    'Lewistown',
    'Libby',
    'Lincoln',
    'Livingston',
    'Logan',
    'Lolo',
    'Malmstrom AFB',
    'Malta',
    'Manhattan',
    'Miles City',
    'Missoula',
    'Montana City',
    'Morgan',
    'Nye',
    'Olney',
    'Opheim',
    'Pablo',
    'Pendroy',
    'Philipsburg',
    'Piegan',
    'Plentywood',
    'Polson',
    'Pony',
    'Poplar',
    'Quinlan',
    'Raymond',
    'Red Lodge',
    'Rexford',
    'Rollins',
    'Ronan',
    'Roosville',
    'Roundup',
    'Saint Ignatius',
    'Sand Coulee',
    'Savage',
    'Scobey',
    'Shelby',
    'Shepherd',
    'Sidney',
    'Silver Bow',
    'Silver Star',
    'Somers',
    'Stevensville',
    'Stockett',
    'Sunburst',
    'Sweet Grass',
    'Thompson Falls',
    'Three Forks',
    'Three Forks Junction',
    'Trego',
    'Trout Creek',
    'Troy',
    'Turner',
    'Ulm',
    'Victor',
    'West Yellowstone',
    'White Sulphur Springs',
    'Whitefish',
    'Whitehall',
    'Whitetail',
    'Whitlash',
    'Willow Creek',
    'Winston',
    'Wolf Point'
  ],
  NC: [
    'Aberdeen',
    'Aberdin',
    'Advance',
    'Ahoskie',
    'Alamance',
    'Albemarle',
    'Alexis',
    'Allenton',
    'Altamahaw',
    'Altapass',
    'Andrews',
    'Angier',
    'Ansonville',
    'Apex',
    'Archdale',
    'Arden',
    'Ash',
    'Asheboro',
    'Asheville',
    'Atkinson',
    'Atlantic',
    'Atlantic Beach',
    'Aulander',
    'Aurora',
    'Avon',
    'Ayden',
    'Badin',
    'Bahama',
    'Bailey',
    'Bakersville',
    'Balfour',
    'Banner Elk',
    'Barnardsville',
    'Bath',
    'Battleboro',
    'Bayshore',
    'Beaufort',
    'Belews Creek',
    'Belhaven',
    'Belmont',
    'Benson',
    'Bessemer City',
    'Bethel, Pitt',
    'Bethlehem',
    'Beulaville',
    'Biscoe',
    'Black Creek',
    'Black Mountain',
    'Bladenboro',
    'Blounts Creek',
    'Blowing Rock',
    'Boiling Springs',
    'Bolivia',
    'Bolton',
    'Boomer',
    'Boone',
    'Boonville',
    'Bostic',
    'Brasstown',
    'Brevard',
    'Bridgeton',
    'Broadway',
    'Browns Summit',
    'Bryson City',
    'Bunn',
    'Burgaw',
    'Burlington',
    'Burnsville',
    'Butner',
    'Calabash',
    'Calypso',
    'Camden',
    'Cameron',
    'Camp Lejeune',
    'Campbell',
    'Candler',
    'Candor',
    'Canton',
    'Cape Carteret',
    'Cape Fear',
    'Cape Hatteras',
    'Carolina Beach',
    'Carrboro',
    'Carthage',
    'Cary',
    'Casar',
    'Cashiers',
    'Castalia',
    'Castle Hayne',
    'Catawba',
    'Cedar Falls',
    'Cedar Grove',
    'Cedar Hill',
    'Cedar Point',
    'Chadbourn',
    'Chapel Hill',
    'Charleston',
    'Charlotte',
    'Cherry Point',
    'Cherryville',
    'China Grove',
    'Chocowinity',
    'Claremont',
    'Clarkton',
    'Clayton',
    'Clemmons',
    'Cleveland',
    'Cliffside',
    'Climax',
    'Clinton',
    'Clyde',
    'Coats',
    'Cofield',
    'Colerain',
    'Colfax',
    'Columbia',
    'Columbus',
    'Concord',
    'Connelly Springs',
    'Connellys Springs',
    'Conover',
    'Conway',
    'Cooleemee',
    'Cordova',
    'Cornelius',
    'Corolla',
    'Cove City',
    'Crabtree',
    'Cramerton',
    'Creedmoor',
    'Crossnore',
    'Cullowhee',
    'Currie',
    'Dallas',
    'Davidson',
    'Davie',
    'Deep Gap',
    'Denton',
    'Denver',
    'Dobson',
    'Drexel',
    'Duart',
    'Dublin',
    'Duck',
    'Dudley',
    'Dunn',
    'Durham',
    'Durham-Raleigh Apt',
    'Eagle Springs',
    'Earl',
    'East Bend',
    'East Fayetteville',
    'East Flat Rock',
    'East Lake',
    'Eden',
    'Edenton',
    'Edneyville',
    'Elizabeth City',
    'Elizabethtown',
    'Elk Park',
    'Elkin',
    'Ellenboro',
    'Ellerbe',
    'Elm City',
    'Elon',
    'Emerald Isle',
    'Enfield',
    'Enka',
    'Ennice',
    'Erwin',
    'Ether',
    'Etowah',
    'Everetts',
    'Fair Bluff',
    'Fairfield',
    'Fairmont',
    'Fairview',
    'Faison',
    'Faith',
    'Falcon',
    'Fallston',
    'Farmville',
    'Fayetteville',
    'Flat Rock',
    'Fletcher',
    'Forest City',
    'Fort Bragg',
    'Fountain',
    'Four Oaks',
    'Franklin',
    'Franklinton',
    'Franklinville',
    'Fremont',
    'Friendship',
    'Fuquay-Varina',
    'Garland',
    'Garner',
    'Garysburg',
    'Gaston',
    'Gastonia',
    'Gates',
    'Gatesville',
    'Gibson',
    'Gibsonville',
    'Glen Raven',
    'Glenola',
    'Glenville',
    'Glenwood, McDowell',
    'Godwin',
    'Gold Hill',
    'Goldsboro',
    'Goldston',
    'Graham',
    'Grandy',
    'Granite Falls',
    'Granite Quarry',
    'Grassy Creek',
    'Green Mountain',
    'Greensboro',
    'Greensboro-High Point Apt',
    'Greenville',
    'Grifton',
    'Grover',
    'Halifax',
    'Hamilton',
    'Hamlet',
    'Hampstead',
    'Hamptonville',
    'Hanrahan',
    'Harbinger',
    'Harrells',
    'Harris',
    'Harrisburg',
    'Hatteras',
    'Havelock',
    'Haw River',
    'Hayesville',
    'Hazelwood',
    'Henderson',
    'Hendersonville',
    'Hertford',
    'Hickory',
    'Hiddenite',
    'High Point',
    'High Point-Greensboro Apt',
    'Highlands',
    'Hildebran',
    'Hillsborough',
    'Hobbsville',
    'Hobgood',
    'Hoffman',
    'Holden Beach',
    'Holly Ridge',
    'Holly Springs, Carteret',
    'Hope Mills',
    'Horseshoe',
    'Hot Springs',
    'Hubert',
    'Hudson',
    'Huntersville',
    'Icard',
    'Indian Trail',
    'Iron Station',
    'Ivanhoe',
    'Jacksonville',
    'Jamestown',
    'Jamesville',
    'Jarvisburg',
    'Jefferson',
    'Jonesville',
    'Julian',
    'Kannapolis',
    'Kelford',
    'Kelly',
    'Kenansville',
    'Kenly',
    'Kernersville',
    'Kill Devil Hills',
    'King',
    'Kings Mountain',
    'Kingsmountain',
    'Kingston',
    'Kingstown',
    'Kinston',
    'Kittrell',
    'Kitty Hawk',
    'Knightdale',
    'La Grange',
    'Lake Toxaway',
    'Lake Waccamaw',
    'Landis',
    'Lansing',
    'Lattimore',
    'Laurel Hill',
    'Laurel Hill, Lincoln',
    'Laurel Springs',
    'Laurinburg',
    'Lawndale',
    'Lawsonville',
    'Leicester',
    'Leland',
    'Lenoir',
    'Lewiston',
    'Lewisville',
    'Lexington',
    'Liberty',
    'Lilesville',
    'Lillington',
    'Lincolnton',
    'Linwood',
    'Littleton',
    'Longwood',
    'Louisburg',
    'Love Valley',
    'Lowell',
    'Lowgap',
    'Lucama',
    'Lumber Bridge',
    'Lumberton',
    'Lupton',
    'Lynn',
    'Madison',
    'Maggie Valley',
    'Magnolia',
    'Maiden',
    'Manteo',
    'Marble',
    'Marion',
    'Mars Hill',
    'Marshall',
    'Marshville',
    'Marston',
    'Matthews',
    'Maxton',
    'Mayodan',
    'McAdenville',
    'McLeansville',
    'Mebane',
    'Merry Hill',
    'Micaville',
    'Middleburg',
    'Middlesex',
    'Midland',
    'Midway Park',
    'Mill Spring',
    'Mills River',
    'Mineral Springs',
    'Minneapolis',
    'Mint Hill',
    'Misenheimer',
    'Mocksville',
    'Moncure',
    'Monroe',
    'Monroe Park',
    'Mooresboro',
    'Mooresville',
    'Moravian Falls',
    'Morehead City',
    'Morganton',
    'Morgantown',
    'Morrisville',
    'Morven',
    'Mount Airy',
    'Mount Gilead',
    'Mount Holly',
    'Mount Olive',
    'Mount Pleasant, Cabarrus',
    'Mount Ulla',
    'Mountain Home',
    'Moyock',
    'Murfreesboro',
    'Murphy',
    'Nags Head',
    'Naples',
    'Nashville',
    'Nebo, McDowell',
    'New Bern',
    'New Haven',
    'New Hill',
    'New London',
    'Newport',
    'Newton',
    'Newton Grove',
    'Norlina',
    'North Charlotte',
    'North Cove',
    'North Wilkesboro',
    'Norwood',
    'Oak Island',
    'Oak Ridge',
    'Oakboro',
    'Ocean Isle Beach',
    'Ogden',
    'Old Fort',
    'Olin',
    'Otto',
    'Oxford',
    'Pantego',
    'Parkton',
    'Patterson',
    'Paw Creek',
    'Peachland',
    'Pelham',
    'Pembroke',
    'Pendleton',
    'Penrose',
    'Pfafftown',
    'Pikeville',
    'Pilot Mountain',
    'Pine Hall',
    'Pine Level, Johnston',
    'Pinebluff',
    'Pinehurst',
    'Pinetops',
    'Pineville',
    'Pink Hill',
    'Pinnacle',
    'Pisgah Forest',
    'Pittsboro',
    'Pleasant Garden',
    'Pleasant Hill',
    'Plymouth',
    'Polkton',
    'Pollocksville',
    'Poplar Branch',
    'Powells Point',
    'Princeton',
    'Princeville',
    'Providence',
    'Raeford',
    'Raleigh',
    'Raleigh-Durham Apt',
    'Ramseur',
    'Randleman',
    'Ranlo',
    'Red Springs',
    'Regal',
    'Reidsville',
    'Research Triangle Park',
    'Rhodhiss',
    'Rich Square',
    'Richfield',
    'Richlands',
    'Ridgecrest',
    'Ridgeway',
    'Riegelwood',
    'Roanoke Rapids',
    'Roaring River',
    'Robbins',
    'Robbinsville',
    'Robersonville',
    'Rock Hill',
    'Rockingham',
    'Rockwell',
    'Rocky Mount',
    'Rocky Point',
    'Rodanthe',
    'Rolesville',
    'Ronda',
    'Roper',
    'Rose Hill',
    'Roseboro',
    'Roseville',
    'Rosman',
    'Roxboro',
    'Rural Hall',
    'Rutherford College',
    'Rutherfordton',
    'Saint Pauls',
    'Salemburg',
    'Salisbury',
    'Saluda',
    'Sandy Ridge',
    'Sanford',
    'Sapphire',
    'Sawmills',
    'Saxapahaw',
    'Scotland Neck',
    'Seaboard',
    'Seagrove',
    'Selma',
    'Seminole',
    'Semora',
    'Seven Springs',
    'Severn',
    'Sevier',
    'Shallotte',
    'Shannon',
    'Sharpsburg',
    'Shelby',
    'Sherrills Ford',
    'Siler City',
    'Silver City',
    'Simpson',
    'Sims',
    'Skyland',
    'Smithfield',
    'Smiths',
    'Smyrna',
    'Sneads Ferry',
    'Snow Hill',
    'Sophia',
    'South Mills',
    'Southern Pines',
    'Southport',
    'Sparta',
    'Spencer',
    'Spindale',
    'Spring Hope',
    'Spring Lake',
    'Spruce Pine',
    'Staley',
    'Stallings',
    'Stanfield',
    'Stanley',
    'Stantonsburg',
    'Star',
    'Statesville',
    'Stedman',
    'Stokesdale',
    'Stoneville',
    'Stony Point',
    'Stovall',
    'Summerfield',
    'Sunny Point',
    'Sunset Beach',
    'Supply Brunswick County',
    'Surf City',
    'Swan Quarter',
    'Swannanoa',
    'Swepsonville',
    'Sylva',
    'Tabor City',
    'Tar Heel',
    'Tarboro',
    'Taylorsville',
    'Teachey',
    'Thomasville',
    'Thurmond',
    'Tillery',
    'Timberlake',
    'Tobaccoville',
    'Todd',
    'Toddville',
    'Trenton',
    'Trinity',
    'Trinity Park',
    'Troutman',
    'Troy',
    'Tryon',
    'Turkey',
    'Tyner',
    'Union Grove',
    'Valdese',
    'Vale',
    'Valle Crucis',
    'Vanceboro',
    'Vass',
    'Waco',
    'Wade',
    'Wadesboro',
    'Wadeville',
    'Wagram',
    'Wake Forest',
    'Walkertown',
    'Wallace',
    'Wallburg',
    'Walnut Cove',
    'Walstonburg',
    'Wanchese',
    'Warne',
    'Warrenton',
    'Warsaw',
    'Washington',
    'Waxhaw',
    'Waynesville',
    'Weaverville',
    'Webster',
    'Welcome',
    'Weldon',
    'Wendell',
    'West End',
    'West Jefferson',
    'Westfield',
    'Whispering Pines',
    'Whitakers',
    'White Lake',
    'White Oak',
    'Whiteville',
    'Whitsett',
    'Whittier',
    'Wilkesboro',
    'Williamston',
    'Willow Spring',
    'Wilmington',
    'Wilson',
    'Windsor, Bertie',
    'Winfall',
    'Wingate',
    'Winston Salem',
    'Winterville',
    'Winton',
    'Woodfin',
    'Wrightsville Beach',
    'Yadkinville',
    'Yanceyville',
    'Youngsville',
    'Zebulon',
    'Zionville',
    'Zirconia',
    'Winston-Salem'
  ],
  ND: [
    'Abercrombie',
    'Alexander',
    'Ambrose',
    'Anamoose',
    'Antler',
    'Arnegard',
    'Ashley',
    'Baldwin',
    'Beach',
    'Belcourt',
    'Belfield',
    'Benedict',
    'Beulah',
    'Binford',
    'Bismarck',
    'Bonetraill',
    'Bottineau',
    'Bowman',
    'Buxton',
    'Cando',
    'Carbury',
    'Carrington',
    'Carson',
    'Casselton',
    'Cavalier',
    'Center',
    'Colgate',
    'Columbus',
    'Cooperstown',
    'Courtenay',
    'Crete',
    'Crosby',
    'Denhoff',
    'Devils Lake',
    'Dickinson',
    'Drayton',
    'Dunseith',
    'Durbin',
    'Dwight',
    'Edgeley',
    'Eldridge',
    'Ellendale',
    'Enderlin',
    'Englevale',
    'Fairmount',
    'Fargo',
    'Fessenden',
    'Finley',
    'Fordville',
    'Fort Yates',
    'Fortuna',
    'Fryburg',
    'Fullerton',
    'Galesburg',
    'Garrison',
    'Gascoyne',
    'Georgetown',
    'Glen Ullin',
    'Grafton',
    'Grand Forks',
    'Grandin',
    'Grassy Butte',
    'Gwinner',
    'Hamberg',
    'Hamilton',
    'Hannah',
    'Hansboro',
    'Harmon',
    'Harvey',
    'Hatton',
    'Hazen',
    'Hebron',
    'Hettinger',
    'Hillsboro',
    'Hope',
    'Horace',
    'Jamestown',
    'Kenmare',
    'Killdeer',
    'Kindred',
    'Kulm',
    'Lakota',
    'LaMoure',
    'Langdon',
    'Lansford',
    'Larimore',
    'Leeds',
    'Lehr',
    'Leonard',
    'Leroy',
    'Linton',
    'Lisbon, Ransom',
    'Maddock',
    'Maida',
    'Mandan',
    'Manning',
    'Mapleton',
    'Mayville',
    'McCanna',
    'Mcclusky',
    'Mckenzie',
    'Medina',
    'Michigan',
    'Minot',
    'Minto',
    'Mohall',
    'Munich',
    'Napoleon',
    'Neche',
    'New Salem',
    'New Town',
    'Noonan',
    'Northgate',
    'Northwood',
    'Oakes',
    'Pembina',
    'Plaza',
    'Portal',
    'Ray',
    'Red River',
    'Reeder',
    'Regent',
    'Richardton',
    'Rolla',
    'Rugby',
    'Saint John',
    'Sarles',
    'Scranton',
    'Sherwood',
    'South Heart',
    'Spiritwood',
    'Stanley',
    'Steele',
    'Sterling',
    'Sydney',
    'Tappen',
    'Thompson',
    'Tioga',
    'Tolley',
    'Turtle Lake',
    'Underwood',
    'Valley City',
    'Velva',
    'Wahpeton',
    'Walhalla',
    'Warwick',
    'Washburn',
    'Watford City',
    'West Fargo',
    'Westhope',
    'Williston',
    'Wimbledon',
    'Wishek',
    'Zeeland'
  ],
  NE: [
    'Ainsworth',
    'Albion',
    'Alda',
    'Alliance',
    'Arapahoe',
    'Arnold',
    'Ashland',
    'Ashton',
    'Atkinson',
    'Atlanta',
    'Auburn',
    'Aurora',
    'Avoca',
    'Axtell',
    'Bartlett',
    'Bassett',
    'Bayard',
    'Beatrice',
    'Beaver City',
    'Beaver Crossing',
    'Bellevue',
    'Belvidere',
    'Bennet',
    'Bennington',
    'Bladen',
    'Blair',
    'Bloomfield',
    'Blue Springs',
    'Boys Town',
    'Bridgeport',
    'Broken Bow',
    'Brownville',
    'Burchard',
    'Burwell',
    'Cambridge',
    'Campbell',
    'Carroll',
    'Cedar Bluffs',
    'Central City',
    'Ceresco',
    'Chadron',
    'Chalco',
    'Chapman',
    'Clarks',
    'Clay Center',
    'Clearwater',
    'Columbus',
    'Cozad',
    'Crawford',
    'Creighton',
    'Crete',
    'Crofton',
    'Culbertson',
    'Curtis',
    'Dakota City',
    'Dannebrog',
    'David City',
    'De Witt',
    'Decatur',
    'Denton',
    'Deshler',
    'Dix',
    'Dodge',
    'Doniphan',
    'Duncan',
    'Eagle',
    'Eddyville',
    'Edgar',
    'Elkhorn',
    'Elm Creek',
    'Elmwood',
    'Elwood',
    'Endicott',
    'Ewing',
    'Fairbury',
    'Fairmont',
    'Falls City',
    'Farnam',
    'Firth',
    'Fort Calhoun',
    'Franklin',
    'Fremont',
    'Friend',
    'Fullerton',
    'Funk',
    'Geneva',
    'Genoa',
    'Gering',
    'Gibbon',
    'Goehner',
    'Gordon',
    'Gothenburg',
    'Grand Island',
    'Grant',
    'Greenwood',
    'Gretna',
    'Gurley',
    'Hartington',
    'Hastings',
    'Hebron',
    'Henderson',
    'Hershey',
    'Holdrege',
    'Holmesville',
    'Hooper',
    'Howells',
    'Hubbell',
    'Humboldt',
    'Humphrey',
    'Imperial',
    'Kearney',
    'Kenesaw',
    'Kimball',
    'La Vista',
    'Lamar',
    'Laurel',
    'Leigh',
    'Lexington',
    'Lincoln',
    'Lindsay',
    'Lodgepole',
    'Loomis',
    'Louisville',
    'Loup City',
    'Lyons',
    'Madison',
    'Malcolm',
    'Malmo',
    'Marquette',
    'Maxwell',
    'Maywood',
    'McCook',
    'Mead',
    'Milford',
    'Minatare',
    'Minden',
    'Mitchell',
    'Morrill',
    'Morse Bluff',
    'Mullen',
    'Murdock',
    'Murray',
    'Nebraska City',
    'Neligh',
    'Nelson',
    'Newman Grove',
    'Niobrara',
    'Norfolk',
    'North Bend',
    'North Loup',
    'North Platte',
    'Oak',
    'Odessa',
    'Offutt',
    'Ogallala',
    'Omaha',
    "O'Neill",
    'Orchard',
    'Ord',
    'Oshkosh',
    'Osmond',
    'Overton',
    'Oxford',
    'Papillion',
    'Pawnee City',
    'Petersburg',
    'Phillips',
    'Pierce',
    'Plainview',
    'Platte Center',
    'Plattsmouth',
    'Prague',
    'Ralston',
    'Randolph',
    'Ravenna',
    'Red Cloud',
    'Rising City',
    'Roca',
    'Ruskin',
    'Saint Edward',
    'Salem',
    'Schuyler',
    'Scottsbluff',
    'Scribner',
    'Seward',
    'Shelby',
    'Shelton',
    'Sidney',
    'Snyder',
    'South Sioux City',
    'Sparks',
    'Springfield',
    'Springview',
    'Stapleton',
    'Superior',
    'Sutherland',
    'Sutton',
    'Syracuse',
    'Taylor',
    'Tecumseh',
    'Tekamah',
    'Thedford',
    'Thurston',
    'Tilden',
    'Trumbull',
    'Ulysses',
    'Utica',
    'Valentine',
    'Valley',
    'Valparaiso',
    'Waco',
    'Wahoo',
    'Wakefield',
    'Walton',
    'Waterloo',
    'Waverly',
    'Wayne',
    'Weeping Water',
    'West Point',
    'Wilber',
    'Winnebago',
    'Wood River',
    'York',
    'Yutan'
  ],
  NH: [
    'Acworth',
    'Allenstown',
    'Alstead',
    'Alton Bay',
    'Amherst',
    'Andover',
    'Antrim',
    'Ashland',
    'Atkinson',
    'Auburn',
    'Barnstead',
    'Barrington',
    'Bartlett',
    'Bath',
    'Bedford',
    'Belmont',
    'Bennington',
    'Berlin',
    'Bethlehem',
    'Boscawen',
    'Bow',
    'Bow Center',
    'Brentwood',
    'Bretton Woods',
    'Bristol',
    'Brookline',
    'Campton',
    'Canaan',
    'Candia',
    'Canterbury',
    'Center Harbor',
    'Center Tuftonboro',
    'Charlestown',
    'Chester',
    'Chesterfield',
    'Chichester',
    'Claremont',
    'Colebrook',
    'Concord',
    'Contoocook',
    'Conway',
    'Cornish',
    'Dalton',
    'Danbury',
    'Danville',
    'Deerfield',
    'Derry',
    'Dixville Notch',
    'Dover',
    'Dublin',
    'Durham',
    'East Kingston',
    'East Swanzey',
    'Eaton Center',
    'Effingham',
    'Ellsworth',
    'Enfield',
    'Epping',
    'Epsom',
    'Errol',
    'Exeter',
    'Farmington',
    'Fitzwilliam',
    'Francestown',
    'Franconia',
    'Franklin',
    'Fremont',
    'Georges Mills',
    'Gilford',
    'Gilmanton',
    'Gilsum',
    'Goffstown',
    'Gonic',
    'Gorham',
    'Goshen',
    'Grafton',
    'Greenfield',
    'Greenland',
    'Greenville',
    'Groton',
    'Groveton',
    'Guild',
    'Hampstead',
    'Hampton',
    'Hampton Falls',
    'Hamshire',
    'Hancock',
    'Hanover',
    'Hanover-Lebanon-White River Apt',
    'Harrisville',
    'Haverhill',
    'Henniker',
    'Hill',
    'Hillsboro',
    'Hinsdale',
    'Holderness',
    'Hollis',
    'Hooksett',
    'Hudson',
    'Jackson',
    'Jaffrey',
    'Jefferson',
    'Keene',
    'Kingston',
    'Laconia',
    'Lancaster',
    'Langdon',
    'Lebanon',
    'Lee',
    'Lempster',
    'Lincoln',
    'Lisbon',
    'Litchfield',
    'Littleton',
    'Londonderry',
    'Loudon',
    'Lyme',
    'Lyndeborough',
    'Madbury',
    'Madison',
    'Manchester',
    'Marlborough',
    'Marlow',
    'Melvin Village',
    'Meredith',
    'Merrimack',
    'Milan',
    'Milford',
    'Milton',
    'Milton Mills',
    'Mirror Lake',
    'Monroe',
    'Mont Vernon',
    'Moultonborough',
    'Nashua',
    'Nelson',
    'New Boston',
    'New Castle',
    'New Durham',
    'New Hampton',
    'New Ipswich',
    'New London',
    'New Londonderry',
    'Newbury',
    'Newfields',
    'Newington',
    'Newmarket',
    'Newport',
    'Newton',
    'North Conway',
    'North Hampton',
    'North Stratford',
    'North Walpole',
    'North Woodstock',
    'Northfield',
    'Northwood',
    'Nottingham',
    'Orford',
    'Ossipee',
    'Pelham',
    'Pembroke',
    'Penacook',
    'Peterborough',
    'Piermont',
    'Pittsburg',
    'Pittsfield',
    'Plainfield',
    'Plaistow',
    'Plymouth',
    'Portsmouth',
    'Randolph',
    'Raymond',
    'Richmond',
    'Rindge',
    'Rochester',
    'Rollinsford',
    'Rumney',
    'Rye',
    'Rye Beach',
    'Salem',
    'Salisbury',
    'Sanbornton',
    'Sanbornville',
    'Sandown',
    'Seabrook',
    'Silver Lake',
    'Somersworth',
    'South Hampton',
    'Spofford',
    'Springfield',
    'Stoddard',
    'Strafford',
    'Stratham',
    'Sugar Hill',
    'Sullivan',
    'Sunapee',
    'Suncook',
    'Sutton',
    'Swanzey',
    'Tamworth',
    'Temple',
    'Thornton',
    'Tilton',
    'Troy',
    'Twin Mountain',
    'Walpole',
    'Warner',
    'Warren',
    'Washington',
    'Waterville Valley',
    'Weare',
    'Wentworth',
    'West Chesterfield',
    'West Lebanon',
    'West Ossipee',
    'West Peterborough',
    'West Springfield',
    'West Swanzey',
    'Westmoreland',
    'Whitefield',
    'Wilmot',
    'Wilton',
    'Winchester',
    'Windham',
    'Wolfeboro',
    'Woodstock',
    'Woodsville'
  ],
  NJ: [
    'Aberdeen',
    'Absecon',
    'Adelphia',
    'Allamuchy',
    'Allendale',
    'Allenhurst',
    'Allentown',
    'Allenwood',
    'Alpha',
    'Alpine',
    'Ambrose Channel Light',
    'Andover',
    'Annandale',
    'Applegarth',
    'Ardena',
    'Arlington',
    'Asbury',
    'Asbury Park',
    'Asbury, Gloucester',
    'Asbury, Warren',
    'Atlantic City',
    'Atlantic Highlands',
    'Augusta',
    'Aura',
    'Avalon',
    'Avenel',
    'Avon-by-the-Sea',
    'Bairdsville',
    'Barnegat',
    'Barrington',
    'Basking Ridge',
    'Bay Head',
    'Bayonne',
    'Bayville',
    'Bayway',
    'Beach Haven',
    'Beachwood',
    'Beattystown',
    'Bedminster',
    'Belford',
    'Belle Mead',
    'Belleville',
    'Bellmawr',
    'Belmar',
    'Belvidere',
    'Bergen',
    'Bergenfield',
    'Berkeley Heights',
    'Berlin',
    'Bernardsville',
    'Beverly',
    'Birch Hills',
    'Birmingham',
    'Blackwood',
    'Blairstown',
    'Bloomfield',
    'Bloomingdale',
    'Bloomsbury',
    'Blue Anchor',
    'Bogota',
    'Bonhamtown',
    'Boonton',
    'Bordentown',
    'Bound Brook',
    'Bradevelt',
    'Bradley Beach',
    'Brainards',
    'Branchburg',
    'Branchburg Park',
    'Branchville',
    'Brick',
    'Bricktown',
    'Bridgeport',
    'Bridgeton',
    'Bridgewater',
    'Broadway',
    'Brooklawn',
    'Brookside',
    'Browns Mills',
    'Brunswick Gardens',
    'Budd Lake',
    'Buena',
    'Burlington',
    'Butler',
    'Caldwell',
    'Califon',
    'Camden',
    'Canton',
    'Cape May',
    'Cape May Court House',
    'Carlstadt',
    'Carneys Point',
    'Carrs Corner',
    'Carteret',
    'Cedar Brook',
    'Cedar Grove',
    'Cedar Knolls',
    'Cedarville',
    'Charleston Springs',
    'Chatham',
    'Chatsworth',
    'Cherry Hill',
    'Chester',
    'Chesterfield',
    'Chrome',
    'Cinnaminson',
    'Clark',
    'Clarksboro',
    'Clarksburg',
    'Clayton',
    'Clementon',
    'Clermont',
    'Cliffside Park',
    'Cliffwood',
    'Clifton',
    'Clinton',
    'Clinton Township',
    'Closter/New Jersey',
    'Clyde',
    'Collingswood',
    'Colonia',
    'Colts Neck',
    'Columbia',
    'Columbus',
    'Coontown',
    'Corbin City',
    'Cranbury',
    'Crandon Lakes',
    'Cranford',
    'Cream Ridge',
    'Cresskill',
    'Croxton',
    'Dayton',
    'Deal',
    'Deans',
    'Deepwater',
    'Deer Park, Camden',
    'Deerfield',
    'Delair',
    'Delanco',
    'Delaware',
    'Delran',
    'Demarest',
    'Dennisville',
    'Denville',
    'Deptford',
    'Deptford Township',
    'Dover',
    'Dumont',
    'Dunellen',
    'Eagle Point',
    'Earle',
    'East Brunswick',
    'East Freehold',
    'East Hanover',
    'East Keansburg',
    'East Millstone',
    'East Newark',
    'East Orange',
    'East Rutherford',
    'East Windsor',
    'Eastampton Township',
    'Eatontown',
    'Edgar',
    'Edgewater',
    'Edgewater Park',
    'Edgewood',
    'Edison',
    'Egg Harbor City',
    'Egg Harbor Township',
    'Elbert',
    'Elizabeth',
    'Elizabethport',
    'Ellisdale',
    'Elmer',
    'Elmwood Park',
    'Elwood',
    'Emerson',
    'Englewood',
    'Englewood Cliffs',
    'Englishtown',
    'Erial',
    'Essex Fells',
    'Everett',
    'Ewing',
    'Fair Haven',
    'Fair Lawn',
    'Fair View',
    'Fairfield',
    'Fairton',
    'Fairview',
    'Fanwood',
    'Far Hills',
    'Farmingdale',
    'Farmington',
    'Fieldsboro',
    'Finderne',
    'Flagtown',
    'Flanders',
    'Flemington',
    'Florence',
    'Florham Park',
    'Folsom',
    'Fords',
    'Forked River',
    'Fort Dix',
    'Fort Lee',
    'Fort Monmouth',
    'Franklin',
    'Franklin Lakes',
    'Franklinville',
    'Freehold',
    'Frenchtown',
    'Galloway',
    'Garden City',
    'Garfield',
    'Garwood',
    'Georgia',
    'Gibbsboro',
    'Gibbstown',
    'Gillette',
    'Glassboro',
    'Glen Gardner',
    'Glen Ridge',
    'Glen Rock',
    'Glendora',
    'Gloucester City',
    'Great Meadows',
    'Green Brook',
    'Green Pond',
    'Grenloch',
    'Guttenberg',
    'Hackensack',
    'Hackettstown',
    'Haddon Heights',
    'Haddonfield',
    'Hainesport',
    'Haledon',
    'Hamburg',
    'Hamilton Square',
    'Hamilton, Monmouth',
    'Hamilton, Ocean',
    'Hammonton',
    'Hampton',
    'Hancocks Bridge',
    'Hanover',
    'Hardwick',
    'Harrington Park',
    'Harrison',
    'Harvey Cedars',
    'Hasbrouck Heights',
    'Haskell',
    'Haworth',
    'Hawthorne',
    'Hazlet',
    'Herbertsville',
    'Hibernia',
    'High Bridge',
    'Highlands',
    'Hightstown',
    'Hillsborough',
    'Hillsdale',
    'Hillside',
    'Hoboken',
    'Ho-Ho-Kus',
    'Holmdel',
    'Hopatcong',
    'Hope',
    'Hopelawn',
    'Hopewell',
    'Howell',
    'Howland Hook',
    'Hurffville',
    'Indian Mills',
    'Irvington',
    'Iselin',
    'Jackson',
    'Jacksonville',
    'Jacobstown',
    'Jamesburg',
    'Jersey City',
    'Jobstown',
    'Johnsonburg',
    'Keansburg',
    'Kearny',
    'Keasbey',
    'Kendall Park',
    'Kenilworth',
    'Kenvil',
    'Keyport',
    'Kingston',
    'Kinnelon',
    'Lafayette',
    'Lake Hiawatha',
    'Lake Hopatcong',
    'Lakehurst',
    'Lakewood',
    'Lambertville',
    'Landing',
    'Landisville',
    'Lanoka Harbor',
    'Laurence Harbor',
    'Lavallette',
    'Lawnside',
    'Lawrence Township',
    'Lawrenceville',
    'Lebanon',
    'Ledgewood',
    'Leonardo',
    'Leonia',
    'Lincoln Park',
    'Lincroft',
    'Linden',
    'Lindenwold',
    'Linwood',
    'Little Falls',
    'Little Ferry',
    'Little Silver',
    'Livingston',
    'Locust',
    'Lodi',
    'Logan Township',
    'Long Beach',
    'Long Branch',
    'Long Valley',
    'Longport',
    'Lopatcong',
    'Lumberton',
    'Lyndhurst',
    'Madison',
    'Maher Terminal',
    'Mahwah',
    'Malaga',
    'Manahawkin',
    'Manalapan',
    'Manasquan',
    'Manchester',
    'Mannington',
    'Mannington Mills',
    'Mantoloking',
    'Mantua',
    'Manville',
    'Maple Shade',
    'Maplewood',
    'Marlboro',
    'Marlton',
    'Martinsville',
    'Matawan',
    'Mauricetown',
    'Mays Landing',
    'Maywood',
    'Medford',
    'Mendham',
    'Mercerville',
    'Merchantville',
    'Metuchen',
    'Mickleton',
    'Middlesex',
    'Middletown',
    'Midland Park',
    'Milford',
    'Millburn',
    'Millington',
    'Millstone',
    'Millstone Township',
    'Milltown',
    'Millville',
    'Milville',
    'Mine Hill',
    'Minotola',
    'Monmouth Beach',
    'Monmouth Junction',
    'Monroe',
    'Monroe Township',
    'Monroeville',
    'Montague',
    'Montclair',
    'Montvale',
    'Montville',
    'Moonachie/New Jersey',
    'Moorestown',
    'Morganville',
    'Moriches',
    'Morris Plains',
    'Morristown',
    'Morrisville',
    'Mount Arlington',
    'Mount Freedom',
    'Mount Holly',
    'Mount Laurel',
    'Mount Olive',
    'Mount Royal',
    'Mountain Lakes',
    'Mountainside',
    'Mountainville',
    'Mounts Mills',
    'Mullica Hill',
    'Murray Hill',
    'Neptune',
    'Neptune City',
    'Neshanic Station',
    'Netcong',
    'New Brunswick',
    'New Egypt',
    'New Gretna',
    'New Milford',
    'New Providence',
    'New Vernon',
    'Newark',
    'Newfield',
    'Newfoundland',
    'Newton',
    'Norma',
    'North Arlington',
    'North Bergen',
    'North Branch',
    'North Brunswick',
    'North Caldwell',
    'North Haledon',
    'North Plainfield',
    'North Wildwood',
    'Northfield',
    'Northvale',
    'Norwood',
    'Nutley',
    'Oak Island',
    'Oak Ridge',
    'Oakhurst',
    'Oakland',
    'Ocean',
    'Ocean City',
    'Ocean Gate',
    'Ocean View',
    'Oceanport',
    'Oceanview',
    'Ogdensburg',
    'Old Bridge',
    'Old Tappan',
    'Oradell',
    'Orange',
    'Oxford',
    'Palisade',
    'Palisades Park',
    'Palmyra',
    'Paramus',
    'Park Ridge',
    'Parkridge',
    'Parlin',
    'Parsippany',
    'Passaic',
    'Paterson',
    'Paulsboro',
    'Peapack',
    'Pedricktown',
    'Pennington',
    'Penns Grove',
    'Pennsauken',
    'Pennsville',
    'Pequannock',
    'Perrineville',
    'Perth Amboy',
    'Petersburg',
    'Phillipsburg',
    'Picatinny Arsenal',
    'Pine Brook',
    'Pine Hill',
    'Piscataway',
    'Pitman',
    'Pittsgrove',
    'Pittstown',
    'Plainfield',
    'Plainsboro',
    'Pleasantville',
    'Pluckemin',
    'Point Pleasant',
    'Point Pleasant Beach',
    'Pompton',
    'Pompton Lakes',
    'Pompton Plains',
    'Port Elizabeth',
    'Port Monmouth',
    'Port Murray',
    'Port Newark',
    'Port Norris',
    'Port Reading',
    'Port Republic',
    'Port Washington',
    'Potterstown',
    'Princeton',
    'Princeton Junction',
    'Prospect',
    'Quakertown',
    'Rahway',
    'Ramsey',
    'Rancocas',
    'Randolph',
    'Raritan',
    'Readington',
    'Red Bank',
    'Richwood',
    'Ridgefield',
    'Ridgefield Park',
    'Ridgewood',
    'Ringoes',
    'Ringwood',
    'Rio Grande',
    'River Edge',
    'Riverdale',
    'Riverside',
    'Riverton',
    'Robbinsville',
    'Robertsville',
    'Robinsville',
    'Rochelle Park',
    'Rockaway',
    'Rockleigh',
    'Rockport',
    'Roebling',
    'Rosedale',
    'Roseland',
    'Roselle',
    'Roselle Park',
    'Rosemont',
    'Rosenhayn',
    'Rumson',
    'Runnemede',
    'Rutherford',
    'Saddle Brook',
    'Saddle River',
    'Salem',
    'Sayreville',
    'Scobeyville',
    'Scotch Plains',
    'Sea Girt',
    'Seabrook',
    'Seaside Heights',
    'Secaucus',
    'Sewaren',
    'Sewell',
    'Shamong',
    'Shiloh',
    'Ship Bottom',
    'Short Hills',
    'Shrewsbury',
    'Sicklerville',
    'Skillman',
    'Somerdale',
    'Somers Point',
    'Somerset',
    'Somerville',
    'South Amboy',
    'South Brunswick',
    'South Hackensack/New Jersey',
    'South Kearny',
    'South Orange',
    'South Plainfield',
    'South River',
    'Southampton',
    'Sparta',
    'Spotswood',
    'Spring Lake',
    'Springfield',
    'Stanhope',
    'Stewartsville',
    'Stillwater',
    'Stirling',
    'Stockton',
    'Stratford',
    'Strathmere',
    'Succasunna',
    'Summit',
    'Surf City',
    'Sussex',
    'Swedesboro',
    'Tabernacle',
    'Tavistock',
    'Teaneck',
    'Tenafly',
    'Tennent',
    'Teterboro',
    'Tewksbury',
    'Thorofare',
    'Three Bridges',
    'Tinton Falls',
    'Toms River',
    'Totowa',
    'Towaco',
    'Township of Woolwich',
    'Trenton',
    'Tuckerton',
    'Turnersville',
    'Union',
    'Union Beach',
    'Union City',
    'Upper Freehold',
    'Upper Saddle River',
    'Vauxhall',
    'Ventnor City',
    'Verno',
    'Vernon',
    'Vernon, Camden',
    'Verona',
    'Vienna',
    'Vincentown',
    'Vineland',
    'Voorhees',
    'Waldwick',
    'Wall',
    'Wall Township',
    'Wallington',
    'Wanaque',
    'Wantage',
    'Warren',
    'Warrenville',
    'Washington',
    'Washington Township',
    'Watchung',
    'Waterford Works',
    'Wayne',
    'Weehawken',
    'Wenonah',
    'West Berlin',
    'West Caldwell',
    'West Creek',
    'West Deptford',
    'West Long Branch',
    'West Milford',
    'West New York',
    'West Orange/New Jersey',
    'West Paterson',
    'West Portal',
    'West Trenton',
    'West Windsor',
    'West Winfield',
    'Westampton',
    'Westfield',
    'Westhampton',
    'Westville',
    'Westwood',
    'Wharton',
    'Whippany',
    'Whitehouse',
    'Whitehouse Station',
    'Wildwood',
    'Williamstown',
    'Willingboro',
    'Wills Point',
    'Windsor',
    'Winslow',
    'Woodbine',
    'Woodbridge',
    'Woodbury',
    'Woodcliff Lakes',
    'Woodland Park',
    'Wood-Ridge',
    'Woodstown',
    'Woolwich Township',
    'Wrightstown',
    'Wyckoff',
    'Yardville',
    'Yorketown'
  ],
  NM: [
    'Abiquiu',
    'Alamogordo',
    'Albuquerque',
    'Alcade',
    'Alcalde',
    'Algodones',
    'Angel Fire',
    'Animas',
    'Anthony',
    'Arrey',
    'Arroyo Seco',
    'Artesia',
    'Aztec',
    'Belen',
    'Bernalillo',
    'Bloomfield',
    'Bosque Farms',
    'Buchanan',
    'Cannon Afb',
    'Capitan',
    'Carlsbad',
    'Carrizozo',
    'Casa Blanca',
    'Chama',
    'Chamberino',
    'Chimayo',
    'Clayton',
    'Clovis',
    'Columbus',
    'Corrales',
    'Coyote',
    'Cuba',
    'Culebra',
    'Dalies',
    'Deming',
    'Dexter',
    'Dora',
    'Edgewood',
    'El Prado',
    'Elida',
    'Espanola',
    'Estancia',
    'Eunice',
    'Farmington',
    'Fort Sumner',
    'Fort Wingate',
    'Fruitland',
    'Gallup',
    'Grants',
    'Hagerman',
    'Hanover',
    'Hatch',
    'Hobbs',
    'Holloman Air Force Base',
    'Holman',
    'Hondo',
    'Hurley',
    'Jal',
    'Kirtland',
    'La Luz',
    'La Mesa',
    'La Union',
    'Las Cruces',
    'Las Vegas',
    'Lemitar',
    'Loco Hills',
    'Lordsburg',
    'Los Alamos',
    'Los Lunas',
    'Loving',
    'Lovington',
    'Lyden',
    'Mentmore',
    'Mesilla Park',
    'Mesquite',
    'Milan',
    'Mimbres',
    'Mora',
    'Moriarty',
    'Ohkay Owingeh',
    'Orogrande',
    'Otto',
    'Penasco',
    'Peralta',
    'Placitas',
    'Portales',
    'Prewitt',
    'Quemado',
    'Radium Springs',
    'Ranchos de Taos',
    'Raton',
    'Red River',
    'Rincon',
    'Rio Rancho',
    'Rodeo',
    'Roswell',
    'Ruidoso',
    'Salem',
    'San Antonio',
    'San Jon',
    'San Juan Pueblo',
    'San Ysidro',
    'Sandia Park',
    'Santa Ana Pueblo',
    'Santa Clara',
    'Santa Fe',
    'Santa Rosa',
    'Santa Teresa',
    'Silver City',
    'Sixela',
    'Socorro',
    'Sunland Park',
    'Taos',
    'Tesuque',
    'Tijeras',
    'Torreon',
    'Truth Or Consequences',
    'Tucumcari',
    'Tyrone',
    'Vado',
    'Valdez',
    'Vanadium',
    'Vaughn',
    'Wagon Mound',
    'Waterflow',
    'White Rock',
    'White Sands',
    'Willard',
    'Winston',
    'Zitlaltepec'
  ],
  NV: [
    'Alamo',
    'Amargosa Valley',
    'Austin',
    'Baker',
    'Battle Mountain',
    'Beatty',
    'Beowawe',
    'Blue Diamond',
    'Boulder City',
    'Carlin',
    'Carson City',
    'Crystal Bay',
    'Dayton',
    'Dyer',
    'Elko',
    'Ely',
    'Empire',
    'Eureka',
    'Fallon',
    'Fernley',
    'Gabbs',
    'Gardnerville',
    'Gerlach',
    'Glenbrook',
    'Golconda',
    'Goodsprings',
    'Hawthorne',
    'Hazen',
    'Henderson',
    'Incline Village',
    'Indian Springs',
    'Jackpot',
    'Jean',
    'Las Vegas',
    'Lathrop Wells',
    'Laughlin',
    'Lockwood',
    'Lovelock',
    'Manhattan',
    'McCarran',
    'Mercury',
    'Mesquite',
    'Midas',
    'Mill City',
    'Minden',
    'Moapa',
    'Mound House',
    'Nellis Air Force Base',
    'North Las Vegas',
    'Overton, Clark',
    'Pahrump',
    'Panaca',
    'Patrick',
    'Pioche',
    'Primm',
    'Reno',
    'Reno-Stead',
    'Round Mountain',
    'Ruby Valley',
    'Ruth',
    'Sandy Valley',
    'Silver Peak',
    'Silver Springs',
    'Sloan',
    'Spanish Springs',
    'Sparks',
    'Spring Creek',
    'Stateline',
    'Sun Valley',
    'Tonopah',
    'Tuscarora',
    'Valmy',
    'Verdi',
    'Wadsworth',
    'Wells',
    'West Wendover',
    'Winnemucca',
    'Yerington',
    'Yucca Flat',
    'Zephyr Cove'
  ],
  NY: [
    'Accord',
    'Acra',
    'Adams Center',
    'Addison',
    'Airmont',
    'Akron',
    'Alabama',
    'Albany',
    'Albertson',
    'Albion',
    'Alden',
    'Alexander',
    'Alexandria Bay',
    'Alfred',
    'Alfred Station',
    'Allegany',
    'Allentown, Allegany',
    'Alsen',
    'Altamont',
    'Alton',
    'Amagansett',
    'Amherst',
    'Amityville',
    'Amsterdam',
    'Ancram',
    'Anderson',
    'Andes',
    'Andover',
    'Angelica',
    'Angola',
    'Annandale-on-Hudson',
    'Antwerp',
    'Apalachin',
    'Appleton',
    'Aquebogue',
    'Arcade',
    'Ardsley',
    'Argyle',
    'Arkport',
    'Arlington',
    'Armonk',
    'Arverne',
    'Ashville',
    'Astoria/Queens/New York',
    'Athens',
    'Atlantic Beach',
    'Attica',
    'Auburn',
    'Aurora',
    'Ava',
    'Averill Park',
    'Avoca',
    'Avon',
    'Babylon',
    'Bainbridge',
    'Baiting Hollow',
    'Baldwin',
    'Baldwinsville',
    'Ballston Lake',
    'Ballston Spa',
    'Balmat',
    'Baltimore',
    'Barker',
    'Basom',
    'Batavia',
    'Bath',
    'Battenville',
    'Bay Shore',
    'Bayport',
    'Bayside',
    'Bayville',
    'Beacon',
    'Beacon Hills',
    'Beaver Falls',
    'Bedford',
    'Bedford Hills',
    'Belle Harbor',
    'Bellerose',
    'Belleville',
    'Bellmore',
    'Bellport',
    'Belmont',
    'Bergen',
    'Bergholtz',
    'Berlin',
    'Bethel',
    'Bethpage',
    'Big Flats',
    'Big Tree',
    'Binghamton',
    'Binghamton-Endicott-Johnson City Apt',
    'Blasdell',
    'Blauvelt',
    'Blodgett Mills',
    'Bloomfield',
    'Bloomingburg',
    'Bloomington',
    'Blue Point',
    'Bohemia',
    'Bolivar',
    'Bolton Landing',
    'Bombay',
    'Boonville',
    'Boston',
    'Bovina Center',
    'Bowmansville',
    'Brant Lake',
    'Brasher Falls',
    'Brentwood',
    'Brewerton',
    'Brewster',
    'Briarcliff Manor',
    'Bridgehampton',
    'Bridgeport',
    'Bridgewater',
    'Brockport',
    'Bronx',
    'Bronxville',
    'Brookfield',
    'Brookhaven',
    'Brooklyn/New York',
    'Brookville',
    'Brownville',
    'Brushton',
    'Buchanan',
    'Buffalo',
    'Bullville',
    'Bulville',
    'Burdett',
    'Burke',
    'Burlington',
    'Burlington Flats',
    'Burt',
    'Byron',
    'Caledonia',
    'Callicoon',
    'Calverton',
    'Cambridge',
    'Camden',
    'Camillus',
    'Campbell',
    'Campbell Hall',
    'Canajoharie',
    'Canandaigua',
    'Canastota',
    'Candor',
    'Canisteo',
    'Canton',
    'Cape Vincent',
    'Carle Place',
    'Carlisle',
    'Carman',
    'Carmel',
    'Carthage',
    'Castile',
    'Castleton On Hudson',
    'Castorland',
    'Catatonk',
    'Cato',
    'Catskill',
    'Cattaraugus',
    'Cayuga',
    'Cayuta',
    'Cazenovia',
    'Cedarhurst',
    'Celoron',
    'Cementon',
    'Center Moriches',
    'Centereach',
    'Centerport',
    'Centerport, Gayuga',
    'Central Bridge',
    'Central Islip',
    'Central Valley',
    'Chadwicks',
    'Chaffee',
    'Champlain',
    'Chappaqua',
    'Charlotte',
    'Chase Mills',
    'Chateaugay',
    'Chatham',
    'Chautauqua',
    'Chazy',
    'Cheektowaga',
    'Chelsea',
    'Chemung',
    'Chenango Bridge',
    'Chenango Forks',
    'Cherry Valley',
    'Chester',
    'Chestertown',
    'Chestnut Ridge',
    'Chili',
    'Chittenango',
    'Churchville',
    'Churubusco',
    'Cicero',
    'City Island',
    'Clarence',
    'Clarence Center',
    'Claverack',
    'Clay',
    'Clayton',
    'Clermont',
    'Cleveland',
    'Clifton Park',
    'Clifton Springs',
    'Clinton',
    'Clyde',
    'Clymer',
    'Cobleskill',
    'Cochecton',
    'Coeymans',
    'Cohocton',
    'Cohoes',
    'Cold Spring',
    'Cold Spring Harbor',
    'Colden',
    'College Point',
    'Collins',
    'Colonie',
    'Commack',
    'Conewango Valley',
    'Congers',
    'Conklin',
    'Cooperstown',
    'Copake',
    'Copake Falls',
    'Copenhagen',
    'Copiague',
    'Coram',
    'Corfu',
    'Corinth',
    'Corning',
    'Cornwall, Town of',
    'Corona',
    'Cortland',
    'Cowlesville',
    'Coxsackie, Greene',
    'Cranberry Lake',
    'Craryville',
    'Croton Falls',
    'Croton-on-Hudson',
    'Cuba',
    'Cutchogue',
    'Dansville',
    'Danville',
    'Darien Center',
    'Dayton',
    'De Witt',
    'Deansboro',
    'Deer Park',
    'Deferiet',
    'Delanson',
    'Delevan',
    'Delhi',
    'Denmark',
    'Denver',
    'Depew',
    'Deposit',
    'Derby',
    'Dewitt',
    'Dewittville',
    'Dexter',
    'Dix Hills',
    'Dobbs Ferry',
    'Dolgeville',
    'Douglaston/Queens/New York',
    'Dover Plains',
    'Downsville',
    'Dresden',
    'Dryden',
    'Duanesburg',
    'Dundee',
    'Dunkirk',
    'Eagle Bridge',
    'East Amherst',
    'East Aurora',
    'East Bloomfield',
    'East Branch',
    'East Chatham',
    'East Concord',
    'East Durham',
    'East Elmhurst',
    'East Farmingdale',
    'East Fishkill',
    'East Glenville',
    'East Greenbush',
    'East Hampton',
    'East Islip',
    'East Jewett',
    'East Meadow',
    'East Meredith',
    'East Moriches',
    'East New York',
    'East Northport',
    'East Norwich',
    'East Otto',
    'East Palmyra',
    'East Patchogue',
    'East Pembroke',
    'East Quogue',
    'East Rochester',
    'East Rockaway',
    'East Setauket',
    'East Springfield',
    'East Syracuse',
    'Eastchester',
    'Easton',
    'Eastport',
    'Eden',
    'Edgewood',
    'Edgewood, Suffolk',
    'Elba',
    'Elbridge',
    'Elizabethtown',
    'Elizaville',
    'Elka Park',
    'Ellenville',
    'Ellicottville',
    'Elma',
    'Elma, Erie',
    'Elmhurst',
    'Elmira',
    'Elmira Heights',
    'Elmont',
    'Elmsford',
    'Endicott',
    'Endicott-Johnson City-Binghamton Apt',
    'Endwell',
    'Englewood',
    'Erwins',
    'Esopus',
    'Esperance',
    'Essex',
    'Evans Mills',
    'Fairfield',
    'Fairport',
    'Falconer',
    'Fancher',
    'Far Rockaway, Queens',
    'Farmingdale',
    'Farmington',
    'Farmingville',
    'Fayetteville',
    'Ferndale',
    'Feura Bush',
    'Fillmore',
    'Findley Lake',
    'Fishers',
    'Fishers Island',
    'Fishkill',
    'Flanders',
    'Fleischmanns',
    'Fleming',
    'Floodwood',
    'Floral Park',
    'Florida',
    'Flushing Apt/New York',
    'Flushing/Queens/New York',
    'Fonda',
    'Forest Hills/Queens/New York',
    'Forestburg',
    'Forestport',
    'Forestville',
    'Fort Ann',
    'Fort Covington',
    'Fort Drum',
    'Fort Edward',
    'Fort Montgomery',
    'Fort Plain',
    'Frankfort',
    'Franklin Springs',
    'Franklin Square',
    'Fraser',
    'Fredonia',
    'Freedom',
    'Freehold',
    'Freeport',
    'Freeville',
    'Fremont Center',
    'Fresh Meadows',
    'Frewsburg',
    'Fulton',
    'Fultonville',
    'Gabriels',
    'Gainesville',
    'Gansevoort',
    'Garden City',
    'Garden City Park',
    'Gardiner',
    'Garfield',
    'Garnerville',
    'Garrattsville',
    'Garrison',
    'Gasport',
    'Geddes',
    'Geneseo',
    'Geneva',
    'Genoa',
    'Germantown',
    'Getzville',
    'Ghent',
    'Glen Cove',
    'Glen Head',
    'Glen Oaks',
    'Glen Spey',
    'Glendale',
    'Glenmont',
    'Glens Falls',
    'Glenville',
    'Glenwood Landing',
    'Gloversville',
    'Gorham',
    'Goshen',
    'Gouverneur',
    'Grand Gorge',
    'Grand Island',
    'Granville',
    'Great Neck',
    'Greece',
    'Green Island',
    'Green Point',
    'Greenboro',
    'Greene',
    'Greenfield',
    'Greenlawn',
    'Greenport',
    'Greenvale',
    'Greenville',
    'Greenwich',
    'Groton',
    'Guilderland',
    'Guilderland Center',
    'Hagaman',
    'Halcott Center',
    'Halfmoon',
    'Hall',
    'Hamburg',
    'Hamden',
    'Hamilton',
    'Hamlin',
    'Hammondsport',
    'Hancock',
    'Harford',
    'Harpursville',
    'Harriman',
    'Harrison',
    'Hartford',
    'Hartsdale',
    'Hastings-on-Hudson',
    'Hauppauge',
    'Haverstraw',
    'Hawthorne',
    'Hebron',
    'Hector',
    'Hempstead',
    'Henderson',
    'Henrietta',
    'Herkimer',
    'Heuvelton',
    'Hewlett',
    'Hicksville',
    'High Falls',
    'Highland',
    'Highland Falls',
    'Highland Mills',
    'Hillburn',
    'Hillsdale',
    'Hilton',
    'Himrod',
    'Hogansburg',
    'Holbrook',
    'Holcomb',
    'Holland',
    'Holley',
    'Hollis',
    'Holmes',
    'Holtsville',
    'Homer',
    'Honeoye',
    'Honeoye Falls',
    'Hoosick Falls',
    'Hopewell Junction',
    'Hornell',
    'Horseheads',
    'Howard Beach/Queens/New York',
    'Hubbardsville',
    'Hudson',
    'Hudson Falls',
    'Huguenot',
    'Hunter',
    'Huntington',
    'Huntington Station',
    'Hunts Point/Bronx/New York',
    'Hurley',
    'Hyde Park',
    'Ilion',
    'Indian Lake',
    'Interlaken',
    'Inwood',
    'Irondequoit',
    'Irving',
    'Irvington',
    'Island Park',
    'Islandia',
    'Islip',
    'Islip Terrace',
    'Ithaca',
    'Jackson Heights',
    'Jamaica/Queens/New York',
    'Jamesport',
    'Jamestown',
    'Jamesville',
    'Jefferson',
    'Jeffersonville',
    'Jericho',
    'John F. Kennedy Apt/New York',
    'Johnsburg',
    'Johnson',
    'Johnson City',
    'Johnson City-Binghamton-Endicott Apt',
    'Johnstown',
    'Jordan',
    'Kanona',
    'Katonah',
    'Keene Valley',
    'Keeseville',
    'Kendall',
    'Kenilworth',
    'Kenmore',
    'Kerhonkson',
    'Kew Gardens, Queens',
    'Kiamesha Lake',
    'Kill Buck',
    'Kinderhook',
    'King Ferry',
    'Kings Park',
    'Kingston',
    'Kirkville',
    'Kirkwood',
    'La Fargeville',
    'La Guardia Apt/New York',
    'Lackawanna',
    'Lacona',
    'Lafayette',
    'Lagrangeville',
    'Lake George',
    'Lake Grove',
    'Lake Huntington',
    'Lake Katerine',
    'Lake Katrine',
    'Lake Placid',
    'Lake Ronkonkoma',
    'Lake Success',
    'Lake View, Erie',
    'Lakeville, Livingston',
    'Lakewood',
    'Lamont',
    'Lancaster',
    'Lansing, Tomkins',
    'Larchmont',
    'Latham',
    'Laurel',
    'Laurelton',
    'Laurens',
    'Lawrence',
    'Le Roy',
    'Lee',
    'Leicester',
    'Levittown',
    'Lewiston',
    'Liberty',
    'Lido Beach',
    'Lima',
    'Linden Hill',
    'Lindenhurst',
    'Linwood',
    'Lisbon',
    'Little Falls',
    'Little Genesee',
    'Little Neck/Queens/New York',
    'Little Valley',
    'Liverpool',
    'Livingston',
    'Livingston Manor',
    'Livonia',
    'Lockport',
    'Locust Valley',
    'Lodi',
    'Long Beach',
    'Long Eddy',
    'Long Island City/Queens/New York',
    'Long Lake',
    'Loudonville',
    'Lowville',
    'Lynbrook',
    'Lyndonville',
    'Lyons',
    'Lyons Falls',
    'Lysander',
    'Macedon',
    'Madison',
    'Mahopac',
    'Malone',
    'Malta',
    'Mamaroneck',
    'Manchester',
    'Manhasset',
    'Manhattan/New York',
    'Manheim Center',
    'Manlius',
    'Mannsville',
    'Manorville',
    'Marathon',
    'Marcellus',
    'Marcy',
    'Margaretville',
    'Marion',
    'Marlboro',
    'Maspeth',
    'Massapequa',
    'Massena',
    'Mastic',
    'Mastic Beach',
    'Mattituck',
    'Mattydale',
    'Maybrook',
    'Mayville',
    'Maywood, Albany',
    'McConnellsville',
    'Mcdonough',
    'Mcgraw',
    'Mechanicville',
    'Medford',
    'Medina',
    'Mellenville',
    'Melrose',
    'Melville',
    'Menands',
    'Mendon',
    'Meredith',
    'Merrick',
    'Middle Falls',
    'Middle Granville',
    'Middle Grove',
    'Middle Island',
    'Middle Village',
    'Middleport',
    'Middletown',
    'Milan',
    'Milford',
    'Millbrook',
    'Miller Place',
    'Millerton',
    'Millwood',
    'Milton',
    'Mineola',
    'Mineville',
    'Mohawk',
    'Mohegan Lake',
    'Monroe',
    'Monsey',
    'Montauk',
    'Montebello',
    'Montgomery',
    'Monticello',
    'Montour Falls',
    'Mooers',
    'Mooers Forks',
    'Moores Mill',
    'Moravia',
    'Moriches',
    'Morristown',
    'Morrisville',
    'Mount Kisco',
    'Mount Morris',
    'Mount Sinai',
    'Mount Upton',
    'Mount Vernon',
    'Mountainville',
    'Munnsville',
    'Nanuet',
    'Napanoch',
    'Naples',
    'Narrowsburg',
    'Nassau',
    'Natural Bridge',
    'Nesconset',
    'New Berlin',
    'New City',
    'New Connecticut',
    'New Hampton',
    'New Hartford',
    'New Hyde Park',
    'New Kingston',
    'New Paltz',
    'New Rochelle',
    'New Windsor',
    'New York',
    'New York Mills',
    'Newark',
    'Newark Valley',
    'Newburgh',
    'Newfane',
    'Newfield',
    'Newton Falls',
    'Newtonville',
    'Niagara Falls',
    'Nichols',
    'Nichols, Tioga',
    'Niskayuna',
    'Norfolk',
    'North',
    'North Babylon',
    'North Bay Shore',
    'North Bellmore',
    'North Blenheim',
    'North Chili',
    'North Collins',
    'North Creek',
    'North Haven',
    'North Hoosick',
    'North Java',
    'North Lawrence',
    'North Lindenhurst',
    'North Merrick',
    'North River',
    'North Rose',
    'North Salem',
    'North Syracuse',
    'North Tonawanda',
    'North White Plains',
    'Northport',
    'Northville (Riverhead)',
    'Norwich',
    'Norwood',
    'Nunda',
    'Nyack',
    'Oak Hill',
    'Oakdale',
    'Oakfield',
    'Oakland Gardens',
    'Oceanside',
    'Odessa',
    'Ogdensburg',
    'Old Bethpage',
    'Old Brookville',
    'Old Chatham',
    'Old Westbury',
    'Olean',
    'Oneida',
    'Oneonta',
    'Onondaga',
    'Ontario',
    'Orangeburg',
    'Orchard Park',
    'Oriskany',
    'Oriskany Falls',
    'Ossining',
    'Oswego',
    'Otego',
    'Otisville',
    'Ovid',
    'Owego',
    'Oxford',
    'Oyster Bay',
    'Ozone Park/Queens/New York',
    'Painted Post',
    'Palatine Bridge',
    'Palenville',
    'Palisades',
    'Palmyra',
    'Parish',
    'Patchogue',
    'Patterson',
    'Pavilion',
    'Pawling',
    'Pearl River',
    'Pecksville',
    'Peconic',
    'Peekskill',
    'Pelham',
    'Pelham Manor',
    'Penfield',
    'Penn Yan',
    'Perry',
    'Peru',
    'Petersburg',
    'Phelps',
    'Philadelphia',
    'Phillipsburg',
    'Philmont',
    'Phoenicia',
    'Phoenix',
    'Piermont',
    'Piffard',
    'Pine Bush',
    'Pine City',
    'Pine Island',
    'Pine Plains',
    'Pine Valley',
    'Pittsfield',
    'Pittsford',
    'Pittstown',
    'Plainfield Center',
    'Plainview',
    'Plainville',
    'Plandome',
    'Plattekill',
    'Plattsburgh',
    'Pleasant Valley',
    'Pleasantville',
    'Poestenkill',
    'Poland',
    'Pomona',
    'Port Byron',
    'Port Chester',
    'Port Ewen',
    'Port Ivory/Staten Isl/New York',
    'Port Jefferson',
    'Port Jervis',
    'Port Morris, Bronx',
    'Port Washington',
    'Portageville',
    'Porter Corners',
    'Portville',
    'Potsdam',
    'Poughkeepsie',
    'Poughquag',
    'Pound Ridge',
    'Prattsburg',
    'Prattsville',
    'Preble',
    'Pulaski',
    'Pultneyville',
    'Purchase',
    'Purling',
    'Queens Village',
    'Queens/New York',
    'Queensbury',
    'Quogue',
    'Ramapo',
    'Randolph',
    'Ransomville',
    'Ravena',
    'Red Creek',
    'Red Hook',
    'Rego Park, Queens',
    'Rensselaer',
    'Retsof',
    'Rexford',
    'Rhinebeck',
    'Rhode Island',
    'Richfield Springs',
    'Richland',
    'Richmond',
    'Richmond Hill/Queens/New York',
    'Richmondville',
    'Ridge',
    'Ridgewood',
    'Rifton',
    'Riparius',
    'Ripley',
    'Riverdale',
    'Riverhead',
    'Rochester',
    'Rock City Falls',
    'Rock Hill',
    'Rock Tavern',
    'Rockaway Beach',
    'Rockaway Parks',
    'Rockland',
    'Rockville Centre',
    'Rome',
    'Romulus',
    'Ronkonkoma',
    'Rooseveltown',
    'Roscoe',
    'Rosedale',
    'Rosendale',
    'Roseton',
    'Roslyn',
    'Roslyn Heights',
    'Rotterdam',
    'Rotterdam Junction',
    'Round Lake',
    'Rouses Point',
    'Rush',
    'Rye',
    'Rye Brook',
    'Sag Harbor',
    'Sagaponack',
    'Saint Albans',
    'Saint James',
    'Saint Johnsville',
    'Salamanca',
    'Salem',
    'Salisbury Mills',
    'Salt Point',
    'Sanborn',
    'Sands Point',
    'Sandy Creek',
    'Sanford',
    'Sangerfield',
    'Saranac Lake',
    'Saratoga Springs',
    'Sardinia',
    'Saugerties',
    'Savannah',
    'Sayville',
    'Scarsdale',
    'Schaghticoke',
    'Schenectady',
    'Schenevus',
    'Schodack Landing',
    'Schoharie',
    'Schuylerville',
    'Scipio Center',
    'Scotia',
    'Scottsville',
    'Sea Cliff',
    'Seaford',
    'Selden',
    'Selkirk',
    'Seneca',
    'Seneca Falls',
    'Sharon Springs',
    'Shelter Island',
    'Shelter Island Heights',
    'Sherburne',
    'Sheridan',
    'Sherman',
    'Sherrill',
    'Shirley',
    'Shokan',
    'Shoreham',
    'Shortsville',
    'Shroon',
    'Shrub Oak',
    'Sidney',
    'Silver Creek',
    'Silver Springs',
    'Skaneateles',
    'Skaneateles Falls',
    'Slate Hill',
    'Sleepy Hollow',
    'Sleepy Hollow Manor',
    'Slingerlands',
    'Sloatsburg',
    'Smithtown',
    'Smyrna',
    'Snyder',
    'Sodus',
    'Sodus Point',
    'Solvay',
    'Somers',
    'South Albany',
    'South Bethlehem',
    'South Dayton',
    'South Fallsburg',
    'South Glens Falls',
    'South Jamesport',
    'South Ozone Park',
    'South Plymouth',
    'South Salem',
    'South Wales',
    'South Windsor',
    'Southampton',
    'Southfields',
    'Southold',
    'Sparrow Bush',
    'Spencer',
    'Spencerport',
    'Spring Valley, Rockland',
    'Springfield Gardens',
    'Springville',
    'Springville, Suffolk',
    'Staatsburg',
    'Stafford',
    'Stamford',
    'Stanfordville',
    'Stanley',
    'Staten Island',
    'Stephentown',
    'Stillwater',
    'Stittville',
    'Stone Ridge, Ulster',
    'Stony Brook',
    'Stony Point',
    'Stony Point, Rockland',
    'Stormville',
    'Stow',
    'Stratford',
    'Strykersville',
    'Suffern',
    'Sugar Loaf',
    'Sunnyside, Queens',
    'Swan Lake',
    'Syosset',
    'Syracuse',
    'Tallman',
    'Tannersville',
    'Tappan',
    'Tarrytown',
    'Theresa',
    'Thompsonville',
    'Thornwood',
    'Ticonderoga',
    'Tillson',
    'Tivoli',
    'Tomkins Cove',
    'Tonawanda',
    'Tottenville, Staten Island',
    'Trout Creek',
    'Trout River',
    'Troy',
    'Trumansburg',
    'Tuckahoe',
    'Tully',
    'Tupper Lake',
    'Tuscan',
    'Tuxedo Park',
    'Ulster Park',
    'Unadilla',
    'Union Springs',
    'Uniondale',
    'Upper Brookville',
    'Upper Jay',
    'Upper Saint Regis',
    'Upton',
    'Utica',
    'Vailsgate',
    'Valatie',
    'Valhalla',
    'Valley Cottage',
    'Valley Falls',
    'Valley Stream',
    'Van Buren',
    'Van Etten',
    'Vandalia',
    'Vernon',
    'Vernon Center',
    'Vestal',
    'Victor',
    'Voorheesville',
    'Waddington',
    'Wading River',
    'Wainscott',
    'Walden',
    'Wales',
    'Wales Center',
    'Wallkill',
    'Walton',
    'Wampsville',
    'Wantagh',
    'Wappingers Falls',
    'Warners',
    'Warren',
    'Warrensburg',
    'Warsaw',
    'Warwick',
    'Washingtonville',
    'Wassaic',
    'Water Mill',
    'Waterford',
    'Waterloo',
    'Waterport',
    'Watertown',
    'Waterville',
    'Watervliet',
    'Watkins Glen',
    'Waverly',
    'Wayland',
    'Wayne',
    'Webster',
    'Weedsport',
    'Wellsville',
    'West Babylon',
    'West Chazy',
    'West Coxsackie',
    'West Harrison',
    'West Haverstraw',
    'West Hempstead',
    'West Henrietta',
    'West Hurley',
    'West Islip',
    'West Nyack',
    'West Point',
    'West Sayville',
    'West Seneca',
    'West Winfield',
    'Westbury',
    'Westchester County Apt',
    'Westerlo',
    'Westernville',
    'Westfield',
    'Westhampton',
    'Westhampton Beach',
    'Westmoreland',
    'Weston Mills',
    'Westtown',
    'Wheatfield',
    'White Lake',
    'White Plains',
    'Whitehall',
    'Whitesboro',
    'Whitestone/Queens/New York',
    'Williamsburg/Brooklyn/New York',
    'Williamson',
    'Williamstown',
    'Williamsville',
    'Willianstown',
    'Williston Park',
    'Willsboro',
    'Wilmington',
    'Wilson',
    'Wilton',
    'Windsor',
    'Wingdale',
    'Wolcott',
    'Woodbourne',
    'Woodbury',
    'Woodbury, Nassau',
    'Woodgate',
    'Woodhaven',
    'Woodhull',
    'Woodmere',
    'Woodridge',
    'Woods Mill',
    'Woodside/Queens/New York',
    'Woodstock',
    'Worcester',
    'Wurtsboro',
    'Wyandanch',
    'Wynantskill',
    'Wyoming',
    'Yaphank',
    'Yonkers',
    'York',
    'Yorktown Heights',
    'Yorkville',
    'Youngstown',
    'The Bronx',
    'Cortlandt',
    'Eldred',
    'Coeymans Hollow'
  ],
  OH: [
    'Aberdeen',
    'Ada',
    'Addison',
    'Addyston',
    'Adrian',
    'Akron',
    'Albany',
    'Alexandria',
    'Alger',
    'Alledonia',
    'Alliance',
    'Alpha',
    'Alvada',
    'Alvordton',
    'Amelia',
    'Amherst',
    'Amlin',
    'Anderson',
    'Andover',
    'Anna',
    'Ansonia',
    'Antwerp',
    'Apple Creek',
    'Arcanum',
    'Archbold',
    'Ashland',
    'Ashtabula',
    'Ashville',
    'Athens',
    'Attica',
    'Augusta',
    'Aurora',
    'Austinburg',
    'Austintown',
    'Avon',
    'Avon Lake',
    'Bainbridge',
    'Baker City',
    'Bakersville',
    'Baltic',
    'Baltimore',
    'Barberton',
    'Barlow',
    'Barnesville',
    'Barton',
    'Bascom',
    'Batavia',
    'Bay Village',
    'Beach City',
    'Beachwood',
    'Beaver',
    'Beaver Dam',
    'Beavercreek',
    'Bedford',
    'Bedford Heights',
    'Bellaire',
    'Bellbrook',
    'Belle Center',
    'Bellefontaine',
    'Bellevue',
    'Bellville',
    'Belmont',
    'Beloit',
    'Belpre',
    'Berea',
    'Bergholz',
    'Berlin Heights',
    'Berlin, Holmes',
    'Bethel, Clermont',
    'Bettsville',
    'Beverly',
    'Bidwell',
    'Big Prairie',
    'Birmingham',
    'Blacklick',
    'Blakeslee',
    'Blanchester',
    'Blissfield',
    'Bloomingburg',
    'Bloomingdale',
    'Blue Ash',
    'Bluffton',
    'Boardman',
    'Bolivar',
    'Boston Heights',
    'Botkins',
    'Bowerston',
    'Bowling Green',
    'Bradner',
    'Bratenahl',
    'Brecksville',
    'Bremen',
    'Brewster',
    'Bridgeport',
    'Brilliant',
    'Brimfield',
    'Broadview Heights',
    'Broadview, Summit',
    'Brook Park',
    'Brookfield',
    'Brooklyn',
    'Brooklyn Heights',
    'Brookville',
    'Brunswick',
    'Bryan',
    'Buckeye Lake',
    'Bucyrus',
    'Buffalo',
    'Burbank',
    'Burton',
    'Byesville',
    'Cadiz',
    'Caldwell',
    'Caledonia',
    'Cambridge',
    'Camden',
    'Camp Dennison',
    'Canal Fulton',
    'Canal Winchester',
    'Canfield',
    'Canton',
    'Carbondale',
    'Cardington',
    'Carey',
    'Carlisle',
    'Carroll',
    'Carrollton',
    'Casstown',
    'Castalia',
    'Cedarville',
    'Celina',
    'Centerburg',
    'Centerville',
    'Chagrin Falls',
    'Chandlersville',
    'Chardon',
    'Charm',
    'Chattanooga',
    'Chesapeake',
    'Cheshire',
    'Chester',
    'Chesterland',
    'Chillicothe',
    'Cincinnati',
    'Circleville',
    'Clarington',
    'Clarksville',
    'Claves',
    'Clayton',
    'Cleveland',
    'Cleveland Heights',
    'Cleves',
    'Clinton',
    'Cloverdale',
    'Clyde',
    'Coalton',
    'Coldwater',
    'College Corner',
    'Columbia',
    'Columbia Station',
    'Columbiana',
    'Columbus',
    'Columbus Grove',
    'Concord, Lake',
    'Conesville',
    'Conneaut',
    'Continental',
    'Convoy',
    'Copley',
    'Cortland',
    'Coshocton',
    'Covington',
    'Crestline',
    'Creston',
    'Crooksville',
    'Croton',
    'Curtice',
    'Custar',
    'Cuyahoga Falls',
    'Cuyahoga Heights',
    'Cynthiana',
    'Dalton',
    'Danville',
    'Dayton',
    'Decatur',
    'Deer Park',
    'Deerfield',
    'Defiance',
    'Delaware',
    'Delhi',
    'Dellroy',
    'Delphos',
    'Delta',
    'Dennison',
    'Deshler',
    'Dexter',
    'Dexter City',
    'Dover',
    'Dublin',
    'Dunbridge',
    'Duncan Falls',
    'Dundee',
    'Dunkirk',
    'East Canton',
    'East Fairfield',
    'East Lake',
    'East Liberty',
    'East Liverpool',
    'East Palestine',
    'East Sparta',
    'East Springfield',
    'Easton',
    'Eaton',
    'Edgerton',
    'Edgewater Beach',
    'Edon',
    'Eldorado',
    'Elida',
    'Elkton',
    'Elmore',
    'Elyria',
    'Englewood',
    'Enon',
    'Etna',
    'Euclid',
    'Evendale',
    'Fairborn',
    'Fairfax, Hamilton',
    'Fairfield',
    'Fairlawn',
    'Fairport Harbor Village',
    'Fairview Park',
    'Farmer',
    'Farmersville',
    'Fayette',
    'Fayetteville',
    'Fernald',
    'Findlay',
    'Flat Rock',
    'Fleming',
    'Fletcher',
    'Forest Park',
    'Forest, Hardin',
    'Fort Loramie',
    'Fort Recovery',
    'Fostoria',
    'Frankfort',
    'Franklin',
    'Franklin Furnace',
    'Frazeysburg',
    'Fredericksburg',
    'Fredericktown',
    'Freeport',
    'Fremont',
    'Fresno',
    'Gahanna',
    'Galena',
    'Galion',
    'Gallipolis',
    'Galloway',
    'Gambier',
    'Garfield Heights',
    'Garrettsville',
    'Geneva',
    'Genoa, Ottawa',
    'Georgetown',
    'Georgetown, Vermilion',
    'Germantown',
    'Gettysburg',
    'Gibsonburg',
    'Girard',
    'Glendale',
    'Glenford',
    'Glenmont',
    'Glenwillow',
    'Glouster',
    'Gnadenhutten',
    'Goshen',
    'Grafton',
    'Grand Rapids',
    'Grand River',
    'Grandview',
    'Grandview Heights',
    'Granville',
    'Green',
    'Green Springs',
    'Greenfield',
    'Greenville',
    'Greenwich',
    'Greenwood, Guernsey',
    'Grove City',
    'Groveport',
    'Gypsum',
    'Hamersville',
    'Hamilton',
    'Hammondsville',
    'Hanging Rock',
    'Hannibal',
    'Hanoverton',
    'Harpersfield',
    'Harpster',
    'Harrison',
    'Harrisville',
    'Harrod',
    'Hartford',
    'Hartville',
    'Harveysburg',
    'Haskins',
    'Haverhill',
    'Haviland',
    'Haydenville',
    'Haynesville',
    'Heath',
    'Hebron',
    'Hennessy',
    'Hicksville',
    'Highland Heights',
    'Highpoint',
    'Hilliard',
    'Hillsboro, Ohio',
    'Hinckley',
    'Hiram',
    'Holgate',
    'Holiday City',
    'Holland',
    'Holmesville',
    'Homer',
    'Homeworth',
    'Hooven',
    'Horton',
    'Houston',
    'Hubbard',
    'Huber Heights',
    'Hudson',
    'Huntsville',
    'Huron',
    'Iberia',
    'Independence',
    'Irondale',
    'Ironton',
    'Ivorydale',
    'Jackson',
    'Jackson Center',
    'Jacksontown',
    'Jamestown',
    'Jefferson',
    'Jeffersonville',
    'Jeromesville',
    'Jewell',
    'Johnston',
    'Johnstown',
    'Junction City, Perry',
    'Kalida',
    'Kensington',
    'Kent',
    'Kenton',
    'Kerr',
    'Kettering',
    'Kettlersville',
    'Kidron',
    'Killbuck',
    'Kingman',
    'Kings Mills',
    'Kingston',
    'Kinsman, Belmont',
    'Kinsman, Trumbull',
    'Kirby',
    'Kirtland',
    'Kitts Hill',
    'La Rue',
    'Lafayette',
    'Lafferty',
    'Lagrange',
    'Lakeside',
    'Lakeside Marblehead',
    'Lakewood',
    'Lancaster',
    'Langsville',
    'Lansing',
    'Laurelville',
    'Leavittsburg',
    'Lebanon',
    'Leesburg',
    'Leetonia',
    'Leipsic',
    'Lewis Center',
    'Lewisburg',
    'Lexington',
    'Liberty',
    'Liberty Township',
    'Lima',
    'Linndale',
    'Lisbon',
    'Little Hocking',
    'Lockbourne',
    'Lockland',
    'Lodi',
    'Logan',
    'London',
    'Lorain',
    'Lordstown',
    'Loudonville',
    'Louisville',
    'Loveland',
    'Lowell',
    'Lowellville',
    'Lucasville',
    'Luckey',
    'Lyndhurst',
    'Lyons',
    'Macedonia',
    'Madeira',
    'Madison',
    'Magnolia',
    'Maineville',
    'Malinta',
    'Malta',
    'Malvern',
    'Manchester',
    'Mansfield',
    'Mantua',
    'Maple Grove',
    'Maple Heights',
    'Marblehead',
    'Marengo',
    'Maria Stein',
    'Mariemont',
    'Marietta',
    'Marion',
    'Marseilles',
    'Martel',
    'Martins Ferry',
    'Martinsburg',
    'Marysville',
    'Mason',
    'Massillon',
    'Masury',
    'Maumee',
    'Mayfield',
    'Mayfield Heights',
    'Mayfield, Butler',
    'Mayfield, Cuyahoga',
    'McArthur',
    'McClure',
    'McCombs',
    'McConnelsville',
    'McDermott',
    'McDonald, Muskingum',
    'McDonald, Trumbull',
    'Medina',
    'Medway',
    'Mentor',
    'Mesopotamia',
    'Metamora',
    'Miamisburg',
    'Miamitown',
    'Miamiville',
    'Middle Point',
    'Middlebranch',
    'Middleburg Heights',
    'Middleburg, Logan',
    'Middlefield',
    'Middleport',
    'Middletown',
    'Midland',
    'Midvale',
    'Milan',
    'Milford',
    'Milford Center',
    'Millbury',
    'Millersburg',
    'Millersport',
    'Millersville',
    'Mineral City',
    'Mineral Ridge',
    'Minerva',
    'Mingo Junction',
    'Minster',
    'Mogadore',
    'Monclova',
    'Monroe',
    'Monroeville',
    'Montgomery',
    'Montpelier',
    'Montreal',
    'Moraine',
    'Moreland Hills',
    'Morral',
    'Morristown',
    'Morrow',
    'Mount Eaton',
    'Mount Gilead',
    'Mount Hope',
    'Mount Orab',
    'Mount Perry',
    'Mount Sterling',
    'Mount Vernon',
    'Munroe Falls',
    'Napoleon',
    'Nashport',
    'Nashville',
    'Navarre',
    'Negley',
    'Nelsonville',
    'New Albany',
    'New Bloomington',
    'New Bremen',
    'New Carlisle',
    'New Concord',
    'New Hampshire',
    'New Holland',
    'New Knoxville',
    'New Lebanon',
    'New Lexington',
    'New London',
    'New Marshfield',
    'New Miami',
    'New Middletown',
    'New Paris',
    'New Philadelphia',
    'New Richmond',
    'New Vienna',
    'New Washington',
    'New Waterford',
    'New Weston',
    'Newark',
    'Newburg',
    'Newburgh Heights',
    'Newbury',
    'Newcomerstown',
    'Newkirk',
    'Newport',
    'Newton Falls',
    'Newtown',
    'Niles',
    'North Baltimore',
    'North Bend',
    'North Bloomfield',
    'North Canton',
    'North College Hill',
    'North Jackson',
    'North Kingsville',
    'North Lawrence',
    'North Lewisburg',
    'North Liberty',
    'North Lima',
    'North Olmsted',
    'North Randall',
    'North Ridgeville',
    'North Royalton',
    'Northfield',
    'Northwood, Logan',
    'Northwood, Wood',
    'Norton, Summit',
    'Norwalk',
    'Norwood',
    'Nova',
    'Novelty',
    'Oak Harbor',
    'Oak Hill',
    'Oakwood',
    'Oakwood Village',
    'Oakwood, Paulding',
    'Oberlin',
    'Obetz',
    'Ohio City',
    'Okeana',
    'Okolona',
    'Old Fort',
    'Olmsted Falls',
    'Ontario',
    'Orange',
    'Oregon',
    'Orrville',
    'Orwell',
    'Ostrander',
    'Ottawa',
    'Ottoville',
    'Overpeck',
    'Oxford',
    'Painesville',
    'Pandora',
    'Parkman',
    'Parma',
    'Pataskala',
    'Patriot',
    'Paulding',
    'Payne',
    'Peebles',
    'Pemberville',
    'Peninsula',
    'Peoria',
    'Pepper Pike',
    'Perry',
    'Perrysburg',
    'Perrysville',
    'Pettisville',
    'Philo',
    'Pickerington',
    'Piketon',
    'Pioneer',
    'Piqua',
    'Pitsburg',
    'Plain City',
    'Plainfield',
    'Plainview',
    'Pleasant City',
    'Pleasant Plain',
    'Plymouth',
    'Poland',
    'Pomeroy',
    'Port Clinton',
    'Port William',
    'Portage',
    'Portland',
    'Portsmouth',
    'Pottersburg',
    'Powell',
    'Princeton',
    'Proctorville',
    'Prospect',
    'Put-In-Bay',
    'Quaker City',
    'Racine',
    'Rahway',
    'Randolph',
    'Ravenna',
    'Ray',
    'Raymond',
    'Reading',
    'Reno',
    'Reynoldsburg',
    'Richfield',
    'Richland',
    'Richmond',
    'Richmond Dale',
    'Richmond Heights',
    'Richwood',
    'Rickenbacker International Apt',
    'Ridgeville Corners',
    'Rimer',
    'Rio Grande',
    'Rittman',
    'Riverside',
    'Rock Creek',
    'Rockford',
    'Rocky River',
    'Rogers',
    'Rootstown',
    'Roseville',
    'Ross',
    'Rossburg',
    'Rossford',
    'Russells Point',
    'Russia',
    'Sabina',
    'Sagamore Hills',
    'Saint Bernard',
    'Saint Clairsville',
    'Saint Henry',
    'Saint James',
    'Saint Louisville',
    'Saint Marys',
    'Saint Paris',
    'Salem',
    'Salesville',
    'Sandusky, Erie',
    'Savannah',
    'Saybrook',
    'Sciotoville',
    'Sebring',
    'Senecaville',
    'Seville',
    'Shadyside',
    'Shaker Heights',
    'Shandon',
    'Sharon Center',
    'Sharonville',
    'Sheffield',
    'Sheffield Lake',
    'Sheffield Village',
    'Shelby',
    'Shiloh',
    'Shreve',
    'Sidney',
    'Silverton',
    'Smithfield',
    'Smithville, Wayne',
    'Solon',
    'Somerville',
    'South Charleston',
    'South Euclid',
    'South Lebanon',
    'South Point',
    'South Solon',
    'South Vienna',
    'South Webster',
    'Spencer',
    'Spencerville',
    'Springboro',
    'Springdale',
    'Springfield',
    'Springhills',
    'Sterling',
    'Steubenville',
    'Stillwater',
    'Stony Ridge',
    'Stow',
    'Strasburg',
    'Stratton',
    'Streetsboro',
    'Strongsville',
    'Struthers',
    'Stryker',
    'Suffield',
    'Sugar Creek',
    'Sugarcreek',
    'Sullivan',
    'Summitville',
    'Sunbury',
    'Swanton',
    'Sycamore',
    'Sylvania',
    'Syracuse',
    'Tallmadge',
    'Thompson',
    'Thornville',
    'Tiffin',
    'Tiltonsville',
    'Tipp City',
    'Toledo',
    'Toronto',
    'Trenton',
    'Trotwood',
    'Troy',
    'Twinsburg',
    'Uhrichsville',
    'Uniontown',
    'Uniopolis',
    'University Heights',
    'Upper Arlington',
    'Upper Sandusky',
    'Urbana',
    'Urbancrest',
    'Utica',
    'Valley City',
    'Valley View',
    'Van Buren',
    'Van Wert',
    'Vandalia',
    'Vermilion',
    'Versailles',
    'Vienna',
    'Wadsworth',
    'Wakeman',
    'Walbridge',
    'Waldo',
    'Walhonding',
    'Walnut Creek',
    'Walnut Hills',
    'Walton Hills',
    'Wapakoneta',
    'Warren',
    'Warrensville Heights',
    'Warrenton',
    'Warsaw',
    'Washington Court House',
    'Waterford',
    'Waterloo',
    'Waterville',
    'Wauseon',
    'Waverly',
    'Waynesburg',
    'Waynesfield',
    'Waynesville',
    'Wellington',
    'Wellston',
    'Wellsville',
    'West Alexandria',
    'West Carrollton',
    'West Chester',
    'West Farmington',
    'West Jefferson, Madison',
    'West Jefferson, Williams',
    'West Lafayette',
    'West Liberty',
    'West Milton',
    'West Portsmouth',
    'West Salem',
    'West Union',
    'West Unity',
    'Westchester',
    'Westerville',
    'Westlake',
    'Weston',
    'Whipple',
    'Whitehall',
    'Whitehouse',
    'Wickliffe',
    'Wilkesville',
    'Willard',
    'Williamsburg',
    'Willoughby',
    'Willow Wood',
    'Wilmington',
    'Winchester',
    'Windham',
    'Winesburg',
    'Wintersville',
    'Woodlawn',
    'Woodmere',
    'Woodsfield',
    'Woodville',
    'Wooster',
    'Worthington',
    'Wright-Patterson',
    'Xenia',
    'Yellow Springs',
    'Yorkshire',
    'Youngstown',
    'Zanesfield',
    'Zanesville',
    ''
  ],
  OK: [
    'Ada',
    'Adair',
    'Afton',
    'Allen',
    'Altus',
    'Alva',
    'Ames',
    'Anadarko',
    'Antlers',
    'Apache',
    'Arapaho',
    'Ardmore',
    'Arkoma',
    'Arnett',
    'Atoka',
    'Avard',
    'Balko',
    'Barnsdall',
    'Bartlesville',
    'Beaver',
    'Beggs',
    'Bessie',
    'Big Cabin',
    'Bixby',
    'Blackwell',
    'Blanchard',
    'Blocker',
    'Boise City',
    'Boswell',
    'Brackenridge',
    'Braman',
    'Bristow',
    'Broken Arrow',
    'Broken Bow',
    'Buffalo',
    'Burlington',
    'Cache',
    'Calumet',
    'Cameron',
    'Canton',
    'Carmen',
    'Carnegie',
    'Carrier',
    'Carter',
    'Castle',
    'Catoosa',
    'Cement',
    'Chandler',
    'Chattanooga',
    'Checotah',
    'Chelsea',
    'Cherokee',
    'Cheyenne',
    'Chickasha',
    'Choctaw',
    'Chouteau',
    'Claremore',
    'Clarita',
    'Cleveland',
    'Clinton',
    'Coalgate',
    'Colcord',
    'Coleman',
    'Collinsville',
    'Comanche',
    'Commerce',
    'Copan',
    'Cushing',
    'Davis',
    'Del City',
    'Dentonville',
    'Dewey',
    'Dill City',
    'Dover',
    'Drumright',
    'Duke',
    'Duncan',
    'Durant',
    'Eakly',
    'Edmond',
    'El Reno',
    'Elk City',
    'Enid',
    'Eufaula',
    'Fairland',
    'Fairview',
    'Fanshawe',
    'Fargo',
    'Felt',
    'Fletcher',
    'Forgan',
    'Fort Gibson',
    'Fort Sill',
    'Frederick',
    'Gage',
    'Glenpool',
    'Granite',
    'Grove',
    'Guthrie',
    'Guymon',
    'Haileyville',
    'Hammon',
    'Harrah',
    'Hartshorne',
    'Haskell',
    'Healdton',
    'Heavener',
    'Helena',
    'Hennessey',
    'Henryetta',
    'Hinton',
    'Hobart',
    'Holdenville',
    'Hooker',
    'Howe',
    'Hugo',
    'Hulbert',
    'Idabel',
    'Indiahoma',
    'Inola',
    'Jay',
    'Jenks',
    'Jones',
    'Kansas',
    'Kellyville',
    'Keota',
    'Ketchum',
    'Keyes',
    'Kiefer',
    'Kingfisher',
    'Kiowa',
    'Konawa',
    'Krebs',
    'Kremlin',
    'Lahoma',
    'Laverne',
    'Lawton',
    'Lebanon',
    'Leedey',
    'Lehigh',
    'Lindsay',
    'Locust Grove',
    'Luther',
    'Macomb',
    'Madill',
    'Manchester',
    'Mannford',
    'Marble City',
    'Marietta',
    'Marlow',
    'Marshall',
    'Maxwell',
    'Maysville',
    'McAlester',
    'Mead',
    'Medford',
    'Meeker',
    'Meno',
    'Miami',
    'Midwest City',
    'Milburn',
    'Mill Creek',
    'Minco',
    'Moffett',
    'Moore',
    'Mooreland',
    'Mounds',
    'Muldrow',
    'Muskogee',
    'Mustang',
    'Newcastle',
    'Newkirk',
    'Nichols Hills',
    'Ninnekah',
    'Noble',
    'Norman',
    'Nowata',
    'Ochelata',
    'Oilton',
    'Okarche',
    'Okeene',
    'Okemah',
    'Oklahoma City',
    'Okmulgee',
    'Oologah',
    'Owasso',
    'Panama',
    'Paoli',
    'Park Hill',
    'Pauls Valley',
    'Pawhuska',
    'Pawnee',
    'Perkins',
    'Perry',
    'Picher',
    'Pocola',
    'Ponca City',
    'Poteau',
    'Prague',
    'Pryor',
    'Purcell',
    'Quapaw',
    'Quinton',
    'Ramona',
    'Ratliff City',
    'Rattan',
    'Red Rock',
    'Ringold',
    'Ringwood',
    'Roff',
    'Roland',
    'Rome',
    'Rosston',
    'Rush Springs',
    'Ryan',
    'Saint Louis',
    'Sallisaw',
    'Sand Springs',
    'Sapulpa',
    'Sawyer',
    'Sayre',
    'Seiling',
    'Seminole',
    'Shady Point',
    'Shangri-la',
    'Shattuck',
    'Shawnee',
    'Shidler',
    'Skiatook',
    'Snyder',
    'Southard',
    'Sperry',
    'Stigler',
    'Stillwater',
    'Stilwell',
    'Stratford',
    'Stringtown',
    'Stroud',
    'Sulphur',
    'Sweetwater',
    'Tahlequah',
    'Talihina',
    'Tecumseh',
    'Texhoma',
    'Thackerville',
    'Thomas',
    'Tinker Air Force Base',
    'Tishomingo',
    'Tonkawa',
    'Tulsa',
    'Tuttle',
    'Valliant',
    'Velma',
    'Vici',
    'Vinita',
    'Wagoner',
    'Walters',
    'Wanette',
    'Watonga',
    'Waurika',
    'Wayne',
    'Waynoka',
    'Weatherford',
    'Welch',
    'Westville',
    'Wewoka',
    'Wilburton',
    'Willow',
    'Wister',
    'Woodward',
    'Wynnewood',
    'Yale',
    'Yukon'
  ],
  OR: [
    'Adams',
    'Adrian',
    'Albany',
    'Albany-Corvallis Apt',
    'Aloha',
    'Amity',
    'Applegate',
    'Arlington',
    'Ashland',
    'Astoria',
    'Athena',
    'Aumsville',
    'Aurora',
    'Baker',
    'Baker City',
    'Bandon',
    'Banks',
    'Bay City',
    'Beaver',
    'Beaver Creek',
    'Beaverton',
    'Bend',
    'Bend-Redmond Apt',
    'Bethany',
    'Blachly',
    'Boardman',
    'Bonanza',
    'Boring',
    'Bradwood',
    'Brightwood',
    'Brookings',
    'Brooks',
    'Brownsville',
    'Burlington',
    'Burns',
    'Camas Valley',
    'Canby',
    'Cannon Beach',
    'Canyonville',
    'Cape Blanco',
    'Carlton',
    'Carver',
    'Cascade Locks',
    'Cave Junction',
    'Cedar Hills',
    'Central Point',
    'Charleston',
    'Cheshire',
    'Chiloquin',
    'Christmas Valley',
    'Clackamas',
    'Clatskanie',
    'Cloverdale',
    'Coburg',
    'Colton',
    'Columbia City',
    'Coos Bay',
    'Coquille',
    'Cornelius',
    'Cornelius Pass',
    'Corvallis',
    'Corvallis-Albany Apt',
    'Cottage Grove',
    'Cove',
    'Crescent Lake',
    'Creswell',
    'Culver',
    'Dairy',
    'Dallas',
    'Damascus',
    'Days Creek',
    'Dayton',
    'Dayville',
    'Deer Island',
    'Donald',
    'Drain',
    'Dufur',
    'Dundee',
    'Durham',
    'Durkee',
    'Eagle Creek',
    'Eagle Point',
    'Elkton',
    'Enterprise',
    'Estacada',
    'Eugene',
    'Fairview',
    'Fall Creek',
    'Fields',
    'Florence',
    'Forest Grove',
    'Fort Klamath',
    'Fort Rock',
    'Gardiner',
    'Gaston',
    'Gervais',
    'Gladstone',
    'Glendale',
    'Gold Beach',
    'Gold Hill',
    'Government Camp',
    'Grand Ronde',
    'Grande Ronde',
    'Grants Pass',
    'Grass Valley',
    'Gresham',
    "Guild's Lake",
    'Halsey',
    'Hamburg',
    'Hammond',
    'Happy Valley',
    'Harbor',
    'Harrisburg',
    'Hermiston',
    'Hillsboro',
    'Hinkle',
    'Hood River',
    'Hubbard',
    'Idanha',
    'Imbler',
    'Independence',
    'Irrigon',
    'Jacksonville',
    'Jasper',
    'Jefferson',
    'Jewell',
    'John Day',
    'Johnson City',
    'Junction City',
    'Keizer',
    'Kellogg',
    'Keno',
    'Kent',
    'Kenton',
    'Kerby',
    'King City',
    'Klamath Falls',
    'La Grande',
    'Lafayette',
    'Lake Oswego',
    'Lakeview',
    'Lebanon',
    'Lexington',
    'Lincoln City',
    'Linnton',
    'Lowell',
    'Lyons',
    'Madison-Madras Apt',
    'Madras',
    'Madras-Madison Apt',
    'Malin',
    'Mapleton',
    'Maywood Park',
    'McMinnville',
    'McNary',
    'Medford',
    'Merrill',
    'Metolius',
    'Metzger',
    'Milton-Freewater',
    'Milwaukie',
    'Molalla',
    'Monmouth',
    'Monroe',
    'Moro',
    'Mosier',
    'Mount Angel',
    'Mulino',
    'Murphy',
    'Myrtle Creek',
    'Myrtle Point',
    'Newberg',
    'Newport',
    'North Bend',
    'North Plains',
    'North Powder',
    'Noti',
    'Nyssa',
    'Oak Grove',
    'Oakland',
    'Oakridge',
    'Odell',
    'Ontario',
    'Oregon City',
    'Pacific City',
    'Parkrose',
    'Pendleton',
    'Philomath',
    'Phoenix',
    'Pleasant Hill',
    'Port Orford',
    'Portland',
    'Prairie City',
    'Prescott',
    'Prineville',
    'Prospect',
    'Rainier',
    'Redmond',
    'Redmond-Bend Apt',
    'Richland',
    'Rickreall',
    'Riddle',
    'Rivergate',
    'Rockwood',
    'Rogue River',
    'Rome',
    'Roseburg',
    'Saginaw',
    'Saint Helens',
    'Saint Paul',
    'Salem',
    'Sandy',
    'Scappoose',
    'Scholls',
    'Scio',
    'Scott Mills',
    'Seal Rock',
    'Seaside',
    'Selma',
    'Shedd',
    'Sheridan',
    'Sherwood',
    'Shorewood',
    'Silverton',
    'Sisters',
    'Sixes',
    'South Beach',
    'Springfield',
    'Stanfield',
    'Stayton',
    'Sublimity',
    'Sun River',
    'Sutherlin',
    'Sweet Home',
    'Talent',
    'Tangent',
    'Terrebonne',
    'The Dalles',
    'Tigard',
    'Tillamook',
    'Toledo',
    'Troutdale',
    'Tualatin',
    'Tulsa',
    'Turner',
    'Umatilla',
    'Umpqua',
    'Vale',
    'Veneta',
    'Vernonia',
    'Walterville',
    'Warm Springs',
    'Warren',
    'Warrenton',
    'Wasco',
    'Wauna',
    'Wauna Range',
    'West Linn',
    'West Union',
    'Weston',
    'White City',
    'Willamette City',
    'Williams',
    'Wilsonville',
    'Winston',
    'Wood Village',
    'Woodburn',
    'Yachats',
    'Yamhill'
  ],
  PA: [
    'Aaronsburg',
    'Abbottstown',
    'Abington',
    'Adamstown',
    'Addison',
    'Adrian',
    'Akron',
    'Albion (Erie County)',
    'Albrightsville',
    'Alburtis',
    'Aldan',
    'Alexandria',
    'Aliquippa',
    'Allegheny',
    'Allegheny County Apt/Pittsburgh',
    'Allenport',
    'Allensville',
    'Allentown',
    'Allenwood',
    'Allison Park',
    'Altoona',
    'Alum Bank',
    'Ambler',
    'Ambridge',
    'Analomink',
    'Andreas',
    'Annville',
    'Apollo',
    'Archbald',
    'Ardmore',
    'Aristes',
    'Armagh',
    'Arnold',
    'Ashland',
    'Ashley',
    'Ashville',
    'Aspers',
    'Aston',
    'Atglen',
    'Athens',
    'Atlantic',
    'Atlasburg',
    'Auburn',
    'Audubon',
    'Aultman',
    'Avoca',
    'Avondale',
    'Avonmore',
    'Baden',
    'Bagdad',
    'Bainbridge',
    'Bakerstown',
    'Bala-Cynwyd',
    'Bally',
    'Bangor',
    'Barkeyville',
    'Barnesville',
    'Barree',
    'Barto',
    'Bartonsville',
    'Bath',
    'Beaver',
    'Beaver Falls',
    'Beavertown',
    'Bedford',
    'Bedminster',
    'Beech Creek',
    'Belden',
    'Belle Vernon',
    'Bellefonte',
    'Bellefonte-Clearfield Apt',
    'Belleville',
    'Bellevue',
    'Bellwood',
    'Belmont',
    'Bensalem',
    'Bentleyville',
    'Benton',
    'Berlin',
    'Bernville',
    'Berrysburg',
    'Berwick',
    'Berwyn',
    'Bessemer',
    'Bethany',
    'Bethayres',
    'Bethel',
    'Bethel Park',
    'Bethlehem',
    'Bigler',
    'Biglerville',
    'Bird in Hand',
    'Birdsboro',
    'Birmingham',
    'Blain',
    'Blairsville',
    'Blakeslee',
    'Blandon',
    'Blawnox',
    'Bloomsburg',
    'Blossburg',
    'Blue Ball',
    'Blue Bell',
    'Blue Ridge Summit',
    'Boothwyn',
    'Boston',
    'Boswell',
    'Bowmanstown',
    'Bowmansville',
    'Boyers',
    'Boyertown',
    'Brackenridge',
    'Braddock',
    'Bradford',
    'Brandonville',
    'Brave',
    'Breezewood',
    'Breinigsville',
    'Bridesburg',
    'Bridgeburg',
    'Bridgeport',
    'Bridgeville',
    'Brier Hill',
    'Brighton Heights',
    'Bristol',
    'Brockport',
    'Brockway',
    'Brogue',
    'Brookhaven',
    'Brookville',
    'Broomall',
    'Brownstown',
    'Brownsville',
    'Brush Valley',
    'Bryn Mawr',
    'Buena Vista',
    'Bulger',
    'Bunola',
    'Burgettstown',
    'Burley',
    'Burlington',
    'Burnham',
    'Burnside',
    'Bushkill',
    'Bustleton',
    'Butler',
    'Byberry',
    'Cabot',
    'California',
    'Callery',
    'Cambridge Springs',
    'Camp Hill',
    'Camphill',
    'Canonsburg',
    'Canton',
    'Carbondale',
    'Carlisle',
    'Carlton',
    'Carmichaels',
    'Carnegie',
    'Carrolltown',
    'Castanea',
    'Catasauqua',
    'Catawissa',
    'Cecil',
    'Center Valley',
    'Central',
    'Centre Hall',
    'Chadds Ford',
    'Chalfont',
    'Chambersburg',
    'Chambersville',
    'Champion',
    'Charleroi',
    'Charleston',
    'Chatham',
    'Chelsea',
    'Cherry Tree',
    'Cherryville',
    'Chester',
    'Chester Heights',
    'Chester Springs',
    'Chesterbrook',
    'Cheswick',
    'Chevy Chase Heights',
    'Childs',
    'Christiana',
    'Churchtown',
    'Clairton',
    'Clarence',
    'Clarendon Heights',
    'Clarion',
    'Clark',
    'Clarks Summit',
    'Clarksville',
    'Claysburg',
    'Clearfield',
    'Clearfield-Bellefonte Apt',
    'Clearville',
    'Cleona',
    'Clifford Township',
    'Clifton',
    'Clifton Heights',
    'Clinton',
    'Coal Center',
    'Coal Run, Northumberland',
    'Coal Township',
    'Coaldale',
    'Coaltown',
    'Coatesville',
    'Cochranton',
    'Cochranville',
    'Codorus',
    'Codorus Furnance',
    'Cogan Station',
    'Collegeville',
    'Collingdale',
    'Colmar',
    'Columbia',
    'Columbia Cross Roads',
    'Colver',
    'Colwyn',
    'Concordville',
    'Conestoga',
    'Confluence',
    'Conneautville',
    'Connellsville',
    'Conshohocken',
    'Conway',
    'Conyngham',
    'Coopersburg',
    'Coplay',
    'Coraopolis',
    'Cornwells Heights',
    'Corry',
    'Coudersport',
    'Courtdale',
    'Covington',
    'Covington Township',
    'Cowansville',
    'Crabtree',
    'Crafton',
    'Cranberry Township',
    'Creekside',
    'Creighton',
    'Crescent Township',
    'Cresco',
    'Cresson',
    'Cressona',
    'Croydon',
    'Crum Lynne',
    'Crystal Lake',
    'Cuddy',
    'Curryville',
    'Curwensville',
    'Dallas',
    'Dallastown',
    'Dalmatia',
    'Dalton',
    'Damascus',
    'Danboro',
    'Danielsville',
    'Danville',
    'Darby',
    'Darlington',
    'Dauberville',
    'Dauphin',
    'Davidsville',
    'Deer Lake, Schuylkill',
    'Deer Park',
    'Delano',
    'Delaware Water Gap',
    'Delmont',
    'Delta',
    'Denver',
    'Derry',
    'Devon',
    'Dickson City',
    'Dillsburg',
    'Dingmans Ferry',
    'Donegal',
    'Donora',
    'Douglassville',
    'Dover',
    'Downey',
    'Downingtown',
    'Doylestown',
    'Dravosburg',
    'Dresher',
    'Drums',
    'Du Bois',
    'Dublin',
    'Dubois',
    'Dunbar',
    'Duncansville',
    'Dunmore',
    'Dupont',
    'Duquesne',
    'Durham',
    'Duryea',
    'Dushore',
    'Eagle',
    'Eagles Mere',
    'Eagleville',
    'East Bangor',
    'East Berlin',
    'East Butler',
    'East Earl',
    'East Freedom',
    'East Goshen',
    'East Greenville',
    'East McKeesport',
    'East Norriton',
    'East Penn',
    'East Petersburg',
    'East Pittsburgh',
    'East Stroudsburg',
    'Easton',
    'Ebensburg',
    'Eddystone',
    'Edgemont',
    'Edinboro',
    'Edinburg',
    'Edwardsville',
    'Effort',
    'Eighty Four',
    'Eldorado',
    'Eldred',
    'Elizabethtown',
    'Elizabethville',
    'Elkins Park',
    'Elkland',
    'Ellport',
    'Ellsworth',
    'Ellwood City',
    'Elm',
    'Elton',
    'Elverson',
    'Elysburg',
    'Emigsville',
    'Emlenton',
    'Emmaus',
    'Emporium',
    'Emsworth',
    'Endeavor',
    'Enola',
    'Enon Valley',
    'Ephrata',
    'Erie',
    'Essington',
    'Etna',
    'Evans City',
    'Evansville',
    'Everett',
    'Everson',
    'Exeter',
    'Export',
    'Exton',
    'Eynon',
    'Fair Hill',
    'Fairchance',
    'Fairfield',
    'Fairless Hills',
    'Fairmont City',
    'Fairmount',
    'Fairview',
    'Falls',
    'Falls Creek',
    'Fallsington',
    'Farmers Valley',
    'Farmington',
    'Farmington Hill',
    'Farmington, Fayette',
    'Farrell',
    'Fawn Grove',
    'Fayetteville',
    'Feasterville',
    'Feasterville-Trevose',
    'Felton',
    'Finleyville',
    'Fishertown',
    'Fleetwood',
    'Flinton',
    'Floreffe',
    'Flourtown',
    'Fogelsville',
    'Folcroft',
    'Fombell',
    'Ford City',
    'Forest City',
    'Forksville',
    'Fort Hill',
    'Fort Indiantown',
    'Fort Littleton',
    'Fort Loudon',
    'Fort Washington',
    'Forty Fort',
    'Fountain Hill',
    'Foxcroft',
    'Frackville',
    'Franconia',
    'Frank',
    'Frankford',
    'Frankfort Springs',
    'Franklin',
    'Frazer',
    'Frederick',
    'Fredericksburg',
    'Fredericktown',
    'Fredonia',
    'Freedom',
    'Freeland',
    'Freeport',
    'Friedens',
    'Frisco',
    'Frostburg',
    'Furlong',
    'Galeton',
    'Galilee',
    'Gap',
    'Gardner',
    'Gardners',
    'Gayly',
    'Genesee',
    'Germansville',
    'Gettysburg',
    'Gibraltar',
    'Gibson',
    'Gibsonia',
    'Gilbertsville',
    'Gillett',
    'Girard',
    'Gladwyne',
    'Glassport',
    'Glen Hope',
    'Glen Mills',
    'Glen Rock',
    'Glenmoore',
    'Glenolden',
    'Glenshaw',
    'Glenside',
    'Goodville, Lancaster',
    'Gordon',
    'Gordonville',
    'Gouldsboro',
    'Gowen City',
    'Grampian',
    'Grand Valley',
    'Grantham',
    'Grantville',
    'Granville',
    'Graterford',
    'Gratz',
    'Greeley',
    'Green Lane',
    'Green Tree',
    'Greencastle',
    'Greenfield Township',
    'Greensboro',
    'Greensburg',
    'Greentown',
    'Greenville',
    'Grove City',
    'Guys Mills',
    'Gwynedd',
    'Hadley',
    'Halifax',
    'Hallstead',
    'Hamburg',
    'Hamilton Square',
    'Hanover',
    'Hanover Township',
    'Hanover Township, Luzerne',
    'Harborcreek',
    'Harding',
    'Harford',
    'Harleysville',
    'Harmony',
    'Harrisburg',
    'Harrison',
    'Harrison City',
    'Harrisville',
    'Harveys Lake',
    'Harwick',
    'Hatboro',
    'Hatfield, Montgomery',
    'Haverford',
    'Havertown',
    'Hawley',
    'Hawthorn',
    'Hazelton Mills',
    'Hazle Township',
    'Hazleton',
    'Hegins',
    'Heidelberg',
    'Heidlersburg',
    'Hellertown',
    'Hermitage',
    'Hershey',
    'Hibbs',
    'Hickory',
    'Hidden Valley',
    'Highspire',
    'Holland',
    'Hollidaysburg',
    'Hollsopple',
    'Holmes',
    'Holtwood',
    'Home',
    'Homer City',
    'Homestead',
    'Hometown',
    'Honesdale',
    'Honey Brook',
    'Honey Grove',
    'Horsham',
    'Houston',
    'Howard',
    'Hughestown',
    'Hughesville',
    'Hummels Wharf',
    'Hummelstown',
    'Hunker',
    'Huntingdon',
    'Huntingdon Valley',
    'Hyde',
    'Hyde Park',
    'Imler',
    'Imperial',
    'Indiana',
    'Indianola',
    'Industry',
    'Irvine',
    'Irving',
    'Irvona',
    'Irwin',
    'Ivyland',
    'Jackson',
    'Jackson Center',
    'Jamestown, Cambria',
    'Jeannette',
    'Jefferson',
    'Jefferson Hills',
    'Jeffersonville',
    'Jenkins Township',
    'Jenkintown',
    'Jermyn',
    'Jersey Shore',
    'Jessup',
    'Jim Thorpe',
    'Johnsonburg',
    'Johnston',
    'Johnstown',
    'Jonestown',
    'Josephtown',
    'Julian',
    'Kane',
    'Karns City',
    'Karthaus',
    'Kempton',
    'Kennett Square',
    'Kensington',
    'Kersey',
    'Kimberton',
    'King of Prussia',
    'Kingsley',
    'Kingston',
    'Kinzers',
    'Kirby',
    'Kirkwood',
    'Kittanning',
    'Klingerstown',
    'Knox',
    'Knoxville',
    'Koppel',
    'Kreamer',
    'Kulpmont',
    'Kulpsville',
    'Kunkletown',
    'Kutztown',
    'Kylertown',
    'Laceyville',
    'Lackawaxen',
    'Lafayette Hill',
    'Laflin',
    'Lahaska',
    'Lake Ariel',
    'Lake City',
    'Lake Como',
    'Lake Winola',
    'Lamar',
    'Lampeter',
    'Lancaster',
    'Landenberg',
    'Landisville',
    'Langeloth',
    'Langhorne',
    'Lansdale',
    'Lansdowne',
    'Lansford',
    'Latrobe',
    'Laurel',
    'Laureldale',
    'Laurys Station',
    'Lawrence',
    'Lawrenceville',
    'Lebanon',
    'Leechburg',
    'Leeper',
    'Leesport',
    'Leetsdale',
    'Lehigh',
    'Lehigh Valley',
    'Lehighton',
    'Lemasters',
    'Lemont Furnace',
    'Lemoyne',
    'Lenhartsville',
    'Lenni',
    'Leola',
    'Lester',
    'Levittown',
    'Lewis Run',
    'Lewisberry',
    'Lewisburg',
    'Lewistown',
    'Lewistown, Mifflin',
    'Liberty',
    'Ligonier',
    'Lima',
    'Limerick',
    'Lincoln University',
    'Line Lexington',
    'Linesville',
    'Linfield',
    'Linwood',
    'Lionville',
    'Lititz',
    'Littlestown',
    'Littletown',
    'Liverpool',
    'Lock Haven',
    'Locust Summit',
    'Londonderry',
    'Long Pond',
    'Loretto',
    'Loyalhanna',
    'Loysville',
    'Luthersburg',
    'Luzerne',
    'Lykens',
    'Lyon Station',
    'Mabel',
    'Macungie',
    'Madison',
    'Mahanoy City',
    'Mainland',
    'Malvern',
    'Manayunk',
    'Manchester',
    'Manheim',
    'Manns Choice',
    'Manor',
    'Mansfield',
    'Manver',
    'Marble',
    'Marcus Hook',
    'Marienville',
    'Marietta',
    'Marion',
    'Marlin',
    'Mars',
    'Martins Creek',
    'Martinsburg',
    'Marysville',
    'Masontown',
    'Mayfield',
    'McAdoo',
    'McAlisterville',
    'Mcclellandtown',
    'McClure',
    'McConnellsburg',
    'McDonald',
    'McElhattan',
    'Mckean',
    'McKees Rocks',
    'McKeesport',
    'McMurray',
    'McSherrystown',
    'McVeytown',
    'Meadow Lands',
    'Meadville',
    'Mechanicsburg',
    'Mechanicsburg, Beaver',
    'Media',
    'Mehoopany',
    'Melrose Park',
    'Mercer',
    'Mercersburg',
    'Merrittstown',
    'Mertztown',
    'Meshoppen',
    'Meyersdale',
    'Middleburg',
    'Middlebury Center',
    'Middleton',
    'Middletown',
    'Midland',
    'Mifflinburg',
    'Mifflintown',
    'Mifflinville',
    'Milan',
    'Mildred',
    'Milesburg',
    'Milford',
    'Mill Creek',
    'Mill Hall',
    'Millersburg',
    'Millerstown',
    'Millersville',
    'Millheim',
    'Millmont',
    'Millvale',
    'Millville',
    'Milroy',
    'Milton',
    'Mineral Springs',
    'Minersville',
    'Miquon',
    'Modena',
    'Mohnton',
    'Mohrsville',
    'Monaca',
    'Monessen',
    'Monongahela',
    'Monroeville',
    'Mont Alto',
    'Montello',
    'Montgomery',
    'Montgomeryville',
    'Montoursville',
    'Montrose',
    'Moon',
    'Moon Township',
    'Moonstown',
    'Moorestown',
    'Moosic',
    'Morgan',
    'Morgantown',
    'Morris',
    'Morrisville',
    'Morton',
    'Moscow',
    'Mount Bethel',
    'Mount Braddock',
    'Mount Carmel',
    'Mount Gretna',
    'Mount Holly Springs',
    'Mount Jewett',
    'Mount Joy',
    'Mount Morris',
    'Mount Pleasant',
    'Mount Pleasant Mills',
    'Mount Pocono',
    'Mount Union',
    'Mount Union, Huntingdon',
    'Mount Wolf',
    'Mountain Top',
    'Mountville',
    'Muhlenberg',
    'Muncy',
    'Murrysville',
    'Myerstown',
    'Nanticoke',
    'Narberth',
    'Narvon',
    'Natrona Heights',
    'Nazareth',
    'Needmore',
    'Nescopeck',
    'Nesquehoning',
    'Neville Island',
    'New Albany',
    'New Alexandria',
    'New Baltimore',
    'New Berlin',
    'New Bethlehem',
    'New Brighton',
    'New Britain',
    'New Buffalo',
    'New Castle',
    'New Centerville',
    'New Columbia',
    'New Cumberland',
    'New Eagle',
    'New Enterprise',
    'New Florence',
    'New Freedom',
    'New Galilee',
    'New Geneva',
    'New Holland',
    'New Hope',
    'New Kensington',
    'New Kingstown',
    'New London',
    'New Milford',
    'New Oxford',
    'New Philadelphia',
    'New Providence',
    'New Ringgold',
    'New Salem',
    'New Stanton',
    'New Tripoli',
    'New Wilmington',
    'Newell',
    'Newmanstown',
    'Newport, Perry',
    'Newportville',
    'Newry',
    'Newtown',
    'Newtown Square',
    'Newville',
    'Nicetown',
    'Nicholson',
    'Nixon',
    'Normalville',
    'Norristown',
    'North Bend',
    'North East',
    'North Huntingdon',
    'North Middleton',
    'North Versailles',
    'North Wales',
    'Northampton',
    'Northern Cambria',
    'Northumberland',
    'Norwood',
    'Nottingham',
    'Oak',
    'Oakdale',
    'Oakland',
    'Oakmont',
    'Oaks',
    'Ohio',
    'Ohiopyle',
    'Ohioview',
    'Oil City',
    'Olanta',
    'Old Forge',
    'Oley',
    'Olyphant',
    'Ono',
    'Orangeville',
    'Orefield',
    'Oreland',
    'Orrstown',
    'Orrtanna',
    'Orwigsburg',
    'Ottsville',
    'Oxford',
    'Palm',
    'Palmer',
    'Palmerton',
    'Palmertown',
    'Palmyra',
    'Paoli',
    'Paradise',
    'Park Junction',
    'Parker Ford',
    'Parkesburg',
    'Parkwood',
    'Parnassus',
    'Paxinos',
    'Peach Bottom',
    'Peach Glen',
    'Peckville',
    'Pen Argyl',
    'Penfield',
    'Penn Run',
    'Penndel',
    'Pennsburg',
    'Pennsdale',
    'Pennsylvania Furnace',
    'Pequea',
    'Perkasie',
    'Perkiomenville',
    'Perryopolis',
    'Petersburg',
    'Petrolia',
    'Philadelphia',
    'Philadelphia North East Apt',
    'Philipsburg',
    'Phoenixville',
    'Pillow',
    'Pine Forge',
    'Pine Grove',
    'Pine Grove, Clearfield',
    'Pipersville',
    'Pitcairn',
    'Pitman',
    'Pittsburg',
    'Pittsburgh',
    'Pittsfield',
    'Pittston',
    'Pittsville',
    'Plains',
    'Pleasant Gap',
    'Pleasant Hall',
    'Pleasant Hills',
    'Pleasant Unity',
    'Plumsteadville',
    'Plymouth',
    'Plymouth Meeting',
    'Pocono Manor',
    'Pocono Pines',
    'Pocono Summit',
    'Point Breeze',
    'Point Lookout',
    'Point Marion',
    'Polk',
    'Pomeroy',
    'Port Allegany',
    'Port Carbon',
    'Port Providence',
    'Port Richmond',
    'Port Trevorton',
    'Portage',
    'Portersville',
    'Portland',
    'Portland Mills',
    'Pottstown',
    'Pottsville',
    'Prospect Park',
    'Prospect, Butler',
    'Pulaski, Lawrence',
    'Punxsutawney',
    'Quakertown',
    'Quarryville',
    'Radnor',
    'Rahns',
    'Rankin',
    'Ransom',
    'Reading',
    'Reamstown',
    'Red Hill',
    'Red Lion',
    'Reedsville',
    'Rehrersburg',
    'Reinholds',
    'Renfrew',
    'Reno',
    'Reynoldsville',
    'Rheems',
    'Richboro',
    'Richfield',
    'Richland',
    'Richlandtown',
    'Riddlesburg',
    'Ridgway',
    'Ridley Park',
    'Rimersburg',
    'Ringgold',
    'Ringtown',
    'Riverside',
    'Roaring Branch',
    'Roaring Spring',
    'Robesonia',
    'Rochester',
    'Rockwood',
    'Ronks',
    'Rosemont',
    'Roseto',
    'Rothsville',
    'Rouseville',
    'Royersford',
    'Ruffs Dale',
    'Rural Valley',
    'Sacramento',
    'Sadsburyville',
    'Saegertown',
    'Saint Clair',
    'Saint Marys',
    'Saint Peters',
    'Saint Thomas',
    'Salisbury',
    'Saltsburg',
    'Salunga',
    'Sandy Lake',
    'Sarver',
    'Saxonburg',
    'Saxton',
    'Saylorsburg',
    'Sayre',
    'Schaefferstown',
    'Schenley',
    'Schnecksville',
    'Schuylkill',
    'Schuylkill Haven',
    'Schwenksville',
    'Scotland',
    'Scott Township',
    'Scottdale',
    'Scranton',
    'Secane',
    'Seiple',
    'Selinsgrove',
    'Sellersville',
    'Seneca',
    'Seven Fields',
    'Seven Valleys',
    'Sewickley',
    'Shade Gap',
    'Shady Grove',
    'Shamokin',
    'Sharon',
    'Sharon Hill',
    'Sharpsburg',
    'Sharpsville',
    'Shartlesville',
    'Shavertown',
    'Shawnee on Delaware',
    'Sheffield',
    'Shelocta',
    'Sheppton',
    'Shermans Dale',
    'Shickshinny',
    'Shippensburg',
    'Shippenville',
    'Shippingport',
    'Shiremanstown',
    'Shirleysburg',
    'Shoemakersville',
    'Shohola',
    'Shrewsbury',
    'Sidman',
    'Silverdale',
    'Simpson',
    'Sinking Spring',
    'Skippack',
    'Slatedale',
    'Slatington',
    'Slippery Rock',
    'Smethport',
    'Smithfield',
    'Smithton',
    'Smock',
    'Smoketown',
    'Snow Shoe',
    'Somerset',
    'Souderton',
    'South Abington',
    'South Canaan',
    'South Fork',
    'South Park',
    'South Willamsport',
    'Southampton',
    'Southwark',
    'Spartansburg',
    'Spraggs',
    'Spring City',
    'Spring Grove',
    'Spring House',
    'Spring Mills',
    'Springdale',
    'Springfield',
    'Sproul',
    'St. Petersburg',
    'State College',
    'Steelton',
    'Sterling',
    'Stevens',
    'Stockertown',
    'Stoneboro',
    'Stowe',
    'Stoystown',
    'Strafford',
    'Strasburg',
    'Strausstown',
    'Strinestown',
    'Strongstown',
    'Stroudsburg',
    'Sugar Grove',
    'Sugar Run',
    'Summerhill',
    'Summerville',
    'Summit',
    'Summit Station',
    'Sumneytown',
    'Sunbury',
    'Susquehanna',
    'Sutersville',
    'Swarthmore/Philadelphia',
    'Swatara',
    'Swiftwater',
    'Swissvale',
    'Sykesville',
    'Tafton',
    'Tamaqua',
    'Tannersville',
    'Tarentum',
    'Tatamy',
    'Taylor',
    'Telford',
    'Temple',
    'Terre Hill',
    'Thomasville',
    'Thompsontown',
    'Thorndale',
    'Throop',
    'Tioga',
    'Tionesta',
    'Tipton',
    'Titusville',
    'Tobyhanna',
    'Tomhicken',
    'Topton',
    'Toughkenamon',
    'Towanda',
    'Tower City',
    'Trafford',
    'Trainer',
    'Transfer',
    'Trappe',
    'Tremont',
    'Trevorton',
    'Trevose',
    'Troy',
    'Trumbauersville',
    'Tullytown',
    'Tunkhannock',
    'Turtle Creek',
    'Tuscarora',
    'Twin Rocks',
    'Tylersport',
    'Tyrone',
    'Ulysses',
    'Union',
    'Union City',
    'Union Dale',
    'Uniontown',
    'Unionville',
    'University Park',
    'Upper Chichester',
    'Upper Darby',
    'Uwchland',
    'Valencia',
    'Valley Forge',
    'Valley View',
    'Vandergrift',
    'Vanport',
    'Verona',
    'Villanova',
    'Vogleyville',
    'Volant',
    'Wagontown',
    'Walkertown',
    'Wall',
    'Wallingford',
    'Walnutport',
    'Walston',
    'Wampum',
    'Wapwallopen',
    'Warfordsburg',
    'Warminster',
    'Warren',
    'Warren Center',
    'Warrendale',
    'Warrington',
    'Washington',
    'Washington Crossing',
    'Washingtonville',
    'Waterford',
    'Waterville',
    'Watsontown',
    'Waymart',
    'Wayne',
    'Waynesboro',
    'Waynesburg',
    'Waynesville',
    'Weatherly',
    'Weissport',
    'Wellsboro',
    'Wellsville',
    'Wendel',
    'Wernersville',
    'West Alexander',
    'West Chester',
    'West Conshohocken',
    'West Easton',
    'West Elizabeth',
    'West Grove',
    'West Hazelton',
    'West Hazleton',
    'West Homestead',
    'West Lawn (Berk County)',
    'West Middlesex',
    'West Mifflin',
    'West Pittsburg',
    'West Pittston',
    'West Point',
    'West Reading',
    'West York',
    'Westmoreland',
    'Westmoreland City',
    'Westport',
    'Wexford',
    'Wheatland',
    'White Deer',
    'White Haven',
    'White Oak',
    'Whitehall',
    'Wilcox',
    'Wildwood',
    'Wilkes-Barre',
    'Wilkes-Barre/Scranton Apt',
    'Wilkinsburg',
    'Williamsburg',
    'Williamsport',
    'Williamstown',
    'Willow Grove',
    'Willow Hill',
    'Willow Street',
    'Wilmerding',
    'Winburne',
    'Wind Gap',
    'Wind Ridge',
    'Windber',
    'Winfield',
    'Wissahickon',
    'Womelsdorf',
    'Woodland',
    'Woolrich',
    'Worcester',
    'Worthington',
    'Wrightsville',
    'Wyalusing',
    'Wyncote',
    'Wynnewood',
    'Wyoming',
    'Wyomissing',
    'Wysox',
    'Yardley',
    'Yatesboro',
    'Yeadon',
    'Yeagertown',
    'Yoe',
    'York',
    'York Haven',
    'York Springs',
    'Youngstown',
    'Youngsville',
    'Youngwood',
    'Zelienople',
    'Zieglerville',
    'Zionsville',
    'Zullinger',
    'Mt Penn'
  ],
  PR: ['San Jose'],
  RI: [
    'Ashaway',
    'Ashton',
    'Barrington',
    'Berkeley',
    'Block Island',
    'Bradford',
    'Bristol',
    'Carolina',
    'Central Falls',
    'Charlestown',
    'Chepachet',
    'Clayville',
    'Coventry',
    'Cranston',
    'Cumberland',
    'Davisville',
    'East Greenwich',
    'East Providence',
    'Esmond',
    'Exeter',
    'Fiskeville',
    'Forestdale',
    'Foster',
    'Glendale',
    'Glocester',
    'Greenville',
    'Harrisville',
    'Hope',
    'Hope Valley',
    'Hopkinton',
    'Jamestown',
    'Johnston',
    'Kenyon',
    'Kingston',
    'Lincoln',
    'Little Compton',
    'Manville',
    'Mapleville',
    'Middletown',
    'Millville',
    'Narragansett',
    'Nasonville',
    'Newport',
    'North Kingston Beach',
    'North Kingstown',
    'North Providence',
    'North Scituate',
    'North Smithfield',
    'Oakland',
    'Pascoag',
    'Pawtucket',
    'Peace Dale',
    'Point Judith',
    'Portsmouth',
    'Providence',
    'Quonset Point',
    'Richmond',
    'Riverside',
    'Rockville',
    'Rumford',
    'Saunderstown',
    'Saylesville',
    'Shannock',
    'Slatersville',
    'Slaterville',
    'Slocum',
    'Smithfield',
    'South Kingstown',
    'Tiverton',
    'Wakefield',
    'Warren',
    'Warwick',
    'West Glocester',
    'West Greenwich',
    'West Kingston',
    'West Warwick',
    'Westerly',
    'Wildes Corner',
    'Wood River Junction',
    'Woonsocket',
    'Wyoming'
  ],
  SC: [
    'Abbeville',
    'Aberdeen',
    'Adams Run',
    'Aiken',
    'Allendale',
    'Anderson',
    'Andrews',
    'Arcadia',
    'Awendaw',
    'Aynor',
    'Baldwin',
    'Bamberg',
    'Barnwell',
    'Batesburg',
    'Bath',
    'Beaufort',
    'Beech Island',
    'Belton',
    'Bennettsville',
    'Bethune',
    'Bishopville',
    'Blacksburg',
    'Blackstock',
    'Blackville',
    'Blair',
    'Bluffton',
    'Blythewood',
    'Boiling Springs, Barnwell',
    'Bonneau',
    'Bowman',
    'Bradley',
    'Branchville',
    'Buffalo',
    'Calhoun Falls',
    'Camden',
    'Campobello',
    'Carlisle',
    'Carlisle, Spartanburg',
    'Cartersville',
    'Catawba',
    'Cayce',
    'Central',
    'Chapin',
    'Charleston',
    'Charleston Heights',
    'Cheraw',
    'Cherokee Falls',
    'Chesnee',
    'Chester',
    'Chesterfield',
    'Clemson',
    'Clifton',
    'Clinton',
    'Clio',
    'Clover',
    'Columbia',
    'Conestee',
    'Conway',
    'Cooper River Landing',
    'Cope',
    'Coward',
    'Cowpens',
    'Cross',
    'Dalzell',
    'Daniel Island',
    'Darlington',
    'Daufuskie Island',
    'Denmark',
    'Dillon',
    'Donalds',
    'Dorchester',
    'Duncan',
    'Dunn',
    'Early Branch',
    'Easley',
    'Eastover',
    'Edgefield',
    'Edgemoor',
    'Edisto Island',
    'Effingham',
    'Elgin',
    'Elloree',
    'Enoree',
    'Estill',
    'Fairfax',
    'Fairforest',
    'Florence',
    'Folly Beach',
    'Fort Jackson',
    'Fort Lawn',
    'Fort Mill',
    'Fountain Inn',
    'Frogmore',
    'Furman',
    'Gable',
    'Gadsden',
    'Gaffney',
    'Garden City',
    'Gaston',
    'Georgetown',
    'Gilbert',
    'Goose Creek',
    'Grace',
    'Graniteville',
    'Gray Court',
    'Great Falls',
    'Greeleyville',
    'Green Pond',
    'Green Valley',
    'Greenville',
    'Greenville-Greer Apt',
    'Greenwood',
    'Greer',
    'Greer-Greenville Apt',
    'Gresham',
    'Hamer',
    'Hampton',
    'Hanahan',
    'Hardeeville',
    'Harleyville',
    'Harmony',
    'Hartsville',
    'Heath Springs',
    'Hemingway',
    'Hickory Grove, York',
    'Hilton Head Island',
    'Hodges',
    'Holly Hill',
    'Hollywood',
    'Honea Path',
    'Hopkins',
    'Huger',
    'Indian Land',
    'Inman',
    'Irmo',
    'Isle of Palms',
    'Iva',
    'Jackson, Aiken',
    'Jackson, Georgetown',
    'Jacksonboro',
    'James Island',
    'Jamestown',
    'Jefferson',
    'Jenkinsville',
    'Joanna',
    'Johns Island',
    'Johnsonville',
    'Johnston',
    'Jonesville',
    'Kershaw',
    'Kiawah Island',
    'Kinards',
    'Kings Creek',
    'Kingstree',
    'La France',
    'Ladson',
    'Lake City',
    'Lake Wylie',
    'Lamar',
    'Lancaster',
    'Lando',
    'Landrum',
    'Lane',
    'Langley',
    'Latta',
    'Laurens',
    'Leeds',
    'Leesville',
    'Lexington',
    'Liberty',
    'Little Mountain',
    'Little River',
    'Longs',
    'Loris',
    'Lugoff',
    'Lyman',
    'Lynchburg',
    'Madison',
    'Manning',
    'Marietta',
    'Marion',
    'Martin',
    'Mauldin',
    'Mayo',
    'McBee',
    'Mcclellanville',
    'Mcconnells',
    'McCormick',
    'Meggett',
    'Moncks Corner',
    'Monetta',
    'Moore',
    'Mount Holly',
    'Mount Pleasant',
    'Mountain Rest',
    'Mountville',
    'Mullins',
    'Murrells Inlet',
    'Myrtle Beach',
    'Nesmith',
    'New Ellenton',
    'New Prospect',
    'Newberry',
    'Nichols',
    'Ninety Six',
    'Norris',
    'North',
    'North Augusta',
    'North Charleston',
    'North Myrtle Beach',
    'North Santee',
    'Olar',
    'Orangeburg',
    'Pacolet',
    'Pageland',
    'Pamplico',
    'Parris Island',
    'Pauline',
    'Pawleys Island',
    'Paxville',
    'Pelion',
    'Pelzer',
    'Pendleton',
    'Perry',
    'Pickens',
    'Piedmont',
    'Pineland',
    'Pineville',
    'Pontiac',
    'Port Royal',
    'Princeton',
    'Prosperity',
    'Ravenel',
    'Reidville',
    'Richburg',
    'Ridge Spring',
    'Ridgeland',
    'Ridgeville',
    'Ridgeway',
    'Rock Hill',
    'Roebuck',
    'Rowesville',
    'Ruby',
    'Ruffin',
    'Saint Andrews',
    'Saint George',
    'Saint Matthews',
    'Saint Stephen',
    'Salem',
    'Salley',
    'Saluda',
    'Sampit',
    'Sandy Run',
    'Sandy Springs',
    'Santee',
    'Seabrook',
    'Seneca',
    'Shaw Air Force Base',
    'Silverstreet',
    'Simpsonville',
    'Six Mile',
    'Slater',
    'Society Hill',
    'South Sumter',
    'Spartanburg',
    'Springfield',
    'Starr',
    'Startex',
    'Stone',
    'Summerton',
    'Summerville',
    'Summit',
    'Sumter',
    'Surfside Beach',
    'Swansea',
    'Tatum',
    'Taylors',
    'Tega Cay',
    'Timmonsville',
    'Travelers Rest',
    'Trenton',
    'Ulmer',
    'Union',
    'Union South Carolina',
    'Van Wyck',
    'Vance',
    'Varnville',
    'Wadmalaw Island',
    'Wagener',
    'Walhalla',
    'Wallace',
    'Walterboro',
    'Wando',
    'Ward',
    'Ware Shoals',
    'Warrenville',
    'Wedgefield',
    'Wellford',
    'West Columbia',
    'West Union',
    'Westminster',
    'White Stone',
    'Whitesville',
    'Whitmire',
    'Wilkins',
    'Williamston',
    'Willington',
    'Williston',
    'Windsor',
    'Winnsboro',
    'Woodruff',
    'Yemassee',
    'York'
  ],
  SD: [
    'Aberdeen',
    'Alcester',
    'Alpena',
    'Amherst',
    'Andover',
    'Arlington',
    'Artesian',
    'Astoria',
    'Aurora',
    'Baltic',
    'Belle Fourche',
    'Beresford',
    'Big Stone City',
    'Blackhawk',
    'Box Elder',
    'Brandon',
    'Brandt',
    'Bridgewater',
    'Britton',
    'Brookings',
    'Bruce',
    'Bryant, Hamlin',
    'Burke',
    'Byant',
    'Canistota',
    'Canton',
    'Carpenter',
    'Castlewood',
    'Chamberlain',
    'Claire City',
    'Clark',
    'Clear Lake',
    'Colman',
    'Corsica',
    'Custer',
    'Dakota Dunes',
    'De Smet',
    'Deadwood',
    'Eagle Butte',
    'Eden',
    'Edgemont',
    'Egan',
    'Elk Point',
    'Elkton',
    'Ellsworth Air Force Base',
    'Emery',
    'Enning',
    'Estelline',
    'Ethan',
    'Fairburn',
    'Fairview',
    'Faulkton',
    'Flandreau',
    'Fort Pierre',
    'Frankfort',
    'Freeman',
    'Fruitdale',
    'Garretson',
    'Gayville, Yankton',
    'Geddes',
    'Gregory',
    'Grenville',
    'Groton',
    'Harold',
    'Harrisburg',
    'Harrold',
    'Hartford',
    'Hayes',
    'Herreid',
    'Highmore',
    'Hill City',
    'Hitchcock',
    'Hosmer',
    'Hot Springs',
    'Hoven',
    'Howard',
    'Hudson',
    'Huron',
    'Ipswich',
    'Isabel',
    'Jefferson',
    'Kadoka',
    'Keystone',
    'Lake Andes',
    'Lake Norden',
    'Lake Preston',
    'Lead',
    'Lemmon',
    'Lennox',
    'Lesterville',
    'Letcher',
    'Lower Brule',
    'Lyons',
    'Madison',
    'Mansfield',
    'Marion',
    'McIntosh',
    'Menno',
    'Midland',
    'Milbank',
    'Milesville',
    'Miller',
    'Mission',
    'Mission Hill',
    'Mitchell',
    'Mobridge',
    'Nemo',
    'North Sioux City',
    'Oldham',
    'Onida',
    'Parkston',
    'Philip',
    'Pickstown',
    'Piedmont',
    'Pierre',
    'Pine Ridge',
    'Plankinton',
    'Platte',
    'Presho',
    'Rapid City',
    'Reva',
    'Revillo',
    'Rosholt',
    'Rowena',
    'Salem',
    'Scotland',
    'Sioux Falls',
    'Sisseton',
    'Spearfish',
    'Springfield',
    'Sturgis',
    'Tabor',
    'Tea',
    'Timber Lake',
    'Trent',
    'Tripp',
    'Utica',
    'Vale',
    'Valley Springs',
    'Veblen',
    'Vermillion',
    'Viborg',
    'Volga',
    'Wagner',
    'Wall',
    'Watertown',
    'Webster',
    'Wentworth',
    'Westport',
    'Wetonka',
    'White',
    'White River',
    'Winner',
    'Woonsocket',
    'Worthing',
    'Yankton'
  ],
  TN: [
    'Adams',
    'Adamsville',
    'Afton',
    'Alamo',
    'Alcoa',
    'Alexandria',
    'Algood',
    'Altamont',
    'Andersonville',
    'Antioch',
    'Ardmore',
    'Arlington, Houston',
    'Arlington, Knox',
    'Arlington, Shelby',
    'Arrington',
    'Ashland',
    'Ashland City',
    'Athens',
    'Atoka',
    'Auburntown',
    'Bakewell',
    'Bartlett',
    'Baxter',
    'Bean Station',
    'Beersheba',
    'Belfast',
    'Bell Buckle',
    'Bells',
    'Belvidere',
    'Bemis',
    'Benton',
    'Bether Springs',
    'Big Sandy',
    'Birchwood',
    'Blaine',
    'Blountville',
    'Bluff City',
    'Bolivar',
    'Bon Aqua',
    'Bradford',
    'Brentwood',
    'Brighton',
    'Bristol',
    'Bristol-Johnson City-Kingsport (Tri-Cities Regional) Apt',
    'Brownsville',
    'Bruceton',
    'Buchanan',
    'Bulls Gap',
    'Burlison',
    'Burns',
    'Bybee',
    'Byrdstown',
    'Calhoun',
    'Camden',
    'Carthage',
    'Caryville',
    'Cedar Hill',
    'Celina',
    'Centerville',
    'Chapel Hill',
    'Chapmansboro',
    'Charleston',
    'Charlotte',
    'Chattanooga',
    'Chuckey',
    'Church Hill',
    'Clarkrange',
    'Clarksburg',
    'Clarksville',
    'Cleveland',
    'Clifton',
    'Clinton',
    'Coalfield',
    'College Grove',
    'Collegedale',
    'Collierville',
    'Collinwood',
    'Columbia',
    'Cookeville',
    'Coopertown',
    'Copperhill',
    'Cordova',
    'Corinth',
    'Cornersville',
    'Cosby',
    'Cottage Grove',
    'Cottontown',
    'Counce',
    'Covington',
    'Cowan',
    'Crab Orchard',
    'Cross Plains',
    'Crossville',
    'Crump',
    'Culleoka',
    'Cumberland City',
    'Cumberland Furnace',
    'Dandridge',
    'Dayton',
    'Decatur',
    'Decaturville',
    'Decherd',
    'Deer Lodge',
    'Del Rio',
    'Delano',
    'Dickson',
    'Dover',
    'Doyle',
    'Dresden',
    'Ducktown',
    'Dunlap',
    'Dyer',
    'Dyersburg',
    'Eads',
    'Eagleville',
    'East Ridge',
    'Elizabethton',
    'Elkton',
    'Elora',
    'Englewood',
    'Erin',
    'Erwin',
    'Estill Springs',
    'Ethridge',
    'Etowah',
    'Eva',
    'Evansville',
    'Fairfield',
    'Fairview',
    'Fayetteville',
    'Forest Hills',
    'Franklin',
    'Friendship',
    'Friendsville',
    'Gainesboro',
    'Gallatin',
    'Gallaway',
    'Gates',
    'Gatlinburg',
    'Georgetown',
    'Germantown',
    'Gladeville',
    'Gleason',
    'Goodlettsville',
    'Gordonsville',
    'Grand Junction',
    'Gray',
    'Greenback',
    'Greenbrier, Robertson',
    'Greeneville',
    'Greenfield',
    'Greenland',
    'Greenville',
    'Guild',
    'Halls',
    'Hampshire',
    'Hampton',
    'Harriman',
    'Harrison',
    'Hartsville',
    'Helenwood',
    'Henderson',
    'Hendersonville',
    'Henning',
    'Henry',
    'Hermitage',
    'Hillsboro',
    'Hixon',
    'Hixson',
    'Hohenwald',
    'Holladay',
    'Holston',
    'Hornsby',
    'Houston',
    'Humboldt',
    'Huntingdon',
    'Huntington',
    'Huntsville',
    'Huron',
    'Iron City',
    'Jacksboro',
    'Jackson',
    'Jacksonville',
    'Jamestown',
    'Jasper',
    'Jefferson City',
    'Jellico',
    'Joelton',
    'Johnson City',
    'Johnson City-Kingsport-Bristol (Tri-Cities Regional) Apt',
    'Jonesboro',
    'Jonesborough',
    'Kelso',
    'Kenton',
    'Kingsport',
    'Kingsport-Bristol-Johnson City (Tri-Cities Regional) Apt',
    'Kingston',
    'Kingston Springs',
    'Kirkland',
    'Knoxville',
    'Kodak',
    'La Follette',
    'La Vergne',
    'Lafayette',
    'Lake City',
    'Lakeland',
    'Lavinia',
    'Lawrenceburg',
    'Lebanon',
    'Lenoir City',
    'Leoma',
    'Lewisburg',
    'Lexington',
    'Liberty Hill, Williamson',
    'Liberty, De Kalb',
    'Limestone',
    'Linden',
    'Livingston',
    'Lookout Mountain',
    'Loretto',
    'Loudon',
    'Louisville',
    'Lowland',
    'Lupton City',
    'Luray',
    'Luttrell, Union',
    'Lutts',
    'Lyles',
    'Lynchburg',
    'Lynnville',
    'Madison',
    'Madisonville',
    'Manchester',
    'Martin',
    'Marvin',
    'Maryville',
    'Mascot',
    'Mason',
    'Maury City',
    'Maynardville',
    'McDonald',
    'McEwen',
    'McKenzie',
    'McMinnville',
    'Medon',
    'Melrose',
    'Memphis',
    'Middleburg',
    'Middleton',
    'Midway',
    'Milan',
    'Millington',
    'Monroe',
    'Monteagle',
    'Monterey',
    'Montgomery',
    'Mooresburg',
    'Mooresville',
    'Morgantown',
    'Morrison',
    'Morristown',
    'Moscow',
    'Mosheim',
    'Moss',
    'Mount Juliet',
    'Mount Pleasant',
    'Mountain City',
    'Mountain Home',
    'Munford',
    'Murfreesboro',
    'Nashville',
    'New Hope',
    'New Johnsonville',
    'New Market',
    'New Tazewell',
    'Newbern',
    'Newcomb',
    'Newport',
    'Niota',
    'Nolensville',
    'Normandy',
    'Norris',
    'Nunnelly',
    'Oak Ridge',
    'Oakdale, Morgan',
    'Oakfield',
    'Oakland, Fayette',
    'Obion',
    'Ocoee',
    'Ocoee, Polk',
    'Old Fort',
    'Old Hickory',
    'Oneida',
    'Ooltewah',
    'Orlinda',
    'Paris',
    'Parrottsville',
    'Parsons',
    'Pelham',
    'Petersburg',
    'Pigeon Forge',
    'Pikeville',
    'Piney Flats',
    'Pinson',
    'Pioneer',
    'Piperton',
    'Plainfield',
    'Pleasant Hill',
    'Pleasant Shade',
    'Pleasant View',
    'Pleasant View, Cheatham',
    'Pleasantville',
    'Pocahontas',
    'Portland',
    'Powell',
    'Primm Springs',
    'Prospect',
    'Pulaski',
    'Puryear',
    'Red Bank',
    'Red Boiling Springs',
    'Riceville',
    'Rickman',
    'Ripley',
    'Roan Mountain',
    'Rockford',
    'Rockvale',
    'Rockwood',
    'Rogersville',
    'Rossville',
    'Russellville',
    'Rutherford',
    'Rutledge',
    'Sale Creek',
    'Saltillo',
    'Savannah',
    'Scotts Hill',
    'Selmer',
    'Sequatchie',
    'Sevierville',
    'Sewanee',
    'Seymour',
    'Shelbyville',
    'Signal Mountain',
    'Smartt',
    'Smithville',
    'Smyrna',
    'Sneedville',
    'Soddy Daisy',
    'Soddy-Daisy',
    'Somerville',
    'South Johnson City',
    'South Pittsburg',
    'Sparta',
    'Speedwell',
    'Spencer',
    'Spring City',
    'Spring Hill',
    'Springfield',
    'Springville',
    'Stanton',
    'Stantonville',
    'Strawberry Plains',
    'Surgoinsville',
    'Sweetwater',
    'Talbott',
    'Tazewell',
    'Telford',
    'Tellico Plains',
    'Ten Mile',
    'Tennessee Ridge',
    'Terrell',
    'Thompsons Station',
    'Three Way',
    'Tiptonville',
    'Trenton',
    'Tri-Cities Regional Airport',
    'Troy',
    'Tullahoma',
    'Unicoi',
    'Union City',
    'Unionville',
    'Vanleer',
    'Verona',
    'Vonore',
    'Walland',
    'Wartburg',
    'Washburn',
    'Watertown',
    'Waverly',
    'Waynesboro',
    'Westmoreland',
    'White Bluff',
    'White House',
    'White Pine',
    'Whites Creek',
    'Whitesburg',
    'Whiteville',
    'Whitlock',
    'Whitwell',
    'Wildersville',
    'Williston',
    'Winchester',
    'Winfield',
    'Woodbury',
    'Woodlawn',
    'Woodstock'
  ],
  TX: [
    'Abbott',
    'Abernathy',
    'Abilene',
    'Acton',
    'Addison',
    'Adkins',
    'Adrian',
    'Agua Dulce',
    'Alamo',
    'Alba',
    'Albany',
    'Aldine',
    'Aledo',
    'Alice',
    'Alief',
    'Allen',
    'Alleyton',
    'Allison',
    'Alpine',
    'Alta Loma',
    'Alto',
    'Alvarado',
    'Alvin',
    'Amarillo',
    'Amelia',
    'Ames',
    'Amherst',
    'Anahuac',
    'Anderson',
    'Andrews',
    'Angleton',
    'Anna',
    'Annona',
    'Anthony',
    'Aransas Pass',
    'Arcola',
    'Argyle',
    'Arlington',
    'Artesia',
    'Arthur City',
    'Ashland',
    'Aspermont',
    'Atascocita',
    'Athens',
    'Atlanta',
    'Atreco',
    'Aubrey',
    'Austin',
    'Avalon',
    'Avery',
    'Avinger',
    'Azle',
    'Bacliff',
    'Balch Springs',
    'Ballinger',
    'Balmorhea',
    'Bandera',
    'Barbours Cut',
    'Barker',
    'Barstow',
    'Bartlett',
    'Bartonville',
    'Bastrop',
    'Batesville',
    'Batson',
    'Bay City',
    'Bayport',
    'Bayside',
    'Baytown',
    'Beach City',
    'Beasley',
    'Beaumont',
    'Bedford',
    'Bee Cave',
    'Beeville',
    'Bellaire',
    'Bellmead',
    'Bellville',
    'Belmont',
    'Belton',
    'Benbrook',
    'Berclair',
    'Berger',
    'Bertram',
    'Big Bend',
    'Big Lake',
    'Big Spring',
    'Bishop',
    'Blackland, Rockwell',
    'Blanco',
    'Blanconia',
    'Bleiblerville',
    'Bloomburg',
    'Bloomington',
    'Blue Mound',
    'Bluff Dale',
    'Blum',
    'Boerne',
    'Bogata',
    'Boling',
    'Bon Wier',
    'Bonger',
    'Bonham',
    'Booker',
    'Borger',
    'Boston',
    'Bowie',
    'Boyd',
    'Brackettville',
    'Brady',
    'Brazoria',
    'Breckenridge',
    'Bremond',
    'Brenham',
    'Briarcliff',
    'Bridge City',
    'Bridgeport',
    'Brock',
    'Brookshire',
    'Brookston',
    'Brownfield',
    'Brownsville',
    'Brownwood',
    'Bruceville',
    'Bryan',
    'Bryson',
    'Buda',
    'Buffalo',
    'Bullard',
    'Bulverde',
    'Burkburnett',
    'Burleson',
    'Burnet',
    'Burnett',
    'Cactus',
    'Cactus (Moore County)',
    'Caddo Mills',
    'Cadiz',
    'Caldwell',
    'Calvert',
    'Camden',
    'Cameron',
    'Campbell',
    'Canadian',
    'Canton',
    'Canutillo',
    'Canyon',
    'Canyon City',
    'Canyon Lake',
    'Carlton',
    'Carmine',
    'Carrizo Springs',
    'Carrollton',
    'Carthage',
    'Castle Hills',
    'Castroville',
    'Cat Spring',
    'Catarina',
    'Cedar Bayou',
    'Cedar Creek',
    'Cedar Hill',
    'Cedar Park',
    'Cee Vee',
    'Celina',
    'Center',
    'Center, Limestone',
    'Channelview',
    'Channing',
    'Charleston',
    'Charlotte',
    'Cheek',
    'Chico',
    'Childress',
    'Chillicothe',
    'China',
    'Chocolate Bayou',
    'Chriesman',
    'Christine',
    'Cibolo',
    'Cisco',
    'Clarendon',
    'Clark',
    'Clarksville',
    'Clear Lake Shores',
    'Clearlake',
    'Cleburne',
    'Cleveland',
    'Clifton',
    'Clint',
    'Clute',
    'Clyde',
    'Cockrell Hill',
    'Coldspring',
    'Coleman',
    'College Station',
    'Colleyville',
    'Collinsville',
    'Colorado City',
    'Columbus',
    'Comanche',
    'Combes',
    'Comfort',
    'Commerce',
    'Comyn',
    'Concan',
    'Concord',
    'Conroe',
    'Converse',
    'Cooper',
    'Copell',
    'Coppell',
    'Copper Canyon',
    'Copperas Cove',
    'Corinth',
    'Corpus Christi',
    'Corrigan',
    'Corsicana',
    'Cotulla',
    'Coupland',
    'Coyanosa',
    'Crandall',
    'Crane',
    'Creedmoor',
    'Cresson',
    'Crockett',
    'Crosby',
    'Crosbyton',
    'Cross Roads',
    'Crossroads',
    'Crowell',
    'Crowley',
    'Crystal City',
    'Cuero',
    'Cushing',
    'Cut and Shoot',
    'Cypress',
    'Daingerfield',
    'Dalhart',
    'Dallas',
    'Dallas-Fort Worth Int Apt',
    'Dal-nor',
    'Danbury',
    'Danevang',
    'Dawn',
    'Dayton',
    'De Kalb',
    'De Leon',
    'De Soto',
    'Decatur',
    'Decker Prairie',
    'Deer Park',
    'Defense',
    'Del Rio',
    'Del Valle',
    'DeLeon',
    'Dell City',
    'Denison',
    'Dennis',
    'Denton',
    'Denver City',
    'Deport',
    'DeSoto',
    'Detroit',
    'Devine',
    "D'Hanis",
    'Diboll',
    'Dickinson',
    'Dilley',
    'Dilworth',
    'Dime Box',
    'Dimmitt',
    'Domino',
    'Donna',
    'Dowling',
    'Dresden',
    'Driftwood',
    'Dripping Springs',
    'Dryden',
    'Dublin',
    'Dumas',
    'Duncanville',
    'Eagle Lake',
    'Eagle Pass',
    'Early',
    'East Bernard',
    'Eastland',
    'Edcouch',
    'Eddy',
    'Eden, Concho',
    'Eden, Nacogdoches',
    'Edgewood',
    'Edinburg',
    'Edna',
    'Edroy',
    'El Campo',
    'El Paso',
    'Eldon',
    'Eldorado',
    'Elgin',
    'Elkhart',
    'Elm Mott',
    'Elmendorf',
    'Elwood',
    'Emory',
    'Encino',
    'Energy',
    'Englewood Yard',
    'Ennis',
    'Enochs',
    'Etter',
    'Euless',
    'Eustace',
    'Evadale',
    'Evant',
    'Everman',
    'Fabens',
    'Fairfield',
    'Fairview',
    'Falfurrias',
    'Falls City',
    'Farmers Branch',
    'Farmersville',
    'Farwell',
    'Fate',
    'Fayetteville',
    'Ferris',
    'Fischer',
    'Flatonia',
    'Flint',
    'Florence',
    'Floresville',
    'Flower Mound',
    'Floydada',
    'Follett',
    'Forest Hill',
    'Forestburg',
    'Forney',
    'Forsan',
    'Fort Bliss',
    'Fort Davis',
    'Fort Hancock',
    'Fort Hood',
    'Fort Sam Houston',
    'Fort Stockton',
    'Fort Worth',
    'Frankel City',
    'Franklin',
    'Frankston',
    'Fredericksburg',
    'Fredonia',
    'Freeport',
    'Freer',
    'Fresno',
    'Friendswood',
    'Friona',
    'Frisco',
    'Frost',
    'Fulshear',
    'Fulton',
    'Gainesville',
    'Galena Park',
    'Gallatin',
    'Galveston',
    'Ganado',
    'Garden City',
    'Gardendale',
    'Garland',
    'Gary',
    'Gatesville',
    'Genoa',
    'George Bush Intercontinental Apt/Houston',
    'Georgetown',
    'Giddings',
    'Gilmer',
    'Girard',
    'Gladewater',
    'Gladewater-Longview-Kilgore Apt',
    'Glen Rose',
    'Glendale',
    'Godley',
    'Golden',
    'Goldsmith',
    'Goldthwaite',
    'Goliad',
    'Gonzales',
    'Goodrich',
    'Gordonville',
    'Gorman',
    'Graford',
    'Graham',
    'Granbury',
    'Grand Prairie',
    'Grand Saline',
    'Grandfalls',
    'Grandview',
    'Grandview, Dawson',
    'Granger',
    'Granite Shoals',
    'Grapeland',
    'Grapevine',
    'Greenview Hills',
    'Greenville',
    'Gregory',
    'Griffin',
    'Groesbeck',
    'Groves',
    'Gun Barrel City',
    'Gunter',
    'Gustine',
    'Hallettsville',
    'Hallsville',
    'Haltom City',
    'Hamilton',
    'Hamlin',
    'Haney',
    'Hankamer',
    'Harbor Island',
    'Hardin',
    'Harker Heights',
    'Harlingen',
    'Harper',
    'Hart',
    'Hartley',
    'Harwood',
    'Haskell',
    'Haslet',
    'Hawkins',
    'Hearne',
    'Heath',
    'Hebbronville',
    'Hebert',
    'Helotes',
    'Hemphill Texas',
    'Hempstead',
    'Hemstead',
    'Henderson',
    'Henrietta',
    'Hereford',
    'Hewitt',
    'Hickory Creek',
    'Hico',
    'Hidalgo',
    'Highland Village',
    'Highland, Dallas',
    'Highlands',
    'Hillister',
    'Hillsboro',
    'Hitchcock',
    'Hochheim',
    'Hockley',
    'Hondo',
    'Honey Grove',
    'Hooks',
    'Hope',
    'Horizon City',
    'Horseshoe Bay',
    'Houston',
    'Houston Heights',
    'Howe',
    'Huffman',
    'Hughes Springs',
    'Hull',
    'Humble',
    'Hungerford',
    'Hunt',
    'Huntsville',
    'Hurst',
    'Hutchins',
    'Hutto',
    'Idalou',
    'Imperial',
    'Industry',
    'Ingleside',
    'Ingram',
    'Iola',
    'Iowa Park',
    'Ira',
    'Iraan',
    'Irving',
    'Italy',
    'Itasca',
    'Jacinto City',
    'Jacksboro',
    'Jacksonville',
    'Jarrell',
    'Jasper',
    'Jefferson',
    'Jewett',
    'Johnson City',
    'Jolly',
    'Jollyville',
    'Jonesboro',
    'Jonestown',
    'Joshua',
    'Jourdanton',
    'Juárez',
    'Junction',
    'Justin',
    'Kamay',
    'Katy',
    'Kaufman',
    'Keenan',
    'Keene',
    'Keller',
    'Kemah',
    'Kemp',
    'Kendleton',
    'Kenedy',
    'Kennedale',
    'Kerens',
    'Kermit',
    'Kerrville',
    'Kilgore',
    'Kilgore-Gladewater-Longview Apt',
    'Killeen',
    'Kingsbury',
    'Kingsland',
    'Kingsville',
    'Kingwood',
    'Kinwood',
    'Kirby',
    'Klein',
    'Klondike',
    'Knippa',
    'Kosse',
    'Kountze',
    'Kress',
    'Krugerville',
    'Krum',
    'Kyle',
    'La Feria',
    'La Grange',
    'La Marque',
    'La Porte',
    'La Pryor',
    'La Vernia',
    'La Ward',
    'Lackland City',
    'Ladonia',
    'Lago Vista',
    'Laguna Vista',
    'Lake Creek',
    'Lake Dallas',
    'Lake Jackson',
    'Lake Worth',
    'Lakeside',
    'Lakeway',
    'Lamesa',
    'Lampasas',
    'Lancaster',
    'Lane City',
    'Laredo',
    'Latexo',
    'Laughlin Air Force Base',
    'Lavon',
    'League City',
    'Leakey',
    'Leander',
    'Ledbetter',
    'Leesburg',
    'Lenorah',
    'Leon Valley',
    'Leonard',
    'Levelland',
    'Lewisville',
    'Lexington',
    'Liberty',
    'Liberty Hill, Milam',
    'Lindale',
    'Linden',
    'Lindsay',
    'Linn',
    'Little Elm',
    'Little River',
    'Littlefield',
    'Liverpool',
    'Livingston',
    'Llano',
    'Lockhart',
    'Lockney',
    'Lolita',
    'Lometa',
    'Lone Star',
    'Long Mott',
    'Longview',
    'Longview Heights',
    'Longview-Kilgore-Gladewater Apt',
    'Loop',
    'Lorena',
    'Lorenzo',
    'Los Fresnos',
    'Los Indios',
    'Lott',
    'Louise',
    'Lovelady',
    'Lubbock',
    'Lufkin',
    'Luling',
    'Lumberton',
    'Lyford',
    'Lytle',
    'Mabank',
    'Madisonville',
    'Magnolia',
    'Malakoff',
    'Malone',
    'Malvern',
    'Manor',
    'Mansfield',
    'Manvel',
    'Mapleton',
    'Marathon',
    'Marble Falls',
    'Marfa',
    'Marion',
    'Markham',
    'Marlin',
    'Marquez',
    'Marshall',
    'Martindale',
    'Maryneal',
    'Mason',
    'Matador',
    'Matagorda Island',
    'Mathis',
    'Mauriceville',
    'May',
    'Maypearl',
    'McAllen',
    'McAllen-Mission Apt',
    'McCamey',
    'McDade',
    'McGregor',
    'McKinney',
    'McNeil',
    'McQueeney',
    'Meadows Place',
    'Medina',
    'Megargel',
    'Melissa',
    'Melvin',
    'Memphis',
    'Mendota',
    'Mentone',
    'Mercedes',
    'Meridian',
    'Merkel',
    'Mertzon',
    'Mesquite',
    'Mexia',
    'Miami',
    'Mico',
    'Midfield',
    'Midkiff',
    'Midland',
    'Midland Airpark',
    'Midlothian',
    'Milford',
    'Millersview',
    'Millican',
    'Millsap',
    'Minden',
    'Mineola',
    'Mineral',
    'Mineral Wells',
    'Mingus',
    'Mission',
    'Mission-McAllen Apt',
    'Missouri City',
    'Monahans',
    'Mont Belvieu',
    'Monte Alto',
    'Montgomery',
    'Moody',
    'Morgan Point',
    'Morgans Point',
    'Morse',
    'Morton',
    'Moscow',
    'Moulton',
    'Mount Pleasant',
    'Mount Vernon',
    'Muenster',
    'Muldoon',
    'Muleshoe',
    'Mumford',
    'Murchison',
    'Murphy',
    'Mustang Ridge',
    'Nacogdoches',
    'Naples',
    'Nash',
    'Nash, Ellis',
    'Nassau Bay',
    'Navasota',
    'Neches',
    'Nederland',
    'Needville',
    'Nevada',
    'New Boston',
    'New Braunfels',
    'New Caney',
    'New Colony',
    'New Deal',
    'New Summerfield',
    'New Ulm',
    'New Waverly',
    'New York',
    'Newark',
    'Newton',
    'Nixon',
    'Nocona',
    'Nolan',
    'Nolanville',
    'Nome',
    'Normangee',
    'North Branch',
    'North Houston',
    'North Richland Hills',
    'Northlake',
    'Nursery',
    'Oak Point',
    'Oak Ridge North',
    'Oakland',
    'Oakwood',
    'Odem',
    'Odessa',
    "O'donnell",
    'Oilton',
    'Oklahoma',
    'Old Glory',
    'Old Ocean',
    'Olmito',
    'Olney',
    'Olton',
    'Onalaska',
    'Orange',
    'Ore City',
    'Orla',
    'Overton',
    'Ovilla',
    'Oyster Creek',
    'Ozona',
    'Paducah',
    'Palacios',
    'Palestine',
    'Palmer',
    'Palmview',
    'Palo Pinto',
    'Pampa',
    'Panhandle, Carson',
    'Panhandle, Cass',
    'Pantego',
    'Paradise',
    'Paris',
    'Pasadena',
    'Pattison',
    'Pearland',
    'Pearsall',
    'Pecos',
    'Pendleton',
    'Penitas',
    'Penwell',
    'Perryton',
    'Petersburg',
    'Petrolia',
    'Petty',
    'Pflugerville',
    'Pharr',
    'Phoenix',
    'Pilot Point',
    'Pinehurst',
    'Pittsburg',
    'Placedo',
    'Plains',
    'Plainview',
    'Plano',
    'Plantersville',
    'Pleasanton',
    'Point',
    'Point Comfort',
    'Ponder',
    'Poolville',
    'Port Alto',
    'Port Aransas',
    'Port Arthur',
    'Port Bolivar',
    'Port Isabel',
    'Port Lavaca',
    'Port Neches',
    'Porter',
    'Portland',
    'Post',
    'Poteet',
    'Pottsboro',
    'Powderly',
    'Prairie Hill, Limestone',
    'Prairie Hill, Washington',
    'Prairie View',
    'Premont',
    'Presidio',
    'Princeton',
    'Progreso',
    'Prosper',
    'Puerto Rico',
    'Quanah',
    'Queen City',
    'Quintana',
    'Quitaque',
    'Quitman',
    'Ralls',
    'Ranger',
    'Raymondville',
    'Raywood',
    'Reagan',
    'Red Bluff',
    'Red Oak',
    'Red River Ad',
    'Red Rock',
    'Redwater',
    'Refugio',
    'Reklaw',
    'Reno',
    'Rhome',
    'Rice',
    'Richardson',
    'Richland Hills',
    'Richmond',
    'Riesel',
    'Rio Grande City',
    'Rio Hondo',
    'Roanoke',
    'Robstown',
    'Rochester',
    'Rockdale',
    'Rockport',
    'Rocksprings',
    'Rockwall',
    'Rogers',
    'Roma',
    'Ropesville',
    'Rosanky',
    'Roscoe',
    'Rose City',
    'Rosebud',
    'Rosenberg',
    'Rosharon',
    'Rosser',
    'Rossville',
    'Rotan',
    'Round Rock',
    'Round Top',
    'Rowena',
    'Rowlett',
    'Royse City',
    'Rule',
    'Rusk',
    'Rye',
    'Sabinal',
    'Sabine',
    'Sabine Pass',
    'Sachse',
    'Saginaw',
    'Saint Jo',
    'Salado',
    'Salt Flat',
    'Saltillo',
    'San Angelo',
    'San Antonio',
    'San Augustine',
    'San Benito',
    'San Diego',
    'San Felipe',
    'San Gabriel',
    'San Juan',
    'San Leon',
    'San Marcos',
    'San Saba',
    'San Ygnacio',
    'Sand Hills',
    'Sanderson',
    'Sanger',
    'Santa Anna',
    'Santa Elena',
    'Santa Fe',
    'Santa Rosa',
    'Santo',
    'Sarita',
    'Savoy',
    'Schertz',
    'Schulenburg',
    'Scottsville',
    'Seabrook',
    'Seadrift',
    'Seagoville',
    'Seagraves',
    'Sealy',
    'Sebastian',
    'Seguin',
    'Selma',
    'Seminole',
    'Sequin',
    'Seven Points',
    'Seymour',
    'Shallowater',
    'Shamrock, Wheeler',
    'Shelbyville',
    'Sheldon',
    'Shenandoah',
    'Shepherd',
    'Sheppard',
    'Sherman',
    'Sherman-Denison',
    'Shiner',
    'Shoreacres',
    'Sierra Blanca',
    'Silsbee',
    'Silverton',
    'Simonton',
    'Sinton',
    'Slaton',
    'Smithville, Bastrop',
    'Snyder',
    'Socorro',
    'Somerset',
    'Sonora',
    'South Houston',
    'South Padre Island',
    'Southlake',
    'Spearman',
    'Spicewood',
    'Splendora',
    'Spring',
    'Spring Branch',
    'Spring Valley',
    'Springlake',
    'Springtown',
    'Spur',
    'Spurger',
    'Stafford',
    'Stamford',
    'Stanton',
    'Star',
    'Stephenville',
    'Sterling City',
    'Stockdale',
    'Stonewall',
    'Strang',
    'Stratford',
    'Sudan',
    'Sugar Land',
    'Sullivan City',
    'Sulphur Springs',
    'Summerfield',
    'Sumner',
    'Sunnyvale',
    'Sunray',
    'Sunset Valley',
    'Sweeny',
    'Sweetwater',
    'Taft',
    'Tahoka',
    'Talco',
    'Tarzan',
    'Tatum',
    'Taylor',
    'Teague',
    'Temple',
    'Tenaha',
    'Tennessee Colony',
    'Terlingua',
    'Terrell',
    'Texarkana',
    'Texas City',
    'The Colony',
    'The Hills',
    'The Woodlands',
    'Thompsons',
    'Thorndale',
    'Thrall',
    'Three Rivers',
    'Tilden',
    'Timpson',
    'Tioga',
    'Tokio',
    'Tolar',
    'Tom Bean',
    'Tomball',
    'Tornillo',
    'Tow',
    'Trenton',
    'Trinidad',
    'Trinity',
    'Trophy Club',
    'Troup',
    'Troy',
    'Tulia',
    'Turkey',
    'Tye',
    'Tyler',
    'Universal City',
    'University Park',
    'Uvalde',
    'Valentine',
    'Valley View',
    'Van',
    'Van Alstyne',
    'Van Horn',
    'Vega',
    'Venus',
    'Vernon',
    'Vickery',
    'Victoria',
    'Victory City',
    'Vidor',
    'Vinton',
    'Viola',
    'Voca',
    'Von Ormy',
    'Waco',
    'Wadsworth',
    'Waelder',
    'Walburg',
    'Wall',
    'Waller',
    'Wallisville',
    'Walnut Springs',
    'Warrenton',
    'Waskom',
    'Waverly',
    'Waxahachie',
    'Weatherford',
    'Webster',
    'Webster, Harris',
    'Weesatche',
    'Weimar',
    'Wellington',
    'Wellman',
    'Weslaco',
    'West',
    'West Columbia',
    'West Lake Hills',
    'West Orange',
    'Westlake',
    'Westminster',
    'Wharton',
    'White Oak',
    'White Settlement',
    'Whitehouse',
    'Whitesboro',
    'Whitewright',
    'Whitney',
    'Whitsett',
    'Wichita Falls',
    'Wickett',
    'Williams',
    'Willis',
    'Willow Park',
    'Wills Point',
    'Wilmer',
    'Wilson',
    'Wimberley',
    'Windthorst',
    'Winfield',
    'Wingate',
    'Wink',
    'Winnie',
    'Winnsboro',
    'Winona',
    'Winters',
    'Wolfe City',
    'Wolfforth',
    'Woodlawn',
    'Woodsboro',
    'Woodville',
    'Woodway',
    'Wortham',
    'Wright City',
    'Wylie',
    'Yoakum',
    'Yorktown',
    'Zapata',
    'Zita'
  ],
  UT: [
    'Alpine',
    'Alta',
    'Altamont',
    'American Fork',
    'Aragonite',
    'Aurora',
    'Axtell',
    'Bear River City',
    'Beaver',
    'Beryl',
    'Bingham Canyon',
    'Blanding',
    'Bluff',
    'Bluffdale',
    'Bonanza',
    'Bountiful',
    'Brian Head',
    'Brigham City',
    'Bryce',
    'Bullfrog Basin',
    'Carbonville',
    'Castle Dale',
    'Cedar City',
    'Cedar Hills',
    'Centerville',
    'Charleston',
    'Circleville',
    'Clearfield',
    'Cleveland',
    'Clifton',
    'Clinton',
    'Coalville',
    'Collinston',
    'Copperton',
    'Corinne',
    'Cottonwood Heights',
    'Delta',
    'Deweyville',
    'Draper',
    'Dugway',
    'Eagle Mountain',
    'Eden',
    'Elberta',
    'Enterprise, Washington',
    'Ephraim',
    'Erda',
    'Eureka',
    'Farmington',
    'Farr West',
    'Fillmore',
    'Fremont Junction',
    'Garland',
    'Glendale',
    'Grantsville',
    'Green River',
    'Hanksville',
    'Heber City',
    'Henefer',
    'Herriman',
    'Highland',
    'Hildale',
    'Hill Air Force Base',
    'Holden',
    'Holladay',
    'Honeyville',
    'Hooper',
    'Huntington',
    'Huntsville',
    'Hurricane',
    'Hyde Park',
    'Hyrum',
    'Ivins',
    'Kamas',
    'Kanab',
    'Kanosh',
    'Kaysville',
    'Kearns',
    'La Verkin',
    'Lake Point',
    'Lapoint',
    'Layton',
    'Leamington',
    'Leeds',
    'Lehi',
    'Levan',
    'Lewiston',
    'Lindon',
    'Logan',
    'Magna',
    'Manti',
    'Mapleton',
    'Mendon',
    'Midvale',
    'Midway',
    'Milford',
    'Minersville',
    'Moab',
    'Mona',
    'Monticello',
    'Monument Valley Gldngs',
    'Morgan',
    'Moroni',
    'Mount Pleasant',
    'Mountain Green',
    'Murray',
    'Myton',
    'Nephi',
    'Newcastle',
    'Nibley',
    'North Logan',
    'North Ogden',
    'North Salt Lake',
    'Oakley',
    'Ogden',
    'Orangeville',
    'Orem',
    'Panguitch',
    'Park City',
    'Parowan',
    'Payson',
    'Petersboro',
    'Pleasant Grove',
    'Pleasant View, Weber',
    'Plymouth',
    'Price',
    'Promontory',
    'Providence',
    'Provo',
    'Randlett',
    'Redmond',
    'Richfield',
    'Richmond',
    'Riverdale',
    'Riverton',
    'Roosevelt',
    'Rowley',
    'Roy',
    'Rush Valley',
    'Saint George',
    'Salem',
    'Salina',
    'Salt Lake City',
    'Sandy',
    'Santa Clara',
    'Santaquin',
    'Saratoga Springs',
    'Scofield',
    'Sigurd',
    'Skull Valley',
    'Smithfield',
    'Snowbird',
    'South Jordan',
    'South Ogden',
    'South Salt Lake',
    'Spanish Fork',
    'Spring City',
    'Springdale',
    'Springville',
    'Stansbury park',
    'Sundance',
    'Sunset',
    'Syracuse',
    'Taylorsville',
    'Tooele',
    'Toquerville',
    'Torrey',
    'Tremonton',
    'Trenton',
    'Vernal',
    'Veyo',
    'Vineyard',
    'Virgin',
    'Washington',
    'Washington Terrace',
    'Wellington',
    'Wendover',
    'West Bountiful',
    'West Haven',
    'West Jordan',
    'West Valley City',
    'Willard',
    'Woodland Hills',
    'Woods Cross'
  ],
  VA: [
    'Abingdon',
    'Accomac',
    'Afton',
    'Aldie',
    'Alexandria',
    'Altavista',
    'Alton',
    'Amelia',
    'Amherst',
    'Amissville',
    'Ampthill',
    'Annandale',
    'Appalachia',
    'Appomattox',
    'Ararat',
    'Arlington',
    'Aroda',
    'Arrington',
    'Arvonia',
    'Ashburn',
    'Ashland',
    'Atkins',
    'Atlantic',
    'Augusta Springs',
    'Austinville',
    'Axton',
    'Aylett',
    'Bacova',
    'Barboursville',
    'Bassett',
    'Bastian',
    'Basye',
    'Bealeton',
    'Beaverdam',
    'Bedford',
    'Bena',
    'Bent Mountain',
    'Berryville',
    'Big Island',
    'Big Rock',
    'Big Stone Gap',
    'Blacksburg',
    'Blackstone',
    'Blairs',
    'Bland',
    'Bloxom',
    'Blue Ridge',
    'Bluefield',
    'Bolington',
    'Boones Mill',
    'Boston',
    'Botetourt',
    'Bowling Green',
    'Boyce',
    'Boydton',
    'Boykins',
    'Bracey',
    'Brandy Station',
    'Breaks',
    'Bridgewater',
    'Bristol',
    'Bristow',
    'Broad Run',
    'Broadlands',
    'Broadway',
    'Brodnax',
    'Brookneal',
    'Buchanan',
    'Buena Vista',
    'Buffalo Junction',
    'Bumpass',
    'Burke',
    'Burkeville',
    'Callands',
    'Callaway',
    'Calverton',
    'Cana',
    'Cape Charles',
    'Cape Henry',
    'Carrollton',
    'Castlewood',
    'Catlett',
    'Cedar Bluff',
    'Center Cross',
    'Centreville',
    'Champlain',
    'Chantilly',
    'Charles City',
    'Charlottesville',
    'Chase City',
    'Chatham',
    'Cheriton',
    'Chesapeake',
    'Chester',
    'Chesterfield',
    'Chilhowie',
    'Chincoteague',
    'Christiansburg',
    'Chuckatuck',
    'Churchville',
    'Claremont',
    'Clarksville',
    'Claudville',
    'Clear Brook',
    'Cleveland',
    'Clifton',
    'Clifton Forge',
    'Clintwood',
    'Clover, Halifax',
    'Cloverdale',
    'Cobbs Creek',
    'Coeburn',
    'Collinsville',
    'Colonial Heights',
    'Columbia',
    'Concord',
    'Courtland',
    'Covesville',
    'Covington',
    'Crewe',
    'Crimora',
    'Cross Junction',
    'Crozet',
    'Crystal Hill',
    'Culpeper',
    'Cumberland',
    'Dahlgren',
    'Dale City',
    'Daleville',
    'Damascus',
    'Danville',
    'Davenport',
    'Dayton',
    'Delaplane',
    'Dendron',
    'DeWitt',
    'Diggs',
    'Dillwyn',
    'Dinwiddie',
    'Disputanta',
    'Doswell',
    'Dry Fork, Pittsylvania',
    'Dublin',
    'Duffield',
    'Dugspur',
    'Dulles',
    'Dulles Int Apt/Washington',
    'Dumfries',
    'Eagle Rock, Botetourt',
    'Earlysville',
    'East Danville',
    'Edinburg',
    'Elkton',
    'Elkwood',
    'Elliston',
    'Emporia',
    'Esmont',
    'Evington',
    'Ewing',
    'Exmore',
    'Faber',
    'Fairfax',
    'Fairfax Station',
    'Fairfield',
    'Falls Church',
    'Falls Mills',
    'Falmouth',
    'Farmville',
    'Farnham',
    'Fentress',
    'Fieldale',
    'Fincastle',
    'Fishersville',
    'Flint Hill',
    'Floyd',
    'Forest',
    'Fork Union',
    'Fort Belvoir',
    'Fort Chiswell',
    'Fort Eustis',
    'Fort Lee',
    'Franconia',
    'Franklin',
    'Fredericksburg',
    'Free Union',
    'Freeman',
    'Front Royal',
    'Fulks Run',
    'Gainesville',
    'Galax',
    'Gate City',
    'Glade Spring',
    'Gladstone',
    'Gladys',
    'Glasgow',
    'Glen Allen',
    'Gloucester',
    'Goochland',
    'Goode',
    'Gordonsville',
    'Gore',
    'Goshen',
    'Grafton',
    'Great Falls',
    'Green Bay',
    'Greenwich',
    'Gretna',
    'Grottoes',
    'Grundy',
    'Gum Spring',
    'Hadensville',
    'Halifax',
    'Hallwood',
    'Hamilton',
    'Hampton',
    'Hampton Roads Pt',
    'Hampton-Williamsburg-Newport News Apt',
    'Hanover',
    'Harpersville',
    'Harrisburg',
    'Harrisonburg',
    'Havelock',
    'Hayes',
    'Haymarket',
    'Heathsville',
    'Henrico',
    'Henry',
    'Herndon',
    'Hillsboro',
    'Hillsville',
    'Hinton',
    'Hiwassee',
    'Hopewell',
    'Hot Springs',
    'Hume',
    'Huntington',
    'Huntly',
    'Hurt',
    'Independence',
    'Ingleside',
    'Irvington',
    'Ivy',
    'Jamaica',
    'Jamesville',
    'Jeffersonton',
    'Jetersville',
    'Keller',
    'Kenbridge',
    'Keokee',
    'Keswick',
    'Keysville',
    'Kilmanrock',
    'Kilmarnock',
    'King George',
    'King William',
    'La Crosse',
    'Ladysmith',
    'Laneview',
    'Langley',
    'Lawrenceville',
    'Lawyers',
    'Lebanon',
    'Leesburg',
    'Lexington',
    'Lightfoot',
    'Lignum',
    'Linville',
    'Lively',
    'Locust Hill, Middlesex',
    'Locustville',
    'Long Island',
    'Lorton',
    'Louisa',
    'Lovettsville',
    'Lovingston',
    'Low Moor',
    'Lowmoor',
    'Luray',
    'Lynchburg',
    'Lyndhurst',
    'Lynnhaven Roads',
    'Madison',
    'Madison Heights',
    'Maidens',
    'Manakin',
    'Manakin-Sabot',
    'Manassas',
    'Manassas Park',
    'Manquin',
    'Mappsville',
    'Marion',
    'Markham',
    'Marshall',
    'Martinsville',
    'Mattaponi',
    'Max Meadows',
    'Mcgaheysville',
    'McKenney',
    'McLean/Washington, D.C.',
    'Meadows of Dan',
    'Mechanicsville',
    'Melfa',
    'Mendota',
    'Merrifield',
    'Middleburg',
    'Middletown',
    'Midland',
    'Midlothian',
    'Milford',
    'Millwood',
    'Mineral',
    'Moneta',
    'Monroe',
    'Monterey',
    'Montpelier',
    'Montross',
    'Montvale',
    'Morattico',
    'Mount Crawford',
    'Mount Jackson',
    'Mouth of Wilson',
    'Narrows',
    'Nassawadox',
    'Natural Bridge',
    'Natural Bridge Station',
    'Nellysford',
    'Nelsonia',
    'New Baltimore',
    'New Castle',
    'New Church',
    'New Point',
    'Newbern',
    'Newington',
    'Newmarket',
    'Newport',
    'Newport News',
    'Newport News-Hampton-Williamsburg Apt',
    'Nickelsville',
    'Nokesville',
    'Norcross',
    'Norfolk',
    'North Tazewell',
    'Norton',
    'Oak Grove',
    'Oak Hill, Fairfax',
    'Oak Hill, Pittsylvania',
    'Oakton',
    'Oakwood, Buchanan',
    'Oceana',
    'Oilville',
    'Orange',
    'Ordinary',
    'Painter',
    'Palmyra',
    'Pamplin',
    'Parker',
    'Parksley',
    'Patrick Springs',
    'Pearisburg',
    'Pennington Gap',
    'Petersburg',
    'Piney River',
    'Poquoson',
    'Port Republic',
    'Portsmouth',
    'Potomac Falls',
    'Pound',
    'Pounding Mill',
    'Powhatan',
    'Prince George',
    'Prospect',
    'Providence Forge',
    'Pulaski',
    'Purcellville',
    'Quantico',
    'Quicksburg',
    'Quinton',
    'Radford',
    'Rapidan',
    'Raven',
    'Rawlings',
    'Red Oak',
    'Reedville',
    'Remington',
    'Rescue',
    'Reston',
    'Rice',
    'Rich Creek',
    'Richlands',
    'Richmond',
    'Ridgeway, Henry',
    'Riner',
    'Ringgold',
    'Ripplemead',
    'Riverville',
    'Rixeyville',
    'Roanoke',
    'Rochelle',
    'Rock Enon Springs',
    'Rockville',
    'Rocky Mount',
    'Roseland',
    'Rowe',
    'Ruckersville',
    'Rural Retreat',
    'Rustburg',
    'Ruther Glen',
    'Saint Paul',
    'Salem',
    'Saltville',
    'Saluda',
    'Sandston',
    'Schuyler',
    'Scottsburg',
    'Scottsville',
    'Sedley',
    'Severn',
    "Sewell's Point",
    'Shackelefords',
    'Shacklefords',
    'Shenandoah',
    'Skippers',
    'Smithfield',
    'South Boston',
    'South Hill',
    'Spencer',
    'Sperryville',
    'Spotsylvania',
    'Spring Grove',
    'Springfield',
    'Stafford',
    'Stanley',
    'Stanleytown',
    'Statesville',
    'Staunton',
    'Stephens',
    'Sterling',
    'Stevensburg',
    'Stony Creek',
    'Strasburg',
    'Stuart',
    'Stuarts Draft',
    'Suffolk',
    'Surry',
    'Sutherland',
    'Sutherlin',
    'Swoope',
    'Swords Creek',
    'Tabb',
    'Tappahannock',
    'Tasley',
    'Tazewell',
    'Temperanceville',
    'The Plains',
    'Timberville',
    'Toano',
    'Topping',
    'Trevilians',
    'Triangle',
    'Troutville',
    'Troy',
    'Tyro',
    'Tysons Corner',
    'Union Hall',
    'Upperville',
    'Vansant',
    'Verona',
    'Victoria',
    'Vienna',
    'Vint Hill Farms',
    'Vinton',
    'Viola',
    'Virginia Beach',
    'Virginia City',
    'Wake',
    'Wakefield',
    'Walkerton',
    'Warm Springs',
    'Warrenton',
    'Warsaw',
    'Washington',
    'Waverly',
    'Waynesboro',
    'Weber City',
    'West Point',
    'Weyers Cave',
    'White Post',
    'White Stone',
    'Wicomico Church',
    'Williamsburg',
    'Williamsburg-Newport News-Hampton Apt',
    'Willston',
    'Wilmington',
    'Winchester',
    'Windsor',
    'Wintergreen',
    'Wirtz',
    'Wise',
    'Woodbridge',
    'Woodford',
    'Woodstock',
    'Wytheville',
    'Yale',
    'Yorktown',
    'Dunn Loring',
    'Aquia Harbour'
  ],
  VT: [
    'Albany',
    'Alburg',
    'Arlington',
    'Ascutney',
    'Bakersfield',
    'Barnard',
    'Barnet',
    'Barre',
    'Barre-Montpelier Apt',
    'Barton',
    'Beecher Falls',
    'Bellows Falls',
    'Belvidere Center',
    'Bennington',
    'Benson',
    'Berlin Corners',
    'Bethel',
    'Bondville',
    'Bradford',
    'Brandon',
    'Brattleboro',
    'Bridgewater',
    'Bridgewater Corners',
    'Bridport',
    'Bristol',
    'Brookfield',
    'Burlington',
    'Cabot',
    'Calais',
    'Cambridge',
    'Cambridgeport',
    'Canaan',
    'Castleton',
    'Cavendish',
    'Charlotte',
    'Chelsea',
    'Chester',
    'Chester Depot',
    'Chittenden',
    'Colchester',
    'Concord',
    'Corinth',
    'Cornwall',
    'Coventry',
    'Craftsbury',
    'Danby',
    'Danville',
    'Derby',
    'Derby Center',
    'Derby Line',
    'Dorset',
    'East Alburg',
    'East Burke',
    'East Dorset',
    'East Haven',
    'East Montpelier',
    'East Randolph',
    'Eden',
    'Elmore',
    'Enosburg Center',
    'Enosburg Falls',
    'Essex',
    'Essex Junction',
    'Fair Haven',
    'Fairfax',
    'Fairfield',
    'Fairlee',
    'Ferrisburgh',
    'Florence',
    'Forest Dale',
    'Franklin',
    'Georgia',
    'Gilman',
    'Grafton',
    'Granby',
    'Grand Isle',
    'Graniteville',
    'Granville',
    'Greensboro',
    'Greensboro Bend',
    'Groton',
    'Guildhall',
    'Hancock',
    'Hardwick',
    'Hartford',
    'Hartland',
    'Highgate Center',
    'Highgate Springs',
    'Hinesburg',
    'Hortonia',
    'Huntington',
    'Hyde Park',
    'Irasburg',
    'Island Pond',
    'Isle la Motte',
    'Jamaica',
    'Jay',
    'Jeffersonville',
    'Jericho',
    'Johnson',
    'Jonesville',
    'Killington',
    'Killington Village',
    'Landgrove',
    'Lebanon-White River-Hanover Apt',
    'Londonderry',
    'Lowell',
    'Lower Waterford',
    'Ludlow',
    'Lunenburg',
    'Lyndon',
    'Lyndonville',
    'Manchester',
    'Manchester Center',
    'Marlboro',
    'Marshfield',
    'Middlebury',
    'Middletown Springs',
    'Milton',
    'Monkton',
    'Montgomery',
    'Montgomery Center',
    'Montpelier',
    'Montpelier-Barre Apt',
    'Moretown',
    'Morgan',
    'Morrisville',
    'Mount Holly',
    'New Haven',
    'Newbury',
    'Newfane',
    'Newport',
    'North Bennington',
    'North Clarendon',
    'North Ferrisburgh',
    'North Hartland',
    'North Hero',
    'North Springfield',
    'North Troy',
    'Northfield',
    'Norton',
    'Norwich',
    'Orange',
    'Orleans',
    'Orwell',
    'Pawlet',
    'Peacham',
    'Perkinsville',
    'Peru',
    'Pittsfield',
    'Pittsford',
    'Plainfield',
    'Plymouth',
    'Post Mills',
    'Poultney',
    'Pownal',
    'Proctor',
    'Proctorsville',
    'Putney',
    'Quechee',
    'Randolph',
    'Randolph Center',
    'Reading',
    'Readsboro',
    'Richford',
    'Richmond',
    'Ripton',
    'Rochester',
    'Rockingham',
    'Roxbury',
    'Royalton',
    'Rupert',
    'Rutland',
    'Ryegate',
    'S.Burlington',
    'Saint Albans',
    'Saint Albans Bay',
    'Saint Johnsbury',
    'Saint Johnsbury Center',
    'Salisbury',
    'Searsburg',
    'Shaftsbury',
    'Sharon',
    'Sheffield',
    'Shelburne',
    'Sheldon',
    'Sheldon Springs',
    'Shoreham',
    'South Burlington',
    'South Hero',
    'South Londonderry',
    'South Royalton',
    'Springfield',
    'Stain George',
    'Stamford',
    'Starksboro',
    'Stockbridge',
    'Stowe',
    'Strafford',
    'Stratton Mountain',
    'Sunderland',
    'Sutton',
    'Swanton',
    'Taftsville',
    'Thetford',
    'Topsham',
    'Townshend',
    'Troy',
    'Tunbridge',
    'Underhill',
    'Vergennes',
    'Vernon',
    'Vershire',
    'Waitsfield',
    'Wallingford',
    'Wardsboro',
    'Warren',
    'Washington',
    'Waterbury',
    'Waterford',
    'Websterville',
    'Wells River',
    'West Burke',
    'West Dover',
    'West Fairlee',
    'West Halifax',
    'West Haven',
    'West Newbury',
    'West Rutland',
    'West Windsor',
    'Westfield',
    'Westford',
    'Westminster',
    'Weston',
    'Weybridge',
    'White River Junction',
    'White River-Hanover-Lebanon Apt',
    'Whiting',
    'Whitingham',
    'Wilder',
    'Williamstown',
    'Williston',
    'Wilmington',
    'Windsor',
    'Winooski',
    'Wolcott',
    'Woodbury',
    'Woodstock',
    'Worcester',
    ''
  ],
  WA: [
    'Aberdeen',
    'Acme',
    'Airway Heights',
    'Algona',
    'Allyn',
    'Amanda Park',
    'Amboy',
    'Anacortes',
    'Arlington',
    'Asotin',
    'Auburn',
    'Bainbridge Island',
    'Ballard/Seattle',
    'Basin City',
    'Battle Ground',
    'Bay Center',
    'Beaver',
    'Belfair',
    'Bellevue',
    'Bellingham',
    'Benton City',
    'Beverly',
    'Bingen',
    'Black Diamond',
    'Blaine',
    'Blakely Island',
    'Boeing Field Apt',
    'Bonney Lake',
    'Bothell',
    'Boundary',
    'Bow',
    'Bremerton',
    'Brewster',
    'Brier',
    'Brinnon',
    'Brush Prairie',
    'Buckley',
    'Buena',
    'Burbank',
    'Burien',
    'Burlington',
    'Camano Is.',
    'Camas',
    'Carson',
    'Cashmere',
    'Castle Rock',
    'Cathcart',
    'Cathlamet',
    'Center Island',
    'Centralia',
    'Chattaroy',
    'Chehalis',
    'Chelan',
    'Chelan Falls',
    'Cheney',
    'Cherry Point',
    'Chewelah',
    'Chimacum',
    'Chinook',
    'Clallam Bay',
    'Clarkston',
    'Cle Elum',
    'Clinton',
    'Colbert',
    'Colfax',
    'College Place',
    'Colville',
    'Concrete',
    'Connell',
    'Cosmopolis',
    'Coulee Dam',
    'Coupeville',
    'Covington',
    'Cowiche',
    'Crane Island',
    'Crystal Mountain',
    'Custer',
    'Danville',
    'Darrington',
    'Davenport',
    'Dayton',
    'Decatur Island',
    'Deer Harbor',
    'Deming',
    'Des Moines',
    'Diamond',
    'Du Pont',
    'Dupont',
    'Duvall',
    'East Greenwich',
    'East Wenatchee',
    'Easton',
    'Eastsound',
    'Eatonville',
    'Edgewood',
    'Edmonds',
    'Ellensburg',
    'Ellsworth',
    'Elma',
    'Eltopia',
    'Entiat',
    'Enumclaw',
    'Ephrata',
    'Everett',
    'Evergreen',
    'Everson',
    'Fairchild Air Force Base',
    'Fairfield',
    'Fall City',
    'Farmington',
    'Federal Way',
    'Felida',
    'Ferndale',
    'Fife',
    'Fircrest',
    'Fisher',
    'Five Corners',
    'Ford',
    'Fort Lewis',
    'Fox Island',
    'Frederickson',
    'Freeland',
    'Friday Harbor',
    'Frontier',
    'Garfield',
    'George',
    'Georgetown',
    'Gig Harbor',
    'Gleed',
    'Gold Bar',
    'Goldendale',
    'Goose Prairie',
    'Graham',
    'Grand Coulee',
    'Grandview',
    'Granger',
    'Granite Falls',
    'Grapeview',
    'Grays Harbor City',
    'Grays River',
    'Greenacres',
    'Hamilton',
    'Harrah',
    'Hazel Dell',
    'Highlands',
    'Hillyard',
    'Hoodsport',
    'Hoquiam',
    'Ilwaco',
    'Indianola',
    'Interbay',
    'Ione',
    'Issaquah',
    'Kalama',
    'Kelso',
    'Kenmore Air Harbor',
    'Kenmore/Seattle',
    'Kennewick',
    'Kent',
    'Kettle Falls',
    'Keyport',
    'Kingston',
    'Kirkland',
    'Kittitas',
    'La Center',
    'La Conner',
    'Lacey',
    'Lake Bay',
    'Lake Forest Park',
    'Lake Stevens',
    'Lakewood',
    'Langley',
    'Laurier',
    'Leavenworth',
    'Liberty Lake',
    'Lilliwaup',
    'Lincoln',
    'Lind',
    'Long Beach',
    'Longview',
    'Lopez Island',
    'Lowden',
    'Lyle',
    'Lynden',
    'Lynnwood',
    'Mabton',
    'Madison Park',
    'Malaga',
    'Malden',
    'Malott',
    'Maltby',
    'Manchester',
    'Manson',
    'Maple Valley',
    'Marysville',
    'Mattawa',
    'McChord Air Force Base',
    'McCleary',
    'McMillin',
    'Mead',
    'Medina',
    'Mercer Island',
    'Mesa',
    'Metaline Falls',
    'Mica',
    'Mill Creek',
    'Milton',
    'Minnehaha',
    'Monroe',
    'Montesano',
    'Moorlands',
    'Morton',
    'Moses Lake',
    'Mossyrock',
    'Mount Vernon',
    'Mountlake Terrace',
    'Moxee City',
    'Mukilteo',
    'Naches',
    'Neah Bay',
    'Nespelem',
    'Newcastle',
    'Newman Lake',
    'Newport',
    'Nighthawk',
    'Nine Mile Falls',
    'Nooksack',
    'North Bend',
    'North Bonneville',
    'North City',
    'Oak Harbor',
    'Oakesdale',
    'Oakville',
    'Ocean Shores',
    'Odessa',
    'Okanogan',
    'Olympia',
    'Omak',
    'Onalaska',
    'Orcas',
    'Orchards',
    'Orondo',
    'Oroville',
    'Orting',
    'Othello',
    'Otis Orchards',
    'Outlook',
    'Pacific',
    'Palouse',
    'Parker',
    'Parkwater-Spokane',
    'Pasco',
    'Pateros',
    'Paterson',
    'Patterson',
    'Peshastin',
    'Plymouth',
    'Point Roberts',
    'Point Wells',
    'Pomeroy',
    'Port Angeles',
    'Port Blakely',
    'Port Gamble',
    'Port Hadlock',
    'Port Ludlow',
    'Port Madison',
    'Port Orchard',
    'Port Townsend',
    'Poulsbo',
    'Prescott',
    'Preston',
    'Prosser',
    'Puget Sound',
    'Pullman',
    'Puyallup',
    'Quillayute',
    'Quincy',
    'Rainier',
    'Randle',
    'Ravensdale',
    'Raymond',
    'Redmond',
    'Renton',
    'Republic',
    'Retsil',
    'Richland',
    'Richmond Beach',
    'Richmond Highlands',
    'Ridgefield',
    'Ritzville',
    'Roche Harbor',
    'Rochester',
    'Rock Island',
    'Rockford',
    'Rockport',
    'Roosevelt',
    'Rosalia',
    'Rosario',
    'Roy',
    'Royal City',
    'Russell',
    'Sammamish',
    'Satsop',
    'Seabeck',
    'Seatac',
    'Seattle',
    'Seaview',
    'Sedro Woolley',
    'Selah',
    'Sequim',
    'Shelton',
    'Shoreline',
    'Silverdale',
    'Skykomish',
    'Smokey Point',
    'Snohomish',
    'Snoqualmie',
    'Snoqualmie Pass',
    'Soap Lake',
    'South Bend',
    'South Prairie',
    'Spanaway',
    'Spangle',
    'Spokane',
    'Spokane Valley',
    'Sprague',
    'Stanwood',
    'Startup',
    'Steilacoom',
    'Stevenson',
    'Stuart Island',
    'Sultan',
    'Sumas',
    'Sumner',
    'Sunnyside',
    'Suquamish',
    'Tacoma',
    'Tahuya',
    'Tatoosh Island',
    'Tekoa',
    'Tenino',
    'Thorp',
    'Toledo',
    'Tonasket',
    'Toppenish',
    'Touchet',
    'Tri-Cities',
    'Trout Lake',
    'Tukwila',
    'Tulalip',
    'Tumwater',
    'Twisp',
    'Underwood',
    'Union',
    'Union Gap',
    'University Place',
    'Usk',
    'Valleyford',
    'Vancouver',
    'Vashon',
    'Vashon Island',
    'Villard Junction',
    'Waitsburg',
    'Waldron Island',
    'Walla Walla',
    'Wallula',
    'Wapato',
    'Warden',
    'Washougal',
    'Waterville',
    'Wauna',
    'Wenatchee',
    'West Richland',
    'West Sound',
    'Westport',
    'Whidbey Island',
    'White Salmon',
    'White Swan',
    'Willapa',
    'Wilma',
    'Winchester',
    'Winlock',
    'Woodinville',
    'Woodland',
    'Woodway',
    'Yacolt',
    'Yakima',
    'Yelm',
    'Zillah'
  ],
  WI: [
    'Abbotsford',
    'Abrams',
    'Adams',
    'Adell',
    'Adrian',
    'Albany',
    'Algoma',
    'Allenton',
    'Alma',
    'Almena',
    'Almond',
    'Altoona',
    'Amery',
    'Amherst',
    'Antigo',
    'Appleton',
    'Arbor Vitae',
    'Arcadia',
    'Arena',
    'Argyle',
    'Arlington',
    'Arpin',
    'Ashippun',
    'Ashland',
    'Ashwaubenon',
    'Athens',
    'Auburndale',
    'Augusta',
    'Avoca',
    'Babcock',
    'Baldwin',
    'Balsam Lake',
    'Bancroft',
    'Bangor',
    'Baraboo',
    'Barneveld',
    'Barron',
    'Bay City',
    'Bay View',
    'Bayfield',
    'Bayside',
    'Bear Creek',
    'Beaver Dam',
    'Beetown',
    'Belgium',
    'Belleville',
    'Belmont',
    'Beloit',
    'Benton',
    'Berlin',
    'Big Bend',
    'Binghamton',
    'Birchwood',
    'Birnamwood',
    'Biron',
    'Black Creek',
    'Black River Falls',
    'Blaine',
    'Blair',
    'Bloomer',
    'Blue Mounds',
    'Blue River',
    'Bonduel',
    'Boscobel',
    'Bowler',
    'Boyceville',
    'Boyd',
    'Branch',
    'Brandon',
    'Brillion',
    'Bristol',
    'Brodhead',
    'Brokaw',
    'Brookfield',
    'Brooklyn',
    'Brown Deer',
    'Brownsville',
    'Bruce',
    'Bryant',
    'Burlington',
    'Butler',
    'Butternut',
    'Caledonia',
    'Cambria',
    'Cambridge',
    'Cameron',
    'Camp Douglas',
    'Campbellsport',
    'Caroline',
    'Casco',
    'Cashton',
    'Catawba',
    'Cedar Grove',
    'Cedarburg',
    'Centuria',
    'Chetek',
    'Chilton',
    'Chippewa Falls',
    'Clam Falls',
    'Clear Lake',
    'Cleveland',
    'Clinton',
    'Clintonville',
    'Clover',
    'Clyman',
    'Cobb',
    'Cochrane',
    'Colby',
    'Coleman',
    'Colfax',
    'Columbia',
    'Columbus',
    'Combined Locks',
    'Coon Valley',
    'Cottage Grove',
    'Crandon',
    'Crivitz',
    'Cross Plains',
    'Cuba City',
    'Cudahy',
    'Cumberland',
    'Curtiss',
    'Custer',
    'Dale',
    'Danbury',
    'Dane',
    'Darien',
    'Darlington',
    'De Forest',
    'De Pere',
    'Deerbrook',
    'Deerfield',
    'Delafield',
    'Delavan',
    'Denmark',
    'Dickeyville',
    'Dodgeville',
    'Dorchester',
    'Dousman',
    'Downing',
    'Downsville',
    'Dresser',
    'Dunbar',
    'Durand',
    'Eagle',
    'Eagle River',
    'East Rio',
    'East Troy',
    'Eastman',
    'Eau Claire',
    'Eden',
    'Edgar',
    'Edgerton',
    'Egg Harbor',
    'Eldorado',
    'Elk Mound',
    'Elkhart Lake',
    'Elkhorn',
    'Ellsworth',
    'Elm Grove',
    'Elmwood',
    'Elmwood, Pierce',
    'Elroy',
    'Embarrass',
    'Endeavor',
    'Ettrick',
    'Evansville',
    'Exeland',
    'Fairwater',
    'Fall Creek',
    'Fall River',
    'Fennimore',
    'Ferryville',
    'Fitchburg',
    'Florence',
    'Fond du Lac',
    'Fontana',
    'Forest Junction',
    'Forestville',
    'Fort Atkinson',
    'Fountain City',
    'Fox Lake',
    'Fox Point',
    'Franklin',
    'Franksville',
    'Frederic',
    'Fredonia',
    'Freisland',
    'Fremont',
    'Friendship',
    'Friesland',
    'Galesville',
    'Gays Mills',
    'Genesee',
    'Genoa',
    'Genoa City',
    'Germantown',
    'Gillett',
    'Gleason',
    'Glen Flora',
    'Glendale',
    'Glidden',
    'Goodman',
    'Grafton',
    'Grand Chute',
    'Grand View',
    'Granton',
    'Grantsburg',
    'Granville',
    'Green Bay',
    'Green Lake',
    'Greenbush',
    'Greendale',
    'Greenfield',
    'Greenville',
    'Greenwood, Clark',
    'Hager City',
    'Hales Corners',
    'Hammond',
    'Hancock',
    'Hartford',
    'Hartland',
    'Haugen',
    'Hawkins',
    'Hayward',
    'Hazelhurst',
    'Hilbert',
    'Hillsboro',
    'Hingham',
    'Hixton',
    'Hobart',
    'Holcombe',
    'Holland',
    'Holmen',
    'Honey Creek',
    'Horicon',
    'Hortonville',
    'Houlton',
    'Howard',
    'Hubertus',
    'Hudson',
    'Humbird',
    'Hurley',
    'Hustisford',
    'Iola',
    'Iron Ridge',
    'Iron River',
    'Ixonia',
    'Jackson',
    'Janesville',
    'Jefferson',
    'Jefferson Junction',
    'Jim Falls',
    'Johnson Creek',
    'Juda',
    'Junction City',
    'Juneau',
    'Kansasville',
    'Kaukauna',
    'Kenosha',
    'Keshena',
    'Kewaskum',
    'Kewaunee',
    'Kiel',
    'Kieler',
    'Kimberly',
    'King',
    'Knapp',
    'Kohler',
    'Krakow',
    'Kronenwetter',
    'La Crosse',
    'La Farge',
    'La Pointe',
    'Lac du Flambeau',
    'Ladysmith',
    'Lake Delton',
    'Lake Geneva',
    'Lake Mills',
    'Lakemills',
    'Lancaster',
    "Land O' Lakes",
    'Lannon',
    'Laona',
    'Lena',
    'Little Chute',
    'Little Suamico',
    'Lodi',
    'Loganville',
    'Lomira',
    'Lone Rock',
    'Lowell',
    'Loyal',
    'Luck',
    'Luxemburg',
    'Lyndon Station',
    'Lyons',
    'Madison',
    'Manawa',
    'Manitowish Waters',
    'Manitowoc',
    'Maple Bluff',
    'Marathon',
    'Marinette',
    'Marion',
    'Markesan',
    'Marshall',
    'Marshfield',
    'Mason, Bayfield',
    'Mattoon',
    'Mauston',
    'Mayville',
    'Mazomanie',
    'McFarland',
    'Medford',
    'Mellen',
    'Menasha',
    'Menomonee Falls',
    'Menomonie',
    'Mequon',
    'Mercer',
    'Merrill',
    'Merrimac',
    'Merton',
    'Middleton',
    'Milladore',
    'Millston',
    'Milltown',
    'Milton',
    'Milwaukee',
    'Mindoro',
    'Mineral Point, Iowa',
    'Minocqua',
    'Minong',
    'Mishicot',
    'Modena',
    'Mondovi',
    'Monona',
    'Monroe',
    'Montello',
    'Montfort',
    'Monticello',
    'Mosel',
    'Mosinee',
    'Mount Horeb',
    'Mount Pleasant',
    'Mountain',
    'Mukwonago',
    'Muscoda',
    'Muskego',
    'Nashotah',
    'Necedah',
    'Neenah',
    'Neillsville',
    'Nekoosa',
    'Nelson',
    'Nelsonville, Portage',
    'New Auburn',
    'New Berlin',
    'New Franken',
    'New Glarus',
    'New Holstein',
    'New Lisbon',
    'New London',
    'New Richmond',
    'Newald',
    'Newton',
    'Niagara',
    'Nichols',
    'North Freedom',
    'North Prairie',
    'Oak Creek',
    'Oakfield',
    'Oconomowoc',
    'Oconto',
    'Oconto Falls',
    'Ogdensburg',
    'Omro',
    'Onalaska',
    'Oneida',
    'Ontario',
    'Oostburg',
    'Oregon',
    'Osceola',
    'Oshkosh',
    'Osseo',
    'Owen',
    'Oxford',
    'Palmyra',
    'Pardeeville',
    'Park Falls',
    'Pearson',
    'Pembine',
    'Pepin',
    'Peshtigo',
    'Pewaukee',
    'Phillips',
    'Pigeon Falls',
    'Pittsville',
    'Plain',
    'Plainfield',
    'Platteville',
    'Pleasant Prairie',
    'Plover',
    'Plymouth',
    'Polar',
    'Port Edwards',
    'Port Washington',
    'Portage',
    'Poskin',
    'Potter',
    'Pound',
    'Powers Lake',
    'Poy Sippi',
    'Poynette',
    'Prairie du Chien',
    'Prairie du Sac',
    'Prentice',
    'Prescott',
    'Princeton',
    'Pulaski',
    'Racine',
    'Randolph',
    'Random Lake',
    'Raymond',
    'Readstown',
    'Redgranite',
    'Reedsburg',
    'Reedsville',
    'Reeseville',
    'Rhinelander',
    'Rice Lake',
    'Richfield',
    'Richland Center',
    'Richmond',
    'Ridgeland',
    'Ridgeway',
    'Ringle',
    'Rio',
    'Ripon',
    'River Falls',
    'River Hills',
    'Roberts',
    'Rochester',
    'Rock Springs',
    'Rockland',
    'Rosendale',
    'Rosholt',
    'Rothschild',
    'Saint Cloud',
    'Saint Croix Falls',
    'Saint Francis',
    'Saint Germain',
    'Saint Nazianz',
    'Sarona',
    'Sauk City',
    'Saukville',
    'Saxeville',
    'Sayner',
    'Schofield',
    'Seymour',
    'Sharon',
    'Shawano',
    'Sheboygan',
    'Sheboygan Falls',
    'Shelby',
    'Sheldon',
    'Shell Lake',
    'Sherwood',
    'Shorewood',
    'Shullsburg',
    'Silver Lake',
    'Siren',
    'Sister Bay',
    'Slinger',
    'Soldiers Grove',
    'Somerset',
    'South Milwaukee',
    'South Wayne',
    'Sparta',
    'Spencer',
    'Spooner',
    'Spring Green',
    'Spring Valley',
    'Stanley',
    'Stevens Point',
    'Stoddard',
    'Stoughton',
    'Stratford',
    'Strum',
    'Sturgeon Bay',
    'Sturtevant',
    'Suamico',
    'Sun Prairie',
    'Superior',
    'Suring',
    'Sussex',
    'Taylor',
    'Theresa',
    'Thiensville',
    'Thorp',
    'Three Lakes',
    'Tigerton',
    'Tomah',
    'Tomahawk',
    'Tony',
    'Trempealeau',
    'Trevor',
    'Tunnel City',
    'Turtle Lake',
    'Twin Lakes',
    'Two Rivers',
    'Union Center',
    'Union Grove',
    'Unity',
    'Valders',
    'Van Buren',
    'Vernon',
    'Verona',
    'Vesper',
    'Viola',
    'Viroqua',
    'Waldo',
    'Wales',
    'Walworth',
    'Warrens',
    'Washburn',
    'Washington Island',
    'Waterford',
    'Waterloo',
    'Watertown',
    'Waukesha',
    'Waunakee',
    'Waupaca',
    'Waupun',
    'Wausau',
    'Wausaukee',
    'Wautoma',
    'Wauwatosa',
    'Waxdale',
    'Webster',
    'West Allis',
    'West Bend',
    'West Milwaukee',
    'West Salem',
    'Westboro',
    'Westby',
    'Westfield',
    'Weston',
    'Weyauwega',
    'Wheatland',
    'White Lake',
    'Whitehall',
    'Whitelaw',
    'Whitewater',
    'Whiting',
    'Williams Bay',
    'Wilmot',
    'Wilson',
    'Wilton',
    'Windsor',
    'Winneconne',
    'Wisconsin Dells',
    'Wisconsin Rapids',
    'Withee',
    'Wittenberg',
    'Wonewoc',
    'Woodruff',
    'Woodville',
    'Wrightstown',
    'Wyoming',
    'Yorkville',
    'Zenda'
  ],
  WV: [
    'Adamston',
    'Adrian',
    'Albright',
    'Alloy',
    'Anmoore',
    'Apple Grove',
    'Ashford',
    'Ashland-Huntington Apt',
    'Athens',
    'Aurora',
    'Barboursville',
    'Barrackville',
    'Bartow',
    'Bayard',
    'Beaver',
    'Beckley',
    'Beech Bottom',
    'Belle',
    'Bellington',
    'Belmont',
    'Bens Run',
    'Benwood',
    'Berkeley Springs',
    'Beverly',
    'Bickmore',
    'Big Creek',
    'Black Betsy',
    'Bluefield',
    'Bradley',
    'Bradshaw',
    'Bridgeport',
    'Bruceton Mills',
    'Buchannon',
    'Buckhannon',
    'Buffalo',
    'Bunker Hill',
    'Cabin Creek',
    'Cairo',
    'Canvas',
    'Carolina',
    'Cedar Grove',
    'Ceredo',
    'Chapmanville',
    'Charles Town',
    'Charleston',
    'Chester',
    'Clarksburg',
    'Clendenin',
    'Colliers',
    'Congo',
    'Cordova',
    'Cowen, Webster',
    'Crab Orchard',
    'Craigsville',
    'Cross Lanes',
    'Culloden',
    'Cyclone',
    'Dailey',
    'Danese',
    'Daniels',
    'Davis',
    'Davisville, Wood',
    'Davy',
    'Delbarton',
    'Delray',
    'Dunbar',
    'Dunmore',
    'Durbin',
    'Eckman',
    'Eleanor',
    'Elizabeth',
    'Elkins',
    'Elkview',
    'Ellenboro',
    'Elmore',
    'Eskdale',
    'Evansville',
    'Fairlea',
    'Fairmont',
    'Fairview',
    'Falling Waters',
    'Farmington',
    'Fayetteville',
    'Fenwick',
    'Flemington',
    'Follansbee',
    'Forest Hill',
    'Fort Ashby',
    'Fort Gay',
    'Frank',
    'Frankford',
    'Franklin',
    'Fraziers Bottom',
    'Friendly',
    'Gallipolis Ferry',
    'Gassaway',
    'Gauley Bridge',
    'Ghent',
    'Glasgow',
    'Glen Dale',
    'Glen Daniel',
    'Glen Easton',
    'Glen Jean',
    'Glendale',
    'Glenville',
    'Grafton',
    'Grantsville',
    'Great Cacapon',
    'Green Bank',
    'Half Way',
    'Halltown',
    'Harpers Ferry',
    'Harrisville',
    'Heaters',
    'Hedgesville',
    'Hepzibah',
    'Hico',
    'High View',
    'Hillsboro',
    'Hinton',
    'Holden',
    'Horner',
    'Huntington',
    'Huntington-Ashland Apt',
    'Hurricane',
    'Institute',
    'Inwood',
    'Jane Lew',
    'Julian',
    'Justice',
    'Kearneysville',
    'Kenna',
    'Kenova',
    'Keslers Cross Lanes',
    'Keyser',
    'Keystone',
    'Kingwood',
    'Lansing',
    'Left Hand',
    'Lesage',
    'Lester',
    'Letart',
    'Levels',
    'Lewisburg',
    'Linden',
    'Lindside',
    'Logan',
    'Long Reach',
    'Mabie',
    'Mabscott',
    'Madison',
    'Maidsville',
    'Man',
    'Mannington',
    'Marlinton',
    'Martinsburg',
    'Masontown',
    'Matewan',
    'Maysville',
    'McDowell',
    'McMechen',
    'Melissa',
    'Middlebourne',
    'Middleway',
    'Millwood',
    'Milton',
    'Mineral Wells',
    'Mineralwells',
    'Moatsville',
    'Monaville',
    'Montgomery',
    'Moorefield',
    'Morgantown',
    'Moundsville',
    'Mount Hope',
    'Mount Nebo',
    'Mount Storm',
    'Mullens',
    'Murraysville',
    'Natrium',
    'New Cumberland',
    'New Haven',
    'New Martinsville',
    'Newell',
    'Nitro',
    'Northfork',
    'Oak Hill',
    'Ona',
    'Paden City',
    'Parkersburg',
    'Parsons',
    'Paw Paw',
    'Pax',
    'Pennsboro',
    'Petersburg',
    'Philippi',
    'Piedmont',
    'Pineville',
    'Poca',
    'Pocatalico',
    'Point Pleasant',
    'Prichard',
    'Princeton',
    'Proctor',
    'Rainelle',
    'Rand',
    'Ranson',
    'Ravenswood',
    'Red House',
    'Reedsville',
    'Reedy',
    'Renick',
    'Richwood',
    'Ridgeley',
    'Ripley',
    'Rock',
    'Rocket Center',
    'Rockport',
    'Roderfield',
    'Ronceverte',
    'Saint Albans',
    'Saint Marys',
    'Salem',
    'Sand Fork',
    'Scott Depot',
    'Shady Spring',
    'Shenandoah Junction',
    'Shepherdstown',
    'Shinnston',
    'Shrewsbury',
    'Sissonville',
    'Sistersville',
    'Smithville, Ritchie',
    'Smoot',
    'Snowshoe',
    'Sophia',
    'South Charleston',
    'Spelter',
    'Spencer',
    'Stonewood',
    'Summersville',
    'Summit Point',
    'Sutton',
    'Switzer',
    'Tams',
    'Teays',
    'Terra Alta',
    'Thacker',
    'Thomas',
    'Thornton',
    'Triadelphia',
    'Uneeda',
    'Union, Monroe',
    'Valley Grove',
    'Vienna',
    'Walker',
    'Wardensville',
    'Washington',
    'Waverly',
    'Webster Springs',
    'Weirton',
    'Wellsburg',
    'West Columbia',
    'West Liberty',
    'West Logan',
    'Weston',
    'Wheeling',
    'White Sulphur Springs',
    'Whitesville',
    'Whitman',
    'Williamstown',
    'Willow Island',
    'Windsor Heights',
    'Winfield',
    'Worthington'
  ],
  WY: [
    'Afton',
    'Aladdin',
    'Albany',
    'Albin',
    'Alpine',
    'Alta',
    'Alva',
    'Bar Nunn',
    'Basin',
    'Big Horn',
    'Big Piney',
    'Bosler',
    'Buffalo',
    'Burlington',
    'Casper',
    'Cheyenne',
    'Cody',
    'Cokeville',
    'Colony',
    'Diamondville',
    'Douglas/Converse',
    'Dubois',
    'Etna',
    'Evanston',
    'Evansville',
    'Falls',
    'FE Warren Air Force Base',
    'Fort Bridger',
    'Frannie',
    'Gillette',
    'Glenrock',
    'Green River',
    'Greybull',
    'Guernsey',
    'Hanna',
    'Hulett',
    'Jackson',
    'Kaycee',
    'Kelly',
    'Kemmerer',
    'Kirby',
    'La Barge',
    'Lance Creek',
    'Lander',
    'Laramie',
    'Lingle',
    'Lovell',
    'Lovell-Powell Apt',
    'Lusk',
    'Medicine Bow',
    'Meeteetse',
    'Mills',
    'Moran',
    'Newcastle',
    'North Java',
    'Opal',
    'Osage',
    'Pine Bluffs',
    'Pinedale',
    'Point of Rocks',
    'Powell',
    'Powell-Lovell Apt',
    'Ranchester',
    'Rawlins',
    'Reliance',
    'Riverton',
    'Rock Springs',
    'Rozet',
    'Saratoga',
    'Sheridan',
    'Shoshoni',
    'Sinclair',
    'Sundance',
    'Ten Sleep',
    'Teton Village',
    'Thayne',
    'Thermopolis',
    'Torrington',
    'Upton',
    'Wamsutter',
    'Wapiti',
    'Wendover',
    'Weston',
    'Wheatland',
    'Wilson',
    'Worland',
    'Wright',
    'Moose Wilson Rd'
  ]
}
