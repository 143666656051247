import { FC, useEffect } from 'react'
import { CampaignCategoryListParams } from './interfaces/campaign-categories'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { getAllAgencies } from '../agencies/slices'
import { AgenciesSelectItems, selectMenuItem } from '../commons/utils/utils'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'

interface CampaignCategoriesListHeaderProps {
  handleFormChange: (changedData: CampaignCategoryListParams) => void
  agencyID?: string
}

const CampaignCategoriesListHeader: FC<CampaignCategoriesListHeaderProps> = ({
  handleFormChange,
  agencyID
}) => {
  const { agencies, tenantID } = useAppSelector((state) => ({
    agencies: state.agencies.agencies,
    tenantID: state.auth.tenantID
  }))
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getAllAgencies({ tenantID }))
  }, [dispatch, tenantID])

  return (
    <div>
      <Form>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='categories-label'>
            {agencyID === '' && t`SelectAgency`}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ agencyID: e.target.value as string })}
            value={agencyID}
            menuItem={selectMenuItem(t`AllAgencies`)}
            selectItems={AgenciesSelectItems(agencies)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default CampaignCategoriesListHeader
