export const ErrorStatus = {
  FAIL: 'FAIL',
  PASS: 'PASS'
}
export interface SelectOption {
  text: string
  value: string
}

export const getAggregatesMethod = [
  {
    text: 'Count',
    value: 'Count'
  },
  {
    text: 'Distance in Km',
    value: 'DistanceInKm'
  },
  {
    text: 'Time in minutes',
    value: 'TimeInMinutes'
  },
  {
    text: 'Price',
    value: 'Price'
  }
]
export const getEarnUnit = [
  {
    text: 'Day',
    value: 'DAY'
  },
  {
    text: 'Week',
    value: 'WEEK'
  },
  {
    text: 'ISO Week',
    value: 'ISO_WEEK'
  }
]
export const getOperator = (filter: string): SelectOption[] => {
  if (filter === 'SqlFilter') {
    return [
      {
        text: '=',
        value: '='
      }
    ]
  } else {
    return [
      {
        text: '<',
        value: '<'
      },
      {
        text: '<=',
        value: '<='
      },
      {
        text: '=',
        value: '='
      },
      {
        text: '>=',
        value: '>='
      },
      {
        text: '>',
        value: '>'
      }
    ]
  }
}
export const getFilterTypes = [
  { value: 'FromTimestampUTC', text: 'FromTimestampUTC' },
  { value: 'ToTimestampUTC', text: 'ToTimestampUTC' },
  { value: 'Price', text: 'Price' },
  { value: 'Currency', text: 'Currency' },
  { value: 'DistanceInKm', text: 'DistanceInKm' },
  { value: 'ProviderID', text: 'ProviderID' },
  { value: 'VerticalID', text: 'VerticalID' },
  { value: 'VehicleType', text: 'VehicleType' },
  { value: 'FromLng', text: 'FromLng' },
  { value: 'FromLat', text: 'FromLat' },
  { value: 'FromAddress', text: 'FromAddress' },
  { value: 'ToLng', text: 'ToLng' },
  { value: 'ToLat', text: 'ToLat' },
  { value: 'ToAddress', text: 'ToAddress' },
  { value: 'CountryISO', text: 'CountryISO' },
  { value: 'UNLocCode', text: 'UNLocCode' },
  { value: 'SqlFilter', text: 'SqlFilter' }
]

export const ImageKeyName = {
  ThumbnailID: 'thumbnailID',
  IconID: 'iconID'
}

export const KEY_DOWN_ACTION_ESCAPE = 'Escape'
export const NO_ICON = 'NoIcon'
export const BADGE_FORM_NAME = 'badge'
