import { Box, Divider, Typography } from '@mui/material'
import { t } from 'i18next'
import { ReactElement } from 'react'
import '../segments.scss'

interface UserValidationBoxProps {
  userEntered: number
  userNotFound: number
  duplicated: number
  newUsersToAdd: number
}

const userBoxRow = (title: string, value: number): ReactElement => (
  <Box className='user-box-row'>
    <Typography className='options' variant='subtitle2'>
      {title}
    </Typography>
    <Typography variant='subtitle2' className='user-box-typo'>
      {value}
    </Typography>
  </Box>
)

const UserValidationBox = ({
  userEntered,
  userNotFound,
  duplicated,
  newUsersToAdd
}: UserValidationBoxProps): ReactElement => (
  <Box className='user-box-container'>
    {userBoxRow(t`UserEntered`, userEntered)}
    {userBoxRow(t`UserNotFound`, userNotFound)}
    {userBoxRow(t`Duplicated`, duplicated)}
    {userBoxRow(t`NewUsersToAdd`, newUsersToAdd)}
    <Divider className='user-box-divider' />
  </Box>
)

UserValidationBox.defaultProps = {
  userEntered: 0,
  userNotFound: 0,
  duplicated: 0,
  newUsersToAdd: 0
}

export default UserValidationBox
