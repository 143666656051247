import { ReactElement } from 'react'
import './Stepper.scss'
import {
  Step,
  StepButton,
  Stepper
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface SelfServeStepperProps {
  steps: string[]
  nonLinear: boolean
  activeStep: number
  setActiveStep: (newStep: number) => void
}

const SelfServeStepper = ({
  steps,
  nonLinear,
  activeStep,
  setActiveStep
}: SelfServeStepperProps): ReactElement | null => {
  return (
    <Stepper
      connector={<ChevronRightIcon fontSize='small' />}
      nonLinear={nonLinear}
      activeStep={activeStep}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton
            disableRipple
            disableTouchRipple
            color='inherit'
            onClick={() => nonLinear && setActiveStep(index)}
          >
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

export default SelfServeStepper
