export const PREDEFINED_ICON_LIST = [
  'AccessAlarmOutlined',
  'AccessTimeOutlined',
  'AccessibilityNewOutlined',
  'AccessibleOutlined',
  'AirplanemodeActiveOutlined',
  'AirportShuttleOutlined',
  'AnchorOutlined',
  'ApartmentOutlined',
  'AudiotrackOutlined',
  'BedtimeOutlined',
  'BikeScooterOutlined',
  'BusAlertOutlined',
  'CalendarMonthOutlined',
  'CameraAltOutlined',
  'CasesOutlined',
  'CelebrationOutlined',
  'CommentOutlined',
  'CommuteOutlined',
  'ConfirmationNumberOutlined',
  'ContentPasteOutlined',
  'CreditCardOutlined',
  'DepartureBoardOutlined',
  'DirectionsBikeOutlined',
  'DirectionsBoatFilledOutlined',
  'DirectionsBusOutlined',
  'DirectionsCarOutlined',
  'DirectionsRailwayFilledOutlined',
  'DirectionsSubwayOutlined',
  'DirectionsWalkOutlined',
  'DiscountOutlined',
  'DriveEtaOutlined',
  'ElectricBikeOutlined',
  'ElectricCarOutlined',
  'ElectricScooterOutlined',
  'EmojiEventsOutlined',
  'EvStationOutlined',
  'LocalParkingOutlined',
  'LocationOnOutlined',
  'LocalShippingOutlined',
  'LocalTaxiOutlined',
  'PersonOutlineOutlined'
]
