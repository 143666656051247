import { Badge, Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import _ from 'lodash'
import '../../ui/phone-preview/PhonePreview.scss'
import { CampaignsInterfaceProps } from '../interfaces/campaigns'
import { t } from 'i18next'

const FeaturedCampaignPreview = ({
  campaign: { url, rewardPerUser, subHeader, title, subTitle, isFeatured },
  points,
  isStreakCampaign
}: CampaignsInterfaceProps): ReactElement => {
  return (
    <Box className='preview-campaigns' flexDirection='column'>
      {isFeatured === true && !_.isEmpty(url)
        ? (
          <Box>
            {((!_.isNull(rewardPerUser) && points !== 0) || isStreakCampaign) && (
              <Badge
                badgeContent={isStreakCampaign ? t`Streak` : `${points} pts`}
                overlap='rectangular'
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                className='mb-6'
              />
            )}
            <img src={url} alt='live-campaign' />
            <Typography className='header'>{subHeader}</Typography>
            <Typography className='subheader'>{title}</Typography>
            <Typography className='campaign-action'>{subTitle}</Typography>
          </Box>
          )
        : (
          <Box className='empty-preview-box'>
            <Box className='flex-column'>
              <Box className='empty-preview main' />
              <Box className='empty-description main' />
            </Box>
          </Box>
          )}
    </Box>
  )
}

export default FeaturedCampaignPreview
