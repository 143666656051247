import {
  TextField,
  FormControl,
  Divider,
  Box,
  InputLabel,
  MenuItem
} from '@mui/material'
import { useEffect, useMemo, ReactElement } from 'react'
import SelfServeButton from '../../ui/button/Button'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import '../campaigns.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import {
  CampaignAllProviders,
  CampaignFormInterface,
  CampaignFormProps
} from '../interfaces/campaigns'
import * as formValidationRule from '../campaign-rules/CampaignFormValidationRules'
import {
  getAggregatesMethod,
  getFilterTypes,
  getOperator,
  SelectOption
} from '../constants/CommonConstants'
import { getAllProviderList } from '../slices'
import { handleNextStep } from '../../commons/utils/utils'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import CampaignSelectionChip from '../../ui/form-selection-chip/FormSelectionChip'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

const CampaignOptions = ({
  onFormChange,
  handleChange,
  handleNext,
  handleBack,
  onDiscard,
  isFromEdit = false,
  isEditable = true
}: CampaignFormProps): ReactElement | null => {
  const {
    campaignForm: {
      provider,
      aggregateValue,
      aggregateMethod,
      filter,
      operator,
      filterValue
    },
    isFormTouched,
    allProviders
  } = useAppSelector((state) => state.campaigns)
  const {
    auth: { tenantID: TenantID }
  } = useAppSelector((state) => state)
  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      provider,
      aggregateValue,
      aggregateMethod,
      filter,
      operator,
      filterValue
    }
  })
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    void dispatch(getAllProviderList({ TenantID }))
  }, [TenantID, dispatch])
  const providerListWithMemo = useMemo(
    () =>
      allProviders
        .filter((item: CampaignAllProviders) => {
          return item.Name !== '' && !provider?.includes(item.Name)
        })
        .map((item) => (
          <MenuItem key={item.Name} value={item.Name}>
            {item.Name}
          </MenuItem>
        )),
    [allProviders, provider]
  )
  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <FormFieldBox
          title={t`ProviderRequiredToConnect`}
          rules={
            isEditable && {
              required: formValidationRule.formValidationConstants.requiredField
            }
          }
          name='provider'
          render={({ field: { name, onChange } }) => (
            <>
              <InputLabel
                shrink={false}
                className='select-label'
                id='state-label'
              >
                {provider?.length === 0 && t`SelectProviders`}
              </InputLabel>
              <SelfServeCustomSelect
                handleFormChange={(e) => {
                  handleChange(e, name as keyof CampaignFormInterface, onChange)
                }}
                value={provider}
                selectItems={providerListWithMemo}
                name={name}
                disabled={!isEditable}
                inputProps='custom-form-select'
                isFormSelect
                multiple
                renderValue={() => (
                  <CampaignSelectionChip
                    selectedList={provider}
                    onFormChange={onFormChange}
                    name={name}
                    isEditable={!isEditable}
                  />
                )}
              />
            </>
          )}
          control={control}
          errorField={errors.provider}
        />
        <Box className='mb-1 flex-row space-between'>
          <FormControl fullWidth>
            <FormFieldBox
              title={t`AggregateValue`}
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField,
                  pattern: formValidationRule.formValidationConstants.numberOnly
                }
              }
              name='aggregateValue'
              render={({ field: { name, onChange } }) => (
                <TextField
                  inputProps={{ 'data-testid': 'aggregateValueField' }}
                  name={name}
                  placeholder={t`AggregateValue`}
                  id='aggregateValue'
                  onChange={(e) => {
                    handleChange(
                      e,
                      name as keyof CampaignFormInterface,
                      onChange,
                      true
                    )
                  }}
                  value={aggregateValue}
                  disabled={!isEditable}
                />
              )}
              control={control}
              errorField={errors.aggregateValue}
            />
          </FormControl>
          <Divider orientation='vertical' variant='inset' flexItem />
          <FormControl fullWidth>
            <FormFieldBox
              title={t`AggregateMethod`}
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField
                }
              }
              name='aggregateMethod'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel
                    shrink={false}
                    className='select-label'
                    id='state-label'
                  >
                    {aggregateMethod === '' && t`SelectMethod`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    data-testid='aggregateMethodField'
                    handleFormChange={(e) => {
                      handleChange(
                        e,
                        name as keyof CampaignFormInterface,
                        onChange
                      )
                    }}
                    value={aggregateMethod}
                    selectItems={getAggregatesMethod.map(
                      (item: SelectOption) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.text}
                        </MenuItem>
                      )
                    )}
                    name={name}
                    disabled={!isEditable}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.aggregateMethod}
            />
          </FormControl>
        </Box>
        <Box className='mb-1 flex-row space-between'>
          <FormControl fullWidth>
            <FormFieldBox
              title={t`FilterType`}
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField
                }
              }
              name='filter'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel
                    shrink={false}
                    className='select-label'
                    id='state-label'
                  >
                    {(filter == null || filter === '') && t`SelectType`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    data-testid='filterTypeField'
                    handleFormChange={(e) => {
                      handleChange(
                        e,
                        name as keyof CampaignFormInterface,
                        onChange
                      )
                    }}
                    value={filter}
                    selectItems={getFilterTypes.map((item: SelectOption) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                    name={name}
                    disabled={!isEditable}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.filter}
            />
          </FormControl>
          <Divider orientation='vertical' variant='inset' flexItem />
          <FormControl fullWidth>
            <FormFieldBox
              title={t`FilterOperator`}
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField
                }
              }
              name='operator'
              render={({ field: { name, onChange } }) => (
                <>
                  <InputLabel
                    shrink={false}
                    className='select-label'
                    id='state-label'
                  >
                    {operator === '' && t`SelectOperator`}
                  </InputLabel>
                  <SelfServeCustomSelect
                    data-testid='filterOperatorField'
                    handleFormChange={(e) => {
                      handleChange(
                        e,
                        name as keyof CampaignFormInterface,
                        onChange
                      )
                    }}
                    value={operator}
                    selectItems={
                      filter != null &&
                      getOperator(filter).map((item: SelectOption) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.text}
                        </MenuItem>
                      ))
                    }
                    name={name}
                    disabled={!isEditable}
                    inputProps='custom-form-select'
                    isFormSelect
                  />
                </>
              )}
              control={control}
              errorField={errors.operator}
            />
          </FormControl>
          <Divider orientation='vertical' variant='inset' flexItem />
          <FormControl fullWidth>
            <FormFieldBox
              title={t`FilterValue`}
              rules={
                isEditable && {
                  required:
                    formValidationRule.formValidationConstants.requiredField
                }
              }
              name='filterValue'
              render={({ field: { name, onChange } }) => (
                <TextField
                  inputProps={{ 'data-testid': 'filterValueField' }}
                  name={name}
                  placeholder={t`FilterValue`}
                  id='filterValue'
                  onChange={(e) => {
                    handleChange(
                      e,
                      name as keyof CampaignFormInterface,
                      onChange
                    )
                  }}
                  disabled={!isEditable}
                  value={filterValue}
                />
              )}
              control={control}
              errorField={errors.filterValue}
            />
          </FormControl>
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='campaign-create-preview'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='nextButtonCampaignOptions'
              id='campaign-create-next'
              type='submit'
              variant='contained'
              disabled={isFromEdit ? false : !isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='campaign-create-discard'
            onClick={onDiscard}
            variant='contained'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </Box>
  )
}

export default CampaignOptions
