import constants from './constants'
const authInitialState = {
  userType: '',
  authStatus: constants.AuthStatus.LOGGED_OUT,
  userID: '',
  signInLoading: false,
  signInErrorMsg: '',
  email: '',
  name: '',
  tenantID: '',
  isAgencyEnabled: false
}

export default authInitialState
