import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from '../../locales/en/en.json'

const getShortLang = (lang: string): string => {
  let shortLang = lang
  if (shortLang.includes('-')) { shortLang = shortLang.split('-')[0] }

  if (shortLang.includes('_')) { shortLang = shortLang.split('_')[0] }

  return shortLang
}

const init = async (): Promise<any> => (
  await i18n
    .use(initReactI18next)
    .init({
      returnNull: false,
      resources: {
        en: {
          translation: translationEN
        }
      },
      lng: getShortLang(navigator.language),
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    })
)

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common'
    resources: {
      [key: string]: {
        [key: string]: string
      }
    }
  }
}

export default init
