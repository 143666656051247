import { FC, useState, useMemo } from 'react'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { useAppSelector } from '../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import SelfServeButton from '../ui/button/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import SelectWithAutoComplete from '../ui/select/SelectWithAutoComplete'
import { EMPTY_OPTION_SEGMENTS, ADD_TO_ALL_OPTION_SEGMENTS } from './constants'
import './segments.scss'
import { SelectOption } from '../commons/interfaces/commons-interfaces'
interface SegmentsListHeaderProps {
  onAddClick: (id: string) => void
  segmentListIDs?: string[]
  excludeAll?: boolean
}

const SegmentsListHeader: FC<SegmentsListHeaderProps> = ({
  onAddClick,
  segmentListIDs = [],
  excludeAll = false
}: SegmentsListHeaderProps) => {
  const { segmentOptions } = useAppSelector((state) => state.segments)
  const { t } = useTranslation()
  const [value, setValue] = useState<SelectOption>(EMPTY_OPTION_SEGMENTS)

  const filteredSegments = useMemo(() => {
    let segments = segmentOptions
    if (segmentListIDs.length > 0) {
      segments = segments.filter(
        (segment) =>
          !segmentListIDs.includes(segment.id) &&
          !segmentListIDs.includes(segment.groupID ?? '')
      )
    }
    if (excludeAll) {
      segments = segments.filter(
        (segment) => segment.id !== ADD_TO_ALL_OPTION_SEGMENTS.id
      )
    }
    return segments
  }, [segmentListIDs, segmentOptions, excludeAll])

  return segmentOptions != null
    ? (
      <div>
        <Form>
          <SelectWithAutoComplete
            options={filteredSegments ?? segmentOptions}
            value={value}
            setValue={setValue}
          />
          <FormControl className='form-control segment-button'>
            <SelfServeButton
              variant='outlined'
              id='add-segment'
              onClick={() => {
                onAddClick(value.id)
                setValue(EMPTY_OPTION_SEGMENTS)
              }}
              disabled={value.id === ''}
            >
              <AddOutlinedIcon className='button-icon' /> {t`NewSegment`}
            </SelfServeButton>
          </FormControl>
        </Form>
      </div>
      )
    : null
}

export default SegmentsListHeader
