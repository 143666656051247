import { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { t } from 'i18next'
import { PER_PAGE_WALLET_TABLE, FIRST_PAGE_WALLET_TABLE } from '../constants'
import { UsersListParams } from '../interfaces/users'
import { getValueForForm } from '../../commons/utils/utils'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { PAGE, constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import WalletTrasactionsList from './WalletTransactionsList'
import { getUserByID, getUserWallet } from '../slices'
import WalletTransactionsListHeader from './WalletTransactionsListHeader'
import EmptyWallet from './EmptyWallet'

const WalletTransactions = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const { usersTransactions, totalUsersTransactions, hasTransactions } =
    useAppSelector((state) => state.users)

  const handleFormChange = ({ page, perPage, type }: UsersListParams): void => {
    Object.entries({
      page,
      perPage,
      type
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    if (id != null) {
      void dispatch(getUserByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.USER))
      dispatch(setBackButtonTitle(t`BackToUsers`))
      dispatch(setBackButtonRoute(RoutePath.Users))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getUserWallet({
          userID: id,
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_WALLET_TABLE.toString()
          ),
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_WALLET_TABLE.toString()
          ),
          type: searchParams.get('type') ?? ''
        })
      )
    }
  }, [id, dispatch, searchParams])

  return id != null && id !== ''
    ? (
      <Box className='flex-column'>
        <h1 className='title'>{t`UserWalletTransactions`}</h1>
        {hasTransactions
          ? (
            <>
              <WalletTransactionsListHeader
                handleFormChange={handleFormChange}
                type={searchParams.get('type') ?? ''}
              />
              <WalletTrasactionsList
                transactionsList={usersTransactions}
                total={totalUsersTransactions}
                onPageChange={handleFormChange}
                page={parseInt(
                  searchParams.get('page') ?? FIRST_PAGE_WALLET_TABLE.toString()
                )}
                perPage={parseInt(
                  searchParams.get('perPage') ?? PER_PAGE_WALLET_TABLE.toString()
                )}
              />
            </>
            )
          : (
            <EmptyWallet />
            )}
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default WalletTransactions
