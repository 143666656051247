import initTranslation from '../../core/utils/i18n'
import { ErrorLevels } from '../../core/error/constants'
import { reportError } from '../../core/error/handler'
import i18n from 'i18next'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export const constants = {
  GENERAL_TABLE_MIN_WIDTH: 650,
  MAX_PAGINATION_PER_PAGE: 100,
  MIN_PAGINATION_PER_PAGE: 0,
  MAX_WIDTH_CARD: '33em',
  SIDEBAR_ENTITY_TYPES: {
    GENERAL: 'GENERAL',
    AGENCY: 'AGENCY',
    CAMPAIGN: 'CAMPAIGN',
    CAMPAIGN_CATEGORY: 'CAMPAIGN_CATEGORY',
    OFFER: 'OFFER',
    OFFER_CATEGORY: 'OFFER_CATEGORY',
    USER: 'USER',
    SEGMENT: 'SEGMENT'
  },
  COMMON_FORM_VALUES: {
    THUMBNAILURL: 'ThumbnailUrl',
    URL: 'url',
    ICON: 'icon',
    CITY: 'city',
    DASHBOARD_ACCESS: 'dashboardAccess',
    ISFEATURED: 'isFeatured',
    HASCTA: 'hasCTA',
    CATEGORY_NAME: 'categoryName',
    HASTIMECONSTRAINT: 'hasTimeConstraint',
    VALIDMODEOFTRANSPORTATION: 'validModeOfTransportation',
    MODEOFTRANSPORTATION: 'modeOfTransportation',
    CAMPAIGNTICKETTYPE: 'campaignTicketType',
    CAMPAIGNEARNUNIT: 'earnUnit',
    HASEXPIREPOINTSDATE: 'hasExpiryDate',
    ID: 'id'
  },
  BUTTON_PROPS: {
    MEDIUM: 'medium',
    PRIMARY: 'primary'
  },
  EMPTY_STRING: '',
  TRUE: 'true',
  TEXT: 'text',
  PASSWORD: 'password'
}
export const regexEmail =
  /^([a-zA-Z0-9]{1})([a-zA-Z0-9.]{1,})([a-zA-Z0-9_\-.#!$%&+\-/=_*^{}~]{0,})@([a-zA-Z0-9])([a-zA-Z0-9-]{0,})\.([a-zA-Z-.]{1,})([a-zA-Z])$/

export enum AppStatus {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export const CENTERED_HEADER_TEXT = {
  ICON: 'Icon',
  ID: 'ID',
  UI_POSITION: 'UI Position',
  AVAILABLE: 'Available',
  USERS: 'Users',
  TIMES_CAN_BE_REDEEMED: 'Times can be Redeemed',
  AVAILABLE_CODES: 'Available Codes'
}

export const enum PAGE_PARAMS {
  DEFAULT_PAGE = 0,
  DEFAULT_PER_PAGE = 30
}

export const FORM_DATE_FORMAT = 'MMM DD, YYYY | HH:mm'
export const FORM_DATE_FORMAT_PLACEHOLDER = 'MM/DD/YYY --- HH:MM'
export const VALIDATION_ERROR = 'ValidationError'

export const TEXTFIELD_EDITOR_TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }]
]

export const MARGIN_TOP_01 = 'mt-01'

export const PAGE = 'page'
export const USERTYPE = 'userType'

export const commonFormValidationConstants = {
  requiredField: { value: true, message: 'Required Field' },
  minLengthError: {
    value: 3,
    message: i18n.t('LengthError', { min: 3, max: 50 })
  },
  maxLengthError: {
    value: 50,
    message: i18n.t('LengthError', { min: 3, max: 50 })
  }
}

export const UP = 'UP'
export const DOWN = 'DOWN'
export const REORDER_KEY_NAME = {
  CategoryIDKeyWord: 'CategoryID',
  OfferIDKeyWord: 'OfferID',
  CampaignIDKeyWord: 'CampaignID',
  WebUiPosition: 'WebUiPosition',
  FeaturedUiPosition: 'FeaturedUiPosition',
  OfferTitle: 'Title',
  CampaignTitle: 'Title',
  CategoryName: 'CategoryName',
  OfferStatus: 'offerstatus',
  CampaignStatus: 'status'
}
export const KEY_NAME_TYPE = {
  Title: 'Title',
  ID: 'ID'
}
export const UI_TYPE = {
  CAMPAIGN: 'CAMPAIGN',
  OFFER: 'OFFER'
}
