import { RoleType } from '../../core/routes/constants'
import { CENTERED_HEADER_TEXT, DOWN, KEY_NAME_TYPE, REORDER_KEY_NAME, UI_TYPE, UP, VALIDATION_ERROR, constants } from '../constants/constants'
import axios, { AxiosError } from 'axios'
import {
  ReactElement,
  useMemo,
  ReactNode,
  SetStateAction,
  Dispatch,
  ChangeEvent
} from 'react'
import { Box, Chip, FormHelperText, MenuItem, SelectChangeEvent, TableCell, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TargetGroupsInterface } from '../../segments/interfaces/segments'
import { FieldValues, UseFormTrigger } from 'react-hook-form'
import { ADD_TO_ALL_OPTION_SEGMENTS } from '../../segments/constants'
import { DateRange, IconProps, SelectOption } from '../interfaces/commons-interfaces'
import * as MUIcon from '@mui/icons-material'
import _ from 'lodash'
import i18n from 'i18next'
import initTranslation from '../../core/utils/i18n'
import { ErrorLevels } from '../../core/error/constants'
import { handleRejectWithReport, reportError } from '../../core/error/handler'
import { AgenciesInterface } from '../../agencies/interfaces/agencies'
import { customAlphabet } from 'nanoid'
import { CampaignCategoryInterface } from '../../campaign-categories/interfaces/campaign-categories'
import { DropResult } from 'react-beautiful-dnd'
import { OfferCategoriesInterface } from '../../offer-categories/interfaces/offer-categories'
import { OfferFormInterface, OffersInterface } from '../../offers/interfaces/offers'
import { CampaignFormInterface, CampaignsInterface } from '../../campaigns/interfaces/campaigns'
import { getKeyName } from '../../offers/utils'

const NANO_ID_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

dayjs.extend(utc)
if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export const getRoleTypeByString = (role: string): RoleType => {
  return RoleType[role as keyof typeof RoleType]
}

export const isAtIndexFromEnd = <T,>(
  i: number,
  count: number,
  columns: T[]
): boolean => i === columns.length - count

type FormValues = string | Blob

export const appendFormValue = <T,>(
  form: FormData,
  value: T,
  name: string
): void => {
  const checkedValue = Array.isArray(value) ? value.join(',') : value
  form.append(name, checkedValue as FormValues)
}

export const getValueForForm = <T,>(
  key: string,
  value: FormTypes<T>,
  params: T
): FormTypes<T> => {
  return value != null ? value : params[key as keyof typeof params]
}

export type FormTypes<T> = number | string | string[] | T[keyof T] | undefined

export const checkIsNotEmpty = <T,>(value: T): boolean => {
  if (Array.isArray(value)) {
    return value != null && value.length !== 0
  }
  return value != null && value !== ''
}

export const checkObjectIsNotEmpty = <T,>(
  value?: T
): boolean => {
  return Object.keys(value as Record<PropertyKey, unknown>).length !== 0
}

export const columnTextCheck = (column: string): boolean => {
  return Object.values(CENTERED_HEADER_TEXT).includes(column)
}

export const onFormChange = <T,>(
  value: T,
  name: string,
  setValueMap: Record<string, (v: T) => void>
): void => {
  setValueMap[name](value)
}

export const onFormChangeWrapper = (
  value: string,
  name: string,
  onChange: (value: string, name: string) => void,
  setValueMap: Record<string, (v: string) => void>,
  resetUIs?: () => void
): void => {
  onFormChange(value, name, setValueMap)
  onChange(value, name)
  resetUIs?.()
}

export const getErrorMessage = <T extends Error | AxiosError>(
  error: T
): string => {
  if (!axios.isAxiosError(error)) {
    return error.message
  }

  if (error.response == null) {
    return error.message
  }

  return error.response.data.error_description ?? error.message
}

export const allText = (text: string): ReactElement => (
  <Box>
    {text} <span className='black'>{i18n.t('All')}</span>
  </Box>
)

export const dashBox = <Box className='mt-1'>-</Box>
export const emptyCell = <TableCell className='body-cell' />

export const allWithoutText = <span className='black'>{i18n.t('All')}</span>

export const selectMenuItem = (value: string): ReactElement => (
  <MenuItem value=''>
    <em>{value}</em>
  </MenuItem>
)

export const setClassNameForTable = <T,>(
  column: string,
  i: number,
  columns: T[],
  setCustomHeader = false,
  customClassName = ''
): string => {
  return isAtIndexFromEnd(i, 1, columns)
    ? 'last-header-cell'
    : isAtIndexFromEnd(i, 2, columns)
      ? 'second-last-header-cell'
      : columnTextCheck(column)
        ? 'centered-cell header-cell'
        : setCustomHeader
          ? customClassName
          : 'header-cell'
}

export const convertStringToDateAndTime = (
  timeStamp: string | undefined | null | Date
): {
  date: string | undefined
  time: string | undefined
} => {
  let date
  let time
  if (timeStamp != null && timeStamp !== '') {
    date = dayjs.utc(timeStamp).format('MMM D, YYYY')
    time = dayjs.utc(timeStamp).format('HH:mm')
  }
  return {
    date,
    time
  }
}

export const formatDateAndTime = (
  date: string = '',
  time: string = ''
): string => {
  return !_.isEmpty(date) ? `${date} | ${time}` : constants.EMPTY_STRING
}

export const DashBox = <Box className='mt-0'>-</Box>

export const renderSegments = (
  segments: TargetGroupsInterface[] | undefined,
  className?: string): ReactElement | ReactElement[] => {
  return Array.isArray(segments) && segments.length > 0
    ? segments.map((segment) => (
      <Chip key={segment.TargetGroupID} label={segment.Name} className={className} />
    ))
    : DashBox
}

export const setDashIfEmpty = (text?: string | number): string | number => {
  return text != null && text !== '' ? text : '-'
}

export const truncateTextWithDots = (text: string, uptoLength: number): string => {
  return text.length > uptoLength ? text.substring(0, uptoLength).concat('...') : text
}

export const getCapitalisation = (text: string): string => {
  return text.charAt(0) + text.substring(1).toLowerCase()
}

export const mapSegmentsToIDs = (segments: TargetGroupsInterface[]): string[] =>
  segments.map((segment) => segment.TargetGroupID ?? '')

export const constructDate = (start: string, end: string): string => {
  return checkIsNotEmpty(start) && checkIsNotEmpty(end)
    ? [start, end].join(' - ')
    : checkIsNotEmpty(start)
      ? start
      : end
}

export const removeWhitespace = (value: string): string => {
  return value.replace(/\s+/g, '')
}

export const replaceWhitespaceWithComma = (value: string): string => {
  return value.replace(/\s+/g, ',')
}

export const filterNonNumericValues = (value: string): string => {
  return value.replace(/[+\-.e\D]/gi, '')
}

export const filterSpecialCharacters = (value: string): string => {
  return value.replace(/[^\w\s]/gi, '')
}

export const filterDuplicateValues = (value: string): string => {
  return _.uniq(value).join('')
}

export const handleSubmit = async (
  event: React.FormEvent,
  trigger: UseFormTrigger<any>,
  onSubmit: () => Promise<void> | void
): Promise<void> => {
  event.preventDefault()
  const noErrors = await trigger()
  if (noErrors && onSubmit != null) {
    await onSubmit()
  }
}

export const handleNextStep = async (
  event: React.FormEvent,
  trigger: UseFormTrigger<any>,
  next: () => void
): Promise<void> => {
  event.preventDefault()
  const noErrors = await trigger()
  if (noErrors) {
    next()
  }
}

const createMenuItems = (item: SelectOption): ReactNode => {
  return (
    <MenuItem key={item.id} value={item.id}>
      {item.label}
    </MenuItem>
  )
}

export const SegmentList = (
  segmentOptions: SelectOption[],
  targetList: string[],
  compareList: string[],
  isExclude: boolean): ReactNode =>
  useMemo(() => {
    return segmentOptions
      ?.filter((item: SelectOption) => {
        return isExclude
          ? item.id !== '' &&
              !targetList.includes(item.id) &&
              !compareList.includes(item.id) &&
              isExclude &&
              item.id !== ADD_TO_ALL_OPTION_SEGMENTS.id
          : item.id !== '' &&
              !targetList.includes(item.id) &&
              !compareList.includes(item.id)
      })
      .map((item) => createMenuItems(item))
  }, [targetList, compareList, segmentOptions, isExclude])

export const checkIsNumPositive = (
  value: number | null | undefined
): boolean => {
  return value != null && value > 0
}

export const removeDecimalPoints = (
  value: number | undefined | null | string
): number | null => {
  if (_.isString(value)) {
    const parsedValue = parseFloat(value)
    return isNaN(parsedValue) ? null : Math.trunc(parsedValue)
  }
  return value != null ? Math.trunc(value) : null
}

export const createImageID = (
  value: unknown,
  fileValue: Blob
): string | null => {
  return value != null ? URL.createObjectURL(fileValue) : null
}

// TODO: Check if we need this function.
export const setFileInfo = (
  fileValue: Blob
): Blob | null => {
  return fileValue != null ? fileValue : null
}

export const formatDate = (
  date: Date | string | undefined | null,
  timeStamp: string | undefined,
  dateFormat: string
): string => {
  return date != null
    ? timeStamp !== ''
      ? dayjs.utc(date).format(dateFormat)
      : dayjs.utc(date).format(dateFormat).toString()
    : ''
}

export const PreviewSegmentsList = (segments: string[], options: SelectOption[]): ReactNode =>
  useMemo(() => {
    const set = new Set(segments)
    return options.map(
      (item) => {
        return set.has(item.id) && (
          <Typography key={item.id}>{item.label}</Typography>
        )
      }
    )
  }, [segments, options])

export const handleDateRangeChange = (
  selectedDateRange: Array<Date | null>,
  setStartDate: Dispatch<SetStateAction<Date | null>>,
  setEndDate: Dispatch<SetStateAction<Date | null>>
): DateRange => {
  const [startDate, endDate] = selectedDateRange
  let endDateString = ''
  let startDateString = ''

  setStartDate(startDate)
  setEndDate(endDate)

  startDate != null &&
    (startDateString = dayjs.utc(startDate).utc(true).startOf('day').toISOString())

  endDate != null &&
    (endDateString = dayjs.utc(endDate).utc(true).endOf('day').toISOString())

  return { startDateString, endDateString }
}
export const IconComponent = ({ icon }: IconProps): ReactElement | undefined => {
  const Icon = icon != null ? MUIcon[icon] : undefined
  return Icon != null ? <Icon fontSize='medium' className='single-icon-comp' /> : undefined
}
export const isExpiredDatePassed = (
  expirePointsDate: string | null | Dayjs | undefined
): boolean => {
  const currentTime = dayjs.utc()
  if (expirePointsDate != null) {
    return currentTime.isAfter(dayjs.utc(expirePointsDate))
  }
  return false
}

export const processCSVInputFile = (
  file: Blob,
  setValue: Dispatch<SetStateAction<string[]>>
): void => {
  const reader = new FileReader()
  reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
    const str = readerEvent?.target?.result as string

    const fileRows = str.replaceAll('\r', '').replaceAll('"', '').split('\n')
    const fileHeaders = fileRows[0].split(',')
    const columnsToKeep = ['userid', 'codes']

    const filteredRows = fileRows.map((row) => {
      const values = row.split(',')
      const filteredValues = values.filter((value, index) => {
        const sanitizedHeader = fileHeaders[index].toLowerCase().trim()
        return columnsToKeep.includes(sanitizedHeader)
      })
      return filteredValues.join(',')
    })
    setValue(filteredRows)
  }
  reader.readAsText(file)
}

export const generateRandomCodes = (
  prefix: string = '',
  length: number,
  numOfCodes: number,
  excludedCharacters: string[]
): string[] => {
  const codes: string[] = []
  const characters = excludedCharacters.flatMap((value) => value.split(''))
  const nanoid = customAlphabet(
    NANO_ID_ALPHABET.split('')
      .filter(
        (char) =>
          !characters.includes(char.toLowerCase()) &&
          !characters.includes(char.toUpperCase())
      )
      .join('')
  )
  for (let i = 0; i < numOfCodes; i++) {
    codes.push(prefix + nanoid(length))
  }
  return codes
}

export const onCategorySelectChange = (
  event: SelectChangeEvent<string[]> | SelectChangeEvent,
  handleFormChange: (params: { categories: string[] | [] }) => void
): void => {
  const { value } = event.target as { value: string[] }
  const categories = value.includes('') ? [] : value
  handleFormChange({ categories })
}

export const AgenciesSelectItems = (list: AgenciesInterface[]): ReactNode =>
  useMemo(() => {
    return list.map((item) => (
      <MenuItem key={item.AgencyID} value={item.AgencyID}>
        {item.Name}
      </MenuItem>
    ))
  }, [list])

export const StaticSelectItems = (items: {
  [key: string]: string
}): ReactNode =>
  useMemo(() => {
    return Object.entries(items).map(([k, v]) => (
      <MenuItem key={k} value={v}>
        {v}
      </MenuItem>
    ))
  }, [items])

export const containsText = (text: string, searchText: string): boolean =>
  text.toLowerCase().includes(searchText.toLowerCase())

export const validationErrorsDisplay = (
  errors: string[]
): ReactElement[] | boolean =>
  Array.isArray(errors) && errors?.length > 0 &&
    errors.map((error, index) => (
      <FormHelperText className='mt-1' error key={`${error}-${index}`}>
        {error}
      </FormHelperText>
    ))

export const errorDisplay = (errorMessage: string | string[] | null): boolean | ReactElement =>
  errorMessage !== '' && (
    <FormHelperText className='mt-1' error>
      {errorMessage}
    </FormHelperText>
  )

export const blockerConditionWithoutStepper = (
  isDirty: boolean,
  currentLocation: string,
  isLeavingDialogOpen: boolean,
  setOpenConfirmLeavingDialog: (value: SetStateAction<boolean>) => void,
  nextLocation?: string
): boolean => {
  if (isDirty && nextLocation !== currentLocation && !isLeavingDialogOpen) {
    setOpenConfirmLeavingDialog(true)
    return true
  }
  return false
}

export const handleFormSubmit = async<T extends FieldValues> (
  event: React.FormEvent,
  trigger: UseFormTrigger<T>,
  onSubmit: (() => Promise<void>) | (() => void),
  errors: {}
): Promise<void> => {
  event.preventDefault()
  await trigger()
  if (Object.keys(errors).length === 0) {
    await onSubmit()
  }
}

export const handleSliceErrors = (error: any, rejectWithValue: Function): any => {
  if (error.response.data.name === VALIDATION_ERROR) {
    error.message = error.response.data.errors
  } else {
    error.message = error.response.data.error_description
  }
  return handleRejectWithReport(rejectWithValue, error)
}

export type ReorderList = Array<CampaignCategoryInterface | OfferCategoriesInterface | OffersInterface | CampaignsInterface>
export type ReorderItem = CampaignCategoryInterface | OfferCategoriesInterface | OffersInterface | CampaignsInterface

export const handleDrag = (result: DropResult,
  list: ReorderList,
  setUpdatedList: (value: ReorderList) => void,
  isFeatured: boolean): void => {
  if (
    result.destination == null ||
    result.destination.index >= list.length
  ) {
    return
  }
  const { source, destination } = result
  const itemsArray = [...list]
  const sIndex = source.index
  const dIndex = destination.index
  const updatedItemsArray =
    sIndex < dIndex
      ? reorder(sIndex, dIndex, itemsArray, DOWN, list, isFeatured)
      : reorder(sIndex, dIndex, itemsArray, UP, list, isFeatured)
  setUpdatedList(updatedItemsArray)
}

export const reorder = (
  sIndex: number,
  dIndex: number,
  itemsArray: ReorderList,
  direction: string,
  list: ReorderList,
  isFeatured: boolean
): ReorderList => {
  const webUiPositionKeyName = getUiPositionName(
    isFeatured
  )
  let itemsIndexDiff = direction === UP ? sIndex - dIndex : dIndex - sIndex
  let substituteItem = list[sIndex]
  while (itemsIndexDiff >= 0) {
    const placeholder = itemsArray[dIndex]
    itemsArray[dIndex] = substituteItem
    substituteItem = placeholder
    itemsIndexDiff -= 1
    direction === UP ? (dIndex += 1) : (dIndex -= 1)
  }
  return itemsArray.map((record, index) => {
    const recordClone = _.cloneDeep(record)
    return { ...recordClone, [webUiPositionKeyName]: index + 1 }
  })
}

export const modifyDragList = (
  list: ReorderList,
  setUpdatedList: (value: ReorderList) => void,
  name: string,
  isOffer: boolean,
  isCampaign: boolean,
  isFeatured: boolean
): void => {
  const webUiPositionKeyName = getUiPositionName(
    isFeatured
  ) as keyof ReorderItem
  const tempArray = list.map((item) => {
    return {
      ...item,
      [webUiPositionKeyName]:
        parseInt((item[webUiPositionKeyName] ?? 0) as string) + 1
    }
  })
  setUpdatedList([
    {
      [getKeyName(isOffer, isCampaign, KEY_NAME_TYPE.Title)]: name,
      [webUiPositionKeyName]: 1
    },
    ...tempArray
  ])
}

export const handleReorderSave = (
  name: string | undefined,
  list: ReorderList,
  setWebUiPosition: (arg0: string) => void,
  setOpenUIreorder: (arg0: boolean) => void,
  setUpdatedUIs: (arg0: { [key: number]: string | number | undefined }) => void,
  setListWithNewOrder: (arg0: ReorderList) => void,
  isOffer: boolean,
  isCampaign: boolean,
  isFeatured: boolean,
  isEdit: boolean,
  id?: number
): void => {
  const webUiPositionKeyName = getUiPositionName(
    isFeatured
  ) as keyof ReorderItem
  const newUiPosition = list.find((item) => {
    const nameKey = getItemKey(
      item,
      getKeyName(isOffer, isCampaign, KEY_NAME_TYPE.Title)
    )
    if (isEdit) {
      const itemIDKey = getItemKey(
        item,
        getKeyName(isOffer, isCampaign, KEY_NAME_TYPE.ID)
      )
      return isValid(itemIDKey)
        ? item[itemIDKey as keyof ReorderItem] === id
        : item[nameKey as keyof ReorderItem] === name
    } else {
      return item[nameKey as keyof ReorderItem] === name
    }
  })
  setWebUiPosition(
    newUiPosition != null ? (newUiPosition[webUiPositionKeyName] as string) : ''
  )
  setOpenUIreorder(false)
  const updatedUIPositions: { [key: number]: string | number | undefined } = {}
  list.forEach((item) => {
    const itemIDKey = getItemKey(
      item,
      getKeyName(isOffer, isCampaign, KEY_NAME_TYPE.ID)
    )
    if (itemIDKey != null && itemIDKey.length > 0) {
      const updateItemID = item[itemIDKey as keyof ReorderItem]
      updatedUIPositions[updateItemID as number] = item[webUiPositionKeyName]
    }
  })
  setUpdatedUIs(updatedUIPositions)
  setListWithNewOrder(list)
}

export const getItemKey = (item: ReorderItem, keyName: string): string => {
  const itemKey = Object.keys(item).find((key) =>
    key.toUpperCase().includes(keyName.toUpperCase())
  )
  return itemKey ?? ''
}

export const onFormChangeOfferWrapper = (
  value: string,
  name: string,
  onFormChange: (
    value: OfferFormInterface[keyof OfferFormInterface],
    name: string
  ) => void,
  onChange: (value: string, name: string) => void,
  resetUIs?: () => void
): void => {
  onFormChange(value, name as keyof OfferFormInterface)
  onChange(value, name)
  resetUIs?.()
}

export const onFormChangeCampaignWrapper = (
  value: string,
  name: string,
  onFormChange: (
    value: CampaignFormInterface[keyof CampaignFormInterface] | Dayjs,
    name: string
  ) => void,
  onChange: (value: string, name: string) => void,
  resetUIs?: () => void
): void => {
  onFormChange(value, name as keyof OfferFormInterface)
  onChange(value, name)
  resetUIs?.()
}

export const onHandleChangeCampaignWrapper = (
  value: | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent<string | null | string[]>,
  name: string,
  handleChange: (
    e:
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | SelectChangeEvent<string | null | string[]>,
    name: keyof CampaignFormInterface,
    onChange: (val: string | string[] | null, name?: string) => void,
    isNumberOnly?: boolean
  ) => void,
  onChange: (val: string | string[] | null, name?: string) => void,
  resetUIs?: () => void
): void => {
  handleChange(value, name as keyof CampaignFormInterface, onChange)
  resetUIs?.()
}

export const isValid = (value: string | undefined | null): boolean => {
  return value != null && value !== ''
}

export const getUpdatedList = (
  isEdit: boolean,
  list: ReorderList,
  keyName: string,
  title: string | undefined,
  currentID?: number | undefined
): ReorderList => {
  return list.map((item) => {
    const iDkey = getItemKey(item, keyName)
    if (iDkey != null) {
      const itemID = item[iDkey as keyof ReorderItem]
      const shouldUpdateTitle = isEdit
        ? itemID === currentID || itemID === null
        : itemID === null

      if (shouldUpdateTitle) {
        return { ...item, Title: title }
      }
    }
    return item
  })
}
export const getUiPositionName = (isFeatured: boolean): string => {
  return isFeatured
    ? REORDER_KEY_NAME.FeaturedUiPosition
    : REORDER_KEY_NAME.WebUiPosition
}

export const modifyUIObjects = (list: {
  [key: number]: string | number | undefined
}, isFeatured: boolean, type: string): Object[] => {
  return Object.keys(list).map((id) => {
    return {
      [type === UI_TYPE.CAMPAIGN
        ? REORDER_KEY_NAME.CampaignIDKeyWord
        : REORDER_KEY_NAME.OfferIDKeyWord]: id,
      [getUiPositionName(isFeatured)]: list[parseInt(id)]
    }
  })
}
