import { FC, useCallback } from 'react'
import {
  CouponsListParams,
  RepeatableCouponsInterface
} from '../interfaces/offers'
import {
  PER_PAGE_COUPONS_TABLE,
  FIRST_PAGE_COUPONS_TABLE,
  RepeatableSettingsListColumns
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import { setClassNameForTable } from '../../commons/utils/utils'
import './OfferRepeatableSettings.scss'

interface RepeatableSettingsListProps {
  onPageChange: (changedData: CouponsListParams) => void
  page: number
  perPage: number
  total: number
  repeatableCouponsList: RepeatableCouponsInterface[]
}

const COLUMNS = Object.values(RepeatableSettingsListColumns)

const RepeatableSettingsList: FC<RepeatableSettingsListProps> = ({
  page,
  perPage,
  total,
  repeatableCouponsList,
  onPageChange
}) => {
  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {repeatableCouponsList.length > 0 &&
          (
            <TableBody>
              {repeatableCouponsList?.map((row) => (
                <TableRow className='row-with-actions' key={row.CouponID}>
                  <TableCell className='body-cell'>{row.CouponCode}</TableCell>
                  <TableCell className='center-cell body-cell'>
                    {row.GlobalRepeatableLimit}
                  </TableCell>
                  <TableCell className='center-cell body-cell'>
                    {row.availableCodes}
                  </TableCell>
                  <TableCell className='body-cell pl-4'>
                    {row.redeemedCodes}
                  </TableCell>
                  <TableCell className='body-cell' />
                </TableRow>
              ))}
            </TableBody>
          )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_COUPONS_TABLE}
        perPage={perPage ?? PER_PAGE_COUPONS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={repeatableCouponsList.length}
      />
    </TableContainer>
  )
}

export default RepeatableSettingsList
