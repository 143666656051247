import { ReportHandler } from 'web-vitals'
import { ErrorLevels } from './app/core/error/constants'
import { reportError } from './app/core/error/handler'

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if ((onPerfEntry != null) && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    }).catch(err => {
      reportError(err, ErrorLevels.Debug)
    })
  }
}

export default reportWebVitals
