import { FC, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { OffersInterface, OffersListParams } from '../interfaces/offers'
import {
  AgencyOffersListColumns,
  TenantOffersListColumns,
  OffersStatuses,
  FIRST_PAGE_OFFERS_TABLE,
  PER_PAGE_OFFERS_TABLE,
  OFFER_TYPE_UNIQUE_CODE
} from '../constants'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import StatusIndicator from '../../ui/status-indicator/StatusIndicator'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { constants } from '../../commons/constants/constants'
import {
  checkIsNotEmpty,
  setClassNameForTable
} from '../../commons/utils/utils'
import { useAppSelector } from '../../core/redux/hooks'
import { RoutePath } from '../../core/routes/routePaths'
import { RoleType } from '../../core/routes/constants'
import { setStatusColor } from '../../offer-categories/utils'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'
import { t } from 'i18next'
import { offersListActionItems } from '../utils'

interface OffersListMainProps {
  onPageChange: (changedData: OffersListParams) => void
  page?: number
  perPage?: number
  total: number
  offersList?: OffersInterface[]
}

const OffersListMain: FC<OffersListMainProps> = ({
  page,
  perPage,
  total,
  offersList,
  onPageChange
}) => {
  const { userType, isAgencyEnabled } = useAppSelector((state) => ({
    userType: state.auth.userType,
    isAgencyEnabled: state.auth.isAgencyEnabled
  }))

  const COLUMNS = Object.values(
    userType === RoleType.TENANT && isAgencyEnabled
      ? TenantOffersListColumns
      : AgencyOffersListColumns
  )

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {offersList?.length != null && offersList.length > 0 && (
          <TableBody>
            {offersList?.map((row) => (
              <TableRow className='row-with-actions' key={row.OfferID}>
                <TableCell className='centered-cell body-cell'>
                  <Link to={`${RoutePath.Offers}/${row.OfferID ?? ''}`}>
                    {row.OfferID}
                  </Link>
                </TableCell>
                <TableCell className='body-cell'>
                  <Link to={`${RoutePath.Offers}/${row.OfferID ?? ''}`}>
                    {checkIsNotEmpty(row.WebTitle) ? row.WebTitle : row.Title}
                  </Link>
                </TableCell>
                {userType === RoleType.TENANT && isAgencyEnabled && (
                  <TableCell className='body-cell'>
                    {row.AgencyIconUrl != null && (
                      <img
                        alt='agency-icon'
                        className='rounded-image-list'
                        src={row.AgencyIconUrl}
                      />
                    )}
                    <Link to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}>
                      {row.AgencyName !== '' && row.AgencyName}
                    </Link>
                  </TableCell>
                )}
                <TableCell className='body-cell'>{row.CategoryName}</TableCell>
                <TableCell className='body-cell centered-cell'>
                  {row.WebUiPosition}
                </TableCell>
                <TableCell className='body-cell'>
                  {row.offerstatus != null && (
                    <StatusIndicator
                      color={setStatusColor(row.offerstatus as OffersStatuses)}
                      title={row.offerstatus}
                    />
                  )}
                </TableCell>
                <TableCell className='body-cell centered-cell'>
                  {row.OfferType === OFFER_TYPE_UNIQUE_CODE
                    ? row.AvailableCouponsCount
                    : '-'}
                </TableCell>
                <TableCell className='body-cell centered-cell'>
                  {row.RedeemedCouponsCount}
                </TableCell>
                <TableCell className='body-cell'>
                  <Box className='list-actions flex-center'>
                    <SelfServeActionItem
                      path={`${RoutePath.Offers}/${row.OfferID ?? ''}${
                        RoutePath.Coupons
                      }`}
                      title={t`Coupons`}
                      icon={<ConfirmationNumberOutlinedIcon />}
                    />
                    {offersListActionItems(row.OfferID?.toString() ?? '').map(
                      (item) => {
                        return (
                          <SelfServeActionItem
                            path={item.path}
                            title={item.title}
                            icon={item.icon}
                            key={item.title}
                          />
                        )
                      }
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_OFFERS_TABLE}
        perPage={perPage ?? PER_PAGE_OFFERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={offersList?.length}
      />
    </TableContainer>
  )
}

export default OffersListMain
