import { FC, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { useSearchParams } from 'react-router-dom'
import { UsersListParams } from './interfaces/users'
import { getValueForForm } from '../commons/utils/utils'
import { cleanUsersForCSV, getUsers, getUsersForCSV } from './slices'
import { FIRST_PAGE_USERS_TABLE, PER_PAGE_USERS_TABLE } from './constants'
import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelfServeButton from '../ui/button/Button'
import { CSVLink } from 'react-csv'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import UsersListHeader from './UsersListHeader'
import UsersList from './UsersList'
import { PAGE, constants } from '../commons/constants/constants'

const Users: FC<{}> = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { users, totalUsersList, usersForCSV, usersForCSVLoading } =
    useAppSelector((state) => state.users)
  const { t } = useTranslation()
  const csvRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const handleFormChange = ({
    search,
    page,
    perPage,
    status,
    joinDateStart,
    joinDateEnd
  }: UsersListParams): void => {
    Object.entries({
      search,
      page,
      perPage,
      status,
      joinDateStart,
      joinDateEnd
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      Array.isArray(checkedValue) &&
        checkedValue.length === 0 &&
        setSearchParams((params) => {
          params.delete(k)
          return params
        })
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    if (!usersForCSVLoading && usersForCSV.length > 0) {
      csvRef?.current?.link.click()
    }
  }, [usersForCSVLoading, usersForCSV])

  const csvClick = (): void => {
    void dispatch(
      getUsersForCSV({
        search: searchParams.get('search') ?? '',
        page: undefined,
        perPage: undefined,
        status: searchParams.get('status') ?? '',
        joinDateStart: searchParams.get('joinDateStart') ?? '',
        joinDateEnd: searchParams.get('joinDateEnd') ?? ''
      })
    )
  }

  useEffect(() => {
    void dispatch(
      getUsers({
        search: searchParams.get('search') ?? '',
        page: parseInt(
          searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
        ),
        status: searchParams.get('status') ?? '',
        joinDateStart: searchParams.get('joinDateStart') ?? '',
        joinDateEnd: searchParams.get('joinDateEnd') ?? ''
      })
    )
  }, [dispatch, searchParams])

  useEffect(() => {
    return () => {
      dispatch(cleanUsersForCSV())
    }
  }, [dispatch])

  return users != null
    ? (
      <Box className='flex-column'>
        <Box className='flex-row space-between'>
          <h1 className='title'>{t`Users`}</h1>
          <Box>
            <SelfServeButton
              id='generate-users-report'
              className='mr-1'
              variant='contained'
              color='secondary'
              onClick={csvClick}
            >
              <FileDownloadOutlinedIcon
                fontSize='small'
                className='button-icon'
              />{' '}
              {t`ExportList`}
            </SelfServeButton>
            <CSVLink
              data={usersForCSV.map((item) => {
                return {
                  UserID: item.UserID,
                  VelociaID: item.VelociaID,
                  UserIDAtTenant: item.UserIDAtTenant,
                  SignUpDate: item.CreatedAtUTC,
                  JoinDate: item.JoinDate,
                  Status: item.Status,
                  PhoneNumberVerified:
                  item.IsPhoneVerified === true ? t`Yes` : t`No`,
                  BalancePoints: item.UsersBalance
                }
              })}
              ref={csvRef}
              filename={`users-${Date.now()}.csv`}
            />
          </Box>
        </Box>
        <UsersListHeader
          search={searchParams.get('search') ?? ''}
          status={searchParams.get('status') ?? ''}
          joinDateStart={searchParams.get('joinDateStart') ?? ''}
          joinDateEnd={searchParams.get('joinDateEnd') ?? ''}
          handleFormChange={handleFormChange}
        />
        <UsersList
          usersList={users}
          total={totalUsersList}
          onPageChange={handleFormChange}
          page={parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          )}
          perPage={parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          )}
        />
      </Box>
      )
    : (
      <CircularProgress />
      )
}

export default Users
