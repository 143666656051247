import { axiosInstance } from '../core/api/init'
import { RoutePath } from '../core/routes/routePaths'
import {
  CouponsInterface,
  CouponsListParams,
  OffersListParams,
  OffersListResponse,
  OffersInterface,
  RepeatableCouponsListResponse,
  OfferFormInterface,
  RepeatableCouponsAddedInterface,
  CouponUpdateParamsInterface
} from './interfaces/offers'

export const offersService = {
  getOffers: async (params: OffersListParams): Promise<OffersListResponse> => {
    const {
      perPage,
      page,
      tenantID,
      agencyID,
      categories,
      search,
      offerType,
      status
    } = params
    const res = await axiosInstance.get('/offers', {
      params: {
        perPage,
        page,
        tenantID,
        agencyID,
        categories:
          categories != null && categories?.length > 0
            ? JSON.stringify(categories)
            : null,
        search,
        offerType,
        status
      }
    })
    return res.data
  },
  createOffer: async (params: OfferFormInterface): Promise<OffersInterface> => {
    const res = await axiosInstance.post('offers', {
      Name: params.offerName,
      Title: params.title,
      OfferImageUrl: params.thumbnailUrl,
      OfferImageID: params.thumbnailIDUpload,
      ListedPoints: params.listedPoints,
      RealPoints: params.realPoints,
      Description: params.description,
      AgencyID: params.agencyID,
      TenantID: params.tenantID,
      Category: params.categoryID,
      UiPosition: params.webUiPosition,
      OfferIconUrl: params.offerIconUrl,
      OfferIconID: params.offerIconUpload,
      Instruction: params.instructions,
      RedirectionLink: params.redirectionLink,
      RepeatableCoupon: params.isARepeatableCoupon,
      Redemptionlimt: params.hasRedemptionLimit,
      RedeemEarnTimes: params.offerRedeemTimesCount,
      RedeemEarnDays: params.offerRedeemTimesDays,
      RedeemMax: params.maxTimeRewardPerUser,
      StartTimestampUTC: params.startTimeStampUTC,
      EndTimestampUTC: params.endTimeStampUTC,
      AvailableSegments: params.segmentsIncluded,
      ExcludeSegments: params.segmentsExcluded,
      StartDate: params.startDate,
      EndDate: params.endDate,
      updatedUIs: params.UpdatedUIs,
      userType: params.userType
    })
    return res.data
  },
  editOffer: async (
    params: OfferFormInterface,
    id: string
  ): Promise<OffersInterface> => {
    const res = await axiosInstance.put(`/offers/${id}`, {
      OfferID: id,
      Name: params.offerName,
      title: params.title,
      offerImageID: params.thumbnailIDUpload,
      listedPoint: params.listedPoints,
      realPoint: params.realPoints,
      Description: params.description,
      AgencyID: params.agencyID,
      Category: params.categoryID,
      UiPosition: params.webUiPosition,
      offerIconID: params.offerIconUpload,
      instruction: params.instructions,
      redirectionLink: params.redirectionLink,
      RedeemLimit: params.hasRedemptionLimit,
      timeLimit: params.offerRedeemTimesCount,
      dayLimit: params.offerRedeemTimesDays,
      userLimit: params.maxTimeRewardPerUser,
      StartTimestampUTC: params.startTimeStampUTC,
      EndTimestampUTC: params.endTimeStampUTC,
      StartDate: params.startDate,
      EndDate: params.endDate,
      updatedUIs: params.UpdatedUIs,
      userType: params.userType,
      TenantID: params.tenantID
    })
    return res.data
  },
  getLiveOfferByID: async (offerID: number): Promise<OffersInterface> => {
    const res = await axiosInstance.get(`${RoutePath.Offers}/${offerID}`)
    return res.data
  },
  getCouponsByOfferID: async (
    params: CouponsListParams,
    id: string
  ): Promise<CouponsInterface> => {
    const res = await axiosInstance.get(
      `${RoutePath.Offers}/${id}${RoutePath.Coupons}`,
      {
        params: {
          perPage: params.perPage,
          page: params.page,
          search: params.search,
          status: params.status,
          redeemDateStart: params.redeemDateStart,
          redeemDateEnd: params.redeemDateEnd
        }
      }
    )
    return res.data
  },
  getRepeatableCouponsByOfferID: async (
    params: CouponsListParams,
    id: string
  ): Promise<RepeatableCouponsListResponse> => {
    const res = await axiosInstance.get(
      `${RoutePath.Offers}/${id}${RoutePath.RepeatableSettings}`,
      {
        params: {
          perPage: params.perPage,
          page: params.page
        }
      }
    )
    return res.data
  },
  deleteAllAvailableCouponsByOfferID: async (
    id: string
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `/offers/${id}/coupons`
    )
    return res.data
  },
  deleteCouponByOfferID: async (
    params: CouponUpdateParamsInterface
  ): Promise<void> => {
    const { OfferID, CouponID } = params
    const res = await axiosInstance.delete(
      `/offers/${OfferID}/coupons/${CouponID}`
    )
    return res.data
  },
  refundCouponByID: async (
    params: CouponUpdateParamsInterface
  ): Promise<void> => {
    const { OfferID, CouponID } = params
    const res = await axiosInstance.put(
      `/offers/${OfferID}/coupons/${CouponID}`
    )
    return res.data
  },
  getOfferDetailsByID: async (offerID: string): Promise<OffersInterface> => {
    const res = await axiosInstance.get(`${RoutePath.Offers}/${offerID}`)
    return res.data
  },
  editOfferStatus: async (
    OfferID: string,
    offerstatus: string
  ): Promise<OffersInterface> => {
    const res = await axiosInstance.patch(`/offers/${OfferID}/status`, {
      offerstatus
    })
    return res.data
  },
  removeOfferFromTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<OffersInterface> => {
    const res = await axiosInstance.delete(
      `/offers/${id}/target-group/${targetGroupID}`
    )
    return res.data
  },
  addOfferToTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<OffersInterface> => {
    const res = await axiosInstance.post(
      `/offers/${id}/target-group/${targetGroupID}/include`
    )
    return res.data
  },
  excludeOfferFromTargetGroup: async (
    id: string,
    targetGroupID: string
  ): Promise<OffersInterface> => {
    const res = await axiosInstance.post(
      `/offers/${id}/target-group/${targetGroupID}/exclude`
    )
    return res.data
  },
  addCouponsByOfferID: async (
    couponCodes: string[],
    offerID: string
  ): Promise<void> => {
    const res = await axiosInstance.post(`/offers/${offerID}/coupons`, {
      couponCodes
    })
    return res.data
  },
  addRepeatableCouponsByOfferID: async (
    codes: RepeatableCouponsAddedInterface[],
    OfferID: string
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/offers/${OfferID}/repeatable-coupons`,
      {
        codes
      }
    )
    return res.data
  }
}

export default offersService
