import { FC } from 'react'
import SelfServeButton from '../../ui/button/Button'
import { useTranslation } from 'react-i18next'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'

interface CampaignPreviewTitleBoxProps {
  name: string
  toStep: number
  handleVisitStep: (stepNumber: number) => void
}

const CampaignPreviewTitleBox: FC<CampaignPreviewTitleBoxProps> = ({
  name,
  toStep,
  handleVisitStep
}) => {
  const { t } = useTranslation()
  return (
    <div className='preview-box-header'>
      <p className='preview-title'>{name}</p>
      <SelfServeButton
        id='campaign-step-0'
        className='preview-edit-btn'
        onClick={() => handleVisitStep(toStep)}
        variant='contained'
        color='secondary'
      >
        <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
      </SelfServeButton>
    </div>
  )
}

export default CampaignPreviewTitleBox
