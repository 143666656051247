import { Box, FormHelperText } from '@mui/material'
import { FC, ReactElement, useEffect } from 'react'
import '../segments.scss'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import {
  SegmentsFormStepsProps,
  TargetGroupsInterface
} from '../interfaces/segments'
import { getAllAgencies } from '../../agencies/slices'
import SelfServeStepper from '../../ui/stepper/Stepper'
import {
  GenerationMethodType,
  STEPS_FOR_EDIT_REWARDS_SEGMENTS_STEPPER
} from '../constants'
import { constants } from '../../commons/constants/constants'
import _ from 'lodash'
import SegmentRewardsInformationStep from './SegmentRewardsInformationStep'
import SegmentRewardsUploadListEdit from './SegmentRewardsUploadListEdit'
import dayjs from 'dayjs'
import SegmentRewardsAutomaticEdit from './SegmentRewardsAutomaticEdit'
import { setActiveStep } from '../slices'

const SegmentRewardsEdit: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  onDiscard,
  onSubmit
}) => {
  const {
    segments: {
      errors,
      activeStep,
      segmentsForm: { generationMethod }
    },
    auth: { agencyID: tenantID }
  } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(
      getAllAgencies({
        tenantID
      })
    )
  }, [tenantID, dispatch])

  const renderStepper = (
    <SelfServeStepper
      nonLinear={false}
      steps={STEPS_FOR_EDIT_REWARDS_SEGMENTS_STEPPER}
      setActiveStep={(step) => {
        dispatch(setActiveStep(step))
      }}
      activeStep={activeStep}
    />
  )

  const validationErrorsDisplay = (): ReactElement | null =>
    !_.isEmpty(errors)
      ? (
        <FormHelperText className='mt-1' error>
          {errors}
        </FormHelperText>
        )
      : null

  const handleNext = (): void => {
    dispatch(setActiveStep(activeStep + 1))
  }

  const handleBack = (): void => {
    dispatch(setActiveStep(activeStep - 1))
  }

  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof TargetGroupsInterface,
    onChange?: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    if (onChange != null) {
      onChange(date)
    }
  }

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return (
          <SegmentRewardsInformationStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleDateChange={handleDateChange}
            onFormChange={onFormChange}
            onDiscard={onDiscard}
            isEdit
          />
        )
      case 1:
        return _.isEqual(
          generationMethod,
          GenerationMethodType.AUTOMATIC_CODES
        )
          ? (
            <SegmentRewardsAutomaticEdit
              onFormChange={onFormChange}
              onSubmit={onSubmit}
              onDiscard={onDiscard}
              handleBack={handleBack}
            />
            )
          : (
            <SegmentRewardsUploadListEdit
              onFormChange={onFormChange}
              onSubmit={onSubmit}
              onDiscard={onDiscard}
              handleBack={handleBack}
            />
            )
      default:
        return null
    }
  }

  return (
    <Box>
      {renderStepper}
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        {renderSwitch()}
        {validationErrorsDisplay()}
      </Box>
    </Box>
  )
}

export default SegmentRewardsEdit
