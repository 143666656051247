import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsTypes } from '../constants'
import { ReactElement } from 'react'
import featured from '../../../assets/images/Featured.png'
import './DetailsCampaignPhonePreview.scss'
import CategoriesCampaignPreview from './CategoriesCampaignPreview'
import { useAppSelector } from '../../core/redux/hooks'
import { CampaignPhonePreviewProps } from '../interfaces/campaigns'
import { getCampaignPreviewObject } from '../utils'
import FeaturedCampaignPreview from './FeaturedCampaignPreview'
import _ from 'lodash'

const CampaignCardPhonePreview = ({
  isFromCreateEdit
}: CampaignPhonePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const { campaignForm, campaignSelected, categories } = useAppSelector(
    (state) => ({
      campaignForm: state.campaigns.campaignForm,
      campaignSelected: state.campaigns.campaignSelected,
      categories: state.campaignCategories.categories
    })
  )

  const campaign = getCampaignPreviewObject(
    campaignForm,
    campaignSelected,
    categories,
    isFromCreateEdit
  )

  const points =
    campaign.rewardPerUser != null ? _.toInteger(campaign.rewardPerUser) : 0
  const isStreakCampaign = campaign.campaignType === CampaignsTypes.STREAK

  return (
    <Box className='campaign-phone-preview'>
      <Box display='flex' justifyContent='start'>
        <img alt='featured-icon' src={featured} className='featured-icon' />
        <Typography className='featured'>{t`Featured`}</Typography>
      </Box>
      <FeaturedCampaignPreview
        campaign={campaign}
        isStreakCampaign={isStreakCampaign}
        points={points}
      />
      <CategoriesCampaignPreview
        campaign={campaign}
        isStreakCampaign={isStreakCampaign}
        points={points}
      />
    </Box>
  )
}

export default CampaignCardPhonePreview
