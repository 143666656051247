import { ReactElement, useState } from 'react'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Nullable } from '../../../type'
import { useTranslation } from 'react-i18next'
import './SelfServeDatePicker.scss'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('UTC')
const clear = 'clear'
const accept = 'accept'
interface SelfServeDatePickerProps {
  onChange: (date: string | Dayjs | null) => void
  dateTime?: string | undefined | null
  isFromEdit?: boolean
  dateFormat: string
  minDateTime?: Nullable<string> | undefined
  disabled: boolean
  placeholder?: string
}
const SelfServeDatePicker = ({
  onChange,
  dateTime,
  isFromEdit = false,
  dateFormat,
  minDateTime,
  disabled,
  placeholder
}: SelfServeDatePickerProps): ReactElement | null => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleTextFieldClick = (): void => {
    if (!disabled) {
      setOpen(true)
    }
  }
  return (
    <div className='ss-date-picker-box'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          className='ss-date-picker'
          timezone='UTC'
          value={
            isFromEdit
              ? dateTime != null
                ? dayjs.utc(dateTime)
                : null
              : dateTime
          }
          ampm={false}
          disablePast
          closeOnSelect={false}
          open={open}
          onOpen={() => handleTextFieldClick()}
          onClose={() => setOpen(false)}
          onChange={onChange}
          format={dateFormat}
          minDateTime={minDateTime ?? undefined}
          slotProps={{
            actionBar: {
              actions: [clear, accept]
            },
            textField: {
              placeholder: _.isEmpty(placeholder) ? t`SelectDate` : placeholder,
              onClick: () => handleTextFieldClick(),
              error: false
            }
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </div>
  )
}

export default SelfServeDatePicker
