import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  CampaignCategoriesListColumnsForTenants,
  CampaignCategoriesListColumnsForAgencies,
  FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE,
  PER_PAGE_CAMPAIGN_CATEGORIES_TABLE
} from './constants'
import { Link } from 'react-router-dom'
import './campaign-categories.scss'
import {
  CampaignCategoryInterface,
  CampaignCategoryListParams
} from './interfaces/campaign-categories'
import { Box } from '@mui/material'
import SelfServePagination from '../ui/pagination/SelfServePagination'
import { constants } from '../commons/constants/constants'
import { setClassNameForTable } from '../commons/utils/utils'
import { RoutePath } from '../core/routes/routePaths'
import PreviewButton from '../ui/button/PreviewButton'
import LiveCampaignPreview from '../campaigns/live-campaigns/LiveCampaignPreview'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { getCampaignPreviewByID } from '../campaigns/slices'
import SelfServeActionItem from '../ui/list-action-item/SelfServeActionItem'
import { categoriesListActionItems } from './utils'

interface CampaignCategoriesListProps {
  onPageChange: (changedData: CampaignCategoryListParams) => void
  page?: number
  perPage?: number
  total: number
  campaignCategoriesList: CampaignCategoryInterface[]
  isTenant: boolean
}

const CampaignCategoriesList: FC<CampaignCategoriesListProps> = ({
  page,
  perPage,
  total,
  campaignCategoriesList,
  onPageChange,
  isTenant
}) => {
  const dispatch = useAppDispatch()
  const { isAgencyEnabled } = useAppSelector((state) => state.auth)
  const COLUMNS = Object.values(
    isTenant && isAgencyEnabled
      ? CampaignCategoriesListColumnsForTenants
      : CampaignCategoriesListColumnsForAgencies
  )

  const loader = (campaignID?: number): void => {
    if (campaignID != null) {
      void dispatch(getCampaignPreviewByID(campaignID))
    }
  }

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {campaignCategoriesList.length > 0 && (
          <TableBody>
            {campaignCategoriesList.map(
              (row) =>
                row.CampaignCategoryID != null && (
                  <TableRow
                    className='row-with-actions'
                    key={row.CampaignCategoryID}
                  >
                    <TableCell className='body-cell centered-cell'>
                      <Link
                        to={`${RoutePath.CampaignCategories}/${row.CampaignCategoryID}`}
                      >
                        {row.CampaignCategoryID}
                      </Link>
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Link
                        to={`${RoutePath.CampaignCategories}/${row.CampaignCategoryID}`}
                      >
                        {row.CategoryName}
                      </Link>
                    </TableCell>
                    {isTenant && isAgencyEnabled && (
                      <TableCell className='body-cell'>
                        {row.AgencyImageUrl != null && (
                          <img
                            alt='campaign-category-logo'
                            className='rounded-image-list'
                            src={row.AgencyImageUrl}
                          />
                        )}
                        <Link
                          to={`${RoutePath.Agencies}/${row.AgencyID ?? ''}`}
                        >
                          {row.AgencyName}
                        </Link>
                      </TableCell>
                    )}
                    <TableCell className='body-cell centered-cell'>
                      {row.WebUiPosition}
                    </TableCell>
                    <TableCell className='body-cell centered-cell'>
                      {row.Url != null && (
                        <img
                          alt='campaign-category-logo'
                          className='square-image-list'
                          src={row.Url}
                        />
                      )}
                    </TableCell>
                    <TableCell className='body-cell'>
                      {row.ActiveCampaigns?.map((campaign) => {
                        return (
                          row.CampaignCategoryID != null && (
                            <PreviewButton
                              variant='contained'
                              id={`live-campaign-${campaign}`}
                              key={campaign}
                              color='secondary'
                              loader={() => loader(campaign)}
                              previewComponent={<LiveCampaignPreview />}
                              className='live-preview minw-3'
                            >
                              {campaign}
                            </PreviewButton>
                          )
                        )
                      })}
                    </TableCell>
                    <TableCell className='body-cell'>
                      <Box className='list-actions flex-center'>
                        {categoriesListActionItems(row.CampaignCategoryID).map(
                          (item) => {
                            return (
                              <SelfServeActionItem
                                path={item.path}
                                title={item.title}
                                icon={item.icon}
                                key={item.title}
                              />
                            )
                          }
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_CAMPAIGN_CATEGORIES_TABLE}
        perPage={perPage ?? PER_PAGE_CAMPAIGN_CATEGORIES_TABLE}
        onPageChange={(page) => onPageChange({ page })}
        onPerPageChange={(perPage) => onPageChange({ perPage })}
        total={total}
        length={campaignCategoriesList.length}
      />
    </TableContainer>
  )
}

export default CampaignCategoriesList
