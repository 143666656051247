import { FC, useMemo } from 'react'
import { AgenciesListSearch } from './interfaces/agencies'
import { LOCATIONS } from '../commons/constants/locations'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Form } from 'react-router-dom'
import { getCities } from './utils'
import { useTranslation } from 'react-i18next'
import { allText, selectMenuItem } from '../commons/utils/utils'
import SelfServeSearchField from '../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'

interface AgenciesListHeaderProps {
  handleFormChange: (changedData: AgenciesListSearch) => void
  search?: string
  city?: string
  state?: string
}

const AgenciesListHeader: FC<AgenciesListHeaderProps> = ({
  handleFormChange,
  search,
  city,
  state
}) => {
  const locations = LOCATIONS
  const { t } = useTranslation()

  const propagateFormChange = (changedData: AgenciesListSearch): void => {
    handleFormChange(changedData)
  }

  const statesSelectItems = useMemo(() => {
    return Object.keys(locations).map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }, [locations])

  const citySelectItems = useMemo(() => {
    return getCities(state ?? '').map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }, [state])

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={propagateFormChange} />
        </FormControl>
        <FormControl size='small' className='form-control'>
          <InputLabel shrink={false} id='state-label'>
            {state === '' && allText(t`StateForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              propagateFormChange({
                state: e.target.value as string
              })}
            value={state}
            menuItem={selectMenuItem(t`All`)}
            selectItems={statesSelectItems}
            inputProps='table-input'
            isFormSelect
          />
        </FormControl>
        <FormControl size='small' className='form-control'>
          <InputLabel shrink={false} id='city-label'>
            {city === '' && allText(t`CityForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              propagateFormChange({
                city: e.target.value as string
              })}
            value={city}
            menuItem={selectMenuItem(t`All`)}
            selectItems={citySelectItems}
            isFormSelect
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default AgenciesListHeader
