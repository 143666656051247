import { AuthForm } from '../../type'
import { axiosInstance } from '../core/api/init'

interface SignInResponse {
  userType: string
  authStatus: string
  userID: string
  message: string
  email: string
  name: string
  tenantID: string
  agencyID: string
  logoUrl: string
}

export const authService = {
  signIn: async (data: AuthForm): Promise<SignInResponse> => {
    return await axiosInstance
      .post('/auth/login', data)
      .then((response) => response.data)
      .catch((err) => {
        throw new Error(err.response?.data)
      })
  },
  forgotPassword: async (data: AuthForm): Promise<boolean> => {
    return await axiosInstance
      .post('/auth/forgot-password', data)
      .then((response) => response.data)
  },
  signOut: async (): Promise<void> => {
    return await axiosInstance
      .get('/auth/logout')
      .then((response) => response.data)
  }
}

export default authService
