import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterfaceProps } from './CampaignsDetails'
import { setDashIfEmpty } from '../../commons/utils/utils'
import { GetLimitValue } from '../utils'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'

const CampaignRewardsStep = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          <SelfServeROTextField
            id='pointsToReward'
            label={t`PointsToReward`}
            defaultValue={
              campaign.RewardPerUser != null
                ? +campaign.RewardPerUser
                : t`Dash`
            }
            showDivider
          />
          <SelfServeROTextField
            id='maxTimeRewardPerUser'
            label={t`MaxTimeRewardPerUser`}
            defaultValue={setDashIfEmpty(campaign.MaxClaimsRepetition)}
            showDivider
          />
          <SelfServeROTextField
            id='timesCanBeEarnedPerUser'
            label={t`TimesCanBeEarnedPerUser`}
            defaultValue={GetLimitValue(
              campaign.RepeatableBucketClaims,
              campaign.MaxBucketClaimsRepetition,
              campaign.campaignLimit
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignRewardsStep
