import { ReactElement, FC, useState } from 'react'
import { CouponsListParams } from '../../offers/interfaces/offers'
import { t } from 'i18next'
import { FormControl, InputLabel } from '@mui/material'
import { Form } from 'react-router-dom'
import { FilterCouponStatus } from '../../offers/constants'
import {
  StaticSelectItems,
  allText,
  checkIsNotEmpty,
  handleDateRangeChange,
  selectMenuItem
} from '../../commons/utils/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './OffersCoupons.scss'
import { DateRangeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

dayjs.extend(utc)
interface CouponsListHeaderProps {
  handleFormChange: (changedData: CouponsListParams) => void
  search?: string
  status?: string
  redeemDateStart: string
  redeemDateEnd: string
}

const CouponsListHeader: FC<CouponsListHeaderProps> = ({
  handleFormChange,
  search,
  status,
  redeemDateStart,
  redeemDateEnd
}): ReactElement => {
  const [startDate, setStartDate] = useState<null | Date>(
    checkIsNotEmpty(redeemDateStart) ? new Date(redeemDateStart) : null
  )
  const [endDate, setEndDate] = useState<null | Date>(
    checkIsNotEmpty(redeemDateEnd) ? new Date(redeemDateEnd) : null
  )

  const handleDateChange = (selectedDateRange: Array<Date | null>): void => {
    const { startDateString, endDateString } = handleDateRangeChange(
      selectedDateRange,
      setStartDate,
      setEndDate
    )

    handleFormChange({
      redeemDateStart: startDateString ?? '',
      redeemDateEnd: endDateString ?? ''
    })
  }

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control'>
          <label className='date-picker-box'>
            <DatePicker
              placeholderText={t`RedeemDate`}
              className='date-picker'
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              dateFormat='MMM dd, yyy'
              isClearable
            />
            <DateRangeOutlined
              className='picker-icon'
              color='action'
              fontSize='small'
            />
          </label>
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='status-label'>
            {(status == null || status === '') && allText(t`StatusForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ status: e.target.value as string })}
            value={status}
            menuItem={selectMenuItem(t`AllStatus`)}
            selectItems={StaticSelectItems(FilterCouponStatus)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default CouponsListHeader
