import * as React from 'react'
import Box from '@mui/material/Box'
import { useAppSelector } from '../../core/redux/hooks'
import { Avatar, Typography } from '@mui/material'
import { t } from 'i18next'

const CampaignCategoryDetailCard = (): React.ReactElement => {
  const {
    campaignCategorySelected: { Url, CategoryName, CampaignCategoryID }
  } = useAppSelector((state) => state.campaignCategories)

  return (
    <Box className='flex-column' margin='3em 3em'>
      <Avatar
        className='sidebar-avatar'
        src={Url}
        alt='category-logo'
      />
      <Typography variant='h5'>{CategoryName}</Typography>
      <Typography variant='subtitle2'>{t('CampaignCategorySidebarID', { CampaignCategoryID })}</Typography>
    </Box>
  )
}

export default CampaignCategoryDetailCard
