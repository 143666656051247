import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { IconButton, Tooltip, Box, Divider } from '@mui/material'
import { constants } from '../commons/constants/constants'
import {
  setClassNameForTable,
  convertStringToDateAndTime,
  formatDateAndTime
} from '../commons/utils/utils'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useTranslation } from 'react-i18next'
import {
  IncludeSegmentsListColumns,
  TargetGroupsListColumns
} from './constants'
import { TargetGroupsInterface } from './interfaces/segments'
import './segments.scss'
import { Link } from 'react-router-dom'
import { RoutePath } from '../core/routes/routePaths'

interface SegmentsListProps {
  segmentsList: TargetGroupsInterface[]
  removeSegmentAction: (targetGroupID?: string, name?: string) => void
  showRedemption?: boolean
  usersSegments?: boolean
}

const SegmentsList: FC<SegmentsListProps> = ({
  segmentsList,
  removeSegmentAction,
  showRedemption = false,
  usersSegments = false
}) => {
  const COLUMNS = Object.values(
    showRedemption ? IncludeSegmentsListColumns : TargetGroupsListColumns
  )
  const { t } = useTranslation()
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {segmentsList.map(
            ({
              TargetGroupID,
              Name,
              AddedTime,
              TargetGroupRedemptionCount,
              TargetGroupUserCount,
              Manual
            }) => {
              const { date, time } = convertStringToDateAndTime(AddedTime)
              return (
                <TableRow className='row-with-actions' key={TargetGroupID}>
                  <TableCell className='body-cell centered-cell'>
                    <Link to={`${RoutePath.Segments}/${TargetGroupID ?? ''}`}>
                      {TargetGroupID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Segments}/${TargetGroupID ?? ''}`}>
                      {Name}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    {formatDateAndTime(date, time)}
                  </TableCell>
                  <TableCell
                    className={`body-cell ${
                      showRedemption ? 'center-cell' : ''
                    }`}
                  >
                    {TargetGroupUserCount}
                  </TableCell>
                  {showRedemption && (
                    <TableCell className='body-cell'>
                      {TargetGroupRedemptionCount}
                    </TableCell>
                  )}
                  <TableCell className='body-cell'>
                    {((usersSegments && Manual === true) || !usersSegments) && (
                      <Box
                        className='list-actions'
                        display='flex'
                        justifyContent='flex-end'
                      >
                        <Tooltip title={t`RemoveSegment`}>
                          <IconButton
                            id='remove-segment'
                            aria-label='Remove Segment'
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            onClick={() =>
                              removeSegmentAction(TargetGroupID, Name)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )
            }
          )}
        </TableBody>
      </Table>
      <Divider className='divider mb-3' />
    </TableContainer>
  )
}
export default SegmentsList
