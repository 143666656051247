import { Typography } from '@mui/material'
import { ReactElement, useEffect } from 'react'
import SelfServeButton from '../ui/button/Button'
import './ErrorsPage.scss'
import { Link, useNavigate } from 'react-router-dom'
import { RoutePath } from '../core/routes/routePaths'
import { t } from 'i18next'
import { useAppSelector } from '../core/redux/hooks'
import authConstants from '../auth/constants'

interface ErrorsPageProps {
  errorCode: number
  errorTitle: string
  errorContent: string
}

const ErrorsPage = ({
  errorCode,
  errorContent,
  errorTitle
}: ErrorsPageProps): ReactElement => {
  const navigate = useNavigate()
  const { authStatus } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (authStatus === authConstants.AuthStatus.LOGGED_OUT) {
      navigate(RoutePath.Login)
    }
  }, [navigate, authStatus])

  return (
    <div className='error-box'>
      <Typography className='error-code'>{errorCode}</Typography>
      <Typography className='error-title'>{errorTitle}</Typography>
      <Typography className='error-content'>{errorContent}</Typography>
      <SelfServeButton
        variant='contained'
        id='go-to-dashboard'
        className='error-btn'
      >
        <Link to={RoutePath.Main}>{t`GoToDashboard`}</Link>
      </SelfServeButton>
    </div>
  )
}

export default ErrorsPage
