import { FC, useMemo } from 'react'
import { useAppSelector } from '../../core/redux/hooks'
import { Box, Typography, Divider } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import SelfServeButton from '../../ui/button/Button'
import {
  PreviewSegmentsList,
  checkIsNumPositive,
  convertStringToDateAndTime,
  formatDateAndTime,
  getRoleTypeByString
} from '../../commons/utils/utils'
import { userHasTenantPermission } from '../../core/routes/router'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import PreviewBox from './PreviewBox'

interface OfferFormPreviewStepProps {
  webUiPosition: string
  handleBack: () => void
  onDiscard: () => void
  onSubmit: () => void
  isLeavingDialogOpen: boolean
  onStepChange: (step: number) => void
  isEdit?: boolean
}

const OfferFormPreviewStep: FC<OfferFormPreviewStepProps> = ({
  webUiPosition,
  handleBack,
  onDiscard,
  onSubmit,
  onStepChange,
  isEdit = false
}) => {
  const {
    offerCreateForm: {
      offerName,
      title,
      thumbnailID,
      listedPoints,
      realPoints,
      description,
      agencyID,
      categoryID,
      offerIconID,
      instructions,
      redirectionLink,
      isARepeatableCoupon,
      hasRedemptionLimit,
      offerRedeemTimesCount,
      offerRedeemTimesDays,
      maxTimeRewardPerUser,
      startDate,
      endDate,
      segmentsIncluded,
      segmentsExcluded
    }
  } = useAppSelector((state) => state.offers)
  const { segmentOptions, agencies, offerCategories, userType } =
    useAppSelector((state) => ({
      segmentOptions: state.segments.segmentOptions,
      agencies: state.agencies.agencies,
      offerCategories: state.offerCategories.offerCategories,
      userType: state.auth.userType
    }))

  const role = getRoleTypeByString(userType)
  const { date: startDateFormatted, time: startTimeFormatted } =
    convertStringToDateAndTime(startDate)
  const { date: endDateFormatted, time: endTimeFormatted } =
    convertStringToDateAndTime(endDate)

  const agencySelected = useMemo(
    () =>
      agencies.map(
        (agency) =>
          agency.AgencyID === agencyID && (
            <Typography key={agency.AgencyID}>{agency.Name}</Typography>
          )
      ),
    [agencies, agencyID]
  )

  const offerCategorySelected = useMemo(
    () =>
      offerCategories.map(
        (category) =>
          category.OfferCategoryID === categoryID && (
            <Typography key={category.OfferCategoryID}>
              {category.CategoryName}
            </Typography>
          )
      ),
    [offerCategories, categoryID]
  )

  return (
    <>
      <Box maxWidth={constants.MAX_WIDTH_CARD}>
        <Box>
          <Box className='form-preview-box'>
            <h3 className='title'>{t`OfferInformation`}</h3>
            <SelfServeButton
              size='small'
              id='edit-offer-info-button'
              variant='contained'
              color='secondary'
              onClick={() => onStepChange(0)}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
          <Box
            display='flex'
            width={constants.MAX_WIDTH_CARD}
            className='create-offer-info-box'
          >
            <PreviewBox
              title={t`OfferName`}
              value={offerName ?? ''}
              classNameBox='border-bottom flex-item'
              flexBasis='15em'
            />
            <PreviewBox
              title={t`Title`}
              value={title ?? ''}
              classNameBox='border-left border-bottom flex-item'
              flexBasis='15em'
            />
            <PreviewBox
              title={t`OfferIcon`}
              value={offerIconID ?? ''}
              classNameBox='flex-item border-bottom'
              isImage
            />
            <PreviewBox
              title={t`ListedPoints`}
              value={listedPoints ?? ''}
              classNameBox='flex-item border-bottom border-left preview-width'
            />
            <PreviewBox
              title={t`RealPoints`}
              value={realPoints ?? ''}
              classNameBox='flex-item border-bottom border-left'
            />
            <PreviewBox
              title={t`Description`}
              value={description ?? ''}
              classNameBox='flex-item border-bottom'
              flexBasis='33em'
            />
            {userHasTenantPermission(role) && (
              <Box className='flex-item border-bottom preview-width'>
                <Typography className='title-preview '>{t`Agency`}</Typography>
                {agencySelected}
              </Box>
            )}
            <Box className='flex-item border-left border-bottom preview-width'>
              <Typography className='title-preview '>{t`CategorySelect`}</Typography>
              {offerCategorySelected}
            </Box>
            <PreviewBox
              title={t`UIPosition`}
              value={webUiPosition ?? ''}
              classNameBox='flex-item border-bottom border-left'
            />
            <PreviewBox
              title={t`Thumbnail`}
              value={thumbnailID ?? ''}
              classNameBox='flex-item'
              flexBasis='33em'
              isImage
            />
          </Box>
        </Box>
        <Box>
          <Box className='form-preview-box'>
            <h3 className='title'>{t`CouponInformation`}</h3>
            <SelfServeButton
              size='small'
              id='edit-coupon-info-button'
              variant='contained'
              color='secondary'
              onClick={() => onStepChange(1)}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
          <Box
            display='flex'
            width={constants.MAX_WIDTH_CARD}
            className='create-offer-info-box'
          >
            <PreviewBox
              title={t`Instructions`}
              value={instructions ?? ''}
              classNameBox='border-bottom flex-item'
              flexBasis='33em'
            />
            <PreviewBox
              title={t`RedirectionLink`}
              value={redirectionLink ?? ''}
              classNameBox='border-bottom flex-item'
              flexBasis='15em'
            />
            <PreviewBox
              title={t`IsARepeatableCoupon`}
              value={isARepeatableCoupon === true ? t`Yes` : t`No`}
              classNameBox='flex-item border-bottom border-left'
              flexBasis='15em'
              isSuccess={isARepeatableCoupon === true}
              isError={isARepeatableCoupon === false}
            />
            <PreviewBox
              title={t`HasRedemptionLimit`}
              value={hasRedemptionLimit === true ? t`Yes` : t`No`}
              classNameBox='flex-item border-bottom'
              flexBasis='15em'
              isSuccess={hasRedemptionLimit === true}
              isError={hasRedemptionLimit === false}
            />
            <PreviewBox
              title={t`RedemptionCount`}
              value={
                checkIsNumPositive(offerRedeemTimesCount) &&
                checkIsNumPositive(offerRedeemTimesDays)
                  ? `${offerRedeemTimesCount ?? ''} x ${
                      offerRedeemTimesDays ?? ''
                    }`
                  : t`NoLimit`
              }
              classNameBox='flex-item border-left border-bottom'
              flexBasis='15em'
            />
            <PreviewBox
              title={t`MaxTimesEarnByUser`}
              value={
                checkIsNumPositive(maxTimeRewardPerUser)
                  ? maxTimeRewardPerUser ?? ''
                  : t`NoLimit`
              }
              classNameBox='flex-item '
              flexBasis='33em'
            />
          </Box>
        </Box>
        <Box>
          <Box className='form-preview-box'>
            <h3 className='title'>{t`Audience`}</h3>
            <SelfServeButton
              size='small'
              id='edit-audience-info-button'
              variant='contained'
              color='secondary'
              onClick={() => onStepChange(2)}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
          <Box
            display='flex'
            width={constants.MAX_WIDTH_CARD}
            className='create-offer-info-box'
          >
            <PreviewBox
              title={t`StartDate`}
              value={formatDateAndTime(startDateFormatted, startTimeFormatted)}
              classNameBox={`flex-item ${!isEdit ? 'border-bottom' : ''}`}
              flexBasis='15em'
            />
            <PreviewBox
              title={t`EndDate`}
              value={formatDateAndTime(endDateFormatted, endTimeFormatted)}
              classNameBox={`flex-item border-left ${
                !isEdit ? 'border-bottom' : ''
              }`}
              flexBasis='15em'
            />
            {!isEdit
              ? (
                <>
                  <Box className='flex-item' flexBasis='15em'>
                    <Typography className='title-preview '>{t`SegmentsAvailableTo`}</Typography>
                    {PreviewSegmentsList(segmentsIncluded, segmentOptions)}
                  </Box>
                  <Box className='flex-item border-left' flexBasis='15em'>
                    <Typography className='title-preview '>{t`SegmentsExcludedFrom`}</Typography>
                    {PreviewSegmentsList(segmentsExcluded, segmentOptions)}
                  </Box>
                </>
                )
              : null}
          </Box>
        </Box>
        <Divider className='mt-2' />
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='offer-create-previous'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='saveCreateButton'
              id='offer-create-submit'
              type='submit'
              variant='contained'
              onClick={onSubmit}
            >
              {isEdit ? t`SaveChanges` : t`CreateOfferSubmit`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='offer-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Box>
    </>
  )
}

export default OfferFormPreviewStep
