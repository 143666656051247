import './MainHeader.scss'
import React, { ReactElement } from 'react'
import logo from '../../../assets/images/velocia-logo.png'
import { Avatar, Box, Button, Menu, MenuItem } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

interface HeaderProps {
  name: string
  handleLogOut: () => any
  logoUrl?: string
}

const MainHeader = ({
  name,
  handleLogOut,
  logoUrl
}: HeaderProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const buttonSx = { textTransform: 'none', color: '#272727', fontSize: '1em' }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Box className='main-header'>
      <Box paddingLeft='1em'>
        <img src={logo} alt='logo' />
      </Box>
      <Box display='flex'>
        <Avatar alt='user-logo' src={logoUrl}>
          <Avatar className='user-avatar'>{name.charAt(0).toUpperCase()}</Avatar>
        </Avatar>
        <Box alignSelf='center'>
          <Button
            sx={buttonSx}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            id='user-menu-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {name} <ExpandMore />
          </Button>
          <Menu
            id='user-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default MainHeader
