import { ReactElement } from 'react'
import { Box, InputLabel, Divider } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import { OffersInterfaceProps } from './OfferDetails'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { setDashIfEmpty, dashBox } from '../../commons/utils/utils'
import ReactMarkdown from 'react-markdown'

const CouponInformation = ({ offer }: OffersInterfaceProps): ReactElement | null => {
  const hasRepeatableCoupons = offer.HasRepeatableCoupons === true
  const hasMaxRedeemsLimit = (offer.MaxRedeemsPerUser != null && offer.MaxRedeemsPerUser > 0)
  const hasTimeFrameLimit = (offer.RedemptionsAllowedInTimeFrame != null && offer.RedemptionsAllowedInTimeFrame > 0) &&
    (offer.LimitTimeFrameInDays != null && offer.LimitTimeFrameInDays > 0)
  const redemptionsAllowedinTimeFrame = offer.RedemptionsAllowedInTimeFrame ?? ''
  const timeFrameLimit = offer.LimitTimeFrameInDays ?? ''

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD} marginTop='2em'>
      <Box
        className='offer-detail-step'
      >
        <InputLabel size='small' variant='standard' className='small-label coupon-label'>
          {t`Instructions`}
        </InputLabel>
        {offer.CouponInstructions != null
          ? <ReactMarkdown linkTarget='_blank' className='description-text'>{offer.CouponInstructions}</ReactMarkdown>
          : dashBox}
        <Divider className='divider mt-01' />
        <SelfServeROTextField
          id='offer-redirection-link'
          label={t`RedirectionLink`}
          defaultValue={setDashIfEmpty(offer.CouponURLTemplate)}
          showDivider
        />
        <SelfServeROTextField
          id='offer-repeatable'
          label={t`IsARepeatableCoupon`}
          defaultValue={hasRepeatableCoupons
            ? t`Yes`
            : !hasRepeatableCoupons
                ? t`No`
                : t`Dash`}
          className={hasRepeatableCoupons
            ? 'success'
            : !hasRepeatableCoupons
                ? 'error'
                : 'black'}
          showDivider
        />
        <SelfServeROTextField
          id='offer-redemption-limit'
          label={t`HasRedemptionLimit`}
          defaultValue={(hasMaxRedeemsLimit) || (hasTimeFrameLimit) ? t`Yes` : t`No`}
          className={(hasMaxRedeemsLimit) || (hasTimeFrameLimit)
            ? 'success'
            : 'error'}
          showDivider
        />
        <SelfServeROTextField
          id='offer-redemption-count'
          label={t`RedemptionCount`}
          defaultValue={(redemptionsAllowedinTimeFrame === 0 && timeFrameLimit === 0)
            ? t`NoLimitsByTimeframe`
            : `${redemptionsAllowedinTimeFrame}, in ${timeFrameLimit} days`}
          showDivider
        />
        <SelfServeROTextField
          id='offer-max-times'
          label={t`MaxTimesEarnByUser`}
          defaultValue={offer.MaxRedeemsPerUser === 0
            ? t`NoLimitsByTimeframe`
            : setDashIfEmpty(offer.MaxRedeemsPerUser)}
        />
      </Box>
    </Box>
  )
}

export default CouponInformation
