import { FC, ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Chip } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { RoutePath } from '../core/routes/routePaths'
import { constants } from '../commons/constants/constants'
import {
  convertStringToDateAndTime,
  setDashIfEmpty
} from '../commons/utils/utils'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../ui/sidebar/slices'
import { getUserByID } from './slices'
import { useTranslation } from 'react-i18next'
import SelfServeROTextField from '../ui/text-field/TextFieldRO'

const UsersDetails: FC<{}> = (): ReactElement | null => {
  const {
    userSelected: {
      UserID,
      CreatedAtUTC,
      VelociaID,
      UserIDAtTenant,
      JoinDate,
      PhoneNumber,
      referralCouponCode,
      completedActions,
      codesEntered,
      referredCoupon,
      IsPhoneVerified
    },
    loading
  } = useAppSelector((state) => state.users)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { date: joinDate, time: joinTime } =
    convertStringToDateAndTime(JoinDate)
  const { date: signUpDate, time: signUpTime } =
    convertStringToDateAndTime(CreatedAtUTC)

  useEffect(() => {
    if (id != null) {
      void dispatch(getUserByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.USER))
      dispatch(setBackButtonTitle(t`BackToUsers`))
      dispatch(setBackButtonRoute(RoutePath.Users))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  return UserID != null && !loading
    ? (
      <Box>
        <Box maxWidth={constants.MAX_WIDTH_CARD}>
          <Box display='flex'>
            <Box>
              <h1 className='title'>{t`UserDetails`}</h1>
            </Box>
          </Box>
          <Box className='details-section-box'>
            <SelfServeROTextField
              id='UserID'
              label={t`UserID`}
              defaultValue={setDashIfEmpty(UserID)}
              showDivider
            />
            <SelfServeROTextField
              id='VelociaID'
              label={t`VelociaID`}
              defaultValue={setDashIfEmpty(VelociaID)}
              showDivider
            />
            <SelfServeROTextField
              id='UserIDAtTenant'
              label={t`UserIDAtTenant`}
              defaultValue={setDashIfEmpty(UserIDAtTenant)}
              showDivider
            />
            <SelfServeROTextField
              id='SignUpDate'
              label={t`SignUpDate`}
              defaultValue={`${signUpDate ?? ''} | ${signUpTime ?? ''}`}
              showDivider
            />
            <SelfServeROTextField
              id='JoinedDate'
              label={t`JoinedDate`}
              defaultValue={`${joinDate ?? ''} | ${joinTime ?? ''}`}
              showDivider
            />
            <SelfServeROTextField
              id='PhoneVerification'
              label={t`PhoneVerification`}
              defaultValue={
              IsPhoneVerified === true ? PhoneNumber : t`NotVerified`
            }
              showDivider
            />
            {referredCoupon != null && (
              <SelfServeROTextField
                id='referredCoupon'
                label={t`ReferredCoupon`}
                defaultValue={setDashIfEmpty(referredCoupon)}
                showDivider
              />
            )}
            <SelfServeROTextField
              id='referralCouponCode'
              label={t`ReferralCouponCode`}
              defaultValue={setDashIfEmpty(referralCouponCode)}
            />
            <Chip
              label={`${t`TimesEntered` as string}${setDashIfEmpty(codesEntered)}`}
            />
            <Chip
              label={`${t`CompletedActions` as string}${setDashIfEmpty(
              completedActions
            )}`}
            />
          </Box>
        </Box>
      </Box>
      )
    : null
}

export default UsersDetails
