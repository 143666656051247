import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import SelfServeButton from '../../ui/button/Button'
import { useTranslation } from 'react-i18next'

const NoLiveOffer = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Box className='no-live-offer-box'>
      <Typography className='no-live-offer-text'>{t`NoLiveOffer`}</Typography>
      <SelfServeButton
        id='go-to-offers-button'
        variant='outlined'
        className='go-to-offers-btn'
      >{t`GoToOffers`}
      </SelfServeButton>
    </Box>
  )
}

export default NoLiveOffer
