import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { usersService } from './services'
import { USERS_CONSTANTS, UserPrivacyStatus, UserStatus } from './constants'
import { UsersState } from '../../type'
import { EarningsListParams, UsersListParams } from './interfaces/users'
import { handleRejectWithReport } from '../core/error/handler'
import { UserTargetGroup } from '../segments/interfaces/segments'
import { CampaignListParams } from '../campaigns/interfaces/campaigns'
import { OffersListParams } from '../offers/interfaces/offers'

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (params: UsersListParams, { rejectWithValue }) => {
    try {
      return await usersService.getUsers(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUsersForCSV = createAsyncThunk(
  'users/getUsersForCSV',
  async (params: UsersListParams, { rejectWithValue }) => {
    try {
      return await usersService.getUsers(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserWallet = createAsyncThunk(
  'users/getUserWallet',
  async (params: UsersListParams, { rejectWithValue }) => {
    try {
      return await usersService.getUserWallet(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserCampaigns = createAsyncThunk(
  'users/getUserCampaigns',
  async (params: CampaignListParams, { rejectWithValue }) => {
    try {
      return await usersService.getUserCampaigns(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserByID = createAsyncThunk(
  'users/getUserByID',
  async (id: string, { rejectWithValue }) => {
    try {
      return await usersService.getUserByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserEarningsByID = createAsyncThunk(
  'users/getUserEarningsByID',
  async (params: EarningsListParams, { rejectWithValue }) => {
    try {
      if (params.userID != null) {
        return await usersService.getUserEarningsByID(params.userID, params)
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)
export const removeUserFromTargetGroup = createAsyncThunk(
  'users/removeUserFromTargetGroup',
  async ({ TargetGroupID, UserID }: UserTargetGroup, { rejectWithValue }) => {
    try {
      return await usersService.removeUserFromTargetGroup(UserID, TargetGroupID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const addUserToTargetGroup = createAsyncThunk(
  'users/addUserToTargetGroup',
  async ({ TargetGroupID, UserID }: UserTargetGroup, { rejectWithValue }) => {
    try {
      return await usersService.addUserToTargetGroup(UserID, TargetGroupID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserSegmentsByID = createAsyncThunk(
  'users/getUserSegmentsByID',
  async (id: string, { rejectWithValue }) => {
    try {
      if (id != null) {
        return await usersService.getUserSegmentsByID(id)
      }
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getEarningsListOptions = createAsyncThunk(
  'segments/getEarningsListOptions',
  async (userID: string, { rejectWithValue }) => {
    try {
      return await usersService.getEarningsListOptions(userID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUserOffers = createAsyncThunk(
  'users/getUserOffers',
  async (params: OffersListParams, { rejectWithValue }) => {
    try {
      return await usersService.getUserOffers(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const activateUser = createAsyncThunk(
  'users/activateUser',
  async (userID: string, { rejectWithValue }) => {
    try {
      return await usersService.activateUser(userID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const suspendUser = createAsyncThunk(
  'users/suspendUser',
  async (userID: string, { rejectWithValue }) => {
    try {
      return await usersService.suspendUser(userID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const optOutUser = createAsyncThunk(
  'users/optOutUser',
  async (userID: string, { rejectWithValue }) => {
    try {
      return await usersService.optOutUser(userID)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

const usersInitialState = {
  users: [],
  totalUsersList: 0,
  usersTransactions: [],
  totalUsersTransactions: 0,
  userSelected: {},
  totalEarningsList: 0,
  loading: false,
  userEarningsListOptions: [],
  userEarningsList: [],
  loadSegments: false,
  userSegmentsList: [],
  usersForCSV: [],
  usersForCSVLoading: false,
  usersCampaigns: [],
  totalUsersCampaigns: 0,
  usersOffers: [],
  totalUsersOffers: 0,
  hasTransactions: true
}

const users = createSlice({
  name: USERS_CONSTANTS.Domain,
  initialState: usersInitialState as UsersState,
  reducers: {
    cleanUsersForCSV: (state) => {
      state.usersForCSV = []
      state.usersForCSVLoading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.users
        state.totalUsersList = action.payload.count
        state.loading = false
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsers.rejected, (state) => {
        state.users = []
        state.loading = false
        state.totalUsersList = 0
      })
      .addCase(getUsersForCSV.fulfilled, (state, action) => {
        state.usersForCSV = action.payload.users
        state.usersForCSVLoading = false
      })
      .addCase(getUsersForCSV.pending, (state) => {
        state.usersForCSVLoading = true
      })
      .addCase(getUsersForCSV.rejected, (state) => {
        state.usersForCSV = []
        state.usersForCSVLoading = false
      })
      .addCase(getUserByID.fulfilled, (state, action) => {
        state.userSelected = action.payload
        state.loading = false
      })
      .addCase(getUserByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserByID.rejected, (state) => {
        state.loading = false
      })
      .addCase(getUserWallet.fulfilled, (state, action) => {
        state.usersTransactions = action.payload.transactions
        state.totalUsersTransactions = action.payload.count
        state.hasTransactions = action.payload.hasTransactions
        state.loading = false
      })
      .addCase(getUserWallet.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserWallet.rejected, (state) => {
        state.usersTransactions = []
        state.totalUsersTransactions = 0
        state.hasTransactions = true
        state.loading = false
      })
      .addCase(getUserEarningsByID.fulfilled, (state, action) => {
        state.totalEarningsList = action.payload.count
        state.userEarningsList = action.payload.earnings
      })
      .addCase(getUserEarningsByID.pending, (state) => {
        state.loading = true
        state.loading = false
      })
      .addCase(getUserEarningsByID.rejected, (state) => {
        state.userEarningsList = []
        state.totalEarningsList = 0
        state.loading = false
      })
      .addCase(getEarningsListOptions.fulfilled, (state, action) => {
        state.userEarningsListOptions = action.payload
      })
      .addCase(getEarningsListOptions.rejected, (state) => {
        state.userEarningsListOptions = []
      })
      .addCase(getUserSegmentsByID.fulfilled, (state, action) => {
        state.userSegmentsList = action.payload
        state.loadSegments = false
      })
      .addCase(getUserSegmentsByID.rejected, (state) => {
        state.loadSegments = false
        state.userSegmentsList = []
      })
      .addCase(addUserToTargetGroup.fulfilled, (state, action) => {
        state.loadSegments = true
      })
      .addCase(addUserToTargetGroup.rejected, (state) => {
        state.loadSegments = false
      })
      .addCase(removeUserFromTargetGroup.fulfilled, (state, action) => {
        state.loadSegments = true
      })
      .addCase(removeUserFromTargetGroup.rejected, (state) => {
        state.loadSegments = false
      })
      .addCase(getUserCampaigns.fulfilled, (state, action) => {
        state.usersCampaigns = action.payload.campaigns
        state.totalUsersCampaigns = action.payload.count
        state.loading = false
      })
      .addCase(getUserCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserCampaigns.rejected, (state) => {
        state.usersCampaigns = []
        state.loading = false
        state.totalUsersCampaigns = 0
      })
      .addCase(getUserOffers.fulfilled, (state, action) => {
        state.usersOffers = action.payload.offers
        state.totalUsersOffers = action.payload.count
        state.loading = false
      })
      .addCase(getUserOffers.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserOffers.rejected, (state) => {
        state.usersOffers = []
        state.loading = false
        state.totalUsersOffers = 0
      })
      .addCase(activateUser.fulfilled, (state) => {
        state.userSelected.Status = UserStatus.ACTIVE
      })
      .addCase(suspendUser.fulfilled, (state) => {
        state.userSelected.Status = UserStatus.SUSPENDED
      })
      .addCase(optOutUser.fulfilled, (state) => {
        state.userSelected.PrivacyStatus = UserPrivacyStatus.OPTED_OUT
      })
  }
})

export const { cleanUsersForCSV } = users.actions
export const usersReducer = users.reducer
