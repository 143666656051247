import { ReactElement, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import ReorderUiPositionDialog from '../reorder-ui-position-dialog/ReorderUiPositionDialog'
import { ReorderUiPositionWrapperProps } from '../../commons/interfaces/commons-interfaces'
import { ReorderList, modifyDragList } from '../../commons/utils/utils'
import { REORDER_KEY_NAME } from '../../commons/constants/constants'

const ReorderUiPositionWrapperCreate = ({
  isUIReorderOpen,
  handleClose,
  name = '',
  onReorderSave,
  currentList,
  listWithNewOrder = [],
  isOffer = false,
  isCampaign = false,
  isFeatured = false
}: ReorderUiPositionWrapperProps): ReactElement => {
  const [updatedList, setUpdatedList] = useState<ReorderList>(
    []
  )

  const modifyList = useCallback(() => {
    const sortedList = _.sortBy(currentList, REORDER_KEY_NAME.WebUiPosition)
    modifyDragList(sortedList, setUpdatedList, name, isOffer, isCampaign, isFeatured)
  }, [currentList, name, isOffer, isCampaign, isFeatured])

  useEffect(() => {
    if (listWithNewOrder?.length > 0) {
      setUpdatedList(_.cloneDeep(listWithNewOrder))
    } else {
      modifyList()
    }
  }, [modifyList, listWithNewOrder])

  return (
    <ReorderUiPositionDialog
      open={isUIReorderOpen}
      handleClose={handleClose}
      onReorderSave={onReorderSave}
      updatedList={updatedList}
      setUpdatedList={setUpdatedList}
      isOffer={isOffer}
      isCampaign={isCampaign}
      isFeatured={isFeatured}
    />
  )
}

export default ReorderUiPositionWrapperCreate
