import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SegmentState } from '../../type'
import { handleRejectWithReport } from '../core/error/handler'
import segmentsService from './services'
import {
  segmentsConstants,
  ADD_TO_ALL_OPTION_SEGMENTS,
  EMPTY_OPTION_SEGMENTS
} from './constants'
import {
  CodesListParams,
  CodeTargetGroup,
  SegmentsListParams,
  TargetGroupListParams,
  TargetGroupsInterface
} from './interfaces/segments'
import { handleSliceErrors } from '../commons/utils/utils'
import { VALIDATION_ERROR } from '../commons/constants/constants'
import { UsersListParams } from '../users/interfaces/users'
import _ from 'lodash'

export const getAllSegments = createAsyncThunk(
  'segments/getAllSegments',
  async (
    { TenantID, AgencyID }: TargetGroupListParams,
    { rejectWithValue }
  ) => {
    try {
      return await segmentsService.getAllSegments(
        TenantID ?? '',
        AgencyID ?? ''
      )
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getSegmentByID = createAsyncThunk(
  'segments/getSegmentByID',
  async (id: string, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegmentByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getSegmentByIDForEdit = createAsyncThunk(
  'segments/getSegmentByIDForEdit',
  async (id: string, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegmentByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUsersSegments = createAsyncThunk(
  'segments/getUsersSegments',
  async ({ TenantID }: TargetGroupListParams, { rejectWithValue }) => {
    try {
      return await segmentsService.getUsersSegments(TenantID ?? '')
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getSegments = createAsyncThunk(
  'segments/getSegments',
  async (params: SegmentsListParams, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegments(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getSegmentsForCSV = createAsyncThunk(
  'segments/getSegmentsForCSV',
  async (params: SegmentsListParams, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegments(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const createSegment = createAsyncThunk(
  'segments/createSegment',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      return await segmentsService.createSegment(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editSegment = createAsyncThunk(
  'segments/editSegment',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      if (params.TargetGroupID != null) {
        return await segmentsService.editSegment(params, params.TargetGroupID)
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editRewardsSegment = createAsyncThunk(
  'segments/editRewardsSegment',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      if (params.TargetGroupID != null) {
        return await segmentsService.editRewardsSegment(
          params,
          params.TargetGroupID
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const removeSegment = createAsyncThunk(
  'segments/removeSegment',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      if (params.Name != null && params.TenantID != null) {
        return await segmentsService.removeSegment(params.Name, params.TenantID)
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const createRewardsSegment = createAsyncThunk(
  'segments/createRewardsSegment',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      return await segmentsService.createRewardsSegment(params)
    } catch (error: any) {
      if (error.response.data.name === VALIDATION_ERROR) {
        error.message = error.response.data.errors
      }
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getNonExistUserList = createAsyncThunk(
  'segments/getNonExistUserList',
  async (userList: string[], { rejectWithValue }) => {
    try {
      return await segmentsService.getNonExistUserList(userList)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getUsersForSegments = createAsyncThunk(
  'segments/getUsersForSegments',
  async (params: UsersListParams, { rejectWithValue }) => {
    try {
      if (params.targetGroupID != null) {
        return await segmentsService.getUsersForSegments(
          params,
          params.targetGroupID
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getUsersForSegmentsCSV = createAsyncThunk(
  'segments/getUsersForSegmentsCSV',
  async (params: UsersListParams, { rejectWithValue }) => {
    try {
      if (params.targetGroupID != null) {
        return await segmentsService.getUsersForSegments(
          params,
          params.targetGroupID
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getCodesForSegments = createAsyncThunk(
  'segments/getCodesForSegments',
  async (params: CodesListParams, { rejectWithValue }) => {
    try {
      if (params.targetGroupID != null) {
        return await segmentsService.getCodesForSegments(
          params.targetGroupID,
          params
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getCodesForSegmentsCSV = createAsyncThunk(
  'segments/getCodesForSegmentsCSV',
  async (params: CodesListParams, { rejectWithValue }) => {
    try {
      if (params.targetGroupID != null) {
        return await segmentsService.getCodesForSegments(
          params.targetGroupID,
          params
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const codesPrecheck = createAsyncThunk(
  'segments/codesPrecheck',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      return await segmentsService.codesPrecheck(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const usersPrecheck = createAsyncThunk(
  'segments/usersPrecheck',
  async (params: TargetGroupsInterface, { rejectWithValue }) => {
    try {
      if (params.TargetGroupID != null) {
        return await segmentsService.usersPrecheck(
          params.TargetGroupID,
          params.userList ?? []
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getCodeNotAvailable = createAsyncThunk(
  'segments/getCodeNotAvailable',
  async (params: CodeTargetGroup, { rejectWithValue }) => {
    try {
      return await segmentsService.isCodeAvailable(
        params.code,
        params.TargetGroupID
      )
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const removeCodeFromSegment = createAsyncThunk(
  'segments/removeCodeFromSegment',
  async (params: CodeTargetGroup, { rejectWithValue }) => {
    try {
      return await segmentsService.removeCodeFromSegment(
        params.code,
        params.TargetGroupID
      )
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getSegmentOffers = createAsyncThunk(
  'segments/getSegmentOffers',
  async (id: string, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegmentOffers(id)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getSegmentCampaigns = createAsyncThunk(
  'segments/getSegmentCampaigns',
  async (id: string, { rejectWithValue }) => {
    try {
      return await segmentsService.getSegmentCampaigns(id)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

const segmentsInitialState = {
  segmentOptions: [],
  segments: [],
  segmentSelected: {},
  totalSegmentsList: 0,
  segmentsForCSV: [],
  segmentsForCSVLoading: false,
  loading: false,
  isFormTouched: false,
  isFormUpdated: false,
  isFulfilled: false,
  isDescription: false,
  descriptionHTML: '',
  errors: '',
  users: [],
  totalUsers: 0,
  codes: [],
  totalCodes: 0,
  segmentsUsersForCSV: [],
  segmentsUsersForCSVLoading: false,
  segmentsCodesForCSV: [],
  segmentsCodesForCSVLoading: false,
  isCodeAvailable: false,
  codeDeleted: false,
  codeDeletedLoading: false,
  offersIncluded: [],
  offersExcluded: [],
  campaignsIncluded: [],
  campaignsExcluded: [],
  activeStep: 0,
  radioButtonOption: '',
  segmentsForm: {
    Name: '',
    previousName: '',
    editMethod: '',
    AgencyID: '',
    users: [],
    usersCSV: [],
    usersFile: null,
    usersFileID: null,
    Description: '',
    Manual: true,
    expiryDate: null,
    timesCanBeUsed: '',
    showWelcomeDialog: false,
    showWelcomePoints: false,
    welcomeDialogImageID: 0,
    welcomeDialogImageUrl: '',
    welcomeDialogMessage: '',
    welcomeDialogLabel: '',
    welcomeDialogTitle: '',
    welcomeDialogImageFile: null,
    welcomePoints: '',
    welcomePointsTitle: '',
    welcomePointsUrl: '',
    welcomePointsImageID: 0,
    welcomePointsImageFile: null,
    isFromCSV: null,
    areCodesFromCSV: false,
    inviteCodes: '',
    inviteCodesCSV: [],
    codesFile: null,
    codesFileID: null,
    prefix: '',
    codeLength: 4,
    excludedCharacters: '',
    generationMethod: '',
    existInCurrentSegment: [],
    duplicatedCodes: [],
    duplicatedUsers: [],
    notExistUserList: [],
    currentTimesCanBeUsed: 0
  }
}

const segments = createSlice({
  name: segmentsConstants.Domain,
  initialState: segmentsInitialState as SegmentState,
  reducers: {
    cleanSegmentsForCSV: (state) => {
      state.segmentsForCSV = []
      state.segmentsForCSVLoading = false
    },
    cleanUserSegmentsForCSV: (state) => {
      state.segmentsUsersForCSV = []
      state.segmentsUsersForCSVLoading = false
    },
    cleanCodesForSegmentsCSV: (state) => {
      state.segmentsCodesForCSV = []
      state.segmentsCodesForCSVLoading = false
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
    setRadioButtonOption: (state, action) => {
      state.radioButtonOption = action.payload
    },
    setCodeDeleted: (state, action) => {
      state.codeDeleted = action.payload
    },
    modifySegmentForm: (state, action) => {
      state.loading = true
      state.segmentsForm[
        action.payload.name as keyof typeof state.segmentsForm
      ] = action.payload.value
      state.isFormTouched = true
      state.loading = false
    },
    resetSegmentForm: (state) => {
      state.loading = true
      state.segmentsForm = segmentsInitialState.segmentsForm
      state.isFormTouched = false
      state.isFormUpdated = false
      state.errors = ''
      state.descriptionHTML = ''
      state.isFulfilled = false
      state.loading = false
      state.activeStep = 0
      state.radioButtonOption = ''
    },
    setIsDescription: (state, action) => {
      state.isDescription = true
      if (action.payload != null) {
        state.descriptionHTML = action?.payload
      }
    },
    resetIsDescription: (state) => {
      state.isDescription = false
    },
    clearWelcomeDialog: (state) => {
      state.segmentsForm.welcomeDialogImageID = 0
      state.segmentsForm.welcomeDialogImageUrl = ''
      state.segmentsForm.welcomeDialogMessage = ''
      state.segmentsForm.welcomeDialogLabel = ''
      state.segmentsForm.welcomeDialogTitle = ''
      state.segmentsForm.welcomeDialogImageFile = null
    },
    clearWelcomePoints: (state) => {
      state.segmentsForm.welcomePoints = ''
      state.segmentsForm.welcomePointsTitle = ''
      state.segmentsForm.welcomePointsUrl = ''
      state.segmentsForm.welcomePointsImageID = 0
      state.segmentsForm.welcomePointsImageFile = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSegments.fulfilled, (state, action) => {
        state.segmentOptions = action.payload
        state.segmentOptions.unshift(ADD_TO_ALL_OPTION_SEGMENTS)
        state.segmentOptions.unshift(EMPTY_OPTION_SEGMENTS)
      })
      .addCase(getAllSegments.rejected, (state) => {
        state.segmentOptions = []
      })
      .addCase(getUsersSegments.fulfilled, (state, action) => {
        state.segmentOptions = action.payload
        state.segmentOptions.unshift(EMPTY_OPTION_SEGMENTS)
      })
      .addCase(getUsersSegments.rejected, (state) => {
        state.segmentOptions = []
      })
      .addCase(getSegments.fulfilled, (state, action) => {
        state.segments = action.payload.targetGroups
        state.totalSegmentsList = action.payload.count
        state.loading = false
      })
      .addCase(getSegments.pending, (state) => {
        state.loading = true
      })
      .addCase(getSegments.rejected, (state) => {
        state.segments = []
        state.loading = false
        state.totalSegmentsList = 0
      })
      .addCase(getUsersForSegments.fulfilled, (state, action) => {
        state.users = action.payload.users
        state.totalUsers = action.payload.count
        state.loading = false
      })
      .addCase(getUsersForSegments.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsersForSegments.rejected, (state) => {
        state.users = []
        state.loading = false
        state.totalUsers = 0
      })
      .addCase(getCodesForSegments.fulfilled, (state, action) => {
        state.codes = action.payload.codes
        state.totalCodes = action.payload.count
        state.codeDeleted = false
        state.loading = false
      })
      .addCase(getCodesForSegments.pending, (state) => {
        state.loading = true
      })
      .addCase(getCodesForSegments.rejected, (state) => {
        state.codes = []
        state.loading = false
        state.totalCodes = 0
      })
      .addCase(getSegmentsForCSV.fulfilled, (state, action) => {
        state.segmentsForCSV = action.payload.targetGroups
        state.segmentsForCSVLoading = false
      })
      .addCase(getSegmentsForCSV.pending, (state) => {
        state.segmentsForCSVLoading = true
      })
      .addCase(createSegment.fulfilled, (state, action) => {
        state.segmentSelected.TargetGroupID = action.payload.targetGroupID
        state.errors = ''
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(createSegment.pending, (state) => {
        state.errors = ''
        state.loading = true
      })
      .addCase(createSegment.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string
      })
      .addCase(editSegment.fulfilled, (state, action) => {
        state.segmentSelected.TargetGroupID = action.payload.targetGroupID
        state.errors = ''
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editSegment.pending, (state) => {
        state.errors = ''
        state.loading = true
      })
      .addCase(editSegment.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string
      })
      .addCase(editRewardsSegment.fulfilled, (state, action) => {
        state.segmentSelected.TargetGroupID = action.payload.targetGroupID
        state.errors = ''
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editRewardsSegment.pending, (state) => {
        state.errors = ''
        state.loading = true
      })
      .addCase(editRewardsSegment.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string
      })
      .addCase(createRewardsSegment.fulfilled, (state, action) => {
        state.segmentSelected.TargetGroupID = action.payload.targetGroupID
        state.errors = ''
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(createRewardsSegment.pending, (state) => {
        state.errors = ''
        state.loading = true
      })
      .addCase(createRewardsSegment.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string
      })
      .addCase(getSegmentByID.fulfilled, (state, action) => {
        state.segmentSelected = action.payload
        state.loading = false
      })
      .addCase(getSegmentByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getSegmentByID.rejected, (state) => {
        state.loading = false
      })
      .addCase(codesPrecheck.fulfilled, (state, action) => {
        state.segmentsForm.duplicatedCodes = action.payload.duplicatedCodes
        state.segmentsForm.notExistCodes = action.payload.notExistCodes
        state.segmentsForm.alreadyExistCodes = action.payload.alreadyExistCodes
        state.segmentsForm.filteredList = action.payload.filteredList
        state.segmentsForm.existInCurrentSegment =
          action.payload.existInCurrentSegment
        state.loading = false
      })
      .addCase(codesPrecheck.pending, (state) => {
        state.loading = true
      })
      .addCase(codesPrecheck.rejected, (state) => {
        state.loading = false
      })
      .addCase(usersPrecheck.fulfilled, (state, action) => {
        state.segmentsForm.notExistUserList = action.payload.notExistUserList
        state.segmentsForm.duplicatedUsers = action.payload.duplicatedUsers
        state.loading = false
      })
      .addCase(usersPrecheck.pending, (state) => {
        state.loading = true
      })
      .addCase(usersPrecheck.rejected, (state) => {
        state.loading = false
      })
      .addCase(getSegmentByIDForEdit.fulfilled, (state, action) => {
        state.segmentSelected = action.payload
        state.segmentsForm = action.payload
        state.segmentsForm.previousName = action.payload?.Name
        state.segmentsForm.currentTimesCanBeUsed =
          action.payload?.timesCanBeUsed
        state.segmentsForm.codeLength = _.parseInt(action.payload?.codeLength)
        state.segmentsForm.showWelcomeDialog = !_.isEmpty(
          action.payload?.welcomeDialogLabel
        )
        state.segmentsForm.showWelcomePoints = !_.isEmpty(
          action.payload?.welcomePointsTitle
        )
        state.segmentsForm.isFromCSV = true
        state.loading = false
      })
      .addCase(getSegmentByIDForEdit.pending, (state) => {
        state.loading = true
      })
      .addCase(getSegmentByIDForEdit.rejected, (state) => {
        state.loading = false
      })
      .addCase(getSegmentOffers.fulfilled, (state, action) => {
        state.offersExcluded = action.payload.excluded
        state.offersIncluded = action.payload.included
        state.loading = false
      })
      .addCase(getSegmentOffers.pending, (state) => {
        state.loading = true
      })
      .addCase(getSegmentOffers.rejected, (state) => {
        state.loading = false
      })
      .addCase(getSegmentCampaigns.fulfilled, (state, action) => {
        state.campaignsExcluded = action.payload.excluded
        state.campaignsIncluded = action.payload.included
        state.loading = false
      })
      .addCase(getSegmentCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getSegmentCampaigns.rejected, (state) => {
        state.loading = false
      })
      .addCase(getUsersForSegmentsCSV.fulfilled, (state, action) => {
        state.segmentsUsersForCSV = action.payload.users
        state.segmentsUsersForCSVLoading = false
      })
      .addCase(getUsersForSegmentsCSV.pending, (state) => {
        state.segmentsUsersForCSVLoading = true
      })
      .addCase(getCodesForSegmentsCSV.fulfilled, (state, action) => {
        state.segmentsCodesForCSV = action.payload.codes
        state.segmentsCodesForCSVLoading = false
      })
      .addCase(getCodesForSegmentsCSV.pending, (state) => {
        state.segmentsCodesForCSVLoading = true
      })
      .addCase(getCodeNotAvailable.fulfilled, (state, action) => {
        state.isCodeAvailable = action.payload
        state.loading = false
      })
      .addCase(getCodeNotAvailable.pending, (state) => {
        state.loading = true
      })
      .addCase(removeCodeFromSegment.pending, (state) => {
        state.codeDeletedLoading = true
      })
      .addCase(removeCodeFromSegment.fulfilled, (state) => {
        state.codeDeletedLoading = false
        state.codeDeleted = true
      })
      .addCase(getNonExistUserList.fulfilled, (state, action) => {
        state.segmentsForm.notExistUserList = action.payload.notExistUserList
      })
      .addCase(getNonExistUserList.rejected, (state) => {
        state.segmentsForm.notExistUserList = []
      })
  }
})

export const {
  cleanSegmentsForCSV,
  modifySegmentForm,
  resetSegmentForm,
  setIsDescription,
  resetIsDescription,
  clearWelcomeDialog,
  clearWelcomePoints,
  cleanUserSegmentsForCSV,
  cleanCodesForSegmentsCSV,
  setCodeDeleted,
  setActiveStep,
  setRadioButtonOption
} = segments.actions
export const segmentsReducer = segments.reducer
