import { FC, useCallback } from 'react'
import { UsersListParams } from '../interfaces/users'
import {
  PER_PAGE_WALLET_TABLE,
  FIRST_PAGE_WALLET_TABLE,
  TenantUserCampaignsListColumns,
  AgencyUserCampaignsListColumns
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import { setClassNameForTable } from '../../commons/utils/utils'
import { CampaignsInterface } from '../../campaigns/interfaces/campaigns'
import StatusIndicator from '../../ui/status-indicator/StatusIndicator'
import { campaignsListActionItems, setStatusColor } from '../../campaigns/utils'
import { CampaignsStatuses } from '../../campaigns/constants'
import { RoutePath } from '../../core/routes/routePaths'
import { Link } from 'react-router-dom'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'

interface UserVisibleCampaignsListProps {
  onPageChange: (changedData: UsersListParams) => void
  page: number
  perPage: number
  total: number
  campaigns: CampaignsInterface[]
  showAgency?: boolean
}

const UserVisibleCampaignsList: FC<UserVisibleCampaignsListProps> = ({
  page,
  perPage,
  total,
  campaigns,
  onPageChange,
  showAgency
}) => {
  const COLUMNS = Object.values(
    showAgency != null && showAgency
      ? TenantUserCampaignsListColumns
      : AgencyUserCampaignsListColumns
  )

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='campaigns table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {campaigns.length > 0 && (
          <TableBody>
            {campaigns?.map((row) => {
              return (
                <TableRow className='row-with-actions' key={row.CampaignID}>
                  <TableCell className='body-cell centered-cell'>
                    <Link to={`${RoutePath.Campaigns}/${row.CampaignID ?? ''}`}>
                      {row.CampaignID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Campaigns}/${row.CampaignID ?? ''}`}>
                      {row.Title}
                    </Link>
                  </TableCell>
                  {showAgency === true && (
                    <TableCell className='body-cell'>{row.AgencyName}</TableCell>
                  )}
                  <TableCell className='body-cell'>{row.CategoryName}</TableCell>
                  <TableCell className='body-cell centered-cell'>
                    {row.WebUiPosition}
                  </TableCell>
                  <TableCell className='body-cell centered-cell'>
                    {row.status != null && (
                      <StatusIndicator
                        color={setStatusColor(row.status as CampaignsStatuses)}
                        title={row.status}
                      />
                    )}
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Box className='list-actions flex-center'>
                      {campaignsListActionItems(
                        row.CampaignID?.toString() ?? ''
                      ).map((item) => {
                        return (
                          <SelfServeActionItem
                            path={item.path}
                            title={item.title}
                            icon={item.icon}
                            key={item.title}
                          />
                        )
                      })}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        perPage={perPage ?? PER_PAGE_WALLET_TABLE}
        page={page ?? FIRST_PAGE_WALLET_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={campaigns.length}
      />
    </TableContainer>
  )
}

export default UserVisibleCampaignsList
