import { TextField } from '@mui/material'
import { ReactElement } from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { t } from 'i18next'

interface SearchChangeParams {
  search: string
}

interface SelfServeSearchFieldProps {
  value?: string
  onChange: (params: SearchChangeParams) => void
}

const SelfServeSearchField = ({
  value,
  onChange
}: SelfServeSearchFieldProps): ReactElement => {
  return (
    <TextField
      size='small'
      label={
        (value === '' || value == null) && (
          <>
            <SearchOutlinedIcon className='search-icon' fontSize='small' />
            {t`Search`}
          </>
        )
      }
      InputProps={{
        type: 'search',
        name: 'search',
        defaultValue: value
      }}
      inputProps={{ className: 'table-input' }}
      InputLabelProps={{ shrink: false }}
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => onChange({ search: event.target.value })}
    />
  )
}

export default SelfServeSearchField
