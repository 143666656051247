import {
  FormControlLabel,
  TextField,
  FormHelperText,
  Divider,
  Box,
  InputLabel,
  FormControl
} from '@mui/material'
import { LOCATIONS } from '../commons/constants/locations'
import MenuItem from '@mui/material/MenuItem'
import { FC, useMemo, useState } from 'react'
import SelfServeButton from '../ui/button/Button'
import {
  Form,
  useLocation,
  useBlocker,
  useNavigation
} from 'react-router-dom'
import { getCities } from './utils'
import ImageDropZone from '../ui/dropzone/Dropzone'
import { useForm, Controller } from 'react-hook-form'
import CustomSwitch from '../ui/custom-switch/CustomSwitch'
import './agencies.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../commons/constants/constants'
import CustomDialog from '../ui/custom-dialog/CustomDialog'
import { reportError } from '../core/error/handler'
import { ErrorLevels } from '../core/error/constants'
import {
  blockerConditionWithoutStepper,
  handleFormSubmit,
  onFormChange,
  onFormChangeWrapper,
  selectMenuItem
} from '../commons/utils/utils'
import { FormValues } from './interfaces/agencies'
import FormFieldBox from '../ui/form-field-box/FormFieldBox'
import { agencyFormValidationConstants } from './constants'
import SelfServeCustomSelect from '../ui/select/SelfServeCustomSelect'

interface AgenciesFormProps {
  properties: {
    state: string
    city: string
    icon?: File | undefined
    dashboardAccess?: boolean
    agencyName?: string
    email?: string
    url?: string
  }
  isLeavingDialogOpen: boolean
  edit: boolean
  setValueMap: {}
  onSubmit: (() => Promise<void>) | (() => void)
  onDiscard: () => void
}

const AgenciesForm: FC<AgenciesFormProps> = ({
  properties: { url, state, city, icon, dashboardAccess, agencyName, email },
  setValueMap,
  onSubmit,
  onDiscard,
  edit,
  isLeavingDialogOpen
}) => {
  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      url,
      state,
      city,
      iconID: icon,
      dashboardAccess,
      agencyName,
      email
    }
  })

  const [openConfirmLeavingDialog, setOpenConfirmLeavingDialog] =
    useState(false)
  const { t } = useTranslation()
  const location = useLocation()
  const navigation = useNavigation()

  const blocker = useBlocker(() => {
    return blockerConditionWithoutStepper(
      isDirty,
      location.pathname,
      isLeavingDialogOpen,
      setOpenConfirmLeavingDialog,
      navigation.location?.pathname
    )
  })

  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={edit ? t`DiscardAgencyModification` : t`DiscardAgencyCreation`}
      open={openConfirmLeavingDialog}
      cancelCallback={() => {
        (blocker.reset as () => void)()
        setOpenConfirmLeavingDialog(false)
      }}
      okCallback={() => {
        (blocker.proceed as () => void)()
      }}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const statesSelectItems = useMemo(() => {
    return Object.keys(LOCATIONS).map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }, [])

  const citySelectItems = useMemo(() => {
    return getCities(state).map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }, [state])

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Box>
        <h1 className='title'>{edit ? t`EditAgency` : t`CreateAgency`}</h1>
      </Box>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleFormSubmit(event, trigger, onSubmit, errors).catch((error) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <FormFieldBox
          title={t`AgencyName`}
          rules={{
            required: agencyFormValidationConstants.requiredField,
            minLength: agencyFormValidationConstants.minLengthError,
            maxLength: agencyFormValidationConstants.maxLengthError
          }}
          name='agencyName'
          render={({ field: { name, onChange } }) => (
            <TextField
              name={name}
              id='agencyName'
              onChange={(e) => {
                onFormChangeWrapper(
                  e.target?.value,
                  name,
                  onChange,
                  setValueMap
                )
              }}
              value={agencyName}
            />
          )}
          control={control}
          errorField={errors.agencyName}
        />
        <Box className='mb-1'>
          <FormControl fullWidth>
            <FormHelperText>{t`Agency Logo`}</FormHelperText>
            <Controller
              rules={{
                validate: {
                  required: () => {
                    return icon != null || url != null
                  }
                }
              }}
              render={({ field: { name, onChange } }) => (
                <ImageDropZone
                  isImage
                  url={url}
                  name={name}
                  setFile={(data: File | undefined) => {
                    onFormChange(
                      undefined,
                      constants.COMMON_FORM_VALUES.URL as keyof FormValues,
                      setValueMap
                    )
                    onFormChange(data, name, setValueMap)
                    onChange(data, name)
                  }}
                  onFormChange={(e) => onChange(e)}
                />
              )}
              name='iconID'
              control={control}
            />
          </FormControl>
          {errors.iconID != null && (
            <FormHelperText error>{t`RequiredField`}</FormHelperText>
          )}
        </Box>
        <Box className='flex-row space-between'>
          <FormFieldBox
            title={t`State`}
            rules={{
              required: agencyFormValidationConstants.requiredField
            }}
            name='state'
            render={({ field: { name, onChange } }) => (
              <>
                <InputLabel
                  shrink={false}
                  className='select-label label-position'
                  id='state-label'
                >
                  {state === '' && t`SelectState`}
                </InputLabel>
                <SelfServeCustomSelect
                  handleFormChange={(e) => {
                    onFormChange(
                      '',
                      constants.COMMON_FORM_VALUES.CITY as keyof FormValues,
                      setValueMap
                    )
                    onFormChangeWrapper(
                      e.target?.value as string,
                      name,
                      onChange,
                      setValueMap
                    )
                  }}
                  value={state}
                  menuItem={selectMenuItem(t`None`)}
                  selectItems={statesSelectItems}
                  name={name}
                  inputProps='custom-form-select'
                  isFormSelect
                />
              </>
            )}
            control={control}
            errorField={errors.state}
            className='select-minw'
          />
          <FormFieldBox
            title={t`City`}
            rules={{
              required: agencyFormValidationConstants.requiredField,
              validate: {
                notEmpty: () => {
                  return city !== ''
                }
              }
            }}
            name='city'
            render={({ field: { name, onChange } }) => (
              <>
                <InputLabel
                  shrink={false}
                  className='select-label label-position'
                  id='city-label'
                >
                  {city === '' && t`SelectCity`}
                </InputLabel>
                <SelfServeCustomSelect
                  disabled={state === ''}
                  handleFormChange={(e) => {
                    onFormChangeWrapper(
                      e.target?.value as string,
                      name,
                      onChange,
                      setValueMap
                    )
                  }}
                  value={city}
                  menuItem={selectMenuItem(t`None`)}
                  selectItems={citySelectItems}
                  name={name}
                  inputProps='custom-form-select'
                  isFormSelect
                />
              </>
            )}
            control={control}
            errorField={errors.city}
            className='select-minw'
          />
        </Box>
        <FormFieldBox
          title={t`Email`}
          rules={{
            pattern: agencyFormValidationConstants.validEmailError,
            required: agencyFormValidationConstants.requiredField
          }}
          name='email'
          render={({ field: { name, onChange } }) => (
            <TextField
              name={name}
              id='email'
              value={email}
              onChange={(e) => {
                onFormChangeWrapper(
                  e.target?.value,
                  name,
                  onChange,
                  setValueMap
                )
              }}
            />
          )}
          control={control}
          errorField={errors.email}
        />
        {!edit && (
          <Box className='mb-1'>
            <FormHelperText>{t`GrantAccessToDashboard`}</FormHelperText>
            <FormControlLabel
              className='mb-1 switch-label'
              name='dashboardAccess'
              control={
                <CustomSwitch
                  size='medium'
                  onClick={() =>
                    onFormChange(
                      dashboardAccess != null && !dashboardAccess,
                      constants.COMMON_FORM_VALUES
                        .DASHBOARD_ACCESS as keyof FormValues,
                      setValueMap
                    )}
                  checked={dashboardAccess}
                />
              }
              label={t`DashboardAccess`}
            />
          </Box>
        )}
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <SelfServeButton
            id='agencies-create-save'
            type='submit'
            variant='contained'
            disabled={!isDirty}
          >
            {edit ? t`SaveChanges` : t`CreateAgency`}
          </SelfServeButton>
          <SelfServeButton
            id='agencies-create-discard'
            onClick={onDiscard}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
      {renderConfirmLeavingDialog}
    </Box>
  )
}

export default AgenciesForm
