import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import empty from '../../../assets/images/Empty.png'

const EmptyWallet = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <Box
      display='flex'
      className='mt-1 mb-1'
      flexDirection='column'
      alignItems='center'
    >
      <img className='maxw-4 maxh-4' src={empty} alt='empty-icon' />
      <Typography variant='h5'>{t`NoTransactions`}</Typography>
      <Typography className='subtitle3'>
        {t`ThereAreNoRecords`}
      </Typography>
    </Box>
  )
}

export default EmptyWallet
