import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import store from './app/core/redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import * as Sentry from '@sentry/react'

const {
  REACT_APP_SENTRY_URL,
  REACT_APP_VERSION = '',
  REACT_APP_ENVIRONMENT
} = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  environment: REACT_APP_ENVIRONMENT,
  release: REACT_APP_VERSION
})

const persistor = persistStore(store)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
reportWebVitals()
