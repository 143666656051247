import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { offerCategoriesService } from './services'
import { offerCategoriesConstants } from './constants'
import { OfferCategoryState } from '../../type'
import { OffersListParams } from '../offers/interfaces/offers'
import { handleRejectWithReport } from '../core/error/handler'
import {
  OfferCategoriesInterface,
  OfferCategoriesListParams,
  OfferCategoriesParams
} from './interfaces/offer-categories'
import { handleSliceErrors } from '../commons/utils/utils'

export const getOfferCategoryByID = createAsyncThunk(
  'categories/getOfferCategoryByID',
  async (params: OfferCategoriesParams, { rejectWithValue }) => {
    try {
      return await offerCategoriesService.getOfferCategoryByID(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getLiveOffers = createAsyncThunk(
  'categories/getLiveOffers',
  async (params: OfferCategoriesParams, { rejectWithValue }) => {
    try {
      return await offerCategoriesService.getLiveOffersByCategoryID(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getOffersByCategoryID = createAsyncThunk(
  'categories/getOffersByCategoryID',
  async (params: OffersListParams, { rejectWithValue }) => {
    if (params.id != null) {
      try {
        return await offerCategoriesService.getOffersByCategoryID(
          params,
          params?.id
        )
      } catch (error: any) {
        return handleRejectWithReport(rejectWithValue, error)
      }
    }
  }
)

export const getOfferCategories = createAsyncThunk(
  'categories/getOfferCategories',
  async (params: OfferCategoriesListParams, { rejectWithValue }) => {
    try {
      return await offerCategoriesService.getOfferCategories(params)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const createOfferCategory = createAsyncThunk(
  'categories/createOfferCategory',
  async (params: OfferCategoriesInterface, { rejectWithValue }) => {
    try {
      return await offerCategoriesService.createOfferCategory(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editOfferCategory = createAsyncThunk(
  'categories/editOfferCategory',
  async (params: OfferCategoriesInterface, { rejectWithValue }) => {
    try {
      if (params.OfferCategoryID != null) {
        return await offerCategoriesService.editOfferCategory(
          params.OfferCategoryID,
          params
        )
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

const categoriesInitialState = {
  offerCategorySelected: {},
  liveOffers: [],
  loading: true,
  offers: [],
  totalOffersList: 0,
  offerCategories: [],
  totals: 0,
  isFulfilled: false,
  errors: []
}

const offerCategories = createSlice({
  name: offerCategoriesConstants.Domain,
  initialState: categoriesInitialState as OfferCategoryState,
  reducers: {
    resetCategoryForm (state) {
      state.isFulfilled = false
      state.errors = []
      state.offerCategorySelected = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferCategoryByID.fulfilled, (state, action) => {
        state.offerCategorySelected = action.payload
        state.loading = false
      })
      .addCase(getOfferCategoryByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getOfferCategoryByID.rejected, (state, action) => {
        state.offerCategorySelected = {}
        state.loading = false
      })
      .addCase(getLiveOffers.fulfilled, (state, action) => {
        state.liveOffers = action.payload
        state.loading = false
      })
      .addCase(getLiveOffers.pending, (state) => {
        state.loading = true
      })
      .addCase(getLiveOffers.rejected, (state, action) => {
        state.liveOffers = []
        state.loading = false
      })
      .addCase(getOffersByCategoryID.fulfilled, (state, action) => {
        state.offers = action.payload?.offers ?? []
        state.totalOffersList = action.payload?.count ?? 0
        state.loading = false
      })
      .addCase(getOffersByCategoryID.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getOffersByCategoryID.rejected, (state, action) => {
        state.offers = []
        state.totalOffersList = 0
        state.loading = false
      })
      .addCase(getOfferCategories.fulfilled, (state, action) => {
        state.offerCategories = action.payload?.offersCategories ?? []
        state.totals = action.payload.count
        state.loading = false
      })
      .addCase(getOfferCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(getOfferCategories.rejected, (state, action) => {
        state.offerCategories = []
        state.totals = 0
        state.loading = false
      })
      .addCase(createOfferCategory.fulfilled, (state, action) => {
        state.loading = false
        state.isFulfilled = true
        state.offerCategorySelected = action.payload
      })
      .addCase(createOfferCategory.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(createOfferCategory.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
      .addCase(editOfferCategory.fulfilled, (state, action) => {
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editOfferCategory.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(editOfferCategory.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
  }
})

export const { resetCategoryForm } = offerCategories.actions
export const offerCategoriesReducer = offerCategories.reducer
