import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FC } from 'react'
import '../segments.scss'
import { useAppSelector } from '../../core/redux/hooks'
import { SegmentsFormStepsProps } from '../interfaces/segments'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import SelfServeButton from '../../ui/button/Button'
import { handleNextStep } from '../../commons/utils/utils'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { MANUAL } from '../constants'

const SegmentTypeStep: FC<SegmentsFormStepsProps> = ({
  onFormChange,
  handleNext,
  onDiscard
}) => {
  const {
    segments: { segmentsForm }
  } = useAppSelector((state) => state)
  const { t } = useTranslation()

  const { trigger } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      Manual: segmentsForm?.Manual
    }
  })

  return (
    <Form
      autoComplete='off'
      method='post'
      onSubmit={(event: React.FormEvent) => {
        handleNext != null &&
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
      }}
      className='form-wrapper'
    >
      <Typography variant='subtitle1' className='mt-1'>
        {t`SelectTheTypeOfTheSegment`}
      </Typography>
      <RadioGroup
        aria-labelledby='segment-radios'
        name='manual'
        value={segmentsForm?.Manual}
        onChange={(e) => {
          onFormChange(e.target.value === constants.TRUE, MANUAL)
        }}
      >
        <FormControlLabel value control={<Radio />} label={t`Manual`} />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={t`RewardsCode`}
        />
      </RadioGroup>
      <Divider className='mt-2' />
      <Box className='mt-1 space-between'>
        <SelfServeButton
          id='segment-create-next'
          type='submit'
          variant='contained'
        >
          {t`Next`}
        </SelfServeButton>
        <SelfServeButton
          id='segments-create-discard'
          onClick={onDiscard}
          variant='outlined'
        >
          {t`Discard`}
        </SelfServeButton>
      </Box>
    </Form>
  )
}

export default SegmentTypeStep
