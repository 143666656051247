import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AgenciesInterface,
  AgenciesListSearch,
  AgenciesResendEmail
} from './interfaces/agencies'
import { agenciesService } from './services'
import { agenciesConstants } from './constants'
import { AgenciesState } from '../../type'
import { handleRejectWithReport } from '../core/error/handler'
import { handleSliceErrors } from '../commons/utils/utils'

export const getAllAgencies = createAsyncThunk(
  'agencies/getAllAgencies',
  async (search: AgenciesListSearch, { rejectWithValue }) => {
    try {
      return await agenciesService.getAllAgencies(search)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const getFilteredAgencies = createAsyncThunk(
  'agencies/getFilteredAgencies',
  async (search: AgenciesListSearch, { rejectWithValue }) => {
    try {
      return await agenciesService.getAgencies(search)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const createAgency = createAsyncThunk(
  'agencies/createAgency',
  async (params: AgenciesInterface, { rejectWithValue }) => {
    try {
      return await agenciesService.createAgency(params)
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const editAgency = createAsyncThunk(
  'agencies/editAgency',
  async (params: AgenciesInterface, { rejectWithValue }) => {
    try {
      if (params.AgencyID != null) {
        return await agenciesService.editAgency(params.AgencyID, params)
      }
    } catch (error: any) {
      return handleSliceErrors(error, rejectWithValue)
    }
  }
)

export const getAgencyByID = createAsyncThunk(
  'agencies/getAgencyByID',
  async (id: string, { rejectWithValue }) => {
    try {
      return await agenciesService.getAgencyByID(id)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const editAgencyStatus = createAsyncThunk(
  'agencies/editAgencyStatus',
  async (agency: AgenciesInterface, { rejectWithValue }) => {
    try {
      return await agenciesService.editAgencyStatus(
        agency.AgencyID ?? '',
        agency.AgencyStatus ?? ''
      )
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const resendAgencyEmail = createAsyncThunk(
  'agencies/resendAgencyEmail',
  async ({ id, emailType }: AgenciesResendEmail, { rejectWithValue }) => {
    try {
      return await agenciesService.resendAgencyEmail(id, emailType)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

const agenciesInitialState = {
  agencies: [],
  agencySelected: {},
  loading: false,
  filteredAgencies: [],
  totals: 0,
  isFulfilled: false,
  errors: []
}

const agencies = createSlice({
  name: agenciesConstants.Domain,
  initialState: agenciesInitialState as AgenciesState,
  reducers: {
    setAgencySelected (state, action) {
      state.agencySelected = action.payload
    },
    resetAgencyForm (state) {
      state.isFulfilled = false
      state.errors = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAgencies.fulfilled, (state, action) => {
        state.agencies = action.payload?.agencies ?? []
        state.loading = false
      })
      .addCase(getAllAgencies.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllAgencies.rejected, (state, action) => {
        state.agencies = []
        state.loading = false
      })
      .addCase(getAgencyByID.fulfilled, (state, action) => {
        state.agencySelected = action.payload
        state.loading = false
      })
      .addCase(getAgencyByID.pending, (state) => {
        state.loading = true
      })
      .addCase(getAgencyByID.rejected, (state, action) => {
        state.agencySelected = {}
        state.loading = false
      })
      .addCase(editAgencyStatus.fulfilled, (state, action) => {
        state.agencySelected.AgencyStatus = action.payload.AgencyStatus
      })
      .addCase(resendAgencyEmail.fulfilled, (state, action) => {
        state.agencySelected.DashboardAccess =
          action.payload.DashboardAccess ?? state.agencySelected.DashboardAccess
      })
      .addCase(getFilteredAgencies.fulfilled, (state, action) => {
        state.filteredAgencies = action.payload.agencies
        state.totals = action.payload.count
      })
      .addCase(getFilteredAgencies.rejected, (state) => {
        state.filteredAgencies = []
        state.totals = 0
      })
      .addCase(createAgency.fulfilled, (state) => {
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(createAgency.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
      .addCase(editAgency.fulfilled, (state) => {
        state.loading = false
        state.isFulfilled = true
      })
      .addCase(editAgency.pending, (state) => {
        state.loading = true
        state.isFulfilled = false
      })
      .addCase(editAgency.rejected, (state, action) => {
        state.loading = false
        state.isFulfilled = false
        state.errors = action.payload as string[]
      })
  }
})

export const { setAgencySelected, resetAgencyForm } = agencies.actions
export const agenciesReducer = agencies.reducer
