import { SelectChangeEvent } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import {
  REORDER_KEY_NAME,
  UI_TYPE,
  constants
} from '../../commons/constants/constants'
import { FC, useState, ReactElement, useEffect, ChangeEvent } from 'react'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation,
  useParams
} from 'react-router-dom'
import { uploadImage } from '../../commons/components/uploader/utils'
import Box from '@mui/material/Box'
import '../campaigns.scss'
import { RoutePath } from '../../core/routes/routePaths'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { t } from 'i18next'
import CampaignCardInfo from './CampaignCardInfo'
import CampaignDetail from './CampaignDetail'
import CampaignOptions from './CampaignOptions'
import CampaignRewards from './CampaignRewardsDistribution'
import CampaignEditAudience from './campaig-audience/CampaignEditAudience'
import CampaignPreview from './CampaignPreview'
import { CampaignFormInterface } from '../interfaces/campaigns'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import {
  editCampaign,
  getCampaignByID,
  getCampaigns,
  getFeaturedCampaigns,
  modifyCampaignForm,
  resetCampaignForm,
  setCampaignForm
} from '../slices'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { blockerCondition } from '../../offers/utils'
import {
  STEPS_FOR_CREATE_CAMPAIGN,
  STEP_NUMBER,
  CampaignsTypes
} from '../constants'
import {
  setEntity,
  setEditMode,
  setBackButtonRoute,
  setBackButtonTitle
} from '../../ui/sidebar/slices'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ErrorStatus } from '../constants/CommonConstants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import {
  ReorderList,
  checkIsNotEmpty,
  errorDisplay,
  getErrorMessage,
  getUpdatedList,
  handleReorderSave,
  isValid,
  modifyUIObjects,
  validationErrorsDisplay
} from '../../commons/utils/utils'
import CampaignPhonePreviewWrapper from '../campaign-phone-previews/CampaignPhonePreviewWrapper'
import ReorderUiPositionWrapperEdit from '../../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperEdit'

dayjs.extend(utc)

const CampaignEdit: FC<{}> = (): ReactElement | null => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState(false)
  const [openConfirmEditCampaign, setOpenConfirmEditCampaign] =
    useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const [checkEditable, setCheckEditable] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(
    null
  )

  const {
    campaignSelected,
    campaignForm,
    isFormUpdated,
    errors,
    errorStatus,
    loading,
    campaigns,
    featuredCampaigns
  } = useAppSelector((state) => ({
    campaignSelected: state.campaigns.campaignSelected,
    campaignForm: state.campaigns.campaignForm,
    isFormUpdated: state.campaigns.isFormUpdated,
    errors: state.campaigns.errors,
    errorStatus: state.campaigns.errorStatus,
    loading: state.campaigns.loading,
    campaigns: state.campaigns.campaigns,
    featuredCampaigns: state.campaigns.featuredCampaigns
  }))

  const {
    userID,
    tenantID: TenantID,
    isAgencyEnabled,
    userType
  } = useAppSelector((state) => state.auth)
  const {
    name,
    title,
    thumbnailFile,
    campaignIconFile: iconFile,
    AgencyID,
    category,
    isFeatured
  } = campaignForm
  const [webUiPosition, setWebUiPosition] = useState(
    campaignSelected.WebUiPosition?.toString() ?? ''
  )
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [featuredUiPosition, setFeaturedUiPosition] = useState(
    campaignSelected.FeaturedUiPosition?.toString() ?? ''
  )
  const [isFeaturedUIReorderOpen, setOpenFeaturedUIreorder] =
    useState<boolean>(false)
  const [listWithNewFeaturedOrder, setListWithNewFeaturedOrder] =
    useState<ReorderList>([])
  const [updatedFeaturedUIs, setUpdatedFeaturedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsCampaignNameUpdated] = useState<boolean>(false)

  useEffect(() => {
    dispatch(resetCampaignForm())
  }, [dispatch])

  useEffect(() => {
    if (errors.length > 0) {
      handleClose()
    }
  }, [errors])

  useEffect(() => {
    if (errorStatus === ErrorStatus.PASS) {
      dispatch(resetCampaignForm())
      navigate(`${RoutePath.Campaigns}/${id ?? ''}`)
    }
  }, [errorStatus, navigate, id, dispatch])

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleVisitStep = (stepNumber: number): void => {
    setActiveStep(stepNumber)
  }
  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const handleClose = (): void => {
    setOpenConfirmEditCampaign(false)
    setOpenConfirmLeaving(false)
  }
  const handleChange = (
    e:
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | SelectChangeEvent<string | null | string[]>,
    name: keyof CampaignFormInterface,
    onChange: (val: string | string[] | null, name?: string) => void
  ): void => {
    onFormChange(e.target?.value, name)
    if (name != null) {
      onChange(e.target?.value, e.target?.name)
    } else {
      onChange(e.target?.value)
    }
  }

  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof CampaignFormInterface,
    onChange: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    onChange(date)
  }

  useEffect(() => {
    if (id != null) {
      void dispatch(getCampaignByID(id))
      dispatch(setCampaignForm())
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.CAMPAIGN))
      dispatch(setEditMode(true))
      dispatch(setBackButtonTitle(t`BackToCampaign`))
      dispatch(setBackButtonRoute(RoutePath.Campaigns))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
      dispatch(setEditMode(false))
    }
  }, [dispatch, id])

  useEffect(() => {
    setCheckEditable(
      !(
        dayjs.utc().isAfter(dayjs.utc(campaignForm.startTimestampUTC)) ||
        (campaignForm.pointExpiryDate != null &&
          dayjs.utc().isAfter(dayjs.utc(campaignForm.pointExpiryDate)))
      )
    )
  }, [campaignForm])
  const blocker = useBlocker(() => {
    return blockerCondition(
      location.pathname,
      true,
      true,
      openConfirmLeaving || openConfirmEditCampaign,
      setOpenConfirmLeaving,
      navigation.location?.pathname
    )
  })
  const handleFormReset = (): void => {
    dispatch(resetCampaignForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(RoutePath.Campaigns)
  }
  const onFormChange = (
    value: CampaignFormInterface[keyof CampaignFormInterface] | Dayjs,
    name: string
  ): void => {
    dispatch(modifyCampaignForm({ name, value, campaignForm }))
  }
  const onReorderSave = (list: ReorderList): void => {
    if (title != null) {
      handleReorderSave(
        title,
        list,
        setWebUiPosition,
        setOpenUIreorder,
        setUpdatedUIs,
        setListWithNewOrder,
        false,
        true,
        false,
        true,
        campaignSelected.CampaignID
      )
    }
  }
  const onFeaturedReorderSave = (list: ReorderList): void => {
    if (title != null) {
      handleReorderSave(
        title,
        list,
        setFeaturedUiPosition,
        setOpenFeaturedUIreorder,
        setUpdatedFeaturedUIs,
        setListWithNewFeaturedOrder,
        false,
        true,
        true,
        true,
        campaignSelected.CampaignID
      )
    }
  }

  useEffect(() => {
    if ((isValid(AgencyID) || !isAgencyEnabled) && category != null) {
      const categoryID = category.toString()
      void dispatch(
        getCampaigns({
          tenantID: TenantID,
          agencyID: AgencyID?.toString(),
          categories: categoryID != null ? [categoryID] : []
        })
      )
    }
  }, [dispatch, AgencyID, TenantID, category, isAgencyEnabled])

  useEffect(() => {
    if ((isValid(AgencyID) || !isAgencyEnabled) && isFeatured) {
      void dispatch(
        getFeaturedCampaigns({
          tenantID: TenantID,
          agencyID: AgencyID?.toString()
        })
      )
    }
  }, [dispatch, AgencyID, TenantID, isFeatured, isAgencyEnabled])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setFeaturedUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setListWithNewFeaturedOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])

  useEffect(() => {
    const categoryID = category?.toString()
    const categoryIDFromDB = campaignSelected.CategoryID?.toString()
    if (categoryID === categoryIDFromDB) {
      setListWithNewOrder(
        getUpdatedList(
          true,
          campaigns,
          REORDER_KEY_NAME.CampaignIDKeyWord,
          title,
          campaignSelected.CampaignID
        )
      )
    }
  }, [category, title, campaignSelected, campaigns])

  const handleNameChange = (list: ReorderList): void => {
    setListWithNewOrder(
      getUpdatedList(
        true,
        list,
        REORDER_KEY_NAME.CampaignIDKeyWord,
        title,
        campaignSelected.CampaignID
      )
    )
    setIsCampaignNameUpdated(false)
  }

  const handleNameChangeForFeatured = (list: ReorderList): void => {
    setListWithNewFeaturedOrder(
      getUpdatedList(
        true,
        list,
        REORDER_KEY_NAME.CampaignIDKeyWord,
        title,
        campaignSelected.CampaignID
      )
    )
    setIsCampaignNameUpdated(false)
  }

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }
  const editCampaignSubmission = async (): Promise<void> => {
    try {
      const iconUpload =
        iconFile != null ? await uploadImage(userID, iconFile) : null
      const thumbnailUpload =
        thumbnailFile != null ? await uploadImage(userID, thumbnailFile) : null
      void dispatch(
        editCampaign({
          ...campaignForm,
          fromCreation: false,
          campaignID: id?.toString(),
          campaignsImageID:
            thumbnailUpload?.imageID === 0 ? null : thumbnailUpload?.imageID,
          campaignsIconID:
            iconUpload?.imageID === 0 ? null : iconUpload?.imageID,
          ctaName: campaignForm.hasCTA ? campaignForm.ctaName : null,
          ctaLink: campaignForm.hasCTA ? campaignForm.ctaLink : null,
          campaignType: CampaignsTypes.TRANSACTIONAL,
          uiPosition: parseInt(webUiPosition),
          featuredUi: checkIsNotEmpty(featuredUiPosition)
            ? parseInt(featuredUiPosition)
            : null,
          TenantID,
          updatedUIs: modifyUIObjects(updatedUIs, false, UI_TYPE.CAMPAIGN),
          updatedFeaturedUIs: modifyUIObjects(
            updatedFeaturedUIs,
            true,
            UI_TYPE.CAMPAIGN
          ),
          existFeatured: campaignSelected.FeaturedUiPosition !== null,
          userType
        })
      )
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    }
  }
  const renderConfirmEditCampaignDialog = (
    <CustomDialog
      title={t`EditCampaign`}
      open={openConfirmEditCampaign}
      cancelCallback={handleClose}
      okCallback={editCampaignSubmission}
      mainMessage={t('CampaignEditDashboardDialogMessage')}
      name={name}
      okText={t`EditWithAccess`}
      cancelText={t`Cancel`}
    />
  )
  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardCampaignEdit`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )
  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case STEP_NUMBER.CardInfo:
        return (
          <Box>
            <CampaignCardInfo
              webUiPosition={webUiPosition}
              featuredUiPosition={featuredUiPosition}
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              onDiscard={handleDiscard}
              handleBack={handleBack}
              isLeavingDialogOpen={openConfirmLeaving}
              isReorderDisabled={
                isValid(title) &&
                (isValid(AgencyID) || !isAgencyEnabled) &&
                category != null
              }
              isFeaturedReorderDisabled={
                isValid(title) && (isValid(AgencyID) || !isAgencyEnabled)
              }
              onUiReorderClick={() => setOpenUIreorder(true)}
              onFeaturedUiReorderClick={() => setOpenFeaturedUIreorder(true)}
              resetUIs={() => setResetUIs(true)}
              setIsCampaignNameUpdated={() => setIsCampaignNameUpdated(true)}
              isFromEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Detail:
        return (
          <Box>
            <CampaignDetail
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isFromEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.CampaignOptions:
        return (
          <Box>
            <CampaignOptions
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isFromEdit
              isEditable={checkEditable}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.RewardsDistribution:
        return (
          <Box>
            <CampaignRewards
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isFromEdit
              isEditable={checkEditable}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Audience:
        return (
          <Box>
            <CampaignEditAudience
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isFromEdit
              isEditable={checkEditable}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Preview:
        return (
          <Box>
            <CampaignPreview
              webUiPosition={webUiPosition}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              onSubmit={() => setOpenConfirmEditCampaign(true)}
              handleVisitStep={handleVisitStep}
              isLeavingDialogOpen={openConfirmLeaving}
              isFromEdit
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      default:
        return null
    }
  }
  return isFormUpdated
    ? (
      <>
        <Box>
          <h1 className='title'>{t`EditCampaign`}</h1>
        </Box>
        <Box className='editOutline editCampaignWrapper'>
          <SelfServeStepper
            nonLinear={false}
            steps={STEPS_FOR_CREATE_CAMPAIGN}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
          <Box className='mt-2'>
            <Box className='space-between'>
              <Box minWidth={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
              <CampaignPhonePreviewWrapper isFromCreateEdit />
            </Box>
          </Box>
          {isUIReorderOpen && (
            <ReorderUiPositionWrapperEdit
              isUIReorderOpen={isUIReorderOpen}
              handleClose={() => setOpenUIreorder(false)}
              name={title}
              onReorderSave={onReorderSave}
              currentList={campaigns}
              listWithNewOrder={listWithNewOrder}
              currentID={campaignSelected.CampaignID}
              handleNameChange={handleNameChange}
              isNameUpdated={isNameUpdated}
              isCampaign
            />
          )}
          {isFeaturedUIReorderOpen && (
            <ReorderUiPositionWrapperEdit
              isUIReorderOpen={isFeaturedUIReorderOpen}
              handleClose={() => setOpenFeaturedUIreorder(false)}
              name={title}
              onReorderSave={onFeaturedReorderSave}
              currentList={featuredCampaigns}
              listWithNewOrder={listWithNewFeaturedOrder}
              currentID={campaignSelected.CampaignID}
              handleNameChange={handleNameChangeForFeatured}
              isNameUpdated={isNameUpdated}
              isCampaign
              isFeatured
            />
          )}
          {renderConfirmEditCampaignDialog}
          {renderConfirmLeavingDialog}
          {validationErrorsDisplay(errors)}
        </Box>
      </>
      )
    : null
}
export default CampaignEdit
