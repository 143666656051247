import initTranslation from '../core/utils/i18n'
import { ErrorLevels } from '../core/error/constants'
import { reportError } from '../core/error/handler'
import i18n from 'i18next'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export const PER_PAGE_AGENCIES_TABLE = 10
export const FIRST_PAGE_AGENCIES_TABLE = 0

export enum AgenciesListColumns {
  'ID' = 'ID',
  'AGENCY' = 'Agency',
  'LOCATION' = 'Location',
  'STATUS' = 'Status',
  'ADMIN_ACCESS' = 'Admin Access',
  'MENU' = ''
}

export const agenciesConstants = {
  Domain: 'agencies',
  Statuses: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  },
  EmailType: {
    PASSWORD: 'PASSWORD',
    INVITATION: 'INVITATION'
  }
}

export enum AgenciesStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const agencyFormValidationConstants = {
  requiredField: { value: true, message: 'Required Field' },
  minLengthError: {
    value: 3,
    message: i18n.t('AgencyNameLengthError')
  },
  maxLengthError: {
    value: 50,
    message: i18n.t('AgencyNameLengthError')
  },
  validEmailError: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: i18n.t('ValidEmail')
  }
}

export const AGENCIES = 'Agencies'
