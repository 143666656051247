import { FC, useCallback } from 'react'
import {
  PER_PAGE_USERS_TABLE,
  FIRST_PAGE_USERS_TABLE,
  UserStatus
} from '../../users/constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import {
  setClassNameForTable,
  convertStringToDateAndTime,
  getCapitalisation
} from '../../commons/utils/utils'
import { UsersListParams } from '../../users/interfaces/users'
import StatusIndicator from '../../ui/status-indicator/StatusIndicator'
import { RoutePath } from '../../core/routes/routePaths'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../core/redux/hooks'
import {
  UsersForRewardsSegmentsListColumns,
  UsersForSegmentsListColumns
} from '../constants'
import { Box } from '@mui/material'
import { usersListActionItems } from '../../users/utils'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'

interface SegmentUsersListProps {
  onPageChange: (changedData: UsersListParams) => void
  page?: number
  perPage?: number
}

const SegmentUsersList: FC<SegmentUsersListProps> = ({
  page,
  perPage,
  onPageChange
}) => {
  const {
    users,
    totalUsers,
    segmentSelected: { Manual }
  } = useAppSelector((state) => state.segments)

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )
  const COLUMNS = Object.values(
    Manual === true
      ? UsersForSegmentsListColumns
      : UsersForRewardsSegmentsListColumns
  )

  return (
    <TableContainer className='mt-1'>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {users?.length > 0 && (
          <TableBody>
            {users?.map((row) => {
              const joinDateString = convertStringToDateAndTime(
                row.CreatedAtUTC
              )
              return (
                <TableRow className='row-with-actions' key={row.UserID}>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Users}/${row.UserID ?? ''}`}>
                      {row.UserID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    {row.CreatedAtUTC != null &&
                      `${joinDateString?.date ?? ''} |
                  ${joinDateString?.time ?? ''}`}
                  </TableCell>
                  <TableCell className='body-cell'>
                    <StatusIndicator
                      color={
                        row.Status === UserStatus.NEW ||
                        row.Status === UserStatus.ACTIVE
                          ? 'success'
                          : row.Status === UserStatus.INACTIVE
                            ? 'warning'
                            : 'error'
                      }
                      title={getCapitalisation(row.Status ?? '')}
                    />
                  </TableCell>
                  {Manual === false && (
                    <TableCell className='body-cell'>
                      {row.PromotionCode}
                    </TableCell>
                  )}
                  <TableCell className='body-cell'>
                    <Box className='list-actions flex-center'>
                      {usersListActionItems(row.UserID ?? '').map((item) => {
                        return (
                          <SelfServeActionItem
                            path={item.path}
                            title={item.title}
                            icon={item.icon}
                            key={item.title}
                          />
                        )
                      })}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        page={page ?? FIRST_PAGE_USERS_TABLE}
        perPage={perPage ?? PER_PAGE_USERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={totalUsers}
        length={users?.length}
      />
    </TableContainer>
  )
}

export default SegmentUsersList
