import { ReactElement, FC, useEffect } from 'react'
import { OffersListParams } from '../../offers/interfaces/offers'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import { getAllAgencies } from '../../agencies/slices'
import { FormControl, InputLabel } from '@mui/material'
import { Form } from 'react-router-dom'
import { FilterOfferStatus, FilterOfferTypes } from '../../offers/constants'
import {
  AgenciesSelectItems,
  StaticSelectItems,
  allText,
  selectMenuItem
} from '../../commons/utils/utils'
import SelfServeSearchField from '../../ui/search-field/SelfServeSearchField'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'
interface CategoryOffersListHeaderProps {
  handleFormChange: (changedData: OffersListParams) => void
  search?: string
  agency?: string
  status?: string
  offerType?: string
}

const CategoryOffersListHeader: FC<CategoryOffersListHeaderProps> = ({
  handleFormChange,
  search,
  agency,
  status,
  offerType
}): ReactElement => {
  const { userType, tenantID, isAgencyEnabled, agencies } = useAppSelector(
    (state) => ({
      userType: state.auth.userType,
      tenantID: state.auth.tenantID,
      isAgencyEnabled: state.auth.isAgencyEnabled,
      agencies: state.agencies.agencies
    })
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userType !== RoleType.AGENCY && isAgencyEnabled) {
      void dispatch(getAllAgencies({ tenantID }))
    }
  }, [tenantID, dispatch, userType, isAgencyEnabled])

  return (
    <div>
      <Form role='search'>
        <FormControl className='form-control'>
          <SelfServeSearchField value={search} onChange={handleFormChange} />
        </FormControl>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='offer-type-label'>
            {(offerType == null || offerType === '') &&
              allText(t`OfferTypeSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ offerType: e.target.value as string })}
            value={offerType}
            menuItem={selectMenuItem(t`All`)}
            selectItems={StaticSelectItems(FilterOfferTypes)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <FormControl className='form-control' size='small'>
            <InputLabel shrink={false} id='agencies-label'>
              {(agency == null || agency === '') && allText(t`AgencyForSelect`)}
            </InputLabel>
            <SelfServeCustomSelect
              handleFormChange={(e) =>
                handleFormChange({ agencyID: e.target.value as string })}
              value={agency}
              menuItem={selectMenuItem(t`AllAgencies`)}
              selectItems={AgenciesSelectItems(agencies)}
              inputProps='table-input maxw-8 minw-8'
            />
          </FormControl>
        )}
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='status-label'>
            {(status == null || status === '') && allText(t`StatusForSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ status: e.target.value as string })}
            value={status}
            menuItem={selectMenuItem(t`AllStatus`)}
            selectItems={StaticSelectItems(FilterOfferStatus)}
            inputProps='table-input maxw-8 minw-8'
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default CategoryOffersListHeader
