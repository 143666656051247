import { ReactElement, FC, useMemo } from 'react'
import { UsersListParams } from '../interfaces/users'
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material'
import { Form } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { allText, selectMenuItem } from '../../commons/utils/utils'
import { useTranslation } from 'react-i18next'
import { TransactionTypeOptions } from '../constants'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

interface WalletTransactionsListHeaderProps {
  handleFormChange: (changedData: UsersListParams) => void
  type?: string
}

const WalletTransactionsListHeader: FC<WalletTransactionsListHeaderProps> = ({
  handleFormChange,
  type
}): ReactElement => {
  const { t } = useTranslation()

  const transactionTypeSelectItems = useMemo(
    () =>
      Object.entries(TransactionTypeOptions).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {v}
        </MenuItem>
      )),
    []
  )

  return (
    <div className='mb-1'>
      <Form role='search'>
        <FormControl className='form-control' size='small'>
          <InputLabel shrink={false} id='agencies-label'>
            {(type == null || type === '') && allText(t`TransactionTypeSelect`)}
          </InputLabel>
          <SelfServeCustomSelect
            handleFormChange={(e) =>
              handleFormChange({ type: e.target.value as string })}
            value={type}
            inputProps='table-input minw-9'
            menuItem={selectMenuItem(t`AllTransactions`)}
            selectItems={transactionTypeSelectItems}
          />
        </FormControl>
      </Form>
    </div>
  )
}

export default WalletTransactionsListHeader
