import { CampaignListParams, CampaignsInterface } from '../campaigns/interfaces/campaigns'
import { axiosInstance } from '../core/api/init'
import {
  CampaignCategoryListParams,
  CampaignCategoryListResponse,
  CampaignCategoryInterface,
  CampaignsByCategoryListResponse
} from './interfaces/campaign-categories'

export const campaignCategoriesService = {
  getCampaignCategories: async (
    params: CampaignCategoryListParams
  ): Promise<CampaignCategoryListResponse> => {
    const res = await axiosInstance.get('/campaign-categories', {
      params: {
        perPage: params.perPage,
        page: params.page,
        tenantID: params.tenantID,
        agencyID: params.agencyID
      }
    })
    return res.data
  },
  getCampaignCategoryByID: async (
    id: string
  ): Promise<CampaignCategoryInterface> => {
    const res = await axiosInstance.get(`/campaign-categories/${id}`)
    return res.data
  },
  getCampaignsByCategoryID: async (
    params: CampaignListParams,
    id: string
  ): Promise<CampaignsByCategoryListResponse> => {
    const res = await axiosInstance.get(
      `/campaign-categories/${id}/campaigns`,
      {
        params: {
          perPage: params.perPage,
          page: params.page,
          tenantID: params.tenantID,
          agencyID: params.agencyID,
          search: params.search,
          status: params.status
        }
      }
    )
    return res.data
  },
  getLiveCampaignsByCategoryID: async (
    tenantID: string,
    id: string
  ): Promise<CampaignsInterface[]> => {
    const res = await axiosInstance.get(
      `/campaign-categories/${id}/live-campaigns`,
      {
        params: {
          tenantID
        }
      }
    )
    return res.data
  },
  createCampaignCategory: async (
    campaignCategory: CampaignCategoryInterface
  ): Promise<CampaignCategoryInterface> => {
    const res = await axiosInstance.post('/campaign-categories/', {
      categoryName: campaignCategory.CategoryName,
      webUiPosition: campaignCategory.WebUiPosition,
      iconImageID: campaignCategory.IconImageID,
      tenantID: campaignCategory.TenantID,
      agencyID: campaignCategory.AgencyID,
      updatedUIs: campaignCategory.UpdatedUIs
    })
    return res.data
  },
  editCampaignCategory: async (
    id: number,
    campaignCategory: CampaignCategoryInterface
  ): Promise<CampaignCategoryInterface> => {
    const res = await axiosInstance.put(`/campaign-categories/${id}`, {
      categoryName: campaignCategory.CategoryName,
      webUiPosition: campaignCategory.WebUiPosition,
      iconImageID: campaignCategory.IconImageID,
      agencyID: campaignCategory.AgencyID,
      updatedUIs: campaignCategory.UpdatedUIs
    })
    return res.data
  }
}

export default campaignCategoriesService
