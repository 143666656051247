import { Box, Badge, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterface } from '../interfaces/campaigns'
import { CampaignsTypes } from '../constants'
import { ReactElement } from 'react'

export interface CampaignsInterfaceProps {
  campaign: CampaignsInterface
  small?: boolean
}

const LiveCampaignContent = ({ campaign, small }: CampaignsInterfaceProps): ReactElement => {
  const { t } = useTranslation()
  const points =
    campaign.RewardPerUser != null ? +campaign.RewardPerUser : 0
  const isStreakCampaign = campaign.CampaignType === CampaignsTypes.STREAK
  const className = small != null && small ? 'live-campaigns mt-2 small' : 'live-campaigns mt-2 medium'
  return (
    <Box className={className} flexDirection='column'>
      {((campaign.RewardPerUser != null && points !== 0) ||
        isStreakCampaign) && (
          <Badge
            badgeContent={isStreakCampaign ? t`Streak` : `${points} pts`}
            overlap='rectangular'
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            className='mb-6'
          />
      )}
      <img src={campaign.Url} alt='live-campaign' />
      <Typography className='header'>{campaign.SubHeader}</Typography>
      <Typography className='subheader'>{campaign.Title}</Typography>
      <Typography className='campaign-action'>{campaign.SubTitle}</Typography>
    </Box>
  )
}

export default LiveCampaignContent
