import { FC, useCallback } from 'react'
import { UsersListParams } from '../interfaces/users'
import {
  PER_PAGE_USERS_TABLE,
  FIRST_PAGE_USERS_TABLE,
  TenantUserOffersListColumns,
  AgencyUserOffersListColumns
} from '../constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import { setClassNameForTable } from '../../commons/utils/utils'
import { RoutePath } from '../../core/routes/routePaths'
import { Link } from 'react-router-dom'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { OffersInterface } from '../../offers/interfaces/offers'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'
import { t } from 'i18next'
import { offersListActionItems } from '../../offers/utils'

interface UserVisibleOffersListProps {
  onPageChange: (changedData: UsersListParams) => void
  page: number
  perPage: number
  total: number
  offers: OffersInterface[]
  showAgency?: boolean
}

const UserVisibleOffersList: FC<UserVisibleOffersListProps> = ({
  page,
  perPage,
  total,
  offers,
  onPageChange,
  showAgency
}) => {
  const COLUMNS = Object.values(
    showAgency != null && showAgency
      ? TenantUserOffersListColumns
      : AgencyUserOffersListColumns
  )

  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
        aria-label='Offers table'
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map((column, i) => (
              <TableCell
                className={setClassNameForTable(column, i, COLUMNS)}
                key={column}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {offers?.length > 0 && (
          <TableBody>
            {offers?.map((row) => {
              return (
                <TableRow className='row-with-actions' key={row.OfferID}>
                  <TableCell className='body-cell centered-cell'>
                    <Link to={`${RoutePath.Offers}/${row.OfferID ?? ''}`}>
                      {row.OfferID}
                    </Link>
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Link to={`${RoutePath.Offers}/${row.OfferID ?? ''}`}>
                      {row.Title}
                    </Link>
                  </TableCell>
                  {showAgency === true && (
                    <TableCell className='body-cell'>{row.AgencyName}</TableCell>
                  )}
                  <TableCell className='body-cell'>{row.CategoryName}</TableCell>
                  <TableCell className='body-cell centered-cell'>
                    {row.WebUiPosition}
                  </TableCell>
                  <TableCell className='body-cell centered-cell'>
                    {row.RedeemedCouponsCount}
                  </TableCell>
                  <TableCell className='body-cell'>
                    <Box className='list-actions flex-center'>
                      <SelfServeActionItem
                        path={`${RoutePath.Offers}/${row.OfferID ?? ''}${
                        RoutePath.Coupons
                      }`}
                        title={t`Coupons`}
                        icon={<ConfirmationNumberOutlinedIcon />}
                      />
                      {offersListActionItems(row.OfferID?.toString() ?? '').map(
                        (item) => {
                          return (
                            <SelfServeActionItem
                              path={item.path}
                              title={item.title}
                              icon={item.icon}
                              key={item.title}
                            />
                          )
                        }
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      <SelfServePagination
        perPage={perPage ?? PER_PAGE_USERS_TABLE}
        page={page ?? FIRST_PAGE_USERS_TABLE}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        total={total}
        length={offers.length}
      />
    </TableContainer>
  )
}

export default UserVisibleOffersList
