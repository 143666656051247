import { RoleType } from './constants'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AgencyOutlinedIcon from '../../../assets/images/agencies-sidebar-icon.svg'
import initTranslation from '../utils/i18n'
import i18n from 'i18next'
import { ErrorLevels } from '../../core/error/constants'
import { reportError } from '../../core/error/handler'

if (!i18n.isInitialized) {
  initTranslation().catch((err) => {
    reportError(err, ErrorLevels.Fatal)
  })
}

export enum RoutePath {
  Login = '/login',
  ForgotPassword = '/forgot-password',
  Main = '/',
  Default = '*',
  Edit = 'edit',
  Empty = '',
  Segments = '/segments',
  Wallet = '/wallet',
  CampaignEarnings = '/campaign-earnings',
  VisibleCampaigns = '/visible-campaigns',
  VisibleOffers = '/visible-offers',
  Agencies = '/agencies',
  Campaigns = '/campaigns',
  Offers = '/offers',
  OfferSegments = '/offers/:id/segments',
  OfferRepeatableSettings = '/offers/:id/repeatable-settings',
  RepeatableSettings = '/repeatable-settings',
  Coupons = '/coupons',
  Codes = '/codes',
  Offer = '/offers/:id',
  OffersCoupons = '/offers/:id/coupons',
  OffersCouponsCreate = '/offers/:id/coupons/create',
  CreateOffers = '/offers/create',
  EditOffers = '/offers/:id/edit',
  Agency = '/agencies/:id',
  AgencyCampaigns = '/agencies/:id/campaigns',
  AgencyOffers = '/agencies/:id/offers',
  CreateAgencies = '/agencies/create',
  EditAgencies = '/agencies/:id/edit',
  Campaign = '/campaigns/:id',
  CampaignSegments = '/campaigns/:id/segments',
  CreateCampaigns = '/campaigns/create',
  EditCampaigns = '/campaigns/:id/edit',
  CampaignCategories = '/campaign-categories',
  CampaignCategory = '/campaign-categories/:id',
  CategoryCampaigns = '/campaign-categories/:id/campaigns',
  CreateCampaignCategories = '/campaign-categories/create',
  EditCampaignCategories = '/campaign-categories/:id/edit',
  OfferCategories = '/offer-categories',
  OfferCategory = '/offer-categories/:id',
  CreateOfferCategories = '/offer-categories/create',
  EditOfferCategories = '/offer-categories/:id/edit',
  CategoryOffers = '/offer-categories/:id/offers',
  Users = '/users',
  User = '/users/:id',
  UserWallet = '/users/:id/wallet',
  UserEarnings = '/users/:id/campaign-earnings',
  UserSegments = '/users/:id/segments',
  UserCampaigns = '/users/:id/visible-campaigns',
  UserOffers = '/users/:id/visible-offers',
  SegmentCreate = '/segments/create',
  SegmentEdit = '/segments/:id/edit',
  Segment = '/segments/:id',
  SegmentUsers = '/segments/:id/users',
  SegmentOffers = '/segments/:id/offers',
  SegmentCodes = '/segments/:id/codes',
  SegmentCampaigns = '/segments/:id/campaigns',
  AccessForbidden = '/access-forbidden'
}

export interface RouteItem {
  path: string
  setPath?: boolean
  title: string
  roles: RoleType[]
  icon: OverridableComponent<SvgIconTypeMap> | null
  children: RouteItem[]
  showBadge?: boolean
  badgeValue?: number
}

const rolesArray = [RoleType.ADMIN, RoleType.TENANT, RoleType.AGENCY]

export const SideBarRoutePath: Record<string, RouteItem> = {
  dashboard: {
    path: RoutePath.Main,
    title: i18n.t('Dashboard'),
    roles: rolesArray,
    icon: DashboardOutlinedIcon,
    children: []
  },
  agencies: {
    path: RoutePath.Agencies,
    title: i18n.t('Agencies'),
    roles: [RoleType.ADMIN, RoleType.TENANT],
    icon: () => (
      <img src={AgencyOutlinedIcon} alt='logo' className='custom-svg-icon' />
    ),
    children: [
      {
        path: RoutePath.CreateAgencies,
        title: i18n.t('AddNew'),
        roles: [RoleType.ADMIN, RoleType.TENANT],
        icon: null,
        children: []
      },
      {
        path: RoutePath.Agencies,
        title: i18n.t('AgenciesList'),
        roles: [RoleType.ADMIN, RoleType.TENANT],
        icon: null,
        children: []
      }
    ]
  },
  campaigns: {
    path: RoutePath.Campaigns,
    title: i18n.t('Campaigns'),
    roles: rolesArray,
    icon: CampaignOutlinedIcon,
    children: [
      {
        path: RoutePath.Campaigns,
        title: i18n.t('CampaignsList'),
        roles: rolesArray,
        icon: null,
        children: []
      },
      {
        path: RoutePath.CreateCampaigns,
        title: i18n.t('AddNew'),
        roles: rolesArray,
        icon: null,
        children: []
      },
      {
        path: RoutePath.CampaignCategories,
        title: i18n.t('CampaignCategories'),
        roles: rolesArray,
        icon: null,
        setPath: true,
        children: []
      }
    ]
  },
  offers: {
    path: RoutePath.Offers,
    title: i18n.t('Offers'),
    roles: rolesArray,
    icon: VerifiedOutlinedIcon,
    children: [
      {
        path: RoutePath.Offers,
        title: i18n.t('OffersList'),
        roles: rolesArray,
        icon: null,
        children: []
      },
      {
        path: RoutePath.CreateOffers,
        title: i18n.t('AddNew'),
        roles: rolesArray,
        icon: null,
        children: []
      },
      {
        path: RoutePath.OfferCategories,
        setPath: true,
        title: i18n.t('OfferCategories'),
        roles: rolesArray,
        icon: null,
        children: []
      }
    ]
  },
  users: {
    path: RoutePath.Users,
    title: i18n.t('Users'),
    roles: rolesArray,
    icon: PeopleAltOutlinedIcon,
    children: []
  },
  segments: {
    path: RoutePath.Segments,
    title: i18n.t('Segments'),
    roles: rolesArray,
    icon: DonutSmallOutlinedIcon,
    children: [
      {
        path: RoutePath.Segments,
        title: i18n.t('SegmentsList'),
        roles: rolesArray,
        icon: null,
        children: []
      },
      {
        path: RoutePath.SegmentCreate,
        title: i18n.t('AddNew'),
        roles: rolesArray,
        icon: null,
        children: []
      }
    ]
  },
  utilities: {
    path: RoutePath.Empty,
    title: i18n.t('Utilities'),
    roles: rolesArray,
    icon: SettingsOutlinedIcon,
    children: []
  }
}
