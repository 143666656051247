import { FC, ReactElement, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import {
  setEntity,
  setBackButtonTitle,
  setBackButtonRoute
} from '../../ui/sidebar/slices'
import { constants } from '../../commons/constants/constants'
import { RoutePath } from '../../core/routes/routePaths'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { getOfferDetailsByID } from '../slices'
import { Box } from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SelfServeButton from '../../ui/button/Button'
import './offer-details.scss'
import OfferInformation from './OfferInformationStep'
import CouponInformation from './OfferCouponStep'
import AudienceInformation from './OfferAudienceStep'
import { OffersInterface } from '../interfaces/offers'
import OfferPhonePreviewWrapper from '../offers-phone-preview/OfferPhonePreviewWrapper'

export interface OffersInterfaceProps {
  offer: OffersInterface
}

const OfferDetails: FC<{}> = (): ReactElement | null => {
  const { offerSelected, loading } = useAppSelector((state) => ({
    offerSelected: state.offers.offerSelected,
    loading: state.offers.loading
  }))
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const STEPS_FOR_OFFER_STEPPER = [
    t`OfferInformation`,
    t`CouponInformation`,
    t`Audience`
  ]
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<number>(0)

  useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return <OfferInformation offer={offerSelected} />
      case 1:
        return <CouponInformation offer={offerSelected} />
      case 2:
        return <AudienceInformation offer={offerSelected} />
      default:
        return null
    }
  }

  return offerSelected.OfferID != null && !loading
    ? (
      <Box className='offer-detail-box'>
        <Box className='flex-row'>
          <Box>
            <h1 className='title'>{t`OfferDetails`}</h1>
          </Box>
          <Box className='offer-edit-btn'>
            <SelfServeButton
              size='small'
              dataTestId='editButton'
              id='edit-offer-button'
              variant='contained'
              color='secondary'
              onClick={() =>
                navigate(
                `${RoutePath.Offers}/${offerSelected.OfferID ?? ''}/${
                  RoutePath.Edit
                }`
                )}
            >
              <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
            </SelfServeButton>
          </Box>
        </Box>
        <SelfServeStepper
          nonLinear
          steps={STEPS_FOR_OFFER_STEPPER}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
        <Box>
          <Box className='space-between'>
            <Box minWidth={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
            <OfferPhonePreviewWrapper isFromCreateEdit={false} />
          </Box>
        </Box>
      </Box>
      )
    : null
}
export default OfferDetails
