import { Box, CircularProgress, SelectChangeEvent } from '@mui/material'
import { FC, useState, ReactElement, useEffect, ChangeEvent } from 'react'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation
} from 'react-router-dom'
import { uploadImage } from '../../commons/components/uploader/utils'
import '../campaigns.scss'
import { RoutePath } from '../../core/routes/routePaths'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import CampaignCardInfo from './CampaignCardInfo'
import CampaignDetail from './CampaignDetail'
import CampaignOptions from './CampaignOptions'
import CampaignRewards from './CampaignRewardsDistribution'
import CampaignCreateAudience from './campaig-audience/CampaignCreateAudience'
import CampaignPreview from './CampaignPreview'
import { CampaignFormInterface } from '../interfaces/campaigns'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import {
  modifyCampaignForm,
  resetCampaignForm,
  createNewCampaign,
  getCampaigns,
  getFeaturedCampaigns
} from '../slices'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { blockerCondition } from '../../offers/utils'
import { STEPS_FOR_CREATE_CAMPAIGN, STEP_NUMBER } from '../constants'
import { ErrorStatus } from '../constants/CommonConstants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import {
  ReorderList,
  checkIsNotEmpty,
  errorDisplay,
  filterNonNumericValues,
  getErrorMessage,
  getUpdatedList,
  handleReorderSave,
  isValid,
  modifyUIObjects,
  validationErrorsDisplay
} from '../../commons/utils/utils'
import {
  REORDER_KEY_NAME,
  UI_TYPE,
  constants
} from '../../commons/constants/constants'
import _ from 'lodash'
import CampaignPhonePreviewWrapper from '../campaign-phone-previews/CampaignPhonePreviewWrapper'
import dayjs, { Dayjs } from 'dayjs'
import ReorderUiPositionWrapperCreate from '../../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperCreate'
import { RoleType } from '../../core/routes/constants'

const CampaignCreate: FC<{}> = (): ReactElement | null => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState(false)
  const [openConfirmCreateCampaign, setOpenConfirmCreateCampaign] =
    useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(
    null
  )

  const {
    campaignForm,
    loading,
    errors,
    errorStatus,
    campaignSelected,
    campaigns,
    featuredCampaigns
  } = useAppSelector((state) => state.campaigns)
  const {
    userID,
    tenantID: TenantID,
    isAgencyEnabled,
    userType,
    agencyID
  } = useAppSelector((state) => state.auth)
  const {
    name,
    title,
    startTimestampUTC,
    thumbnailFile,
    campaignIconFile: iconFile,
    AgencyID,
    category,
    isFeatured
  } = campaignForm
  const [webUiPosition, setWebUiPosition] = useState('')
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [featuredUiPosition, setFeaturedUiPosition] = useState('')
  const [isFeaturedUIReorderOpen, setOpenFeaturedUIreorder] =
    useState<boolean>(false)
  const [listWithNewFeaturedOrder, setListWithNewFeaturedOrder] =
    useState<ReorderList>([])
  const [updatedFeaturedUIs, setUpdatedFeaturedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsCampaignNameUpdated] = useState<boolean>(false)

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleVisitStep = (stepNumber: number): void => {
    setActiveStep(stepNumber)
  }
  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }

  const handleClose = (): void => {
    setOpenConfirmCreateCampaign(false)
    setOpenConfirmLeaving(false)
  }

  const handleChange = (
    e:
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | SelectChangeEvent<string | null | string[]>,
    name: keyof CampaignFormInterface,
    onChange: (val: string | string[] | null, name?: string) => void,
    isNumberOnly?: boolean
  ): void => {
    let value = e.target?.value
    if (_.isBoolean(isNumberOnly) && isNumberOnly && _.isString(value)) {
      value = filterNonNumericValues(value)
    }
    onFormChange(value, name)
    if (name != null) {
      onChange(value, e.target?.name)
    } else {
      onChange(value)
    }
  }
  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof CampaignFormInterface,
    onChange: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    onChange(date)
  }

  const blocker = useBlocker(() => {
    return blockerCondition(
      location.pathname,
      true,
      true,
      openConfirmLeaving || openConfirmCreateCampaign,
      setOpenConfirmLeaving,
      navigation.location?.pathname
    )
  })
  const handleFormReset = (): void => {
    dispatch(resetCampaignForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(RoutePath.Campaigns)
  }
  const onReorderSave = (list: ReorderList): void => {
    if (title != null) {
      handleReorderSave(
        title,
        list,
        setWebUiPosition,
        setOpenUIreorder,
        setUpdatedUIs,
        setListWithNewOrder,
        false,
        true,
        false,
        false
      )
    }
  }
  const onFeaturedReorderSave = (list: ReorderList): void => {
    if (title != null) {
      handleReorderSave(
        title,
        list,
        setFeaturedUiPosition,
        setOpenFeaturedUIreorder,
        setUpdatedFeaturedUIs,
        setListWithNewFeaturedOrder,
        false,
        true,
        true,
        false
      )
    }
  }

  useEffect(() => {
    if ((isValid(AgencyID) || !isAgencyEnabled) && category != null) {
      const categoryID = category.toString()
      void dispatch(
        getCampaigns({
          tenantID: TenantID,
          agencyID: AgencyID?.toString(),
          categories: categoryID != null ? [categoryID] : []
        })
      )
    }
  }, [dispatch, AgencyID, TenantID, category, isAgencyEnabled])

  useEffect(() => {
    if (isNameUpdated) {
      setListWithNewOrder(
        getUpdatedList(
          false,
          listWithNewOrder,
          REORDER_KEY_NAME.CampaignIDKeyWord,
          title
        )
      )
      setListWithNewFeaturedOrder(
        getUpdatedList(
          false,
          listWithNewFeaturedOrder,
          REORDER_KEY_NAME.CampaignIDKeyWord,
          title
        )
      )
      setIsCampaignNameUpdated(false)
    }
  }, [isNameUpdated, title, listWithNewOrder, listWithNewFeaturedOrder])

  useEffect(() => {
    if ((isValid(AgencyID) || !isAgencyEnabled) && isFeatured) {
      void dispatch(
        getFeaturedCampaigns({
          tenantID: TenantID,
          agencyID: AgencyID?.toString()
        })
      )
    }
  }, [dispatch, AgencyID, TenantID, isFeatured, isAgencyEnabled])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setFeaturedUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setListWithNewFeaturedOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])
  useEffect(() => {
    dispatch(resetCampaignForm())
  }, [dispatch])

  useEffect(() => {
    handleClose()
  }, [errors])

  useEffect(() => {
    if (errorStatus === ErrorStatus.PASS) {
      blocker.proceed != null
        ? (blocker.proceed as () => void)()
        : navigate(
            `${RoutePath.Campaigns}/${campaignSelected.CampaignID ?? ''}`
        )
    }
  }, [
    errorStatus,
    navigate,
    campaignSelected.CampaignID,
    dispatch,
    blocker.proceed
  ])

  const createCampaignSubmission = async (): Promise<void> => {
    try {
      const iconUpload =
        iconFile != null ? await uploadImage(userID, iconFile) : null
      const thumbnailUpload =
        thumbnailFile != null ? await uploadImage(userID, thumbnailFile) : null
      const form = {
        ...campaignForm,
        fromCreation: true,
        campaignsImageID:
          thumbnailUpload?.imageID === 0 ? null : thumbnailUpload?.imageID,
        campaignsIconID: iconUpload?.imageID === 0 ? null : iconUpload?.imageID,
        TenantID,
        ctaName: campaignForm.hasCTA ? campaignForm.ctaName : '',
        ctaLink: campaignForm.hasCTA ? campaignForm.ctaLink : '',
        startTimestampUTC: startTimestampUTC?.toString() ?? null,
        uiPosition: parseInt(webUiPosition),
        featuredUi: checkIsNotEmpty(featuredUiPosition)
          ? parseInt(featuredUiPosition)
          : null,
        updatedUIs: modifyUIObjects(updatedUIs, false, UI_TYPE.CAMPAIGN),
        updatedFeaturedUIs: modifyUIObjects(
          updatedFeaturedUIs,
          true,
          UI_TYPE.OFFER
        ),
        userType
      }

      if (userType === RoleType.AGENCY) {
        form.AgencyID = agencyID
      }
      void dispatch(createNewCampaign(form))
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    }
  }

  const renderConfirmCreateCampaignDialog = (
    <CustomDialog
      title={t`CreateCampaign`}
      open={openConfirmCreateCampaign}
      cancelCallback={handleClose}
      okCallback={createCampaignSubmission}
      mainMessage={t('CampaignCreateDashboardDialogMessage')}
      name={name}
      okText={t`CreateCampaignCTA`}
      cancelText={t`Cancel`}
    />
  )
  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardCampaignCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )
  const onFormChange = (
    value: CampaignFormInterface[keyof CampaignFormInterface] | Dayjs,
    name: string
  ): void => {
    dispatch(modifyCampaignForm({ name, value, campaignForm }))
  }
  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case STEP_NUMBER.CardInfo:
        return (
          <Box>
            <CampaignCardInfo
              webUiPosition={webUiPosition}
              featuredUiPosition={featuredUiPosition}
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              onDiscard={handleDiscard}
              handleBack={handleBack}
              isLeavingDialogOpen={openConfirmLeaving}
              isReorderDisabled={
                isValid(title) &&
                category != null &&
                (isValid(AgencyID) || !isAgencyEnabled)
              }
              isFeaturedReorderDisabled={
                isValid(title) && (isValid(AgencyID) || !isAgencyEnabled)
              }
              onUiReorderClick={() => setOpenUIreorder(true)}
              onFeaturedUiReorderClick={() => setOpenFeaturedUIreorder(true)}
              resetUIs={() => setResetUIs(true)}
              setIsCampaignNameUpdated={() => setIsCampaignNameUpdated(true)}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Detail:
        return (
          <Box>
            <CampaignDetail
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.CampaignOptions:
        return (
          <Box>
            <CampaignOptions
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.RewardsDistribution:
        return (
          <Box>
            <CampaignRewards
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Audience:
        return (
          <Box>
            <CampaignCreateAudience
              onFormChange={onFormChange}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case STEP_NUMBER.Preview:
        return (
          <Box>
            <CampaignPreview
              webUiPosition={webUiPosition}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              onSubmit={() => setOpenConfirmCreateCampaign(true)}
              handleVisitStep={handleVisitStep}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      default:
        return null
    }
  }
  return (
    <>
      <Box>
        <h1 className='title'>{t`CreateCampaign`}</h1>
      </Box>
      <Box className='createCampaignWrapper'>
        <SelfServeStepper
          nonLinear={false}
          steps={STEPS_FOR_CREATE_CAMPAIGN}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
        <Box className='mt-2'>
          <Box className='space-between'>
            <Box minWidth={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
            <CampaignPhonePreviewWrapper isFromCreateEdit />
          </Box>
        </Box>
        {isUIReorderOpen && (
          <ReorderUiPositionWrapperCreate
            isUIReorderOpen={isUIReorderOpen}
            handleClose={() => setOpenUIreorder(false)}
            name={title}
            onReorderSave={onReorderSave}
            currentList={campaigns}
            listWithNewOrder={listWithNewOrder}
            isCampaign
          />
        )}
        {isFeaturedUIReorderOpen && (
          <ReorderUiPositionWrapperCreate
            isUIReorderOpen={isFeaturedUIReorderOpen}
            handleClose={() => setOpenFeaturedUIreorder(false)}
            name={title}
            onReorderSave={onFeaturedReorderSave}
            currentList={featuredCampaigns}
            listWithNewOrder={listWithNewFeaturedOrder}
            isCampaign
            isFeatured
          />
        )}
        {renderConfirmCreateCampaignDialog}
        {renderConfirmLeavingDialog}
        {validationErrorsDisplay(errors)}
      </Box>
    </>
  )
}

export default CampaignCreate
