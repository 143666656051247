const authConstants = {
  AuthStatus: {
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT',
    SUSPENDED: 'SUSPENDED'
  },
  UserType: {
    TENANT: 'TENANT',
    AGENCY: 'AGENCY',
    ADMIN: 'ADMIN'
  },
  Domain: 'auth'
}
export default authConstants
