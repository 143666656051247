import { ReactElement } from 'react'
import { Box, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsInterfaceProps } from './CampaignsDetails'
import {
  setDashIfEmpty,
  checkIsNotEmpty,
  checkObjectIsNotEmpty
} from '../../commons/utils/utils'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'

const CampaignTypeStep = ({
  campaign
}: CampaignsInterfaceProps): ReactElement | null => {
  const { t } = useTranslation()
  const aggregatesText = `${campaign.transactionalAggregates?.Category ?? ''} ${
    campaign.transactionalAggregates?.Value ?? ''
  }`

  return (
    <Box>
      <Box>
        <Box className='details-box'>
          <SelfServeROTextField
            id='provider'
            label={t`ProviderRequiredToConnect`}
            defaultValue={setDashIfEmpty(campaign.providers)}
            showDivider
          />
          <SelfServeROTextField
            id='transactionAggregate'
            label={t`TransactionAggregate`}
            classNameDivider='mb-0'
            showDivider
            defaultValue={
              checkObjectIsNotEmpty(campaign.transactionalAggregates)
                ? aggregatesText
                : t`Dash`
            }
          />
          <InputLabel
            size='small'
            variant='standard'
            className='small-label mb-2'
          >
            {t`TransactionFilter`}
          </InputLabel>
          {checkIsNotEmpty(campaign.transactionalFilter)
            ? campaign.transactionalFilter?.map((filter) => {
              return (
                <SelfServeROTextField
                  id='transactionFilter'
                  key={filter.Category}
                  classNameDivider='mb-0'
                  defaultValue={`${filter.Category} ${filter.Operator} ${filter.Value}`}
                />
              )
            })
            : t`Dash`}
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignTypeStep
