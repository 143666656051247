import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import _ from 'lodash'
import '../../ui/phone-preview/PhonePreview.scss'
import {
  emptyCategoryPlaceholder,
  emptyImagePlaceholder,
  emptyPreviewWithCategory
} from '../../ui/phone-preview/PhonePreview'
import { OfferPreviewInterface } from '../interfaces/offers'
import { t } from 'i18next'
import { checkIsNumPositive } from '../../commons/utils/utils'
import { MARGIN_TOP_01 } from '../../commons/constants/constants'

interface OffersInterfaceProps {
  offer: OfferPreviewInterface
}

const emptyPreviewRowsCount = 3

const offerPreview = ({
  url,
  veloAmount,
  title,
  category
}: OfferPreviewInterface): ReactElement => {
  const categoryPreview = !_.isEmpty(category)
    ? (
      <Typography className='category-title'>{category}</Typography>
      )
    : (
        emptyCategoryPlaceholder()
      )
  const imagePreview = !_.isEmpty(url)
    ? (
      <img alt='category-offer-preview' src={url ?? ''} />
      )
    : (
        emptyImagePlaceholder()
      )

  return (
    <Box className='offer-preview'>
      {categoryPreview}
      {imagePreview}
      <Typography className='subheader'>
        {checkIsNumPositive(veloAmount)
          ? t('PreviewCardPoints', { veloAmount })
          : t`Free`}
      </Typography>
      <Typography className='header'>{title}</Typography>
    </Box>
  )
}

const CategoriesOfferPreview = ({
  offer
}: OffersInterfaceProps): ReactElement => {
  return (
    <Box className='flex-column mt-2'>
      <Box className='flex-row'>
        {!_.isEmpty(offer.title)
          ? offerPreview(offer)
          : emptyPreviewWithCategory()}
        {emptyPreviewWithCategory(true)}
      </Box>
      {_.times(emptyPreviewRowsCount, (index) => (
        <Box className='flex-row' key={`${index}-${emptyPreviewRowsCount}`}>
          {emptyPreviewWithCategory(false, MARGIN_TOP_01)}
          {emptyPreviewWithCategory(true, MARGIN_TOP_01)}
        </Box>
      ))}
    </Box>
  )
}

export default CategoriesOfferPreview
