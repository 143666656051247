import { Box } from '@mui/material'
import { ReactElement } from 'react'
import { useAppSelector } from '../../core/redux/hooks'
import { OfferPhonePreviewProps } from '../interfaces/offers'
import { getOfferPreviewObject } from '../utils'
import CategoriesOfferPreview from './CategoriesOfferPreview'
import './OffersPhonePreview.scss'

const OfferCardPhonePreview = ({
  isFromCreateEdit
}: OfferPhonePreviewProps): ReactElement => {
  const { offerCreateForm, offerSelected, offerCategories } = useAppSelector(
    (state) => ({
      offerCreateForm: state.offers.offerCreateForm,
      offerSelected: state.offers.offerSelected,
      offerCategories: state.offerCategories.offerCategories
    })
  )

  const offer = getOfferPreviewObject(
    offerCreateForm,
    offerSelected,
    offerCategories,
    isFromCreateEdit
  )

  return (
    <Box className='offer-phone-preview'>
      <CategoriesOfferPreview offer={offer} />
    </Box>
  )
}

export default OfferCardPhonePreview
