import { FC, ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { RoutePath } from '../../core/routes/routePaths'
import { constants } from '../../commons/constants/constants'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { getSegmentByID, removeSegment } from '../slices'
import { Trans, useTranslation } from 'react-i18next'
import SelfServeButton from '../../ui/button/Button'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SelfServeStepper from '../../ui/stepper/Stepper'
import SegmentsInfoStep from './SegmentsInfoStep'
import SegmentsCodesInfoStep from './SegmentsCodesInfoStep'
import SegmentsManualInfoStep from './SegmentsManualInfoStep'
import _ from 'lodash'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'

const SegmentsDetails: FC<{}> = (): ReactElement | null => {
  const {
    TargetGroupID,
    Manual,
    totalCampaigns,
    totalOffers,
    totalUsers,
    Name
  } = useAppSelector((state) => ({
    TargetGroupID: state.segments.segmentSelected.TargetGroupID,
    Manual: state.segments.segmentSelected.Manual,
    totalCampaigns: state.segments.segmentSelected.totalCampaigns,
    totalOffers: state.segments.segmentSelected.totalOffers,
    totalUsers: state.segments.segmentSelected.totalUsers,
    Name: state.segments.segmentSelected.Name
  }))

  const { loading } = useAppSelector((state) => state.segments)
  const { tenantID } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const STEPS_FOR_CAMPAIGN_STEPPER = [
    t`SegmentsInformation`,
    t`CodesInformation`
  ]
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openUnableToDelete, setOpenUnableToDelete] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)

  useEffect(() => {
    if (id != null) {
      void dispatch(getSegmentByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch, t])

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return <SegmentsInfoStep />
      case 1:
        return <SegmentsCodesInfoStep />
      default:
        return null
    }
  }

  const handleClose = (): void => {
    setOpenConfirmDelete(false)
    setOpenUnableToDelete(false)
  }

  const renderConfirmDeleteDialog = (
    <CustomDialog
      title={t`DeleteSegment`}
      open={openConfirmDelete}
      cancelCallback={handleClose}
      okCallback={() => {
        void dispatch(removeSegment({ Name, TenantID: tenantID }))
        navigate(RoutePath.Segments)
      }}
      mainMessage={
        <Trans i18nKey='DeleteSegmentMessage' values={{ segmentName: Name }} />
      }
      okText={t`YesDeleteIt`}
      cancelText={t`Cancel`}
    />
  )

  const renderUnableToDeleteDialog = (
    <CustomDialog
      title={t`CannotDelete`}
      open={openUnableToDelete}
      cancelCallback={handleClose}
      okCallback={handleClose}
      mainMessage={t`UnableToDeleteMessage`}
      okText={t`Ok`}
    />
  )

  const deleteSegment = (): void => {
    if (
      _.gt(totalCampaigns, 0) ||
      _.gt(totalOffers, 0) ||
      _.gt(totalUsers, 0)
    ) {
      setOpenUnableToDelete(true)
    } else {
      setOpenConfirmDelete(true)
    }
  }

  return TargetGroupID != null && !loading
    ? (
      <Box>
        <Box className='space-between flex-row'>
          <Box className='flex-row '>
            <Box>
              <h1 className='title'>{t`SegmentDetails`}</h1>
            </Box>
            <Box className='ml-2'>
              <SelfServeButton
                size='small'
                id='edit-segment-button'
                variant='contained'
                color='secondary'
                onClick={() => navigate(RoutePath.Edit)}
              >
                <ModeEditOutlinedIcon /> &nbsp; {t`Edit`}
              </SelfServeButton>
            </Box>
          </Box>
          {Manual === true && (
            <SelfServeButton
              size='small'
              id='delete-segment-button'
              variant='outlined'
              className='error-outlined'
              onClick={deleteSegment}
            >
              {t`DeleteSegment`}
            </SelfServeButton>
          )}
        </Box>
        {Manual === true
          ? (
            <Box width={constants.MAX_WIDTH_CARD}>
              <SegmentsManualInfoStep />
            </Box>
            )
          : (
            <>
              <SelfServeStepper
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                nonLinear
                steps={STEPS_FOR_CAMPAIGN_STEPPER}
              />
              <Box className='mt-2'>
                <Box width={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
              </Box>
            </>
            )}
        {openConfirmDelete && renderConfirmDeleteDialog}
        {openUnableToDelete && renderUnableToDeleteDialog}
      </Box>
      )
    : null
}

export default SegmentsDetails
