import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import './offer-create.scss'
import '../../main/Main.scss'
import { t } from 'i18next'

interface PreviewBoxProps {
  title: string
  value: string | number
  classNameBox: string
  isError?: boolean
  isSuccess?: boolean
  [key: string]: string | number | boolean | undefined
  isImage?: boolean
}

const PreviewBox: FC<PreviewBoxProps> = ({
  title,
  value,
  classNameBox,
  isError = false,
  isSuccess = false,
  isImage = false,
  ...rest
}) => {
  return (
    <Box className={classNameBox} {...rest}>
      <Typography className='title-preview'>{title}</Typography>
      {isImage && value !== ''
        ? (
          <img
            className={
            title === t`Thumbnail` ? 'thumbnail-preview' : 'icon-preview'
          }
            src={typeof value === 'string' ? value : ''}
            alt='dropzone-img'
          />
          )
        : (
          <Typography
            className={`${isError ? 'error' : isSuccess ? 'success' : 'black'}`}
          >
            {value}
          </Typography>
          )}
    </Box>
  )
}

export default PreviewBox
