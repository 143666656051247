import { axiosInstance } from '../../../core/api/init'

interface ImageUploadParams {
  userID: string
  fileType: string
  maxCacheAge: number
  prefix: string
}

export interface ImageUploadParamResponse {
  endpointUrl: string
  params: ImageParams
  imageId: number
}

interface ImageParams {
  policy: string
  'x-amz-signature': string
  key: string
  bucket: string
  acl: string
  'x-amz-algorithm': string
  'x-amz-credential': string
  'x-amz-date': string
  'Cache-Control': string
  'x-amz-security-token': string
}

export const uploaderService = {
  imageUploadParams: async ({ userID, fileType, maxCacheAge, prefix }: ImageUploadParams): Promise<ImageUploadParamResponse> => {
    const res = await axiosInstance.get('/images/upload-params', {
      params: {
        userID,
        fileType,
        maxCacheAge,
        prefix
      }
    })
    return res.data
  }
}
