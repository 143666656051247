import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { setDashIfEmpty } from '../../commons/utils/utils'
import '../segments.scss'
import SelfServeROTextField from '../../ui/text-field/TextFieldRO'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import _ from 'lodash'

const SegmentsManualInfoStep = (): ReactElement | null => {
  const { t } = useTranslation()
  const {
    auth: { userType, isAgencyEnabled },
    segments: {
      segmentSelected: { Name, AgencyName, inviteCodeSegmentName }
    }
  } = useAppSelector((state) => state)

  return (
    <Box>
      <Box className='details-box'>
        {userType !== RoleType.AGENCY && isAgencyEnabled && (
          <SelfServeROTextField
            id='agencyName'
            label={t`Agency`}
            defaultValue={setDashIfEmpty(AgencyName)}
            showDivider
          />
        )}
        <SelfServeROTextField
          id='segmentName'
          label={t`SegmentName`}
          defaultValue={
            !_.isEmpty(inviteCodeSegmentName) ? inviteCodeSegmentName : Name
          }
          classNameDivider='mb-0'
          showDivider
        />
      </Box>
    </Box>
  )
}

export default SegmentsManualInfoStep
