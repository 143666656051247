import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../core/redux/hooks'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { OffersListParams } from './interfaces/offers'
import { getValueForForm } from '../commons/utils/utils'
import { getOffers } from './slices'
import { FIRST_PAGE_OFFERS_TABLE, PER_PAGE_OFFERS_TABLE } from './constants'
import Box from '@mui/material/Box'
import SelfServeButton from '../ui/button/Button'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import OffersListMainHeader from './offers-main-section/OffersListMainHeader'
import OffersListMain from './offers-main-section/OffersListMain'
import { CSVLink } from 'react-csv'
import { RoutePath } from '../core/routes/routePaths'
import { PAGE, constants } from '../commons/constants/constants'

const Offers = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { offers, totalOffersList, tenantID, agencyID } = useAppSelector(
    (state) => ({
      offers: state.offers.offers,
      totalOffersList: state.offers.totalOffersList,
      tenantID: state.auth.tenantID,
      agencyID: state.auth.agencyID
    })
  )

  const handleFormChange = ({
    agencyID,
    tenantID,
    search,
    categories,
    page,
    perPage,
    status,
    offerType
  }: OffersListParams): void => {
    Object.entries({
      agencyID,
      tenantID,
      search,
      categories,
      page,
      perPage,
      status,
      offerType
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      setSearchParams((params) => {
        if (Array.isArray(checkedValue) && checkedValue.length === 0) {
          params.delete(k)
        } else if (checkedValue != null) {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
        }
        return params
      })
    })
  }

  React.useEffect(() => {
    void dispatch(
      getOffers({
        categories: searchParams.get('categories')?.split(',') ?? [],
        tenantID,
        search: searchParams.get('search') ?? '',
        page: parseInt(
          searchParams.get('page') ?? FIRST_PAGE_OFFERS_TABLE.toString()
        ),
        perPage: parseInt(
          searchParams.get('perPage') ?? PER_PAGE_OFFERS_TABLE.toString()
        ),
        agencyID: agencyID ?? searchParams.get('agencyID') ?? '',
        offerType: searchParams.get('offerType') ?? '',
        status: searchParams.get('status') ?? ''
      })
    )
  }, [agencyID, tenantID, dispatch, searchParams])

  return (
    <Box className='flex-column'>
      <Box className='flex-row space-between'>
        <h1 className='title'>{t`Offers`}</h1>
        <Box>
          <SelfServeButton
            id='generate-offer-report'
            className='mr-1'
            variant='contained'
            color='secondary'
          >
            <CSVLink
              data={offers.map((offer) => {
                return {
                  ID: offer.OfferID,
                  Type: offer.OfferType,
                  Name: offer.Title,
                  Agency: offer.AgencyName,
                  Category: offer.CategoryName,
                  OfferUIPosition: offer.WebUiPosition,
                  Title: offer.WebTitle,
                  Description: offer.Description,
                  Instruction: offer.CouponInstructions,
                  ListedPoints: offer.VeloAmount,
                  RealPoints: offer.RealValue,
                  RedemptionLimit: offer.MaxRedeemsPerUser,
                  Status: offer.offerstatus
                }
              })}
              filename={`offers-${Date.now()}.csv`}
              className='neutral-link-secondary'
            >
              <FileDownloadOutlinedIcon
                fontSize='small'
                className='button-icon'
              />{' '}
              {t`ExportList`}
            </CSVLink>
          </SelfServeButton>
          <SelfServeButton
            color='primary'
            id='create-offer'
            variant='contained'
          >
            <Link to={RoutePath.CreateOffers} className='neutral-link'>
              <AddOutlinedIcon className='button-icon' /> {t`NewOffer`}
            </Link>
          </SelfServeButton>
        </Box>
      </Box>
      <OffersListMainHeader
        handleFormChange={handleFormChange}
        agency={agencyID ?? searchParams.get('agencyID') ?? ''}
        search={searchParams.get('search') ?? ''}
        status={searchParams.get('status') ?? ''}
        categories={searchParams.get('categories')?.split(',') ?? []}
        offerType={searchParams.get('offerType') ?? ''}
      />
      <OffersListMain
        offersList={offers}
        total={totalOffersList}
        onPageChange={handleFormChange}
        page={parseInt(
          searchParams.get('page') ?? FIRST_PAGE_OFFERS_TABLE.toString()
        )}
        perPage={parseInt(
          searchParams.get('perPage') ?? PER_PAGE_OFFERS_TABLE.toString()
        )}
      />
    </Box>
  )
}

export default Offers
