import {
  FormControl,
  FormHelperText,
  Divider,
  Box,
  FormControlLabel
} from '@mui/material'
import { useEffect, ReactElement } from 'react'
import SelfServeButton from '../../../ui/button/Button'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import '../../campaigns.scss'
import { useTranslation } from 'react-i18next'
import {
  FORM_DATE_FORMAT,
  constants
} from '../../../commons/constants/constants'
import { reportError } from '../../../core/error/handler'
import { ErrorLevels } from '../../../core/error/constants'
import { useAppSelector, useAppDispatch } from '../../../core/redux/hooks'
import * as formValidationRule from '../../campaign-rules/CampaignFormValidationRules'
import * as validationConstants from '../../constants/ValidationConstants'
import {
  CampaignFormInterface,
  CampaignFormProps
} from '../../interfaces/campaigns'
import 'react-datepicker/dist/react-datepicker.css'
import CustomSwitch from '../../../ui/custom-switch/CustomSwitch'
import { getAllSegments } from '../../../segments/slices'
import {
  handleNextStep,
  isExpiredDatePassed
} from '../../../commons/utils/utils'
import FormFieldBox from '../../../ui/form-field-box/FormFieldBox'
import SelfServeDatePicker from '../../../ui/date-picker/SelfServeDatePicker'

const CampaignEditAudience = ({
  onFormChange,
  handleNext,
  handleDateChange,
  handleBack,
  onDiscard,
  isEditable = true
}: CampaignFormProps): ReactElement | null => {
  const {
    startTimestampUTC,
    endTimestampUTC,
    hasExpiryDate,
    pointExpiryDate,
    availableSegments,
    excludeSegments,
    AgencyID
  } = useAppSelector((state) => ({
    startTimestampUTC: state.campaigns.campaignForm.startTimestampUTC,
    endTimestampUTC: state.campaigns.campaignForm.endTimestampUTC,
    hasExpiryDate: state.campaigns.campaignForm.hasExpiryDate,
    pointExpiryDate: state.campaigns.campaignForm.pointExpiryDate,
    availableSegments: state.campaigns.campaignForm.availableSegments,
    excludeSegments: state.campaigns.campaignForm.excludeSegments,
    AgencyID: state.campaigns.campaignForm.AgencyID
  }))
  const {
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      startTimestampUTC,
      endTimestampUTC,
      hasExpiryDate,
      pointExpiryDate,
      availableSegments,
      excludeSegments,
      AgencyID
    }
  })
  const { tenantID, agencyID: agencyIDLoggedIn } = useAppSelector(
    (state) => state.auth
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (AgencyID != null) {
      void dispatch(
        getAllSegments({
          TenantID: tenantID,
          AgencyID: agencyIDLoggedIn ?? AgencyID
        })
      )
    }
  }, [AgencyID, dispatch, tenantID, agencyIDLoggedIn])
  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <Box className='mb-1'>
          <FormControl fullWidth>
            <Box className='flex-row space-between'>
              <Box>
                <FormFieldBox
                  title={t`StartDate`}
                  rules={{
                    required:
                      formValidationRule.formValidationConstants.requiredField,
                    ...formValidationRule.dateBeforeTargetTime(
                      validationConstants.StartDate,
                      startTimestampUTC,
                      validationConstants.CurrentTime,
                      null,
                      true
                    )
                  }}
                  name='startTimestampUTC'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      data-testid='startTimeField'
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof CampaignFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={startTimestampUTC}
                      isFromEdit
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={!isEditable}
                    />
                  )}
                  control={control}
                  errorField={errors.startTimestampUTC}
                  isAdjacentDatePicker
                />
              </Box>
              <Box>
                <FormFieldBox
                  title={t`EndDate`}
                  rules={{
                    required:
                      formValidationRule.formValidationConstants.requiredField,
                    ...formValidationRule.dateBeforeTargetTime(
                      validationConstants.EndDate,
                      endTimestampUTC,
                      validationConstants.StartDate,
                      startTimestampUTC,
                      true
                    )
                  }}
                  name='endTimestampUTC'
                  render={({ field: { name, onChange } }) => (
                    <SelfServeDatePicker
                      data-testid='endTimeField'
                      onChange={(date) => {
                        if (handleDateChange != null) {
                          handleDateChange(
                            date,
                            name as keyof CampaignFormInterface,
                            onChange
                          )
                        }
                      }}
                      dateTime={endTimestampUTC}
                      isFromEdit
                      dateFormat={FORM_DATE_FORMAT}
                      disabled={isExpiredDatePassed(pointExpiryDate)}
                    />
                  )}
                  control={control}
                  errorField={errors.endTimestampUTC}
                  isAdjacentDatePicker
                />
              </Box>
            </Box>
          </FormControl>
        </Box>
        <Box className='mb-1'>
          <FormHelperText>{t`ExpirePointsDate`}</FormHelperText>
          <FormControlLabel
            className='mb-1 switch-label'
            name='hasExpiryDate'
            control={
              <CustomSwitch
                data-testid='hasExpiryDateSwitch'
                size='medium'
                onClick={() =>
                  onFormChange(
                    hasExpiryDate != null && !hasExpiryDate,
                    constants.COMMON_FORM_VALUES
                      .HASEXPIREPOINTSDATE as keyof CampaignFormInterface
                  )}
                checked={hasExpiryDate}
                disabled={isExpiredDatePassed(pointExpiryDate)}
              />
            }
            label={t`SetExpirePointsDate`}
          />
          {hasExpiryDate && (
            <FormFieldBox
              title=''
              rules={{
                required:
                  formValidationRule.formValidationConstants.requiredField,
                ...formValidationRule.dateBeforeTargetTime(
                  validationConstants.ExpiryDate,
                  pointExpiryDate,
                  validationConstants.EndOfCampaign,
                  endTimestampUTC,
                  false
                )
              }}
              name='pointExpiryDate'
              render={({ field: { name, onChange } }) => (
                <SelfServeDatePicker
                  data-testid='pointExpiryDateField'
                  onChange={(date) => {
                    if (handleDateChange != null) {
                      handleDateChange(
                        date,
                        name as keyof CampaignFormInterface,
                        onChange
                      )
                    }
                  }}
                  dateTime={pointExpiryDate}
                  isFromEdit
                  dateFormat={FORM_DATE_FORMAT}
                  disabled={isExpiredDatePassed(pointExpiryDate)}
                />
              )}
              control={control}
              errorField={errors.pointExpiryDate}
              isAdjacentDatePicker
            />
          )}
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='campaign-create-preview'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='nextButtonCampaignEditAudience'
              id='campaign-create-next'
              type='submit'
              variant='contained'
              disabled={false}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='campaign-create-discard'
            onClick={onDiscard}
            variant='contained'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </Box>
  )
}

export default CampaignEditAudience
