import {
  TextField,
  FormControl,
  FormHelperText,
  Divider,
  Box,
  FormControlLabel,
  MenuItem,
  ListSubheader,
  InputAdornment
} from '@mui/material'
import { useState, useEffect, ReactElement, useMemo } from 'react'
import SelfServeButton from '../../ui/button/Button'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import '../campaigns.scss'
import { useTranslation } from 'react-i18next'
import { constants } from '../../commons/constants/constants'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import CustomSwitch from '../../ui/custom-switch/CustomSwitch'
import { useAppSelector } from '../../core/redux/hooks'
import {
  BadgeProp,
  CampaignFormInterface,
  CampaignFormProps
} from '../interfaces/campaigns'
import * as formValidationRule from '../campaign-rules/CampaignFormValidationRules'
import * as validationConstants from '../constants/ValidationConstants'
import { DropResult } from 'react-beautiful-dnd'
import DraggableList from '../../ui/badges/DraggableBadgeList'
import AddIcon from '@mui/icons-material/Add'
import {
  IconComponent,
  containsText,
  handleNextStep
} from '../../commons/utils/utils'
import FormFieldBox from '../../ui/form-field-box/FormFieldBox'
import FormsTextFieldWithEditor from '../../ui/text-field/FormsTextFieldWithEditor'
import { resetIsDescription, setIsDescription } from '../slices'
import { PREDEFINED_ICON_LIST } from '../../commons/constants/badge-icon'
import * as MUIcon from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import {
  BADGE_FORM_NAME,
  KEY_DOWN_ACTION_ESCAPE,
  NO_ICON
} from '../constants/CommonConstants'
import SelfServeCustomSelect from '../../ui/select/SelfServeCustomSelect'

const CampaignDetail = ({
  onFormChange,
  handleChange,
  handleNext,
  handleBack,
  onDiscard,
  isFromEdit = false
}: CampaignFormProps): ReactElement | null => {
  const {
    description,
    hasCTA,
    ctaName,
    ctaLink,
    badge,
    icon,
    label,
    isFormTouched,
    isDescription,
    descriptionHTML
  } = useAppSelector((state) => ({
    description: state.campaigns.campaignForm.description,
    hasCTA: state.campaigns.campaignForm.hasCTA,
    ctaName: state.campaigns.campaignForm.ctaName,
    ctaLink: state.campaigns.campaignForm.ctaLink,
    badge: state.campaigns.campaignForm.badge,
    icon: state.campaigns.campaignForm.icon,
    label: state.campaigns.campaignForm.label,
    isFormTouched: state.campaigns.isFormTouched,
    isDescription: state.campaigns.isDescription,
    descriptionHTML: state.campaigns.descriptionHTML
  }))

  const {
    control,
    trigger,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      description,
      hasCTA,
      ctaName,
      ctaLink,
      badge,
      icon,
      label
    }
  })
  const { t } = useTranslation()
  const [badgeList, setBadgeList] = useState<BadgeProp[]>([])
  const [singleBadgeIcon, setSingleBadgeIcon] = useState(NO_ICON)
  const [singleBadgeLabel, setSingleBadgeLabel] = useState('')
  const [lastBadgeUI, setLastBadgeUI] = useState(badge.length.toString())
  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    setBadgeList(badge)
    setSingleBadgeIcon(NO_ICON)
    setSingleBadgeLabel('')
    setLastBadgeUI(badge.length.toString())
  }, [badge])
  const handleAddBadge = (
    singleBadgeIcon: string,
    singleBadgeLabel: string,
    singleBadgeUI: string
  ): void => {
    const currentBadgeList = [...badgeList]
    currentBadgeList.push({
      icon: singleBadgeIcon,
      label: singleBadgeLabel,
      ui: singleBadgeUI
    })
    onFormChange(currentBadgeList, BADGE_FORM_NAME)
  }
  const reorderBadgeUI = (badge: BadgeProp[]): BadgeProp[] => {
    return badge.map((singleBadge, index) => {
      const tempBadge = Object.assign({}, singleBadge)
      tempBadge.ui = index.toString()
      return tempBadge
    })
  }
  const handleRemoveBadge = (index: number): void => {
    let currentBadgeList = [...badgeList]
    currentBadgeList.splice(index, 1)
    currentBadgeList = reorderBadgeUI(currentBadgeList)
    onFormChange(currentBadgeList, BADGE_FORM_NAME)
  }
  const reorder = (
    list: Array<{ icon: string, label: string, ui: string }>,
    startIndex: number,
    endIndex: number
  ): Array<{ icon: string, label: string, ui: string }> => {
    let result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    result = reorderBadgeUI(result)
    return result
  }
  const onDragEnd = ({ destination, source }: DropResult): void => {
    if (destination == null) return

    const newItems = reorder(badgeList, source.index, destination.index)
    onFormChange(newItems, BADGE_FORM_NAME)
  }
  const isMaxBadge = badge.length > 4

  const displayedOptions = useMemo(
    () =>
      PREDEFINED_ICON_LIST.filter((option) => containsText(option, searchText)),
    [searchText]
  )

  const addBadgeSection = (): ReactElement => {
    return (
      <Box className='badgeField'>
        <SelfServeCustomSelect
          id='singleBadgeIcon'
          menuProps={{ autoFocus: false }}
          placeholder={t`SelectIcon`}
          handleFormChange={(e) => {
            setSingleBadgeIcon(e.target.value as string)
          }}
          onClose={() => setSearchText('')}
          value={singleBadgeIcon}
          selectItems={displayedOptions.map((values: string) => (
            <MenuItem key={values} value={values}>
              {IconComponent({ icon: values as keyof typeof MUIcon })}
            </MenuItem>
          ))}
          menuItem={
            <MenuItem value={NO_ICON}>
              <span className='no-icon-display'>{t`SelectIcon`}</span>
            </MenuItem>
          }
          subheader={
            <ListSubheader>
              <TextField
                size='small'
                autoFocus
                placeholder={t`TypeToSearch`}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== KEY_DOWN_ACTION_ESCAPE) {
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
          }
          inputProps='singleBadgeField'
          isFormSelect
        />
        <TextField
          id='singleBadgeLabel'
          value={singleBadgeLabel}
          placeholder={t`Label`}
          onChange={(e) => {
            setSingleBadgeLabel(e.target?.value)
          }}
          disabled={isMaxBadge}
          className='ml-01'
        />
        <div>
          <button
            type='button'
            className='addNewBadge'
            onClick={() =>
              handleAddBadge(singleBadgeIcon, singleBadgeLabel, lastBadgeUI)}
            disabled={
              singleBadgeIcon === NO_ICON ||
              singleBadgeLabel === '' ||
              isMaxBadge
            }
          >
            <AddIcon className='add-button-icon' />
            <p className='add-button-text'>{t`Add Badge`}</p>
          </button>
        </div>
      </Box>
    )
  }

  return (
    <Box maxWidth={constants.MAX_WIDTH_CARD}>
      <Form
        onSubmit={(event: React.FormEvent) => {
          handleNextStep(event, trigger, handleNext).catch((error: any) =>
            reportError(error, ErrorLevels.Error)
          )
        }}
        autoComplete='off'
        method='post'
      >
        <FormFieldBox
          title={t`Description`}
          rules={{
            required: formValidationRule.formValidationConstants.requiredField,
            ...formValidationRule.lengthRule(
              validationConstants.descriptionLength.min,
              validationConstants.descriptionLength.max
            )
          }}
          name='description'
          render={({ field: { name, onChange } }) => (
            <FormsTextFieldWithEditor
              data-testid='descriptionField'
              onChange={(value: string) => {
                onFormChange(value, name as keyof CampaignFormInterface)
                onChange(value)
              }}
              valueMarkdown={description}
              valueHTML={descriptionHTML}
              isChanged={isDescription}
              placeholder={t`AddDescription`}
              setIsChanged={setIsDescription}
              resetIsChanged={resetIsDescription}
            />
          )}
          control={control}
          errorField={errors.description}
        />
        <Box className='mb-1'>
          <FormHelperText>{t`HasCTA`}</FormHelperText>
          <FormControlLabel
            className='mb-1 switch-label'
            name='isFeatured'
            control={
              <CustomSwitch
                data-testid='ctaSwitch'
                size='medium'
                onClick={() =>
                  onFormChange(
                    hasCTA != null && !hasCTA,
                    constants.COMMON_FORM_VALUES
                      .HASCTA as keyof CampaignFormInterface
                  )}
                checked={hasCTA}
              />
            }
            label={t`HasCTALabel`}
          />
          {hasCTA && (
            <Box className='mb-1' display='flex' justifyContent='space-between'>
              <FormControl fullWidth>
                <FormFieldBox
                  title={t`CTALabel`}
                  rules={{
                    required:
                      formValidationRule.formValidationConstants.requiredField
                  }}
                  name='ctaName'
                  render={({ field: { name, onChange } }) => (
                    <TextField
                      inputProps={{ 'data-testid': 'ctaLabel' }}
                      name={name}
                      id='ctaName'
                      onChange={(e) => {
                        handleChange(
                          e,
                          name as keyof CampaignFormInterface,
                          onChange
                        )
                      }}
                      value={ctaName}
                    />
                  )}
                  control={control}
                  errorField={errors.ctaName}
                />
              </FormControl>
              <Divider orientation='vertical' variant='inset' flexItem />
              <FormControl fullWidth>
                <FormFieldBox
                  title={t`CTALink`}
                  rules={{
                    required:
                      formValidationRule.formValidationConstants.requiredField
                  }}
                  name='ctaLink'
                  render={({ field: { name, onChange } }) => (
                    <TextField
                      inputProps={{ 'data-testid': 'ctaLink' }}
                      name={name}
                      id='ctaLink'
                      onChange={(e) => {
                        handleChange(
                          e,
                          name as keyof CampaignFormInterface,
                          onChange
                        )
                      }}
                      value={ctaLink}
                    />
                  )}
                  control={control}
                  errorField={errors.ctaLink}
                />
              </FormControl>
            </Box>
          )}
        </Box>
        <Box className='mb-1'>
          <FormHelperText>{t`Badges`}</FormHelperText>
          <DraggableList
            items={badgeList}
            onDragEnd={onDragEnd}
            handleRemoveBadge={handleRemoveBadge}
          />
          {addBadgeSection()}
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box className='mt-1 flex-row space-between'>
          <Box>
            <SelfServeButton
              id='campaign-create-preview'
              variant='contained'
              onClick={handleBack}
              color='secondary'
              className='mr-1'
            >
              {t`Previous`}
            </SelfServeButton>
            <SelfServeButton
              dataTestId='nextButtonCampaignDetail'
              id='campaign-create-next'
              type='submit'
              variant='contained'
              disabled={isFromEdit ? false : !isDirty && !isFormTouched}
            >
              {t`Next`}
            </SelfServeButton>
          </Box>
          <SelfServeButton
            id='campaign-create-discard'
            onClick={onDiscard}
            variant='contained'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </Form>
    </Box>
  )
}

export default CampaignDetail
