import { Box, CircularProgress } from '@mui/material'
import { FC, ReactElement, useEffect, useState } from 'react'
import {
  REORDER_KEY_NAME,
  UI_TYPE,
  constants
} from '../../commons/constants/constants'
import { t } from 'i18next'
import OfferCreateOfferInfoStep from './OfferCreateOfferInfoStep'
import OfferCreateCouponInfoStep from './OfferCreateCouponInfoStep'
import OfferCreateAudienceStep from './OfferCreateAudienceStep'
import {
  useBlocker,
  useLocation,
  useNavigate,
  useNavigation
} from 'react-router-dom'
import { RoutePath } from '../../core/routes/routePaths'
import { reportError } from '../../core/error/handler'
import { ErrorLevels } from '../../core/error/constants'
import {
  ReorderList,
  errorDisplay,
  getErrorMessage,
  handleReorderSave,
  validationErrorsDisplay,
  getItemKey,
  isValid,
  ReorderItem,
  modifyUIObjects
} from '../../commons/utils/utils'
import CustomDialog from '../../ui/custom-dialog/CustomDialog'
import { useAppSelector, useAppDispatch } from '../../core/redux/hooks'
import SelfServeStepper from '../../ui/stepper/Stepper'
import { OfferFormInterface } from '../interfaces/offers'
import {
  createOffer,
  getOffers,
  modifyOfferForm,
  resetOfferForm
} from '../slices'
import OfferCreatePreviewStep from './OfferCreatePreviewStep'
import { uploadImage } from '../../commons/components/uploader/utils'
import { blockerCondition } from '../utils'
import { STEPS_FOR_OFFER_STEPPER } from '../constants'
import OfferPhonePreviewWrapper from '../offers-phone-preview/OfferPhonePreviewWrapper'
import dayjs, { Dayjs } from 'dayjs'
import ReorderUiPositionWrapperCreate from '../../ui/reorder-ui-position-wrapper/ReorderUiPositionWrapperCreate'

const OfferCreate: FC<{}> = (): ReactElement | null => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [openConfirmLeaving, setOpenConfirmLeaving] = useState<boolean>(false)
  const [openConfirmCreateOffer, setOpenConfirmCreateOffer] =
    useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigation = useNavigation()
  const [webUiPosition, setWebUiPosition] = useState('')
  const [isUIReorderOpen, setOpenUIreorder] = useState<boolean>(false)
  const [listWithNewOrder, setListWithNewOrder] = useState<ReorderList>([])
  const [updatedUIs, setUpdatedUIs] = useState<{
    [key: number]: string | number | undefined
  }>({})
  const [resetUIs, setResetUIs] = useState<boolean>(false)
  const [isNameUpdated, setIsOfferNameUpdated] = useState<boolean>(false)

  const {
    offerCreateForm,
    offerSelected,
    loading,
    errors,
    isFulfilled,
    offers
  } = useAppSelector((state) => state.offers)
  const {
    userID,
    tenantID,
    agencyID: agencyIDLoggedIn,
    isAgencyEnabled,
    userType
  } = useAppSelector((state) => state.auth)
  const {
    offerName,
    thumbnailfile: thumbnailFile,
    offerIconFile: iconFile,
    agencyID,
    startDate,
    endDate,
    categoryID
  } = offerCreateForm
  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleStepChange = (step: number): void => {
    setActiveStep(step)
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleDiscard = (): void => {
    setOpenConfirmLeaving(true)
  }
  const handleFormReset = (): void => {
    dispatch(resetOfferForm())
    blocker.proceed != null
      ? (blocker.proceed as () => void)()
      : navigate(RoutePath.Offers)
  }
  const handleClose = (): void => {
    setOpenConfirmCreateOffer(false)
    setOpenConfirmLeaving(false)
  }
  const onReorderSave = (list: ReorderList): void => {
    handleReorderSave(
      offerName,
      list,
      setWebUiPosition,
      setOpenUIreorder,
      setUpdatedUIs,
      setListWithNewOrder,
      true,
      false,
      false,
      false
    )
  }

  useEffect(() => {
    dispatch(resetOfferForm())
  }, [dispatch])

  const blocker = useBlocker(() => {
    return blockerCondition(
      location.pathname,
      true,
      true,
      openConfirmLeaving || openConfirmCreateOffer,
      setOpenConfirmLeaving,
      navigation.location?.pathname
    )
  })

  const handleDateChange = (
    date: dayjs.Dayjs | string | null,
    name: keyof OfferFormInterface,
    onChange: (val: dayjs.Dayjs | string | null, name?: string) => void
  ): void => {
    onFormChange(date, name)
    onChange(date)
  }

  const createOfferSubmission = async (): Promise<void> => {
    try {
      const iconUpload =
        iconFile != null ? await uploadImage(userID, iconFile) : null
      const thumbnailUpload =
        thumbnailFile != null ? await uploadImage(userID, thumbnailFile) : null
      const startTimeStampUTC = startDate ?? undefined
      const endTimeStampUTC = endDate ?? undefined
      void dispatch(
        createOffer({
          ...offerCreateForm,
          thumbnailIDUpload:
            thumbnailUpload?.imageID === 0 ? null : thumbnailUpload?.imageID,
          offerIconUpload:
            iconUpload?.imageID === 0 ? null : iconUpload?.imageID,
          tenantID,
          agencyID: agencyIDLoggedIn ?? agencyID,
          startTimeStampUTC,
          endTimeStampUTC,
          webUiPosition: parseInt(webUiPosition),
          UpdatedUIs: modifyUIObjects(updatedUIs, false, UI_TYPE.OFFER),
          userType
        })
      )
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
      reportError(error, ErrorLevels.Error)
    }
  }

  useEffect(() => {
    if ((isValid(agencyID) || !isAgencyEnabled) && isValid(categoryID)) {
      void dispatch(
        getOffers({
          tenantID,
          agencyID,
          categories: categoryID != null ? [categoryID] : []
        })
      )
    }
  }, [dispatch, agencyID, tenantID, categoryID, isAgencyEnabled])

  useEffect(() => {
    if (isNameUpdated) {
      const updatedList = listWithNewOrder.map((item) => {
        const offerIDkey = getItemKey(item, REORDER_KEY_NAME.OfferIDKeyWord)
        if (offerIDkey != null) {
          if (item[offerIDkey as keyof ReorderItem] == null) {
            return { ...item, Title: offerName }
          }
        }
        return item
      })
      setListWithNewOrder(updatedList)
      setIsOfferNameUpdated(false)
    }
  }, [isNameUpdated, offerName, listWithNewOrder])

  useEffect(() => {
    if (resetUIs) {
      setWebUiPosition('')
      setUpdatedUIs({})
      setListWithNewOrder([])
      setResetUIs(false)
    }
  }, [resetUIs])

  const renderConfirmCreateOfferDialog = (
    <CustomDialog
      title={t`ConfirmOfferCreation`}
      open={openConfirmCreateOffer}
      cancelCallback={handleClose}
      okCallback={createOfferSubmission}
      mainMessage={t('OfferCreateDialogMessage', { offerName })}
      okText={t`Create`}
      cancelText={t`Cancel`}
    />
  )
  const renderConfirmLeavingDialog = (
    <CustomDialog
      title={t`DiscardOfferCreation`}
      open={openConfirmLeaving}
      cancelCallback={handleClose}
      okCallback={handleFormReset}
      mainMessage={t`DiscardMessage`}
      okText={t`Leave`}
      cancelText={t`Cancel`}
    />
  )

  const onFormChange = (
    value: OfferFormInterface[keyof OfferFormInterface] | Dayjs,
    name: string
  ): void => {
    dispatch(modifyOfferForm({ name, value, offerCreateForm }))
  }

  useEffect(() => {
    handleClose()
  }, [errors])

  useEffect(() => {
    if (isFulfilled) {
      dispatch(resetOfferForm())
      navigate(`${RoutePath.Offers}/${offerSelected.OfferID ?? ''}`)
    }
  }, [dispatch, navigate, isFulfilled, offerSelected])

  if (loading) {
    return (
      <Box className='centered-box'>
        <CircularProgress />
      </Box>
    )
  }

  const renderSwitch = (): ReactElement | null => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <OfferCreateOfferInfoStep
              webUiPosition={webUiPosition}
              onFormChange={onFormChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
              isReorderDisabled={
                isValid(offerName) &&
                (isValid(agencyID) || !isAgencyEnabled) &&
                isValid(categoryID)
              }
              onUiReorderClick={() => setOpenUIreorder(true)}
              resetUIs={() => setResetUIs(true)}
              setIsOfferNameUpdated={() => setIsOfferNameUpdated(true)}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 1:
        return (
          <Box>
            <OfferCreateCouponInfoStep
              onFormChange={onFormChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 2:
        return (
          <Box>
            <OfferCreateAudienceStep
              onFormChange={onFormChange}
              handleDateChange={handleDateChange}
              handleNext={handleNext}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              isLeavingDialogOpen={openConfirmLeaving}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      case 3:
        return (
          <Box>
            <OfferCreatePreviewStep
              webUiPosition={webUiPosition}
              handleBack={handleBack}
              onDiscard={handleDiscard}
              onSubmit={() => setOpenConfirmCreateOffer(true)}
              isLeavingDialogOpen={openConfirmLeaving}
              onStepChange={handleStepChange}
            />
            {errorDisplay(errorMessage)}
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Box>
      <Box className='offer-create'>
        <h1 className='title'>{t`CreateOffer`}</h1>
        <SelfServeStepper
          nonLinear={false}
          steps={STEPS_FOR_OFFER_STEPPER}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      </Box>
      <Box className='mt-2'>
        <Box className='space-between'>
          <Box width={constants.MAX_WIDTH_CARD}>{renderSwitch()}</Box>
          <OfferPhonePreviewWrapper isFromCreateEdit />
        </Box>
      </Box>
      {isUIReorderOpen && (
        <ReorderUiPositionWrapperCreate
          isUIReorderOpen={isUIReorderOpen}
          handleClose={() => setOpenUIreorder(false)}
          name={offerName}
          onReorderSave={onReorderSave}
          currentList={offers}
          listWithNewOrder={listWithNewOrder}
          isOffer
        />
      )}
      {renderConfirmCreateOfferDialog}
      {renderConfirmLeavingDialog}
      {validationErrorsDisplay(errors)}
    </Box>
  )
}

export default OfferCreate
