import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authInitialState from './initialState'
import constants from './constants'
import { authService } from './services'
import { AuthForm, AuthState } from '../../type'
import { handleRejectWithReport } from '../core/error/handler'

export const signIn = createAsyncThunk(
  'auth/signInStatus',
  async (form: AuthForm, { rejectWithValue }) => {
    try {
      return await authService.signIn(form)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (form: AuthForm, { rejectWithValue }) => {
    try {
      return await authService.forgotPassword(form)
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

export const signOut = createAsyncThunk(
  'auth/signOutStatus',
  async (_, { rejectWithValue }) => {
    try {
      return await authService.signOut()
    } catch (error: any) {
      return handleRejectWithReport(rejectWithValue, error)
    }
  }
)

const loadingCheck = (state: AuthState): void => {
  if (!state.signInLoading) {
    state.signInLoading = true
  }
}

const handleReject = (state: AuthState, action: any): void => {
  state.signInLoading = false
  state.signInErrorMsg = action.payload
}

const auth = createSlice({
  name: constants.Domain,
  initialState: authInitialState as AuthState,
  reducers: {
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    clearAuth: (state) => {
      state.signInErrorMsg = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        if (action.payload != null) {
          state.userType = action.payload.userType
          state.authStatus = action.payload.authStatus
          state.userID = action.payload.userID
          state.email = action.payload.email
          state.name = action.payload.name
          state.signInErrorMsg = ''
          state.signInLoading = false
          state.tenantID = action.payload.tenantID
          state.agencyID = action.payload.agencyID
          state.logoUrl = action.payload.logoUrl
          state.isAgencyEnabled = action.payload.isAgencyEnabled ?? false
        }
      })
      .addCase(signIn.pending, (state) => {
        loadingCheck(state)
      })
      .addCase(signIn.rejected, (state, action) => {
        handleReject(state, action)
      })
      .addCase(signOut.fulfilled, (state) => {
        state.authStatus = constants.AuthStatus.LOGGED_OUT
        state.userType = ''
        state.userID = ''
        state.email = ''
        state.name = ''
        state.signInErrorMsg = ''
        state.signInLoading = false
        state.tenantID = ''
        state.logoUrl = ''
        state.isAgencyEnabled = false
      })
      .addCase(signOut.pending, (state) => {
        loadingCheck(state)
      })
      .addCase(signOut.rejected, (state, action) => {
        handleReject(state, action)
      })
  }
})

export const authReducer = auth.reducer
export const { setUserEmail, clearAuth } = auth.actions
