import { FC, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, CircularProgress } from '@mui/material'
import { constants } from '../../commons/constants/constants'
import {
  setClassNameForTable,
  convertStringToDateAndTime
} from '../../commons/utils/utils'
import { useTranslation } from 'react-i18next'
import {
  FIRST_PAGE_SEGMENTS_TABLE,
  PER_PAGE_SEGMENTS_TABLE,
  SegmentsMainListColumnsForAgencies,
  SegmentsMainListColumnsForTenants
} from '../constants'
import { SegmentsListParams } from '../interfaces/segments'
import '../segments.scss'
import SelfServePagination from '../../ui/pagination/SelfServePagination'
import { useAppSelector } from '../../core/redux/hooks'
import { RoleType } from '../../core/routes/constants'
import SelfServeActionItem from '../../ui/list-action-item/SelfServeActionItem'
import { segmentsListActionItems } from '../utils'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../core/routes/routePaths'

interface MainSegmentsListProps {
  onPageChange: (changedData: SegmentsListParams) => void
  page?: number
  perPage?: number
}

const MainSegmentsList: FC<MainSegmentsListProps> = ({
  onPageChange,
  page,
  perPage
}) => {
  const { t } = useTranslation()
  const {
    auth: { userType, isAgencyEnabled },
    segments: { totalSegmentsList, segments, loading }
  } = useAppSelector((state) => state)

  const COLUMNS = Object.values(
    userType !== RoleType.AGENCY && isAgencyEnabled
      ? SegmentsMainListColumnsForTenants
      : SegmentsMainListColumnsForAgencies
  )
  const handlePageChange = useCallback(
    (page: number) => onPageChange({ page }),
    [onPageChange]
  )
  const handlePerPageChange = useCallback(
    (perPage: number) => onPageChange({ perPage }),
    [onPageChange]
  )

  return !loading
    ? (
      <TableContainer>
        <Table
          sx={{ minWidth: constants.GENERAL_TABLE_MIN_WIDTH }}
          aria-label='simple table'
        >
          <TableHead>
            <TableRow>
              {COLUMNS.map((column, i) => (
                <TableCell
                  className={setClassNameForTable(column, i, COLUMNS)}
                  key={column}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {segments.length > 0 && (
            <TableBody>
              {segments.map(
                ({
                  TargetGroupID,
                  Name,
                  CreatedAtUTC,
                  TargetGroupUserCount,
                  Manual,
                  AgencyImageUrl,
                  AgencyName
                }) => {
                  const addedTime = convertStringToDateAndTime(CreatedAtUTC)
                  return (
                    <TableRow className='row-with-actions' key={TargetGroupID}>
                      <TableCell className='body-cell centered-cell'>
                        <Link to={`${RoutePath.Segments}/${TargetGroupID ?? ''}`}>
                          {TargetGroupID}
                        </Link>
                      </TableCell>
                      <TableCell className='body-cell'>
                        <Link to={`${RoutePath.Segments}/${TargetGroupID ?? ''}`}>
                          {Name}
                        </Link>
                      </TableCell>
                      {userType !== RoleType.AGENCY && isAgencyEnabled && (
                        <TableCell className='body-cell'>
                          <img
                            alt='agency-logo'
                            className='rounded-image-list'
                            src={AgencyImageUrl}
                          />{' '}
                          {AgencyName}
                        </TableCell>
                      )}
                      <TableCell className='body-cell'>
                        {addedTime.date ?? ''}
                      </TableCell>
                      <TableCell className='body-cell'>
                        {Manual === true ? t`Manual` : t`RewardsCode`}
                      </TableCell>
                      <TableCell className='body-cell'>
                        {TargetGroupUserCount}
                      </TableCell>
                      <TableCell className='body-cell'>
                        <Box className='list-actions flex-center'>
                          {segmentsListActionItems(
                            TargetGroupID ?? '',
                            Manual
                          ).map((item) => {
                            return (
                              <SelfServeActionItem
                                path={item.path}
                                title={item.title}
                                icon={item.icon}
                                key={item.title}
                              />
                            )
                          })}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          )}
        </Table>
        <SelfServePagination
          page={page ?? FIRST_PAGE_SEGMENTS_TABLE}
          perPage={perPage ?? PER_PAGE_SEGMENTS_TABLE}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          total={totalSegmentsList}
          length={segments.length}
        />
      </TableContainer>
      )
    : (
      <CircularProgress />
      )
}
export default MainSegmentsList
