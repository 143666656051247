import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './IconUpload.scss'
import '../dropzone/dropzone.scss'
import { Typography, Box, FormHelperText, IconButton } from '@mui/material'
import SelfServeButton from '../button/Button'
import CancelIcon from '@mui/icons-material/Cancel'

interface IconUploadProps {
  setFile?: (file?: File) => void
  name?: string
  url?: string
  onFormChange?: (value: number) => void
  iconID?: string | null | undefined
  testId?: string
}
interface IconUploadStateProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  testId?: string
}
const ACCEPTED_IMG_TYPES = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png']
}
const MAX_SIZE = 1000000

const IconUpload = ({
  setFile,
  name,
  url,
  onFormChange,
  iconID = null,
  testId,
  ...rest
}: IconUploadProps & React.HTMLProps<HTMLInputElement>): ReactElement => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [path, setPath] = useState(url ?? undefined)
  const [sizeError, setSizeError] = useState(false)
  const { t } = useTranslation()

  const onDrop = (acceptedFiles: File[]): void => {
    const file = acceptedFiles[0]
    if (file.size > MAX_SIZE) {
      setSizeError(true)
      setSelectedFile(undefined)
    } else {
      setSelectedFile(file)
      setPath(URL.createObjectURL(file))
      setSizeError(false)
      setFile?.(file)
    }
  }
  const deleteFile = (): void => {
    setPath(undefined)
    setSelectedFile(undefined)
    setFile?.(undefined)
    onFormChange?.(0)
  }
  const iconUpload = ({
    getRootProps,
    getInputProps,
    testId
  }: IconUploadStateProps): ReactElement => (
    <section>
      <div className='upload-image' {...getRootProps()}>
        <input id={name} {...rest} {...getInputProps({ name })} />
        <Typography className='upload-text'>{t`UploadIcon`}</Typography>
        <SelfServeButton
          id='upload'
          variant='outlined'
          className='upload-btn maxw-5'
        >
          {t`Upload`}
        </SelfServeButton>
      </div>
    </section>
  )
  return (
    <Box>
      <Dropzone accept={ACCEPTED_IMG_TYPES} maxFiles={1} onDrop={onDrop}>
        {({ getRootProps, getInputProps, testId }: IconUploadStateProps) =>
          selectedFile != null ||
          (path != null && path !== '') ||
          (iconID != null && iconID !== '')
            ? (
              <div className='upload-image'>
                {(selectedFile != null || (iconID != null && iconID !== '')) && (
                  <Box className='upload-preview'>
                    <img
                      className='square-image'
                      src={iconID ?? path}
                      alt='upload-img'
                    />
                    <Typography className='highlighted-text'>
                      {selectedFile?.name}
                    </Typography>
                    <IconButton
                      onClick={() => deleteFile()}
                      id='delete'
                      disableRipple
                      aria-label='delete'
                      color='error'
                      className='delete-icon'
                    >
                      <CancelIcon color='action' />
                    </IconButton>
                    <Box className='hidden-box'>
                      {iconUpload({ getRootProps, getInputProps })}
                    </Box>
                  </Box>
                )}
                <SelfServeButton
                  id='upload'
                  variant='outlined'
                  className='upload-btn maxw-5'
                >
                  {t`Upload`}
                </SelfServeButton>
              </div>
              )
            : (
              <Box>{iconUpload({ getRootProps, getInputProps })}</Box>
              )}
      </Dropzone>
      {sizeError && <FormHelperText error>{t`MaxSize1MB`}</FormHelperText>}
    </Box>
  )
}
export default IconUpload
