import { ReactElement, useEffect } from 'react'
import NonRepeatableCouponCreate from './NonRepeatableCouponsCreate'
import RepeatableCouponsCreate from './RepeatableCouponsCreate'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useParams } from 'react-router-dom'
import { getOfferDetailsByID } from '../slices'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { constants } from '../../commons/constants/constants'
import { t } from 'i18next'
import { RoutePath } from '../../core/routes/routePaths'
import { Box } from '@mui/material'

const CouponCreate = (): ReactElement => {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const {
    offerSelected: { HasRepeatableCoupons }
  } = useAppSelector((state) => state.offers)

  useEffect(() => {
    if (id != null) {
      void dispatch(getOfferDetailsByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.OFFER))
      dispatch(setBackButtonTitle(t`BackToOffers`))
      dispatch(setBackButtonRoute(RoutePath.Offers))
    }
    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [id, dispatch])

  return (
    <Box>
      <h1 className='title'>{t`AddCoupons`}</h1>
      {HasRepeatableCoupons === true
        ? (
          <RepeatableCouponsCreate />
          )
        : (
          <NonRepeatableCouponCreate />
          )}
    </Box>
  )
}

export default CouponCreate
