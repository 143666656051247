import { Box, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material'
import { ReactElement } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp'
import StrictModeDroppable from '../badges/DroppableForStrictMode'
import SelfServeButton from '../button/Button'
import { t } from 'i18next'
import './reorder-ui-position-dialog.scss'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  ReorderItem,
  ReorderList,
  getItemKey,
  getUiPositionName,
  handleDrag
} from '../../commons/utils/utils'
import { KEY_NAME_TYPE } from '../../commons/constants/constants'
import { getKeyName, getOfferInfo } from '../../offers/utils'
import { getCampaignInfo } from '../../campaigns/utils'

interface ReorderUiPositionDialogProps {
  open: boolean
  handleClose: () => void
  onReorderSave?: (list: ReorderList) => void
  updatedList: ReorderList
  setUpdatedList: (value: ReorderList) => void
  isOffer?: boolean
  isCampaign?: boolean
  isFeatured?: boolean
}

const ReorderUiPositionDialog = ({
  open,
  handleClose,
  onReorderSave,
  updatedList,
  setUpdatedList,
  isOffer = false,
  isCampaign = false,
  isFeatured = false
}: ReorderUiPositionDialogProps): ReactElement => {
  const webUiPositionKeyName = getUiPositionName(
    isFeatured
  ) as keyof ReorderItem
  return (
    <Dialog
      open={open}
      maxWidth='lg'
      onClose={handleClose}
      className='reorder-dialog'
    >
      <DialogContent className='reorder-dialog-content'>
        <DialogTitle className='reorder-title'>
          {t`UiPosition`}
          <CloseOutlinedIcon
            onClick={handleClose}
            aria-label='close'
            className='close-icon'
          />
        </DialogTitle>
        <DragDropContext
          onDragEnd={(result) =>
            handleDrag(result, updatedList, setUpdatedList, isFeatured)}
        >
          <StrictModeDroppable droppableId='droppable-id'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {updatedList.map((item, index) => (
                  <Draggable
                    draggableId={`draggable-id-${index}`}
                    index={index}
                    key={`draggable-id-${index}`}
                  >
                    {(provided, snapshot) => (
                      <Box
                        className={`flex-row space-between ${
                          snapshot.isDragging ? 'dragging-box' : 'drag-drop-box'
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box className='ui-position'>
                          {item[webUiPositionKeyName]}
                        </Box>
                        <DragIndicatorSharpIcon className='drag-icon' />
                        <Box className='category-name'>
                          {
                            item[
                              getItemKey(
                                item,
                                getKeyName(
                                  isOffer,
                                  isCampaign,
                                  KEY_NAME_TYPE.Title
                                )
                              ) as keyof ReorderItem
                            ] as string
                          }
                          {isOffer && getOfferInfo(item)}
                          {isCampaign && getCampaignInfo(item)}
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <Divider />
        <Box className='mt-1 flex-row space-between'>
          <SelfServeButton
            dataTestId='reorderSaveChanges'
            id='reorder-ui-save'
            variant='contained'
            onClick={() => onReorderSave?.(updatedList)}
          >
            {t`SaveChanges`}
          </SelfServeButton>
          <SelfServeButton
            id='reorder-ui-discard'
            onClick={handleClose}
            variant='outlined'
          >
            {t`Discard`}
          </SelfServeButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ReorderUiPositionDialog
