import { FC, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/redux/hooks'
import { useSearchParams, useParams } from 'react-router-dom'
import { UsersListParams } from '../../users/interfaces/users'
import { getValueForForm } from '../../commons/utils/utils'
import {
  getSegmentByID,
  getUsersForSegments,
  getUsersForSegmentsCSV,
  cleanUserSegmentsForCSV
} from '../slices'
import {
  FIRST_PAGE_USERS_TABLE,
  PER_PAGE_USERS_TABLE
} from '../../users/constants'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PAGE, constants } from '../../commons/constants/constants'
import SegmentUsersListHeader from './SegmentUsersListHeader'
import SegmentUsersList from './SegmentUsersList'
import {
  setBackButtonRoute,
  setBackButtonTitle,
  setEntity
} from '../../ui/sidebar/slices'
import { RoutePath } from '../../core/routes/routePaths'
import { CSVLink } from 'react-csv'
import SelfServeButton from '../../ui/button/Button'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const SegmentUsers: FC<{}> = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const {
    segmentSelected: { Manual },
    segmentsUsersForCSV,
    segmentsUsersForCSVLoading
  } = useAppSelector((state) => state.segments)
  const { t } = useTranslation()
  const csvRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const handleFormChange = ({
    search,
    page,
    perPage,
    status,
    addedDateStart,
    addedDateEnd
  }: UsersListParams): void => {
    Object.entries({
      search,
      page,
      perPage,
      status,
      addedDateStart,
      addedDateEnd
    }).forEach(([k, v]) => {
      const checkedValue = getValueForForm(k, v, searchParams.entries)
      checkedValue != null &&
        setSearchParams((params) => {
          params.set(k, checkedValue as string)
          if (k !== PAGE) {
            params.set(PAGE, constants.MIN_PAGINATION_PER_PAGE.toString())
          }
          return params
        })
    })
  }

  useEffect(() => {
    if (!segmentsUsersForCSVLoading && segmentsUsersForCSV?.length > 0) {
      csvRef?.current?.link.click()
    }
  }, [segmentsUsersForCSV, segmentsUsersForCSVLoading])

  useEffect(() => {
    if (id != null) {
      void dispatch(
        getUsersForSegments({
          search: searchParams.get('search') ?? '',
          page: parseInt(
            searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
          ),
          perPage: parseInt(
            searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
          ),
          addedDateStart: searchParams.get('addedDateStart') ?? '',
          addedDateEnd: searchParams.get('addedDateEnd') ?? '',
          targetGroupID: id,
          Manual: Manual === true
        })
      )
      void dispatch(getSegmentByID(id))
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.SEGMENT))
      dispatch(setBackButtonTitle(t`BackToSegments`))
      dispatch(setBackButtonRoute(RoutePath.Segments))
    }

    return () => {
      dispatch(setEntity(constants.SIDEBAR_ENTITY_TYPES.GENERAL))
    }
  }, [dispatch, searchParams, Manual, id, t])

  const csvClick = (): void => {
    void dispatch(
      getUsersForSegmentsCSV({
        search: searchParams.get('search') ?? '',
        addedDateStart: searchParams.get('addedDateStart') ?? '',
        addedDateEnd: searchParams.get('addedDateEnd') ?? '',
        targetGroupID: id,
        Manual: Manual === true
      })
    )
  }

  useEffect(() => {
    return () => {
      dispatch(cleanUserSegmentsForCSV())
    }
  }, [dispatch])

  return (
    <Box className='flex-column'>
      <Box className='flex-row space-between'>
        <h1 className='title'>{t`Users`}</h1>
        <Box>
          <SelfServeButton
            id='generate-users-report'
            className='mr-1'
            variant='contained'
            color='secondary'
            onClick={csvClick}
          >
            <FileDownloadOutlinedIcon
              fontSize='small'
              className='button-icon'
            />{' '}
            {t`ExportList`}
          </SelfServeButton>
          <CSVLink
            data={(segmentsUsersForCSV ?? []).map((item) => {
              return {
                UserID: item.UserID,
                VelociaID: item.VelociaID,
                UserIDAtTenant: item.UserIDAtTenant,
                SignUpDate: item.SignUpDate,
                JoinDate: item.JoinDate,
                AddedDate: item.CreatedAtUTC,
                Status: item.Status,
                BalancePoints: item.UsersBalance
              }
            })}
            ref={csvRef}
            filename={`users-segments-${Date.now()}.csv`}
          />
        </Box>
      </Box>
      <SegmentUsersListHeader
        search={searchParams.get('search') ?? ''}
        addedDateStart={searchParams.get('addedDateStart') ?? ''}
        addedDateEnd={searchParams.get('addedDateEnd') ?? ''}
        handleFormChange={handleFormChange}
      />
      <SegmentUsersList
        onPageChange={handleFormChange}
        page={parseInt(
          searchParams.get('page') ?? FIRST_PAGE_USERS_TABLE.toString()
        )}
        perPage={parseInt(
          searchParams.get('perPage') ?? PER_PAGE_USERS_TABLE.toString()
        )}
      />
    </Box>
  )
}

export default SegmentUsers
