import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { useAppSelector } from '../../core/redux/hooks'
import './segments-sidebar-card.scss'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import { t } from 'i18next'
import _ from 'lodash'

const SegmentsSidebarCard = (): ReactElement => {
  const {
    segmentSelected: { Name, inviteCodeSegmentName, TargetGroupID, Manual }
  } = useAppSelector((state) => state.segments)
  return (
    <Box className='segment-sidebar-card'>
      <Typography className='mb-02 mt-01 name' variant='h5'>
        {!_.isEmpty(Name) ? Name : inviteCodeSegmentName}
      </Typography>
      <Box className='sidebar-card-detail'>
        <TagOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        {TargetGroupID}
      </Box>
      <Box className='sidebar-card-detail'>
        <DonutSmallOutlinedIcon
          className='sidebar-preview-icon'
          color='action'
          fontSize='small'
        />
        <Box className='grey mr-01'>{t`Type`}</Box>
        {Manual === true ? t`Manual` : t`RewardsCode`}
      </Box>
    </Box>
  )
}

export default SegmentsSidebarCard
